import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { isMobile } from "react-device-detect";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PropTypes from "prop-types";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import StorefrontIcon from "@mui/icons-material/Storefront";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Skeleton from "@mui/material/Skeleton";
import "./App.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <ProductionQuantityLimitsIcon />,
    3: <StorefrontIcon />,
    4: <InsertInvitationIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "Set up meeting",
  "Choose product view ",
  "Product presentation",
  "Generate Invitation",
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");
function App() {
  const [idUser, setIdUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [urlStore, setUrlStore] = useState(null);
  const [productIds, setProductIds] = useState(null);
  const [agenda, setInputAgenda] = useState(null);
  const [topic, setInputTopic] = useState(null);
  const [inputDesc, setInputDesc] = useState("");
  const [inputDate, setInputDate] = useState(new Date());
  const [inputTime, setInputTime] = useState(new Date());
  const [modalStepTwo, setModalStepTwo] = useState(false);
  const [modalMiddleStep, setModalMiddleStep] = useState(false);
  const [chooseOption, setChooseOption] = useState(0);
  const [filterProducts, setFilterProducts] = useState(null);
  const [allProducts, setAllProducts] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [idMeeting, setIDMeeting] = useState(null);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setURL] = useState(null);
  const [productList, setProductList] = useState([]);
  const [urlCheckOut, setUrlCheckOut] = useState("");
  const [items, setitems] = useState([]);
  const [itemSelected, setitemSelected] = useState([]);
  const [itemId, setitemId] = useState([]);
  const [invitePeople, setInvitePeople] = useState(false);

  const [meetings, setMeetings] = useState([]);

  const domain_user = "https://backzoom-develop.herokuapp.com";
  const domain_db = "https://backzoom-develop.herokuapp.com";
  const domain_zoom = "https://backzoom-develop.herokuapp.com";
  // const domain_user = "https://companduser.herokuapp.com";
  // const domain_db = "https://backzoom-develop.herokuapp.com";
  // const domain_zoom = "https://meetingssellwithzoom-develop.herokuapp.com/";

  const handleChange = (newValue) => {
    setInputDate(newValue);
  };

  const handleChangeTime = (newValue) => {
    setInputTime(newValue);
  };

  useEffect(() => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${domain_db}/products`, options)
      .then((response) => response.json())
      .then((result) => {
        setitems(result);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    setIdUser(process.env.REACT_APP_IDUSER);
    setUserId(process.env.REACT_APP_USERID);
  }, []);

  useEffect(() => {
    let userId = process.env.REACT_APP_IDUSER;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${domain_db}/meetingDBByUser/${userId}`, options)
      .then((response) => response.json())
      .then((result) => {
        setMeetings(result);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const openMeeting = () => {
    let url = document.getElementById("txtLinkMeeting").value;
    window.open(url, "_blank");
  };
  const copyInvitation = () => {
    navigator.clipboard
      .writeText(
        `${window.location.origin}/${idMeeting}${
          url ? `?loadIframe=` + url : ""
        }`
      )
      .then(() => {
        setCopied(true);
        // console.log("Text copied to clipboard...");
      });
  };

  const generateMeeting = () => {
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        agenda: "test",
        password: "",
        duration: 120,
        pre_schedule: false,
        settings: {
          alternative_hosts: "",
          audio: "voip",
          auto_recording: "cloud",
          join_before_host: false,
          participant_video: true,
          waiting_room: true,
          watermark: true,
        },
        start_time: `${inputDate}T${inputTime}Z`,
        topic: topic,
        type: 2,
      }),
    };

    fetch(`${domain_zoom}/meeting/${userId}`, options)
      .then((response) => response.json())
      .then((result) => {
        setIDMeeting(result.id);
        setLoading(false);
        saveMeeting(result);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const saveMeeting = (meeting) => {
    console.log({
      id_user: idUser,
      meeting_id: meeting.id,
      host_id: meeting.host_id,
      topic: meeting.topic,
      description: inputDesc,
      start_time: meeting.start_time,
      duration: meeting.duration,
      time_zone: meeting.timezone,
      created_at: meeting.created_at,
      join_url: meeting.join_url,
      product_ids: productIds,
      url_store: urlStore,
    });
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_user: idUser,
        meeting_id: meeting.id,
        host_id: meeting.host_id,
        topic: meeting.topic,
        description: inputDesc,
        start_time: meeting.start_time,
        duration: meeting.duration,
        time_zone: meeting.timezone,
        created_at: meeting.created_at,
        join_url: meeting.join_url,
        product_ids: productList.join(","),
        url_store: url,
      }),
    };
    fetch(`${domain_db}/meetingDB`, options)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  return (
    <>
      {/*  First Step  */}
      <div className="App">
        <br></br>
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            onClick={(e) => {
              setOpenModal(true);
            }}
          >
            Create Meeting
          </Button>
        </Stack>
      </div>
      <div className="App">
        {!meetings?.length ? (
          <Typography
            variant="h4"
            sx={{ color: "#888888", fontSize: "19px", mt: 4 }}
          >
            You haven't had a meeting before.
          </Typography>
        ) : (
          <div style={{ height: "600px", overflowY: "scroll" }}>
            {meetings?.map((meeting) => (
              <Grid item md={12} sx={{ width: "100%" }}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {" "}
                      {meeting.topic}{" "}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      {" "}
                      {!meeting.description
                        ? "No Description."
                        : meeting.description}{" "}
                    </Typography>
                    <a
                      href={`${window.location.origin}/${meeting.meeting_id}`}
                      target="_blank"
                    >
                      <Typography variant="h6" sx={{ color: "#888888" }}>
                        {" "}
                        Meeting ID: {meeting.meeting_id}{" "}
                      </Typography>
                    </a>
                    <Typography variant="h6" sx={{ color: "#888888" }}>
                      {" "}
                      Start Time: {meeting.start_time}{" "}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      href={`${window.location.origin}/${meeting.meeting_id}`}
                      target="_blank"
                    >
                      Go To Meeting
                    </Button>
                  </CardActions>
                </Card>
                <br></br>
              </Grid>
            ))}
          </div>
        )}
      </div>
      <div className="App">
        <Modal
          isOpen={openModal}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <div>
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#e33f5b",
                }}
              >
                Meeting Information
              </h3>
            </div>
            <div>
              <Stepper
                alternativeLabel
                activeStep={0}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="content-form">
              <div>
                <div>
                  <label>Topic</label>
                </div>
                <div>
                  <input
                    onChange={(e) => setInputTopic(e.target.value)}
                    className="input-credentials"
                    defaultValue={topic ? topic : ""}
                  />
                </div>
                {/* <div>
                  <label>Store URL</label>
                </div>
                <div>
                  <input
                    onChange={(e) => setURL(e.target.value)}
                    className="input-credentials"
                  />
                </div> */}
              </div>
              <div>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={0.5}>
                      <label>Date</label>

                      {!isMobile ? (
                        <DesktopDatePicker
                          id="date-select-wk"
                          inputFormat="MM/DD/YYYY"
                          minDate={new Date()}
                          value={inputDate}
                          className="input-credentials"
                          onChange={handleChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      ) : (
                        <MobileDatePicker
                          inputFormat="MM/DD/YYYY"
                          value={inputDate}
                          className="input-credentials"
                          onChange={handleChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                      <label>Time</label>

                      <TimePicker
                        className="input-credentials"
                        value={inputTime}
                        onChange={handleChangeTime}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>
              <div>
                <div>
                  <label>Description</label>
                </div>
                <div>
                  <textarea
                    type="text"
                    onChange={(e) => setInputDesc(e.target.value)}
                    className="input-credentials input-description new-text-area"
                    defaultValue={inputDesc ? inputDesc : ""}
                  />
                </div>
              </div>
              <div className="step-btn-content">
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setOpenModal(false);
                    setModalMiddleStep(true);
                  }}
                  disabled={!topic}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          {/* <button
            className="close-modal"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button> */}
        </Modal>
      </div>
      {/*  Second Step  */}
      <div className="App">
        <Modal
          isOpen={modalMiddleStep}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <div>
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#e33f5b",
                }}
              >
                Meeting Information
              </h3>
            </div>
            <div>
              <Stepper
                alternativeLabel
                activeStep={1}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="content-form">
              <div>
                <Typography variant="h4" fontWeight="bold">
                  Choose product view
                </Typography>
              </div>
              <div className="content-choose-cards">
                <div className="choose-card" onClick={() => setChooseOption(1)}>
                  <div className="choose-card-header">
                    <img
                      className="image-header"
                      src="https://images.unsplash.com/photo-1504270997636-07ddfbd48945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80"
                    />
                  </div>
                  <div className="choose-card-body">
                    <h4 className="choose-card-title">URL Store</h4>
                    <p>
                      Selecting URL Store you can view the products of any store
                      using the URL of the store you want to access during the
                      meeting
                    </p>
                  </div>
                  {chooseOption == 1 ? (
                    <CheckCircleIcon
                      sx={{
                        position: "relative",
                        top: "-230px",
                        left: "94%",
                        fontSize: "large",
                      }}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon
                      sx={{
                        position: "relative",
                        top: "-230px",
                        left: "94%",
                        fontSize: "large",
                      }}
                    />
                  )}
                </div>
                <div className="choose-card" onClick={() => setChooseOption(2)}>
                  <div className="choose-card-header">
                    <img
                      className="image-header"
                      src="https://images.unsplash.com/photo-1522204523234-8729aa6e3d5f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                    />
                  </div>
                  <div className="choose-card-body">
                    <h4 className="choose-card-title">Products</h4>
                    <p>
                      Select the products that you want people in the meeting to
                      be able to buy.
                    </p>
                  </div>
                  {chooseOption == 2 ? (
                    <CheckCircleIcon
                      sx={{
                        position: "relative",
                        top: "-211px",
                        left: "94%",
                        fontSize: "large",
                      }}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon
                      sx={{
                        position: "relative",
                        top: "-211px",
                        left: "94%",
                        fontSize: "large",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="step-btn-content">
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setOpenModal(true);
                    setModalMiddleStep(false);
                  }}
                >
                  Back
                </button>
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setModalStepTwo(true);
                    setModalMiddleStep(false);
                  }}
                  disabled={chooseOption == 0}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <button
            className="close-modal"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>
      {/*  Third Step  */}
      <div className="App">
        <Modal
          isOpen={modalStepTwo}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <div>
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#e33f5b",
                }}
              >
                Meeting Information
              </h3>
            </div>
            <div>
              <Stepper
                alternativeLabel
                activeStep={1}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="content-form">
              {chooseOption == 1 && (
                <div>
                  <div>
                    <h3 style={{ textAlign: "center" }}>URL Store</h3>
                    <List
                      style={{
                        marginLeft: "15%",
                        marginRight: "15%",
                        textAlign: "center",
                        marginBottom: "20px",
                      }}
                    >
                      {items?.map((item, index) => {
                        return (
                          <ListItem key={index}>
                            <Checkbox />
                            <ListItemAvatar>
                              <Avatar>
                                <img src={item.url} />
                                {/* <img src={item.smallThumbnailUrl} /> */}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={item.name}
                              secondary={`$${item.price}`}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </div>
                </div>
              )}

              {chooseOption == 2 && (
                <div>
                  <div>
                    <h3 style={{ textAlign: "center" }}>Search Products</h3>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <input
                        type="search"
                        className="input-credentials"
                        onChange={(e) => setFilterProducts(e.target.value)}
                      />
                    </div>
                    <div className="content-product-list">
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        {items?.map((item, index) => {
                          return (
                            <ListItem key={index}>
                              <Checkbox
                                key={item.id}
                                // onClick={() => updateProductList(item.id)}
                                checked={productList.includes(item.id)}
                              />
                              <ListItemAvatar>
                                <Avatar>
                                  <img src={item.smallThumbnailUrl} />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={item.name}
                                secondary={`$${item.price}`}
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </div>
                  </div>
                </div>
              )}

              <div className="step-btn-content">
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setModalMiddleStep(true);
                    setModalStepTwo(false);
                  }}
                >
                  Back
                </button>
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    generateMeeting();
                    setModalStepTwo(false);
                    setConfirmModal(true);
                  }}
                  disabled={chooseOption == 1 && !url}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          {/* <button
            className="close-modal"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button> */}
        </Modal>
      </div>
      {/*  Generate Meeting  */}
      <div className="App">
        <Modal
          isOpen={confirmModal}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <div>
              <h1 style={{ textAlign: "center", color: "e33f5b" }}>
                Meeting Information
              </h1>
            </div>
            <Stepper
              alternativeLabel
              activeStep={2}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className="content-form">
              <div>
                <div>
                  <h2 style={{ fontWeight: "bold" }}>{topic}</h2>
                </div>
              </div>
              <div>
                <div style={{ textAlign: "center" }}>
                  <label>Meeting Invitation</label>
                </div>
                <div class="form-inline">
                  {!loading && (
                    <input
                      type="text"
                      className="input-credentials share-meeting"
                      id="txtLinkMeeting"
                      disabled={true}
                      value={`${window.location.origin}/${idMeeting}${
                        url ? `?loadIframe=` + url : ""
                      }`}
                    />
                  )}
                  {loading && (
                    <Skeleton variant="rounded" width={320} height={30} />
                  )}

                  <button
                    className="btn-personalized"
                    onClick={(e) => {
                      copyInvitation();
                    }}
                  >
                    {!copied ? "Copy Link" : "Copied"}
                  </button>
                </div>
              </div>
              <div>
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    openMeeting();
                  }}
                >
                  Start Meeting
                </button>
              </div>
            </div>
          </div>

          {/* <button
            className="close-modal"
            onClick={() => {
              setConfirmModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button> */}
        </Modal>
      </div>

      {/* Set New Meeting */}
      <div className="App">
        <Modal
          isOpen={confirmModal}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <div>
              <h1 style={{ textAlign: "center", color: "e33f5b" }}>
                Meeting Information
              </h1>
            </div>
            <Stepper
              alternativeLabel
              activeStep={2}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className="content-form">
              <div>
                <div>
                  <h2 style={{ fontWeight: "bold" }}>{topic}</h2>
                </div>
              </div>
              <div>
                <div style={{ textAlign: "center" }}>
                  <label>Meeting Invitation</label>
                </div>
                <div class="form-inline">
                  {!loading && (
                    <input
                      type="text"
                      className="input-credentials share-meeting"
                      id="txtLinkMeeting"
                      disabled={true}
                      value={`${window.location.origin}/${idMeeting}${
                        url ? `?loadIframe=` + url : ""
                      }`}
                    />
                  )}
                  {loading && (
                    <Skeleton variant="rounded" width={320} height={30} />
                  )}

                  <button
                    className="btn-personalized"
                    onClick={(e) => {
                      copyInvitation();
                    }}
                  >
                    {!copied ? "Copy Link" : "Copied"}
                  </button>
                </div>
              </div>
              <div>
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    openMeeting();
                  }}
                >
                  Start Meeting
                </button>
              </div>
            </div>
          </div>

          {/* <button
            className="close-modal"
            onClick={() => {
              setConfirmModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button> */}
        </Modal>
      </div>
    </>
  );
}

export default App;
