import { statusValidation } from "../../utils/mockvalidation";

export const getUserStatus = async (token, sub) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      comp: process.env.REACT_APP_TENANT,
    },
  };
  const res = await fetch(
    `https://companduser-develop.herokuapp.com/users/${sub}?tenant=${process.env.REACT_APP_TENANT}`,
    options
  );

  const json = await res.json();

  if (!res.ok) {
    return { value: false };
  }

  if (res.ok) {
    return {
      value: json?.status?.toLowerCase() != statusValidation.USER_STATUS,
    };
  }
};
