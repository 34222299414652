import React from "react";
import "./index.scss";
import { Box, Typography, Link } from "@mui/material";

export const CardStats = (props) => {
  return (
    <Box className="dashboard-card-stats-container">
      <Box className="dashboard-card-meeting-container-header">
        <Typography variant="h4" fontWeight="bold">
          {props.title}
        </Typography>
      </Box>
      <Box className="dashboard-card-meeting-container-body">
        <Typography color="#dec567" variant="h2" fontWeight="bold">
          {props.totalValue}
        </Typography>
      </Box>
      <Box className="dashboard-card-meeting-container-footer">
        <Link sx={{ color: "#dec567", fontSize: "12px" }}>{props.details}</Link>
      </Box>
    </Box>
  );
};
