import { useEffect, useState } from "react";
import { ColorModeContext, useMode } from "../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./Topbar";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useAuth0 } from "@auth0/auth0-react";
import { AlertInfo } from "../../components/AlertInfo";
import { getUserStatus } from "../../services/meetings/verify";
import { setGlobalState, useGlobalState } from "../../hooks/state";
import TopbarLite from "./TopBarLite";

const LayoutLite = ({ children }) => {
  const [userStatus] = useGlobalState("userStatus");
  const { user, getAccessTokenSilently } = useAuth0();
  const [isSidebar, setIsSidebar] = useState(false);
  const [theme, colorMode] = useMode();
  const [mobileCollapsed, setMobileCollapsed] = useState(true);

  useEffect(() => {
    const loadUserStatus = async () => {
      const accessToken = await getAccessTokenSilently();
      const resUserStatus = await getUserStatus(accessToken, user?.sub);
      console.log("Verificar el usuario: ", resUserStatus);
      setGlobalState("userStatus", resUserStatus.value);
    };
    loadUserStatus();
  }, []);

  const handleClick = () => {
    setMobileCollapsed(!mobileCollapsed);
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {userStatus && <AlertInfo email={user.email} />}
        <div className="app">
          <main className="content">
            <TopbarLite setIsSidebar={setIsSidebar} onClick={handleClick} />
            {children}
          </main>
        </div>
        <Footer />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default LayoutLite;
