import React from "react";
import { Autocomplete, Stack, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { Box, Button, TextField, MenuItem, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import Header from "../../components/Header";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TuneIcon from "@mui/icons-material/Tune";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { CardDetailMeeting } from "../../components/CardDetailMeeting";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Layout from "../global/Layout";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { DateRangePicker } from "@mui/x-date-pickers-pro";

function Row(props) {
  const { row } = props;
  const [openDetails, setOpenDetails] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenDetails(!openDetails)}
          >
            {openDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="right">{row.bonusId}</TableCell>
        <TableCell align="right">{row.bonusTitle}</TableCell>
        <TableCell align="right">{row.commissionDate}</TableCell>
        <TableCell align="right">{row.amount}</TableCell>
        <TableCell align="right">{row.date}</TableCell>
        <TableCell align="right">{row.description}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openDetails} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Customer ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell align="right">Released</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => {
                    return (
                      <TableRow key={historyRow.id}>
                        <TableCell component="th" scope="row">
                          {historyRow.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {historyRow.nodeId}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {historyRow.fullName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {historyRow.commissionDate}
                        </TableCell>
                        <TableCell>{historyRow.released}</TableCell>
                        <TableCell align="right">
                          {historyRow.transaction}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    bonusId: PropTypes.string.isRequired,
    bonusTitle: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        nodeId: PropTypes.number.isRequired,
        fullName: PropTypes.string.isRequired,
        commissionDate: PropTypes.string.isRequired,
        released: PropTypes.number.isRequired,
        transaction: PropTypes.string.isRequired,
      })
    ).isRequired,
    due: PropTypes.string.isRequired,
    paynow: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

const Recording = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user, getAccessTokenSilently } = useAuth0();
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [valueTab, setValueTab] = useState("1");
  const [meetings, setMeetings] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [allRecordings, setAllRecordings] = useState([]);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const clearFilters = () => {
    setDateRange([null, null]);
  };

  const filterMeetingsByUser = async () => {
    console.log("Testing...");
  };

  useEffect(() => {
    const getAllRecordings = async () => {
      setLoadingInfo(true);
      const start = dayjs()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const end = dayjs()
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");

      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          comp: process.env.REACT_APP_TENANT,
        },
      };
      await fetch(
        `https://meetingssellwithzoom-develop.herokuapp.com/recordingsbyuser?from_=${start}&to=${end}`,
        options
      )
        .then((res) => res.json())
        .then((res) => {
          setAllRecordings(res.meetings);
          setLoadingInfo(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingInfo(false);
        });
    };

    const loadUserSettings = async () => {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          comp: process.env.REACT_APP_TENANT,
        },
      };

      fetch(
        `https://meetingssellwithzoom-develop.herokuapp.com/recordingsbyuser?from_=2023-04-01&to=2023-04-05`,
        options
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setLoadingInfo(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoadingInfo(false);
        });
    };
    loadUserSettings();
  }, []);

  return (
    <Layout>
      <Box m="20px">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Header title="Zoom Recordings" />
        </Box>
        {!loadingInfo && (
          <Box>
            <Box className="tab-meetings-dashboard">
              <TabContext value={valueTab ? valueTab : "1"}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <TabList
                    onChange={handleChangeTab}
                    aria-label="lab API tabs example"
                    sx={{
                      "& button": {
                        borderTopLeftRadius: 2,
                        borderTopRightRadius: 2,
                      },
                      "& button:hover": { backgroundColor: "#D9F1F7" },
                      "& button:focus": { backgroundColor: "#D9F1F7" },
                      "& button.Mui-selected": {
                        backgroundColor: "#D9F1F7",
                        color: "#000000 !important",
                      },
                      "& .MuiTabs-flexContaine": {
                        height: "50px",
                      },
                      "& .MuiButtonBase-root": {
                        fontSize: "12px !important",
                        width: "200px",
                        height: "50px",
                      },
                    }}
                  >
                    <Tab
                      label={`Recordings`}
                      value="1"
                      style={{
                        width: "200px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#06213b",
                        textAlign: "center",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                        paddingLeft: "40px",
                        paddingRight: "40px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      focusVisibleClassName="focus-tab"
                    ></Tab>
                    {/* <Tab
                label="Company Commissions"
                value="3"
                style={{
                  width: "33%",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#06213b",
                  textAlign: "center",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
                focusVisibleClassName="focus-tab"
              /> */}
                  </TabList>
                </Box>
                <TabPanel value="1" className="upcoming-meetings-container">
                  <Box m="0 0 0 0" height="auto">
                    <Box className="select-dates-filter-container">
                      <Typography
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        variant="h5"
                        fontFamily="inherit"
                        fontWeight="bold"
                        gap="15px"
                      >
                        <TuneIcon fontSize="large" />
                        Zoom Recordings Filter
                      </Typography>

                      <LocalizationProvider
                        sx={{ width: "50%" }}
                        dateAdapter={AdapterDayjs}
                        localeText={{ start: "From", end: "Until" }}
                      >
                        <DateRangePicker
                          value={dateRange}
                          id="dashboard-meetings-date-filter"
                          onChange={(newDates) => {
                            setDateRange(newDates);
                          }}
                          renderInput={(startProps, endProps) => (
                            <React.Fragment>
                              <TextField
                                style={{ height: "10px !important" }}
                                {...startProps}
                              />
                              <Box sx={{ mx: 2 }}> to </Box>
                              <TextField {...endProps} />
                            </React.Fragment>
                          )}
                        />
                      </LocalizationProvider>
                      <Button
                        className="invite-meeting-tab"
                        style={{ marginLeft: "5px", height: "30px" }}
                        onClick={filterMeetingsByUser}
                      >
                        Apply
                      </Button>
                    </Box>
                    {meetings?.map((meet, index) => {
                      const dateStart = new Date(
                        meet?.start_time
                      ).toLocaleTimeString("en-US", {
                        timeZone: meet?.time_zone,
                      });
                      return (
                        <CardDetailMeeting
                          key={index}
                          startDate={new Date(meet?.start_time).toDateString()}
                          meetingTopic={meet?.topic}
                          startTime={
                            [
                              dateStart.split(":")[0],
                              dateStart.split(":")[1],
                            ].join(":") +
                            " " +
                            dateStart.split(" ")[1] +
                            " " +
                            meet?.time_zone?.split("_").join(" ")
                          }
                          meetingID={
                            meet?.join_url
                              ?.split("?")[0]
                              .split("/")
                              .reverse()[0]
                          }
                        ></CardDetailMeeting>
                      );
                    })}
                  </Box>
                </TabPanel>
                <TabPanel value="2" className="upcoming-meetings-container">
                  <Box
                    m="0 0 0 0"
                    height="100%"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1> COMING SOON!</h1>
                  </Box>
                </TabPanel>
                {/* <TabPanel value="3">
            {" "}
            <h1>Coming Soon</h1>
          </TabPanel> */}
              </TabContext>
            </Box>
          </Box>
        )}
        {loadingInfo && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            flexDirection="column"
          >
            <CircularProgress
              style={{ color: "#00000", width: "70px", height: "70px" }}
            />
            <Typography variant="h6" fontWeight="bold">
              Loading...
            </Typography>
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default withAuthenticationRequired(Recording);
