import React from "react";
import Layout from "../global/Layout";
import { Box, TextField, Typography, MenuItem } from "@mui/material";
import Header from "../../components/Header";

import CreditCard from "../../components/CreditCard";
import CardPaymentMethod from "../../components/CollectJsSection/CardPaymentMethods";

export const PaymentCards = () => {
  const handleThankYou = () => {
    console.log("Click");
  };
  return (
    <Layout>
      <Box m="20px">
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <Header title="My Payment Methods" mTValue={true} />
          <Typography
            variant="h5"
            style={{ fontFamily: "inherit", textAlign: "left" }}
          >
            In this section you can add, edit and delete the different payment
            methods that you have registered.
          </Typography>
        </Box>
        
        <Box style={{ width: "100%", display: "flex", marginTop: "15px" }}>
          <Typography variant="h4" fontWeight="bold" fontFamily="inherit">
            Your Cards
          </Typography>
        </Box>
        <Box
          className="credit-cards-container"
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            marginTop: "15px",
          }}
        >
          <CardPaymentMethod
            email="rvenegas@gmail.com"
            name="William Venegas"
            //clearCart={props.clearCart}
          ></CardPaymentMethod>
        </Box>
      </Box>
    </Layout>
  );
};
