import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import ItemPresentation from "../ItemPresentation";
import { useRef } from "react";
import { Button, Box, Typography } from "@mui/material";

export const SliderPresentation = ({
  sliders,
  activeControls,
  activeNavBtn,
}) => {
  const [currentValue, setCurrentValue] = useState(0);

  const handleNext = () => {
    currentValue > 8 ? setCurrentValue(0) : setCurrentValue(currentValue + 1);
    return currentValue;
  };
  const handlePrev = () => {
    currentValue < 0 ? setCurrentValue(8) : setCurrentValue(currentValue - 1);
    return currentValue;
  };

  const sliderRef = useRef(null);
  const handlePrevSlide = (e) => {
    e.preventDefault();
    if (sliderRef?.current) {
      sliderRef.current.prev();
    }
  };

  const handleNextSlide = (e) => {
    e.preventDefault();
    if (sliderRef?.current) {
      sliderRef.current.next();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentValue(parseInt(localStorage.getItem("actual")));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box className="carousel-container">
      <Carousel
        index={currentValue}
        autoPlay={false}
        navButtonsAlwaysVisible={false}
        ref={sliderRef}
        className="carousel-container-live"
        NavButton={({ onClick, className, style, next, prev }) => {
          return (
            <>
              {activeControls && (
                <Button
                  onClick={onClick}
                  className="btn-prev-carrusel-controls"
                  style={{ position: "fixed", top: "545px", color: "#fff" }}
                >
                  {prev && "Previous"}
                </Button>
              )}
              {activeControls && (
                <Button
                  onClick={onClick}
                  className="btn-prev-carrusel-controls"
                  style={{
                    position: "fixed",
                    top: "545px",
                    left: "125px",
                    color: "#fff !important",
                  }}
                >
                  {next && "Next"}
                </Button>
              )}
            </>
          );
        }}
      >
        {sliders.map((item) => (
          <ItemPresentation key={item.id} item={item} />
        ))}
      </Carousel>
    </Box>
  );
};
