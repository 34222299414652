import { Box, Button, Typography } from "@mui/material";
import React from "react";

export const CardLite = ({
  isLoading,
  title,
  detail,
  image,
  onClick,
  textButton,
  status,
  enableClick,
}) => {
  return (
    <Box className="card-lite-container hover-grow">
      <Typography variant="h3" fontWeight="bold">
        {title}
      </Typography>
      <Typography fontSize="14px" color="rgba(0, 0, 0, 0.7)">
        {detail}
      </Typography>
      <img src={image} width="200px" />
      <Box style={{ width: "100%" }}>
        <Button
          onClick={onClick}
          className="card-lite-btn"
          disabled={enableClick}
        >
          {isLoading && !status ? "Loading.." : ""}
          {!isLoading && status ? "Meeting Generated" : ""}
          {!isLoading && !status ? textButton : ""}
        </Button>
      </Box>
    </Box>
  );
};
