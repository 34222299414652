import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Footer } from "../../components/Footer/Footer";

const Universal = () => {
  return (
    <>
      <Box>
        <Box
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box className="card-email-container">
            <Typography fontFamily="inherit" variant="h3" fontWeight="bold">
              ENTER YOUR EMAIL:
            </Typography>
            <Box className="enter-email-input-container">
              <input
                className="enter-email-input"
                placeholder="rvenegas@gmail.com"
              />
            </Box>
            <Box style={{ marginTop: "20px" }}>
              <Button className="invite-meeting-tab">Continue</Button>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Universal;
