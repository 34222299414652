import React, { useState, useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import ItemPresentation from "../ItemPresentation";
import { useRef } from "react";
import { Button, Box, Typography } from "@mui/material";
import MyTimer from "../../scenes/myTimer";

const beautyProducts = [
  "Take care of your skin with our antioxidant facial serum. Designed to reduce the appearance of wrinkles, this nutrient-rich serum hydrates and protects your skin from signs of aging.",
  "Get a natural glow with our liquid foundation. Easy to apply and lightweight, our liquid foundation formula will give you a fresh and radiant look all day.",
  "Want to achieve flawless skin? Try our coconut oil and aloe vera facial cleanser. It deeply cleanses and softens your skin without leaving residue or dryness.",
  "Give your eyes a rejuvenated look with our under-eye concealer. With a full-coverage formula, it helps you conceal those dark circles for a fresh and luminous appearance.",
  "Worried about fine lines and wrinkles? Try our eye serum. Formulated with natural ingredients, it helps to hydrate, reduce dark circles, and smooth out fine lines.",
  "Keep your lips soft and nourished with our hydrating lip balm. Made with organic ingredients and essential oils, this balm is perfect for keeping your lips healthy and smooth.",
  "Give your hair a fuller and voluminous look with our collagen shampoo and conditioner. Infused with collagen and biotin, it helps to improve the strength and texture of your hair.",
  "Get rid of dark spots and discoloration with our skin whitening serum. With a gentle yet effective formula, it helps to reduce the appearance of dark spots and give your skin a more even look.",
  "Want a natural and long-lasting makeup look? Try our matte finish compact powder. With a lightweight and easy-to-apply formula, it helps to keep your skin looking fresh and matte all day.",
];

export const SliderHost = ({ sliders, activeControls, activeNavBtn }) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [nextSlide, setNextSlide] = useState(1);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [reloadTimer, setReloadTimer] = useState(false);

  const checkReloadTimer = () => {
    setReloadTimer(true);
  };

  const updateDate = () => {
    setCurrentTime(new Date());
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateDate();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const checkCurrentNextValue = () => {
    if (currentValue + 1 > 8) {
      localStorage.setItem("actual", 0);
      setCurrentValue(0);
    } else {
      localStorage.setItem("actual", currentValue + 1);
      setCurrentValue(currentValue + 1);
    }
  };

  const checkCurrentPreviousValue = () => {
    if (currentValue - 1 < 0) {
      localStorage.setItem("actual", 8);
      setCurrentValue(8);
    } else {
      localStorage.setItem("actual", currentValue - 1);
      setCurrentValue(currentValue - 1);
    }
  };

  const checkPreviousSlide = () => {
    nextSlide - 1 < 0 ? setNextSlide(8) : setNextSlide(nextSlide - 1);
  };

  const checkNextSlide = () => {
    nextSlide + 1 > 8 ? setNextSlide(0) : setNextSlide(nextSlide + 1);
  };

  return (
    <Box className="container-carrusel-first-column">
      <Carousel
        index={currentValue}
        autoPlay={false}
        navButtonsAlwaysVisible={false}
        className="carrusel-container-dev"
        NavButton={({ onClick, className, style, next, prev }) => {
          // Other logic

          return <></>;
        }}
      >
        {sliders.map((item) => (
          <ItemPresentation key={item.id} item={item} />
        ))}
      </Carousel>
      <Box className="sub-slider-content">
        <img
          src={sliders[nextSlide]?.reference}
          width="200px"
          className="img-sub-slider-container"
        />
        <Box className="stats-container" display="flex" flexDirection="column">
          <Typography fontSize="20px" fontWeight="500" textAlign="left">
            <span style={{ fontWeight: "bold" }}>Slide:</span>{" "}
            <span style={{ color: "#8e8e8e" }}>{currentValue} of 9</span>
          </Typography>

          <Typography fontSize="20px" fontWeight="500" textAlign="left">
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Time:</span>{" "}
            <span style={{ color: "#8e8e8e" }}>
              {currentTime.toLocaleString().split(",")[1]}
            </span>
          </Typography>
          <MyTimer onClick={reloadTimer} />
          <Box style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            <Button
              className="btn-host-controls"
              onClick={() => {
                checkCurrentPreviousValue();
                checkPreviousSlide();
              }}
            >
              Previous
            </Button>

            <Button
              className="btn-host-controls"
              onClick={() => {
                checkCurrentNextValue();
                checkNextSlide();
              }}
            >
              Next
            </Button>
            <Button
              className="btn-host-controls-participant"
              onClick={() => {
                window.open(
                  "/presentation",
                  "Presentation",
                  "width=424, height=330, top=" +
                    (window.screen.height - 300) +
                    ", left=" +
                    (window.screen.width - 400)
                );
                localStorage.setItem("actual", currentValue);
                document.querySelector('[title="Share Screen"]').click();
              }}
            >
              Share
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
