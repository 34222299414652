export const generateMeeting = async (name, token) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      comp: process.env.REACT_APP_TENANT,
    },
    body: JSON.stringify({
      topic: `${name}'s meeting`,
      settings: {},
      url_store: null,
      cohost: [],
      type: 1,
    }),
  };

  const res = await fetch(
    `${process.env.REACT_APP_MEETING_SELL_WITH_ZOOM}/meeting`,
    options
  );
  const json = await res.json();
  if (!res.ok) {
    return { error: "Error Generating Meeting" };
  }

  if (res.ok) {
    return { meetingInfo: json };
  }
};
