import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";
import Modal from "react-modal";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { isMobile } from "react-device-detect";
import "./App.css";
import "./zoom.css";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import Typography from "@mui/material/Typography";
import { QRCode } from "react-qrcode-logo";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { FloatCloseBtn } from "./components/FloatCloseBtn";
import Checkout from "./components/Checkout";
import TopHeader from "./components/TopHeader";
import html2canvas from "html2canvas";
import { Box, Checkbox } from "@mui/material";
import ShareMeeting from "./components/ShareMeeting";

let socket;

Modal.setAppElement("#root");

function WKMeeting() {
  const client = ZoomMtgEmbedded.createClient();
  const [iframe, setIframe] = useState(null);
  const [active, setActive] = useState(false);
  const [choose, setChosse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [items, setitems] = useState([]);
  const [userName, setUserName] = useState(null);
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState(null);
  const [cart, setCart] = useState([]);
  const [totalCart, setTotalCart] = useState(0);
  const [meetingsDetails, setMeetingDetails] = useState(null);
  const [productsIDs, setProductsIDS] = useState([]);
  const [fullName, setFullName] = useState(null);
  const [successOrder, setSuccessOrder] = useState(false);
  const [affiliateID, setAffiliateID] = useState("");
  const [totalProducts, setTotalProducts] = useState([]);
  const [openModalShare, setOpenModalShare] = useState(false);
  // View Depending Type of Subscription
  const [showSingleSub, setShowSingleSub] = useState(true);
  const [copiedLink, setCopiedLink] = useState(false);
  const [copiedQR, setCopiedQR] = useState(false);
  // Listen PostMSG
  const { user, getAccessTokenSilently, isLoading } = useAuth0();
  const [getBasicRole, setGetBasicRole] = useState(null);
  // ID
  let { meetingNumber } = useParams();

  let urlMeeting = params.loadIframe;

  let subtitle;
  // Modal
  const [openModal, setOpenModal] = useState(true);

  const handleOpenModal = () => {
    setOpenModalShare(true);
  };

  const openNewTab = (url) => {
    window.open(url, "_blank");
  };

  const copyToClipboardQR = () => {
    const qr = document.getElementById("codeQr");
    html2canvas(qr).then((canvas) => {
      canvas.toBlob((blob) =>
        navigator.clipboard
          .write([new ClipboardItem({ "image/png": blob })])
          .then(() => {
            setCopiedQR(true);
            console.log("El objeto QR se copió correctamente al portapapeles");
          })
          .catch((error) => {
            console.error(
              "Error al copiar el objeto QR al portapapeles:",
              error
            );
            setCopiedQR(false);
          })
      );
    });
  };

  const copyText = (meeting_id) => {
    navigator.clipboard
      .writeText(window.location.origin + "/" + `${meeting_id}`)
      .then(() => {
        setCopiedLink(true);
      })
      .catch((err) => {
        setCopiedLink(false);
        console.log("Something went wrong", err);
      });
  };

  const facebook = "/assets/images/virtual_skin/fb.svg";
  const telegram = "/assets/images/virtual_skin/tel.svg";
  const msjText = "/assets/images/virtual_skin/msj.svg";
  const tw = "/assets/images/virtual_skin/tw.svg";
  const emailAsset = "/assets/images/virtual_skin/mail.svg";
  // Zoom Credentials Form
  const [inputEmail, setInputEmail] = useState(null);
  const [inputUserName, setInputUserName] = useState();

  // Zoom Host Options
  const [viewHostOptions, setViewHostOptions] = useState(false);

  // Zoom Ready Presentation
  const [viewZoomRP, setViewZoomRP] = useState(false);
  // Create a Bundle
  const [viewCreateBundle, setViewCreateBundle] = useState(false);
  // Enroll Now
  const [viewEnrollNow, setViewEnrollNow] = useState(false);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  const [showCheckOut, setShowCheckOut] = useState(false);
  const [itemSelected, setitemSelected] = useState([]);
  const [itemId, setitemId] = useState([]);
  const [globalIdUser, setGlobalIDUser] = useState(null);

  // Verify
  const [isVerifiying, setIsVerifiying] = useState(true);

  setTimeout(function () {
    setIsVerifiying(false);
  }, 3000);

  const clearCart = () => {
    setCart([]);
    setitemId([]);
  };

  /* const connetSocket = () => {
    socket = io.connect(
      "ws://companduser-develop.herokuapp.com/" + "ws/" + meetingNumber,
      {
        path: `/ws/${meetingNumber}`,
        transports: ["websocket", "polling"],
        query: { foo: "bar" },
      }
    );

    socket.send(
      "message",
      {
        host: true,
        meetingid: 12345,
        mainproduct: 455,
        productsactive: [4465456, 56454, 564654, 6465465],
      },
      (response) => {
        console.log("Connected", response);
      }
    );
  }; */

  useEffect(() => {
    setLoading(true);
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer public_z7gaHztX8yD8gwEg8RH2MEzAT65xpFYC`,
        "Content-Type": "application/json",
      },
    };

    //productsIDs.length > 0 &&
    fetch(`https://app.ecwid.com/api/v3/84085268/products`, options)
      .then((response) => response.json())
      .then((response) => {
        // console.log("response: ", response);
        let arrayItems = [];
        setTotalProducts([]);
        response.items.forEach((element) => {
          if (element.name === undefined) {
            // console.log("viene un producto sin name");
          } else {
            setTotalProducts((totalProducts) => [...totalProducts, element]);
            if (productsIDs.includes(element.id)) arrayItems.push(element);
          }
        });
        setitems(arrayItems);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [productsIDs]);

  useEffect(() => {
    const loadUserInfo = async (id) => {
      const optionsInfo = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          comp: process.env.REACT_APP_TENANT,
        },
      };

      fetch(
        `https://companduser-develop.herokuapp.com/users/${id}?tenant=${process.env.REACT_APP_TENANT}`,
        optionsInfo
      )
        .then((response) => response.json())
        .then((response) => {
          setEmail(response?.email);
          setInputEmail(response?.email);
          setInputUserName(response?.username);
          setFullName(response?.name);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    user?.sub && loadUserInfo(user?.sub);
  }, [user?.sub]);

  useEffect(() => {
    const loadMeetingInfo = async () => {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          comp: process.env.REACT_APP_TENANT,
        },
      };

      fetch(
        `https://meetingssellwithzoom-develop.herokuapp.com/meeting/${meetingNumber}`,
        options
      )
        .then((response) => response.json())
        .then((response) => {
          setAffiliateID(response.id_user);
          setMeetingDetails(response);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const loadMeetingInfoBackZoom = async () => {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(
        `https://backzoom-develop.herokuapp.com/meetingDB/${meetingNumber}?tenant=${process.env.REACT_APP_TENANT}`,
        options
      )
        .then((response) => response.json())
        .then((response) => {
          setGlobalIDUser(response?.id_user);
          const productsResponse =
            response?.product_ids?.length > 0
              ? response.product_ids.split(",")
              : [];
          productsResponse.map((product) =>
            setProductsIDS((productsIDs) => [...productsIDs, parseInt(product)])
          );
        })
        .catch((err) => {
          console.error(err);
        });
    };
    loadMeetingInfo();
    loadMeetingInfoBackZoom();
  }, [meetingNumber]);

  useEffect(() => {
    //setUserName(process.env.REACT_APP_USERNAME);
    //setEmail(process.env.REACT_APP_EMAIL);
    setGetBasicRole(
      user
        ? user["http://locahost:5000/roles"]?.includes("BASIC") ||
            user["http://locahost:5000/roles"]?.includes("VENTURE")
        : true
    );
    setRole(process.env.REACT_APP_ROLE);
    urlMeeting && setChosse(2);
    urlMeeting && setIframe(urlMeeting);
    console.log("SharedArrayBuffer: ", typeof SharedArrayBuffer === "function");
  }, []);

  const handleCloseCheckOut = () => {
    setChosse(1);
  };

  var signatureEndpoint = "https://newsignature.herokuapp.com/";
  //var signatureEndpoint = "https://040854372081.ngrok.app";
  var sdkKey = "7VDgcS7y8ykARL8reNyK88zVgBXYGL0iobcY";
  //var sdkKey = "iv36hTjT6KwssNO1FGGTw";
  // var passWord = "267777";
  var passWord = "";
  var registrantToken = "";

  const handleModalShare = () => {
    setOpenModalShare(true);
  };

  const checkVariables = () => {
    if (inputEmail != email) {
      setRole(0);
      setEmail(inputEmail);
      setUserName(inputUserName);
    } else {
      setViewHostOptions(true);
    }
  };

  function getSignature(e) {
    if (inputEmail != email) {
      setRole(0);
      setEmail(inputEmail);
      setUserName(inputUserName);
    } else {
      setViewHostOptions(true);
    }
    e.preventDefault();

    fetch(signatureEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: inputEmail == email ? role : 0,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        startMeeting(response.signature);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const openCheckOut = () => {
    setShowCheckOut(true);
  };

  const closeCheckOut = () => {
    setShowCheckOut(false);
  };

  const loadIframe = () => {
    setActive(true);
  };

  const chooseStore = () => {
    setActive(true);
    setChosse(2);
  };

  const chooseCatalog = () => {
    setActive(true);
    setChosse(2);
  };

  function startMeeting(signature) {
    let meetingSDKElement = document.getElementById("meetingSDKElement");
    let meetingSDKChatElement = document.getElementById(
      "meetingSDKChatElement"
    );
    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: "en-US",
      customize: {
        video: {
          popper: {
            disableDraggable: true,
          },
          isResizable: false,
          viewSizes: {
            default: {
              width: isMobile ? 400 : 875,
              height: 200,
            },
            ribbon: {
              width: 300,
              height: 500,
            },
          },
        },
        meetingInfo: [
          "topic",
          "host",
          "mn",
          "pwd",
          "telPwd",
          "invite",
          "participant",
          "dc",
          "enctype",
        ],
        toolbar: {
          buttons: [
            {
              text: "Custom Button",
              className: "CustomButton",
              onClick: () => {
                console.log("custom button");
              },
            },
          ],
        },
        participants: { disableDraggable: false },
      },
    });

    client.join({
      disableCORP: !window.crossOriginIsolated,
      helper: "./helper.html",
      sdkKey: sdkKey,
      signature: signature,
      meetingNumber: meetingNumber,
      password: passWord,
      userName: inputUserName,
      userEmail: inputEmail,
      tk: registrantToken,
      success: (success) => {
        console.log(success);
      },
      error: (error) => {
        console.log("error");
        console.log(error);
      },
    });

    client.on("connection-change", (e) => {
      if (e.state == "Connected") {
        return new Promise((resolve, reject) => {
          let ribbon = document.querySelector('button[title="More"]');

          if (ribbon) {
            ribbon.click();
            console.log("-- switching to speak view --");

            return resolve(true);
          }

          return reject(true);
        })
          .then((r) => {
            let resolve = document.getElementById(
              "menu-list-icon-more"
            ).firstChild;
            if (resolve) {
              console.log("Test Resolve: ", resolve);
              resolve.click();
            }
          })
          .catch((error) => {
            console.log("--- error when switching to speak view --> ", error);
          });
      }
    });
  }

  const updateCart = (item) => {
    setCart((cart) => [...cart, item]);
  };

  const handleChangeZoomRP = (event) => {
    let event_ = event.target.checked;
    console.log("Status Enroll Now: ", event_);
    setViewZoomRP(event_);
    // if(event_){
    //   let url_zoom_rp = "http://localhost:5000/presentations";
    //   window.open(url_zoom_rp, '_blank');
    // }
  };

  const handleChangeCreateBundle = (event) => {
    let event_ = event.target.checked;
    console.log("Status Create a Bundle: ", event_);
    setViewCreateBundle(event_);
  };

  const handleChangeEnrollNow = (event) => {
    let event_ = event.target.checked;
    console.log("Status Enroll Now: ", event_);
    setViewEnrollNow(event_);
  };

  useEffect(() => {
    setTotalCart(0);
    cart.map((elem) => setTotalCart((totalCart) => totalCart + elem.price));
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const checkProductSafe = (id) => {
    setitemSelected();
  };

  return (
    <>
      <TopHeader name={fullName}></TopHeader>
      {showCheckOut && (
        <Checkout
          products={cart}
          onClick={closeCheckOut}
          amount={totalCart}
          totalItems={cart.length}
          affiliateId={affiliateID}
          clearCart={clearCart}
        ></Checkout>
      )}
      <div className="App">
        <main className="main-container-meeting">
          {showSingleSub && (
            <div className="row container-entire-meeting">
              <div className="first-column">
                <div id="meetingSDKElement"></div>
                <div id="meetingSDKChatElement"></div>
                {/*  {user?.sub == globalIdUser && (
                  <ZoomReadyPresentation meetingId={meetingNumber} />
                )} */}
                {user?.sub == globalIdUser && (
                  <Box className="topic-share-container">
                    <Typography color="#412770" variant="h4" fontWeight="bold">
                      Topic: {meetingsDetails?.topic}
                    </Typography>
                    <Box>
                      <ShareMeeting onClick={handleModalShare} />
                    </Box>
                  </Box>
                )}
                {user?.sub == globalIdUser && !getBasicRole && (
                  <Box className="host-controls-content">
                    <Typography variant="h5" fontWeight="bold">
                      Host Shopping Controls
                    </Typography>
                  </Box>
                )}
                {user?.sub == globalIdUser && !getBasicRole && (
                  <Box className="content-check-uncheck">
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      style={{ color: "#8ac0d1" }}
                    >
                      Check/Uncheck to display
                    </Typography>
                    <Box className="container-host-controls">
                      {totalProducts.map((item) => {
                        return (
                          <Box className="item-host-controls">
                            <Checkbox
                              id={item.id}
                              checked={
                                productsIDs.includes(item.id) ? true : false
                              }
                            />
                            <img width="70px" src={item.smallThumbnailUrl} />
                            <Box className="item-host-controls-details">
                              <Typography fontWeight="550">
                                {item.name}
                              </Typography>
                              <Typography>
                                {item.defaultDisplayedPriceFormatted}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                )}
              </div>
              {!isMobile && false && (
                <div className="second-column">
                  {/* <div style={{ display: viewEnrollNow ? "block" : "none" }}>
                <h3 style={{ fontWeight: "bold" }}>Enroll Now</h3>
                <p>Details Enroll Now</p>
                <button className="btnChoose-black btn-personalized">
                  Action
                </button>
                <hr></hr>
              </div> */}
                  {choose == 1 && active && (
                    <>
                      {successOrder ? (
                        <div
                          styles={{
                            border: 0,
                            boxShadow: "rgb(0 0 0 / 24%) 0px 3px 8px",
                          }}
                        >
                          <h2>Success Order</h2>
                        </div>
                      ) : (
                        <Iframe
                          url="https://stagingwave4.com/"
                          width="100%"
                          height="110%"
                          id=""
                          crossorigin="anonymous"
                          allow="shared-workers"
                          loading="eager"
                          className=""
                          display="block"
                          position="relative"
                          styles={{
                            border: 0,
                            boxShadow: "rgb(0 0 0 / 24%) 0px 3px 8px",
                          }}
                        />
                      )}
                      {/* <Checkout></Checkout> */}
                      <FloatCloseBtn onClick={closeCheckOut}></FloatCloseBtn>
                    </>
                  )}
                  {choose == 2 && !getBasicRole && (
                    <div className="products-content">
                      {items.map((item) => {
                        return (
                          <div className="card-product">
                            <div className="card-product-image">
                              <img
                                className="width-products"
                                src={item.thumbnailUrl}
                              />
                            </div>
                            <div>
                              <Typography
                                variant="h5"
                                sx={{ padding: "5px 3px" }}
                              >
                                {item.name}
                              </Typography>
                              <Typography
                                variant="h4"
                                textAlign="center"
                                fontWeight="bold"
                                className="card-price"
                              >
                                ${item.price}
                              </Typography>
                              <div className="buttons-container">
                                <button
                                  className="add-bag"
                                  onClick={() => {
                                    updateCart(item);
                                    setitemId([...itemId, item.id]);
                                  }}
                                >
                                  Add to bag
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {choose == 2 && !getBasicRole && (
                    <div className="float" onClick={openCheckOut}>
                      <img
                        width="30px"
                        className="my-float"
                        src="https://i.imgur.com/TXaAcqV.png"
                      />
                      <div className="content-bag">
                        <p className="bag-empty">
                          {cart.length == 0
                            ? "Bag is empty"
                            : `${cart.length} Items`}
                        </p>
                        <p className="total-color">${totalCart.toFixed(2)}</p>
                      </div>
                    </div>
                  )}
                  {choose == 2 && getBasicRole && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="570px"
                      flexDirection="column"
                    >
                      <CircularProgress
                        style={{
                          color: "#00000",
                          width: "100px",
                          height: "100px",
                        }}
                      />
                      <Typography variant="h6" fontWeight="bold">
                        Loading Chat...
                      </Typography>
                    </Box>
                  )}
                </div>
              )}
            </div>
          )}
          {!showSingleSub && (
            <div className="row container-entire-meeting">
              <div className="first-column">
                <div id="meetingSDKElement"></div>
                <div id="meetingSDKChatElement"></div>
                {/* <ZoomReadyPresentation meetingId={meetingNumber} /> */}
                {user?.sub == globalIdUser && (
                  <Box className="topic-share-container">
                    <Typography color="#412770" variant="h4" fontWeight="bold">
                      Topic: {meetingsDetails?.topic}
                    </Typography>
                    <ShareMeeting />
                  </Box>
                )}
              </div>
            </div>
          )}
          {isMobile && (
            <div className="second-column mobile-version">
              <div style={{ display: viewEnrollNow ? "block" : "none" }}>
                <h3 style={{ fontWeight: "bold" }}>Enroll Now</h3>
                <p>Details Enroll Now</p>
                <button className="btnChoose-black btn-personalized">
                  Action
                </button>
                <hr></hr>
              </div>
              {choose == 1 && !active && (
                <>
                  <input
                    onChange={(e) => setIframe(e.target.value)}
                    placeholder="Enter URL"
                    style={{ width: "90%", padding: "5px" }}
                  />
                  <button className="btn-personalized" onClick={loadIframe}>
                    Load Store
                  </button>
                </>
              )}
              {choose == 1 && active && (
                <Iframe
                  url="https://stagingwave4.com/"
                  width="100%"
                  height="520px"
                  id=""
                  className=""
                  crossorigin="anonymous"
                  loading="eager"
                  allow="shared-workers"
                  display="block"
                  position="relative"
                  styles={{ border: 0 }}
                />
              )}
              {choose == 1 && (
                <div className="products-content">
                  {items.map((item) => {
                    return (
                      <div className="card-product">
                        <div className="card-product-image">
                          <img
                            className="width-products"
                            src={item.thumbnailUrl}
                          />
                        </div>
                        <div>
                          <Typography variant="h5" sx={{ padding: "5px 3px" }}>
                            {item.name}
                          </Typography>
                          <p className="card-price">${item.price}</p>
                          <div className="buttons-container">
                            <button
                              className="add-bag"
                              onClick={() => {
                                updateCart(item);
                                setitemId([...itemId, item.id]);
                              }}
                            >
                              Add to bag
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {choose == 2 && !getBasicRole && (
                <div className="float" onClick={openCheckOut}>
                  <img
                    width="30px"
                    className="my-float"
                    src="https://i.imgur.com/TXaAcqV.png"
                  />
                  <div className="content-bag">
                    <p className="bag-empty">
                      {cart.length == 0
                        ? "Bag is empty"
                        : `${cart.length} Items`}
                    </p>
                    <p className="total-color">${totalCart.toFixed(2)}</p>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="App">
            <Modal
              isOpen={openModal}
              //onRequestClose={() => setOpenModal(false)}
              overlayClassName={{
                base: "overlay-base",
                afterOpen: "overlay-after",
                beforeClose: "overlay-before",
              }}
              className={{
                base: "content-base",
                afterOpen: "content-after-max-second",
                beforeClose: "content-before",
              }}
              closeTimeoutMS={500}
            >
              <div className="container-user-credentials">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    padding: "15px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://nblpublicfiles.s3.amazonaws.com/Screenshot+2023-05-17+at+11.54.42+AM.png"
                    width="200px"
                  />
                </div>
                <div>
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    ENTER YOUR CREDENTIALS
                  </Typography>
                  <Typography
                    variant="h5"
                    textAlign="center"
                    padding="0px 14px"
                  >
                    If you enter as a guest you can add the Username with which
                    you want the other people in the meeting to see you.
                  </Typography>
                </div>
                {!isVerifiying && (
                  <div className="content-form">
                    <div style={{ width: "80%" }}>
                      <div>
                        <Typography variant="h5" fontWeight="bold">
                          Name
                        </Typography>
                      </div>
                      <div>
                        <input
                          value={inputUserName || ""}
                          placeholder="e.g. William"
                          onChange={(e) => setInputUserName(e.target.value)}
                          className="input-credentials"
                        />
                      </div>
                    </div>
                    <div style={{ width: "80%" }}>
                      <div>
                        <Typography variant="h5" fontWeight="bold">
                          Email
                        </Typography>
                      </div>
                      <div>
                        <input
                          value={inputEmail || ""}
                          placeholder="e.g. richardvenegas@gmail.com"
                          onChange={(e) => setInputEmail(e.target.value)}
                          className="input-credentials"
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn-personalized"
                        onClick={(e) => {
                          checkVariables();
                          getSignature(e);
                          setOpenModal(false);
                          urlMeeting ? chooseStore() : chooseCatalog();
                        }}
                      >
                        JOIN MEETING
                      </button>
                    </div>
                  </div>
                )}
                {isVerifiying && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    width="100%"
                    flexDirection="column"
                  >
                    <CircularProgress
                      style={{
                        color: "#8B77B6",
                        width: "70px",
                        height: "70px",
                      }}
                    />
                    <Typography variant="h6" fontWeight="bold">
                      Loading...
                    </Typography>
                  </Box>
                )}
              </div>

              {/*  <button
              className="close-modal"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <a className="text-close-modal">X</a>
            </button> */}
            </Modal>
          </div>
          <div className="App">
            <Modal
              isOpen={openModalShare}
              // onRequestClose={() => setConfirmModal(false)}
              overlayClassName={{
                base: "overlay-base",
                afterOpen: "overlay-after",
                beforeClose: "overlay-before",
              }}
              className={{
                base: "content-base",
                afterOpen: "content-after-share",
                beforeClose: "content-before",
              }}
              closeTimeoutMS={500}
            >
              <Box className="share-meeting-link-container">
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  textAlign="left"
                  style={{
                    fontFamily: "inherit",
                    width: "100%",
                    paddingLeft: "30px",
                  }}
                >
                  Easy Share
                </Typography>
                <Box className="share-meeting-input-link">
                  <TextField
                    id="personalized-outlined-basic"
                    label="Invitation Link"
                    variant="outlined"
                    value={window.location + meetingNumber}
                    style={{ width: "70%" }}
                  />
                  <Button
                    className="open-presentation-zrp set-btn-size"
                    onClick={() => copyText(meetingNumber)}
                  >
                    {copiedLink ? "Copied link" : "Copy Invite Link"}
                  </Button>
                </Box>
                <Box className="share-meeting-link">
                  <Box
                    className="share-meeting-link-item"
                    onClick={() =>
                      openNewTab(
                        `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/${meetingNumber}`
                      )
                    }
                  >
                    <img
                      src={facebook}
                      width="50px"
                      height="50px"
                      style={{ borderRadius: "50%" }}
                    />
                    <Typography
                      fontWeight="bold"
                      variant="h6"
                      textAlign="center"
                      style={{ fontFamily: "inherit" }}
                    >
                      Facebook
                    </Typography>
                  </Box>
                  <Box
                    className="share-meeting-link-item"
                    onClick={() => {
                      openNewTab(
                        `https://t.me/share/url?url=${window.location.origin}/${meetingNumber}`
                      );
                    }}
                  >
                    <img
                      src={telegram}
                      width="50px"
                      height="50px"
                      style={{ borderRadius: "50%" }}
                    />
                    <Typography
                      fontWeight="bold"
                      variant="h6"
                      textAlign="center"
                      style={{ fontFamily: "inherit" }}
                    >
                      Telegram
                    </Typography>
                  </Box>
                  <Box
                    className="share-meeting-link-item"
                    onClick={() =>
                      openNewTab(
                        `sms:?&body=${window.location.origin}/${meetingNumber}`
                      )
                    }
                  >
                    <img
                      src={msjText}
                      width="50px"
                      height="50px"
                      style={{ borderRadius: "50%" }}
                    />
                    <Typography
                      fontWeight="bold"
                      variant="h6"
                      textAlign="center"
                      style={{ fontFamily: "inherit" }}
                    >
                      Messages
                    </Typography>
                  </Box>
                  <Box
                    className="share-meeting-link-item"
                    onClick={() =>
                      openNewTab(
                        `https://twitter.com/intent/tweet?url=${window.location.origin}/${meetingNumber}`
                      )
                    }
                  >
                    <img
                      src={tw}
                      width="50px"
                      height="50px"
                      style={{ borderRadius: "50%" }}
                    />
                    <Typography
                      fontWeight="bold"
                      variant="h6"
                      textAlign="center"
                      style={{ fontFamily: "inherit" }}
                    >
                      Twitter
                    </Typography>
                  </Box>
                  <Box
                    className="share-meeting-link-item"
                    onClick={() =>
                      openNewTab(
                        `mailto:info@example.com?&subject=&cc=&bcc=&body=${window.location.origin}/${meetingNumber}`
                      )
                    }
                  >
                    <img
                      src={emailAsset}
                      width="50px"
                      height="50px"
                      style={{ borderRadius: "50%" }}
                    />
                    <Typography
                      fontWeight="bold"
                      variant="h6"
                      textAlign="center"
                      style={{ fontFamily: "inherit" }}
                    >
                      Email
                    </Typography>
                  </Box>
                </Box>
                <Box className="container-choose-copy">
                  <div className="firstPart-copy"></div>
                  <div style={{ textAlign: "center" }}>
                    <span>OR</span>
                  </div>
                  <div className="firstPart-copy"></div>
                </Box>
                <QRCode
                  value={`${window.location.origin}/${meetingNumber}`}
                  size={125}
                  qrStyle="dots"
                  logoPaddingStyle="circle"
                  eyeColor="#000000"
                  id="codeQr"
                />
                <Button
                  className="open-presentation-zrp copy-my-size"
                  onClick={() => copyToClipboardQR()}
                >
                  {copiedQR ? "QR Copied" : "Copy QR"}
                </Button>
              </Box>
              <button
                className="close-modal"
                onClick={() => {
                  setOpenModalShare(false);
                }}
              >
                <a className="text-close-modal">X</a>
              </button>
            </Modal>
          </div>
        </main>
      </div>
    </>
  );
}

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export default WKMeeting;
