import { Box, Typography } from "@mui/material";
import React from "react";

export const Footer = () => {
  return (
    <Box className="footer-main" sx={{ width: "100%", background: "#000", marginTop: "40px" }}>
      <Box
        display="flex"
        justifyContent="center"
        padding="20px"
        alignItems="center"
        gap="10px"
      >
        <img src="https://i.imgur.com/ocLTWwv.png" width="50px" />
        <Typography color="#fff">
          WorkingLive | All rights reserved. | Copyright © 2023.
        </Typography>
      </Box>
    </Box>
  );
};
