import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export const PlanCard = (props) => {
  const saveParamsPayment = (planId, plan) => {
    window.sessionStorage.setItem(
      "set_up_plan_payment",
      JSON.stringify({
        planId: planId,
        plan: plan,
      })
    );
    window.location.replace(`/payment`);
  };

  return (
    <Box
      sx={{ width: "300px", height: "425px" }}
      className={`content-card-plan ${props.selected ? " change-bg-plan" : ""}`}
    >
      <Box className="content-card-plan-box">
        <Typography color="#919194" textAlign="center" variant="h5">
          {props.planTitle}
        </Typography>
        <Typography variant="h3" fontWeight="bold">
          ${props.planTotal}
        </Typography>
        <Typography color="#919194" textAlign="center">
          Per month Billed monthly
        </Typography>
      </Box>
      {props.max && (
        <Box className="content-card-plan-items">
          <Typography
            textAlign="center"
            variant="h6"
            display="flex"
            gap="8px"
            alignItems="center"
          >
            <CheckIcon fontSize="large" sx={{ color: "#73CCE9" }}></CheckIcon>
            Webinars with 500 people
          </Typography>
          <Typography
            textAlign="center"
            variant="h6"
            display="flex"
            gap="8px"
            alignItems="center"
          >
            <CheckIcon fontSize="large" sx={{ color: "#73CCE9" }}></CheckIcon>
            Unlimited Meeting times
          </Typography>
          <Typography
            textAlign="center"
            variant="h6"
            display="flex"
            gap="8px"
            alignItems="center"
          >
            <CheckIcon fontSize="large" sx={{ color: "#73CCE9" }}></CheckIcon>
            Unlimited Cloud Storage
          </Typography>
          <Typography
            textAlign="center"
            variant="h6"
            display="flex"
            gap="8px"
            alignItems="center"
          >
            <CheckIcon fontSize="large" sx={{ color: "#73CCE9" }}></CheckIcon>
            Broadcast to Facebook Live
          </Typography>
        </Box>
      )}
      {props.regular && (
        <Box className="content-card-plan-items">
          <Typography
            textAlign="center"
            variant="h6"
            display="flex"
            gap="8px"
            alignItems="center"
          >
            <CheckIcon fontSize="large" sx={{ color: "#73CCE9" }}></CheckIcon>
            Webinars with 500 people
          </Typography>
          <Typography
            textAlign="center"
            variant="h6"
            display="flex"
            gap="8px"
            alignItems="center"
          >
            <CheckIcon fontSize="large" sx={{ color: "#73CCE9" }}></CheckIcon>
            Unlimited Meeting times
          </Typography>
          <Typography
            textAlign="center"
            variant="h6"
            display="flex"
            gap="8px"
            alignItems="center"
            color="#C9C9C9"
          >
            <CloseIcon fontSize="large" sx={{ color: "#A4A4A4" }}></CloseIcon>
            Unlimited Cloud Storage
          </Typography>
          <Typography
            textAlign="center"
            variant="h6"
            display="flex"
            gap="8px"
            alignItems="center"
            color="#C9C9C9"
          >
            <CloseIcon fontSize="large" sx={{ color: "#A4A4A4" }}></CloseIcon>
            Broadcast to Facebook Live
          </Typography>
        </Box>
      )}
      {props.basic && (
        <Box className="content-card-plan-items">
          <Typography
            textAlign="center"
            variant="h6"
            display="flex"
            gap="8px"
            alignItems="center"
          >
            <CheckIcon fontSize="large" sx={{ color: "#73CCE9" }}></CheckIcon>
            Webinars with 500 people
          </Typography>
          <Typography
            textAlign="center"
            variant="h6"
            display="flex"
            gap="8px"
            alignItems="center"
          >
            <CheckIcon fontSize="large" sx={{ color: "#73CCE9" }}></CheckIcon>
            Unlimited Meeting times
          </Typography>
          <Typography
            textAlign="center"
            variant="h6"
            display="flex"
            gap="8px"
            alignItems="center"
            color="#C9C9C9"
          >
            <CloseIcon fontSize="large" sx={{ color: "#A4A4A4" }}></CloseIcon>
            Unlimited Cloud Storage
          </Typography>
          <Typography
            textAlign="center"
            variant="h6"
            display="flex"
            gap="8px"
            alignItems="center"
            color="#C9C9C9"
          >
            <CloseIcon fontSize="large" sx={{ color: "#A4A4A4" }}></CloseIcon>
            Broadcast to Facebook Live
          </Typography>
        </Box>
      )}
      <Box className="content-card-plan-buy-btn">
        <Button
          className="buy-btn-zoom"
          onClick={() => saveParamsPayment(props.planId, props.planTotal)}
        >
          {props.selected ? "Selected" : "Buy Now"}
        </Button>
      </Box>
    </Box>
  );
};
