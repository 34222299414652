import { Box, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Footer } from "./Footer/Footer";
import "./index.scss";
import { PlanCard } from "./PlanCard/PlanCard";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 54,
  height: 36,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#B01D54",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#fff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 34,
    height: 31,
  },
  "& .MuiSwitch-track": {
    borderRadius: 38 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const Suscriptions = () => {
  return (
    <div className="sub-content-zoom">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "15px",
          flexDirection: "column",
          paddingTop: "50px",
        }}
      >
        <Typography
          variant="h2"
          component="div"
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          Choose the plan that's right for your business
        </Typography>

        <Typography variant="h5" component="div" sx={{ textAlign: "center" }}>
          Choose a monthly or annual plan to start your store off on the right
          foot. Cancel any time. Credit Card Required.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "15px",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "30px",
        }}
      >
        <PlanCard
          planTitle="The original WorkingLive Webinar"
          planTotal={12.99}
          planId="swzpremiummonthly"
          max={true}
        ></PlanCard>
        <PlanCard
          planTitle="The original WorkingLive Webinar"
          planTotal={155.98}
          planId="swzpremiumyear"
          max={true}
        ></PlanCard>
      </Box>
      <Footer></Footer>
    </div>
  );
};
