import React from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const Footer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      className="footer-main"
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "20px 30px",
        background: "#D6C177",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Typography
        variant="h6"
        color="#000000"
        fontWeight="600"
        fontFamily="lato"
      >
        Designed by:
      </Typography>
      <img
        src="https://workinglive.us/wp-content/uploads/2020/07/wl-logo.png"
        width="50px"
      />
      <Typography variant="h6" color="#000000" fontWeight="600">
        {" "}
        for (the) National Business League, Inc. © Copyright 2024
      </Typography>
    </Box>
  );
};

export default Footer;
