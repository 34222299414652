import React from "react";
import { Alert, Box, AlertTitle } from "@mui/material";

export const AlertInfo = ({ email }) => {
  return (
    <Box className="alert-container-email">
      <Alert severity="info" sx={{ fontSize: "12px" }}>
        <AlertTitle sx={{ fontSize: "14px", fontWeight: "bold" }}>
          Info
        </AlertTitle>
        You must accept the ZOOM invitation that you received by email{" "}
        <strong>{email}</strong> in order to use all ZOOM features.
      </Alert>
    </Box>
  );
};
