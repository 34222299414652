import { Box, Button, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const TopbarLite = () => {
  const { logout } = useAuth0();

  const goToMain = () => {
    window.open("http://www.nationalbusinessleague.org/", "_blank");
  };

  return (
    <Box
      className="menu-header-layout top-bar-container-fit-lite"
      display="flex"
      justifyContent="space-between"
      p={2}
    >
      <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <img
          src="https://nationalbusinessleague.org/wp-content/uploads/2022/06/nbl-stl-2022web-1.png"
          width="90px"
          onClick={goToMain}
        />
      </Box>
      <Box
        onClick={goToMain}
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <Typography
          variant="h2"
          fontWeight="bold"
          color="#D6C177"
          style={{ cursor: "pointer" }}
        >
          The National Business League
        </Typography>
        <Typography
          variant="h6"
          fontWeight="600"
          color="#D6C177"
          style={{ cursor: "pointer" }}
        >
          Founded by Dr. Booker T. Washington – August 23, 1900
        </Typography>
      </Box>
      <Box>
        <Button className="logout-btn-lite" onClick={logout}>
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default TopbarLite;
