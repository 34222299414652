import React from "react";
import { Box, Typography } from "@mui/material";

const FifthNote = () => {
  return (
    <Box className="notes-container-zrp">
      <Typography variant="h4" fontWeight="bold">
        Audio Help:
      </Typography>
      <audio
        src="https://marykay.workinglive.us/wp-content/uploads/sites/11/2021/08/Pg-17-Customized-Skin-Care.mp3"
        controls
      />
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">1</span> In addition to the
          amazing TimeWise Miracle Set 3D®, Mary Kay offers many products that
          are customized for specific skin needs.
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">2</span> Mary Kay offers four
          other skin care sets customized for a customer’s individual needs.
          There’s Botanical Effects® for simple beauty, the Clear Proof® Acne
          System for acne-prone skin, TimeWise Repair® for advanced signs of
          aging and Mary Kay Naturally® for natural-certified skin care.
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">3</span> How many of you have
          tried specialized products your girlfriends swear by that just didn’t
          do anything for you? That’s because everyone’s skin is different.
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">4</span> Mary Kay also offers
          an incredible array of targeted skin care solutions for specific
          problem areas. During our individualnconsultation, I can address any
          special skin care concerns you may have.
        </Typography>
      </Box>
      <Box
        style={{
          marginTop: "15px",
          backgroundColor: "#f5dadf",
          padding: "8px",
        }}
      >
        <Typography variant="h6">
          <span style={{ fontWeight: "bold" }}>
            OPTIONAL PRODUCT INFORMATION:
          </span>{" "}
          We want you to feel confident in our Mary Kay Naturally® line, so
          every productis third-party certified as natural according to
          comprehensive standards. The COSMOS standard requires evaluation of
          everything from he sourcing and processing of ingredients to the
          manufacturing of products and packaging.
        </Typography>
      </Box>
    </Box>
  );
};

export default FifthNote;
