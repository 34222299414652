import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import CollectJSSection from "./CollectJsSection";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import { useCreateOrder } from "../../hooks/useOrder";

class InlineCartPageSub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      email: this.props.email,
      phoneNumber: this.props.phoneNumber,
      city: this.props.city,
      address1: this.props.address1,
      session: this.props.session,
      zip: this.props.zip,
      username: this.props.username,
      amount: this.props.amount,
      isSubmitting: false,
      alertMessage: "",
      isLoading: false,
    };
    this.setState = this.setState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.finishSubmit = this.finishSubmit.bind(this);
    this.generateSuscrption = this.generateSuscrption.bind(this);
  }

  componentDidMount() {
    window.CollectJS.configure({
      paymentSelector: "#payButton",
      variant: "inline",
      styleSniffer: true,
      callback: (token) => {
        console.log("Se ejecuta el pago", token);
        this.setState({ isSubmitting: true });
        this.finishSubmit(token);
      },
      fields: {
        ccnumber: {
          placeholder: "0000 0000 0000 0000",
          selector: "#ccnumber",
        },
        ccexp: {
          placeholder: "MM / YY",
          selector: "#ccexp",
        },
        cvv: {
          placeholder: "***",
          selector: "#cvv",
        },
      },
      validationCallback: (field, status, message) => {
        console.log(
          `Detalles: Stados ${status} Mensaje: ${message} y Field: ${field}`
        );
        if (status) {
          if (field == "ccnumber") {
            console.log("Error en el numero de Tarjeta");
          }
          if (field == "ccexp") {
            console.log("Error fecha de expiracion");
          }
          if (field == "ccv") {
            console.log("error CCV");
          }
        }
      },
    });
  }

  async generateSuscrption(payload, sessionToken) {
    console.log("Payload payment Suscription: ", payload);
    this.setState({ isLoading: true });
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(payload),
    };
    fetch(
      `https://companduserback-doq4esxdra-uc.a.run.app/payment_subscriptions`,
      //`https://testpayment.ngrok.io/nmi/set-subscription`,
      options
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        this.setState({ isLoading: false });
        /* window.location.href =
          "https://dev-emnqbzns47fvm8yn.us.auth0.com/continue?state=" +
          getQueryParams("state"); */
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log("Error Suscription: ", err);
      });
  }

  finishSubmit(response) {
    this.setState({ isSubmitting: true });
    this.setState({ isLoading: true });
    const { isSubmitting, alertMessage, ...formData } = { ...this.state };
    formData.token = response.token;
    const sessionToken = this.props.session;
    const payload = {
      BillingInfo: {
        first_name: this.props.firstName,
        last_name: this.props.lastName,
        email: this.props.email,
        username: this.props.username,
        address1: this.props.address1,
        City: this.props.city,
        State: this.props.state,
        zip: this.props.zip,
      },
      PlanInfo: {
        planid: "swzshoppingonly1",
        payment_token: response.token,
      },
    };
    console.log(formData);
    this.setState({
      isSubmitting: false,
    });
    this.generateSuscrption(payload, sessionToken);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    console.log("Enviando el Pago:", this.setState.isSubmitting);
    window.CollectJS.startPaymentRequest();
  }

  render() {
    return (
      <div className="App">
        <Box className="payment-form-container">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              padding: "10px 0px",
              width: "100%",
              justifyContent: "space-between",
              background: "#f1f1f1",
              padding: "10px",
              borderBottom: "1px solid #d5d5d5",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <CreditCardOutlinedIcon fontSize="large" />
              <Typography variant="h5" fontWeight="600">
                Pay with Credit Card
              </Typography>
            </Box>
            <Box>
              <img src="https://i.imgur.com/KtUNWSn.png" width="200px" />
            </Box>
          </Box>

          {this.state.alertMessage && (
            <div className="alert">{this.state.alertMessage}</div>
          )}
          <form
            onSubmit={this.handleSubmit}
            style={{ padding: "0px 10px 10px 10px" }}
          >
            <CollectJSSection />
            <Box
              className="checkout-working-live-btn-checkout"
              style={{ textAlign: "center" }}
            >
              <button
                id="payButton"
                type="submit"
                style={{ background: "#B01D54" }}
                disabled={this.state.isLoading}
              >
                {this.state.isLoading ? (
                  <Typography variant="h5" fontWeight="bold">
                    Loading...
                  </Typography>
                ) : (
                  <Typography variant="h5" fontWeight="bold">
                    Send Pay
                  </Typography>
                )}
              </button>
            </Box>
          </form>
        </Box>
      </div>
    );
  }
}

function getQueryParams(item) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(item);
}

export default InlineCartPageSub;
