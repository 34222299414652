import React from "react";
import { Box, Typography } from "@mui/material";

const ThirdNote = () => {
  return (
    <Box className="notes-container-zrp">
      <Typography variant="h5" fontWeight="bold" textAlign="left">
        <span className="number-detail-italic">1</span> I’ve been building my
        Mary Kay business for ____ months/years. My mission is to
        ________________. My vision is to ________________. And one of my goals
        is to ________________.
      </Typography>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">2</span> By all of you being
          here today, you are helping me fulfill my mission, vision and goals,
          so thank you from the bottom of a grateful heart.
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">3</span> Before I started my
          Mary Kay business …
        </Typography>
        <Typography variant="h4" className="number-detail-italic">
          (add your I-story here)
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">4</span> What I like best about
          my Mary Kay business is ________________.
        </Typography>
      </Box>
      <Box
        style={{
          marginTop: "15px",
          backgroundColor: "#f5dadf",
          padding: "8px",
        }}
      >
        <Typography variant="h6">
          <span style={{ fontWeight: "bold" }}>
            To help identify your “why” and create your I-story
          </span>
          , check out MK University on Mary Kay InTouch® and your Start
          Something Beautiful® magazine.
        </Typography>
      </Box>
    </Box>
  );
};

export default ThirdNote;
