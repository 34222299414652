import React from "react";
import { Box, Typography } from "@mui/material";

const SixthNote = () => {
  return (
    <Box className="notes-container-zrp">
      <Box
        style={{
          marginTop: "15px",
          backgroundColor: "#f5dadf",
          padding: "8px",
        }}
      >
        <Typography
          variant="h5"
          color="#DE6576"
          fontStyle="italic"
          textAlign="left"
          fontWeight="bold"
        >
          1 If you sent a copy of the set sheet:
        </Typography>
        <Typography variant="h5" textAlign="left">
          Great! Everyone take a look at the set sheet I sent in your packet.
        </Typography>
      </Box>
      <Box
        style={{
          marginTop: "15px",
          backgroundColor: "#f5dadf",
          padding: "8px",
        }}
      >
        <Typography variant="h5" textAlign="left">
          As you look over these sets, circle the ones you would like to buy if
          money were no object.
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">2</span> When we speak
          individually, I’ll tell you more about how you could earn some of
          those products for free!
        </Typography>
      </Box>

      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">3</span> Of course, the set we
          tried today was TimeWise Miracle Set 3D®. For the ultimate skin care
          regimen, you can add the TimeWise® Microdermabrasion Plus Set to the
          TimeWise Miracle Set 3D®. Plus, you can add your perfect shade of CC
          Cream.
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">4</span> I’m going to give you
          a minute to look over all the great sets shown here, and please feel
          free to ask me any questions you may have.
        </Typography>
      </Box>
    </Box>
  );
};

export default SixthNote;
