import React from "react";
import { Box, Typography } from "@mui/material";

const FirstNote = () => {
  return (
    <Box className="notes-container-zrp">
      <Typography variant="h5" fontWeight="bold" textAlign="left">
        <span className="number-detail-italic">1</span> Hi, I'd like to thank
        everyone for joining today. For those of you who don’t know me, my name
        is ___________________ , your Mary Kay Independent Beauty Consultant.
      </Typography>
      <Box
        style={{
          marginTop: "15px",
          backgroundColor: "#f5dadf",
          padding: "8px",
        }}
      >
        <Typography
          variant="h5"
          color="#DE6576"
          fontStyle="italic"
          textAlign="left"
          fontWeight="bold"
        >
          If you have a hostess:
        </Typography>
        <Typography variant="h5" textAlign="left">
          I also would like to thank ___________________ (hostess’s name) for
          hosting this party. I reward all of my hostesses with fabulous perks,
          and I‘d be happy to tell you about them at the end of this party.
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">2</span> But first, let me
          share a little about the rest of the party. Today you're going to
          learn about incredible Mary Kay® products for healthy and radiant
          skin, and as we go along, I will also be sharing information about the
          Company as well, so you will get to know what makes the brand so
          special!
        </Typography>
      </Box>
      <Box
        style={{
          marginTop: "15px",
          backgroundColor: "#f5dadf",
          padding: "8px",
        }}
      >
        <Typography
          variant="h5"
          color="#DE6576"
          fontStyle="italic"
          textAlign="left"
          fontWeight="bold"
        >
          If you sent your guests product samples:
        </Typography>
        <Typography variant="h5" textAlign="left">
          Before we start the party, have your product sample packets ready and
          scissors to open them. You need a washcloth, access to water and a
          mirror.
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">3</span> During the party, I
          encourage each of you to like, love or comment in the chat section.
          Also, have a pen close by so you can participate in some fun
          activities. At the end, I will follow up with each of you one-on-one
          for an individual consultation to discuss your specific skin care and
          beauty needs and to answer any questions you may have.
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">4</span> Now let's start with
          the introductions. Tell us your name, a little bit about yourself and
          (if there is a hostess) how you know our hostess. Who would like to go
          first?
        </Typography>
      </Box>
    </Box>
  );
};

export default FirstNote;
