import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { ColorModeContext, tokens } from "../../theme";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useAuth0 } from "@auth0/auth0-react";
import Hamburger from "hamburger-react";
import SearchIcon from "@mui/icons-material/Search";
import ReactSearchBox from "react-search-box";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Topbar = (props) => {
  const handleChangeRoute = () => {
    window.location.href = "/settings";
  };

  const { user, logout, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [meetings, setMeetings] = useState([]);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const backDomain = "https://backzoom-develop.herokuapp.com";
  // List of settings
  const settings = ["Profile", "Account", "Logout"];
  const [isOpen, setOpen] = useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenProfile = () => {
    navigate("/profile");
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnto: window.location.origin + "/plan" } });
  };

  useEffect(() => {
    setMeetings([]);
    JSON.parse(sessionStorage.getItem("my_meetings_by_user"))?.map((meet) => {
      setMeetings((meetings) => [
        ...meetings,
        {
          key: meet?.id,
          value:
            meet?.topic +
            " - " +
            new Date(meet?.start_time).toDateString().toUpperCase() +
            " " +
            `${meet?.status?.toUpperCase()}`,
        },
      ]);
    });
  }, []);

  const handleClick = () => {
    props.onClick();
  };

  return (
    <Box
      className="menu-header-layout top-bar-container-fit"
      display="flex"
      justifyContent="space-between"
      p={2}
    >
      {/* SEARCH BAR */}
      {!isMobile && (
        <Box
          id="search-bar-custom-container"
          display="flex"
          borderRadius="10px"
          sx={{ width: "50%" }}
        >
          <ReactSearchBox
            placeholder="Search Meeting"
            id="my-search-bar"
            className="my-container-search-bar-meeting"
            value="Doe"
            style={{ width: "100%" }}
            data={meetings}
            leftIcon={<SearchIcon style={{ color: "#412770" }} />}
            callback={(record) => console.log(record)}
            onSelect={(e) => console.log(e)}
          />
        </Box>
      )}
      {isMobile && (
        <Box display="flex" borderRadius="3px" sx={{ width: "30%" }}>
          <Hamburger
            toggled={isOpen}
            size={20}
            color="#636363"
            onToggle={props.onClick}
          />
        </Box>
      )}

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => {
            if (setting == "Logout")
              return (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography
                    textAlign="center"
                    fontSize="12px"
                    onClick={handleLogout}
                  >
                    {setting}
                  </Typography>
                </MenuItem>
              );
            else if (setting == "Profile")
              return (
                <MenuItem key={setting} onClick={handleOpenProfile}>
                  <Typography
                    textAlign="center"
                    fontSize="12px"
                    onClick={handleLogout}
                  >
                    {setting}
                  </Typography>
                </MenuItem>
              );
            else {
              return (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center" fontSize="12px">
                    {setting}
                  </Typography>
                </MenuItem>
              );
            }
          })}
        </Menu>
        <IconButton onClick={handleChangeRoute}>
          <SettingsOutlinedIcon fontSize="large" />
        </IconButton>
        <IconButton onClick={handleOpenUserMenu}>
          <PersonOutlinedIcon fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
