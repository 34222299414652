import { tokens } from "../theme";

export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
];

export const mockDataContacts = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    address: "0912 Won Street, Alabama, SY 10001",
    city: "New York",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    address: "1234 Main Street, New York, NY 10001",
    city: "New York",
    zipCode: "13151",
    registrarId: 123512,
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    address: "3333 Want Blvd, Estanza, NAY 42125",
    city: "New York",
    zipCode: "87281",
    registrarId: 4132513,
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    address: "1514 Main Street, New York, NY 22298",
    city: "New York",
    zipCode: "15551",
    registrarId: 123512,
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    address: "11122 Welping Ave, Tenting, CD 21321",
    city: "Tenting",
    zipCode: "14215",
    registrarId: 123512,
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    address: "1234 Canvile Street, Esvazark, NY 10001",
    city: "Esvazark",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    address: "22215 Super Street, Everting, ZO 515234",
    city: "Evertin",
    zipCode: "51523",
    registrarId: 123512,
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    address: "4123 Ever Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 512315,
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    address: "51234 Avery Street, Cantory, ND 212412",
    city: "Colunza",
    zipCode: "111234",
    registrarId: 928397,
  },
  {
    id: 10,
    name: "Enteri Redack",
    email: "enteriredack@gmail.com",
    age: 42,
    phone: "(222)444-5555",
    address: "4123 Easer Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 533215,
  },
  {
    id: 11,
    name: "Steve Goodman",
    email: "stevegoodmane@gmail.com",
    age: 11,
    phone: "(444)555-6239",
    address: "51234 Fiveton Street, CunFory, ND 212412",
    city: "Colunza",
    zipCode: "1234",
    registrarId: 92197,
  },
];

export const mockDataInvoices = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    cost: "21.24",
    phone: "(665)121-5454",
    date: "03/12/2022",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    cost: "1.24",
    phone: "(421)314-2288",
    date: "06/15/2021",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    cost: "11.24",
    phone: "(422)982-6739",
    date: "05/02/2022",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    cost: "80.55",
    phone: "(921)425-6742",
    date: "03/21/2022",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    cost: "1.24",
    phone: "(421)445-1189",
    date: "01/12/2021",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    cost: "63.12",
    phone: "(232)545-6483",
    date: "11/02/2022",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    cost: "52.42",
    phone: "(543)124-0123",
    date: "02/11/2022",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    cost: "21.24",
    phone: "(222)444-5555",
    date: "05/02/2021",
  },
];

export const mockTransactions = [
  {
    txId: "01e4dsa",
    user: "johndoe",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "51034szv",
    user: "goodmanave",
    date: "2022-11-05",
    cost: "200.95",
  },
  {
    txId: "0a123sb",
    user: "stevebower",
    date: "2022-11-02",
    cost: "13.55",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "120s51a",
    user: "wootzifer",
    date: "2019-04-15",
    cost: "24.20",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
];

export const mockBarData = [
  {
    country: "AD",
    "hot dog": 137,
    "hot dogColor": "hsl(229, 70%, 50%)",
    burger: 96,
    burgerColor: "hsl(296, 70%, 50%)",
    kebab: 72,
    kebabColor: "hsl(97, 70%, 50%)",
    donut: 140,
    donutColor: "hsl(340, 70%, 50%)",
  },
  {
    country: "AE",
    "hot dog": 55,
    "hot dogColor": "hsl(307, 70%, 50%)",
    burger: 28,
    burgerColor: "hsl(111, 70%, 50%)",
    kebab: 58,
    kebabColor: "hsl(273, 70%, 50%)",
    donut: 29,
    donutColor: "hsl(275, 70%, 50%)",
  },
  {
    country: "AF",
    "hot dog": 109,
    "hot dogColor": "hsl(72, 70%, 50%)",
    burger: 23,
    burgerColor: "hsl(96, 70%, 50%)",
    kebab: 34,
    kebabColor: "hsl(106, 70%, 50%)",
    donut: 152,
    donutColor: "hsl(256, 70%, 50%)",
  },
  {
    country: "AG",
    "hot dog": 133,
    "hot dogColor": "hsl(257, 70%, 50%)",
    burger: 52,
    burgerColor: "hsl(326, 70%, 50%)",
    kebab: 43,
    kebabColor: "hsl(110, 70%, 50%)",
    donut: 83,
    donutColor: "hsl(9, 70%, 50%)",
  },
  {
    country: "AI",
    "hot dog": 81,
    "hot dogColor": "hsl(190, 70%, 50%)",
    burger: 80,
    burgerColor: "hsl(325, 70%, 50%)",
    kebab: 112,
    kebabColor: "hsl(54, 70%, 50%)",
    donut: 35,
    donutColor: "hsl(285, 70%, 50%)",
  },
  {
    country: "AL",
    "hot dog": 66,
    "hot dogColor": "hsl(208, 70%, 50%)",
    burger: 111,
    burgerColor: "hsl(334, 70%, 50%)",
    kebab: 167,
    kebabColor: "hsl(182, 70%, 50%)",
    donut: 18,
    donutColor: "hsl(76, 70%, 50%)",
  },
  {
    country: "AM",
    "hot dog": 80,
    "hot dogColor": "hsl(87, 70%, 50%)",
    burger: 47,
    burgerColor: "hsl(141, 70%, 50%)",
    kebab: 158,
    kebabColor: "hsl(224, 70%, 50%)",
    donut: 49,
    donutColor: "hsl(274, 70%, 50%)",
  },
];

export const mockPieData = [
  {
    id: "hack",
    label: "hack",
    value: 239,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "make",
    label: "make",
    value: 170,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "go",
    label: "go",
    value: 322,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "lisp",
    label: "lisp",
    value: 503,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "scala",
    label: "scala",
    value: 584,
    color: "hsl(344, 70%, 50%)",
  },
];

export const mockLineData = [
  {
    id: "japan",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: "plane",
        y: 101,
      },
      {
        x: "helicopter",
        y: 75,
      },
      {
        x: "boat",
        y: 36,
      },
      {
        x: "train",
        y: 216,
      },
      {
        x: "subway",
        y: 35,
      },
      {
        x: "bus",
        y: 236,
      },
      {
        x: "car",
        y: 88,
      },
      {
        x: "moto",
        y: 232,
      },
      {
        x: "bicycle",
        y: 281,
      },
      {
        x: "horse",
        y: 1,
      },
      {
        x: "skateboard",
        y: 35,
      },
      {
        x: "others",
        y: 14,
      },
    ],
  },
  {
    id: "france",
    color: tokens("dark").blueAccent[300],
    data: [
      {
        x: "plane",
        y: 212,
      },
      {
        x: "helicopter",
        y: 190,
      },
      {
        x: "boat",
        y: 270,
      },
      {
        x: "train",
        y: 9,
      },
      {
        x: "subway",
        y: 75,
      },
      {
        x: "bus",
        y: 175,
      },
      {
        x: "car",
        y: 33,
      },
      {
        x: "moto",
        y: 189,
      },
      {
        x: "bicycle",
        y: 97,
      },
      {
        x: "horse",
        y: 87,
      },
      {
        x: "skateboard",
        y: 299,
      },
      {
        x: "others",
        y: 251,
      },
    ],
  },
  {
    id: "us",
    color: tokens("dark").redAccent[200],
    data: [
      {
        x: "plane",
        y: 191,
      },
      {
        x: "helicopter",
        y: 136,
      },
      {
        x: "boat",
        y: 91,
      },
      {
        x: "train",
        y: 190,
      },
      {
        x: "subway",
        y: 211,
      },
      {
        x: "bus",
        y: 152,
      },
      {
        x: "car",
        y: 189,
      },
      {
        x: "moto",
        y: 152,
      },
      {
        x: "bicycle",
        y: 8,
      },
      {
        x: "horse",
        y: 197,
      },
      {
        x: "skateboard",
        y: 107,
      },
      {
        x: "others",
        y: 170,
      },
    ],
  },
];

export const mockGeographyData = [
  {
    id: "AFG",
    value: 520600,
  },
  {
    id: "AGO",
    value: 949905,
  },
  {
    id: "ALB",
    value: 329910,
  },
  {
    id: "ARE",
    value: 675484,
  },
  {
    id: "ARG",
    value: 432239,
  },
  {
    id: "ARM",
    value: 288305,
  },
  {
    id: "ATA",
    value: 415648,
  },
  {
    id: "ATF",
    value: 665159,
  },
  {
    id: "AUT",
    value: 798526,
  },
  {
    id: "AZE",
    value: 481678,
  },
  {
    id: "BDI",
    value: 496457,
  },
  {
    id: "BEL",
    value: 252276,
  },
  {
    id: "BEN",
    value: 440315,
  },
  {
    id: "BFA",
    value: 343752,
  },
  {
    id: "BGD",
    value: 920203,
  },
  {
    id: "BGR",
    value: 261196,
  },
  {
    id: "BHS",
    value: 421551,
  },
  {
    id: "BIH",
    value: 974745,
  },
  {
    id: "BLR",
    value: 349288,
  },
  {
    id: "BLZ",
    value: 305983,
  },
  {
    id: "BOL",
    value: 430840,
  },
  {
    id: "BRN",
    value: 345666,
  },
  {
    id: "BTN",
    value: 649678,
  },
  {
    id: "BWA",
    value: 319392,
  },
  {
    id: "CAF",
    value: 722549,
  },
  {
    id: "CAN",
    value: 332843,
  },
  {
    id: "CHE",
    value: 122159,
  },
  {
    id: "CHL",
    value: 811736,
  },
  {
    id: "CHN",
    value: 593604,
  },
  {
    id: "CIV",
    value: 143219,
  },
  {
    id: "CMR",
    value: 630627,
  },
  {
    id: "COG",
    value: 498556,
  },
  {
    id: "COL",
    value: 660527,
  },
  {
    id: "CRI",
    value: 60262,
  },
  {
    id: "CUB",
    value: 177870,
  },
  {
    id: "-99",
    value: 463208,
  },
  {
    id: "CYP",
    value: 945909,
  },
  {
    id: "CZE",
    value: 500109,
  },
  {
    id: "DEU",
    value: 63345,
  },
  {
    id: "DJI",
    value: 634523,
  },
  {
    id: "DNK",
    value: 731068,
  },
  {
    id: "DOM",
    value: 262538,
  },
  {
    id: "DZA",
    value: 760695,
  },
  {
    id: "ECU",
    value: 301263,
  },
  {
    id: "EGY",
    value: 148475,
  },
  {
    id: "ERI",
    value: 939504,
  },
  {
    id: "ESP",
    value: 706050,
  },
  {
    id: "EST",
    value: 977015,
  },
  {
    id: "ETH",
    value: 461734,
  },
  {
    id: "FIN",
    value: 22800,
  },
  {
    id: "FJI",
    value: 18985,
  },
  {
    id: "FLK",
    value: 64986,
  },
  {
    id: "FRA",
    value: 447457,
  },
  {
    id: "GAB",
    value: 669675,
  },
  {
    id: "GBR",
    value: 757120,
  },
  {
    id: "GEO",
    value: 158702,
  },
  {
    id: "GHA",
    value: 893180,
  },
  {
    id: "GIN",
    value: 877288,
  },
  {
    id: "GMB",
    value: 724530,
  },
  {
    id: "GNB",
    value: 387753,
  },
  {
    id: "GNQ",
    value: 706118,
  },
  {
    id: "GRC",
    value: 377796,
  },
  {
    id: "GTM",
    value: 66890,
  },
  {
    id: "GUY",
    value: 719300,
  },
  {
    id: "HND",
    value: 739590,
  },
  {
    id: "HRV",
    value: 929467,
  },
  {
    id: "HTI",
    value: 538961,
  },
  {
    id: "HUN",
    value: 146095,
  },
  {
    id: "IDN",
    value: 490681,
  },
  {
    id: "IND",
    value: 549818,
  },
  {
    id: "IRL",
    value: 630163,
  },
  {
    id: "IRN",
    value: 596921,
  },
  {
    id: "IRQ",
    value: 767023,
  },
  {
    id: "ISL",
    value: 478682,
  },
  {
    id: "ISR",
    value: 963688,
  },
  {
    id: "ITA",
    value: 393089,
  },
  {
    id: "JAM",
    value: 83173,
  },
  {
    id: "JOR",
    value: 52005,
  },
  {
    id: "JPN",
    value: 199174,
  },
  {
    id: "KAZ",
    value: 181424,
  },
  {
    id: "KEN",
    value: 60946,
  },
  {
    id: "KGZ",
    value: 432478,
  },
  {
    id: "KHM",
    value: 254461,
  },
  {
    id: "OSA",
    value: 942447,
  },
  {
    id: "KWT",
    value: 414413,
  },
  {
    id: "LAO",
    value: 448339,
  },
  {
    id: "LBN",
    value: 620090,
  },
  {
    id: "LBR",
    value: 435950,
  },
  {
    id: "LBY",
    value: 75091,
  },
  {
    id: "LKA",
    value: 595124,
  },
  {
    id: "LSO",
    value: 483524,
  },
  {
    id: "LTU",
    value: 867357,
  },
  {
    id: "LUX",
    value: 689172,
  },
  {
    id: "LVA",
    value: 742980,
  },
  {
    id: "MAR",
    value: 236538,
  },
  {
    id: "MDA",
    value: 926836,
  },
  {
    id: "MDG",
    value: 840840,
  },
  {
    id: "MEX",
    value: 353910,
  },
  {
    id: "MKD",
    value: 505842,
  },
  {
    id: "MLI",
    value: 286082,
  },
  {
    id: "MMR",
    value: 915544,
  },
  {
    id: "MNE",
    value: 609500,
  },
  {
    id: "MNG",
    value: 410428,
  },
  {
    id: "MOZ",
    value: 32868,
  },
  {
    id: "MRT",
    value: 375671,
  },
  {
    id: "MWI",
    value: 591935,
  },
  {
    id: "MYS",
    value: 991644,
  },
  {
    id: "NAM",
    value: 701897,
  },
  {
    id: "NCL",
    value: 144098,
  },
  {
    id: "NER",
    value: 312944,
  },
  {
    id: "NGA",
    value: 862877,
  },
  {
    id: "NIC",
    value: 90831,
  },
  {
    id: "NLD",
    value: 281879,
  },
  {
    id: "NOR",
    value: 224537,
  },
  {
    id: "NPL",
    value: 322331,
  },
  {
    id: "NZL",
    value: 86615,
  },
  {
    id: "OMN",
    value: 707881,
  },
  {
    id: "PAK",
    value: 158577,
  },
  {
    id: "PAN",
    value: 738579,
  },
  {
    id: "PER",
    value: 248751,
  },
  {
    id: "PHL",
    value: 557292,
  },
  {
    id: "PNG",
    value: 516874,
  },
  {
    id: "POL",
    value: 682137,
  },
  {
    id: "PRI",
    value: 957399,
  },
  {
    id: "PRT",
    value: 846430,
  },
  {
    id: "PRY",
    value: 720555,
  },
  {
    id: "QAT",
    value: 478726,
  },
  {
    id: "ROU",
    value: 259318,
  },
  {
    id: "RUS",
    value: 268735,
  },
  {
    id: "RWA",
    value: 136781,
  },
  {
    id: "ESH",
    value: 151957,
  },
  {
    id: "SAU",
    value: 111821,
  },
  {
    id: "SDN",
    value: 927112,
  },
  {
    id: "SDS",
    value: 966473,
  },
  {
    id: "SEN",
    value: 158085,
  },
  {
    id: "SLB",
    value: 178389,
  },
  {
    id: "SLE",
    value: 528433,
  },
  {
    id: "SLV",
    value: 353467,
  },
  {
    id: "ABV",
    value: 251,
  },
  {
    id: "SOM",
    value: 445243,
  },
  {
    id: "SRB",
    value: 202402,
  },
  {
    id: "SUR",
    value: 972121,
  },
  {
    id: "SVK",
    value: 319923,
  },
  {
    id: "SVN",
    value: 728766,
  },
  {
    id: "SWZ",
    value: 379669,
  },
  {
    id: "SYR",
    value: 16221,
  },
  {
    id: "TCD",
    value: 101273,
  },
  {
    id: "TGO",
    value: 498411,
  },
  {
    id: "THA",
    value: 506906,
  },
  {
    id: "TJK",
    value: 613093,
  },
  {
    id: "TKM",
    value: 327016,
  },
  {
    id: "TLS",
    value: 607972,
  },
  {
    id: "TTO",
    value: 936365,
  },
  {
    id: "TUN",
    value: 898416,
  },
  {
    id: "TUR",
    value: 237783,
  },
  {
    id: "TWN",
    value: 878213,
  },
  {
    id: "TZA",
    value: 442174,
  },
  {
    id: "UGA",
    value: 720710,
  },
  {
    id: "UKR",
    value: 74172,
  },
  {
    id: "URY",
    value: 753177,
  },
  {
    id: "USA",
    value: 658725,
  },
  {
    id: "UZB",
    value: 550313,
  },
  {
    id: "VEN",
    value: 707492,
  },
  {
    id: "VNM",
    value: 538907,
  },
  {
    id: "VUT",
    value: 650646,
  },
  {
    id: "PSE",
    value: 476078,
  },
  {
    id: "YEM",
    value: 957751,
  },
  {
    id: "ZAF",
    value: 836949,
  },
  {
    id: "ZMB",
    value: 714503,
  },
  {
    id: "ZWE",
    value: 405217,
  },
  {
    id: "KOR",
    value: 171135,
  },
];

export const slider = [
  {
    id: 0,
    reference:
      "https://www.powerhomebiz.com/wp-content/uploads/2020/05/work_from_home_woman.png",
  },
  {
    id: 1,
    reference:
      "https://www.goodfinancialcents.com/wp-content/uploads/2017/03/home-based-business-ideas-scaled.jpg",
  },
  {
    id: 2,
    reference:
      "https://cdn.gobankingrates.com/wp-content/uploads/2020/02/girl-creating-educational-video-iStock-1149122573.jpg",
  },
];

export const presentation = [
  { id: 0, reference: "/assets/images/virtual_skin/1.png" },
  { id: 1, reference: "/assets/images/virtual_skin/2.png" },
  { id: 2, reference: "/assets/images/virtual_skin/3.png" },
  { id: 3, reference: "/assets/images/virtual_skin/4.png" },
  { id: 4, reference: "/assets/images/virtual_skin/5.png" },
  { id: 5, reference: "/assets/images/virtual_skin/6.png" },
  { id: 6, reference: "/assets/images/virtual_skin/7.png" },
  { id: 7, reference: "/assets/images/virtual_skin/8.png" },
  { id: 8, reference: "/assets/images/virtual_skin/9.png" },
];

export const contentNotes = [
  {
    id: 0,
    content:
      "1. Hi, I’d like to thank everyone for joining today.  For those of you who don’t know me, my name is ___________________ , your Mary Kay Independent Beauty Consultant.",
    special:
      "I also would like to thank ___________________ (hostess’s name) for hosting this party. I reward all of my hostesses with fabulous perks, and I‘d be happy to tell you about them at the end of this party.",
  },
];

export const recordingsData = {
  from: "2023-02-28",
  to: "2023-03-30",
  page_count: 1,
  page_size: 30,
  total_records: 1,
  next_page_token: "",
  meetings: [
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "New skin Care, MaryKay",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
    {
      uuid: "BGuLQ9xeQSWXROjOh60YAQ==",
      id: 99952800949,
      account_id: "YZwvfZtWSXq4diaCRhQPKQ",
      host_id: "Tv_Y4im7SVGROPV8rLHeXg",
      topic: "Terry’s DIQ Kick-Off",
      type: 2,
      start_time: "2023-03-04T14:18:09Z",
      timezone: "America/Chicago",
      duration: 0,
      total_size: 1407087,
      recording_count: 3,
      share_url:
        "https://zoom.us/rec/share/qGh8bTsYybdk-E3BqbkozpoBT34Mf9jc7i77No0g_61wK50ZHEiyF154cwk9e8jv.-zjMF9eB4j-ODp7B",
      recording_files: [
        {
          id: "38808945-db94-4d96-86c0-fe5f9b15b8b2",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "M4A",
          file_extension: "M4A",
          file_size: 652070,
          play_url:
            "https://zoom.us/rec/play/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          download_url:
            "https://zoom.us/rec/download/15Gg3lelXIQFtQz_Rt_MmKexSNYP7Z2PMfM9GJ5UONevXfX2R4ttUdCfB3jYNXCGiMLRD9tYf0pAf57p.eJdzbln7x9PpLh-2",
          status: "completed",
          recording_type: "audio_only",
        },
        {
          id: "64cc5611-dc55-4412-a54a-d7452cc61c21",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "TIMELINE",
          file_extension: "JSON",
          file_size: 117,
          download_url:
            "https://zoom.us/rec/download/AlloBjh02_lpg_LNkSCR2djr-IXVkNBkakQyZPd8gE3KC7QMYF0YFDwgxlgIA0Cx3WWp-mbnyliTtyJm.ZDmevi_lLhQKO5hC",
          status: "completed",
          recording_type: "timeline",
        },
        {
          id: "cd22ec1e-495f-4f25-b5f3-35b2f75ff341",
          meeting_id: "BGuLQ9xeQSWXROjOh60YAQ==",
          recording_start: "2023-03-04T14:18:09Z",
          recording_end: "2023-03-04T14:22:48Z",
          file_type: "MP4",
          file_extension: "MP4",
          file_size: 754900,
          play_url:
            "https://zoom.us/rec/play/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          download_url:
            "https://zoom.us/rec/download/nU6rFSGD6Avfmf5vsABpn6TDoUplj543Ul_0RRFIefvF-11gBjX0yG2_ljcP-zgZFMp-A6_vSs3kqOG2.O9X6f5PV974kf-Q8",
          status: "completed",
          recording_type: "active_speaker",
        },
      ],
      recording_play_passcode: "",
    },
  ],
};

export const countriesData = [
  {
    id: 1,
    name: "Afghanistan",
    iso3: "AFG",
    iso2: "AF",
    numeric_code: "004",
    phone_code: "93",
    capital: "Kabul",
    currency: "AFN",
    currency_name: "Afghan afghani",
    currency_symbol: "؋",
    tld: ".af",
    native: "افغانستان",
    region: "Asia",
    subregion: "Southern Asia",
    timezones: [
      {
        zoneName: "Asia/Kabul",
        gmtOffset: 16200,
        gmtOffsetName: "UTC+04:30",
        abbreviation: "AFT",
        tzName: "Afghanistan Time",
      },
    ],
    translations: {
      kr: "아프가니스탄",
      "pt-BR": "Afeganistão",
      pt: "Afeganistão",
      nl: "Afghanistan",
      hr: "Afganistan",
      fa: "افغانستان",
      de: "Afghanistan",
      es: "Afganistán",
      fr: "Afghanistan",
      ja: "アフガニスタン",
      it: "Afghanistan",
      cn: "阿富汗",
      tr: "Afganistan",
    },
    latitude: "33.00000000",
    longitude: "65.00000000",
    emoji: "🇦🇫",
    emojiU: "U+1F1E6 U+1F1EB",
  },
  {
    id: 2,
    name: "Aland Islands",
    iso3: "ALA",
    iso2: "AX",
    numeric_code: "248",
    phone_code: "+358-18",
    capital: "Mariehamn",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".ax",
    native: "Åland",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Europe/Mariehamn",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "올란드 제도",
      "pt-BR": "Ilhas de Aland",
      pt: "Ilhas de Aland",
      nl: "Ålandeilanden",
      hr: "Ålandski otoci",
      fa: "جزایر الند",
      de: "Åland",
      es: "Alandia",
      fr: "Åland",
      ja: "オーランド諸島",
      it: "Isole Aland",
      cn: "奥兰群岛",
      tr: "Åland Adalari",
    },
    latitude: "60.11666700",
    longitude: "19.90000000",
    emoji: "🇦🇽",
    emojiU: "U+1F1E6 U+1F1FD",
  },
  {
    id: 3,
    name: "Albania",
    iso3: "ALB",
    iso2: "AL",
    numeric_code: "008",
    phone_code: "355",
    capital: "Tirana",
    currency: "ALL",
    currency_name: "Albanian lek",
    currency_symbol: "Lek",
    tld: ".al",
    native: "Shqipëria",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/Tirane",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "알바니아",
      "pt-BR": "Albânia",
      pt: "Albânia",
      nl: "Albanië",
      hr: "Albanija",
      fa: "آلبانی",
      de: "Albanien",
      es: "Albania",
      fr: "Albanie",
      ja: "アルバニア",
      it: "Albania",
      cn: "阿尔巴尼亚",
      tr: "Arnavutluk",
    },
    latitude: "41.00000000",
    longitude: "20.00000000",
    emoji: "🇦🇱",
    emojiU: "U+1F1E6 U+1F1F1",
  },
  {
    id: 4,
    name: "Algeria",
    iso3: "DZA",
    iso2: "DZ",
    numeric_code: "012",
    phone_code: "213",
    capital: "Algiers",
    currency: "DZD",
    currency_name: "Algerian dinar",
    currency_symbol: "دج",
    tld: ".dz",
    native: "الجزائر",
    region: "Africa",
    subregion: "Northern Africa",
    timezones: [
      {
        zoneName: "Africa/Algiers",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "알제리",
      "pt-BR": "Argélia",
      pt: "Argélia",
      nl: "Algerije",
      hr: "Alžir",
      fa: "الجزایر",
      de: "Algerien",
      es: "Argelia",
      fr: "Algérie",
      ja: "アルジェリア",
      it: "Algeria",
      cn: "阿尔及利亚",
      tr: "Cezayir",
    },
    latitude: "28.00000000",
    longitude: "3.00000000",
    emoji: "🇩🇿",
    emojiU: "U+1F1E9 U+1F1FF",
  },
  {
    id: 5,
    name: "American Samoa",
    iso3: "ASM",
    iso2: "AS",
    numeric_code: "016",
    phone_code: "+1-684",
    capital: "Pago Pago",
    currency: "USD",
    currency_name: "US Dollar",
    currency_symbol: "$",
    tld: ".as",
    native: "American Samoa",
    region: "Oceania",
    subregion: "Polynesia",
    timezones: [
      {
        zoneName: "Pacific/Pago_Pago",
        gmtOffset: -39600,
        gmtOffsetName: "UTC-11:00",
        abbreviation: "SST",
        tzName: "Samoa Standard Time",
      },
    ],
    translations: {
      kr: "아메리칸사모아",
      "pt-BR": "Samoa Americana",
      pt: "Samoa Americana",
      nl: "Amerikaans Samoa",
      hr: "Američka Samoa",
      fa: "ساموآی آمریکا",
      de: "Amerikanisch-Samoa",
      es: "Samoa Americana",
      fr: "Samoa américaines",
      ja: "アメリカ領サモア",
      it: "Samoa Americane",
      cn: "美属萨摩亚",
      tr: "Amerikan Samoasi",
    },
    latitude: "-14.33333333",
    longitude: "-170.00000000",
    emoji: "🇦🇸",
    emojiU: "U+1F1E6 U+1F1F8",
  },
  {
    id: 6,
    name: "Andorra",
    iso3: "AND",
    iso2: "AD",
    numeric_code: "020",
    phone_code: "376",
    capital: "Andorra la Vella",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".ad",
    native: "Andorra",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/Andorra",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "안도라",
      "pt-BR": "Andorra",
      pt: "Andorra",
      nl: "Andorra",
      hr: "Andora",
      fa: "آندورا",
      de: "Andorra",
      es: "Andorra",
      fr: "Andorre",
      ja: "アンドラ",
      it: "Andorra",
      cn: "安道尔",
      tr: "Andorra",
    },
    latitude: "42.50000000",
    longitude: "1.50000000",
    emoji: "🇦🇩",
    emojiU: "U+1F1E6 U+1F1E9",
  },
  {
    id: 7,
    name: "Angola",
    iso3: "AGO",
    iso2: "AO",
    numeric_code: "024",
    phone_code: "244",
    capital: "Luanda",
    currency: "AOA",
    currency_name: "Angolan kwanza",
    currency_symbol: "Kz",
    tld: ".ao",
    native: "Angola",
    region: "Africa",
    subregion: "Middle Africa",
    timezones: [
      {
        zoneName: "Africa/Luanda",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "앙골라",
      "pt-BR": "Angola",
      pt: "Angola",
      nl: "Angola",
      hr: "Angola",
      fa: "آنگولا",
      de: "Angola",
      es: "Angola",
      fr: "Angola",
      ja: "アンゴラ",
      it: "Angola",
      cn: "安哥拉",
      tr: "Angola",
    },
    latitude: "-12.50000000",
    longitude: "18.50000000",
    emoji: "🇦🇴",
    emojiU: "U+1F1E6 U+1F1F4",
  },
  {
    id: 8,
    name: "Anguilla",
    iso3: "AIA",
    iso2: "AI",
    numeric_code: "660",
    phone_code: "+1-264",
    capital: "The Valley",
    currency: "XCD",
    currency_name: "East Caribbean dollar",
    currency_symbol: "$",
    tld: ".ai",
    native: "Anguilla",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Anguilla",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "앵귈라",
      "pt-BR": "Anguila",
      pt: "Anguila",
      nl: "Anguilla",
      hr: "Angvila",
      fa: "آنگویلا",
      de: "Anguilla",
      es: "Anguilla",
      fr: "Anguilla",
      ja: "アンギラ",
      it: "Anguilla",
      cn: "安圭拉",
      tr: "Anguilla",
    },
    latitude: "18.25000000",
    longitude: "-63.16666666",
    emoji: "🇦🇮",
    emojiU: "U+1F1E6 U+1F1EE",
  },
  {
    id: 9,
    name: "Antarctica",
    iso3: "ATA",
    iso2: "AQ",
    numeric_code: "010",
    phone_code: "672",
    capital: "",
    currency: "AAD",
    currency_name: "Antarctican dollar",
    currency_symbol: "$",
    tld: ".aq",
    native: "Antarctica",
    region: "Polar",
    subregion: "",
    timezones: [
      {
        zoneName: "Antarctica/Casey",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AWST",
        tzName: "Australian Western Standard Time",
      },
      {
        zoneName: "Antarctica/Davis",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "DAVT",
        tzName: "Davis Time",
      },
      {
        zoneName: "Antarctica/DumontDUrville",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "DDUT",
        tzName: "Dumont d'Urville Time",
      },
      {
        zoneName: "Antarctica/Mawson",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "MAWT",
        tzName: "Mawson Station Time",
      },
      {
        zoneName: "Antarctica/McMurdo",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "NZDT",
        tzName: "New Zealand Daylight Time",
      },
      {
        zoneName: "Antarctica/Palmer",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "CLST",
        tzName: "Chile Summer Time",
      },
      {
        zoneName: "Antarctica/Rothera",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ROTT",
        tzName: "Rothera Research Station Time",
      },
      {
        zoneName: "Antarctica/Syowa",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "SYOT",
        tzName: "Showa Station Time",
      },
      {
        zoneName: "Antarctica/Troll",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
      {
        zoneName: "Antarctica/Vostok",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "VOST",
        tzName: "Vostok Station Time",
      },
    ],
    translations: {
      kr: "남극",
      "pt-BR": "Antártida",
      pt: "Antárctida",
      nl: "Antarctica",
      hr: "Antarktika",
      fa: "جنوبگان",
      de: "Antarktika",
      es: "Antártida",
      fr: "Antarctique",
      ja: "南極大陸",
      it: "Antartide",
      cn: "南极洲",
      tr: "Antartika",
    },
    latitude: "-74.65000000",
    longitude: "4.48000000",
    emoji: "🇦🇶",
    emojiU: "U+1F1E6 U+1F1F6",
  },
  {
    id: 10,
    name: "Antigua And Barbuda",
    iso3: "ATG",
    iso2: "AG",
    numeric_code: "028",
    phone_code: "+1-268",
    capital: "St. John's",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".ag",
    native: "Antigua and Barbuda",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Antigua",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "앤티가 바부다",
      "pt-BR": "Antígua e Barbuda",
      pt: "Antígua e Barbuda",
      nl: "Antigua en Barbuda",
      hr: "Antigva i Barbuda",
      fa: "آنتیگوا و باربودا",
      de: "Antigua und Barbuda",
      es: "Antigua y Barbuda",
      fr: "Antigua-et-Barbuda",
      ja: "アンティグア・バーブーダ",
      it: "Antigua e Barbuda",
      cn: "安提瓜和巴布达",
      tr: "Antigua Ve Barbuda",
    },
    latitude: "17.05000000",
    longitude: "-61.80000000",
    emoji: "🇦🇬",
    emojiU: "U+1F1E6 U+1F1EC",
  },
  {
    id: 11,
    name: "Argentina",
    iso3: "ARG",
    iso2: "AR",
    numeric_code: "032",
    phone_code: "54",
    capital: "Buenos Aires",
    currency: "ARS",
    currency_name: "Argentine peso",
    currency_symbol: "$",
    tld: ".ar",
    native: "Argentina",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "America/Argentina/Buenos_Aires",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Catamarca",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Cordoba",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Jujuy",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/La_Rioja",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Mendoza",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Rio_Gallegos",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Salta",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/San_Juan",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/San_Luis",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Tucuman",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Ushuaia",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
    ],
    translations: {
      kr: "아르헨티나",
      "pt-BR": "Argentina",
      pt: "Argentina",
      nl: "Argentinië",
      hr: "Argentina",
      fa: "آرژانتین",
      de: "Argentinien",
      es: "Argentina",
      fr: "Argentine",
      ja: "アルゼンチン",
      it: "Argentina",
      cn: "阿根廷",
      tr: "Arjantin",
    },
    latitude: "-34.00000000",
    longitude: "-64.00000000",
    emoji: "🇦🇷",
    emojiU: "U+1F1E6 U+1F1F7",
  },
  {
    id: 12,
    name: "Armenia",
    iso3: "ARM",
    iso2: "AM",
    numeric_code: "051",
    phone_code: "374",
    capital: "Yerevan",
    currency: "AMD",
    currency_name: "Armenian dram",
    currency_symbol: "֏",
    tld: ".am",
    native: "Հայաստան",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Yerevan",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "AMT",
        tzName: "Armenia Time",
      },
    ],
    translations: {
      kr: "아르메니아",
      "pt-BR": "Armênia",
      pt: "Arménia",
      nl: "Armenië",
      hr: "Armenija",
      fa: "ارمنستان",
      de: "Armenien",
      es: "Armenia",
      fr: "Arménie",
      ja: "アルメニア",
      it: "Armenia",
      cn: "亚美尼亚",
      tr: "Ermenistan",
    },
    latitude: "40.00000000",
    longitude: "45.00000000",
    emoji: "🇦🇲",
    emojiU: "U+1F1E6 U+1F1F2",
  },
  {
    id: 13,
    name: "Aruba",
    iso3: "ABW",
    iso2: "AW",
    numeric_code: "533",
    phone_code: "297",
    capital: "Oranjestad",
    currency: "AWG",
    currency_name: "Aruban florin",
    currency_symbol: "ƒ",
    tld: ".aw",
    native: "Aruba",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Aruba",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "아루바",
      "pt-BR": "Aruba",
      pt: "Aruba",
      nl: "Aruba",
      hr: "Aruba",
      fa: "آروبا",
      de: "Aruba",
      es: "Aruba",
      fr: "Aruba",
      ja: "アルバ",
      it: "Aruba",
      cn: "阿鲁巴",
      tr: "Aruba",
    },
    latitude: "12.50000000",
    longitude: "-69.96666666",
    emoji: "🇦🇼",
    emojiU: "U+1F1E6 U+1F1FC",
  },
  {
    id: 14,
    name: "Australia",
    iso3: "AUS",
    iso2: "AU",
    numeric_code: "036",
    phone_code: "61",
    capital: "Canberra",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".au",
    native: "Australia",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    timezones: [
      {
        zoneName: "Antarctica/Macquarie",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "MIST",
        tzName: "Macquarie Island Station Time",
      },
      {
        zoneName: "Australia/Adelaide",
        gmtOffset: 37800,
        gmtOffsetName: "UTC+10:30",
        abbreviation: "ACDT",
        tzName: "Australian Central Daylight Saving Time",
      },
      {
        zoneName: "Australia/Brisbane",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "AEST",
        tzName: "Australian Eastern Standard Time",
      },
      {
        zoneName: "Australia/Broken_Hill",
        gmtOffset: 37800,
        gmtOffsetName: "UTC+10:30",
        abbreviation: "ACDT",
        tzName: "Australian Central Daylight Saving Time",
      },
      {
        zoneName: "Australia/Currie",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AEDT",
        tzName: "Australian Eastern Daylight Saving Time",
      },
      {
        zoneName: "Australia/Darwin",
        gmtOffset: 34200,
        gmtOffsetName: "UTC+09:30",
        abbreviation: "ACST",
        tzName: "Australian Central Standard Time",
      },
      {
        zoneName: "Australia/Eucla",
        gmtOffset: 31500,
        gmtOffsetName: "UTC+08:45",
        abbreviation: "ACWST",
        tzName: "Australian Central Western Standard Time (Unofficial)",
      },
      {
        zoneName: "Australia/Hobart",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AEDT",
        tzName: "Australian Eastern Daylight Saving Time",
      },
      {
        zoneName: "Australia/Lindeman",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "AEST",
        tzName: "Australian Eastern Standard Time",
      },
      {
        zoneName: "Australia/Lord_Howe",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "LHST",
        tzName: "Lord Howe Summer Time",
      },
      {
        zoneName: "Australia/Melbourne",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AEDT",
        tzName: "Australian Eastern Daylight Saving Time",
      },
      {
        zoneName: "Australia/Perth",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "AWST",
        tzName: "Australian Western Standard Time",
      },
      {
        zoneName: "Australia/Sydney",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AEDT",
        tzName: "Australian Eastern Daylight Saving Time",
      },
    ],
    translations: {
      kr: "호주",
      "pt-BR": "Austrália",
      pt: "Austrália",
      nl: "Australië",
      hr: "Australija",
      fa: "استرالیا",
      de: "Australien",
      es: "Australia",
      fr: "Australie",
      ja: "オーストラリア",
      it: "Australia",
      cn: "澳大利亚",
      tr: "Avustralya",
    },
    latitude: "-27.00000000",
    longitude: "133.00000000",
    emoji: "🇦🇺",
    emojiU: "U+1F1E6 U+1F1FA",
  },
  {
    id: 15,
    name: "Austria",
    iso3: "AUT",
    iso2: "AT",
    numeric_code: "040",
    phone_code: "43",
    capital: "Vienna",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".at",
    native: "Österreich",
    region: "Europe",
    subregion: "Western Europe",
    timezones: [
      {
        zoneName: "Europe/Vienna",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "오스트리아",
      "pt-BR": "áustria",
      pt: "áustria",
      nl: "Oostenrijk",
      hr: "Austrija",
      fa: "اتریش",
      de: "Österreich",
      es: "Austria",
      fr: "Autriche",
      ja: "オーストリア",
      it: "Austria",
      cn: "奥地利",
      tr: "Avusturya",
    },
    latitude: "47.33333333",
    longitude: "13.33333333",
    emoji: "🇦🇹",
    emojiU: "U+1F1E6 U+1F1F9",
  },
  {
    id: 16,
    name: "Azerbaijan",
    iso3: "AZE",
    iso2: "AZ",
    numeric_code: "031",
    phone_code: "994",
    capital: "Baku",
    currency: "AZN",
    currency_name: "Azerbaijani manat",
    currency_symbol: "m",
    tld: ".az",
    native: "Azərbaycan",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Baku",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "AZT",
        tzName: "Azerbaijan Time",
      },
    ],
    translations: {
      kr: "아제르바이잔",
      "pt-BR": "Azerbaijão",
      pt: "Azerbaijão",
      nl: "Azerbeidzjan",
      hr: "Azerbajdžan",
      fa: "آذربایجان",
      de: "Aserbaidschan",
      es: "Azerbaiyán",
      fr: "Azerbaïdjan",
      ja: "アゼルバイジャン",
      it: "Azerbaijan",
      cn: "阿塞拜疆",
      tr: "Azerbaycan",
    },
    latitude: "40.50000000",
    longitude: "47.50000000",
    emoji: "🇦🇿",
    emojiU: "U+1F1E6 U+1F1FF",
  },
  {
    id: 18,
    name: "Bahrain",
    iso3: "BHR",
    iso2: "BH",
    numeric_code: "048",
    phone_code: "973",
    capital: "Manama",
    currency: "BHD",
    currency_name: "Bahraini dinar",
    currency_symbol: ".د.ب",
    tld: ".bh",
    native: "‏البحرين",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Bahrain",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time",
      },
    ],
    translations: {
      kr: "바레인",
      "pt-BR": "Bahrein",
      pt: "Barém",
      nl: "Bahrein",
      hr: "Bahrein",
      fa: "بحرین",
      de: "Bahrain",
      es: "Bahrein",
      fr: "Bahreïn",
      ja: "バーレーン",
      it: "Bahrein",
      cn: "巴林",
      tr: "Bahreyn",
    },
    latitude: "26.00000000",
    longitude: "50.55000000",
    emoji: "🇧🇭",
    emojiU: "U+1F1E7 U+1F1ED",
  },
  {
    id: 19,
    name: "Bangladesh",
    iso3: "BGD",
    iso2: "BD",
    numeric_code: "050",
    phone_code: "880",
    capital: "Dhaka",
    currency: "BDT",
    currency_name: "Bangladeshi taka",
    currency_symbol: "৳",
    tld: ".bd",
    native: "Bangladesh",
    region: "Asia",
    subregion: "Southern Asia",
    timezones: [
      {
        zoneName: "Asia/Dhaka",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "BDT",
        tzName: "Bangladesh Standard Time",
      },
    ],
    translations: {
      kr: "방글라데시",
      "pt-BR": "Bangladesh",
      pt: "Bangladeche",
      nl: "Bangladesh",
      hr: "Bangladeš",
      fa: "بنگلادش",
      de: "Bangladesch",
      es: "Bangladesh",
      fr: "Bangladesh",
      ja: "バングラデシュ",
      it: "Bangladesh",
      cn: "孟加拉",
      tr: "Bangladeş",
    },
    latitude: "24.00000000",
    longitude: "90.00000000",
    emoji: "🇧🇩",
    emojiU: "U+1F1E7 U+1F1E9",
  },
  {
    id: 20,
    name: "Barbados",
    iso3: "BRB",
    iso2: "BB",
    numeric_code: "052",
    phone_code: "+1-246",
    capital: "Bridgetown",
    currency: "BBD",
    currency_name: "Barbadian dollar",
    currency_symbol: "Bds$",
    tld: ".bb",
    native: "Barbados",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Barbados",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "바베이도스",
      "pt-BR": "Barbados",
      pt: "Barbados",
      nl: "Barbados",
      hr: "Barbados",
      fa: "باربادوس",
      de: "Barbados",
      es: "Barbados",
      fr: "Barbade",
      ja: "バルバドス",
      it: "Barbados",
      cn: "巴巴多斯",
      tr: "Barbados",
    },
    latitude: "13.16666666",
    longitude: "-59.53333333",
    emoji: "🇧🇧",
    emojiU: "U+1F1E7 U+1F1E7",
  },
  {
    id: 21,
    name: "Belarus",
    iso3: "BLR",
    iso2: "BY",
    numeric_code: "112",
    phone_code: "375",
    capital: "Minsk",
    currency: "BYN",
    currency_name: "Belarusian ruble",
    currency_symbol: "Br",
    tld: ".by",
    native: "Белару́сь",
    region: "Europe",
    subregion: "Eastern Europe",
    timezones: [
      {
        zoneName: "Europe/Minsk",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "MSK",
        tzName: "Moscow Time",
      },
    ],
    translations: {
      kr: "벨라루스",
      "pt-BR": "Bielorrússia",
      pt: "Bielorrússia",
      nl: "Wit-Rusland",
      hr: "Bjelorusija",
      fa: "بلاروس",
      de: "Weißrussland",
      es: "Bielorrusia",
      fr: "Biélorussie",
      ja: "ベラルーシ",
      it: "Bielorussia",
      cn: "白俄罗斯",
      tr: "Belarus",
    },
    latitude: "53.00000000",
    longitude: "28.00000000",
    emoji: "🇧🇾",
    emojiU: "U+1F1E7 U+1F1FE",
  },
  {
    id: 22,
    name: "Belgium",
    iso3: "BEL",
    iso2: "BE",
    numeric_code: "056",
    phone_code: "32",
    capital: "Brussels",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".be",
    native: "België",
    region: "Europe",
    subregion: "Western Europe",
    timezones: [
      {
        zoneName: "Europe/Brussels",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "벨기에",
      "pt-BR": "Bélgica",
      pt: "Bélgica",
      nl: "België",
      hr: "Belgija",
      fa: "بلژیک",
      de: "Belgien",
      es: "Bélgica",
      fr: "Belgique",
      ja: "ベルギー",
      it: "Belgio",
      cn: "比利时",
      tr: "Belçika",
    },
    latitude: "50.83333333",
    longitude: "4.00000000",
    emoji: "🇧🇪",
    emojiU: "U+1F1E7 U+1F1EA",
  },
  {
    id: 23,
    name: "Belize",
    iso3: "BLZ",
    iso2: "BZ",
    numeric_code: "084",
    phone_code: "501",
    capital: "Belmopan",
    currency: "BZD",
    currency_name: "Belize dollar",
    currency_symbol: "$",
    tld: ".bz",
    native: "Belize",
    region: "Americas",
    subregion: "Central America",
    timezones: [
      {
        zoneName: "America/Belize",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America)",
      },
    ],
    translations: {
      kr: "벨리즈",
      "pt-BR": "Belize",
      pt: "Belize",
      nl: "Belize",
      hr: "Belize",
      fa: "بلیز",
      de: "Belize",
      es: "Belice",
      fr: "Belize",
      ja: "ベリーズ",
      it: "Belize",
      cn: "伯利兹",
      tr: "Belize",
    },
    latitude: "17.25000000",
    longitude: "-88.75000000",
    emoji: "🇧🇿",
    emojiU: "U+1F1E7 U+1F1FF",
  },
  {
    id: 24,
    name: "Benin",
    iso3: "BEN",
    iso2: "BJ",
    numeric_code: "204",
    phone_code: "229",
    capital: "Porto-Novo",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".bj",
    native: "Bénin",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Porto-Novo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "베냉",
      "pt-BR": "Benin",
      pt: "Benim",
      nl: "Benin",
      hr: "Benin",
      fa: "بنین",
      de: "Benin",
      es: "Benín",
      fr: "Bénin",
      ja: "ベナン",
      it: "Benin",
      cn: "贝宁",
      tr: "Benin",
    },
    latitude: "9.50000000",
    longitude: "2.25000000",
    emoji: "🇧🇯",
    emojiU: "U+1F1E7 U+1F1EF",
  },
  {
    id: 25,
    name: "Bermuda",
    iso3: "BMU",
    iso2: "BM",
    numeric_code: "060",
    phone_code: "+1-441",
    capital: "Hamilton",
    currency: "BMD",
    currency_name: "Bermudian dollar",
    currency_symbol: "$",
    tld: ".bm",
    native: "Bermuda",
    region: "Americas",
    subregion: "Northern America",
    timezones: [
      {
        zoneName: "Atlantic/Bermuda",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "버뮤다",
      "pt-BR": "Bermudas",
      pt: "Bermudas",
      nl: "Bermuda",
      hr: "Bermudi",
      fa: "برمودا",
      de: "Bermuda",
      es: "Bermudas",
      fr: "Bermudes",
      ja: "バミューダ",
      it: "Bermuda",
      cn: "百慕大",
      tr: "Bermuda",
    },
    latitude: "32.33333333",
    longitude: "-64.75000000",
    emoji: "🇧🇲",
    emojiU: "U+1F1E7 U+1F1F2",
  },
  {
    id: 26,
    name: "Bhutan",
    iso3: "BTN",
    iso2: "BT",
    numeric_code: "064",
    phone_code: "975",
    capital: "Thimphu",
    currency: "BTN",
    currency_name: "Bhutanese ngultrum",
    currency_symbol: "Nu.",
    tld: ".bt",
    native: "ʼbrug-yul",
    region: "Asia",
    subregion: "Southern Asia",
    timezones: [
      {
        zoneName: "Asia/Thimphu",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "BTT",
        tzName: "Bhutan Time",
      },
    ],
    translations: {
      kr: "부탄",
      "pt-BR": "Butão",
      pt: "Butão",
      nl: "Bhutan",
      hr: "Butan",
      fa: "بوتان",
      de: "Bhutan",
      es: "Bután",
      fr: "Bhoutan",
      ja: "ブータン",
      it: "Bhutan",
      cn: "不丹",
      tr: "Butan",
    },
    latitude: "27.50000000",
    longitude: "90.50000000",
    emoji: "🇧🇹",
    emojiU: "U+1F1E7 U+1F1F9",
  },
  {
    id: 27,
    name: "Bolivia",
    iso3: "BOL",
    iso2: "BO",
    numeric_code: "068",
    phone_code: "591",
    capital: "Sucre",
    currency: "BOB",
    currency_name: "Bolivian boliviano",
    currency_symbol: "Bs.",
    tld: ".bo",
    native: "Bolivia",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "America/La_Paz",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "BOT",
        tzName: "Bolivia Time",
      },
    ],
    translations: {
      kr: "볼리비아",
      "pt-BR": "Bolívia",
      pt: "Bolívia",
      nl: "Bolivia",
      hr: "Bolivija",
      fa: "بولیوی",
      de: "Bolivien",
      es: "Bolivia",
      fr: "Bolivie",
      ja: "ボリビア多民族国",
      it: "Bolivia",
      cn: "玻利维亚",
      tr: "Bolivya",
    },
    latitude: "-17.00000000",
    longitude: "-65.00000000",
    emoji: "🇧🇴",
    emojiU: "U+1F1E7 U+1F1F4",
  },
  {
    id: 155,
    name: "Bonaire, Sint Eustatius and Saba",
    iso3: "BES",
    iso2: "BQ",
    numeric_code: "535",
    phone_code: "599",
    capital: "Kralendijk",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".an",
    native: "Caribisch Nederland",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Anguilla",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "보네르 섬",
      "pt-BR": "Bonaire",
      pt: "Bonaire",
      fa: "بونیر",
      de: "Bonaire, Sint Eustatius und Saba",
      fr: "Bonaire, Saint-Eustache et Saba",
      it: "Bonaire, Saint-Eustache e Saba",
      cn: "博内尔岛、圣尤斯特歇斯和萨巴岛",
      tr: "Karayip Hollandasi",
    },
    latitude: "12.15000000",
    longitude: "-68.26666700",
    emoji: "🇧🇶",
    emojiU: "U+1F1E7 U+1F1F6",
  },
  {
    id: 28,
    name: "Bosnia and Herzegovina",
    iso3: "BIH",
    iso2: "BA",
    numeric_code: "070",
    phone_code: "387",
    capital: "Sarajevo",
    currency: "BAM",
    currency_name: "Bosnia and Herzegovina convertible mark",
    currency_symbol: "KM",
    tld: ".ba",
    native: "Bosna i Hercegovina",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/Sarajevo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "보스니아 헤르체고비나",
      "pt-BR": "Bósnia e Herzegovina",
      pt: "Bósnia e Herzegovina",
      nl: "Bosnië en Herzegovina",
      hr: "Bosna i Hercegovina",
      fa: "بوسنی و هرزگوین",
      de: "Bosnien und Herzegowina",
      es: "Bosnia y Herzegovina",
      fr: "Bosnie-Herzégovine",
      ja: "ボスニア・ヘルツェゴビナ",
      it: "Bosnia ed Erzegovina",
      cn: "波斯尼亚和黑塞哥维那",
      tr: "Bosna Hersek",
    },
    latitude: "44.00000000",
    longitude: "18.00000000",
    emoji: "🇧🇦",
    emojiU: "U+1F1E7 U+1F1E6",
  },
  {
    id: 29,
    name: "Botswana",
    iso3: "BWA",
    iso2: "BW",
    numeric_code: "072",
    phone_code: "267",
    capital: "Gaborone",
    currency: "BWP",
    currency_name: "Botswana pula",
    currency_symbol: "P",
    tld: ".bw",
    native: "Botswana",
    region: "Africa",
    subregion: "Southern Africa",
    timezones: [
      {
        zoneName: "Africa/Gaborone",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "보츠와나",
      "pt-BR": "Botsuana",
      pt: "Botsuana",
      nl: "Botswana",
      hr: "Bocvana",
      fa: "بوتسوانا",
      de: "Botswana",
      es: "Botswana",
      fr: "Botswana",
      ja: "ボツワナ",
      it: "Botswana",
      cn: "博茨瓦纳",
      tr: "Botsvana",
    },
    latitude: "-22.00000000",
    longitude: "24.00000000",
    emoji: "🇧🇼",
    emojiU: "U+1F1E7 U+1F1FC",
  },
  {
    id: 30,
    name: "Bouvet Island",
    iso3: "BVT",
    iso2: "BV",
    numeric_code: "074",
    phone_code: "0055",
    capital: "",
    currency: "NOK",
    currency_name: "Norwegian Krone",
    currency_symbol: "kr",
    tld: ".bv",
    native: "Bouvetøya",
    region: "",
    subregion: "",
    timezones: [
      {
        zoneName: "Europe/Oslo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "부벳 섬",
      "pt-BR": "Ilha Bouvet",
      pt: "Ilha Bouvet",
      nl: "Bouveteiland",
      hr: "Otok Bouvet",
      fa: "جزیره بووه",
      de: "Bouvetinsel",
      es: "Isla Bouvet",
      fr: "Île Bouvet",
      ja: "ブーベ島",
      it: "Isola Bouvet",
      cn: "布维岛",
      tr: "Bouvet Adasi",
    },
    latitude: "-54.43333333",
    longitude: "3.40000000",
    emoji: "🇧🇻",
    emojiU: "U+1F1E7 U+1F1FB",
  },
  {
    id: 31,
    name: "Brazil",
    iso3: "BRA",
    iso2: "BR",
    numeric_code: "076",
    phone_code: "55",
    capital: "Brasilia",
    currency: "BRL",
    currency_name: "Brazilian real",
    currency_symbol: "R$",
    tld: ".br",
    native: "Brasil",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "America/Araguaina",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Bahia",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Belem",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Boa_Vista",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AMT",
        tzName: "Amazon Time (Brazil)[3",
      },
      {
        zoneName: "America/Campo_Grande",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AMT",
        tzName: "Amazon Time (Brazil)[3",
      },
      {
        zoneName: "America/Cuiaba",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "BRT",
        tzName: "Brasilia Time",
      },
      {
        zoneName: "America/Eirunepe",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "ACT",
        tzName: "Acre Time",
      },
      {
        zoneName: "America/Fortaleza",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Maceio",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Manaus",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AMT",
        tzName: "Amazon Time (Brazil)",
      },
      {
        zoneName: "America/Noronha",
        gmtOffset: -7200,
        gmtOffsetName: "UTC-02:00",
        abbreviation: "FNT",
        tzName: "Fernando de Noronha Time",
      },
      {
        zoneName: "America/Porto_Velho",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AMT",
        tzName: "Amazon Time (Brazil)[3",
      },
      {
        zoneName: "America/Recife",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Rio_Branco",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "ACT",
        tzName: "Acre Time",
      },
      {
        zoneName: "America/Santarem",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Sao_Paulo",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
    ],
    translations: {
      kr: "브라질",
      "pt-BR": "Brasil",
      pt: "Brasil",
      nl: "Brazilië",
      hr: "Brazil",
      fa: "برزیل",
      de: "Brasilien",
      es: "Brasil",
      fr: "Brésil",
      ja: "ブラジル",
      it: "Brasile",
      cn: "巴西",
      tr: "Brezilya",
    },
    latitude: "-10.00000000",
    longitude: "-55.00000000",
    emoji: "🇧🇷",
    emojiU: "U+1F1E7 U+1F1F7",
  },
  {
    id: 32,
    name: "British Indian Ocean Territory",
    iso3: "IOT",
    iso2: "IO",
    numeric_code: "086",
    phone_code: "246",
    capital: "Diego Garcia",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".io",
    native: "British Indian Ocean Territory",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Indian/Chagos",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "IOT",
        tzName: "Indian Ocean Time",
      },
    ],
    translations: {
      kr: "영국령 인도양 지역",
      "pt-BR": "Território Britânico do Oceano íÍdico",
      pt: "Território Britânico do Oceano Índico",
      nl: "Britse Gebieden in de Indische Oceaan",
      hr: "Britanski Indijskooceanski teritorij",
      fa: "قلمرو بریتانیا در اقیانوس هند",
      de: "Britisches Territorium im Indischen Ozean",
      es: "Territorio Británico del Océano Índico",
      fr: "Territoire britannique de l'océan Indien",
      ja: "イギリス領インド洋地域",
      it: "Territorio britannico dell'oceano indiano",
      cn: "英属印度洋领地",
      tr: "Britanya Hint Okyanusu Topraklari",
    },
    latitude: "-6.00000000",
    longitude: "71.50000000",
    emoji: "🇮🇴",
    emojiU: "U+1F1EE U+1F1F4",
  },
  {
    id: 33,
    name: "Brunei",
    iso3: "BRN",
    iso2: "BN",
    numeric_code: "096",
    phone_code: "673",
    capital: "Bandar Seri Begawan",
    currency: "BND",
    currency_name: "Brunei dollar",
    currency_symbol: "B$",
    tld: ".bn",
    native: "Negara Brunei Darussalam",
    region: "Asia",
    subregion: "South-Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Brunei",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "BNT",
        tzName: "Brunei Darussalam Time",
      },
    ],
    translations: {
      kr: "브루나이",
      "pt-BR": "Brunei",
      pt: "Brunei",
      nl: "Brunei",
      hr: "Brunej",
      fa: "برونئی",
      de: "Brunei",
      es: "Brunei",
      fr: "Brunei",
      ja: "ブルネイ・ダルサラーム",
      it: "Brunei",
      cn: "文莱",
      tr: "Brunei",
    },
    latitude: "4.50000000",
    longitude: "114.66666666",
    emoji: "🇧🇳",
    emojiU: "U+1F1E7 U+1F1F3",
  },
  {
    id: 34,
    name: "Bulgaria",
    iso3: "BGR",
    iso2: "BG",
    numeric_code: "100",
    phone_code: "359",
    capital: "Sofia",
    currency: "BGN",
    currency_name: "Bulgarian lev",
    currency_symbol: "Лв.",
    tld: ".bg",
    native: "България",
    region: "Europe",
    subregion: "Eastern Europe",
    timezones: [
      {
        zoneName: "Europe/Sofia",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "불가리아",
      "pt-BR": "Bulgária",
      pt: "Bulgária",
      nl: "Bulgarije",
      hr: "Bugarska",
      fa: "بلغارستان",
      de: "Bulgarien",
      es: "Bulgaria",
      fr: "Bulgarie",
      ja: "ブルガリア",
      it: "Bulgaria",
      cn: "保加利亚",
      tr: "Bulgaristan",
    },
    latitude: "43.00000000",
    longitude: "25.00000000",
    emoji: "🇧🇬",
    emojiU: "U+1F1E7 U+1F1EC",
  },
  {
    id: 35,
    name: "Burkina Faso",
    iso3: "BFA",
    iso2: "BF",
    numeric_code: "854",
    phone_code: "226",
    capital: "Ouagadougou",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".bf",
    native: "Burkina Faso",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Ouagadougou",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "부르키나 파소",
      "pt-BR": "Burkina Faso",
      pt: "Burquina Faso",
      nl: "Burkina Faso",
      hr: "Burkina Faso",
      fa: "بورکینافاسو",
      de: "Burkina Faso",
      es: "Burkina Faso",
      fr: "Burkina Faso",
      ja: "ブルキナファソ",
      it: "Burkina Faso",
      cn: "布基纳法索",
      tr: "Burkina Faso",
    },
    latitude: "13.00000000",
    longitude: "-2.00000000",
    emoji: "🇧🇫",
    emojiU: "U+1F1E7 U+1F1EB",
  },
  {
    id: 36,
    name: "Burundi",
    iso3: "BDI",
    iso2: "BI",
    numeric_code: "108",
    phone_code: "257",
    capital: "Bujumbura",
    currency: "BIF",
    currency_name: "Burundian franc",
    currency_symbol: "FBu",
    tld: ".bi",
    native: "Burundi",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Africa/Bujumbura",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "부룬디",
      "pt-BR": "Burundi",
      pt: "Burúndi",
      nl: "Burundi",
      hr: "Burundi",
      fa: "بوروندی",
      de: "Burundi",
      es: "Burundi",
      fr: "Burundi",
      ja: "ブルンジ",
      it: "Burundi",
      cn: "布隆迪",
      tr: "Burundi",
    },
    latitude: "-3.50000000",
    longitude: "30.00000000",
    emoji: "🇧🇮",
    emojiU: "U+1F1E7 U+1F1EE",
  },
  {
    id: 37,
    name: "Cambodia",
    iso3: "KHM",
    iso2: "KH",
    numeric_code: "116",
    phone_code: "855",
    capital: "Phnom Penh",
    currency: "KHR",
    currency_name: "Cambodian riel",
    currency_symbol: "KHR",
    tld: ".kh",
    native: "Kâmpŭchéa",
    region: "Asia",
    subregion: "South-Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Phnom_Penh",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "ICT",
        tzName: "Indochina Time",
      },
    ],
    translations: {
      kr: "캄보디아",
      "pt-BR": "Camboja",
      pt: "Camboja",
      nl: "Cambodja",
      hr: "Kambodža",
      fa: "کامبوج",
      de: "Kambodscha",
      es: "Camboya",
      fr: "Cambodge",
      ja: "カンボジア",
      it: "Cambogia",
      cn: "柬埔寨",
      tr: "Kamboçya",
    },
    latitude: "13.00000000",
    longitude: "105.00000000",
    emoji: "🇰🇭",
    emojiU: "U+1F1F0 U+1F1ED",
  },
  {
    id: 38,
    name: "Cameroon",
    iso3: "CMR",
    iso2: "CM",
    numeric_code: "120",
    phone_code: "237",
    capital: "Yaounde",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".cm",
    native: "Cameroon",
    region: "Africa",
    subregion: "Middle Africa",
    timezones: [
      {
        zoneName: "Africa/Douala",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "카메룬",
      "pt-BR": "Camarões",
      pt: "Camarões",
      nl: "Kameroen",
      hr: "Kamerun",
      fa: "کامرون",
      de: "Kamerun",
      es: "Camerún",
      fr: "Cameroun",
      ja: "カメルーン",
      it: "Camerun",
      cn: "喀麦隆",
      tr: "Kamerun",
    },
    latitude: "6.00000000",
    longitude: "12.00000000",
    emoji: "🇨🇲",
    emojiU: "U+1F1E8 U+1F1F2",
  },
  {
    id: 39,
    name: "Canada",
    iso3: "CAN",
    iso2: "CA",
    numeric_code: "124",
    phone_code: "1",
    capital: "Ottawa",
    currency: "CAD",
    currency_name: "Canadian dollar",
    currency_symbol: "$",
    tld: ".ca",
    native: "Canada",
    region: "Americas",
    subregion: "Northern America",
    timezones: [
      {
        zoneName: "America/Atikokan",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America)",
      },
      {
        zoneName: "America/Blanc-Sablon",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Cambridge_Bay",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Creston",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Dawson",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Dawson_Creek",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Edmonton",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Fort_Nelson",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Glace_Bay",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Goose_Bay",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Halifax",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Inuvik",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Iqaluit",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Moncton",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Nipigon",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Pangnirtung",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Rainy_River",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Rankin_Inlet",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Regina",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Resolute",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/St_Johns",
        gmtOffset: -12600,
        gmtOffsetName: "UTC-03:30",
        abbreviation: "NST",
        tzName: "Newfoundland Standard Time",
      },
      {
        zoneName: "America/Swift_Current",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Thunder_Bay",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Toronto",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Vancouver",
        gmtOffset: -28800,
        gmtOffsetName: "UTC-08:00",
        abbreviation: "PST",
        tzName: "Pacific Standard Time (North America",
      },
      {
        zoneName: "America/Whitehorse",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Winnipeg",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Yellowknife",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
    ],
    translations: {
      kr: "캐나다",
      "pt-BR": "Canadá",
      pt: "Canadá",
      nl: "Canada",
      hr: "Kanada",
      fa: "کانادا",
      de: "Kanada",
      es: "Canadá",
      fr: "Canada",
      ja: "カナダ",
      it: "Canada",
      cn: "加拿大",
      tr: "Kanada",
    },
    latitude: "60.00000000",
    longitude: "-95.00000000",
    emoji: "🇨🇦",
    emojiU: "U+1F1E8 U+1F1E6",
  },
  {
    id: 40,
    name: "Cape Verde",
    iso3: "CPV",
    iso2: "CV",
    numeric_code: "132",
    phone_code: "238",
    capital: "Praia",
    currency: "CVE",
    currency_name: "Cape Verdean escudo",
    currency_symbol: "$",
    tld: ".cv",
    native: "Cabo Verde",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Atlantic/Cape_Verde",
        gmtOffset: -3600,
        gmtOffsetName: "UTC-01:00",
        abbreviation: "CVT",
        tzName: "Cape Verde Time",
      },
    ],
    translations: {
      kr: "카보베르데",
      "pt-BR": "Cabo Verde",
      pt: "Cabo Verde",
      nl: "Kaapverdië",
      hr: "Zelenortska Republika",
      fa: "کیپ ورد",
      de: "Kap Verde",
      es: "Cabo Verde",
      fr: "Cap Vert",
      ja: "カーボベルデ",
      it: "Capo Verde",
      cn: "佛得角",
      tr: "Cabo Verde",
    },
    latitude: "16.00000000",
    longitude: "-24.00000000",
    emoji: "🇨🇻",
    emojiU: "U+1F1E8 U+1F1FB",
  },
  {
    id: 41,
    name: "Cayman Islands",
    iso3: "CYM",
    iso2: "KY",
    numeric_code: "136",
    phone_code: "+1-345",
    capital: "George Town",
    currency: "KYD",
    currency_name: "Cayman Islands dollar",
    currency_symbol: "$",
    tld: ".ky",
    native: "Cayman Islands",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Cayman",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
    ],
    translations: {
      kr: "케이먼 제도",
      "pt-BR": "Ilhas Cayman",
      pt: "Ilhas Caimão",
      nl: "Caymaneilanden",
      hr: "Kajmanski otoci",
      fa: "جزایر کیمن",
      de: "Kaimaninseln",
      es: "Islas Caimán",
      fr: "Îles Caïmans",
      ja: "ケイマン諸島",
      it: "Isole Cayman",
      cn: "开曼群岛",
      tr: "Cayman Adalari",
    },
    latitude: "19.50000000",
    longitude: "-80.50000000",
    emoji: "🇰🇾",
    emojiU: "U+1F1F0 U+1F1FE",
  },
  {
    id: 42,
    name: "Central African Republic",
    iso3: "CAF",
    iso2: "CF",
    numeric_code: "140",
    phone_code: "236",
    capital: "Bangui",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".cf",
    native: "Ködörösêse tî Bêafrîka",
    region: "Africa",
    subregion: "Middle Africa",
    timezones: [
      {
        zoneName: "Africa/Bangui",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "중앙아프리카 공화국",
      "pt-BR": "República Centro-Africana",
      pt: "República Centro-Africana",
      nl: "Centraal-Afrikaanse Republiek",
      hr: "Srednjoafrička Republika",
      fa: "جمهوری آفریقای مرکزی",
      de: "Zentralafrikanische Republik",
      es: "República Centroafricana",
      fr: "République centrafricaine",
      ja: "中央アフリカ共和国",
      it: "Repubblica Centrafricana",
      cn: "中非",
      tr: "Orta Afrika Cumhuriyeti",
    },
    latitude: "7.00000000",
    longitude: "21.00000000",
    emoji: "🇨🇫",
    emojiU: "U+1F1E8 U+1F1EB",
  },
  {
    id: 43,
    name: "Chad",
    iso3: "TCD",
    iso2: "TD",
    numeric_code: "148",
    phone_code: "235",
    capital: "N'Djamena",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".td",
    native: "Tchad",
    region: "Africa",
    subregion: "Middle Africa",
    timezones: [
      {
        zoneName: "Africa/Ndjamena",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "차드",
      "pt-BR": "Chade",
      pt: "Chade",
      nl: "Tsjaad",
      hr: "Čad",
      fa: "چاد",
      de: "Tschad",
      es: "Chad",
      fr: "Tchad",
      ja: "チャド",
      it: "Ciad",
      cn: "乍得",
      tr: "Çad",
    },
    latitude: "15.00000000",
    longitude: "19.00000000",
    emoji: "🇹🇩",
    emojiU: "U+1F1F9 U+1F1E9",
  },
  {
    id: 44,
    name: "Chile",
    iso3: "CHL",
    iso2: "CL",
    numeric_code: "152",
    phone_code: "56",
    capital: "Santiago",
    currency: "CLP",
    currency_name: "Chilean peso",
    currency_symbol: "$",
    tld: ".cl",
    native: "Chile",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "America/Punta_Arenas",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "CLST",
        tzName: "Chile Summer Time",
      },
      {
        zoneName: "America/Santiago",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "CLST",
        tzName: "Chile Summer Time",
      },
      {
        zoneName: "Pacific/Easter",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EASST",
        tzName: "Easter Island Summer Time",
      },
    ],
    translations: {
      kr: "칠리",
      "pt-BR": "Chile",
      pt: "Chile",
      nl: "Chili",
      hr: "Čile",
      fa: "شیلی",
      de: "Chile",
      es: "Chile",
      fr: "Chili",
      ja: "チリ",
      it: "Cile",
      cn: "智利",
      tr: "Şili",
    },
    latitude: "-30.00000000",
    longitude: "-71.00000000",
    emoji: "🇨🇱",
    emojiU: "U+1F1E8 U+1F1F1",
  },
  {
    id: 45,
    name: "China",
    iso3: "CHN",
    iso2: "CN",
    numeric_code: "156",
    phone_code: "86",
    capital: "Beijing",
    currency: "CNY",
    currency_name: "Chinese yuan",
    currency_symbol: "¥",
    tld: ".cn",
    native: "中国",
    region: "Asia",
    subregion: "Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Shanghai",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "CST",
        tzName: "China Standard Time",
      },
      {
        zoneName: "Asia/Urumqi",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "XJT",
        tzName: "China Standard Time",
      },
    ],
    translations: {
      kr: "중국",
      "pt-BR": "China",
      pt: "China",
      nl: "China",
      hr: "Kina",
      fa: "چین",
      de: "China",
      es: "China",
      fr: "Chine",
      ja: "中国",
      it: "Cina",
      cn: "中国",
      tr: "Çin",
    },
    latitude: "35.00000000",
    longitude: "105.00000000",
    emoji: "🇨🇳",
    emojiU: "U+1F1E8 U+1F1F3",
  },
  {
    id: 46,
    name: "Christmas Island",
    iso3: "CXR",
    iso2: "CX",
    numeric_code: "162",
    phone_code: "61",
    capital: "Flying Fish Cove",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".cx",
    native: "Christmas Island",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    timezones: [
      {
        zoneName: "Indian/Christmas",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "CXT",
        tzName: "Christmas Island Time",
      },
    ],
    translations: {
      kr: "크리스마스 섬",
      "pt-BR": "Ilha Christmas",
      pt: "Ilha do Natal",
      nl: "Christmaseiland",
      hr: "Božićni otok",
      fa: "جزیره کریسمس",
      de: "Weihnachtsinsel",
      es: "Isla de Navidad",
      fr: "Île Christmas",
      ja: "クリスマス島",
      it: "Isola di Natale",
      cn: "圣诞岛",
      tr: "Christmas Adasi",
    },
    latitude: "-10.50000000",
    longitude: "105.66666666",
    emoji: "🇨🇽",
    emojiU: "U+1F1E8 U+1F1FD",
  },
  {
    id: 47,
    name: "Cocos (Keeling) Islands",
    iso3: "CCK",
    iso2: "CC",
    numeric_code: "166",
    phone_code: "61",
    capital: "West Island",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".cc",
    native: "Cocos (Keeling) Islands",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    timezones: [
      {
        zoneName: "Indian/Cocos",
        gmtOffset: 23400,
        gmtOffsetName: "UTC+06:30",
        abbreviation: "CCT",
        tzName: "Cocos Islands Time",
      },
    ],
    translations: {
      kr: "코코스 제도",
      "pt-BR": "Ilhas Cocos",
      pt: "Ilhas dos Cocos",
      nl: "Cocoseilanden",
      hr: "Kokosovi Otoci",
      fa: "جزایر کوکوس",
      de: "Kokosinseln",
      es: "Islas Cocos o Islas Keeling",
      fr: "Îles Cocos",
      ja: "ココス（キーリング）諸島",
      it: "Isole Cocos e Keeling",
      cn: "科科斯（基林）群岛",
      tr: "Cocos Adalari",
    },
    latitude: "-12.50000000",
    longitude: "96.83333333",
    emoji: "🇨🇨",
    emojiU: "U+1F1E8 U+1F1E8",
  },
  {
    id: 48,
    name: "Colombia",
    iso3: "COL",
    iso2: "CO",
    numeric_code: "170",
    phone_code: "57",
    capital: "Bogotá",
    currency: "COP",
    currency_name: "Colombian peso",
    currency_symbol: "$",
    tld: ".co",
    native: "Colombia",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "America/Bogota",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "COT",
        tzName: "Colombia Time",
      },
    ],
    translations: {
      kr: "콜롬비아",
      "pt-BR": "Colômbia",
      pt: "Colômbia",
      nl: "Colombia",
      hr: "Kolumbija",
      fa: "کلمبیا",
      de: "Kolumbien",
      es: "Colombia",
      fr: "Colombie",
      ja: "コロンビア",
      it: "Colombia",
      cn: "哥伦比亚",
      tr: "Kolombiya",
    },
    latitude: "4.00000000",
    longitude: "-72.00000000",
    emoji: "🇨🇴",
    emojiU: "U+1F1E8 U+1F1F4",
  },
  {
    id: 49,
    name: "Comoros",
    iso3: "COM",
    iso2: "KM",
    numeric_code: "174",
    phone_code: "269",
    capital: "Moroni",
    currency: "KMF",
    currency_name: "Comorian franc",
    currency_symbol: "CF",
    tld: ".km",
    native: "Komori",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Indian/Comoro",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "코모로",
      "pt-BR": "Comores",
      pt: "Comores",
      nl: "Comoren",
      hr: "Komori",
      fa: "کومور",
      de: "Union der Komoren",
      es: "Comoras",
      fr: "Comores",
      ja: "コモロ",
      it: "Comore",
      cn: "科摩罗",
      tr: "Komorlar",
    },
    latitude: "-12.16666666",
    longitude: "44.25000000",
    emoji: "🇰🇲",
    emojiU: "U+1F1F0 U+1F1F2",
  },
  {
    id: 50,
    name: "Congo",
    iso3: "COG",
    iso2: "CG",
    numeric_code: "178",
    phone_code: "242",
    capital: "Brazzaville",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FC",
    tld: ".cg",
    native: "République du Congo",
    region: "Africa",
    subregion: "Middle Africa",
    timezones: [
      {
        zoneName: "Africa/Brazzaville",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "콩고",
      "pt-BR": "Congo",
      pt: "Congo",
      nl: "Congo [Republiek]",
      hr: "Kongo",
      fa: "کنگو",
      de: "Kongo",
      es: "Congo",
      fr: "Congo",
      ja: "コンゴ共和国",
      it: "Congo",
      cn: "刚果",
      tr: "Kongo",
    },
    latitude: "-1.00000000",
    longitude: "15.00000000",
    emoji: "🇨🇬",
    emojiU: "U+1F1E8 U+1F1EC",
  },
  {
    id: 52,
    name: "Cook Islands",
    iso3: "COK",
    iso2: "CK",
    numeric_code: "184",
    phone_code: "682",
    capital: "Avarua",
    currency: "NZD",
    currency_name: "Cook Islands dollar",
    currency_symbol: "$",
    tld: ".ck",
    native: "Cook Islands",
    region: "Oceania",
    subregion: "Polynesia",
    timezones: [
      {
        zoneName: "Pacific/Rarotonga",
        gmtOffset: -36000,
        gmtOffsetName: "UTC-10:00",
        abbreviation: "CKT",
        tzName: "Cook Island Time",
      },
    ],
    translations: {
      kr: "쿡 제도",
      "pt-BR": "Ilhas Cook",
      pt: "Ilhas Cook",
      nl: "Cookeilanden",
      hr: "Cookovo Otočje",
      fa: "جزایر کوک",
      de: "Cookinseln",
      es: "Islas Cook",
      fr: "Îles Cook",
      ja: "クック諸島",
      it: "Isole Cook",
      cn: "库克群岛",
      tr: "Cook Adalari",
    },
    latitude: "-21.23333333",
    longitude: "-159.76666666",
    emoji: "🇨🇰",
    emojiU: "U+1F1E8 U+1F1F0",
  },
  {
    id: 53,
    name: "Costa Rica",
    iso3: "CRI",
    iso2: "CR",
    numeric_code: "188",
    phone_code: "506",
    capital: "San Jose",
    currency: "CRC",
    currency_name: "Costa Rican colón",
    currency_symbol: "₡",
    tld: ".cr",
    native: "Costa Rica",
    region: "Americas",
    subregion: "Central America",
    timezones: [
      {
        zoneName: "America/Costa_Rica",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
    ],
    translations: {
      kr: "코스타리카",
      "pt-BR": "Costa Rica",
      pt: "Costa Rica",
      nl: "Costa Rica",
      hr: "Kostarika",
      fa: "کاستاریکا",
      de: "Costa Rica",
      es: "Costa Rica",
      fr: "Costa Rica",
      ja: "コスタリカ",
      it: "Costa Rica",
      cn: "哥斯达黎加",
      tr: "Kosta Rika",
    },
    latitude: "10.00000000",
    longitude: "-84.00000000",
    emoji: "🇨🇷",
    emojiU: "U+1F1E8 U+1F1F7",
  },
  {
    id: 54,
    name: "Cote D'Ivoire (Ivory Coast)",
    iso3: "CIV",
    iso2: "CI",
    numeric_code: "384",
    phone_code: "225",
    capital: "Yamoussoukro",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".ci",
    native: null,
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Abidjan",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "코트디부아르",
      "pt-BR": "Costa do Marfim",
      pt: "Costa do Marfim",
      nl: "Ivoorkust",
      hr: "Obala Bjelokosti",
      fa: "ساحل عاج",
      de: "Elfenbeinküste",
      es: "Costa de Marfil",
      fr: "Côte d'Ivoire",
      ja: "コートジボワール",
      it: "Costa D'Avorio",
      cn: "科特迪瓦",
      tr: "Kotdivuar",
    },
    latitude: "8.00000000",
    longitude: "-5.00000000",
    emoji: "🇨🇮",
    emojiU: "U+1F1E8 U+1F1EE",
  },
  {
    id: 55,
    name: "Croatia",
    iso3: "HRV",
    iso2: "HR",
    numeric_code: "191",
    phone_code: "385",
    capital: "Zagreb",
    currency: "HRK",
    currency_name: "Croatian kuna",
    currency_symbol: "kn",
    tld: ".hr",
    native: "Hrvatska",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/Zagreb",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "크로아티아",
      "pt-BR": "Croácia",
      pt: "Croácia",
      nl: "Kroatië",
      hr: "Hrvatska",
      fa: "کرواسی",
      de: "Kroatien",
      es: "Croacia",
      fr: "Croatie",
      ja: "クロアチア",
      it: "Croazia",
      cn: "克罗地亚",
      tr: "Hirvatistan",
    },
    latitude: "45.16666666",
    longitude: "15.50000000",
    emoji: "🇭🇷",
    emojiU: "U+1F1ED U+1F1F7",
  },
  {
    id: 56,
    name: "Cuba",
    iso3: "CUB",
    iso2: "CU",
    numeric_code: "192",
    phone_code: "53",
    capital: "Havana",
    currency: "CUP",
    currency_name: "Cuban peso",
    currency_symbol: "$",
    tld: ".cu",
    native: "Cuba",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Havana",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "CST",
        tzName: "Cuba Standard Time",
      },
    ],
    translations: {
      kr: "쿠바",
      "pt-BR": "Cuba",
      pt: "Cuba",
      nl: "Cuba",
      hr: "Kuba",
      fa: "کوبا",
      de: "Kuba",
      es: "Cuba",
      fr: "Cuba",
      ja: "キューバ",
      it: "Cuba",
      cn: "古巴",
      tr: "Küba",
    },
    latitude: "21.50000000",
    longitude: "-80.00000000",
    emoji: "🇨🇺",
    emojiU: "U+1F1E8 U+1F1FA",
  },
  {
    id: 249,
    name: "Curaçao",
    iso3: "CUW",
    iso2: "CW",
    numeric_code: "531",
    phone_code: "599",
    capital: "Willemstad",
    currency: "ANG",
    currency_name: "Netherlands Antillean guilder",
    currency_symbol: "ƒ",
    tld: ".cw",
    native: "Curaçao",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Curacao",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "퀴라소",
      "pt-BR": "Curaçao",
      pt: "Curaçao",
      nl: "Curaçao",
      fa: "کوراسائو",
      de: "Curaçao",
      fr: "Curaçao",
      it: "Curaçao",
      cn: "库拉索",
      tr: "Curaçao",
    },
    latitude: "12.11666700",
    longitude: "-68.93333300",
    emoji: "🇨🇼",
    emojiU: "U+1F1E8 U+1F1FC",
  },
  {
    id: 57,
    name: "Cyprus",
    iso3: "CYP",
    iso2: "CY",
    numeric_code: "196",
    phone_code: "357",
    capital: "Nicosia",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".cy",
    native: "Κύπρος",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Asia/Famagusta",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
      {
        zoneName: "Asia/Nicosia",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "키프로스",
      "pt-BR": "Chipre",
      pt: "Chipre",
      nl: "Cyprus",
      hr: "Cipar",
      fa: "قبرس",
      de: "Zypern",
      es: "Chipre",
      fr: "Chypre",
      ja: "キプロス",
      it: "Cipro",
      cn: "塞浦路斯",
      tr: "Kuzey Kıbrıs Türk Cumhuriyeti",
    },
    latitude: "35.00000000",
    longitude: "33.00000000",
    emoji: "🇨🇾",
    emojiU: "U+1F1E8 U+1F1FE",
  },
  {
    id: 58,
    name: "Czech Republic",
    iso3: "CZE",
    iso2: "CZ",
    numeric_code: "203",
    phone_code: "420",
    capital: "Prague",
    currency: "CZK",
    currency_name: "Czech koruna",
    currency_symbol: "Kč",
    tld: ".cz",
    native: "Česká republika",
    region: "Europe",
    subregion: "Eastern Europe",
    timezones: [
      {
        zoneName: "Europe/Prague",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "체코",
      "pt-BR": "República Tcheca",
      pt: "República Checa",
      nl: "Tsjechië",
      hr: "Češka",
      fa: "جمهوری چک",
      de: "Tschechische Republik",
      es: "República Checa",
      fr: "République tchèque",
      ja: "チェコ",
      it: "Repubblica Ceca",
      cn: "捷克",
      tr: "Çekya",
    },
    latitude: "49.75000000",
    longitude: "15.50000000",
    emoji: "🇨🇿",
    emojiU: "U+1F1E8 U+1F1FF",
  },
  {
    id: 51,
    name: "Democratic Republic of the Congo",
    iso3: "COD",
    iso2: "CD",
    numeric_code: "180",
    phone_code: "243",
    capital: "Kinshasa",
    currency: "CDF",
    currency_name: "Congolese Franc",
    currency_symbol: "FC",
    tld: ".cd",
    native: "République démocratique du Congo",
    region: "Africa",
    subregion: "Middle Africa",
    timezones: [
      {
        zoneName: "Africa/Kinshasa",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
      {
        zoneName: "Africa/Lubumbashi",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "콩고 민주 공화국",
      "pt-BR": "RD Congo",
      pt: "RD Congo",
      nl: "Congo [DRC]",
      hr: "Kongo, Demokratska Republika",
      fa: "جمهوری کنگو",
      de: "Kongo (Dem. Rep.)",
      es: "Congo (Rep. Dem.)",
      fr: "Congo (Rép. dém.)",
      ja: "コンゴ民主共和国",
      it: "Congo (Rep. Dem.)",
      cn: "刚果（金）",
      tr: "Kongo Demokratik Cumhuriyeti",
    },
    latitude: "0.00000000",
    longitude: "25.00000000",
    emoji: "🇨🇩",
    emojiU: "U+1F1E8 U+1F1E9",
  },
  {
    id: 59,
    name: "Denmark",
    iso3: "DNK",
    iso2: "DK",
    numeric_code: "208",
    phone_code: "45",
    capital: "Copenhagen",
    currency: "DKK",
    currency_name: "Danish krone",
    currency_symbol: "Kr.",
    tld: ".dk",
    native: "Danmark",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Europe/Copenhagen",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "덴마크",
      "pt-BR": "Dinamarca",
      pt: "Dinamarca",
      nl: "Denemarken",
      hr: "Danska",
      fa: "دانمارک",
      de: "Dänemark",
      es: "Dinamarca",
      fr: "Danemark",
      ja: "デンマーク",
      it: "Danimarca",
      cn: "丹麦",
      tr: "Danimarka",
    },
    latitude: "56.00000000",
    longitude: "10.00000000",
    emoji: "🇩🇰",
    emojiU: "U+1F1E9 U+1F1F0",
  },
  {
    id: 60,
    name: "Djibouti",
    iso3: "DJI",
    iso2: "DJ",
    numeric_code: "262",
    phone_code: "253",
    capital: "Djibouti",
    currency: "DJF",
    currency_name: "Djiboutian franc",
    currency_symbol: "Fdj",
    tld: ".dj",
    native: "Djibouti",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Africa/Djibouti",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "지부티",
      "pt-BR": "Djibuti",
      pt: "Djibuti",
      nl: "Djibouti",
      hr: "Džibuti",
      fa: "جیبوتی",
      de: "Dschibuti",
      es: "Yibuti",
      fr: "Djibouti",
      ja: "ジブチ",
      it: "Gibuti",
      cn: "吉布提",
      tr: "Cibuti",
    },
    latitude: "11.50000000",
    longitude: "43.00000000",
    emoji: "🇩🇯",
    emojiU: "U+1F1E9 U+1F1EF",
  },
  {
    id: 61,
    name: "Dominica",
    iso3: "DMA",
    iso2: "DM",
    numeric_code: "212",
    phone_code: "+1-767",
    capital: "Roseau",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".dm",
    native: "Dominica",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Dominica",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "도미니카 연방",
      "pt-BR": "Dominica",
      pt: "Dominica",
      nl: "Dominica",
      hr: "Dominika",
      fa: "دومینیکا",
      de: "Dominica",
      es: "Dominica",
      fr: "Dominique",
      ja: "ドミニカ国",
      it: "Dominica",
      cn: "多米尼加",
      tr: "Dominika",
    },
    latitude: "15.41666666",
    longitude: "-61.33333333",
    emoji: "🇩🇲",
    emojiU: "U+1F1E9 U+1F1F2",
  },
  {
    id: 62,
    name: "Dominican Republic",
    iso3: "DOM",
    iso2: "DO",
    numeric_code: "214",
    phone_code: "+1-809 and 1-829",
    capital: "Santo Domingo",
    currency: "DOP",
    currency_name: "Dominican peso",
    currency_symbol: "$",
    tld: ".do",
    native: "República Dominicana",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Santo_Domingo",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "도미니카 공화국",
      "pt-BR": "República Dominicana",
      pt: "República Dominicana",
      nl: "Dominicaanse Republiek",
      hr: "Dominikanska Republika",
      fa: "جمهوری دومینیکن",
      de: "Dominikanische Republik",
      es: "República Dominicana",
      fr: "République dominicaine",
      ja: "ドミニカ共和国",
      it: "Repubblica Dominicana",
      cn: "多明尼加共和国",
      tr: "Dominik Cumhuriyeti",
    },
    latitude: "19.00000000",
    longitude: "-70.66666666",
    emoji: "🇩🇴",
    emojiU: "U+1F1E9 U+1F1F4",
  },
  {
    id: 63,
    name: "East Timor",
    iso3: "TLS",
    iso2: "TL",
    numeric_code: "626",
    phone_code: "670",
    capital: "Dili",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".tl",
    native: "Timor-Leste",
    region: "Asia",
    subregion: "South-Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Dili",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "TLT",
        tzName: "Timor Leste Time",
      },
    ],
    translations: {
      kr: "동티모르",
      "pt-BR": "Timor Leste",
      pt: "Timor Leste",
      nl: "Oost-Timor",
      hr: "Istočni Timor",
      fa: "تیمور شرقی",
      de: "Timor-Leste",
      es: "Timor Oriental",
      fr: "Timor oriental",
      ja: "東ティモール",
      it: "Timor Est",
      cn: "东帝汶",
      tr: "Doğu Timor",
    },
    latitude: "-8.83333333",
    longitude: "125.91666666",
    emoji: "🇹🇱",
    emojiU: "U+1F1F9 U+1F1F1",
  },
  {
    id: 64,
    name: "Ecuador",
    iso3: "ECU",
    iso2: "EC",
    numeric_code: "218",
    phone_code: "593",
    capital: "Quito",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".ec",
    native: "Ecuador",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "America/Guayaquil",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "ECT",
        tzName: "Ecuador Time",
      },
      {
        zoneName: "Pacific/Galapagos",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "GALT",
        tzName: "Galápagos Time",
      },
    ],
    translations: {
      kr: "에콰도르",
      "pt-BR": "Equador",
      pt: "Equador",
      nl: "Ecuador",
      hr: "Ekvador",
      fa: "اکوادور",
      de: "Ecuador",
      es: "Ecuador",
      fr: "Équateur",
      ja: "エクアドル",
      it: "Ecuador",
      cn: "厄瓜多尔",
      tr: "Ekvator",
    },
    latitude: "-2.00000000",
    longitude: "-77.50000000",
    emoji: "🇪🇨",
    emojiU: "U+1F1EA U+1F1E8",
  },
  {
    id: 65,
    name: "Egypt",
    iso3: "EGY",
    iso2: "EG",
    numeric_code: "818",
    phone_code: "20",
    capital: "Cairo",
    currency: "EGP",
    currency_name: "Egyptian pound",
    currency_symbol: "ج.م",
    tld: ".eg",
    native: "مصر‎",
    region: "Africa",
    subregion: "Northern Africa",
    timezones: [
      {
        zoneName: "Africa/Cairo",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "이집트",
      "pt-BR": "Egito",
      pt: "Egipto",
      nl: "Egypte",
      hr: "Egipat",
      fa: "مصر",
      de: "Ägypten",
      es: "Egipto",
      fr: "Égypte",
      ja: "エジプト",
      it: "Egitto",
      cn: "埃及",
      tr: "Mısır",
    },
    latitude: "27.00000000",
    longitude: "30.00000000",
    emoji: "🇪🇬",
    emojiU: "U+1F1EA U+1F1EC",
  },
  {
    id: 66,
    name: "El Salvador",
    iso3: "SLV",
    iso2: "SV",
    numeric_code: "222",
    phone_code: "503",
    capital: "San Salvador",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".sv",
    native: "El Salvador",
    region: "Americas",
    subregion: "Central America",
    timezones: [
      {
        zoneName: "America/El_Salvador",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
    ],
    translations: {
      kr: "엘살바도르",
      "pt-BR": "El Salvador",
      pt: "El Salvador",
      nl: "El Salvador",
      hr: "Salvador",
      fa: "السالوادور",
      de: "El Salvador",
      es: "El Salvador",
      fr: "Salvador",
      ja: "エルサルバドル",
      it: "El Salvador",
      cn: "萨尔瓦多",
      tr: "El Salvador",
    },
    latitude: "13.83333333",
    longitude: "-88.91666666",
    emoji: "🇸🇻",
    emojiU: "U+1F1F8 U+1F1FB",
  },
  {
    id: 67,
    name: "Equatorial Guinea",
    iso3: "GNQ",
    iso2: "GQ",
    numeric_code: "226",
    phone_code: "240",
    capital: "Malabo",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".gq",
    native: "Guinea Ecuatorial",
    region: "Africa",
    subregion: "Middle Africa",
    timezones: [
      {
        zoneName: "Africa/Malabo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "적도 기니",
      "pt-BR": "Guiné Equatorial",
      pt: "Guiné Equatorial",
      nl: "Equatoriaal-Guinea",
      hr: "Ekvatorijalna Gvineja",
      fa: "گینه استوایی",
      de: "Äquatorial-Guinea",
      es: "Guinea Ecuatorial",
      fr: "Guinée-Équatoriale",
      ja: "赤道ギニア",
      it: "Guinea Equatoriale",
      cn: "赤道几内亚",
      tr: "Ekvator Ginesi",
    },
    latitude: "2.00000000",
    longitude: "10.00000000",
    emoji: "🇬🇶",
    emojiU: "U+1F1EC U+1F1F6",
  },
  {
    id: 68,
    name: "Eritrea",
    iso3: "ERI",
    iso2: "ER",
    numeric_code: "232",
    phone_code: "291",
    capital: "Asmara",
    currency: "ERN",
    currency_name: "Eritrean nakfa",
    currency_symbol: "Nfk",
    tld: ".er",
    native: "ኤርትራ",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Africa/Asmara",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "에리트레아",
      "pt-BR": "Eritreia",
      pt: "Eritreia",
      nl: "Eritrea",
      hr: "Eritreja",
      fa: "اریتره",
      de: "Eritrea",
      es: "Eritrea",
      fr: "Érythrée",
      ja: "エリトリア",
      it: "Eritrea",
      cn: "厄立特里亚",
      tr: "Eritre",
    },
    latitude: "15.00000000",
    longitude: "39.00000000",
    emoji: "🇪🇷",
    emojiU: "U+1F1EA U+1F1F7",
  },
  {
    id: 69,
    name: "Estonia",
    iso3: "EST",
    iso2: "EE",
    numeric_code: "233",
    phone_code: "372",
    capital: "Tallinn",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".ee",
    native: "Eesti",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Europe/Tallinn",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "에스토니아",
      "pt-BR": "Estônia",
      pt: "Estónia",
      nl: "Estland",
      hr: "Estonija",
      fa: "استونی",
      de: "Estland",
      es: "Estonia",
      fr: "Estonie",
      ja: "エストニア",
      it: "Estonia",
      cn: "爱沙尼亚",
      tr: "Estonya",
    },
    latitude: "59.00000000",
    longitude: "26.00000000",
    emoji: "🇪🇪",
    emojiU: "U+1F1EA U+1F1EA",
  },
  {
    id: 70,
    name: "Ethiopia",
    iso3: "ETH",
    iso2: "ET",
    numeric_code: "231",
    phone_code: "251",
    capital: "Addis Ababa",
    currency: "ETB",
    currency_name: "Ethiopian birr",
    currency_symbol: "Nkf",
    tld: ".et",
    native: "ኢትዮጵያ",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Africa/Addis_Ababa",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "에티오피아",
      "pt-BR": "Etiópia",
      pt: "Etiópia",
      nl: "Ethiopië",
      hr: "Etiopija",
      fa: "اتیوپی",
      de: "Äthiopien",
      es: "Etiopía",
      fr: "Éthiopie",
      ja: "エチオピア",
      it: "Etiopia",
      cn: "埃塞俄比亚",
      tr: "Etiyopya",
    },
    latitude: "8.00000000",
    longitude: "38.00000000",
    emoji: "🇪🇹",
    emojiU: "U+1F1EA U+1F1F9",
  },
  {
    id: 71,
    name: "Falkland Islands",
    iso3: "FLK",
    iso2: "FK",
    numeric_code: "238",
    phone_code: "500",
    capital: "Stanley",
    currency: "FKP",
    currency_name: "Falkland Islands pound",
    currency_symbol: "£",
    tld: ".fk",
    native: "Falkland Islands",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "Atlantic/Stanley",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "FKST",
        tzName: "Falkland Islands Summer Time",
      },
    ],
    translations: {
      kr: "포클랜드 제도",
      "pt-BR": "Ilhas Malvinas",
      pt: "Ilhas Falkland",
      nl: "Falklandeilanden [Islas Malvinas]",
      hr: "Falklandski Otoci",
      fa: "جزایر فالکلند",
      de: "Falklandinseln",
      es: "Islas Malvinas",
      fr: "Îles Malouines",
      ja: "フォークランド（マルビナス）諸島",
      it: "Isole Falkland o Isole Malvine",
      cn: "福克兰群岛",
      tr: "Falkland Adalari",
    },
    latitude: "-51.75000000",
    longitude: "-59.00000000",
    emoji: "🇫🇰",
    emojiU: "U+1F1EB U+1F1F0",
  },
  {
    id: 72,
    name: "Faroe Islands",
    iso3: "FRO",
    iso2: "FO",
    numeric_code: "234",
    phone_code: "298",
    capital: "Torshavn",
    currency: "DKK",
    currency_name: "Danish krone",
    currency_symbol: "Kr.",
    tld: ".fo",
    native: "Føroyar",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Atlantic/Faroe",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "WET",
        tzName: "Western European Time",
      },
    ],
    translations: {
      kr: "페로 제도",
      "pt-BR": "Ilhas Faroé",
      pt: "Ilhas Faroé",
      nl: "Faeröer",
      hr: "Farski Otoci",
      fa: "جزایر فارو",
      de: "Färöer-Inseln",
      es: "Islas Faroe",
      fr: "Îles Féroé",
      ja: "フェロー諸島",
      it: "Isole Far Oer",
      cn: "法罗群岛",
      tr: "Faroe Adalari",
    },
    latitude: "62.00000000",
    longitude: "-7.00000000",
    emoji: "🇫🇴",
    emojiU: "U+1F1EB U+1F1F4",
  },
  {
    id: 73,
    name: "Fiji Islands",
    iso3: "FJI",
    iso2: "FJ",
    numeric_code: "242",
    phone_code: "679",
    capital: "Suva",
    currency: "FJD",
    currency_name: "Fijian dollar",
    currency_symbol: "FJ$",
    tld: ".fj",
    native: "Fiji",
    region: "Oceania",
    subregion: "Melanesia",
    timezones: [
      {
        zoneName: "Pacific/Fiji",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "FJT",
        tzName: "Fiji Time",
      },
    ],
    translations: {
      kr: "피지",
      "pt-BR": "Fiji",
      pt: "Fiji",
      nl: "Fiji",
      hr: "Fiđi",
      fa: "فیجی",
      de: "Fidschi",
      es: "Fiyi",
      fr: "Fidji",
      ja: "フィジー",
      it: "Figi",
      cn: "斐济",
      tr: "Fiji",
    },
    latitude: "-18.00000000",
    longitude: "175.00000000",
    emoji: "🇫🇯",
    emojiU: "U+1F1EB U+1F1EF",
  },
  {
    id: 74,
    name: "Finland",
    iso3: "FIN",
    iso2: "FI",
    numeric_code: "246",
    phone_code: "358",
    capital: "Helsinki",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".fi",
    native: "Suomi",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Europe/Helsinki",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "핀란드",
      "pt-BR": "Finlândia",
      pt: "Finlândia",
      nl: "Finland",
      hr: "Finska",
      fa: "فنلاند",
      de: "Finnland",
      es: "Finlandia",
      fr: "Finlande",
      ja: "フィンランド",
      it: "Finlandia",
      cn: "芬兰",
      tr: "Finlandiya",
    },
    latitude: "64.00000000",
    longitude: "26.00000000",
    emoji: "🇫🇮",
    emojiU: "U+1F1EB U+1F1EE",
  },
  {
    id: 75,
    name: "France",
    iso3: "FRA",
    iso2: "FR",
    numeric_code: "250",
    phone_code: "33",
    capital: "Paris",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".fr",
    native: "France",
    region: "Europe",
    subregion: "Western Europe",
    timezones: [
      {
        zoneName: "Europe/Paris",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "프랑스",
      "pt-BR": "França",
      pt: "França",
      nl: "Frankrijk",
      hr: "Francuska",
      fa: "فرانسه",
      de: "Frankreich",
      es: "Francia",
      fr: "France",
      ja: "フランス",
      it: "Francia",
      cn: "法国",
      tr: "Fransa",
    },
    latitude: "46.00000000",
    longitude: "2.00000000",
    emoji: "🇫🇷",
    emojiU: "U+1F1EB U+1F1F7",
  },
  {
    id: 76,
    name: "French Guiana",
    iso3: "GUF",
    iso2: "GF",
    numeric_code: "254",
    phone_code: "594",
    capital: "Cayenne",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".gf",
    native: "Guyane française",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "America/Cayenne",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "GFT",
        tzName: "French Guiana Time",
      },
    ],
    translations: {
      kr: "프랑스령 기아나",
      "pt-BR": "Guiana Francesa",
      pt: "Guiana Francesa",
      nl: "Frans-Guyana",
      hr: "Francuska Gvajana",
      fa: "گویان فرانسه",
      de: "Französisch Guyana",
      es: "Guayana Francesa",
      fr: "Guayane",
      ja: "フランス領ギアナ",
      it: "Guyana francese",
      cn: "法属圭亚那",
      tr: "Fransiz Guyanasi",
    },
    latitude: "4.00000000",
    longitude: "-53.00000000",
    emoji: "🇬🇫",
    emojiU: "U+1F1EC U+1F1EB",
  },
  {
    id: 77,
    name: "French Polynesia",
    iso3: "PYF",
    iso2: "PF",
    numeric_code: "258",
    phone_code: "689",
    capital: "Papeete",
    currency: "XPF",
    currency_name: "CFP franc",
    currency_symbol: "₣",
    tld: ".pf",
    native: "Polynésie française",
    region: "Oceania",
    subregion: "Polynesia",
    timezones: [
      {
        zoneName: "Pacific/Gambier",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "GAMT",
        tzName: "Gambier Islands Time",
      },
      {
        zoneName: "Pacific/Marquesas",
        gmtOffset: -34200,
        gmtOffsetName: "UTC-09:30",
        abbreviation: "MART",
        tzName: "Marquesas Islands Time",
      },
      {
        zoneName: "Pacific/Tahiti",
        gmtOffset: -36000,
        gmtOffsetName: "UTC-10:00",
        abbreviation: "TAHT",
        tzName: "Tahiti Time",
      },
    ],
    translations: {
      kr: "프랑스령 폴리네시아",
      "pt-BR": "Polinésia Francesa",
      pt: "Polinésia Francesa",
      nl: "Frans-Polynesië",
      hr: "Francuska Polinezija",
      fa: "پلی‌نزی فرانسه",
      de: "Französisch-Polynesien",
      es: "Polinesia Francesa",
      fr: "Polynésie française",
      ja: "フランス領ポリネシア",
      it: "Polinesia Francese",
      cn: "法属波利尼西亚",
      tr: "Fransiz Polinezyasi",
    },
    latitude: "-15.00000000",
    longitude: "-140.00000000",
    emoji: "🇵🇫",
    emojiU: "U+1F1F5 U+1F1EB",
  },
  {
    id: 78,
    name: "French Southern Territories",
    iso3: "ATF",
    iso2: "TF",
    numeric_code: "260",
    phone_code: "262",
    capital: "Port-aux-Francais",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".tf",
    native: "Territoire des Terres australes et antarctiques fr",
    region: "Africa",
    subregion: "Southern Africa",
    timezones: [
      {
        zoneName: "Indian/Kerguelen",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "TFT",
        tzName: "French Southern and Antarctic Time",
      },
    ],
    translations: {
      kr: "프랑스령 남방 및 남극",
      "pt-BR": "Terras Austrais e Antárticas Francesas",
      pt: "Terras Austrais e Antárticas Francesas",
      nl: "Franse Gebieden in de zuidelijke Indische Oceaan",
      hr: "Francuski južni i antarktički teritoriji",
      fa: "سرزمین‌های جنوبی و جنوبگانی فرانسه",
      de: "Französische Süd- und Antarktisgebiete",
      es: "Tierras Australes y Antárticas Francesas",
      fr: "Terres australes et antarctiques françaises",
      ja: "フランス領南方・南極地域",
      it: "Territori Francesi del Sud",
      cn: "法属南部领地",
      tr: "Fransiz Güney Topraklari",
    },
    latitude: "-49.25000000",
    longitude: "69.16700000",
    emoji: "🇹🇫",
    emojiU: "U+1F1F9 U+1F1EB",
  },
  {
    id: 79,
    name: "Gabon",
    iso3: "GAB",
    iso2: "GA",
    numeric_code: "266",
    phone_code: "241",
    capital: "Libreville",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".ga",
    native: "Gabon",
    region: "Africa",
    subregion: "Middle Africa",
    timezones: [
      {
        zoneName: "Africa/Libreville",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "가봉",
      "pt-BR": "Gabão",
      pt: "Gabão",
      nl: "Gabon",
      hr: "Gabon",
      fa: "گابن",
      de: "Gabun",
      es: "Gabón",
      fr: "Gabon",
      ja: "ガボン",
      it: "Gabon",
      cn: "加蓬",
      tr: "Gabon",
    },
    latitude: "-1.00000000",
    longitude: "11.75000000",
    emoji: "🇬🇦",
    emojiU: "U+1F1EC U+1F1E6",
  },
  {
    id: 80,
    name: "Gambia The",
    iso3: "GMB",
    iso2: "GM",
    numeric_code: "270",
    phone_code: "220",
    capital: "Banjul",
    currency: "GMD",
    currency_name: "Gambian dalasi",
    currency_symbol: "D",
    tld: ".gm",
    native: "Gambia",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Banjul",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "감비아",
      "pt-BR": "Gâmbia",
      pt: "Gâmbia",
      nl: "Gambia",
      hr: "Gambija",
      fa: "گامبیا",
      de: "Gambia",
      es: "Gambia",
      fr: "Gambie",
      ja: "ガンビア",
      it: "Gambia",
      cn: "冈比亚",
      tr: "Gambiya",
    },
    latitude: "13.46666666",
    longitude: "-16.56666666",
    emoji: "🇬🇲",
    emojiU: "U+1F1EC U+1F1F2",
  },
  {
    id: 81,
    name: "Georgia",
    iso3: "GEO",
    iso2: "GE",
    numeric_code: "268",
    phone_code: "995",
    capital: "Tbilisi",
    currency: "GEL",
    currency_name: "Georgian lari",
    currency_symbol: "ლ",
    tld: ".ge",
    native: "საქართველო",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Tbilisi",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "GET",
        tzName: "Georgia Standard Time",
      },
    ],
    translations: {
      kr: "조지아",
      "pt-BR": "Geórgia",
      pt: "Geórgia",
      nl: "Georgië",
      hr: "Gruzija",
      fa: "گرجستان",
      de: "Georgien",
      es: "Georgia",
      fr: "Géorgie",
      ja: "グルジア",
      it: "Georgia",
      cn: "格鲁吉亚",
      tr: "Gürcistan",
    },
    latitude: "42.00000000",
    longitude: "43.50000000",
    emoji: "🇬🇪",
    emojiU: "U+1F1EC U+1F1EA",
  },
  {
    id: 82,
    name: "Germany",
    iso3: "DEU",
    iso2: "DE",
    numeric_code: "276",
    phone_code: "49",
    capital: "Berlin",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".de",
    native: "Deutschland",
    region: "Europe",
    subregion: "Western Europe",
    timezones: [
      {
        zoneName: "Europe/Berlin",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
      {
        zoneName: "Europe/Busingen",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "독일",
      "pt-BR": "Alemanha",
      pt: "Alemanha",
      nl: "Duitsland",
      hr: "Njemačka",
      fa: "آلمان",
      de: "Deutschland",
      es: "Alemania",
      fr: "Allemagne",
      ja: "ドイツ",
      it: "Germania",
      cn: "德国",
      tr: "Almanya",
    },
    latitude: "51.00000000",
    longitude: "9.00000000",
    emoji: "🇩🇪",
    emojiU: "U+1F1E9 U+1F1EA",
  },
  {
    id: 83,
    name: "Ghana",
    iso3: "GHA",
    iso2: "GH",
    numeric_code: "288",
    phone_code: "233",
    capital: "Accra",
    currency: "GHS",
    currency_name: "Ghanaian cedi",
    currency_symbol: "GH₵",
    tld: ".gh",
    native: "Ghana",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Accra",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "가나",
      "pt-BR": "Gana",
      pt: "Gana",
      nl: "Ghana",
      hr: "Gana",
      fa: "غنا",
      de: "Ghana",
      es: "Ghana",
      fr: "Ghana",
      ja: "ガーナ",
      it: "Ghana",
      cn: "加纳",
      tr: "Gana",
    },
    latitude: "8.00000000",
    longitude: "-2.00000000",
    emoji: "🇬🇭",
    emojiU: "U+1F1EC U+1F1ED",
  },
  {
    id: 84,
    name: "Gibraltar",
    iso3: "GIB",
    iso2: "GI",
    numeric_code: "292",
    phone_code: "350",
    capital: "Gibraltar",
    currency: "GIP",
    currency_name: "Gibraltar pound",
    currency_symbol: "£",
    tld: ".gi",
    native: "Gibraltar",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/Gibraltar",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "지브롤터",
      "pt-BR": "Gibraltar",
      pt: "Gibraltar",
      nl: "Gibraltar",
      hr: "Gibraltar",
      fa: "جبل‌طارق",
      de: "Gibraltar",
      es: "Gibraltar",
      fr: "Gibraltar",
      ja: "ジブラルタル",
      it: "Gibilterra",
      cn: "直布罗陀",
      tr: "Cebelitarik",
    },
    latitude: "36.13333333",
    longitude: "-5.35000000",
    emoji: "🇬🇮",
    emojiU: "U+1F1EC U+1F1EE",
  },
  {
    id: 85,
    name: "Greece",
    iso3: "GRC",
    iso2: "GR",
    numeric_code: "300",
    phone_code: "30",
    capital: "Athens",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".gr",
    native: "Ελλάδα",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/Athens",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "그리스",
      "pt-BR": "Grécia",
      pt: "Grécia",
      nl: "Griekenland",
      hr: "Grčka",
      fa: "یونان",
      de: "Griechenland",
      es: "Grecia",
      fr: "Grèce",
      ja: "ギリシャ",
      it: "Grecia",
      cn: "希腊",
      tr: "Yunanistan",
    },
    latitude: "39.00000000",
    longitude: "22.00000000",
    emoji: "🇬🇷",
    emojiU: "U+1F1EC U+1F1F7",
  },
  {
    id: 86,
    name: "Greenland",
    iso3: "GRL",
    iso2: "GL",
    numeric_code: "304",
    phone_code: "299",
    capital: "Nuuk",
    currency: "DKK",
    currency_name: "Danish krone",
    currency_symbol: "Kr.",
    tld: ".gl",
    native: "Kalaallit Nunaat",
    region: "Americas",
    subregion: "Northern America",
    timezones: [
      {
        zoneName: "America/Danmarkshavn",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
      {
        zoneName: "America/Nuuk",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "WGT",
        tzName: "West Greenland Time",
      },
      {
        zoneName: "America/Scoresbysund",
        gmtOffset: -3600,
        gmtOffsetName: "UTC-01:00",
        abbreviation: "EGT",
        tzName: "Eastern Greenland Time",
      },
      {
        zoneName: "America/Thule",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "그린란드",
      "pt-BR": "Groelândia",
      pt: "Gronelândia",
      nl: "Groenland",
      hr: "Grenland",
      fa: "گرینلند",
      de: "Grönland",
      es: "Groenlandia",
      fr: "Groenland",
      ja: "グリーンランド",
      it: "Groenlandia",
      cn: "格陵兰岛",
      tr: "Grönland",
    },
    latitude: "72.00000000",
    longitude: "-40.00000000",
    emoji: "🇬🇱",
    emojiU: "U+1F1EC U+1F1F1",
  },
  {
    id: 87,
    name: "Grenada",
    iso3: "GRD",
    iso2: "GD",
    numeric_code: "308",
    phone_code: "+1-473",
    capital: "St. George's",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".gd",
    native: "Grenada",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Grenada",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "그레나다",
      "pt-BR": "Granada",
      pt: "Granada",
      nl: "Grenada",
      hr: "Grenada",
      fa: "گرنادا",
      de: "Grenada",
      es: "Grenada",
      fr: "Grenade",
      ja: "グレナダ",
      it: "Grenada",
      cn: "格林纳达",
      tr: "Grenada",
    },
    latitude: "12.11666666",
    longitude: "-61.66666666",
    emoji: "🇬🇩",
    emojiU: "U+1F1EC U+1F1E9",
  },
  {
    id: 88,
    name: "Guadeloupe",
    iso3: "GLP",
    iso2: "GP",
    numeric_code: "312",
    phone_code: "590",
    capital: "Basse-Terre",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".gp",
    native: "Guadeloupe",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Guadeloupe",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "과들루프",
      "pt-BR": "Guadalupe",
      pt: "Guadalupe",
      nl: "Guadeloupe",
      hr: "Gvadalupa",
      fa: "جزیره گوادلوپ",
      de: "Guadeloupe",
      es: "Guadalupe",
      fr: "Guadeloupe",
      ja: "グアドループ",
      it: "Guadeloupa",
      cn: "瓜德罗普岛",
      tr: "Guadeloupe",
    },
    latitude: "16.25000000",
    longitude: "-61.58333300",
    emoji: "🇬🇵",
    emojiU: "U+1F1EC U+1F1F5",
  },
  {
    id: 89,
    name: "Guam",
    iso3: "GUM",
    iso2: "GU",
    numeric_code: "316",
    phone_code: "+1-671",
    capital: "Hagatna",
    currency: "USD",
    currency_name: "US Dollar",
    currency_symbol: "$",
    tld: ".gu",
    native: "Guam",
    region: "Oceania",
    subregion: "Micronesia",
    timezones: [
      {
        zoneName: "Pacific/Guam",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "CHST",
        tzName: "Chamorro Standard Time",
      },
    ],
    translations: {
      kr: "괌",
      "pt-BR": "Guam",
      pt: "Guame",
      nl: "Guam",
      hr: "Guam",
      fa: "گوام",
      de: "Guam",
      es: "Guam",
      fr: "Guam",
      ja: "グアム",
      it: "Guam",
      cn: "关岛",
      tr: "Guam",
    },
    latitude: "13.46666666",
    longitude: "144.78333333",
    emoji: "🇬🇺",
    emojiU: "U+1F1EC U+1F1FA",
  },
  {
    id: 90,
    name: "Guatemala",
    iso3: "GTM",
    iso2: "GT",
    numeric_code: "320",
    phone_code: "502",
    capital: "Guatemala City",
    currency: "GTQ",
    currency_name: "Guatemalan quetzal",
    currency_symbol: "Q",
    tld: ".gt",
    native: "Guatemala",
    region: "Americas",
    subregion: "Central America",
    timezones: [
      {
        zoneName: "America/Guatemala",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
    ],
    translations: {
      kr: "과테말라",
      "pt-BR": "Guatemala",
      pt: "Guatemala",
      nl: "Guatemala",
      hr: "Gvatemala",
      fa: "گواتمالا",
      de: "Guatemala",
      es: "Guatemala",
      fr: "Guatemala",
      ja: "グアテマラ",
      it: "Guatemala",
      cn: "危地马拉",
      tr: "Guatemala",
    },
    latitude: "15.50000000",
    longitude: "-90.25000000",
    emoji: "🇬🇹",
    emojiU: "U+1F1EC U+1F1F9",
  },
  {
    id: 91,
    name: "Guernsey and Alderney",
    iso3: "GGY",
    iso2: "GG",
    numeric_code: "831",
    phone_code: "+44-1481",
    capital: "St Peter Port",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "£",
    tld: ".gg",
    native: "Guernsey",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Europe/Guernsey",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "건지, 올더니",
      "pt-BR": "Guernsey",
      pt: "Guernsey",
      nl: "Guernsey",
      hr: "Guernsey",
      fa: "گرنزی",
      de: "Guernsey",
      es: "Guernsey",
      fr: "Guernesey",
      ja: "ガーンジー",
      it: "Guernsey",
      cn: "根西岛",
      tr: "Alderney",
    },
    latitude: "49.46666666",
    longitude: "-2.58333333",
    emoji: "🇬🇬",
    emojiU: "U+1F1EC U+1F1EC",
  },
  {
    id: 92,
    name: "Guinea",
    iso3: "GIN",
    iso2: "GN",
    numeric_code: "324",
    phone_code: "224",
    capital: "Conakry",
    currency: "GNF",
    currency_name: "Guinean franc",
    currency_symbol: "FG",
    tld: ".gn",
    native: "Guinée",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Conakry",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "기니",
      "pt-BR": "Guiné",
      pt: "Guiné",
      nl: "Guinee",
      hr: "Gvineja",
      fa: "گینه",
      de: "Guinea",
      es: "Guinea",
      fr: "Guinée",
      ja: "ギニア",
      it: "Guinea",
      cn: "几内亚",
      tr: "Gine",
    },
    latitude: "11.00000000",
    longitude: "-10.00000000",
    emoji: "🇬🇳",
    emojiU: "U+1F1EC U+1F1F3",
  },
  {
    id: 93,
    name: "Guinea-Bissau",
    iso3: "GNB",
    iso2: "GW",
    numeric_code: "624",
    phone_code: "245",
    capital: "Bissau",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".gw",
    native: "Guiné-Bissau",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Bissau",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "기니비사우",
      "pt-BR": "Guiné-Bissau",
      pt: "Guiné-Bissau",
      nl: "Guinee-Bissau",
      hr: "Gvineja Bisau",
      fa: "گینه بیسائو",
      de: "Guinea-Bissau",
      es: "Guinea-Bisáu",
      fr: "Guinée-Bissau",
      ja: "ギニアビサウ",
      it: "Guinea-Bissau",
      cn: "几内亚比绍",
      tr: "Gine-bissau",
    },
    latitude: "12.00000000",
    longitude: "-15.00000000",
    emoji: "🇬🇼",
    emojiU: "U+1F1EC U+1F1FC",
  },
  {
    id: 94,
    name: "Guyana",
    iso3: "GUY",
    iso2: "GY",
    numeric_code: "328",
    phone_code: "592",
    capital: "Georgetown",
    currency: "GYD",
    currency_name: "Guyanese dollar",
    currency_symbol: "$",
    tld: ".gy",
    native: "Guyana",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "America/Guyana",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "GYT",
        tzName: "Guyana Time",
      },
    ],
    translations: {
      kr: "가이아나",
      "pt-BR": "Guiana",
      pt: "Guiana",
      nl: "Guyana",
      hr: "Gvajana",
      fa: "گویان",
      de: "Guyana",
      es: "Guyana",
      fr: "Guyane",
      ja: "ガイアナ",
      it: "Guyana",
      cn: "圭亚那",
      tr: "Guyana",
    },
    latitude: "5.00000000",
    longitude: "-59.00000000",
    emoji: "🇬🇾",
    emojiU: "U+1F1EC U+1F1FE",
  },
  {
    id: 95,
    name: "Haiti",
    iso3: "HTI",
    iso2: "HT",
    numeric_code: "332",
    phone_code: "509",
    capital: "Port-au-Prince",
    currency: "HTG",
    currency_name: "Haitian gourde",
    currency_symbol: "G",
    tld: ".ht",
    native: "Haïti",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Port-au-Prince",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
    ],
    translations: {
      kr: "아이티",
      "pt-BR": "Haiti",
      pt: "Haiti",
      nl: "Haïti",
      hr: "Haiti",
      fa: "هائیتی",
      de: "Haiti",
      es: "Haiti",
      fr: "Haïti",
      ja: "ハイチ",
      it: "Haiti",
      cn: "海地",
      tr: "Haiti",
    },
    latitude: "19.00000000",
    longitude: "-72.41666666",
    emoji: "🇭🇹",
    emojiU: "U+1F1ED U+1F1F9",
  },
  {
    id: 96,
    name: "Heard Island and McDonald Islands",
    iso3: "HMD",
    iso2: "HM",
    numeric_code: "334",
    phone_code: "672",
    capital: "",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".hm",
    native: "Heard Island and McDonald Islands",
    region: "",
    subregion: "",
    timezones: [
      {
        zoneName: "Indian/Kerguelen",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "TFT",
        tzName: "French Southern and Antarctic Time",
      },
    ],
    translations: {
      kr: "허드 맥도날드 제도",
      "pt-BR": "Ilha Heard e Ilhas McDonald",
      pt: "Ilha Heard e Ilhas McDonald",
      nl: "Heard- en McDonaldeilanden",
      hr: "Otok Heard i otočje McDonald",
      fa: "جزیره هرد و جزایر مک‌دونالد",
      de: "Heard und die McDonaldinseln",
      es: "Islas Heard y McDonald",
      fr: "Îles Heard-et-MacDonald",
      ja: "ハード島とマクドナルド諸島",
      it: "Isole Heard e McDonald",
      cn: "赫德·唐纳岛及麦唐纳岛",
      tr: "Heard Adasi Ve Mcdonald Adalari",
    },
    latitude: "-53.10000000",
    longitude: "72.51666666",
    emoji: "🇭🇲",
    emojiU: "U+1F1ED U+1F1F2",
  },
  {
    id: 97,
    name: "Honduras",
    iso3: "HND",
    iso2: "HN",
    numeric_code: "340",
    phone_code: "504",
    capital: "Tegucigalpa",
    currency: "HNL",
    currency_name: "Honduran lempira",
    currency_symbol: "L",
    tld: ".hn",
    native: "Honduras",
    region: "Americas",
    subregion: "Central America",
    timezones: [
      {
        zoneName: "America/Tegucigalpa",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
    ],
    translations: {
      kr: "온두라스",
      "pt-BR": "Honduras",
      pt: "Honduras",
      nl: "Honduras",
      hr: "Honduras",
      fa: "هندوراس",
      de: "Honduras",
      es: "Honduras",
      fr: "Honduras",
      ja: "ホンジュラス",
      it: "Honduras",
      cn: "洪都拉斯",
      tr: "Honduras",
    },
    latitude: "15.00000000",
    longitude: "-86.50000000",
    emoji: "🇭🇳",
    emojiU: "U+1F1ED U+1F1F3",
  },
  {
    id: 98,
    name: "Hong Kong S.A.R.",
    iso3: "HKG",
    iso2: "HK",
    numeric_code: "344",
    phone_code: "852",
    capital: "Hong Kong",
    currency: "HKD",
    currency_name: "Hong Kong dollar",
    currency_symbol: "$",
    tld: ".hk",
    native: "香港",
    region: "Asia",
    subregion: "Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Hong_Kong",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "HKT",
        tzName: "Hong Kong Time",
      },
    ],
    translations: {
      kr: "홍콩",
      "pt-BR": "Hong Kong",
      pt: "Hong Kong",
      nl: "Hongkong",
      hr: "Hong Kong",
      fa: "هنگ‌کنگ",
      de: "Hong Kong",
      es: "Hong Kong",
      fr: "Hong Kong",
      ja: "香港",
      it: "Hong Kong",
      cn: "中国香港",
      tr: "Hong Kong",
    },
    latitude: "22.25000000",
    longitude: "114.16666666",
    emoji: "🇭🇰",
    emojiU: "U+1F1ED U+1F1F0",
  },
  {
    id: 99,
    name: "Hungary",
    iso3: "HUN",
    iso2: "HU",
    numeric_code: "348",
    phone_code: "36",
    capital: "Budapest",
    currency: "HUF",
    currency_name: "Hungarian forint",
    currency_symbol: "Ft",
    tld: ".hu",
    native: "Magyarország",
    region: "Europe",
    subregion: "Eastern Europe",
    timezones: [
      {
        zoneName: "Europe/Budapest",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "헝가리",
      "pt-BR": "Hungria",
      pt: "Hungria",
      nl: "Hongarije",
      hr: "Mađarska",
      fa: "مجارستان",
      de: "Ungarn",
      es: "Hungría",
      fr: "Hongrie",
      ja: "ハンガリー",
      it: "Ungheria",
      cn: "匈牙利",
      tr: "Macaristan",
    },
    latitude: "47.00000000",
    longitude: "20.00000000",
    emoji: "🇭🇺",
    emojiU: "U+1F1ED U+1F1FA",
  },
  {
    id: 100,
    name: "Iceland",
    iso3: "ISL",
    iso2: "IS",
    numeric_code: "352",
    phone_code: "354",
    capital: "Reykjavik",
    currency: "ISK",
    currency_name: "Icelandic króna",
    currency_symbol: "kr",
    tld: ".is",
    native: "Ísland",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Atlantic/Reykjavik",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "아이슬란드",
      "pt-BR": "Islândia",
      pt: "Islândia",
      nl: "IJsland",
      hr: "Island",
      fa: "ایسلند",
      de: "Island",
      es: "Islandia",
      fr: "Islande",
      ja: "アイスランド",
      it: "Islanda",
      cn: "冰岛",
      tr: "İzlanda",
    },
    latitude: "65.00000000",
    longitude: "-18.00000000",
    emoji: "🇮🇸",
    emojiU: "U+1F1EE U+1F1F8",
  },
  {
    id: 101,
    name: "India",
    iso3: "IND",
    iso2: "IN",
    numeric_code: "356",
    phone_code: "91",
    capital: "New Delhi",
    currency: "INR",
    currency_name: "Indian rupee",
    currency_symbol: "₹",
    tld: ".in",
    native: "भारत",
    region: "Asia",
    subregion: "Southern Asia",
    timezones: [
      {
        zoneName: "Asia/Kolkata",
        gmtOffset: 19800,
        gmtOffsetName: "UTC+05:30",
        abbreviation: "IST",
        tzName: "Indian Standard Time",
      },
    ],
    translations: {
      kr: "인도",
      "pt-BR": "Índia",
      pt: "Índia",
      nl: "India",
      hr: "Indija",
      fa: "هند",
      de: "Indien",
      es: "India",
      fr: "Inde",
      ja: "インド",
      it: "India",
      cn: "印度",
      tr: "Hindistan",
    },
    latitude: "20.00000000",
    longitude: "77.00000000",
    emoji: "🇮🇳",
    emojiU: "U+1F1EE U+1F1F3",
  },
  {
    id: 102,
    name: "Indonesia",
    iso3: "IDN",
    iso2: "ID",
    numeric_code: "360",
    phone_code: "62",
    capital: "Jakarta",
    currency: "IDR",
    currency_name: "Indonesian rupiah",
    currency_symbol: "Rp",
    tld: ".id",
    native: "Indonesia",
    region: "Asia",
    subregion: "South-Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Jakarta",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "WIB",
        tzName: "Western Indonesian Time",
      },
      {
        zoneName: "Asia/Jayapura",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "WIT",
        tzName: "Eastern Indonesian Time",
      },
      {
        zoneName: "Asia/Makassar",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "WITA",
        tzName: "Central Indonesia Time",
      },
      {
        zoneName: "Asia/Pontianak",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "WIB",
        tzName: "Western Indonesian Time",
      },
    ],
    translations: {
      kr: "인도네시아",
      "pt-BR": "Indonésia",
      pt: "Indonésia",
      nl: "Indonesië",
      hr: "Indonezija",
      fa: "اندونزی",
      de: "Indonesien",
      es: "Indonesia",
      fr: "Indonésie",
      ja: "インドネシア",
      it: "Indonesia",
      cn: "印度尼西亚",
      tr: "Endonezya",
    },
    latitude: "-5.00000000",
    longitude: "120.00000000",
    emoji: "🇮🇩",
    emojiU: "U+1F1EE U+1F1E9",
  },
  {
    id: 103,
    name: "Iran",
    iso3: "IRN",
    iso2: "IR",
    numeric_code: "364",
    phone_code: "98",
    capital: "Tehran",
    currency: "IRR",
    currency_name: "Iranian rial",
    currency_symbol: "﷼",
    tld: ".ir",
    native: "ایران",
    region: "Asia",
    subregion: "Southern Asia",
    timezones: [
      {
        zoneName: "Asia/Tehran",
        gmtOffset: 12600,
        gmtOffsetName: "UTC+03:30",
        abbreviation: "IRDT",
        tzName: "Iran Daylight Time",
      },
    ],
    translations: {
      kr: "이란",
      "pt-BR": "Irã",
      pt: "Irão",
      nl: "Iran",
      hr: "Iran",
      fa: "ایران",
      de: "Iran",
      es: "Iran",
      fr: "Iran",
      ja: "イラン・イスラム共和国",
      cn: "伊朗",
      tr: "İran",
    },
    latitude: "32.00000000",
    longitude: "53.00000000",
    emoji: "🇮🇷",
    emojiU: "U+1F1EE U+1F1F7",
  },
  {
    id: 104,
    name: "Iraq",
    iso3: "IRQ",
    iso2: "IQ",
    numeric_code: "368",
    phone_code: "964",
    capital: "Baghdad",
    currency: "IQD",
    currency_name: "Iraqi dinar",
    currency_symbol: "د.ع",
    tld: ".iq",
    native: "العراق",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Baghdad",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time",
      },
    ],
    translations: {
      kr: "이라크",
      "pt-BR": "Iraque",
      pt: "Iraque",
      nl: "Irak",
      hr: "Irak",
      fa: "عراق",
      de: "Irak",
      es: "Irak",
      fr: "Irak",
      ja: "イラク",
      it: "Iraq",
      cn: "伊拉克",
      tr: "Irak",
    },
    latitude: "33.00000000",
    longitude: "44.00000000",
    emoji: "🇮🇶",
    emojiU: "U+1F1EE U+1F1F6",
  },
  {
    id: 105,
    name: "Ireland",
    iso3: "IRL",
    iso2: "IE",
    numeric_code: "372",
    phone_code: "353",
    capital: "Dublin",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".ie",
    native: "Éire",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Europe/Dublin",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "아일랜드",
      "pt-BR": "Irlanda",
      pt: "Irlanda",
      nl: "Ierland",
      hr: "Irska",
      fa: "ایرلند",
      de: "Irland",
      es: "Irlanda",
      fr: "Irlande",
      ja: "アイルランド",
      it: "Irlanda",
      cn: "爱尔兰",
      tr: "İrlanda",
    },
    latitude: "53.00000000",
    longitude: "-8.00000000",
    emoji: "🇮🇪",
    emojiU: "U+1F1EE U+1F1EA",
  },
  {
    id: 106,
    name: "Israel",
    iso3: "ISR",
    iso2: "IL",
    numeric_code: "376",
    phone_code: "972",
    capital: "Jerusalem",
    currency: "ILS",
    currency_name: "Israeli new shekel",
    currency_symbol: "₪",
    tld: ".il",
    native: "יִשְׂרָאֵל",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Jerusalem",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "IST",
        tzName: "Israel Standard Time",
      },
    ],
    translations: {
      kr: "이스라엘",
      "pt-BR": "Israel",
      pt: "Israel",
      nl: "Israël",
      hr: "Izrael",
      fa: "اسرائیل",
      de: "Israel",
      es: "Israel",
      fr: "Israël",
      ja: "イスラエル",
      it: "Israele",
      cn: "以色列",
      tr: "İsrail",
    },
    latitude: "31.50000000",
    longitude: "34.75000000",
    emoji: "🇮🇱",
    emojiU: "U+1F1EE U+1F1F1",
  },
  {
    id: 107,
    name: "Italy",
    iso3: "ITA",
    iso2: "IT",
    numeric_code: "380",
    phone_code: "39",
    capital: "Rome",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".it",
    native: "Italia",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/Rome",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "이탈리아",
      "pt-BR": "Itália",
      pt: "Itália",
      nl: "Italië",
      hr: "Italija",
      fa: "ایتالیا",
      de: "Italien",
      es: "Italia",
      fr: "Italie",
      ja: "イタリア",
      it: "Italia",
      cn: "意大利",
      tr: "İtalya",
    },
    latitude: "42.83333333",
    longitude: "12.83333333",
    emoji: "🇮🇹",
    emojiU: "U+1F1EE U+1F1F9",
  },
  {
    id: 108,
    name: "Jamaica",
    iso3: "JAM",
    iso2: "JM",
    numeric_code: "388",
    phone_code: "+1-876",
    capital: "Kingston",
    currency: "JMD",
    currency_name: "Jamaican dollar",
    currency_symbol: "J$",
    tld: ".jm",
    native: "Jamaica",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Jamaica",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
    ],
    translations: {
      kr: "자메이카",
      "pt-BR": "Jamaica",
      pt: "Jamaica",
      nl: "Jamaica",
      hr: "Jamajka",
      fa: "جامائیکا",
      de: "Jamaika",
      es: "Jamaica",
      fr: "Jamaïque",
      ja: "ジャマイカ",
      it: "Giamaica",
      cn: "牙买加",
      tr: "Jamaika",
    },
    latitude: "18.25000000",
    longitude: "-77.50000000",
    emoji: "🇯🇲",
    emojiU: "U+1F1EF U+1F1F2",
  },
  {
    id: 109,
    name: "Japan",
    iso3: "JPN",
    iso2: "JP",
    numeric_code: "392",
    phone_code: "81",
    capital: "Tokyo",
    currency: "JPY",
    currency_name: "Japanese yen",
    currency_symbol: "¥",
    tld: ".jp",
    native: "日本",
    region: "Asia",
    subregion: "Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Tokyo",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "JST",
        tzName: "Japan Standard Time",
      },
    ],
    translations: {
      kr: "일본",
      "pt-BR": "Japão",
      pt: "Japão",
      nl: "Japan",
      hr: "Japan",
      fa: "ژاپن",
      de: "Japan",
      es: "Japón",
      fr: "Japon",
      ja: "日本",
      it: "Giappone",
      cn: "日本",
      tr: "Japonya",
    },
    latitude: "36.00000000",
    longitude: "138.00000000",
    emoji: "🇯🇵",
    emojiU: "U+1F1EF U+1F1F5",
  },
  {
    id: 110,
    name: "Jersey",
    iso3: "JEY",
    iso2: "JE",
    numeric_code: "832",
    phone_code: "+44-1534",
    capital: "Saint Helier",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "£",
    tld: ".je",
    native: "Jersey",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Europe/Jersey",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "저지 섬",
      "pt-BR": "Jersey",
      pt: "Jersey",
      nl: "Jersey",
      hr: "Jersey",
      fa: "جرزی",
      de: "Jersey",
      es: "Jersey",
      fr: "Jersey",
      ja: "ジャージー",
      it: "Isola di Jersey",
      cn: "泽西岛",
      tr: "Jersey",
    },
    latitude: "49.25000000",
    longitude: "-2.16666666",
    emoji: "🇯🇪",
    emojiU: "U+1F1EF U+1F1EA",
  },
  {
    id: 111,
    name: "Jordan",
    iso3: "JOR",
    iso2: "JO",
    numeric_code: "400",
    phone_code: "962",
    capital: "Amman",
    currency: "JOD",
    currency_name: "Jordanian dinar",
    currency_symbol: "ا.د",
    tld: ".jo",
    native: "الأردن",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Amman",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "요르단",
      "pt-BR": "Jordânia",
      pt: "Jordânia",
      nl: "Jordanië",
      hr: "Jordan",
      fa: "اردن",
      de: "Jordanien",
      es: "Jordania",
      fr: "Jordanie",
      ja: "ヨルダン",
      it: "Giordania",
      cn: "约旦",
      tr: "Ürdün",
    },
    latitude: "31.00000000",
    longitude: "36.00000000",
    emoji: "🇯🇴",
    emojiU: "U+1F1EF U+1F1F4",
  },
  {
    id: 112,
    name: "Kazakhstan",
    iso3: "KAZ",
    iso2: "KZ",
    numeric_code: "398",
    phone_code: "7",
    capital: "Astana",
    currency: "KZT",
    currency_name: "Kazakhstani tenge",
    currency_symbol: "лв",
    tld: ".kz",
    native: "Қазақстан",
    region: "Asia",
    subregion: "Central Asia",
    timezones: [
      {
        zoneName: "Asia/Almaty",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "ALMT",
        tzName: "Alma-Ata Time[1",
      },
      {
        zoneName: "Asia/Aqtau",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "AQTT",
        tzName: "Aqtobe Time",
      },
      {
        zoneName: "Asia/Aqtobe",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "AQTT",
        tzName: "Aqtobe Time",
      },
      {
        zoneName: "Asia/Atyrau",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "MSD+1",
        tzName: "Moscow Daylight Time+1",
      },
      {
        zoneName: "Asia/Oral",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "ORAT",
        tzName: "Oral Time",
      },
      {
        zoneName: "Asia/Qostanay",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "QYZST",
        tzName: "Qyzylorda Summer Time",
      },
      {
        zoneName: "Asia/Qyzylorda",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "QYZT",
        tzName: "Qyzylorda Summer Time",
      },
    ],
    translations: {
      kr: "카자흐스탄",
      "pt-BR": "Cazaquistão",
      pt: "Cazaquistão",
      nl: "Kazachstan",
      hr: "Kazahstan",
      fa: "قزاقستان",
      de: "Kasachstan",
      es: "Kazajistán",
      fr: "Kazakhstan",
      ja: "カザフスタン",
      it: "Kazakistan",
      cn: "哈萨克斯坦",
      tr: "Kazakistan",
    },
    latitude: "48.00000000",
    longitude: "68.00000000",
    emoji: "🇰🇿",
    emojiU: "U+1F1F0 U+1F1FF",
  },
  {
    id: 113,
    name: "Kenya",
    iso3: "KEN",
    iso2: "KE",
    numeric_code: "404",
    phone_code: "254",
    capital: "Nairobi",
    currency: "KES",
    currency_name: "Kenyan shilling",
    currency_symbol: "KSh",
    tld: ".ke",
    native: "Kenya",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Africa/Nairobi",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "케냐",
      "pt-BR": "Quênia",
      pt: "Quénia",
      nl: "Kenia",
      hr: "Kenija",
      fa: "کنیا",
      de: "Kenia",
      es: "Kenia",
      fr: "Kenya",
      ja: "ケニア",
      it: "Kenya",
      cn: "肯尼亚",
      tr: "Kenya",
    },
    latitude: "1.00000000",
    longitude: "38.00000000",
    emoji: "🇰🇪",
    emojiU: "U+1F1F0 U+1F1EA",
  },
  {
    id: 114,
    name: "Kiribati",
    iso3: "KIR",
    iso2: "KI",
    numeric_code: "296",
    phone_code: "686",
    capital: "Tarawa",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".ki",
    native: "Kiribati",
    region: "Oceania",
    subregion: "Micronesia",
    timezones: [
      {
        zoneName: "Pacific/Enderbury",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "PHOT",
        tzName: "Phoenix Island Time",
      },
      {
        zoneName: "Pacific/Kiritimati",
        gmtOffset: 50400,
        gmtOffsetName: "UTC+14:00",
        abbreviation: "LINT",
        tzName: "Line Islands Time",
      },
      {
        zoneName: "Pacific/Tarawa",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "GILT",
        tzName: "Gilbert Island Time",
      },
    ],
    translations: {
      kr: "키리바시",
      "pt-BR": "Kiribati",
      pt: "Quiribáti",
      nl: "Kiribati",
      hr: "Kiribati",
      fa: "کیریباتی",
      de: "Kiribati",
      es: "Kiribati",
      fr: "Kiribati",
      ja: "キリバス",
      it: "Kiribati",
      cn: "基里巴斯",
      tr: "Kiribati",
    },
    latitude: "1.41666666",
    longitude: "173.00000000",
    emoji: "🇰🇮",
    emojiU: "U+1F1F0 U+1F1EE",
  },
  {
    id: 248,
    name: "Kosovo",
    iso3: "XKX",
    iso2: "XK",
    numeric_code: "926",
    phone_code: "383",
    capital: "Pristina",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".xk",
    native: "Republika e Kosovës",
    region: "Europe",
    subregion: "Eastern Europe",
    timezones: [
      {
        zoneName: "Europe/Belgrade",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "코소보",
      cn: "科索沃",
      tr: "Kosova",
    },
    latitude: "42.56129090",
    longitude: "20.34030350",
    emoji: "🇽🇰",
    emojiU: "U+1F1FD U+1F1F0",
  },
  {
    id: 117,
    name: "Kuwait",
    iso3: "KWT",
    iso2: "KW",
    numeric_code: "414",
    phone_code: "965",
    capital: "Kuwait City",
    currency: "KWD",
    currency_name: "Kuwaiti dinar",
    currency_symbol: "ك.د",
    tld: ".kw",
    native: "الكويت",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Kuwait",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time",
      },
    ],
    translations: {
      kr: "쿠웨이트",
      "pt-BR": "Kuwait",
      pt: "Kuwait",
      nl: "Koeweit",
      hr: "Kuvajt",
      fa: "کویت",
      de: "Kuwait",
      es: "Kuwait",
      fr: "Koweït",
      ja: "クウェート",
      it: "Kuwait",
      cn: "科威特",
      tr: "Kuveyt",
    },
    latitude: "29.50000000",
    longitude: "45.75000000",
    emoji: "🇰🇼",
    emojiU: "U+1F1F0 U+1F1FC",
  },
  {
    id: 118,
    name: "Kyrgyzstan",
    iso3: "KGZ",
    iso2: "KG",
    numeric_code: "417",
    phone_code: "996",
    capital: "Bishkek",
    currency: "KGS",
    currency_name: "Kyrgyzstani som",
    currency_symbol: "лв",
    tld: ".kg",
    native: "Кыргызстан",
    region: "Asia",
    subregion: "Central Asia",
    timezones: [
      {
        zoneName: "Asia/Bishkek",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "KGT",
        tzName: "Kyrgyzstan Time",
      },
    ],
    translations: {
      kr: "키르기스스탄",
      "pt-BR": "Quirguistão",
      pt: "Quirguizistão",
      nl: "Kirgizië",
      hr: "Kirgistan",
      fa: "قرقیزستان",
      de: "Kirgisistan",
      es: "Kirguizistán",
      fr: "Kirghizistan",
      ja: "キルギス",
      it: "Kirghizistan",
      cn: "吉尔吉斯斯坦",
      tr: "Kirgizistan",
    },
    latitude: "41.00000000",
    longitude: "75.00000000",
    emoji: "🇰🇬",
    emojiU: "U+1F1F0 U+1F1EC",
  },
  {
    id: 119,
    name: "Laos",
    iso3: "LAO",
    iso2: "LA",
    numeric_code: "418",
    phone_code: "856",
    capital: "Vientiane",
    currency: "LAK",
    currency_name: "Lao kip",
    currency_symbol: "₭",
    tld: ".la",
    native: "ສປປລາວ",
    region: "Asia",
    subregion: "South-Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Vientiane",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "ICT",
        tzName: "Indochina Time",
      },
    ],
    translations: {
      kr: "라오스",
      "pt-BR": "Laos",
      pt: "Laos",
      nl: "Laos",
      hr: "Laos",
      fa: "لائوس",
      de: "Laos",
      es: "Laos",
      fr: "Laos",
      ja: "ラオス人民民主共和国",
      it: "Laos",
      cn: "寮人民民主共和国",
      tr: "Laos",
    },
    latitude: "18.00000000",
    longitude: "105.00000000",
    emoji: "🇱🇦",
    emojiU: "U+1F1F1 U+1F1E6",
  },
  {
    id: 120,
    name: "Latvia",
    iso3: "LVA",
    iso2: "LV",
    numeric_code: "428",
    phone_code: "371",
    capital: "Riga",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".lv",
    native: "Latvija",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Europe/Riga",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "라트비아",
      "pt-BR": "Letônia",
      pt: "Letónia",
      nl: "Letland",
      hr: "Latvija",
      fa: "لتونی",
      de: "Lettland",
      es: "Letonia",
      fr: "Lettonie",
      ja: "ラトビア",
      it: "Lettonia",
      cn: "拉脱维亚",
      tr: "Letonya",
    },
    latitude: "57.00000000",
    longitude: "25.00000000",
    emoji: "🇱🇻",
    emojiU: "U+1F1F1 U+1F1FB",
  },
  {
    id: 121,
    name: "Lebanon",
    iso3: "LBN",
    iso2: "LB",
    numeric_code: "422",
    phone_code: "961",
    capital: "Beirut",
    currency: "LBP",
    currency_name: "Lebanese pound",
    currency_symbol: "£",
    tld: ".lb",
    native: "لبنان",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Beirut",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "레바논",
      "pt-BR": "Líbano",
      pt: "Líbano",
      nl: "Libanon",
      hr: "Libanon",
      fa: "لبنان",
      de: "Libanon",
      es: "Líbano",
      fr: "Liban",
      ja: "レバノン",
      it: "Libano",
      cn: "黎巴嫩",
      tr: "Lübnan",
    },
    latitude: "33.83333333",
    longitude: "35.83333333",
    emoji: "🇱🇧",
    emojiU: "U+1F1F1 U+1F1E7",
  },
  {
    id: 122,
    name: "Lesotho",
    iso3: "LSO",
    iso2: "LS",
    numeric_code: "426",
    phone_code: "266",
    capital: "Maseru",
    currency: "LSL",
    currency_name: "Lesotho loti",
    currency_symbol: "L",
    tld: ".ls",
    native: "Lesotho",
    region: "Africa",
    subregion: "Southern Africa",
    timezones: [
      {
        zoneName: "Africa/Maseru",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "SAST",
        tzName: "South African Standard Time",
      },
    ],
    translations: {
      kr: "레소토",
      "pt-BR": "Lesoto",
      pt: "Lesoto",
      nl: "Lesotho",
      hr: "Lesoto",
      fa: "لسوتو",
      de: "Lesotho",
      es: "Lesotho",
      fr: "Lesotho",
      ja: "レソト",
      it: "Lesotho",
      cn: "莱索托",
      tr: "Lesotho",
    },
    latitude: "-29.50000000",
    longitude: "28.50000000",
    emoji: "🇱🇸",
    emojiU: "U+1F1F1 U+1F1F8",
  },
  {
    id: 123,
    name: "Liberia",
    iso3: "LBR",
    iso2: "LR",
    numeric_code: "430",
    phone_code: "231",
    capital: "Monrovia",
    currency: "LRD",
    currency_name: "Liberian dollar",
    currency_symbol: "$",
    tld: ".lr",
    native: "Liberia",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Monrovia",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "라이베리아",
      "pt-BR": "Libéria",
      pt: "Libéria",
      nl: "Liberia",
      hr: "Liberija",
      fa: "لیبریا",
      de: "Liberia",
      es: "Liberia",
      fr: "Liberia",
      ja: "リベリア",
      it: "Liberia",
      cn: "利比里亚",
      tr: "Liberya",
    },
    latitude: "6.50000000",
    longitude: "-9.50000000",
    emoji: "🇱🇷",
    emojiU: "U+1F1F1 U+1F1F7",
  },
  {
    id: 124,
    name: "Libya",
    iso3: "LBY",
    iso2: "LY",
    numeric_code: "434",
    phone_code: "218",
    capital: "Tripolis",
    currency: "LYD",
    currency_name: "Libyan dinar",
    currency_symbol: "د.ل",
    tld: ".ly",
    native: "‏ليبيا",
    region: "Africa",
    subregion: "Northern Africa",
    timezones: [
      {
        zoneName: "Africa/Tripoli",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "리비아",
      "pt-BR": "Líbia",
      pt: "Líbia",
      nl: "Libië",
      hr: "Libija",
      fa: "لیبی",
      de: "Libyen",
      es: "Libia",
      fr: "Libye",
      ja: "リビア",
      it: "Libia",
      cn: "利比亚",
      tr: "Libya",
    },
    latitude: "25.00000000",
    longitude: "17.00000000",
    emoji: "🇱🇾",
    emojiU: "U+1F1F1 U+1F1FE",
  },
  {
    id: 125,
    name: "Liechtenstein",
    iso3: "LIE",
    iso2: "LI",
    numeric_code: "438",
    phone_code: "423",
    capital: "Vaduz",
    currency: "CHF",
    currency_name: "Swiss franc",
    currency_symbol: "CHf",
    tld: ".li",
    native: "Liechtenstein",
    region: "Europe",
    subregion: "Western Europe",
    timezones: [
      {
        zoneName: "Europe/Vaduz",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "리히텐슈타인",
      "pt-BR": "Liechtenstein",
      pt: "Listenstaine",
      nl: "Liechtenstein",
      hr: "Lihtenštajn",
      fa: "لیختن‌اشتاین",
      de: "Liechtenstein",
      es: "Liechtenstein",
      fr: "Liechtenstein",
      ja: "リヒテンシュタイン",
      it: "Liechtenstein",
      cn: "列支敦士登",
      tr: "Lihtenştayn",
    },
    latitude: "47.26666666",
    longitude: "9.53333333",
    emoji: "🇱🇮",
    emojiU: "U+1F1F1 U+1F1EE",
  },
  {
    id: 126,
    name: "Lithuania",
    iso3: "LTU",
    iso2: "LT",
    numeric_code: "440",
    phone_code: "370",
    capital: "Vilnius",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".lt",
    native: "Lietuva",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Europe/Vilnius",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "리투아니아",
      "pt-BR": "Lituânia",
      pt: "Lituânia",
      nl: "Litouwen",
      hr: "Litva",
      fa: "لیتوانی",
      de: "Litauen",
      es: "Lituania",
      fr: "Lituanie",
      ja: "リトアニア",
      it: "Lituania",
      cn: "立陶宛",
      tr: "Litvanya",
    },
    latitude: "56.00000000",
    longitude: "24.00000000",
    emoji: "🇱🇹",
    emojiU: "U+1F1F1 U+1F1F9",
  },
  {
    id: 127,
    name: "Luxembourg",
    iso3: "LUX",
    iso2: "LU",
    numeric_code: "442",
    phone_code: "352",
    capital: "Luxembourg",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".lu",
    native: "Luxembourg",
    region: "Europe",
    subregion: "Western Europe",
    timezones: [
      {
        zoneName: "Europe/Luxembourg",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "룩셈부르크",
      "pt-BR": "Luxemburgo",
      pt: "Luxemburgo",
      nl: "Luxemburg",
      hr: "Luksemburg",
      fa: "لوکزامبورگ",
      de: "Luxemburg",
      es: "Luxemburgo",
      fr: "Luxembourg",
      ja: "ルクセンブルク",
      it: "Lussemburgo",
      cn: "卢森堡",
      tr: "Lüksemburg",
    },
    latitude: "49.75000000",
    longitude: "6.16666666",
    emoji: "🇱🇺",
    emojiU: "U+1F1F1 U+1F1FA",
  },
  {
    id: 128,
    name: "Macau S.A.R.",
    iso3: "MAC",
    iso2: "MO",
    numeric_code: "446",
    phone_code: "853",
    capital: "Macao",
    currency: "MOP",
    currency_name: "Macanese pataca",
    currency_symbol: "$",
    tld: ".mo",
    native: "澳門",
    region: "Asia",
    subregion: "Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Macau",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "CST",
        tzName: "China Standard Time",
      },
    ],
    translations: {
      kr: "마카오",
      "pt-BR": "Macau",
      pt: "Macau",
      nl: "Macao",
      hr: "Makao",
      fa: "مکائو",
      de: "Macao",
      es: "Macao",
      fr: "Macao",
      ja: "マカオ",
      it: "Macao",
      cn: "中国澳门",
      tr: "Makao",
    },
    latitude: "22.16666666",
    longitude: "113.55000000",
    emoji: "🇲🇴",
    emojiU: "U+1F1F2 U+1F1F4",
  },
  {
    id: 130,
    name: "Madagascar",
    iso3: "MDG",
    iso2: "MG",
    numeric_code: "450",
    phone_code: "261",
    capital: "Antananarivo",
    currency: "MGA",
    currency_name: "Malagasy ariary",
    currency_symbol: "Ar",
    tld: ".mg",
    native: "Madagasikara",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Indian/Antananarivo",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "마다가스카르",
      "pt-BR": "Madagascar",
      pt: "Madagáscar",
      nl: "Madagaskar",
      hr: "Madagaskar",
      fa: "ماداگاسکار",
      de: "Madagaskar",
      es: "Madagascar",
      fr: "Madagascar",
      ja: "マダガスカル",
      it: "Madagascar",
      cn: "马达加斯加",
      tr: "Madagaskar",
    },
    latitude: "-20.00000000",
    longitude: "47.00000000",
    emoji: "🇲🇬",
    emojiU: "U+1F1F2 U+1F1EC",
  },
  {
    id: 131,
    name: "Malawi",
    iso3: "MWI",
    iso2: "MW",
    numeric_code: "454",
    phone_code: "265",
    capital: "Lilongwe",
    currency: "MWK",
    currency_name: "Malawian kwacha",
    currency_symbol: "MK",
    tld: ".mw",
    native: "Malawi",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Africa/Blantyre",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "말라위",
      "pt-BR": "Malawi",
      pt: "Malávi",
      nl: "Malawi",
      hr: "Malavi",
      fa: "مالاوی",
      de: "Malawi",
      es: "Malawi",
      fr: "Malawi",
      ja: "マラウイ",
      it: "Malawi",
      cn: "马拉维",
      tr: "Malavi",
    },
    latitude: "-13.50000000",
    longitude: "34.00000000",
    emoji: "🇲🇼",
    emojiU: "U+1F1F2 U+1F1FC",
  },
  {
    id: 132,
    name: "Malaysia",
    iso3: "MYS",
    iso2: "MY",
    numeric_code: "458",
    phone_code: "60",
    capital: "Kuala Lumpur",
    currency: "MYR",
    currency_name: "Malaysian ringgit",
    currency_symbol: "RM",
    tld: ".my",
    native: "Malaysia",
    region: "Asia",
    subregion: "South-Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Kuala_Lumpur",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "MYT",
        tzName: "Malaysia Time",
      },
      {
        zoneName: "Asia/Kuching",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "MYT",
        tzName: "Malaysia Time",
      },
    ],
    translations: {
      kr: "말레이시아",
      "pt-BR": "Malásia",
      pt: "Malásia",
      nl: "Maleisië",
      hr: "Malezija",
      fa: "مالزی",
      de: "Malaysia",
      es: "Malasia",
      fr: "Malaisie",
      ja: "マレーシア",
      it: "Malesia",
      cn: "马来西亚",
      tr: "Malezya",
    },
    latitude: "2.50000000",
    longitude: "112.50000000",
    emoji: "🇲🇾",
    emojiU: "U+1F1F2 U+1F1FE",
  },
  {
    id: 133,
    name: "Maldives",
    iso3: "MDV",
    iso2: "MV",
    numeric_code: "462",
    phone_code: "960",
    capital: "Male",
    currency: "MVR",
    currency_name: "Maldivian rufiyaa",
    currency_symbol: "Rf",
    tld: ".mv",
    native: "Maldives",
    region: "Asia",
    subregion: "Southern Asia",
    timezones: [
      {
        zoneName: "Indian/Maldives",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "MVT",
        tzName: "Maldives Time",
      },
    ],
    translations: {
      kr: "몰디브",
      "pt-BR": "Maldivas",
      pt: "Maldivas",
      nl: "Maldiven",
      hr: "Maldivi",
      fa: "مالدیو",
      de: "Malediven",
      es: "Maldivas",
      fr: "Maldives",
      ja: "モルディブ",
      it: "Maldive",
      cn: "马尔代夫",
      tr: "Maldivler",
    },
    latitude: "3.25000000",
    longitude: "73.00000000",
    emoji: "🇲🇻",
    emojiU: "U+1F1F2 U+1F1FB",
  },
  {
    id: 134,
    name: "Mali",
    iso3: "MLI",
    iso2: "ML",
    numeric_code: "466",
    phone_code: "223",
    capital: "Bamako",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".ml",
    native: "Mali",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Bamako",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "말리",
      "pt-BR": "Mali",
      pt: "Mali",
      nl: "Mali",
      hr: "Mali",
      fa: "مالی",
      de: "Mali",
      es: "Mali",
      fr: "Mali",
      ja: "マリ",
      it: "Mali",
      cn: "马里",
      tr: "Mali",
    },
    latitude: "17.00000000",
    longitude: "-4.00000000",
    emoji: "🇲🇱",
    emojiU: "U+1F1F2 U+1F1F1",
  },
  {
    id: 135,
    name: "Malta",
    iso3: "MLT",
    iso2: "MT",
    numeric_code: "470",
    phone_code: "356",
    capital: "Valletta",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".mt",
    native: "Malta",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/Malta",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "몰타",
      "pt-BR": "Malta",
      pt: "Malta",
      nl: "Malta",
      hr: "Malta",
      fa: "مالت",
      de: "Malta",
      es: "Malta",
      fr: "Malte",
      ja: "マルタ",
      it: "Malta",
      cn: "马耳他",
      tr: "Malta",
    },
    latitude: "35.83333333",
    longitude: "14.58333333",
    emoji: "🇲🇹",
    emojiU: "U+1F1F2 U+1F1F9",
  },
  {
    id: 136,
    name: "Man (Isle of)",
    iso3: "IMN",
    iso2: "IM",
    numeric_code: "833",
    phone_code: "+44-1624",
    capital: "Douglas, Isle of Man",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "£",
    tld: ".im",
    native: "Isle of Man",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Europe/Isle_of_Man",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "맨 섬",
      "pt-BR": "Ilha de Man",
      pt: "Ilha de Man",
      nl: "Isle of Man",
      hr: "Otok Man",
      fa: "جزیره من",
      de: "Insel Man",
      es: "Isla de Man",
      fr: "Île de Man",
      ja: "マン島",
      it: "Isola di Man",
      cn: "马恩岛",
      tr: "Man Adasi",
    },
    latitude: "54.25000000",
    longitude: "-4.50000000",
    emoji: "🇮🇲",
    emojiU: "U+1F1EE U+1F1F2",
  },
  {
    id: 137,
    name: "Marshall Islands",
    iso3: "MHL",
    iso2: "MH",
    numeric_code: "584",
    phone_code: "692",
    capital: "Majuro",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".mh",
    native: "M̧ajeļ",
    region: "Oceania",
    subregion: "Micronesia",
    timezones: [
      {
        zoneName: "Pacific/Kwajalein",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "MHT",
        tzName: "Marshall Islands Time",
      },
      {
        zoneName: "Pacific/Majuro",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "MHT",
        tzName: "Marshall Islands Time",
      },
    ],
    translations: {
      kr: "마셜 제도",
      "pt-BR": "Ilhas Marshall",
      pt: "Ilhas Marshall",
      nl: "Marshalleilanden",
      hr: "Maršalovi Otoci",
      fa: "جزایر مارشال",
      de: "Marshallinseln",
      es: "Islas Marshall",
      fr: "Îles Marshall",
      ja: "マーシャル諸島",
      it: "Isole Marshall",
      cn: "马绍尔群岛",
      tr: "Marşal Adalari",
    },
    latitude: "9.00000000",
    longitude: "168.00000000",
    emoji: "🇲🇭",
    emojiU: "U+1F1F2 U+1F1ED",
  },
  {
    id: 138,
    name: "Martinique",
    iso3: "MTQ",
    iso2: "MQ",
    numeric_code: "474",
    phone_code: "596",
    capital: "Fort-de-France",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".mq",
    native: "Martinique",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Martinique",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "마르티니크",
      "pt-BR": "Martinica",
      pt: "Martinica",
      nl: "Martinique",
      hr: "Martinique",
      fa: "مونتسرات",
      de: "Martinique",
      es: "Martinica",
      fr: "Martinique",
      ja: "マルティニーク",
      it: "Martinica",
      cn: "马提尼克岛",
      tr: "Martinik",
    },
    latitude: "14.66666700",
    longitude: "-61.00000000",
    emoji: "🇲🇶",
    emojiU: "U+1F1F2 U+1F1F6",
  },
  {
    id: 139,
    name: "Mauritania",
    iso3: "MRT",
    iso2: "MR",
    numeric_code: "478",
    phone_code: "222",
    capital: "Nouakchott",
    currency: "MRO",
    currency_name: "Mauritanian ouguiya",
    currency_symbol: "MRU",
    tld: ".mr",
    native: "موريتانيا",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Nouakchott",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "모리타니",
      "pt-BR": "Mauritânia",
      pt: "Mauritânia",
      nl: "Mauritanië",
      hr: "Mauritanija",
      fa: "موریتانی",
      de: "Mauretanien",
      es: "Mauritania",
      fr: "Mauritanie",
      ja: "モーリタニア",
      it: "Mauritania",
      cn: "毛里塔尼亚",
      tr: "Moritanya",
    },
    latitude: "20.00000000",
    longitude: "-12.00000000",
    emoji: "🇲🇷",
    emojiU: "U+1F1F2 U+1F1F7",
  },
  {
    id: 140,
    name: "Mauritius",
    iso3: "MUS",
    iso2: "MU",
    numeric_code: "480",
    phone_code: "230",
    capital: "Port Louis",
    currency: "MUR",
    currency_name: "Mauritian rupee",
    currency_symbol: "₨",
    tld: ".mu",
    native: "Maurice",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Indian/Mauritius",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "MUT",
        tzName: "Mauritius Time",
      },
    ],
    translations: {
      kr: "모리셔스",
      "pt-BR": "Maurício",
      pt: "Maurícia",
      nl: "Mauritius",
      hr: "Mauricijus",
      fa: "موریس",
      de: "Mauritius",
      es: "Mauricio",
      fr: "Île Maurice",
      ja: "モーリシャス",
      it: "Mauritius",
      cn: "毛里求斯",
      tr: "Morityus",
    },
    latitude: "-20.28333333",
    longitude: "57.55000000",
    emoji: "🇲🇺",
    emojiU: "U+1F1F2 U+1F1FA",
  },
  {
    id: 141,
    name: "Mayotte",
    iso3: "MYT",
    iso2: "YT",
    numeric_code: "175",
    phone_code: "262",
    capital: "Mamoudzou",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".yt",
    native: "Mayotte",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Indian/Mayotte",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "마요트",
      "pt-BR": "Mayotte",
      pt: "Mayotte",
      nl: "Mayotte",
      hr: "Mayotte",
      fa: "مایوت",
      de: "Mayotte",
      es: "Mayotte",
      fr: "Mayotte",
      ja: "マヨット",
      it: "Mayotte",
      cn: "马约特",
      tr: "Mayotte",
    },
    latitude: "-12.83333333",
    longitude: "45.16666666",
    emoji: "🇾🇹",
    emojiU: "U+1F1FE U+1F1F9",
  },
  {
    id: 142,
    name: "Mexico",
    iso3: "MEX",
    iso2: "MX",
    numeric_code: "484",
    phone_code: "52",
    capital: "Ciudad de México",
    currency: "MXN",
    currency_name: "Mexican peso",
    currency_symbol: "$",
    tld: ".mx",
    native: "México",
    region: "Americas",
    subregion: "Central America",
    timezones: [
      {
        zoneName: "America/Bahia_Banderas",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Cancun",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Chihuahua",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Hermosillo",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Matamoros",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Mazatlan",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Merida",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Mexico_City",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Monterrey",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Ojinaga",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Tijuana",
        gmtOffset: -28800,
        gmtOffsetName: "UTC-08:00",
        abbreviation: "PST",
        tzName: "Pacific Standard Time (North America",
      },
    ],
    translations: {
      kr: "멕시코",
      "pt-BR": "México",
      pt: "México",
      nl: "Mexico",
      hr: "Meksiko",
      fa: "مکزیک",
      de: "Mexiko",
      es: "México",
      fr: "Mexique",
      ja: "メキシコ",
      it: "Messico",
      cn: "墨西哥",
      tr: "Meksika",
    },
    latitude: "23.00000000",
    longitude: "-102.00000000",
    emoji: "🇲🇽",
    emojiU: "U+1F1F2 U+1F1FD",
  },
  {
    id: 143,
    name: "Micronesia",
    iso3: "FSM",
    iso2: "FM",
    numeric_code: "583",
    phone_code: "691",
    capital: "Palikir",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".fm",
    native: "Micronesia",
    region: "Oceania",
    subregion: "Micronesia",
    timezones: [
      {
        zoneName: "Pacific/Chuuk",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "CHUT",
        tzName: "Chuuk Time",
      },
      {
        zoneName: "Pacific/Kosrae",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "KOST",
        tzName: "Kosrae Time",
      },
      {
        zoneName: "Pacific/Pohnpei",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "PONT",
        tzName: "Pohnpei Standard Time",
      },
    ],
    translations: {
      kr: "미크로네시아 연방",
      "pt-BR": "Micronésia",
      pt: "Micronésia",
      nl: "Micronesië",
      hr: "Mikronezija",
      fa: "ایالات فدرال میکرونزی",
      de: "Mikronesien",
      es: "Micronesia",
      fr: "Micronésie",
      ja: "ミクロネシア連邦",
      it: "Micronesia",
      cn: "密克罗尼西亚",
      tr: "Mikronezya",
    },
    latitude: "6.91666666",
    longitude: "158.25000000",
    emoji: "🇫🇲",
    emojiU: "U+1F1EB U+1F1F2",
  },
  {
    id: 144,
    name: "Moldova",
    iso3: "MDA",
    iso2: "MD",
    numeric_code: "498",
    phone_code: "373",
    capital: "Chisinau",
    currency: "MDL",
    currency_name: "Moldovan leu",
    currency_symbol: "L",
    tld: ".md",
    native: "Moldova",
    region: "Europe",
    subregion: "Eastern Europe",
    timezones: [
      {
        zoneName: "Europe/Chisinau",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "몰도바",
      "pt-BR": "Moldávia",
      pt: "Moldávia",
      nl: "Moldavië",
      hr: "Moldova",
      fa: "مولداوی",
      de: "Moldawie",
      es: "Moldavia",
      fr: "Moldavie",
      ja: "モルドバ共和国",
      it: "Moldavia",
      cn: "摩尔多瓦",
      tr: "Moldova",
    },
    latitude: "47.00000000",
    longitude: "29.00000000",
    emoji: "🇲🇩",
    emojiU: "U+1F1F2 U+1F1E9",
  },
  {
    id: 145,
    name: "Monaco",
    iso3: "MCO",
    iso2: "MC",
    numeric_code: "492",
    phone_code: "377",
    capital: "Monaco",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".mc",
    native: "Monaco",
    region: "Europe",
    subregion: "Western Europe",
    timezones: [
      {
        zoneName: "Europe/Monaco",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "모나코",
      "pt-BR": "Mônaco",
      pt: "Mónaco",
      nl: "Monaco",
      hr: "Monako",
      fa: "موناکو",
      de: "Monaco",
      es: "Mónaco",
      fr: "Monaco",
      ja: "モナコ",
      it: "Principato di Monaco",
      cn: "摩纳哥",
      tr: "Monako",
    },
    latitude: "43.73333333",
    longitude: "7.40000000",
    emoji: "🇲🇨",
    emojiU: "U+1F1F2 U+1F1E8",
  },
  {
    id: 146,
    name: "Mongolia",
    iso3: "MNG",
    iso2: "MN",
    numeric_code: "496",
    phone_code: "976",
    capital: "Ulan Bator",
    currency: "MNT",
    currency_name: "Mongolian tögrög",
    currency_symbol: "₮",
    tld: ".mn",
    native: "Монгол улс",
    region: "Asia",
    subregion: "Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Choibalsan",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "CHOT",
        tzName: "Choibalsan Standard Time",
      },
      {
        zoneName: "Asia/Hovd",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "HOVT",
        tzName: "Hovd Time",
      },
      {
        zoneName: "Asia/Ulaanbaatar",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "ULAT",
        tzName: "Ulaanbaatar Standard Time",
      },
    ],
    translations: {
      kr: "몽골",
      "pt-BR": "Mongólia",
      pt: "Mongólia",
      nl: "Mongolië",
      hr: "Mongolija",
      fa: "مغولستان",
      de: "Mongolei",
      es: "Mongolia",
      fr: "Mongolie",
      ja: "モンゴル",
      it: "Mongolia",
      cn: "蒙古",
      tr: "Moğolistan",
    },
    latitude: "46.00000000",
    longitude: "105.00000000",
    emoji: "🇲🇳",
    emojiU: "U+1F1F2 U+1F1F3",
  },
  {
    id: 147,
    name: "Montenegro",
    iso3: "MNE",
    iso2: "ME",
    numeric_code: "499",
    phone_code: "382",
    capital: "Podgorica",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".me",
    native: "Црна Гора",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/Podgorica",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "몬테네그로",
      "pt-BR": "Montenegro",
      pt: "Montenegro",
      nl: "Montenegro",
      hr: "Crna Gora",
      fa: "مونته‌نگرو",
      de: "Montenegro",
      es: "Montenegro",
      fr: "Monténégro",
      ja: "モンテネグロ",
      it: "Montenegro",
      cn: "黑山",
      tr: "Karadağ",
    },
    latitude: "42.50000000",
    longitude: "19.30000000",
    emoji: "🇲🇪",
    emojiU: "U+1F1F2 U+1F1EA",
  },
  {
    id: 148,
    name: "Montserrat",
    iso3: "MSR",
    iso2: "MS",
    numeric_code: "500",
    phone_code: "+1-664",
    capital: "Plymouth",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".ms",
    native: "Montserrat",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Montserrat",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "몬트세랫",
      "pt-BR": "Montserrat",
      pt: "Monserrate",
      nl: "Montserrat",
      hr: "Montserrat",
      fa: "مایوت",
      de: "Montserrat",
      es: "Montserrat",
      fr: "Montserrat",
      ja: "モントセラト",
      it: "Montserrat",
      cn: "蒙特塞拉特",
      tr: "Montserrat",
    },
    latitude: "16.75000000",
    longitude: "-62.20000000",
    emoji: "🇲🇸",
    emojiU: "U+1F1F2 U+1F1F8",
  },
  {
    id: 149,
    name: "Morocco",
    iso3: "MAR",
    iso2: "MA",
    numeric_code: "504",
    phone_code: "212",
    capital: "Rabat",
    currency: "MAD",
    currency_name: "Moroccan dirham",
    currency_symbol: "DH",
    tld: ".ma",
    native: "المغرب",
    region: "Africa",
    subregion: "Northern Africa",
    timezones: [
      {
        zoneName: "Africa/Casablanca",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WEST",
        tzName: "Western European Summer Time",
      },
    ],
    translations: {
      kr: "모로코",
      "pt-BR": "Marrocos",
      pt: "Marrocos",
      nl: "Marokko",
      hr: "Maroko",
      fa: "مراکش",
      de: "Marokko",
      es: "Marruecos",
      fr: "Maroc",
      ja: "モロッコ",
      it: "Marocco",
      cn: "摩洛哥",
      tr: "Fas",
    },
    latitude: "32.00000000",
    longitude: "-5.00000000",
    emoji: "🇲🇦",
    emojiU: "U+1F1F2 U+1F1E6",
  },
  {
    id: 150,
    name: "Mozambique",
    iso3: "MOZ",
    iso2: "MZ",
    numeric_code: "508",
    phone_code: "258",
    capital: "Maputo",
    currency: "MZN",
    currency_name: "Mozambican metical",
    currency_symbol: "MT",
    tld: ".mz",
    native: "Moçambique",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Africa/Maputo",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "모잠비크",
      "pt-BR": "Moçambique",
      pt: "Moçambique",
      nl: "Mozambique",
      hr: "Mozambik",
      fa: "موزامبیک",
      de: "Mosambik",
      es: "Mozambique",
      fr: "Mozambique",
      ja: "モザンビーク",
      it: "Mozambico",
      cn: "莫桑比克",
      tr: "Mozambik",
    },
    latitude: "-18.25000000",
    longitude: "35.00000000",
    emoji: "🇲🇿",
    emojiU: "U+1F1F2 U+1F1FF",
  },
  {
    id: 151,
    name: "Myanmar",
    iso3: "MMR",
    iso2: "MM",
    numeric_code: "104",
    phone_code: "95",
    capital: "Nay Pyi Taw",
    currency: "MMK",
    currency_name: "Burmese kyat",
    currency_symbol: "K",
    tld: ".mm",
    native: "မြန်မာ",
    region: "Asia",
    subregion: "South-Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Yangon",
        gmtOffset: 23400,
        gmtOffsetName: "UTC+06:30",
        abbreviation: "MMT",
        tzName: "Myanmar Standard Time",
      },
    ],
    translations: {
      kr: "미얀마",
      "pt-BR": "Myanmar",
      pt: "Myanmar",
      nl: "Myanmar",
      hr: "Mijanmar",
      fa: "میانمار",
      de: "Myanmar",
      es: "Myanmar",
      fr: "Myanmar",
      ja: "ミャンマー",
      it: "Birmania",
      cn: "缅甸",
      tr: "Myanmar",
    },
    latitude: "22.00000000",
    longitude: "98.00000000",
    emoji: "🇲🇲",
    emojiU: "U+1F1F2 U+1F1F2",
  },
  {
    id: 152,
    name: "Namibia",
    iso3: "NAM",
    iso2: "NA",
    numeric_code: "516",
    phone_code: "264",
    capital: "Windhoek",
    currency: "NAD",
    currency_name: "Namibian dollar",
    currency_symbol: "$",
    tld: ".na",
    native: "Namibia",
    region: "Africa",
    subregion: "Southern Africa",
    timezones: [
      {
        zoneName: "Africa/Windhoek",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "WAST",
        tzName: "West Africa Summer Time",
      },
    ],
    translations: {
      kr: "나미비아",
      "pt-BR": "Namíbia",
      pt: "Namíbia",
      nl: "Namibië",
      hr: "Namibija",
      fa: "نامیبیا",
      de: "Namibia",
      es: "Namibia",
      fr: "Namibie",
      ja: "ナミビア",
      it: "Namibia",
      cn: "纳米比亚",
      tr: "Namibya",
    },
    latitude: "-22.00000000",
    longitude: "17.00000000",
    emoji: "🇳🇦",
    emojiU: "U+1F1F3 U+1F1E6",
  },
  {
    id: 153,
    name: "Nauru",
    iso3: "NRU",
    iso2: "NR",
    numeric_code: "520",
    phone_code: "674",
    capital: "Yaren",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".nr",
    native: "Nauru",
    region: "Oceania",
    subregion: "Micronesia",
    timezones: [
      {
        zoneName: "Pacific/Nauru",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "NRT",
        tzName: "Nauru Time",
      },
    ],
    translations: {
      kr: "나우루",
      "pt-BR": "Nauru",
      pt: "Nauru",
      nl: "Nauru",
      hr: "Nauru",
      fa: "نائورو",
      de: "Nauru",
      es: "Nauru",
      fr: "Nauru",
      ja: "ナウル",
      it: "Nauru",
      cn: "瑙鲁",
      tr: "Nauru",
    },
    latitude: "-0.53333333",
    longitude: "166.91666666",
    emoji: "🇳🇷",
    emojiU: "U+1F1F3 U+1F1F7",
  },
  {
    id: 154,
    name: "Nepal",
    iso3: "NPL",
    iso2: "NP",
    numeric_code: "524",
    phone_code: "977",
    capital: "Kathmandu",
    currency: "NPR",
    currency_name: "Nepalese rupee",
    currency_symbol: "₨",
    tld: ".np",
    native: "नपल",
    region: "Asia",
    subregion: "Southern Asia",
    timezones: [
      {
        zoneName: "Asia/Kathmandu",
        gmtOffset: 20700,
        gmtOffsetName: "UTC+05:45",
        abbreviation: "NPT",
        tzName: "Nepal Time",
      },
    ],
    translations: {
      kr: "네팔",
      "pt-BR": "Nepal",
      pt: "Nepal",
      nl: "Nepal",
      hr: "Nepal",
      fa: "نپال",
      de: "Népal",
      es: "Nepal",
      fr: "Népal",
      ja: "ネパール",
      it: "Nepal",
      cn: "尼泊尔",
      tr: "Nepal",
    },
    latitude: "28.00000000",
    longitude: "84.00000000",
    emoji: "🇳🇵",
    emojiU: "U+1F1F3 U+1F1F5",
  },
  {
    id: 156,
    name: "Netherlands",
    iso3: "NLD",
    iso2: "NL",
    numeric_code: "528",
    phone_code: "31",
    capital: "Amsterdam",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".nl",
    native: "Nederland",
    region: "Europe",
    subregion: "Western Europe",
    timezones: [
      {
        zoneName: "Europe/Amsterdam",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "네덜란드 ",
      "pt-BR": "Holanda",
      pt: "Países Baixos",
      nl: "Nederland",
      hr: "Nizozemska",
      fa: "پادشاهی هلند",
      de: "Niederlande",
      es: "Países Bajos",
      fr: "Pays-Bas",
      ja: "オランダ",
      it: "Paesi Bassi",
      cn: "荷兰",
      tr: "Hollanda",
    },
    latitude: "52.50000000",
    longitude: "5.75000000",
    emoji: "🇳🇱",
    emojiU: "U+1F1F3 U+1F1F1",
  },
  {
    id: 157,
    name: "New Caledonia",
    iso3: "NCL",
    iso2: "NC",
    numeric_code: "540",
    phone_code: "687",
    capital: "Noumea",
    currency: "XPF",
    currency_name: "CFP franc",
    currency_symbol: "₣",
    tld: ".nc",
    native: "Nouvelle-Calédonie",
    region: "Oceania",
    subregion: "Melanesia",
    timezones: [
      {
        zoneName: "Pacific/Noumea",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "NCT",
        tzName: "New Caledonia Time",
      },
    ],
    translations: {
      kr: "누벨칼레도니",
      "pt-BR": "Nova Caledônia",
      pt: "Nova Caledónia",
      nl: "Nieuw-Caledonië",
      hr: "Nova Kaledonija",
      fa: "کالدونیای جدید",
      de: "Neukaledonien",
      es: "Nueva Caledonia",
      fr: "Nouvelle-Calédonie",
      ja: "ニューカレドニア",
      it: "Nuova Caledonia",
      cn: "新喀里多尼亚",
      tr: "Yeni Kaledonya",
    },
    latitude: "-21.50000000",
    longitude: "165.50000000",
    emoji: "🇳🇨",
    emojiU: "U+1F1F3 U+1F1E8",
  },
  {
    id: 158,
    name: "New Zealand",
    iso3: "NZL",
    iso2: "NZ",
    numeric_code: "554",
    phone_code: "64",
    capital: "Wellington",
    currency: "NZD",
    currency_name: "New Zealand dollar",
    currency_symbol: "$",
    tld: ".nz",
    native: "New Zealand",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    timezones: [
      {
        zoneName: "Pacific/Auckland",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "NZDT",
        tzName: "New Zealand Daylight Time",
      },
      {
        zoneName: "Pacific/Chatham",
        gmtOffset: 49500,
        gmtOffsetName: "UTC+13:45",
        abbreviation: "CHAST",
        tzName: "Chatham Standard Time",
      },
    ],
    translations: {
      kr: "뉴질랜드",
      "pt-BR": "Nova Zelândia",
      pt: "Nova Zelândia",
      nl: "Nieuw-Zeeland",
      hr: "Novi Zeland",
      fa: "نیوزیلند",
      de: "Neuseeland",
      es: "Nueva Zelanda",
      fr: "Nouvelle-Zélande",
      ja: "ニュージーランド",
      it: "Nuova Zelanda",
      cn: "新西兰",
      tr: "Yeni Zelanda",
    },
    latitude: "-41.00000000",
    longitude: "174.00000000",
    emoji: "🇳🇿",
    emojiU: "U+1F1F3 U+1F1FF",
  },
  {
    id: 159,
    name: "Nicaragua",
    iso3: "NIC",
    iso2: "NI",
    numeric_code: "558",
    phone_code: "505",
    capital: "Managua",
    currency: "NIO",
    currency_name: "Nicaraguan córdoba",
    currency_symbol: "C$",
    tld: ".ni",
    native: "Nicaragua",
    region: "Americas",
    subregion: "Central America",
    timezones: [
      {
        zoneName: "America/Managua",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
    ],
    translations: {
      kr: "니카라과",
      "pt-BR": "Nicarágua",
      pt: "Nicarágua",
      nl: "Nicaragua",
      hr: "Nikaragva",
      fa: "نیکاراگوئه",
      de: "Nicaragua",
      es: "Nicaragua",
      fr: "Nicaragua",
      ja: "ニカラグア",
      it: "Nicaragua",
      cn: "尼加拉瓜",
      tr: "Nikaragua",
    },
    latitude: "13.00000000",
    longitude: "-85.00000000",
    emoji: "🇳🇮",
    emojiU: "U+1F1F3 U+1F1EE",
  },
  {
    id: 160,
    name: "Niger",
    iso3: "NER",
    iso2: "NE",
    numeric_code: "562",
    phone_code: "227",
    capital: "Niamey",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".ne",
    native: "Niger",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Niamey",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "니제르",
      "pt-BR": "Níger",
      pt: "Níger",
      nl: "Niger",
      hr: "Niger",
      fa: "نیجر",
      de: "Niger",
      es: "Níger",
      fr: "Niger",
      ja: "ニジェール",
      it: "Niger",
      cn: "尼日尔",
      tr: "Nijer",
    },
    latitude: "16.00000000",
    longitude: "8.00000000",
    emoji: "🇳🇪",
    emojiU: "U+1F1F3 U+1F1EA",
  },
  {
    id: 161,
    name: "Nigeria",
    iso3: "NGA",
    iso2: "NG",
    numeric_code: "566",
    phone_code: "234",
    capital: "Abuja",
    currency: "NGN",
    currency_name: "Nigerian naira",
    currency_symbol: "₦",
    tld: ".ng",
    native: "Nigeria",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Lagos",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "나이지리아",
      "pt-BR": "Nigéria",
      pt: "Nigéria",
      nl: "Nigeria",
      hr: "Nigerija",
      fa: "نیجریه",
      de: "Nigeria",
      es: "Nigeria",
      fr: "Nigéria",
      ja: "ナイジェリア",
      it: "Nigeria",
      cn: "尼日利亚",
      tr: "Nijerya",
    },
    latitude: "10.00000000",
    longitude: "8.00000000",
    emoji: "🇳🇬",
    emojiU: "U+1F1F3 U+1F1EC",
  },
  {
    id: 162,
    name: "Niue",
    iso3: "NIU",
    iso2: "NU",
    numeric_code: "570",
    phone_code: "683",
    capital: "Alofi",
    currency: "NZD",
    currency_name: "New Zealand dollar",
    currency_symbol: "$",
    tld: ".nu",
    native: "Niuē",
    region: "Oceania",
    subregion: "Polynesia",
    timezones: [
      {
        zoneName: "Pacific/Niue",
        gmtOffset: -39600,
        gmtOffsetName: "UTC-11:00",
        abbreviation: "NUT",
        tzName: "Niue Time",
      },
    ],
    translations: {
      kr: "니우에",
      "pt-BR": "Niue",
      pt: "Niue",
      nl: "Niue",
      hr: "Niue",
      fa: "نیووی",
      de: "Niue",
      es: "Niue",
      fr: "Niue",
      ja: "ニウエ",
      it: "Niue",
      cn: "纽埃",
      tr: "Niue",
    },
    latitude: "-19.03333333",
    longitude: "-169.86666666",
    emoji: "🇳🇺",
    emojiU: "U+1F1F3 U+1F1FA",
  },
  {
    id: 163,
    name: "Norfolk Island",
    iso3: "NFK",
    iso2: "NF",
    numeric_code: "574",
    phone_code: "672",
    capital: "Kingston",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".nf",
    native: "Norfolk Island",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    timezones: [
      {
        zoneName: "Pacific/Norfolk",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "NFT",
        tzName: "Norfolk Time",
      },
    ],
    translations: {
      kr: "노퍽 섬",
      "pt-BR": "Ilha Norfolk",
      pt: "Ilha Norfolk",
      nl: "Norfolkeiland",
      hr: "Otok Norfolk",
      fa: "جزیره نورفک",
      de: "Norfolkinsel",
      es: "Isla de Norfolk",
      fr: "Île de Norfolk",
      ja: "ノーフォーク島",
      it: "Isola Norfolk",
      cn: "诺福克岛",
      tr: "Norfolk Adasi",
    },
    latitude: "-29.03333333",
    longitude: "167.95000000",
    emoji: "🇳🇫",
    emojiU: "U+1F1F3 U+1F1EB",
  },
  {
    id: 115,
    name: "North Korea",
    iso3: "PRK",
    iso2: "KP",
    numeric_code: "408",
    phone_code: "850",
    capital: "Pyongyang",
    currency: "KPW",
    currency_name: "North Korean Won",
    currency_symbol: "₩",
    tld: ".kp",
    native: "북한",
    region: "Asia",
    subregion: "Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Pyongyang",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "KST",
        tzName: "Korea Standard Time",
      },
    ],
    translations: {
      kr: "조선민주주의인민공화국",
      "pt-BR": "Coreia do Norte",
      pt: "Coreia do Norte",
      nl: "Noord-Korea",
      hr: "Sjeverna Koreja",
      fa: "کره جنوبی",
      de: "Nordkorea",
      es: "Corea del Norte",
      fr: "Corée du Nord",
      ja: "朝鮮民主主義人民共和国",
      it: "Corea del Nord",
      cn: "朝鲜",
      tr: "Kuzey Kore",
    },
    latitude: "40.00000000",
    longitude: "127.00000000",
    emoji: "🇰🇵",
    emojiU: "U+1F1F0 U+1F1F5",
  },
  {
    id: 129,
    name: "North Macedonia",
    iso3: "MKD",
    iso2: "MK",
    numeric_code: "807",
    phone_code: "389",
    capital: "Skopje",
    currency: "MKD",
    currency_name: "Denar",
    currency_symbol: "ден",
    tld: ".mk",
    native: "Северна Македонија",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/Skopje",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "북마케도니아",
      "pt-BR": "Macedônia do Norte",
      pt: "Macedónia do Norte",
      nl: "Noord-Macedonië",
      hr: "Sjeverna Makedonija",
      fa: "ﻢﻗﺩﻮﻨﯿﻫ ﺶﻣﺎﻠﯾ",
      de: "Nordmazedonien",
      es: "Macedonia del Norte",
      fr: "Macédoine du Nord",
      ja: "北マケドニア",
      it: "Macedonia del Nord",
      cn: "北馬其頓",
      tr: "Kuzey Makedonya",
    },
    latitude: "41.83333333",
    longitude: "22.00000000",
    emoji: "🇲🇰",
    emojiU: "U+1F1F2 U+1F1F0",
  },
  {
    id: 164,
    name: "Northern Mariana Islands",
    iso3: "MNP",
    iso2: "MP",
    numeric_code: "580",
    phone_code: "+1-670",
    capital: "Saipan",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".mp",
    native: "Northern Mariana Islands",
    region: "Oceania",
    subregion: "Micronesia",
    timezones: [
      {
        zoneName: "Pacific/Saipan",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "ChST",
        tzName: "Chamorro Standard Time",
      },
    ],
    translations: {
      kr: "북마리아나 제도",
      "pt-BR": "Ilhas Marianas",
      pt: "Ilhas Marianas",
      nl: "Noordelijke Marianeneilanden",
      hr: "Sjevernomarijanski otoci",
      fa: "جزایر ماریانای شمالی",
      de: "Nördliche Marianen",
      es: "Islas Marianas del Norte",
      fr: "Îles Mariannes du Nord",
      ja: "北マリアナ諸島",
      it: "Isole Marianne Settentrionali",
      cn: "北马里亚纳群岛",
      tr: "Kuzey Mariana Adalari",
    },
    latitude: "15.20000000",
    longitude: "145.75000000",
    emoji: "🇲🇵",
    emojiU: "U+1F1F2 U+1F1F5",
  },
  {
    id: 165,
    name: "Norway",
    iso3: "NOR",
    iso2: "NO",
    numeric_code: "578",
    phone_code: "47",
    capital: "Oslo",
    currency: "NOK",
    currency_name: "Norwegian krone",
    currency_symbol: "kr",
    tld: ".no",
    native: "Norge",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Europe/Oslo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "노르웨이",
      "pt-BR": "Noruega",
      pt: "Noruega",
      nl: "Noorwegen",
      hr: "Norveška",
      fa: "نروژ",
      de: "Norwegen",
      es: "Noruega",
      fr: "Norvège",
      ja: "ノルウェー",
      it: "Norvegia",
      cn: "挪威",
      tr: "Norveç",
    },
    latitude: "62.00000000",
    longitude: "10.00000000",
    emoji: "🇳🇴",
    emojiU: "U+1F1F3 U+1F1F4",
  },
  {
    id: 166,
    name: "Oman",
    iso3: "OMN",
    iso2: "OM",
    numeric_code: "512",
    phone_code: "968",
    capital: "Muscat",
    currency: "OMR",
    currency_name: "Omani rial",
    currency_symbol: ".ع.ر",
    tld: ".om",
    native: "عمان",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Muscat",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "GST",
        tzName: "Gulf Standard Time",
      },
    ],
    translations: {
      kr: "오만",
      "pt-BR": "Omã",
      pt: "Omã",
      nl: "Oman",
      hr: "Oman",
      fa: "عمان",
      de: "Oman",
      es: "Omán",
      fr: "Oman",
      ja: "オマーン",
      it: "oman",
      cn: "阿曼",
      tr: "Umman",
    },
    latitude: "21.00000000",
    longitude: "57.00000000",
    emoji: "🇴🇲",
    emojiU: "U+1F1F4 U+1F1F2",
  },
  {
    id: 167,
    name: "Pakistan",
    iso3: "PAK",
    iso2: "PK",
    numeric_code: "586",
    phone_code: "92",
    capital: "Islamabad",
    currency: "PKR",
    currency_name: "Pakistani rupee",
    currency_symbol: "₨",
    tld: ".pk",
    native: "Pakistan",
    region: "Asia",
    subregion: "Southern Asia",
    timezones: [
      {
        zoneName: "Asia/Karachi",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "PKT",
        tzName: "Pakistan Standard Time",
      },
    ],
    translations: {
      kr: "파키스탄",
      "pt-BR": "Paquistão",
      pt: "Paquistão",
      nl: "Pakistan",
      hr: "Pakistan",
      fa: "پاکستان",
      de: "Pakistan",
      es: "Pakistán",
      fr: "Pakistan",
      ja: "パキスタン",
      it: "Pakistan",
      cn: "巴基斯坦",
      tr: "Pakistan",
    },
    latitude: "30.00000000",
    longitude: "70.00000000",
    emoji: "🇵🇰",
    emojiU: "U+1F1F5 U+1F1F0",
  },
  {
    id: 168,
    name: "Palau",
    iso3: "PLW",
    iso2: "PW",
    numeric_code: "585",
    phone_code: "680",
    capital: "Melekeok",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".pw",
    native: "Palau",
    region: "Oceania",
    subregion: "Micronesia",
    timezones: [
      {
        zoneName: "Pacific/Palau",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "PWT",
        tzName: "Palau Time",
      },
    ],
    translations: {
      kr: "팔라우",
      "pt-BR": "Palau",
      pt: "Palau",
      nl: "Palau",
      hr: "Palau",
      fa: "پالائو",
      de: "Palau",
      es: "Palau",
      fr: "Palaos",
      ja: "パラオ",
      it: "Palau",
      cn: "帕劳",
      tr: "Palau",
    },
    latitude: "7.50000000",
    longitude: "134.50000000",
    emoji: "🇵🇼",
    emojiU: "U+1F1F5 U+1F1FC",
  },
  {
    id: 169,
    name: "Palestinian Territory Occupied",
    iso3: "PSE",
    iso2: "PS",
    numeric_code: "275",
    phone_code: "970",
    capital: "East Jerusalem",
    currency: "ILS",
    currency_name: "Israeli new shekel",
    currency_symbol: "₪",
    tld: ".ps",
    native: "فلسطين",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Gaza",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
      {
        zoneName: "Asia/Hebron",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "팔레스타인 영토",
      "pt-BR": "Palestina",
      pt: "Palestina",
      nl: "Palestijnse gebieden",
      hr: "Palestina",
      fa: "فلسطین",
      de: "Palästina",
      es: "Palestina",
      fr: "Palestine",
      ja: "パレスチナ",
      it: "Palestina",
      cn: "巴勒斯坦",
      tr: "Filistin",
    },
    latitude: "31.90000000",
    longitude: "35.20000000",
    emoji: "🇵🇸",
    emojiU: "U+1F1F5 U+1F1F8",
  },
  {
    id: 170,
    name: "Panama",
    iso3: "PAN",
    iso2: "PA",
    numeric_code: "591",
    phone_code: "507",
    capital: "Panama City",
    currency: "PAB",
    currency_name: "Panamanian balboa",
    currency_symbol: "B/.",
    tld: ".pa",
    native: "Panamá",
    region: "Americas",
    subregion: "Central America",
    timezones: [
      {
        zoneName: "America/Panama",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
    ],
    translations: {
      kr: "파나마",
      "pt-BR": "Panamá",
      pt: "Panamá",
      nl: "Panama",
      hr: "Panama",
      fa: "پاناما",
      de: "Panama",
      es: "Panamá",
      fr: "Panama",
      ja: "パナマ",
      it: "Panama",
      cn: "巴拿马",
      tr: "Panama",
    },
    latitude: "9.00000000",
    longitude: "-80.00000000",
    emoji: "🇵🇦",
    emojiU: "U+1F1F5 U+1F1E6",
  },
  {
    id: 171,
    name: "Papua new Guinea",
    iso3: "PNG",
    iso2: "PG",
    numeric_code: "598",
    phone_code: "675",
    capital: "Port Moresby",
    currency: "PGK",
    currency_name: "Papua New Guinean kina",
    currency_symbol: "K",
    tld: ".pg",
    native: "Papua Niugini",
    region: "Oceania",
    subregion: "Melanesia",
    timezones: [
      {
        zoneName: "Pacific/Bougainville",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "BST",
        tzName: "Bougainville Standard Time[6",
      },
      {
        zoneName: "Pacific/Port_Moresby",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "PGT",
        tzName: "Papua New Guinea Time",
      },
    ],
    translations: {
      kr: "파푸아뉴기니",
      "pt-BR": "Papua Nova Guiné",
      pt: "Papua Nova Guiné",
      nl: "Papoea-Nieuw-Guinea",
      hr: "Papua Nova Gvineja",
      fa: "پاپوآ گینه نو",
      de: "Papua-Neuguinea",
      es: "Papúa Nueva Guinea",
      fr: "Papouasie-Nouvelle-Guinée",
      ja: "パプアニューギニア",
      it: "Papua Nuova Guinea",
      cn: "巴布亚新几内亚",
      tr: "Papua Yeni Gine",
    },
    latitude: "-6.00000000",
    longitude: "147.00000000",
    emoji: "🇵🇬",
    emojiU: "U+1F1F5 U+1F1EC",
  },
  {
    id: 172,
    name: "Paraguay",
    iso3: "PRY",
    iso2: "PY",
    numeric_code: "600",
    phone_code: "595",
    capital: "Asuncion",
    currency: "PYG",
    currency_name: "Paraguayan guarani",
    currency_symbol: "₲",
    tld: ".py",
    native: "Paraguay",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "America/Asuncion",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "PYST",
        tzName: "Paraguay Summer Time",
      },
    ],
    translations: {
      kr: "파라과이",
      "pt-BR": "Paraguai",
      pt: "Paraguai",
      nl: "Paraguay",
      hr: "Paragvaj",
      fa: "پاراگوئه",
      de: "Paraguay",
      es: "Paraguay",
      fr: "Paraguay",
      ja: "パラグアイ",
      it: "Paraguay",
      cn: "巴拉圭",
      tr: "Paraguay",
    },
    latitude: "-23.00000000",
    longitude: "-58.00000000",
    emoji: "🇵🇾",
    emojiU: "U+1F1F5 U+1F1FE",
  },
  {
    id: 173,
    name: "Peru",
    iso3: "PER",
    iso2: "PE",
    numeric_code: "604",
    phone_code: "51",
    capital: "Lima",
    currency: "PEN",
    currency_name: "Peruvian sol",
    currency_symbol: "S/.",
    tld: ".pe",
    native: "Perú",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "America/Lima",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "PET",
        tzName: "Peru Time",
      },
    ],
    translations: {
      kr: "페루",
      "pt-BR": "Peru",
      pt: "Peru",
      nl: "Peru",
      hr: "Peru",
      fa: "پرو",
      de: "Peru",
      es: "Perú",
      fr: "Pérou",
      ja: "ペルー",
      it: "Perù",
      cn: "秘鲁",
      tr: "Peru",
    },
    latitude: "-10.00000000",
    longitude: "-76.00000000",
    emoji: "🇵🇪",
    emojiU: "U+1F1F5 U+1F1EA",
  },
  {
    id: 174,
    name: "Philippines",
    iso3: "PHL",
    iso2: "PH",
    numeric_code: "608",
    phone_code: "63",
    capital: "Manila",
    currency: "PHP",
    currency_name: "Philippine peso",
    currency_symbol: "₱",
    tld: ".ph",
    native: "Pilipinas",
    region: "Asia",
    subregion: "South-Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Manila",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "PHT",
        tzName: "Philippine Time",
      },
    ],
    translations: {
      kr: "필리핀",
      "pt-BR": "Filipinas",
      pt: "Filipinas",
      nl: "Filipijnen",
      hr: "Filipini",
      fa: "جزایر الندفیلیپین",
      de: "Philippinen",
      es: "Filipinas",
      fr: "Philippines",
      ja: "フィリピン",
      it: "Filippine",
      cn: "菲律宾",
      tr: "Filipinler",
    },
    latitude: "13.00000000",
    longitude: "122.00000000",
    emoji: "🇵🇭",
    emojiU: "U+1F1F5 U+1F1ED",
  },
  {
    id: 175,
    name: "Pitcairn Island",
    iso3: "PCN",
    iso2: "PN",
    numeric_code: "612",
    phone_code: "870",
    capital: "Adamstown",
    currency: "NZD",
    currency_name: "New Zealand dollar",
    currency_symbol: "$",
    tld: ".pn",
    native: "Pitcairn Islands",
    region: "Oceania",
    subregion: "Polynesia",
    timezones: [
      {
        zoneName: "Pacific/Pitcairn",
        gmtOffset: -28800,
        gmtOffsetName: "UTC-08:00",
        abbreviation: "PST",
        tzName: "Pacific Standard Time (North America",
      },
    ],
    translations: {
      kr: "핏케언 제도",
      "pt-BR": "Ilhas Pitcairn",
      pt: "Ilhas Picárnia",
      nl: "Pitcairneilanden",
      hr: "Pitcairnovo otočje",
      fa: "پیتکرن",
      de: "Pitcairn",
      es: "Islas Pitcairn",
      fr: "Îles Pitcairn",
      ja: "ピトケアン",
      it: "Isole Pitcairn",
      cn: "皮特凯恩群岛",
      tr: "Pitcairn Adalari",
    },
    latitude: "-25.06666666",
    longitude: "-130.10000000",
    emoji: "🇵🇳",
    emojiU: "U+1F1F5 U+1F1F3",
  },
  {
    id: 176,
    name: "Poland",
    iso3: "POL",
    iso2: "PL",
    numeric_code: "616",
    phone_code: "48",
    capital: "Warsaw",
    currency: "PLN",
    currency_name: "Polish złoty",
    currency_symbol: "zł",
    tld: ".pl",
    native: "Polska",
    region: "Europe",
    subregion: "Eastern Europe",
    timezones: [
      {
        zoneName: "Europe/Warsaw",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "폴란드",
      "pt-BR": "Polônia",
      pt: "Polónia",
      nl: "Polen",
      hr: "Poljska",
      fa: "لهستان",
      de: "Polen",
      es: "Polonia",
      fr: "Pologne",
      ja: "ポーランド",
      it: "Polonia",
      cn: "波兰",
      tr: "Polonya",
    },
    latitude: "52.00000000",
    longitude: "20.00000000",
    emoji: "🇵🇱",
    emojiU: "U+1F1F5 U+1F1F1",
  },
  {
    id: 177,
    name: "Portugal",
    iso3: "PRT",
    iso2: "PT",
    numeric_code: "620",
    phone_code: "351",
    capital: "Lisbon",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".pt",
    native: "Portugal",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Atlantic/Azores",
        gmtOffset: -3600,
        gmtOffsetName: "UTC-01:00",
        abbreviation: "AZOT",
        tzName: "Azores Standard Time",
      },
      {
        zoneName: "Atlantic/Madeira",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "WET",
        tzName: "Western European Time",
      },
      {
        zoneName: "Europe/Lisbon",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "WET",
        tzName: "Western European Time",
      },
    ],
    translations: {
      kr: "포르투갈",
      "pt-BR": "Portugal",
      pt: "Portugal",
      nl: "Portugal",
      hr: "Portugal",
      fa: "پرتغال",
      de: "Portugal",
      es: "Portugal",
      fr: "Portugal",
      ja: "ポルトガル",
      it: "Portogallo",
      cn: "葡萄牙",
      tr: "Portekiz",
    },
    latitude: "39.50000000",
    longitude: "-8.00000000",
    emoji: "🇵🇹",
    emojiU: "U+1F1F5 U+1F1F9",
  },
  {
    id: 178,
    name: "Puerto Rico",
    iso3: "PRI",
    iso2: "PR",
    numeric_code: "630",
    phone_code: "+1-787 and 1-939",
    capital: "San Juan",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".pr",
    native: "Puerto Rico",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Puerto_Rico",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "푸에르토리코",
      "pt-BR": "Porto Rico",
      pt: "Porto Rico",
      nl: "Puerto Rico",
      hr: "Portoriko",
      fa: "پورتو ریکو",
      de: "Puerto Rico",
      es: "Puerto Rico",
      fr: "Porto Rico",
      ja: "プエルトリコ",
      it: "Porto Rico",
      cn: "波多黎各",
      tr: "Porto Riko",
    },
    latitude: "18.25000000",
    longitude: "-66.50000000",
    emoji: "🇵🇷",
    emojiU: "U+1F1F5 U+1F1F7",
  },
  {
    id: 179,
    name: "Qatar",
    iso3: "QAT",
    iso2: "QA",
    numeric_code: "634",
    phone_code: "974",
    capital: "Doha",
    currency: "QAR",
    currency_name: "Qatari riyal",
    currency_symbol: "ق.ر",
    tld: ".qa",
    native: "قطر",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Qatar",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time",
      },
    ],
    translations: {
      kr: "카타르",
      "pt-BR": "Catar",
      pt: "Catar",
      nl: "Qatar",
      hr: "Katar",
      fa: "قطر",
      de: "Katar",
      es: "Catar",
      fr: "Qatar",
      ja: "カタール",
      it: "Qatar",
      cn: "卡塔尔",
      tr: "Katar",
    },
    latitude: "25.50000000",
    longitude: "51.25000000",
    emoji: "🇶🇦",
    emojiU: "U+1F1F6 U+1F1E6",
  },
  {
    id: 180,
    name: "Reunion",
    iso3: "REU",
    iso2: "RE",
    numeric_code: "638",
    phone_code: "262",
    capital: "Saint-Denis",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".re",
    native: "La Réunion",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Indian/Reunion",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "RET",
        tzName: "Réunion Time",
      },
    ],
    translations: {
      kr: "레위니옹",
      "pt-BR": "Reunião",
      pt: "Reunião",
      nl: "Réunion",
      hr: "Réunion",
      fa: "رئونیون",
      de: "Réunion",
      es: "Reunión",
      fr: "Réunion",
      ja: "レユニオン",
      it: "Riunione",
      cn: "留尼汪岛",
      tr: "Réunion",
    },
    latitude: "-21.15000000",
    longitude: "55.50000000",
    emoji: "🇷🇪",
    emojiU: "U+1F1F7 U+1F1EA",
  },
  {
    id: 181,
    name: "Romania",
    iso3: "ROU",
    iso2: "RO",
    numeric_code: "642",
    phone_code: "40",
    capital: "Bucharest",
    currency: "RON",
    currency_name: "Romanian leu",
    currency_symbol: "lei",
    tld: ".ro",
    native: "România",
    region: "Europe",
    subregion: "Eastern Europe",
    timezones: [
      {
        zoneName: "Europe/Bucharest",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "루마니아",
      "pt-BR": "Romênia",
      pt: "Roménia",
      nl: "Roemenië",
      hr: "Rumunjska",
      fa: "رومانی",
      de: "Rumänien",
      es: "Rumania",
      fr: "Roumanie",
      ja: "ルーマニア",
      it: "Romania",
      cn: "罗马尼亚",
      tr: "Romanya",
    },
    latitude: "46.00000000",
    longitude: "25.00000000",
    emoji: "🇷🇴",
    emojiU: "U+1F1F7 U+1F1F4",
  },
  {
    id: 182,
    name: "Russia",
    iso3: "RUS",
    iso2: "RU",
    numeric_code: "643",
    phone_code: "7",
    capital: "Moscow",
    currency: "RUB",
    currency_name: "Russian ruble",
    currency_symbol: "₽",
    tld: ".ru",
    native: "Россия",
    region: "Europe",
    subregion: "Eastern Europe",
    timezones: [
      {
        zoneName: "Asia/Anadyr",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "ANAT",
        tzName: "Anadyr Time[4",
      },
      {
        zoneName: "Asia/Barnaul",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "KRAT",
        tzName: "Krasnoyarsk Time",
      },
      {
        zoneName: "Asia/Chita",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "YAKT",
        tzName: "Yakutsk Time",
      },
      {
        zoneName: "Asia/Irkutsk",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "IRKT",
        tzName: "Irkutsk Time",
      },
      {
        zoneName: "Asia/Kamchatka",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "PETT",
        tzName: "Kamchatka Time",
      },
      {
        zoneName: "Asia/Khandyga",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "YAKT",
        tzName: "Yakutsk Time",
      },
      {
        zoneName: "Asia/Krasnoyarsk",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "KRAT",
        tzName: "Krasnoyarsk Time",
      },
      {
        zoneName: "Asia/Magadan",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "MAGT",
        tzName: "Magadan Time",
      },
      {
        zoneName: "Asia/Novokuznetsk",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "KRAT",
        tzName: "Krasnoyarsk Time",
      },
      {
        zoneName: "Asia/Novosibirsk",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "NOVT",
        tzName: "Novosibirsk Time",
      },
      {
        zoneName: "Asia/Omsk",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "OMST",
        tzName: "Omsk Time",
      },
      {
        zoneName: "Asia/Sakhalin",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "SAKT",
        tzName: "Sakhalin Island Time",
      },
      {
        zoneName: "Asia/Srednekolymsk",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "SRET",
        tzName: "Srednekolymsk Time",
      },
      {
        zoneName: "Asia/Tomsk",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "MSD+3",
        tzName: "Moscow Daylight Time+3",
      },
      {
        zoneName: "Asia/Ust-Nera",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "VLAT",
        tzName: "Vladivostok Time",
      },
      {
        zoneName: "Asia/Vladivostok",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "VLAT",
        tzName: "Vladivostok Time",
      },
      {
        zoneName: "Asia/Yakutsk",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "YAKT",
        tzName: "Yakutsk Time",
      },
      {
        zoneName: "Asia/Yekaterinburg",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "YEKT",
        tzName: "Yekaterinburg Time",
      },
      {
        zoneName: "Europe/Astrakhan",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "SAMT",
        tzName: "Samara Time",
      },
      {
        zoneName: "Europe/Kaliningrad",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
      {
        zoneName: "Europe/Kirov",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "MSK",
        tzName: "Moscow Time",
      },
      {
        zoneName: "Europe/Moscow",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "MSK",
        tzName: "Moscow Time",
      },
      {
        zoneName: "Europe/Samara",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "SAMT",
        tzName: "Samara Time",
      },
      {
        zoneName: "Europe/Saratov",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "MSD",
        tzName: "Moscow Daylight Time+4",
      },
      {
        zoneName: "Europe/Ulyanovsk",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "SAMT",
        tzName: "Samara Time",
      },
      {
        zoneName: "Europe/Volgograd",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "MSK",
        tzName: "Moscow Standard Time",
      },
    ],
    translations: {
      kr: "러시아",
      "pt-BR": "Rússia",
      pt: "Rússia",
      nl: "Rusland",
      hr: "Rusija",
      fa: "روسیه",
      de: "Russland",
      es: "Rusia",
      fr: "Russie",
      ja: "ロシア連邦",
      it: "Russia",
      cn: "俄罗斯联邦",
      tr: "Rusya",
    },
    latitude: "60.00000000",
    longitude: "100.00000000",
    emoji: "🇷🇺",
    emojiU: "U+1F1F7 U+1F1FA",
  },
  {
    id: 183,
    name: "Rwanda",
    iso3: "RWA",
    iso2: "RW",
    numeric_code: "646",
    phone_code: "250",
    capital: "Kigali",
    currency: "RWF",
    currency_name: "Rwandan franc",
    currency_symbol: "FRw",
    tld: ".rw",
    native: "Rwanda",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Africa/Kigali",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "르완다",
      "pt-BR": "Ruanda",
      pt: "Ruanda",
      nl: "Rwanda",
      hr: "Ruanda",
      fa: "رواندا",
      de: "Ruanda",
      es: "Ruanda",
      fr: "Rwanda",
      ja: "ルワンダ",
      it: "Ruanda",
      cn: "卢旺达",
      tr: "Ruanda",
    },
    latitude: "-2.00000000",
    longitude: "30.00000000",
    emoji: "🇷🇼",
    emojiU: "U+1F1F7 U+1F1FC",
  },
  {
    id: 184,
    name: "Saint Helena",
    iso3: "SHN",
    iso2: "SH",
    numeric_code: "654",
    phone_code: "290",
    capital: "Jamestown",
    currency: "SHP",
    currency_name: "Saint Helena pound",
    currency_symbol: "£",
    tld: ".sh",
    native: "Saint Helena",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Atlantic/St_Helena",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "세인트헬레나",
      "pt-BR": "Santa Helena",
      pt: "Santa Helena",
      nl: "Sint-Helena",
      hr: "Sveta Helena",
      fa: "سنت هلنا، اسنشن و تریستان دا کونا",
      de: "Sankt Helena",
      es: "Santa Helena",
      fr: "Sainte-Hélène",
      ja: "セントヘレナ・アセンションおよびトリスタンダクーニャ",
      it: "Sant'Elena",
      cn: "圣赫勒拿",
      tr: "Saint Helena",
    },
    latitude: "-15.95000000",
    longitude: "-5.70000000",
    emoji: "🇸🇭",
    emojiU: "U+1F1F8 U+1F1ED",
  },
  {
    id: 185,
    name: "Saint Kitts And Nevis",
    iso3: "KNA",
    iso2: "KN",
    numeric_code: "659",
    phone_code: "+1-869",
    capital: "Basseterre",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".kn",
    native: "Saint Kitts and Nevis",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/St_Kitts",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "세인트키츠 네비스",
      "pt-BR": "São Cristóvão e Neves",
      pt: "São Cristóvão e Neves",
      nl: "Saint Kitts en Nevis",
      hr: "Sveti Kristof i Nevis",
      fa: "سنت کیتس و نویس",
      de: "St. Kitts und Nevis",
      es: "San Cristóbal y Nieves",
      fr: "Saint-Christophe-et-Niévès",
      ja: "セントクリストファー・ネイビス",
      it: "Saint Kitts e Nevis",
      cn: "圣基茨和尼维斯",
      tr: "Saint Kitts Ve Nevis",
    },
    latitude: "17.33333333",
    longitude: "-62.75000000",
    emoji: "🇰🇳",
    emojiU: "U+1F1F0 U+1F1F3",
  },
  {
    id: 186,
    name: "Saint Lucia",
    iso3: "LCA",
    iso2: "LC",
    numeric_code: "662",
    phone_code: "+1-758",
    capital: "Castries",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".lc",
    native: "Saint Lucia",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/St_Lucia",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "세인트루시아",
      "pt-BR": "Santa Lúcia",
      pt: "Santa Lúcia",
      nl: "Saint Lucia",
      hr: "Sveta Lucija",
      fa: "سنت لوسیا",
      de: "Saint Lucia",
      es: "Santa Lucía",
      fr: "Saint-Lucie",
      ja: "セントルシア",
      it: "Santa Lucia",
      cn: "圣卢西亚",
      tr: "Saint Lucia",
    },
    latitude: "13.88333333",
    longitude: "-60.96666666",
    emoji: "🇱🇨",
    emojiU: "U+1F1F1 U+1F1E8",
  },
  {
    id: 187,
    name: "Saint Pierre and Miquelon",
    iso3: "SPM",
    iso2: "PM",
    numeric_code: "666",
    phone_code: "508",
    capital: "Saint-Pierre",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".pm",
    native: "Saint-Pierre-et-Miquelon",
    region: "Americas",
    subregion: "Northern America",
    timezones: [
      {
        zoneName: "America/Miquelon",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "PMDT",
        tzName: "Pierre & Miquelon Daylight Time",
      },
    ],
    translations: {
      kr: "생피에르 미클롱",
      "pt-BR": "Saint-Pierre e Miquelon",
      pt: "São Pedro e Miquelon",
      nl: "Saint Pierre en Miquelon",
      hr: "Sveti Petar i Mikelon",
      fa: "سن پیر و میکلن",
      de: "Saint-Pierre und Miquelon",
      es: "San Pedro y Miquelón",
      fr: "Saint-Pierre-et-Miquelon",
      ja: "サンピエール島・ミクロン島",
      it: "Saint-Pierre e Miquelon",
      cn: "圣皮埃尔和密克隆",
      tr: "Saint Pierre Ve Miquelon",
    },
    latitude: "46.83333333",
    longitude: "-56.33333333",
    emoji: "🇵🇲",
    emojiU: "U+1F1F5 U+1F1F2",
  },
  {
    id: 188,
    name: "Saint Vincent And The Grenadines",
    iso3: "VCT",
    iso2: "VC",
    numeric_code: "670",
    phone_code: "+1-784",
    capital: "Kingstown",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".vc",
    native: "Saint Vincent and the Grenadines",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/St_Vincent",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "세인트빈센트 그레나딘",
      "pt-BR": "São Vicente e Granadinas",
      pt: "São Vicente e Granadinas",
      nl: "Saint Vincent en de Grenadines",
      hr: "Sveti Vincent i Grenadini",
      fa: "سنت وینسنت و گرنادین‌ها",
      de: "Saint Vincent und die Grenadinen",
      es: "San Vicente y Granadinas",
      fr: "Saint-Vincent-et-les-Grenadines",
      ja: "セントビンセントおよびグレナディーン諸島",
      it: "Saint Vincent e Grenadine",
      cn: "圣文森特和格林纳丁斯",
      tr: "Saint Vincent Ve Grenadinler",
    },
    latitude: "13.25000000",
    longitude: "-61.20000000",
    emoji: "🇻🇨",
    emojiU: "U+1F1FB U+1F1E8",
  },
  {
    id: 189,
    name: "Saint-Barthelemy",
    iso3: "BLM",
    iso2: "BL",
    numeric_code: "652",
    phone_code: "590",
    capital: "Gustavia",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".bl",
    native: "Saint-Barthélemy",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/St_Barthelemy",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "생바르텔레미",
      "pt-BR": "São Bartolomeu",
      pt: "São Bartolomeu",
      nl: "Saint Barthélemy",
      hr: "Saint Barthélemy",
      fa: "سن-بارتلمی",
      de: "Saint-Barthélemy",
      es: "San Bartolomé",
      fr: "Saint-Barthélemy",
      ja: "サン・バルテルミー",
      it: "Antille Francesi",
      cn: "圣巴泰勒米",
      tr: "Saint Barthélemy",
    },
    latitude: "18.50000000",
    longitude: "-63.41666666",
    emoji: "🇧🇱",
    emojiU: "U+1F1E7 U+1F1F1",
  },
  {
    id: 190,
    name: "Saint-Martin (French part)",
    iso3: "MAF",
    iso2: "MF",
    numeric_code: "663",
    phone_code: "590",
    capital: "Marigot",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".mf",
    native: "Saint-Martin",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Marigot",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "세인트마틴 섬",
      "pt-BR": "Saint Martin",
      pt: "Ilha São Martinho",
      nl: "Saint-Martin",
      hr: "Sveti Martin",
      fa: "سینت مارتن",
      de: "Saint Martin",
      es: "Saint Martin",
      fr: "Saint-Martin",
      ja: "サン・マルタン（フランス領）",
      it: "Saint Martin",
      cn: "密克罗尼西亚",
      tr: "Saint Martin",
    },
    latitude: "18.08333333",
    longitude: "-63.95000000",
    emoji: "🇲🇫",
    emojiU: "U+1F1F2 U+1F1EB",
  },
  {
    id: 191,
    name: "Samoa",
    iso3: "WSM",
    iso2: "WS",
    numeric_code: "882",
    phone_code: "685",
    capital: "Apia",
    currency: "WST",
    currency_name: "Samoan tālā",
    currency_symbol: "SAT",
    tld: ".ws",
    native: "Samoa",
    region: "Oceania",
    subregion: "Polynesia",
    timezones: [
      {
        zoneName: "Pacific/Apia",
        gmtOffset: 50400,
        gmtOffsetName: "UTC+14:00",
        abbreviation: "WST",
        tzName: "West Samoa Time",
      },
    ],
    translations: {
      kr: "사모아",
      "pt-BR": "Samoa",
      pt: "Samoa",
      nl: "Samoa",
      hr: "Samoa",
      fa: "ساموآ",
      de: "Samoa",
      es: "Samoa",
      fr: "Samoa",
      ja: "サモア",
      it: "Samoa",
      cn: "萨摩亚",
      tr: "Samoa",
    },
    latitude: "-13.58333333",
    longitude: "-172.33333333",
    emoji: "🇼🇸",
    emojiU: "U+1F1FC U+1F1F8",
  },
  {
    id: 192,
    name: "San Marino",
    iso3: "SMR",
    iso2: "SM",
    numeric_code: "674",
    phone_code: "378",
    capital: "San Marino",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".sm",
    native: "San Marino",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/San_Marino",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "산마리노",
      "pt-BR": "San Marino",
      pt: "São Marinho",
      nl: "San Marino",
      hr: "San Marino",
      fa: "سان مارینو",
      de: "San Marino",
      es: "San Marino",
      fr: "Saint-Marin",
      ja: "サンマリノ",
      it: "San Marino",
      cn: "圣马力诺",
      tr: "San Marino",
    },
    latitude: "43.76666666",
    longitude: "12.41666666",
    emoji: "🇸🇲",
    emojiU: "U+1F1F8 U+1F1F2",
  },
  {
    id: 193,
    name: "Sao Tome and Principe",
    iso3: "STP",
    iso2: "ST",
    numeric_code: "678",
    phone_code: "239",
    capital: "Sao Tome",
    currency: "STD",
    currency_name: "Dobra",
    currency_symbol: "Db",
    tld: ".st",
    native: "São Tomé e Príncipe",
    region: "Africa",
    subregion: "Middle Africa",
    timezones: [
      {
        zoneName: "Africa/Sao_Tome",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "상투메 프린시페",
      "pt-BR": "São Tomé e Príncipe",
      pt: "São Tomé e Príncipe",
      nl: "Sao Tomé en Principe",
      hr: "Sveti Toma i Princip",
      fa: "کواترو دو فرویرو",
      de: "São Tomé und Príncipe",
      es: "Santo Tomé y Príncipe",
      fr: "Sao Tomé-et-Principe",
      ja: "サントメ・プリンシペ",
      it: "São Tomé e Príncipe",
      cn: "圣多美和普林西比",
      tr: "Sao Tome Ve Prinsipe",
    },
    latitude: "1.00000000",
    longitude: "7.00000000",
    emoji: "🇸🇹",
    emojiU: "U+1F1F8 U+1F1F9",
  },
  {
    id: 194,
    name: "Saudi Arabia",
    iso3: "SAU",
    iso2: "SA",
    numeric_code: "682",
    phone_code: "966",
    capital: "Riyadh",
    currency: "SAR",
    currency_name: "Saudi riyal",
    currency_symbol: "﷼",
    tld: ".sa",
    native: "المملكة العربية السعودية",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Riyadh",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time",
      },
    ],
    translations: {
      kr: "사우디아라비아",
      "pt-BR": "Arábia Saudita",
      pt: "Arábia Saudita",
      nl: "Saoedi-Arabië",
      hr: "Saudijska Arabija",
      fa: "عربستان سعودی",
      de: "Saudi-Arabien",
      es: "Arabia Saudí",
      fr: "Arabie Saoudite",
      ja: "サウジアラビア",
      it: "Arabia Saudita",
      cn: "沙特阿拉伯",
      tr: "Suudi Arabistan",
    },
    latitude: "25.00000000",
    longitude: "45.00000000",
    emoji: "🇸🇦",
    emojiU: "U+1F1F8 U+1F1E6",
  },
  {
    id: 195,
    name: "Senegal",
    iso3: "SEN",
    iso2: "SN",
    numeric_code: "686",
    phone_code: "221",
    capital: "Dakar",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".sn",
    native: "Sénégal",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Dakar",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "세네갈",
      "pt-BR": "Senegal",
      pt: "Senegal",
      nl: "Senegal",
      hr: "Senegal",
      fa: "سنگال",
      de: "Senegal",
      es: "Senegal",
      fr: "Sénégal",
      ja: "セネガル",
      it: "Senegal",
      cn: "塞内加尔",
      tr: "Senegal",
    },
    latitude: "14.00000000",
    longitude: "-14.00000000",
    emoji: "🇸🇳",
    emojiU: "U+1F1F8 U+1F1F3",
  },
  {
    id: 196,
    name: "Serbia",
    iso3: "SRB",
    iso2: "RS",
    numeric_code: "688",
    phone_code: "381",
    capital: "Belgrade",
    currency: "RSD",
    currency_name: "Serbian dinar",
    currency_symbol: "din",
    tld: ".rs",
    native: "Србија",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/Belgrade",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "세르비아",
      "pt-BR": "Sérvia",
      pt: "Sérvia",
      nl: "Servië",
      hr: "Srbija",
      fa: "صربستان",
      de: "Serbien",
      es: "Serbia",
      fr: "Serbie",
      ja: "セルビア",
      it: "Serbia",
      cn: "塞尔维亚",
      tr: "Sirbistan",
    },
    latitude: "44.00000000",
    longitude: "21.00000000",
    emoji: "🇷🇸",
    emojiU: "U+1F1F7 U+1F1F8",
  },
  {
    id: 197,
    name: "Seychelles",
    iso3: "SYC",
    iso2: "SC",
    numeric_code: "690",
    phone_code: "248",
    capital: "Victoria",
    currency: "SCR",
    currency_name: "Seychellois rupee",
    currency_symbol: "SRe",
    tld: ".sc",
    native: "Seychelles",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Indian/Mahe",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "SCT",
        tzName: "Seychelles Time",
      },
    ],
    translations: {
      kr: "세이셸",
      "pt-BR": "Seicheles",
      pt: "Seicheles",
      nl: "Seychellen",
      hr: "Sejšeli",
      fa: "سیشل",
      de: "Seychellen",
      es: "Seychelles",
      fr: "Seychelles",
      ja: "セーシェル",
      it: "Seychelles",
      cn: "塞舌尔",
      tr: "Seyşeller",
    },
    latitude: "-4.58333333",
    longitude: "55.66666666",
    emoji: "🇸🇨",
    emojiU: "U+1F1F8 U+1F1E8",
  },
  {
    id: 198,
    name: "Sierra Leone",
    iso3: "SLE",
    iso2: "SL",
    numeric_code: "694",
    phone_code: "232",
    capital: "Freetown",
    currency: "SLL",
    currency_name: "Sierra Leonean leone",
    currency_symbol: "Le",
    tld: ".sl",
    native: "Sierra Leone",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Freetown",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "시에라리온",
      "pt-BR": "Serra Leoa",
      pt: "Serra Leoa",
      nl: "Sierra Leone",
      hr: "Sijera Leone",
      fa: "سیرالئون",
      de: "Sierra Leone",
      es: "Sierra Leone",
      fr: "Sierra Leone",
      ja: "シエラレオネ",
      it: "Sierra Leone",
      cn: "塞拉利昂",
      tr: "Sierra Leone",
    },
    latitude: "8.50000000",
    longitude: "-11.50000000",
    emoji: "🇸🇱",
    emojiU: "U+1F1F8 U+1F1F1",
  },
  {
    id: 199,
    name: "Singapore",
    iso3: "SGP",
    iso2: "SG",
    numeric_code: "702",
    phone_code: "65",
    capital: "Singapur",
    currency: "SGD",
    currency_name: "Singapore dollar",
    currency_symbol: "$",
    tld: ".sg",
    native: "Singapore",
    region: "Asia",
    subregion: "South-Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Singapore",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "SGT",
        tzName: "Singapore Time",
      },
    ],
    translations: {
      kr: "싱가포르",
      "pt-BR": "Singapura",
      pt: "Singapura",
      nl: "Singapore",
      hr: "Singapur",
      fa: "سنگاپور",
      de: "Singapur",
      es: "Singapur",
      fr: "Singapour",
      ja: "シンガポール",
      it: "Singapore",
      cn: "新加坡",
      tr: "Singapur",
    },
    latitude: "1.36666666",
    longitude: "103.80000000",
    emoji: "🇸🇬",
    emojiU: "U+1F1F8 U+1F1EC",
  },
  {
    id: 250,
    name: "Sint Maarten (Dutch part)",
    iso3: "SXM",
    iso2: "SX",
    numeric_code: "534",
    phone_code: "1721",
    capital: "Philipsburg",
    currency: "ANG",
    currency_name: "Netherlands Antillean guilder",
    currency_symbol: "ƒ",
    tld: ".sx",
    native: "Sint Maarten",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Anguilla",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "신트마르턴",
      "pt-BR": "Sint Maarten",
      pt: "São Martinho",
      nl: "Sint Maarten",
      fa: "سینت مارتن",
      de: "Sint Maarten (niederl. Teil)",
      fr: "Saint Martin (partie néerlandaise)",
      it: "Saint Martin (parte olandese)",
      cn: "圣马丁岛（荷兰部分）",
      tr: "Sint Maarten",
    },
    latitude: "18.03333300",
    longitude: "-63.05000000",
    emoji: "🇸🇽",
    emojiU: "U+1F1F8 U+1F1FD",
  },
  {
    id: 200,
    name: "Slovakia",
    iso3: "SVK",
    iso2: "SK",
    numeric_code: "703",
    phone_code: "421",
    capital: "Bratislava",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".sk",
    native: "Slovensko",
    region: "Europe",
    subregion: "Eastern Europe",
    timezones: [
      {
        zoneName: "Europe/Bratislava",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "슬로바키아",
      "pt-BR": "Eslováquia",
      pt: "Eslováquia",
      nl: "Slowakije",
      hr: "Slovačka",
      fa: "اسلواکی",
      de: "Slowakei",
      es: "República Eslovaca",
      fr: "Slovaquie",
      ja: "スロバキア",
      it: "Slovacchia",
      cn: "斯洛伐克",
      tr: "Slovakya",
    },
    latitude: "48.66666666",
    longitude: "19.50000000",
    emoji: "🇸🇰",
    emojiU: "U+1F1F8 U+1F1F0",
  },
  {
    id: 201,
    name: "Slovenia",
    iso3: "SVN",
    iso2: "SI",
    numeric_code: "705",
    phone_code: "386",
    capital: "Ljubljana",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".si",
    native: "Slovenija",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/Ljubljana",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "슬로베니아",
      "pt-BR": "Eslovênia",
      pt: "Eslovénia",
      nl: "Slovenië",
      hr: "Slovenija",
      fa: "اسلوونی",
      de: "Slowenien",
      es: "Eslovenia",
      fr: "Slovénie",
      ja: "スロベニア",
      it: "Slovenia",
      cn: "斯洛文尼亚",
      tr: "Slovenya",
    },
    latitude: "46.11666666",
    longitude: "14.81666666",
    emoji: "🇸🇮",
    emojiU: "U+1F1F8 U+1F1EE",
  },
  {
    id: 202,
    name: "Solomon Islands",
    iso3: "SLB",
    iso2: "SB",
    numeric_code: "090",
    phone_code: "677",
    capital: "Honiara",
    currency: "SBD",
    currency_name: "Solomon Islands dollar",
    currency_symbol: "Si$",
    tld: ".sb",
    native: "Solomon Islands",
    region: "Oceania",
    subregion: "Melanesia",
    timezones: [
      {
        zoneName: "Pacific/Guadalcanal",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "SBT",
        tzName: "Solomon Islands Time",
      },
    ],
    translations: {
      kr: "솔로몬 제도",
      "pt-BR": "Ilhas Salomão",
      pt: "Ilhas Salomão",
      nl: "Salomonseilanden",
      hr: "Solomonski Otoci",
      fa: "جزایر سلیمان",
      de: "Salomonen",
      es: "Islas Salomón",
      fr: "Îles Salomon",
      ja: "ソロモン諸島",
      it: "Isole Salomone",
      cn: "所罗门群岛",
      tr: "Solomon Adalari",
    },
    latitude: "-8.00000000",
    longitude: "159.00000000",
    emoji: "🇸🇧",
    emojiU: "U+1F1F8 U+1F1E7",
  },
  {
    id: 203,
    name: "Somalia",
    iso3: "SOM",
    iso2: "SO",
    numeric_code: "706",
    phone_code: "252",
    capital: "Mogadishu",
    currency: "SOS",
    currency_name: "Somali shilling",
    currency_symbol: "Sh.so.",
    tld: ".so",
    native: "Soomaaliya",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Africa/Mogadishu",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "소말리아",
      "pt-BR": "Somália",
      pt: "Somália",
      nl: "Somalië",
      hr: "Somalija",
      fa: "سومالی",
      de: "Somalia",
      es: "Somalia",
      fr: "Somalie",
      ja: "ソマリア",
      it: "Somalia",
      cn: "索马里",
      tr: "Somali",
    },
    latitude: "10.00000000",
    longitude: "49.00000000",
    emoji: "🇸🇴",
    emojiU: "U+1F1F8 U+1F1F4",
  },
  {
    id: 204,
    name: "South Africa",
    iso3: "ZAF",
    iso2: "ZA",
    numeric_code: "710",
    phone_code: "27",
    capital: "Pretoria",
    currency: "ZAR",
    currency_name: "South African rand",
    currency_symbol: "R",
    tld: ".za",
    native: "South Africa",
    region: "Africa",
    subregion: "Southern Africa",
    timezones: [
      {
        zoneName: "Africa/Johannesburg",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "SAST",
        tzName: "South African Standard Time",
      },
    ],
    translations: {
      kr: "남아프리카 공화국",
      "pt-BR": "República Sul-Africana",
      pt: "República Sul-Africana",
      nl: "Zuid-Afrika",
      hr: "Južnoafrička Republika",
      fa: "آفریقای جنوبی",
      de: "Republik Südafrika",
      es: "República de Sudáfrica",
      fr: "Afrique du Sud",
      ja: "南アフリカ",
      it: "Sud Africa",
      cn: "南非",
      tr: "Güney Afrika Cumhuriyeti",
    },
    latitude: "-29.00000000",
    longitude: "24.00000000",
    emoji: "🇿🇦",
    emojiU: "U+1F1FF U+1F1E6",
  },
  {
    id: 205,
    name: "South Georgia",
    iso3: "SGS",
    iso2: "GS",
    numeric_code: "239",
    phone_code: "500",
    capital: "Grytviken",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "£",
    tld: ".gs",
    native: "South Georgia",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "Atlantic/South_Georgia",
        gmtOffset: -7200,
        gmtOffsetName: "UTC-02:00",
        abbreviation: "GST",
        tzName: "South Georgia and the South Sandwich Islands Time",
      },
    ],
    translations: {
      kr: "사우스조지아",
      "pt-BR": "Ilhas Geórgias do Sul e Sandwich do Sul",
      pt: "Ilhas Geórgia do Sul e Sanduíche do Sul",
      nl: "Zuid-Georgia en Zuidelijke Sandwicheilanden",
      hr: "Južna Georgija i otočje Južni Sandwich",
      fa: "جزایر جورجیای جنوبی و ساندویچ جنوبی",
      de: "Südgeorgien und die Südlichen Sandwichinseln",
      es: "Islas Georgias del Sur y Sandwich del Sur",
      fr: "Géorgie du Sud-et-les Îles Sandwich du Sud",
      ja: "サウスジョージア・サウスサンドウィッチ諸島",
      it: "Georgia del Sud e Isole Sandwich Meridionali",
      cn: "南乔治亚",
      tr: "Güney Georgia",
    },
    latitude: "-54.50000000",
    longitude: "-37.00000000",
    emoji: "🇬🇸",
    emojiU: "U+1F1EC U+1F1F8",
  },
  {
    id: 116,
    name: "South Korea",
    iso3: "KOR",
    iso2: "KR",
    numeric_code: "410",
    phone_code: "82",
    capital: "Seoul",
    currency: "KRW",
    currency_name: "Won",
    currency_symbol: "₩",
    tld: ".kr",
    native: "대한민국",
    region: "Asia",
    subregion: "Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Seoul",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "KST",
        tzName: "Korea Standard Time",
      },
    ],
    translations: {
      kr: "대한민국",
      "pt-BR": "Coreia do Sul",
      pt: "Coreia do Sul",
      nl: "Zuid-Korea",
      hr: "Južna Koreja",
      fa: "کره شمالی",
      de: "Südkorea",
      es: "Corea del Sur",
      fr: "Corée du Sud",
      ja: "大韓民国",
      it: "Corea del Sud",
      cn: "韩国",
      tr: "Güney Kore",
    },
    latitude: "37.00000000",
    longitude: "127.50000000",
    emoji: "🇰🇷",
    emojiU: "U+1F1F0 U+1F1F7",
  },
  {
    id: 206,
    name: "South Sudan",
    iso3: "SSD",
    iso2: "SS",
    numeric_code: "728",
    phone_code: "211",
    capital: "Juba",
    currency: "SSP",
    currency_name: "South Sudanese pound",
    currency_symbol: "£",
    tld: ".ss",
    native: "South Sudan",
    region: "Africa",
    subregion: "Middle Africa",
    timezones: [
      {
        zoneName: "Africa/Juba",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "남수단",
      "pt-BR": "Sudão do Sul",
      pt: "Sudão do Sul",
      nl: "Zuid-Soedan",
      hr: "Južni Sudan",
      fa: "سودان جنوبی",
      de: "Südsudan",
      es: "Sudán del Sur",
      fr: "Soudan du Sud",
      ja: "南スーダン",
      it: "Sudan del sud",
      cn: "南苏丹",
      tr: "Güney Sudan",
    },
    latitude: "7.00000000",
    longitude: "30.00000000",
    emoji: "🇸🇸",
    emojiU: "U+1F1F8 U+1F1F8",
  },
  {
    id: 207,
    name: "Spain",
    iso3: "ESP",
    iso2: "ES",
    numeric_code: "724",
    phone_code: "34",
    capital: "Madrid",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".es",
    native: "España",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Africa/Ceuta",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
      {
        zoneName: "Atlantic/Canary",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "WET",
        tzName: "Western European Time",
      },
      {
        zoneName: "Europe/Madrid",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "스페인",
      "pt-BR": "Espanha",
      pt: "Espanha",
      nl: "Spanje",
      hr: "Španjolska",
      fa: "اسپانیا",
      de: "Spanien",
      es: "España",
      fr: "Espagne",
      ja: "スペイン",
      it: "Spagna",
      cn: "西班牙",
      tr: "İspanya",
    },
    latitude: "40.00000000",
    longitude: "-4.00000000",
    emoji: "🇪🇸",
    emojiU: "U+1F1EA U+1F1F8",
  },
  {
    id: 208,
    name: "Sri Lanka",
    iso3: "LKA",
    iso2: "LK",
    numeric_code: "144",
    phone_code: "94",
    capital: "Colombo",
    currency: "LKR",
    currency_name: "Sri Lankan rupee",
    currency_symbol: "Rs",
    tld: ".lk",
    native: "śrī laṃkāva",
    region: "Asia",
    subregion: "Southern Asia",
    timezones: [
      {
        zoneName: "Asia/Colombo",
        gmtOffset: 19800,
        gmtOffsetName: "UTC+05:30",
        abbreviation: "IST",
        tzName: "Indian Standard Time",
      },
    ],
    translations: {
      kr: "스리랑카",
      "pt-BR": "Sri Lanka",
      pt: "Sri Lanka",
      nl: "Sri Lanka",
      hr: "Šri Lanka",
      fa: "سری‌لانکا",
      de: "Sri Lanka",
      es: "Sri Lanka",
      fr: "Sri Lanka",
      ja: "スリランカ",
      it: "Sri Lanka",
      cn: "斯里兰卡",
      tr: "Sri Lanka",
    },
    latitude: "7.00000000",
    longitude: "81.00000000",
    emoji: "🇱🇰",
    emojiU: "U+1F1F1 U+1F1F0",
  },
  {
    id: 209,
    name: "Sudan",
    iso3: "SDN",
    iso2: "SD",
    numeric_code: "729",
    phone_code: "249",
    capital: "Khartoum",
    currency: "SDG",
    currency_name: "Sudanese pound",
    currency_symbol: ".س.ج",
    tld: ".sd",
    native: "السودان",
    region: "Africa",
    subregion: "Northern Africa",
    timezones: [
      {
        zoneName: "Africa/Khartoum",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EAT",
        tzName: "Eastern African Time",
      },
    ],
    translations: {
      kr: "수단",
      "pt-BR": "Sudão",
      pt: "Sudão",
      nl: "Soedan",
      hr: "Sudan",
      fa: "سودان",
      de: "Sudan",
      es: "Sudán",
      fr: "Soudan",
      ja: "スーダン",
      it: "Sudan",
      cn: "苏丹",
      tr: "Sudan",
    },
    latitude: "15.00000000",
    longitude: "30.00000000",
    emoji: "🇸🇩",
    emojiU: "U+1F1F8 U+1F1E9",
  },
  {
    id: 210,
    name: "Suriname",
    iso3: "SUR",
    iso2: "SR",
    numeric_code: "740",
    phone_code: "597",
    capital: "Paramaribo",
    currency: "SRD",
    currency_name: "Surinamese dollar",
    currency_symbol: "$",
    tld: ".sr",
    native: "Suriname",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "America/Paramaribo",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "SRT",
        tzName: "Suriname Time",
      },
    ],
    translations: {
      kr: "수리남",
      "pt-BR": "Suriname",
      pt: "Suriname",
      nl: "Suriname",
      hr: "Surinam",
      fa: "سورینام",
      de: "Suriname",
      es: "Surinam",
      fr: "Surinam",
      ja: "スリナム",
      it: "Suriname",
      cn: "苏里南",
      tr: "Surinam",
    },
    latitude: "4.00000000",
    longitude: "-56.00000000",
    emoji: "🇸🇷",
    emojiU: "U+1F1F8 U+1F1F7",
  },
  {
    id: 211,
    name: "Svalbard And Jan Mayen Islands",
    iso3: "SJM",
    iso2: "SJ",
    numeric_code: "744",
    phone_code: "47",
    capital: "Longyearbyen",
    currency: "NOK",
    currency_name: "Norwegian Krone",
    currency_symbol: "kr",
    tld: ".sj",
    native: "Svalbard og Jan Mayen",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Arctic/Longyearbyen",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "스발바르 얀마옌 제도",
      "pt-BR": "Svalbard",
      pt: "Svalbard",
      nl: "Svalbard en Jan Mayen",
      hr: "Svalbard i Jan Mayen",
      fa: "سوالبارد و یان ماین",
      de: "Svalbard und Jan Mayen",
      es: "Islas Svalbard y Jan Mayen",
      fr: "Svalbard et Jan Mayen",
      ja: "スヴァールバル諸島およびヤンマイエン島",
      it: "Svalbard e Jan Mayen",
      cn: "斯瓦尔巴和扬马延群岛",
      tr: "Svalbard Ve Jan Mayen",
    },
    latitude: "78.00000000",
    longitude: "20.00000000",
    emoji: "🇸🇯",
    emojiU: "U+1F1F8 U+1F1EF",
  },
  {
    id: 212,
    name: "Swaziland",
    iso3: "SWZ",
    iso2: "SZ",
    numeric_code: "748",
    phone_code: "268",
    capital: "Mbabane",
    currency: "SZL",
    currency_name: "Lilangeni",
    currency_symbol: "E",
    tld: ".sz",
    native: "Swaziland",
    region: "Africa",
    subregion: "Southern Africa",
    timezones: [
      {
        zoneName: "Africa/Mbabane",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "SAST",
        tzName: "South African Standard Time",
      },
    ],
    translations: {
      kr: "에스와티니",
      "pt-BR": "Suazilândia",
      pt: "Suazilândia",
      nl: "Swaziland",
      hr: "Svazi",
      fa: "سوازیلند",
      de: "Swasiland",
      es: "Suazilandia",
      fr: "Swaziland",
      ja: "スワジランド",
      it: "Swaziland",
      cn: "斯威士兰",
      tr: "Esvatini",
    },
    latitude: "-26.50000000",
    longitude: "31.50000000",
    emoji: "🇸🇿",
    emojiU: "U+1F1F8 U+1F1FF",
  },
  {
    id: 213,
    name: "Sweden",
    iso3: "SWE",
    iso2: "SE",
    numeric_code: "752",
    phone_code: "46",
    capital: "Stockholm",
    currency: "SEK",
    currency_name: "Swedish krona",
    currency_symbol: "kr",
    tld: ".se",
    native: "Sverige",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Europe/Stockholm",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "스웨덴",
      "pt-BR": "Suécia",
      pt: "Suécia",
      nl: "Zweden",
      hr: "Švedska",
      fa: "سوئد",
      de: "Schweden",
      es: "Suecia",
      fr: "Suède",
      ja: "スウェーデン",
      it: "Svezia",
      cn: "瑞典",
      tr: "İsveç",
    },
    latitude: "62.00000000",
    longitude: "15.00000000",
    emoji: "🇸🇪",
    emojiU: "U+1F1F8 U+1F1EA",
  },
  {
    id: 214,
    name: "Switzerland",
    iso3: "CHE",
    iso2: "CH",
    numeric_code: "756",
    phone_code: "41",
    capital: "Bern",
    currency: "CHF",
    currency_name: "Swiss franc",
    currency_symbol: "CHf",
    tld: ".ch",
    native: "Schweiz",
    region: "Europe",
    subregion: "Western Europe",
    timezones: [
      {
        zoneName: "Europe/Zurich",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "스위스",
      "pt-BR": "Suíça",
      pt: "Suíça",
      nl: "Zwitserland",
      hr: "Švicarska",
      fa: "سوئیس",
      de: "Schweiz",
      es: "Suiza",
      fr: "Suisse",
      ja: "スイス",
      it: "Svizzera",
      cn: "瑞士",
      tr: "İsviçre",
    },
    latitude: "47.00000000",
    longitude: "8.00000000",
    emoji: "🇨🇭",
    emojiU: "U+1F1E8 U+1F1ED",
  },
  {
    id: 215,
    name: "Syria",
    iso3: "SYR",
    iso2: "SY",
    numeric_code: "760",
    phone_code: "963",
    capital: "Damascus",
    currency: "SYP",
    currency_name: "Syrian pound",
    currency_symbol: "LS",
    tld: ".sy",
    native: "سوريا",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Damascus",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "시리아",
      "pt-BR": "Síria",
      pt: "Síria",
      nl: "Syrië",
      hr: "Sirija",
      fa: "سوریه",
      de: "Syrien",
      es: "Siria",
      fr: "Syrie",
      ja: "シリア・アラブ共和国",
      it: "Siria",
      cn: "叙利亚",
      tr: "Suriye",
    },
    latitude: "35.00000000",
    longitude: "38.00000000",
    emoji: "🇸🇾",
    emojiU: "U+1F1F8 U+1F1FE",
  },
  {
    id: 216,
    name: "Taiwan",
    iso3: "TWN",
    iso2: "TW",
    numeric_code: "158",
    phone_code: "886",
    capital: "Taipei",
    currency: "TWD",
    currency_name: "New Taiwan dollar",
    currency_symbol: "$",
    tld: ".tw",
    native: "臺灣",
    region: "Asia",
    subregion: "Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Taipei",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "CST",
        tzName: "China Standard Time",
      },
    ],
    translations: {
      kr: "대만",
      "pt-BR": "Taiwan",
      pt: "Taiwan",
      nl: "Taiwan",
      hr: "Tajvan",
      fa: "تایوان",
      de: "Taiwan",
      es: "Taiwán",
      fr: "Taïwan",
      ja: "台湾（中華民国）",
      it: "Taiwan",
      cn: "中国台湾",
      tr: "Tayvan",
    },
    latitude: "23.50000000",
    longitude: "121.00000000",
    emoji: "🇹🇼",
    emojiU: "U+1F1F9 U+1F1FC",
  },
  {
    id: 217,
    name: "Tajikistan",
    iso3: "TJK",
    iso2: "TJ",
    numeric_code: "762",
    phone_code: "992",
    capital: "Dushanbe",
    currency: "TJS",
    currency_name: "Tajikistani somoni",
    currency_symbol: "SM",
    tld: ".tj",
    native: "Тоҷикистон",
    region: "Asia",
    subregion: "Central Asia",
    timezones: [
      {
        zoneName: "Asia/Dushanbe",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "TJT",
        tzName: "Tajikistan Time",
      },
    ],
    translations: {
      kr: "타지키스탄",
      "pt-BR": "Tajiquistão",
      pt: "Tajiquistão",
      nl: "Tadzjikistan",
      hr: "Tađikistan",
      fa: "تاجیکستان",
      de: "Tadschikistan",
      es: "Tayikistán",
      fr: "Tadjikistan",
      ja: "タジキスタン",
      it: "Tagikistan",
      cn: "塔吉克斯坦",
      tr: "Tacikistan",
    },
    latitude: "39.00000000",
    longitude: "71.00000000",
    emoji: "🇹🇯",
    emojiU: "U+1F1F9 U+1F1EF",
  },
  {
    id: 218,
    name: "Tanzania",
    iso3: "TZA",
    iso2: "TZ",
    numeric_code: "834",
    phone_code: "255",
    capital: "Dodoma",
    currency: "TZS",
    currency_name: "Tanzanian shilling",
    currency_symbol: "TSh",
    tld: ".tz",
    native: "Tanzania",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Africa/Dar_es_Salaam",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "탄자니아",
      "pt-BR": "Tanzânia",
      pt: "Tanzânia",
      nl: "Tanzania",
      hr: "Tanzanija",
      fa: "تانزانیا",
      de: "Tansania",
      es: "Tanzania",
      fr: "Tanzanie",
      ja: "タンザニア",
      it: "Tanzania",
      cn: "坦桑尼亚",
      tr: "Tanzanya",
    },
    latitude: "-6.00000000",
    longitude: "35.00000000",
    emoji: "🇹🇿",
    emojiU: "U+1F1F9 U+1F1FF",
  },
  {
    id: 219,
    name: "Thailand",
    iso3: "THA",
    iso2: "TH",
    numeric_code: "764",
    phone_code: "66",
    capital: "Bangkok",
    currency: "THB",
    currency_name: "Thai baht",
    currency_symbol: "฿",
    tld: ".th",
    native: "ประเทศไทย",
    region: "Asia",
    subregion: "South-Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Bangkok",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "ICT",
        tzName: "Indochina Time",
      },
    ],
    translations: {
      kr: "태국",
      "pt-BR": "Tailândia",
      pt: "Tailândia",
      nl: "Thailand",
      hr: "Tajland",
      fa: "تایلند",
      de: "Thailand",
      es: "Tailandia",
      fr: "Thaïlande",
      ja: "タイ",
      it: "Tailandia",
      cn: "泰国",
      tr: "Tayland",
    },
    latitude: "15.00000000",
    longitude: "100.00000000",
    emoji: "🇹🇭",
    emojiU: "U+1F1F9 U+1F1ED",
  },
  {
    id: 17,
    name: "The Bahamas",
    iso3: "BHS",
    iso2: "BS",
    numeric_code: "044",
    phone_code: "+1-242",
    capital: "Nassau",
    currency: "BSD",
    currency_name: "Bahamian dollar",
    currency_symbol: "B$",
    tld: ".bs",
    native: "Bahamas",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Nassau",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America)",
      },
    ],
    translations: {
      kr: "바하마",
      "pt-BR": "Bahamas",
      pt: "Baamas",
      nl: "Bahama’s",
      hr: "Bahami",
      fa: "باهاما",
      de: "Bahamas",
      es: "Bahamas",
      fr: "Bahamas",
      ja: "バハマ",
      it: "Bahamas",
      cn: "巴哈马",
      tr: "Bahamalar",
    },
    latitude: "24.25000000",
    longitude: "-76.00000000",
    emoji: "🇧🇸",
    emojiU: "U+1F1E7 U+1F1F8",
  },
  {
    id: 220,
    name: "Togo",
    iso3: "TGO",
    iso2: "TG",
    numeric_code: "768",
    phone_code: "228",
    capital: "Lome",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".tg",
    native: "Togo",
    region: "Africa",
    subregion: "Western Africa",
    timezones: [
      {
        zoneName: "Africa/Lome",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "토고",
      "pt-BR": "Togo",
      pt: "Togo",
      nl: "Togo",
      hr: "Togo",
      fa: "توگو",
      de: "Togo",
      es: "Togo",
      fr: "Togo",
      ja: "トーゴ",
      it: "Togo",
      cn: "多哥",
      tr: "Togo",
    },
    latitude: "8.00000000",
    longitude: "1.16666666",
    emoji: "🇹🇬",
    emojiU: "U+1F1F9 U+1F1EC",
  },
  {
    id: 221,
    name: "Tokelau",
    iso3: "TKL",
    iso2: "TK",
    numeric_code: "772",
    phone_code: "690",
    capital: "",
    currency: "NZD",
    currency_name: "New Zealand dollar",
    currency_symbol: "$",
    tld: ".tk",
    native: "Tokelau",
    region: "Oceania",
    subregion: "Polynesia",
    timezones: [
      {
        zoneName: "Pacific/Fakaofo",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "TKT",
        tzName: "Tokelau Time",
      },
    ],
    translations: {
      kr: "토켈라우",
      "pt-BR": "Tokelau",
      pt: "Toquelau",
      nl: "Tokelau",
      hr: "Tokelau",
      fa: "توکلائو",
      de: "Tokelau",
      es: "Islas Tokelau",
      fr: "Tokelau",
      ja: "トケラウ",
      it: "Isole Tokelau",
      cn: "托克劳",
      tr: "Tokelau",
    },
    latitude: "-9.00000000",
    longitude: "-172.00000000",
    emoji: "🇹🇰",
    emojiU: "U+1F1F9 U+1F1F0",
  },
  {
    id: 222,
    name: "Tonga",
    iso3: "TON",
    iso2: "TO",
    numeric_code: "776",
    phone_code: "676",
    capital: "Nuku'alofa",
    currency: "TOP",
    currency_name: "Tongan paʻanga",
    currency_symbol: "$",
    tld: ".to",
    native: "Tonga",
    region: "Oceania",
    subregion: "Polynesia",
    timezones: [
      {
        zoneName: "Pacific/Tongatapu",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "TOT",
        tzName: "Tonga Time",
      },
    ],
    translations: {
      kr: "통가",
      "pt-BR": "Tonga",
      pt: "Tonga",
      nl: "Tonga",
      hr: "Tonga",
      fa: "تونگا",
      de: "Tonga",
      es: "Tonga",
      fr: "Tonga",
      ja: "トンガ",
      it: "Tonga",
      cn: "汤加",
      tr: "Tonga",
    },
    latitude: "-20.00000000",
    longitude: "-175.00000000",
    emoji: "🇹🇴",
    emojiU: "U+1F1F9 U+1F1F4",
  },
  {
    id: 223,
    name: "Trinidad And Tobago",
    iso3: "TTO",
    iso2: "TT",
    numeric_code: "780",
    phone_code: "+1-868",
    capital: "Port of Spain",
    currency: "TTD",
    currency_name: "Trinidad and Tobago dollar",
    currency_symbol: "$",
    tld: ".tt",
    native: "Trinidad and Tobago",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Port_of_Spain",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "트리니다드 토바고",
      "pt-BR": "Trinidad e Tobago",
      pt: "Trindade e Tobago",
      nl: "Trinidad en Tobago",
      hr: "Trinidad i Tobago",
      fa: "ترینیداد و توباگو",
      de: "Trinidad und Tobago",
      es: "Trinidad y Tobago",
      fr: "Trinité et Tobago",
      ja: "トリニダード・トバゴ",
      it: "Trinidad e Tobago",
      cn: "特立尼达和多巴哥",
      tr: "Trinidad Ve Tobago",
    },
    latitude: "11.00000000",
    longitude: "-61.00000000",
    emoji: "🇹🇹",
    emojiU: "U+1F1F9 U+1F1F9",
  },
  {
    id: 224,
    name: "Tunisia",
    iso3: "TUN",
    iso2: "TN",
    numeric_code: "788",
    phone_code: "216",
    capital: "Tunis",
    currency: "TND",
    currency_name: "Tunisian dinar",
    currency_symbol: "ت.د",
    tld: ".tn",
    native: "تونس",
    region: "Africa",
    subregion: "Northern Africa",
    timezones: [
      {
        zoneName: "Africa/Tunis",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "튀니지",
      "pt-BR": "Tunísia",
      pt: "Tunísia",
      nl: "Tunesië",
      hr: "Tunis",
      fa: "تونس",
      de: "Tunesien",
      es: "Túnez",
      fr: "Tunisie",
      ja: "チュニジア",
      it: "Tunisia",
      cn: "突尼斯",
      tr: "Tunus",
    },
    latitude: "34.00000000",
    longitude: "9.00000000",
    emoji: "🇹🇳",
    emojiU: "U+1F1F9 U+1F1F3",
  },
  {
    id: 225,
    name: "Turkey",
    iso3: "TUR",
    iso2: "TR",
    numeric_code: "792",
    phone_code: "90",
    capital: "Ankara",
    currency: "TRY",
    currency_name: "Turkish lira",
    currency_symbol: "₺",
    tld: ".tr",
    native: "Türkiye",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Europe/Istanbul",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "터키",
      "pt-BR": "Turquia",
      pt: "Turquia",
      nl: "Turkije",
      hr: "Turska",
      fa: "ترکیه",
      de: "Türkei",
      es: "Turquía",
      fr: "Turquie",
      ja: "トルコ",
      it: "Turchia",
      cn: "土耳其",
      tr: "Türkiye",
    },
    latitude: "39.00000000",
    longitude: "35.00000000",
    emoji: "🇹🇷",
    emojiU: "U+1F1F9 U+1F1F7",
  },
  {
    id: 226,
    name: "Turkmenistan",
    iso3: "TKM",
    iso2: "TM",
    numeric_code: "795",
    phone_code: "993",
    capital: "Ashgabat",
    currency: "TMT",
    currency_name: "Turkmenistan manat",
    currency_symbol: "T",
    tld: ".tm",
    native: "Türkmenistan",
    region: "Asia",
    subregion: "Central Asia",
    timezones: [
      {
        zoneName: "Asia/Ashgabat",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "TMT",
        tzName: "Turkmenistan Time",
      },
    ],
    translations: {
      kr: "투르크메니스탄",
      "pt-BR": "Turcomenistão",
      pt: "Turquemenistão",
      nl: "Turkmenistan",
      hr: "Turkmenistan",
      fa: "ترکمنستان",
      de: "Turkmenistan",
      es: "Turkmenistán",
      fr: "Turkménistan",
      ja: "トルクメニスタン",
      it: "Turkmenistan",
      cn: "土库曼斯坦",
      tr: "Türkmenistan",
    },
    latitude: "40.00000000",
    longitude: "60.00000000",
    emoji: "🇹🇲",
    emojiU: "U+1F1F9 U+1F1F2",
  },
  {
    id: 227,
    name: "Turks And Caicos Islands",
    iso3: "TCA",
    iso2: "TC",
    numeric_code: "796",
    phone_code: "+1-649",
    capital: "Cockburn Town",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".tc",
    native: "Turks and Caicos Islands",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Grand_Turk",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
    ],
    translations: {
      kr: "터크스 케이커스 제도",
      "pt-BR": "Ilhas Turcas e Caicos",
      pt: "Ilhas Turcas e Caicos",
      nl: "Turks- en Caicoseilanden",
      hr: "Otoci Turks i Caicos",
      fa: "جزایر تورکس و کایکوس",
      de: "Turks- und Caicosinseln",
      es: "Islas Turks y Caicos",
      fr: "Îles Turques-et-Caïques",
      ja: "タークス・カイコス諸島",
      it: "Isole Turks e Caicos",
      cn: "特克斯和凯科斯群岛",
      tr: "Turks Ve Caicos Adalari",
    },
    latitude: "21.75000000",
    longitude: "-71.58333333",
    emoji: "🇹🇨",
    emojiU: "U+1F1F9 U+1F1E8",
  },
  {
    id: 228,
    name: "Tuvalu",
    iso3: "TUV",
    iso2: "TV",
    numeric_code: "798",
    phone_code: "688",
    capital: "Funafuti",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".tv",
    native: "Tuvalu",
    region: "Oceania",
    subregion: "Polynesia",
    timezones: [
      {
        zoneName: "Pacific/Funafuti",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "TVT",
        tzName: "Tuvalu Time",
      },
    ],
    translations: {
      kr: "투발루",
      "pt-BR": "Tuvalu",
      pt: "Tuvalu",
      nl: "Tuvalu",
      hr: "Tuvalu",
      fa: "تووالو",
      de: "Tuvalu",
      es: "Tuvalu",
      fr: "Tuvalu",
      ja: "ツバル",
      it: "Tuvalu",
      cn: "图瓦卢",
      tr: "Tuvalu",
    },
    latitude: "-8.00000000",
    longitude: "178.00000000",
    emoji: "🇹🇻",
    emojiU: "U+1F1F9 U+1F1FB",
  },
  {
    id: 229,
    name: "Uganda",
    iso3: "UGA",
    iso2: "UG",
    numeric_code: "800",
    phone_code: "256",
    capital: "Kampala",
    currency: "UGX",
    currency_name: "Ugandan shilling",
    currency_symbol: "USh",
    tld: ".ug",
    native: "Uganda",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Africa/Kampala",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "우간다",
      "pt-BR": "Uganda",
      pt: "Uganda",
      nl: "Oeganda",
      hr: "Uganda",
      fa: "اوگاندا",
      de: "Uganda",
      es: "Uganda",
      fr: "Uganda",
      ja: "ウガンダ",
      it: "Uganda",
      cn: "乌干达",
      tr: "Uganda",
    },
    latitude: "1.00000000",
    longitude: "32.00000000",
    emoji: "🇺🇬",
    emojiU: "U+1F1FA U+1F1EC",
  },
  {
    id: 230,
    name: "Ukraine",
    iso3: "UKR",
    iso2: "UA",
    numeric_code: "804",
    phone_code: "380",
    capital: "Kiev",
    currency: "UAH",
    currency_name: "Ukrainian hryvnia",
    currency_symbol: "₴",
    tld: ".ua",
    native: "Україна",
    region: "Europe",
    subregion: "Eastern Europe",
    timezones: [
      {
        zoneName: "Europe/Kiev",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
      {
        zoneName: "Europe/Simferopol",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "MSK",
        tzName: "Moscow Time",
      },
      {
        zoneName: "Europe/Uzhgorod",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
      {
        zoneName: "Europe/Zaporozhye",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "우크라이나",
      "pt-BR": "Ucrânia",
      pt: "Ucrânia",
      nl: "Oekraïne",
      hr: "Ukrajina",
      fa: "وکراین",
      de: "Ukraine",
      es: "Ucrania",
      fr: "Ukraine",
      ja: "ウクライナ",
      it: "Ucraina",
      cn: "乌克兰",
      tr: "Ukrayna",
    },
    latitude: "49.00000000",
    longitude: "32.00000000",
    emoji: "🇺🇦",
    emojiU: "U+1F1FA U+1F1E6",
  },
  {
    id: 231,
    name: "United Arab Emirates",
    iso3: "ARE",
    iso2: "AE",
    numeric_code: "784",
    phone_code: "971",
    capital: "Abu Dhabi",
    currency: "AED",
    currency_name: "United Arab Emirates dirham",
    currency_symbol: "إ.د",
    tld: ".ae",
    native: "دولة الإمارات العربية المتحدة",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Dubai",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "GST",
        tzName: "Gulf Standard Time",
      },
    ],
    translations: {
      kr: "아랍에미리트",
      "pt-BR": "Emirados árabes Unidos",
      pt: "Emirados árabes Unidos",
      nl: "Verenigde Arabische Emiraten",
      hr: "Ujedinjeni Arapski Emirati",
      fa: "امارات متحده عربی",
      de: "Vereinigte Arabische Emirate",
      es: "Emiratos Árabes Unidos",
      fr: "Émirats arabes unis",
      ja: "アラブ首長国連邦",
      it: "Emirati Arabi Uniti",
      cn: "阿拉伯联合酋长国",
      tr: "Birleşik Arap Emirlikleri",
    },
    latitude: "24.00000000",
    longitude: "54.00000000",
    emoji: "🇦🇪",
    emojiU: "U+1F1E6 U+1F1EA",
  },
  {
    id: 232,
    name: "United Kingdom",
    iso3: "GBR",
    iso2: "GB",
    numeric_code: "826",
    phone_code: "44",
    capital: "London",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "£",
    tld: ".uk",
    native: "United Kingdom",
    region: "Europe",
    subregion: "Northern Europe",
    timezones: [
      {
        zoneName: "Europe/London",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "영국",
      "pt-BR": "Reino Unido",
      pt: "Reino Unido",
      nl: "Verenigd Koninkrijk",
      hr: "Ujedinjeno Kraljevstvo",
      fa: "بریتانیای کبیر و ایرلند شمالی",
      de: "Vereinigtes Königreich",
      es: "Reino Unido",
      fr: "Royaume-Uni",
      ja: "イギリス",
      it: "Regno Unito",
      cn: "英国",
      tr: "Birleşik Krallik",
    },
    latitude: "54.00000000",
    longitude: "-2.00000000",
    emoji: "🇬🇧",
    emojiU: "U+1F1EC U+1F1E7",
  },
  {
    id: 233,
    name: "United States",
    iso3: "USA",
    iso2: "US",
    numeric_code: "840",
    phone_code: "1",
    capital: "Washington",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".us",
    native: "United States",
    region: "Americas",
    subregion: "Northern America",
    timezones: [
      {
        zoneName: "America/Adak",
        gmtOffset: -36000,
        gmtOffsetName: "UTC-10:00",
        abbreviation: "HST",
        tzName: "Hawaii–Aleutian Standard Time",
      },
      {
        zoneName: "America/Anchorage",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time",
      },
      {
        zoneName: "America/Boise",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Chicago",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Denver",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Detroit",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Indianapolis",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Knox",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Marengo",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Petersburg",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Tell_City",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Vevay",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Vincennes",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Winamac",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Juneau",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time",
      },
      {
        zoneName: "America/Kentucky/Louisville",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Kentucky/Monticello",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Los_Angeles",
        gmtOffset: -28800,
        gmtOffsetName: "UTC-08:00",
        abbreviation: "PST",
        tzName: "Pacific Standard Time (North America",
      },
      {
        zoneName: "America/Menominee",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Metlakatla",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time",
      },
      {
        zoneName: "America/New_York",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Nome",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time",
      },
      {
        zoneName: "America/North_Dakota/Beulah",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/North_Dakota/Center",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/North_Dakota/New_Salem",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Phoenix",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Sitka",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time",
      },
      {
        zoneName: "America/Yakutat",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time",
      },
      {
        zoneName: "Pacific/Honolulu",
        gmtOffset: -36000,
        gmtOffsetName: "UTC-10:00",
        abbreviation: "HST",
        tzName: "Hawaii–Aleutian Standard Time",
      },
    ],
    translations: {
      kr: "미국",
      "pt-BR": "Estados Unidos",
      pt: "Estados Unidos",
      nl: "Verenigde Staten",
      hr: "Sjedinjene Američke Države",
      fa: "ایالات متحده آمریکا",
      de: "Vereinigte Staaten von Amerika",
      es: "Estados Unidos",
      fr: "États-Unis",
      ja: "アメリカ合衆国",
      it: "Stati Uniti D'America",
      cn: "美国",
      tr: "Amerika",
    },
    latitude: "38.00000000",
    longitude: "-97.00000000",
    emoji: "🇺🇸",
    emojiU: "U+1F1FA U+1F1F8",
  },
  {
    id: 234,
    name: "United States Minor Outlying Islands",
    iso3: "UMI",
    iso2: "UM",
    numeric_code: "581",
    phone_code: "1",
    capital: "",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".us",
    native: "United States Minor Outlying Islands",
    region: "Americas",
    subregion: "Northern America",
    timezones: [
      {
        zoneName: "Pacific/Midway",
        gmtOffset: -39600,
        gmtOffsetName: "UTC-11:00",
        abbreviation: "SST",
        tzName: "Samoa Standard Time",
      },
      {
        zoneName: "Pacific/Wake",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "WAKT",
        tzName: "Wake Island Time",
      },
    ],
    translations: {
      kr: "미국령 군소 제도",
      "pt-BR": "Ilhas Menores Distantes dos Estados Unidos",
      pt: "Ilhas Menores Distantes dos Estados Unidos",
      nl: "Kleine afgelegen eilanden van de Verenigde Staten",
      hr: "Mali udaljeni otoci SAD-a",
      fa: "جزایر کوچک حاشیه‌ای ایالات متحده آمریکا",
      de: "Kleinere Inselbesitzungen der Vereinigten Staaten",
      es: "Islas Ultramarinas Menores de Estados Unidos",
      fr: "Îles mineures éloignées des États-Unis",
      ja: "合衆国領有小離島",
      it: "Isole minori esterne degli Stati Uniti d'America",
      cn: "美国本土外小岛屿",
      tr: "Abd Küçük Harici Adalari",
    },
    latitude: "0.00000000",
    longitude: "0.00000000",
    emoji: "🇺🇲",
    emojiU: "U+1F1FA U+1F1F2",
  },
  {
    id: 235,
    name: "Uruguay",
    iso3: "URY",
    iso2: "UY",
    numeric_code: "858",
    phone_code: "598",
    capital: "Montevideo",
    currency: "UYU",
    currency_name: "Uruguayan peso",
    currency_symbol: "$",
    tld: ".uy",
    native: "Uruguay",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "America/Montevideo",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "UYT",
        tzName: "Uruguay Standard Time",
      },
    ],
    translations: {
      kr: "우루과이",
      "pt-BR": "Uruguai",
      pt: "Uruguai",
      nl: "Uruguay",
      hr: "Urugvaj",
      fa: "اروگوئه",
      de: "Uruguay",
      es: "Uruguay",
      fr: "Uruguay",
      ja: "ウルグアイ",
      it: "Uruguay",
      cn: "乌拉圭",
      tr: "Uruguay",
    },
    latitude: "-33.00000000",
    longitude: "-56.00000000",
    emoji: "🇺🇾",
    emojiU: "U+1F1FA U+1F1FE",
  },
  {
    id: 236,
    name: "Uzbekistan",
    iso3: "UZB",
    iso2: "UZ",
    numeric_code: "860",
    phone_code: "998",
    capital: "Tashkent",
    currency: "UZS",
    currency_name: "Uzbekistani soʻm",
    currency_symbol: "лв",
    tld: ".uz",
    native: "O‘zbekiston",
    region: "Asia",
    subregion: "Central Asia",
    timezones: [
      {
        zoneName: "Asia/Samarkand",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "UZT",
        tzName: "Uzbekistan Time",
      },
      {
        zoneName: "Asia/Tashkent",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "UZT",
        tzName: "Uzbekistan Time",
      },
    ],
    translations: {
      kr: "우즈베키스탄",
      "pt-BR": "Uzbequistão",
      pt: "Usbequistão",
      nl: "Oezbekistan",
      hr: "Uzbekistan",
      fa: "ازبکستان",
      de: "Usbekistan",
      es: "Uzbekistán",
      fr: "Ouzbékistan",
      ja: "ウズベキスタン",
      it: "Uzbekistan",
      cn: "乌兹别克斯坦",
      tr: "Özbekistan",
    },
    latitude: "41.00000000",
    longitude: "64.00000000",
    emoji: "🇺🇿",
    emojiU: "U+1F1FA U+1F1FF",
  },
  {
    id: 237,
    name: "Vanuatu",
    iso3: "VUT",
    iso2: "VU",
    numeric_code: "548",
    phone_code: "678",
    capital: "Port Vila",
    currency: "VUV",
    currency_name: "Vanuatu vatu",
    currency_symbol: "VT",
    tld: ".vu",
    native: "Vanuatu",
    region: "Oceania",
    subregion: "Melanesia",
    timezones: [
      {
        zoneName: "Pacific/Efate",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "VUT",
        tzName: "Vanuatu Time",
      },
    ],
    translations: {
      kr: "바누아투",
      "pt-BR": "Vanuatu",
      pt: "Vanuatu",
      nl: "Vanuatu",
      hr: "Vanuatu",
      fa: "وانواتو",
      de: "Vanuatu",
      es: "Vanuatu",
      fr: "Vanuatu",
      ja: "バヌアツ",
      it: "Vanuatu",
      cn: "瓦努阿图",
      tr: "Vanuatu",
    },
    latitude: "-16.00000000",
    longitude: "167.00000000",
    emoji: "🇻🇺",
    emojiU: "U+1F1FB U+1F1FA",
  },
  {
    id: 238,
    name: "Vatican City State (Holy See)",
    iso3: "VAT",
    iso2: "VA",
    numeric_code: "336",
    phone_code: "379",
    capital: "Vatican City",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".va",
    native: "Vaticano",
    region: "Europe",
    subregion: "Southern Europe",
    timezones: [
      {
        zoneName: "Europe/Vatican",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "바티칸 시국",
      "pt-BR": "Vaticano",
      pt: "Vaticano",
      nl: "Heilige Stoel",
      hr: "Sveta Stolica",
      fa: "سریر مقدس",
      de: "Heiliger Stuhl",
      es: "Santa Sede",
      fr: "voir Saint",
      ja: "聖座",
      it: "Santa Sede",
      cn: "梵蒂冈",
      tr: "Vatikan",
    },
    latitude: "41.90000000",
    longitude: "12.45000000",
    emoji: "🇻🇦",
    emojiU: "U+1F1FB U+1F1E6",
  },
  {
    id: 239,
    name: "Venezuela",
    iso3: "VEN",
    iso2: "VE",
    numeric_code: "862",
    phone_code: "58",
    capital: "Caracas",
    currency: "VEF",
    currency_name: "Bolívar",
    currency_symbol: "Bs",
    tld: ".ve",
    native: "Venezuela",
    region: "Americas",
    subregion: "South America",
    timezones: [
      {
        zoneName: "America/Caracas",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "VET",
        tzName: "Venezuelan Standard Time",
      },
    ],
    translations: {
      kr: "베네수엘라",
      "pt-BR": "Venezuela",
      pt: "Venezuela",
      nl: "Venezuela",
      hr: "Venezuela",
      fa: "ونزوئلا",
      de: "Venezuela",
      es: "Venezuela",
      fr: "Venezuela",
      ja: "ベネズエラ・ボリバル共和国",
      it: "Venezuela",
      cn: "委内瑞拉",
      tr: "Venezuela",
    },
    latitude: "8.00000000",
    longitude: "-66.00000000",
    emoji: "🇻🇪",
    emojiU: "U+1F1FB U+1F1EA",
  },
  {
    id: 240,
    name: "Vietnam",
    iso3: "VNM",
    iso2: "VN",
    numeric_code: "704",
    phone_code: "84",
    capital: "Hanoi",
    currency: "VND",
    currency_name: "Vietnamese đồng",
    currency_symbol: "₫",
    tld: ".vn",
    native: "Việt Nam",
    region: "Asia",
    subregion: "South-Eastern Asia",
    timezones: [
      {
        zoneName: "Asia/Ho_Chi_Minh",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "ICT",
        tzName: "Indochina Time",
      },
    ],
    translations: {
      kr: "베트남",
      "pt-BR": "Vietnã",
      pt: "Vietname",
      nl: "Vietnam",
      hr: "Vijetnam",
      fa: "ویتنام",
      de: "Vietnam",
      es: "Vietnam",
      fr: "Viêt Nam",
      ja: "ベトナム",
      it: "Vietnam",
      cn: "越南",
      tr: "Vietnam",
    },
    latitude: "16.16666666",
    longitude: "107.83333333",
    emoji: "🇻🇳",
    emojiU: "U+1F1FB U+1F1F3",
  },
  {
    id: 241,
    name: "Virgin Islands (British)",
    iso3: "VGB",
    iso2: "VG",
    numeric_code: "092",
    phone_code: "+1-284",
    capital: "Road Town",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".vg",
    native: "British Virgin Islands",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/Tortola",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "영국령 버진아일랜드",
      "pt-BR": "Ilhas Virgens Britânicas",
      pt: "Ilhas Virgens Britânicas",
      nl: "Britse Maagdeneilanden",
      hr: "Britanski Djevičanski Otoci",
      fa: "جزایر ویرجین بریتانیا",
      de: "Britische Jungferninseln",
      es: "Islas Vírgenes del Reino Unido",
      fr: "Îles Vierges britanniques",
      ja: "イギリス領ヴァージン諸島",
      it: "Isole Vergini Britanniche",
      cn: "圣文森特和格林纳丁斯",
      tr: "Britanya Virjin Adalari",
    },
    latitude: "18.43138300",
    longitude: "-64.62305000",
    emoji: "🇻🇬",
    emojiU: "U+1F1FB U+1F1EC",
  },
  {
    id: 242,
    name: "Virgin Islands (US)",
    iso3: "VIR",
    iso2: "VI",
    numeric_code: "850",
    phone_code: "+1-340",
    capital: "Charlotte Amalie",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".vi",
    native: "United States Virgin Islands",
    region: "Americas",
    subregion: "Caribbean",
    timezones: [
      {
        zoneName: "America/St_Thomas",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "미국령 버진아일랜드",
      "pt-BR": "Ilhas Virgens Americanas",
      pt: "Ilhas Virgens Americanas",
      nl: "Verenigde Staten Maagdeneilanden",
      fa: "جزایر ویرجین آمریکا",
      de: "Amerikanische Jungferninseln",
      es: "Islas Vírgenes de los Estados Unidos",
      fr: "Îles Vierges des États-Unis",
      ja: "アメリカ領ヴァージン諸島",
      it: "Isole Vergini americane",
      cn: "维尔京群岛（美国）",
      tr: "Abd Virjin Adalari",
    },
    latitude: "18.34000000",
    longitude: "-64.93000000",
    emoji: "🇻🇮",
    emojiU: "U+1F1FB U+1F1EE",
  },
  {
    id: 243,
    name: "Wallis And Futuna Islands",
    iso3: "WLF",
    iso2: "WF",
    numeric_code: "876",
    phone_code: "681",
    capital: "Mata Utu",
    currency: "XPF",
    currency_name: "CFP franc",
    currency_symbol: "₣",
    tld: ".wf",
    native: "Wallis et Futuna",
    region: "Oceania",
    subregion: "Polynesia",
    timezones: [
      {
        zoneName: "Pacific/Wallis",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "WFT",
        tzName: "Wallis & Futuna Time",
      },
    ],
    translations: {
      kr: "왈리스 푸투나",
      "pt-BR": "Wallis e Futuna",
      pt: "Wallis e Futuna",
      nl: "Wallis en Futuna",
      hr: "Wallis i Fortuna",
      fa: "والیس و فوتونا",
      de: "Wallis und Futuna",
      es: "Wallis y Futuna",
      fr: "Wallis-et-Futuna",
      ja: "ウォリス・フツナ",
      it: "Wallis e Futuna",
      cn: "瓦利斯群岛和富图纳群岛",
      tr: "Wallis Ve Futuna",
    },
    latitude: "-13.30000000",
    longitude: "-176.20000000",
    emoji: "🇼🇫",
    emojiU: "U+1F1FC U+1F1EB",
  },
  {
    id: 244,
    name: "Western Sahara",
    iso3: "ESH",
    iso2: "EH",
    numeric_code: "732",
    phone_code: "212",
    capital: "El-Aaiun",
    currency: "MAD",
    currency_name: "Moroccan Dirham",
    currency_symbol: "MAD",
    tld: ".eh",
    native: "الصحراء الغربية",
    region: "Africa",
    subregion: "Northern Africa",
    timezones: [
      {
        zoneName: "Africa/El_Aaiun",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WEST",
        tzName: "Western European Summer Time",
      },
    ],
    translations: {
      kr: "서사하라",
      "pt-BR": "Saara Ocidental",
      pt: "Saara Ocidental",
      nl: "Westelijke Sahara",
      hr: "Zapadna Sahara",
      fa: "جمهوری دموکراتیک عربی صحرا",
      de: "Westsahara",
      es: "Sahara Occidental",
      fr: "Sahara Occidental",
      ja: "西サハラ",
      it: "Sahara Occidentale",
      cn: "西撒哈拉",
      tr: "Bati Sahra",
    },
    latitude: "24.50000000",
    longitude: "-13.00000000",
    emoji: "🇪🇭",
    emojiU: "U+1F1EA U+1F1ED",
  },
  {
    id: 245,
    name: "Yemen",
    iso3: "YEM",
    iso2: "YE",
    numeric_code: "887",
    phone_code: "967",
    capital: "Sanaa",
    currency: "YER",
    currency_name: "Yemeni rial",
    currency_symbol: "﷼",
    tld: ".ye",
    native: "اليَمَن",
    region: "Asia",
    subregion: "Western Asia",
    timezones: [
      {
        zoneName: "Asia/Aden",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time",
      },
    ],
    translations: {
      kr: "예멘",
      "pt-BR": "Iêmen",
      pt: "Iémen",
      nl: "Jemen",
      hr: "Jemen",
      fa: "یمن",
      de: "Jemen",
      es: "Yemen",
      fr: "Yémen",
      ja: "イエメン",
      it: "Yemen",
      cn: "也门",
      tr: "Yemen",
    },
    latitude: "15.00000000",
    longitude: "48.00000000",
    emoji: "🇾🇪",
    emojiU: "U+1F1FE U+1F1EA",
  },
  {
    id: 246,
    name: "Zambia",
    iso3: "ZMB",
    iso2: "ZM",
    numeric_code: "894",
    phone_code: "260",
    capital: "Lusaka",
    currency: "ZMW",
    currency_name: "Zambian kwacha",
    currency_symbol: "ZK",
    tld: ".zm",
    native: "Zambia",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Africa/Lusaka",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "잠비아",
      "pt-BR": "Zâmbia",
      pt: "Zâmbia",
      nl: "Zambia",
      hr: "Zambija",
      fa: "زامبیا",
      de: "Sambia",
      es: "Zambia",
      fr: "Zambie",
      ja: "ザンビア",
      it: "Zambia",
      cn: "赞比亚",
      tr: "Zambiya",
    },
    latitude: "-15.00000000",
    longitude: "30.00000000",
    emoji: "🇿🇲",
    emojiU: "U+1F1FF U+1F1F2",
  },
  {
    id: 247,
    name: "Zimbabwe",
    iso3: "ZWE",
    iso2: "ZW",
    numeric_code: "716",
    phone_code: "263",
    capital: "Harare",
    currency: "ZWL",
    currency_name: "Zimbabwe Dollar",
    currency_symbol: "$",
    tld: ".zw",
    native: "Zimbabwe",
    region: "Africa",
    subregion: "Eastern Africa",
    timezones: [
      {
        zoneName: "Africa/Harare",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "짐바브웨",
      "pt-BR": "Zimbabwe",
      pt: "Zimbabué",
      nl: "Zimbabwe",
      hr: "Zimbabve",
      fa: "زیمباوه",
      de: "Simbabwe",
      es: "Zimbabue",
      fr: "Zimbabwe",
      ja: "ジンバブエ",
      it: "Zimbabwe",
      cn: "津巴布韦",
      tr: "Zimbabve",
    },
    latitude: "-20.00000000",
    longitude: "30.00000000",
    emoji: "🇿🇼",
    emojiU: "U+1F1FF U+1F1FC",
  },
];

export const statesData = [
  {
    id: 3901,
    name: "Badakhshan",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "BDS",
    type: null,
    latitude: "36.73477250",
    longitude: "70.81199530",
  },
  {
    id: 3871,
    name: "Badghis",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "BDG",
    type: null,
    latitude: "35.16713390",
    longitude: "63.76953840",
  },
  {
    id: 3875,
    name: "Baghlan",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "BGL",
    type: null,
    latitude: "36.17890260",
    longitude: "68.74530640",
  },
  {
    id: 3884,
    name: "Balkh",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "BAL",
    type: null,
    latitude: "36.75506030",
    longitude: "66.89753720",
  },
  {
    id: 3872,
    name: "Bamyan",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "BAM",
    type: null,
    latitude: "34.81000670",
    longitude: "67.82121040",
  },
  {
    id: 3892,
    name: "Daykundi",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "DAY",
    type: null,
    latitude: "33.66949500",
    longitude: "66.04635340",
  },
  {
    id: 3899,
    name: "Farah",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "FRA",
    type: null,
    latitude: "32.49532800",
    longitude: "62.26266270",
  },
  {
    id: 3889,
    name: "Faryab",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "FYB",
    type: null,
    latitude: "36.07956130",
    longitude: "64.90595500",
  },
  {
    id: 3870,
    name: "Ghazni",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "GHA",
    type: null,
    latitude: "33.54505870",
    longitude: "68.41739720",
  },
  {
    id: 3888,
    name: "Ghōr",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "GHO",
    type: null,
    latitude: "34.09957760",
    longitude: "64.90595500",
  },
  {
    id: 3873,
    name: "Helmand",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "HEL",
    type: null,
    latitude: "39.29893610",
    longitude: "-76.61604720",
  },
  {
    id: 3887,
    name: "Herat",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "HER",
    type: null,
    latitude: "34.35286500",
    longitude: "62.20402870",
  },
  {
    id: 3886,
    name: "Jowzjan",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "JOW",
    type: null,
    latitude: "36.89696920",
    longitude: "65.66585680",
  },
  {
    id: 3902,
    name: "Kabul",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "KAB",
    type: null,
    latitude: "34.55534940",
    longitude: "69.20748600",
  },
  {
    id: 3890,
    name: "Kandahar",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "KAN",
    type: null,
    latitude: "31.62887100",
    longitude: "65.73717490",
  },
  {
    id: 3879,
    name: "Kapisa",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "KAP",
    type: null,
    latitude: "34.98105720",
    longitude: "69.62145620",
  },
  {
    id: 3878,
    name: "Khost",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "KHO",
    type: null,
    latitude: "33.33384720",
    longitude: "69.93716730",
  },
  {
    id: 3876,
    name: "Kunar",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "KNR",
    type: null,
    latitude: "34.84658930",
    longitude: "71.09731700",
  },
  {
    id: 3900,
    name: "Kunduz Province",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "KDZ",
    type: null,
    latitude: "36.72855110",
    longitude: "68.86789820",
  },
  {
    id: 3891,
    name: "Laghman",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "LAG",
    type: null,
    latitude: "34.68976870",
    longitude: "70.14558050",
  },
  {
    id: 3897,
    name: "Logar",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "LOG",
    type: null,
    latitude: "34.01455180",
    longitude: "69.19239160",
  },
  {
    id: 3882,
    name: "Nangarhar",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "NAN",
    type: null,
    latitude: "34.17183130",
    longitude: "70.62167940",
  },
  {
    id: 3896,
    name: "Nimruz",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "NIM",
    type: null,
    latitude: "31.02614880",
    longitude: "62.45041540",
  },
  {
    id: 3880,
    name: "Nuristan",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "NUR",
    type: null,
    latitude: "35.32502230",
    longitude: "70.90712360",
  },
  {
    id: 3894,
    name: "Paktia",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "PIA",
    type: null,
    latitude: "33.70619900",
    longitude: "69.38310790",
  },
  {
    id: 3877,
    name: "Paktika",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "PKA",
    type: null,
    latitude: "32.26453860",
    longitude: "68.52471490",
  },
  {
    id: 3881,
    name: "Panjshir",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "PAN",
    type: null,
    latitude: "38.88023910",
    longitude: "-77.17172380",
  },
  {
    id: 3895,
    name: "Parwan",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "PAR",
    type: null,
    latitude: "34.96309770",
    longitude: "68.81088490",
  },
  {
    id: 3883,
    name: "Samangan",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "SAM",
    type: null,
    latitude: "36.31555060",
    longitude: "67.96428630",
  },
  {
    id: 3885,
    name: "Sar-e Pol",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "SAR",
    type: null,
    latitude: "36.21662800",
    longitude: "65.93336000",
  },
  {
    id: 3893,
    name: "Takhar",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "TAK",
    type: null,
    latitude: "36.66980130",
    longitude: "69.47845410",
  },
  {
    id: 3898,
    name: "Urozgan",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "URU",
    type: null,
    latitude: "32.92712870",
    longitude: "66.14152630",
  },
  {
    id: 3874,
    name: "Zabul",
    country_id: 1,
    country_code: "AF",
    country_name: "Afghanistan",
    state_code: "ZAB",
    type: null,
    latitude: "32.19187820",
    longitude: "67.18944880",
  },
  {
    id: 603,
    name: "Berat County",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "01",
    type: null,
    latitude: "40.69530120",
    longitude: "20.04496620",
  },
  {
    id: 629,
    name: "Berat District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "BR",
    type: null,
    latitude: "40.70863770",
    longitude: "19.94373140",
  },
  {
    id: 607,
    name: "Bulqizë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "BU",
    type: null,
    latitude: "41.49425870",
    longitude: "20.21471570",
  },
  {
    id: 618,
    name: "Delvinë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "DL",
    type: null,
    latitude: "39.94813640",
    longitude: "20.09558910",
  },
  {
    id: 608,
    name: "Devoll District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "DV",
    type: null,
    latitude: "40.64473470",
    longitude: "20.95066360",
  },
  {
    id: 610,
    name: "Dibër County",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "09",
    type: null,
    latitude: "41.58881630",
    longitude: "20.23556470",
  },
  {
    id: 605,
    name: "Dibër District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "DI",
    type: null,
    latitude: "41.58881630",
    longitude: "20.23556470",
  },
  {
    id: 632,
    name: "Durrës County",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "02",
    type: null,
    latitude: "41.50809720",
    longitude: "19.61631850",
  },
  {
    id: 639,
    name: "Durrës District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "DR",
    type: null,
    latitude: "41.37065170",
    longitude: "19.52110630",
  },
  {
    id: 598,
    name: "Elbasan County",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "03",
    type: null,
    latitude: "41.12666720",
    longitude: "20.23556470",
  },
  {
    id: 631,
    name: "Fier County",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "04",
    type: null,
    latitude: "40.91913920",
    longitude: "19.66393090",
  },
  {
    id: 627,
    name: "Fier District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "FR",
    type: null,
    latitude: "40.72750400",
    longitude: "19.56275960",
  },
  {
    id: 604,
    name: "Gjirokastër County",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "05",
    type: null,
    latitude: "40.06728740",
    longitude: "20.10452290",
  },
  {
    id: 621,
    name: "Gjirokastër District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "GJ",
    type: null,
    latitude: "40.06728740",
    longitude: "20.10452290",
  },
  {
    id: 617,
    name: "Gramsh District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "GR",
    type: null,
    latitude: "40.86698730",
    longitude: "20.18493230",
  },
  {
    id: 600,
    name: "Has District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "HA",
    type: null,
    latitude: "42.79013360",
    longitude: "-83.61220120",
  },
  {
    id: 594,
    name: "Kavajë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "KA",
    type: null,
    latitude: "41.18445290",
    longitude: "19.56275960",
  },
  {
    id: 628,
    name: "Kolonjë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "ER",
    type: null,
    latitude: "40.33732620",
    longitude: "20.67946760",
  },
  {
    id: 630,
    name: "Korçë County",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "06",
    type: null,
    latitude: "40.59056700",
    longitude: "20.61689210",
  },
  {
    id: 597,
    name: "Korçë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "KO",
    type: null,
    latitude: "40.59056700",
    longitude: "20.61689210",
  },
  {
    id: 614,
    name: "Krujë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "KR",
    type: null,
    latitude: "41.50947650",
    longitude: "19.77107320",
  },
  {
    id: 612,
    name: "Kuçovë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "KC",
    type: null,
    latitude: "40.78370630",
    longitude: "19.87823480",
  },
  {
    id: 601,
    name: "Kukës County",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "07",
    type: null,
    latitude: "42.08074640",
    longitude: "20.41429230",
  },
  {
    id: 623,
    name: "Kukës District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "KU",
    type: null,
    latitude: "42.08074640",
    longitude: "20.41429230",
  },
  {
    id: 622,
    name: "Kurbin District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "KB",
    type: null,
    latitude: "41.64126440",
    longitude: "19.70559500",
  },
  {
    id: 609,
    name: "Lezhë County",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "08",
    type: null,
    latitude: "41.78137590",
    longitude: "19.80679160",
  },
  {
    id: 595,
    name: "Lezhë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "LE",
    type: null,
    latitude: "41.78607300",
    longitude: "19.64607580",
  },
  {
    id: 596,
    name: "Librazhd District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "LB",
    type: null,
    latitude: "41.18292320",
    longitude: "20.31747690",
  },
  {
    id: 599,
    name: "Lushnjë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "LU",
    type: null,
    latitude: "40.94198300",
    longitude: "19.69964280",
  },
  {
    id: 602,
    name: "Malësi e Madhe District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "MM",
    type: null,
    latitude: "42.42451730",
    longitude: "19.61631850",
  },
  {
    id: 637,
    name: "Mallakastër District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "MK",
    type: null,
    latitude: "40.52733760",
    longitude: "19.78297910",
  },
  {
    id: 635,
    name: "Mat District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "MT",
    type: null,
    latitude: "41.59376750",
    longitude: "19.99732440",
  },
  {
    id: 638,
    name: "Mirditë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "MR",
    type: null,
    latitude: "41.76428600",
    longitude: "19.90205090",
  },
  {
    id: 619,
    name: "Peqin District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "PQ",
    type: null,
    latitude: "41.04709020",
    longitude: "19.75023840",
  },
  {
    id: 625,
    name: "Përmet District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "PR",
    type: null,
    latitude: "40.23618370",
    longitude: "20.35173340",
  },
  {
    id: 606,
    name: "Pogradec District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "PG",
    type: null,
    latitude: "40.90153140",
    longitude: "20.65562890",
  },
  {
    id: 620,
    name: "Pukë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "PU",
    type: null,
    latitude: "42.04697720",
    longitude: "19.89609680",
  },
  {
    id: 624,
    name: "Sarandë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "SR",
    type: null,
    latitude: "39.85921190",
    longitude: "20.02710010",
  },
  {
    id: 611,
    name: "Shkodër County",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "10",
    type: null,
    latitude: "42.15037100",
    longitude: "19.66393090",
  },
  {
    id: 626,
    name: "Shkodër District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "SH",
    type: null,
    latitude: "42.06929850",
    longitude: "19.50325590",
  },
  {
    id: 593,
    name: "Skrapar District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "SK",
    type: null,
    latitude: "40.53499460",
    longitude: "20.28322170",
  },
  {
    id: 616,
    name: "Tepelenë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "TE",
    type: null,
    latitude: "40.29666320",
    longitude: "20.01816730",
  },
  {
    id: 615,
    name: "Tirana County",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "11",
    type: null,
    latitude: "41.24275980",
    longitude: "19.80679160",
  },
  {
    id: 633,
    name: "Tirana District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "TR",
    type: null,
    latitude: "41.32754590",
    longitude: "19.81869820",
  },
  {
    id: 636,
    name: "Tropojë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "TP",
    type: null,
    latitude: "42.39821510",
    longitude: "20.16259550",
  },
  {
    id: 634,
    name: "Vlorë County",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "12",
    type: null,
    latitude: "40.15009600",
    longitude: "19.80679160",
  },
  {
    id: 613,
    name: "Vlorë District",
    country_id: 3,
    country_code: "AL",
    country_name: "Albania",
    state_code: "VL",
    type: null,
    latitude: "40.46606680",
    longitude: "19.49135600",
  },
  {
    id: 1118,
    name: "Adrar",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "01",
    type: null,
    latitude: "26.41813100",
    longitude: "-0.60147170",
  },
  {
    id: 1119,
    name: "Aïn Defla",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "44",
    type: null,
    latitude: "36.25094290",
    longitude: "1.93938150",
  },
  {
    id: 1122,
    name: "Aïn Témouchent",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "46",
    type: null,
    latitude: "35.29926980",
    longitude: "-1.13927920",
  },
  {
    id: 1144,
    name: "Algiers",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "16",
    type: null,
    latitude: "36.69972940",
    longitude: "3.05761990",
  },
  {
    id: 1103,
    name: "Annaba",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "23",
    type: null,
    latitude: "36.80205080",
    longitude: "7.52472430",
  },
  {
    id: 1142,
    name: "Batna",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "05",
    type: null,
    latitude: "35.59659540",
    longitude: "5.89871390",
  },
  {
    id: 1108,
    name: "Béchar",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "08",
    type: null,
    latitude: "31.62380980",
    longitude: "-2.21624430",
  },
  {
    id: 1128,
    name: "Béjaïa",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "06",
    type: null,
    latitude: "36.75152580",
    longitude: "5.05568370",
  },
  {
    id: 4909,
    name: "Béni Abbès",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "53",
    type: null,
    latitude: "30.08310420",
    longitude: "-2.83450520",
  },
  {
    id: 1114,
    name: "Biskra",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "07",
    type: null,
    latitude: "34.84494370",
    longitude: "5.72485670",
  },
  {
    id: 1111,
    name: "Blida",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "09",
    type: null,
    latitude: "36.53112300",
    longitude: "2.89762540",
  },
  {
    id: 4908,
    name: "Bordj Baji Mokhtar",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "52",
    type: null,
    latitude: "22.96633500",
    longitude: "-3.94727320",
  },
  {
    id: 1116,
    name: "Bordj Bou Arréridj",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "34",
    type: null,
    latitude: "36.07399250",
    longitude: "4.76302710",
  },
  {
    id: 1104,
    name: "Bouïra",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "10",
    type: null,
    latitude: "36.36918460",
    longitude: "3.90061940",
  },
  {
    id: 1125,
    name: "Boumerdès",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "35",
    type: null,
    latitude: "36.68395590",
    longitude: "3.62178020",
  },
  {
    id: 1105,
    name: "Chlef",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "02",
    type: null,
    latitude: "36.16935150",
    longitude: "1.28910360",
  },
  {
    id: 1121,
    name: "Constantine",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "25",
    type: null,
    latitude: "36.33739110",
    longitude: "6.66381200",
  },
  {
    id: 4912,
    name: "Djanet",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "56",
    type: null,
    latitude: "23.83108720",
    longitude: "8.70046720",
  },
  {
    id: 1098,
    name: "Djelfa",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "17",
    type: null,
    latitude: "34.67039560",
    longitude: "3.25037610",
  },
  {
    id: 1129,
    name: "El Bayadh",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "32",
    type: null,
    latitude: "32.71488240",
    longitude: "0.90566230",
  },
  {
    id: 4905,
    name: "El M'ghair",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "49",
    type: null,
    latitude: "33.95405610",
    longitude: "5.13464180",
  },
  {
    id: 4906,
    name: "El Menia",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "50",
    type: null,
    latitude: "31.36422500",
    longitude: "2.57844950",
  },
  {
    id: 1099,
    name: "El Oued",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "39",
    type: null,
    latitude: "33.36781100",
    longitude: "6.85165110",
  },
  {
    id: 1100,
    name: "El Tarf",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "36",
    type: null,
    latitude: "36.75766780",
    longitude: "8.30763430",
  },
  {
    id: 1127,
    name: "Ghardaïa",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "47",
    type: null,
    latitude: "32.49437410",
    longitude: "3.64446000",
  },
  {
    id: 1137,
    name: "Guelma",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "24",
    type: null,
    latitude: "36.46274440",
    longitude: "7.43308330",
  },
  {
    id: 1112,
    name: "Illizi",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "33",
    type: null,
    latitude: "26.16900050",
    longitude: "8.48424650",
  },
  {
    id: 4914,
    name: "In Guezzam",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "58",
    type: null,
    latitude: "20.38643230",
    longitude: "4.77893940",
  },
  {
    id: 4913,
    name: "In Salah",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "57",
    type: null,
    latitude: "27.21492290",
    longitude: "1.84843960",
  },
  {
    id: 1113,
    name: "Jijel",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "18",
    type: null,
    latitude: "36.71796810",
    longitude: "5.98325770",
  },
  {
    id: 1126,
    name: "Khenchela",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "40",
    type: null,
    latitude: "35.42694040",
    longitude: "7.14601550",
  },
  {
    id: 1138,
    name: "Laghouat",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "03",
    type: null,
    latitude: "33.80783410",
    longitude: "2.86282940",
  },
  {
    id: 1134,
    name: "M'Sila",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "28",
    type: null,
    latitude: "35.71866460",
    longitude: "4.52334230",
  },
  {
    id: 1124,
    name: "Mascara",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "29",
    type: null,
    latitude: "35.39041250",
    longitude: "0.14949880",
  },
  {
    id: 1109,
    name: "Médéa",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "26",
    type: null,
    latitude: "36.26370780",
    longitude: "2.75878570",
  },
  {
    id: 1132,
    name: "Mila",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "43",
    type: null,
    latitude: "36.36479570",
    longitude: "6.15269850",
  },
  {
    id: 1140,
    name: "Mostaganem",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "27",
    type: null,
    latitude: "35.95830540",
    longitude: "0.33718890",
  },
  {
    id: 1102,
    name: "Naama",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "45",
    type: null,
    latitude: "33.26673170",
    longitude: "-0.31286590",
  },
  {
    id: 1101,
    name: "Oran",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "31",
    type: null,
    latitude: "35.60823510",
    longitude: "-0.56360900",
  },
  {
    id: 1139,
    name: "Ouargla",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "30",
    type: null,
    latitude: "32.22648630",
    longitude: "5.72998210",
  },
  {
    id: 4907,
    name: "Ouled Djellal",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "51",
    type: null,
    latitude: "34.41782210",
    longitude: "4.96858430",
  },
  {
    id: 1136,
    name: "Oum El Bouaghi",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "04",
    type: null,
    latitude: "35.86887890",
    longitude: "7.11082660",
  },
  {
    id: 1130,
    name: "Relizane",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "48",
    type: null,
    latitude: "35.73834050",
    longitude: "0.75328090",
  },
  {
    id: 1123,
    name: "Saïda",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "20",
    type: null,
    latitude: "34.84152070",
    longitude: "0.14560550",
  },
  {
    id: 1141,
    name: "Sétif",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "19",
    type: null,
    latitude: "36.30733890",
    longitude: "5.56172790",
  },
  {
    id: 4902,
    name: "Sidi Bel Abbès",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "22",
    type: null,
    latitude: "34.68060240",
    longitude: "-1.09994950",
  },
  {
    id: 1110,
    name: "Skikda",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "21",
    type: null,
    latitude: "36.67211980",
    longitude: "6.83509990",
  },
  {
    id: 1143,
    name: "Souk Ahras",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "41",
    type: null,
    latitude: "36.28010620",
    longitude: "7.93840330",
  },
  {
    id: 1135,
    name: "Tamanghasset",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "11",
    type: null,
    latitude: "22.79029720",
    longitude: "5.51932680",
  },
  {
    id: 1117,
    name: "Tébessa",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "12",
    type: null,
    latitude: "35.12906910",
    longitude: "7.95928630",
  },
  {
    id: 1106,
    name: "Tiaret",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "14",
    type: null,
    latitude: "35.37086890",
    longitude: "1.32178520",
  },
  {
    id: 4910,
    name: "Timimoun",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "54",
    type: null,
    latitude: "29.67890600",
    longitude: "0.50046080",
  },
  {
    id: 1120,
    name: "Tindouf",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "37",
    type: null,
    latitude: "27.80631190",
    longitude: "-5.72998210",
  },
  {
    id: 1115,
    name: "Tipasa",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "42",
    type: null,
    latitude: "36.54626500",
    longitude: "2.18432850",
  },
  {
    id: 1133,
    name: "Tissemsilt",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "38",
    type: null,
    latitude: "35.60537810",
    longitude: "1.81309800",
  },
  {
    id: 1131,
    name: "Tizi Ouzou",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "15",
    type: null,
    latitude: "36.70691100",
    longitude: "4.23333550",
  },
  {
    id: 1107,
    name: "Tlemcen",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "13",
    type: null,
    latitude: "34.67802840",
    longitude: "-1.36621600",
  },
  {
    id: 4911,
    name: "Touggourt",
    country_id: 4,
    country_code: "DZ",
    country_name: "Algeria",
    state_code: "55",
    type: null,
    latitude: "33.12484760",
    longitude: "5.78327150",
  },
  {
    id: 488,
    name: "Andorra la Vella",
    country_id: 6,
    country_code: "AD",
    country_name: "Andorra",
    state_code: "07",
    type: null,
    latitude: "42.50631740",
    longitude: "1.52183550",
  },
  {
    id: 489,
    name: "Canillo",
    country_id: 6,
    country_code: "AD",
    country_name: "Andorra",
    state_code: "02",
    type: null,
    latitude: "42.59782490",
    longitude: "1.65663770",
  },
  {
    id: 487,
    name: "Encamp",
    country_id: 6,
    country_code: "AD",
    country_name: "Andorra",
    state_code: "03",
    type: null,
    latitude: "42.53597640",
    longitude: "1.58367730",
  },
  {
    id: 492,
    name: "Escaldes-Engordany",
    country_id: 6,
    country_code: "AD",
    country_name: "Andorra",
    state_code: "08",
    type: null,
    latitude: "42.49093790",
    longitude: "1.58869660",
  },
  {
    id: 493,
    name: "La Massana",
    country_id: 6,
    country_code: "AD",
    country_name: "Andorra",
    state_code: "04",
    type: null,
    latitude: "42.54562500",
    longitude: "1.51473920",
  },
  {
    id: 491,
    name: "Ordino",
    country_id: 6,
    country_code: "AD",
    country_name: "Andorra",
    state_code: "05",
    type: null,
    latitude: "42.59944330",
    longitude: "1.54023270",
  },
  {
    id: 490,
    name: "Sant Julià de Lòria",
    country_id: 6,
    country_code: "AD",
    country_name: "Andorra",
    state_code: "06",
    type: null,
    latitude: "42.45296310",
    longitude: "1.49182350",
  },
  {
    id: 221,
    name: "Bengo Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "BGO",
    type: null,
    latitude: "-9.10422570",
    longitude: "13.72891670",
  },
  {
    id: 218,
    name: "Benguela Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "BGU",
    type: null,
    latitude: "-12.80037440",
    longitude: "13.91439900",
  },
  {
    id: 212,
    name: "Bié Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "BIE",
    type: null,
    latitude: "-12.57279070",
    longitude: "17.66888700",
  },
  {
    id: 228,
    name: "Cabinda Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "CAB",
    type: null,
    latitude: "-5.02487490",
    longitude: "12.34638750",
  },
  {
    id: 226,
    name: "Cuando Cubango Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "CCU",
    type: null,
    latitude: "-16.41808240",
    longitude: "18.80761950",
  },
  {
    id: 217,
    name: "Cuanza Norte Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "CNO",
    type: null,
    latitude: "-9.23985130",
    longitude: "14.65878210",
  },
  {
    id: 216,
    name: "Cuanza Sul",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "CUS",
    type: null,
    latitude: "-10.59519100",
    longitude: "15.40680790",
  },
  {
    id: 215,
    name: "Cunene Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "CNN",
    type: null,
    latitude: "-16.28022210",
    longitude: "16.15809370",
  },
  {
    id: 213,
    name: "Huambo Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "HUA",
    type: null,
    latitude: "-12.52682210",
    longitude: "15.59433880",
  },
  {
    id: 225,
    name: "Huíla Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "HUI",
    type: null,
    latitude: "-14.92805530",
    longitude: "14.65878210",
  },
  {
    id: 222,
    name: "Luanda Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "LUA",
    type: null,
    latitude: "-9.03508800",
    longitude: "13.26634790",
  },
  {
    id: 223,
    name: "Lunda Norte Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "LNO",
    type: null,
    latitude: "-8.35250220",
    longitude: "19.18800470",
  },
  {
    id: 220,
    name: "Lunda Sul Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "LSU",
    type: null,
    latitude: "-10.28665780",
    longitude: "20.71224650",
  },
  {
    id: 227,
    name: "Malanje Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "MAL",
    type: null,
    latitude: "-9.82511830",
    longitude: "16.91225100",
  },
  {
    id: 219,
    name: "Moxico Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "MOX",
    type: null,
    latitude: "-13.42935790",
    longitude: "20.33088140",
  },
  {
    id: 224,
    name: "Uíge Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "UIG",
    type: null,
    latitude: "-7.17367320",
    longitude: "15.40680790",
  },
  {
    id: 214,
    name: "Zaire Province",
    country_id: 7,
    country_code: "AO",
    country_name: "Angola",
    state_code: "ZAI",
    type: null,
    latitude: "-6.57334580",
    longitude: "13.17403480",
  },
  {
    id: 3708,
    name: "Barbuda",
    country_id: 10,
    country_code: "AG",
    country_name: "Antigua And Barbuda",
    state_code: "10",
    type: null,
    latitude: "17.62662420",
    longitude: "-61.77130280",
  },
  {
    id: 3703,
    name: "Redonda",
    country_id: 10,
    country_code: "AG",
    country_name: "Antigua And Barbuda",
    state_code: "11",
    type: null,
    latitude: "16.93841600",
    longitude: "-62.34551480",
  },
  {
    id: 3709,
    name: "Saint George Parish",
    country_id: 10,
    country_code: "AG",
    country_name: "Antigua And Barbuda",
    state_code: "03",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3706,
    name: "Saint John Parish",
    country_id: 10,
    country_code: "AG",
    country_name: "Antigua And Barbuda",
    state_code: "04",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3707,
    name: "Saint Mary Parish",
    country_id: 10,
    country_code: "AG",
    country_name: "Antigua And Barbuda",
    state_code: "05",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3705,
    name: "Saint Paul Parish",
    country_id: 10,
    country_code: "AG",
    country_name: "Antigua And Barbuda",
    state_code: "06",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3704,
    name: "Saint Peter Parish",
    country_id: 10,
    country_code: "AG",
    country_name: "Antigua And Barbuda",
    state_code: "07",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3710,
    name: "Saint Philip Parish",
    country_id: 10,
    country_code: "AG",
    country_name: "Antigua And Barbuda",
    state_code: "08",
    type: null,
    latitude: "40.43682580",
    longitude: "-80.06855320",
  },
  {
    id: 3656,
    name: "Buenos Aires",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "B",
    type: "province",
    latitude: "-37.20172850",
    longitude: "-59.84106970",
  },
  {
    id: 3647,
    name: "Catamarca",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "K",
    type: "province",
    latitude: "-28.47158770",
    longitude: "-65.78772090",
  },
  {
    id: 3640,
    name: "Chaco",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "H",
    type: "province",
    latitude: "-27.42571750",
    longitude: "-59.02437840",
  },
  {
    id: 3651,
    name: "Chubut",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "U",
    type: "province",
    latitude: "-43.29342460",
    longitude: "-65.11148180",
  },
  {
    id: 4880,
    name: "Ciudad Autónoma de Buenos Aires",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "C",
    type: "city",
    latitude: "-34.60368440",
    longitude: "-58.38155910",
  },
  {
    id: 3642,
    name: "Córdoba",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "X",
    type: "province",
    latitude: "-31.39928760",
    longitude: "-64.26438420",
  },
  {
    id: 3638,
    name: "Corrientes",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "W",
    type: "province",
    latitude: "-27.46921310",
    longitude: "-58.83063490",
  },
  {
    id: 3654,
    name: "Entre Ríos",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "E",
    type: "province",
    latitude: "-31.77466540",
    longitude: "-60.49564610",
  },
  {
    id: 3652,
    name: "Formosa",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "P",
    type: "province",
    latitude: "-26.18948040",
    longitude: "-58.22428060",
  },
  {
    id: 3645,
    name: "Jujuy",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "Y",
    type: "province",
    latitude: "-24.18433970",
    longitude: "-65.30217700",
  },
  {
    id: 3655,
    name: "La Pampa",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "L",
    type: "province",
    latitude: "-36.61475730",
    longitude: "-64.28392090",
  },
  {
    id: 3653,
    name: "La Rioja",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "F",
    type: "province",
    latitude: "-29.41937930",
    longitude: "-66.85599320",
  },
  {
    id: 3646,
    name: "Mendoza",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "M",
    type: "province",
    latitude: "-32.88945870",
    longitude: "-68.84583860",
  },
  {
    id: 3644,
    name: "Misiones",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "N",
    type: "province",
    latitude: "-27.42692550",
    longitude: "-55.94670760",
  },
  {
    id: 3648,
    name: "Neuquén",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "Q",
    type: "province",
    latitude: "-38.94587000",
    longitude: "-68.07309250",
  },
  {
    id: 3639,
    name: "Río Negro",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "R",
    type: "province",
    latitude: "-40.82614340",
    longitude: "-63.02663390",
  },
  {
    id: 3643,
    name: "Salta",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "A",
    type: "province",
    latitude: "-24.79976880",
    longitude: "-65.41503670",
  },
  {
    id: 3634,
    name: "San Juan",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "J",
    type: "province",
    latitude: "-31.53169760",
    longitude: "-68.56769620",
  },
  {
    id: 3636,
    name: "San Luis",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "D",
    type: "province",
    latitude: "-33.29620420",
    longitude: "-66.32949480",
  },
  {
    id: 3649,
    name: "Santa Cruz",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "Z",
    type: "province",
    latitude: "-51.63528210",
    longitude: "-69.24743530",
  },
  {
    id: 3641,
    name: "Santa Fe",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "S",
    type: "province",
    latitude: "-31.58551090",
    longitude: "-60.72380160",
  },
  {
    id: 3635,
    name: "Santiago del Estero",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "G",
    type: "province",
    latitude: "-27.78335740",
    longitude: "-64.26416700",
  },
  {
    id: 3650,
    name: "Tierra del Fuego",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "V",
    type: "province",
    latitude: "-54.80539980",
    longitude: "-68.32420610",
  },
  {
    id: 3637,
    name: "Tucumán",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    state_code: "T",
    type: "province",
    latitude: "-26.82211270",
    longitude: "-65.21929030",
  },
  {
    id: 2023,
    name: "Aragatsotn Region",
    country_id: 12,
    country_code: "AM",
    country_name: "Armenia",
    state_code: "AG",
    type: null,
    latitude: "40.33473010",
    longitude: "44.37482960",
  },
  {
    id: 2024,
    name: "Ararat Province",
    country_id: 12,
    country_code: "AM",
    country_name: "Armenia",
    state_code: "AR",
    type: null,
    latitude: "39.91394150",
    longitude: "44.72000040",
  },
  {
    id: 2026,
    name: "Armavir Region",
    country_id: 12,
    country_code: "AM",
    country_name: "Armenia",
    state_code: "AV",
    type: null,
    latitude: "40.15546310",
    longitude: "44.03724460",
  },
  {
    id: 2028,
    name: "Gegharkunik Province",
    country_id: 12,
    country_code: "AM",
    country_name: "Armenia",
    state_code: "GR",
    type: null,
    latitude: "40.35264260",
    longitude: "45.12604140",
  },
  {
    id: 2033,
    name: "Kotayk Region",
    country_id: 12,
    country_code: "AM",
    country_name: "Armenia",
    state_code: "KT",
    type: null,
    latitude: "40.54102140",
    longitude: "44.76901480",
  },
  {
    id: 2029,
    name: "Lori Region",
    country_id: 12,
    country_code: "AM",
    country_name: "Armenia",
    state_code: "LO",
    type: null,
    latitude: "40.96984520",
    longitude: "44.49001380",
  },
  {
    id: 2031,
    name: "Shirak Region",
    country_id: 12,
    country_code: "AM",
    country_name: "Armenia",
    state_code: "SH",
    type: null,
    latitude: "40.96308140",
    longitude: "43.81024610",
  },
  {
    id: 2027,
    name: "Syunik Province",
    country_id: 12,
    country_code: "AM",
    country_name: "Armenia",
    state_code: "SU",
    type: null,
    latitude: "39.51331120",
    longitude: "46.33932340",
  },
  {
    id: 2032,
    name: "Tavush Region",
    country_id: 12,
    country_code: "AM",
    country_name: "Armenia",
    state_code: "TV",
    type: null,
    latitude: "40.88662960",
    longitude: "45.33934900",
  },
  {
    id: 2025,
    name: "Vayots Dzor Region",
    country_id: 12,
    country_code: "AM",
    country_name: "Armenia",
    state_code: "VD",
    type: null,
    latitude: "39.76419960",
    longitude: "45.33375280",
  },
  {
    id: 2030,
    name: "Yerevan",
    country_id: 12,
    country_code: "AM",
    country_name: "Armenia",
    state_code: "ER",
    type: null,
    latitude: "40.18720230",
    longitude: "44.51520900",
  },
  {
    id: 3907,
    name: "Australian Capital Territory",
    country_id: 14,
    country_code: "AU",
    country_name: "Australia",
    state_code: "ACT",
    type: "territory",
    latitude: "-35.47346790",
    longitude: "149.01236790",
  },
  {
    id: 3909,
    name: "New South Wales",
    country_id: 14,
    country_code: "AU",
    country_name: "Australia",
    state_code: "NSW",
    type: "state",
    latitude: "-31.25321830",
    longitude: "146.92109900",
  },
  {
    id: 3910,
    name: "Northern Territory",
    country_id: 14,
    country_code: "AU",
    country_name: "Australia",
    state_code: "NT",
    type: "territory",
    latitude: "-19.49141080",
    longitude: "132.55096030",
  },
  {
    id: 3905,
    name: "Queensland",
    country_id: 14,
    country_code: "AU",
    country_name: "Australia",
    state_code: "QLD",
    type: "state",
    latitude: "-20.91757380",
    longitude: "142.70279560",
  },
  {
    id: 3904,
    name: "South Australia",
    country_id: 14,
    country_code: "AU",
    country_name: "Australia",
    state_code: "SA",
    type: "state",
    latitude: "-30.00023150",
    longitude: "136.20915470",
  },
  {
    id: 3908,
    name: "Tasmania",
    country_id: 14,
    country_code: "AU",
    country_name: "Australia",
    state_code: "TAS",
    type: "state",
    latitude: "-41.45451960",
    longitude: "145.97066470",
  },
  {
    id: 3903,
    name: "Victoria",
    country_id: 14,
    country_code: "AU",
    country_name: "Australia",
    state_code: "VIC",
    type: "state",
    latitude: "-36.48564230",
    longitude: "140.97794250",
  },
  {
    id: 3906,
    name: "Western Australia",
    country_id: 14,
    country_code: "AU",
    country_name: "Australia",
    state_code: "WA",
    type: "state",
    latitude: "-27.67281680",
    longitude: "121.62830980",
  },
  {
    id: 2062,
    name: "Burgenland",
    country_id: 15,
    country_code: "AT",
    country_name: "Austria",
    state_code: "1",
    type: null,
    latitude: "47.15371650",
    longitude: "16.26887970",
  },
  {
    id: 2057,
    name: "Carinthia",
    country_id: 15,
    country_code: "AT",
    country_name: "Austria",
    state_code: "2",
    type: null,
    latitude: "46.72220300",
    longitude: "14.18058820",
  },
  {
    id: 2065,
    name: "Lower Austria",
    country_id: 15,
    country_code: "AT",
    country_name: "Austria",
    state_code: "3",
    type: null,
    latitude: "48.10807700",
    longitude: "15.80495580",
  },
  {
    id: 2061,
    name: "Salzburg",
    country_id: 15,
    country_code: "AT",
    country_name: "Austria",
    state_code: "5",
    type: null,
    latitude: "47.80949000",
    longitude: "13.05501000",
  },
  {
    id: 2059,
    name: "Styria",
    country_id: 15,
    country_code: "AT",
    country_name: "Austria",
    state_code: "6",
    type: null,
    latitude: "47.35934420",
    longitude: "14.46998270",
  },
  {
    id: 2064,
    name: "Tyrol",
    country_id: 15,
    country_code: "AT",
    country_name: "Austria",
    state_code: "7",
    type: null,
    latitude: "47.25374140",
    longitude: "11.60148700",
  },
  {
    id: 2058,
    name: "Upper Austria",
    country_id: 15,
    country_code: "AT",
    country_name: "Austria",
    state_code: "4",
    type: null,
    latitude: "48.02585400",
    longitude: "13.97236650",
  },
  {
    id: 2060,
    name: "Vienna",
    country_id: 15,
    country_code: "AT",
    country_name: "Austria",
    state_code: "9",
    type: null,
    latitude: "48.20817430",
    longitude: "16.37381890",
  },
  {
    id: 2063,
    name: "Vorarlberg",
    country_id: 15,
    country_code: "AT",
    country_name: "Austria",
    state_code: "8",
    type: null,
    latitude: "47.24974270",
    longitude: "9.97973730",
  },
  {
    id: 540,
    name: "Absheron District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "ABS",
    type: null,
    latitude: "40.36296930",
    longitude: "49.27368150",
  },
  {
    id: 559,
    name: "Agdam District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "AGM",
    type: null,
    latitude: "39.99318530",
    longitude: "46.99495620",
  },
  {
    id: 553,
    name: "Agdash District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "AGS",
    type: null,
    latitude: "40.63354270",
    longitude: "47.46743100",
  },
  {
    id: 577,
    name: "Aghjabadi District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "AGC",
    type: null,
    latitude: "28.78918410",
    longitude: "77.51607880",
  },
  {
    id: 543,
    name: "Agstafa District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "AGA",
    type: null,
    latitude: "41.26559330",
    longitude: "45.51342910",
  },
  {
    id: 547,
    name: "Agsu District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "AGU",
    type: null,
    latitude: "40.52833390",
    longitude: "48.36508350",
  },
  {
    id: 528,
    name: "Astara District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "AST",
    type: null,
    latitude: "38.49378450",
    longitude: "48.69443650",
  },
  {
    id: 575,
    name: "Babek District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "BAB",
    type: null,
    latitude: "39.15076130",
    longitude: "45.44853680",
  },
  {
    id: 552,
    name: "Baku",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "BA",
    type: null,
    latitude: "40.40926170",
    longitude: "49.86709240",
  },
  {
    id: 560,
    name: "Balakan District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "BAL",
    type: null,
    latitude: "41.70375090",
    longitude: "46.40442130",
  },
  {
    id: 569,
    name: "Barda District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "BAR",
    type: null,
    latitude: "40.37065550",
    longitude: "47.13789090",
  },
  {
    id: 554,
    name: "Beylagan District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "BEY",
    type: null,
    latitude: "39.77230730",
    longitude: "47.61541660",
  },
  {
    id: 532,
    name: "Bilasuvar District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "BIL",
    type: null,
    latitude: "39.45988330",
    longitude: "48.55098130",
  },
  {
    id: 561,
    name: "Dashkasan District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "DAS",
    type: null,
    latitude: "40.52022570",
    longitude: "46.07793040",
  },
  {
    id: 527,
    name: "Fizuli District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "FUZ",
    type: null,
    latitude: "39.53786050",
    longitude: "47.30338770",
  },
  {
    id: 585,
    name: "Ganja",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "GA",
    type: null,
    latitude: "36.36873380",
    longitude: "-95.99857670",
  },
  {
    id: 589,
    name: "Gədəbəy",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "GAD",
    type: null,
    latitude: "40.56996390",
    longitude: "45.81068830",
  },
  {
    id: 573,
    name: "Gobustan District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "QOB",
    type: null,
    latitude: "40.53261040",
    longitude: "48.92737500",
  },
  {
    id: 551,
    name: "Goranboy District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "GOR",
    type: null,
    latitude: "40.53805060",
    longitude: "46.59908910",
  },
  {
    id: 531,
    name: "Goychay",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "GOY",
    type: null,
    latitude: "40.62361680",
    longitude: "47.74030340",
  },
  {
    id: 574,
    name: "Goygol District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "GYG",
    type: null,
    latitude: "40.55953780",
    longitude: "46.33149530",
  },
  {
    id: 571,
    name: "Hajigabul District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "HAC",
    type: null,
    latitude: "40.03937700",
    longitude: "48.92025330",
  },
  {
    id: 544,
    name: "Imishli District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "IMI",
    type: null,
    latitude: "39.86946860",
    longitude: "48.06652180",
  },
  {
    id: 564,
    name: "Ismailli District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "ISM",
    type: null,
    latitude: "40.74299360",
    longitude: "48.21255560",
  },
  {
    id: 570,
    name: "Jabrayil District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "CAB",
    type: null,
    latitude: "39.26455440",
    longitude: "46.96215620",
  },
  {
    id: 578,
    name: "Jalilabad District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "CAL",
    type: null,
    latitude: "39.20516320",
    longitude: "48.51006040",
  },
  {
    id: 572,
    name: "Julfa District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "CUL",
    type: null,
    latitude: "38.96049830",
    longitude: "45.62929390",
  },
  {
    id: 525,
    name: "Kalbajar District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "KAL",
    type: null,
    latitude: "40.10243290",
    longitude: "46.03648720",
  },
  {
    id: 567,
    name: "Kangarli District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "KAN",
    type: null,
    latitude: "39.38719400",
    longitude: "45.16398520",
  },
  {
    id: 590,
    name: "Khachmaz District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "XAC",
    type: null,
    latitude: "41.45911680",
    longitude: "48.80206260",
  },
  {
    id: 537,
    name: "Khizi District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "XIZ",
    type: null,
    latitude: "40.91094890",
    longitude: "49.07292640",
  },
  {
    id: 524,
    name: "Khojali District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "XCI",
    type: null,
    latitude: "39.91325530",
    longitude: "46.79430500",
  },
  {
    id: 549,
    name: "Kurdamir District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "KUR",
    type: null,
    latitude: "40.36986510",
    longitude: "48.16446260",
  },
  {
    id: 541,
    name: "Lachin District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "LAC",
    type: null,
    latitude: "39.63834140",
    longitude: "46.54608530",
  },
  {
    id: 587,
    name: "Lankaran",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "LAN",
    type: null,
    latitude: "38.75286690",
    longitude: "48.84750150",
  },
  {
    id: 558,
    name: "Lankaran District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "LA",
    type: null,
    latitude: "38.75286690",
    longitude: "48.84750150",
  },
  {
    id: 546,
    name: "Lerik District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "LER",
    type: null,
    latitude: "38.77361920",
    longitude: "48.41514830",
  },
  {
    id: 568,
    name: "Martuni",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "XVD",
    type: null,
    latitude: "39.79146930",
    longitude: "47.11008140",
  },
  {
    id: 555,
    name: "Masally District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "MAS",
    type: null,
    latitude: "39.03407220",
    longitude: "48.65893540",
  },
  {
    id: 580,
    name: "Mingachevir",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "MI",
    type: null,
    latitude: "40.77025630",
    longitude: "47.04960150",
  },
  {
    id: 562,
    name: "Nakhchivan Autonomous Republic",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "NX",
    type: null,
    latitude: "39.32568140",
    longitude: "45.49126480",
  },
  {
    id: 530,
    name: "Neftchala District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "NEF",
    type: null,
    latitude: "39.38810520",
    longitude: "49.24137430",
  },
  {
    id: 556,
    name: "Oghuz District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "OGU",
    type: null,
    latitude: "41.07279240",
    longitude: "47.46506720",
  },
  {
    id: 534,
    name: "Ordubad District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "ORD",
    type: null,
    latitude: "38.90216220",
    longitude: "46.02376250",
  },
  {
    id: 542,
    name: "Qabala District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "QAB",
    type: null,
    latitude: "40.92539250",
    longitude: "47.80161060",
  },
  {
    id: 526,
    name: "Qakh District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "QAX",
    type: null,
    latitude: "41.42068270",
    longitude: "46.93201840",
  },
  {
    id: 521,
    name: "Qazakh District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "QAZ",
    type: null,
    latitude: "41.09710740",
    longitude: "45.35163310",
  },
  {
    id: 563,
    name: "Quba District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "QBA",
    type: null,
    latitude: "41.15642420",
    longitude: "48.41350210",
  },
  {
    id: 548,
    name: "Qubadli District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "QBI",
    type: null,
    latitude: "39.27139960",
    longitude: "46.63543120",
  },
  {
    id: 588,
    name: "Qusar District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "QUS",
    type: null,
    latitude: "41.42668860",
    longitude: "48.43455770",
  },
  {
    id: 557,
    name: "Saatly District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SAT",
    type: null,
    latitude: "39.90955030",
    longitude: "48.35951220",
  },
  {
    id: 565,
    name: "Sabirabad District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SAB",
    type: null,
    latitude: "39.98706630",
    longitude: "48.46925450",
  },
  {
    id: 522,
    name: "Sadarak District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SAD",
    type: null,
    latitude: "39.71051140",
    longitude: "44.88642770",
  },
  {
    id: 545,
    name: "Salyan District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SAL",
    type: null,
    latitude: "28.35248110",
    longitude: "82.12784000",
  },
  {
    id: 536,
    name: "Samukh District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SMX",
    type: null,
    latitude: "40.76046310",
    longitude: "46.40631810",
  },
  {
    id: 591,
    name: "Shabran District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SBN",
    type: null,
    latitude: "41.22283760",
    longitude: "48.84573040",
  },
  {
    id: 579,
    name: "Shahbuz District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SAH",
    type: null,
    latitude: "39.44521030",
    longitude: "45.65680090",
  },
  {
    id: 518,
    name: "Shaki",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SA",
    type: null,
    latitude: "41.19747530",
    longitude: "47.15712410",
  },
  {
    id: 586,
    name: "Shaki District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SAK",
    type: null,
    latitude: "41.11346620",
    longitude: "47.13169270",
  },
  {
    id: 529,
    name: "Shamakhi District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SMI",
    type: null,
    latitude: "40.63187310",
    longitude: "48.63638010",
  },
  {
    id: 583,
    name: "Shamkir District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SKR",
    type: null,
    latitude: "40.82881440",
    longitude: "46.01668790",
  },
  {
    id: 535,
    name: "Sharur District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SAR",
    type: null,
    latitude: "39.55363320",
    longitude: "44.98456800",
  },
  {
    id: 520,
    name: "Shirvan",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SR",
    type: null,
    latitude: "39.94697070",
    longitude: "48.92239190",
  },
  {
    id: 592,
    name: "Shusha District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SUS",
    type: null,
    latitude: "39.75374380",
    longitude: "46.74647550",
  },
  {
    id: 584,
    name: "Siazan District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SIY",
    type: null,
    latitude: "41.07838330",
    longitude: "49.11184770",
  },
  {
    id: 582,
    name: "Sumqayit",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "SM",
    type: null,
    latitude: "40.58547650",
    longitude: "49.63174110",
  },
  {
    id: 519,
    name: "Tartar District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "TAR",
    type: null,
    latitude: "40.34438750",
    longitude: "46.93765190",
  },
  {
    id: 533,
    name: "Tovuz District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "TOV",
    type: null,
    latitude: "40.99545230",
    longitude: "45.61659070",
  },
  {
    id: 539,
    name: "Ujar District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "UCA",
    type: null,
    latitude: "40.50675250",
    longitude: "47.64896410",
  },
  {
    id: 550,
    name: "Yardymli District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "YAR",
    type: null,
    latitude: "38.90589170",
    longitude: "48.24961270",
  },
  {
    id: 538,
    name: "Yevlakh",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "YE",
    type: null,
    latitude: "40.61966380",
    longitude: "47.15003240",
  },
  {
    id: 523,
    name: "Yevlakh District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "YEV",
    type: null,
    latitude: "40.61966380",
    longitude: "47.15003240",
  },
  {
    id: 581,
    name: "Zangilan District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "ZAN",
    type: null,
    latitude: "39.08568990",
    longitude: "46.65247280",
  },
  {
    id: 566,
    name: "Zaqatala District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "ZAQ",
    type: null,
    latitude: "41.59068890",
    longitude: "46.72403730",
  },
  {
    id: 576,
    name: "Zardab District",
    country_id: 16,
    country_code: "AZ",
    country_name: "Azerbaijan",
    state_code: "ZAR",
    type: null,
    latitude: "40.21481140",
    longitude: "47.71494400",
  },
  {
    id: 1992,
    name: "Capital",
    country_id: 18,
    country_code: "BH",
    country_name: "Bahrain",
    state_code: "13",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 1996,
    name: "Central",
    country_id: 18,
    country_code: "BH",
    country_name: "Bahrain",
    state_code: "16",
    type: null,
    latitude: "26.14260930",
    longitude: "50.56532940",
  },
  {
    id: 1995,
    name: "Muharraq",
    country_id: 18,
    country_code: "BH",
    country_name: "Bahrain",
    state_code: "15",
    type: null,
    latitude: "26.26856530",
    longitude: "50.64825170",
  },
  {
    id: 1994,
    name: "Northern",
    country_id: 18,
    country_code: "BH",
    country_name: "Bahrain",
    state_code: "17",
    type: null,
    latitude: "26.15519140",
    longitude: "50.48251730",
  },
  {
    id: 1993,
    name: "Southern",
    country_id: 18,
    country_code: "BH",
    country_name: "Bahrain",
    state_code: "14",
    type: null,
    latitude: "25.93810180",
    longitude: "50.57568870",
  },
  {
    id: 796,
    name: "Bagerhat District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "05",
    type: "district",
    latitude: "22.66024360",
    longitude: "89.78954780",
  },
  {
    id: 802,
    name: "Bahadia",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "33",
    type: null,
    latitude: "23.78987120",
    longitude: "90.16714830",
  },
  {
    id: 752,
    name: "Bandarban District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "01",
    type: "district",
    latitude: "21.83110020",
    longitude: "92.36863210",
  },
  {
    id: 784,
    name: "Barguna District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "02",
    type: "district",
    latitude: "22.09529150",
    longitude: "90.11206960",
  },
  {
    id: 818,
    name: "Barisal District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "06",
    type: "district",
    latitude: "22.70220980",
    longitude: "90.36963160",
  },
  {
    id: 807,
    name: "Barisal Division",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "A",
    type: "division",
    latitude: "22.38111310",
    longitude: "90.33718890",
  },
  {
    id: 756,
    name: "Bhola District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "07",
    type: "district",
    latitude: "22.17853150",
    longitude: "90.71010230",
  },
  {
    id: 797,
    name: "Bogra District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "03",
    type: "district",
    latitude: "24.85104020",
    longitude: "89.36972250",
  },
  {
    id: 810,
    name: "Brahmanbaria District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "04",
    type: "district",
    latitude: "23.96081810",
    longitude: "91.11150140",
  },
  {
    id: 768,
    name: "Chandpur District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "09",
    type: "district",
    latitude: "23.25131480",
    longitude: "90.85178460",
  },
  {
    id: 761,
    name: "Chapai Nawabganj District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "45",
    type: "district",
    latitude: "24.74131110",
    longitude: "88.29120690",
  },
  {
    id: 785,
    name: "Chittagong District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "10",
    type: "district",
    latitude: "22.51501050",
    longitude: "91.75388170",
  },
  {
    id: 803,
    name: "Chittagong Division",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "B",
    type: "division",
    latitude: "23.17931570",
    longitude: "91.98815270",
  },
  {
    id: 788,
    name: "Chuadanga District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "12",
    type: "district",
    latitude: "23.61605120",
    longitude: "88.82630060",
  },
  {
    id: 763,
    name: "Comilla District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "08",
    type: "district",
    latitude: "23.45756670",
    longitude: "91.18089960",
  },
  {
    id: 751,
    name: "Cox's Bazar District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "11",
    type: "district",
    latitude: "21.56406260",
    longitude: "92.02821290",
  },
  {
    id: 771,
    name: "Dhaka District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "13",
    type: "district",
    latitude: "23.81051400",
    longitude: "90.33718890",
  },
  {
    id: 760,
    name: "Dhaka Division",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "C",
    type: "division",
    latitude: "23.95357420",
    longitude: "90.14949880",
  },
  {
    id: 783,
    name: "Dinajpur District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "14",
    type: "district",
    latitude: "25.62791230",
    longitude: "88.63317580",
  },
  {
    id: 762,
    name: "Faridpur District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "15",
    type: "district",
    latitude: "23.54239190",
    longitude: "89.63089210",
  },
  {
    id: 816,
    name: "Feni District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "16",
    type: "district",
    latitude: "22.94087840",
    longitude: "91.40666460",
  },
  {
    id: 795,
    name: "Gaibandha District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "19",
    type: "district",
    latitude: "25.32969280",
    longitude: "89.54296520",
  },
  {
    id: 798,
    name: "Gazipur District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "18",
    type: "district",
    latitude: "24.09581710",
    longitude: "90.41251810",
  },
  {
    id: 792,
    name: "Gopalganj District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "17",
    type: "district",
    latitude: "26.48315840",
    longitude: "84.43655000",
  },
  {
    id: 805,
    name: "Habiganj District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "20",
    type: "district",
    latitude: "24.47712360",
    longitude: "91.45065650",
  },
  {
    id: 808,
    name: "Jamalpur District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "21",
    type: "district",
    latitude: "25.08309260",
    longitude: "89.78532180",
  },
  {
    id: 757,
    name: "Jessore District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "22",
    type: "district",
    latitude: "23.16340140",
    longitude: "89.21816640",
  },
  {
    id: 778,
    name: "Jhalokati District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "25",
    type: "district",
    latitude: "22.57208000",
    longitude: "90.18696440",
  },
  {
    id: 789,
    name: "Jhenaidah District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "23",
    type: "district",
    latitude: "23.54498730",
    longitude: "89.17260310",
  },
  {
    id: 806,
    name: "Joypurhat District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "24",
    type: "district",
    latitude: "25.09473490",
    longitude: "89.09449370",
  },
  {
    id: 786,
    name: "Khagrachari District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "29",
    type: "district",
    latitude: "23.13217510",
    longitude: "91.94902100",
  },
  {
    id: 811,
    name: "Khulna District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "27",
    type: "district",
    latitude: "22.67377350",
    longitude: "89.39665810",
  },
  {
    id: 775,
    name: "Khulna Division",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "D",
    type: "division",
    latitude: "22.80878160",
    longitude: "89.24671910",
  },
  {
    id: 779,
    name: "Kishoreganj District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "26",
    type: "district",
    latitude: "24.42604570",
    longitude: "90.98206680",
  },
  {
    id: 793,
    name: "Kurigram District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "28",
    type: "district",
    latitude: "25.80724140",
    longitude: "89.62947460",
  },
  {
    id: 774,
    name: "Kushtia District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "30",
    type: "district",
    latitude: "23.89069950",
    longitude: "89.10993680",
  },
  {
    id: 819,
    name: "Lakshmipur District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "31",
    type: "district",
    latitude: "22.94467440",
    longitude: "90.82819070",
  },
  {
    id: 780,
    name: "Lalmonirhat District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "32",
    type: "district",
    latitude: "25.99233980",
    longitude: "89.28472510",
  },
  {
    id: 817,
    name: "Madaripur District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "36",
    type: "district",
    latitude: "23.23933460",
    longitude: "90.18696440",
  },
  {
    id: 776,
    name: "Meherpur District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "39",
    type: "district",
    latitude: "23.80519910",
    longitude: "88.67235780",
  },
  {
    id: 794,
    name: "Moulvibazar District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "38",
    type: "district",
    latitude: "24.30953440",
    longitude: "91.73149030",
  },
  {
    id: 790,
    name: "Munshiganj District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "35",
    type: "district",
    latitude: "23.49809310",
    longitude: "90.41266210",
  },
  {
    id: 766,
    name: "Mymensingh District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "34",
    type: "district",
    latitude: "24.75385750",
    longitude: "90.40729190",
  },
  {
    id: 758,
    name: "Mymensingh Division",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "H",
    type: "division",
    latitude: "24.71362000",
    longitude: "90.45023680",
  },
  {
    id: 814,
    name: "Naogaon District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "48",
    type: "district",
    latitude: "24.91315970",
    longitude: "88.75309520",
  },
  {
    id: 769,
    name: "Narail District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "43",
    type: "district",
    latitude: "23.11629290",
    longitude: "89.58404040",
  },
  {
    id: 770,
    name: "Narayanganj District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "40",
    type: "district",
    latitude: "23.71466010",
    longitude: "90.56360900",
  },
  {
    id: 787,
    name: "Natore District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "44",
    type: "district",
    latitude: "24.41024300",
    longitude: "89.00761770",
  },
  {
    id: 764,
    name: "Netrokona District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "41",
    type: "district",
    latitude: "24.81032840",
    longitude: "90.86564150",
  },
  {
    id: 772,
    name: "Nilphamari District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "46",
    type: "district",
    latitude: "25.84827980",
    longitude: "88.94141340",
  },
  {
    id: 815,
    name: "Noakhali District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "47",
    type: "district",
    latitude: "22.87237890",
    longitude: "91.09731840",
  },
  {
    id: 754,
    name: "Pabna District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "49",
    type: "district",
    latitude: "24.15850500",
    longitude: "89.44807180",
  },
  {
    id: 800,
    name: "Panchagarh District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "52",
    type: "district",
    latitude: "26.27087050",
    longitude: "88.59517510",
  },
  {
    id: 777,
    name: "Patuakhali District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "51",
    type: "district",
    latitude: "22.22486320",
    longitude: "90.45475030",
  },
  {
    id: 791,
    name: "Pirojpur District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "50",
    type: "district",
    latitude: "22.57907440",
    longitude: "89.97592640",
  },
  {
    id: 773,
    name: "Rajbari District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "53",
    type: "district",
    latitude: "23.71513400",
    longitude: "89.58748190",
  },
  {
    id: 813,
    name: "Rajshahi District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "54",
    type: "district",
    latitude: "24.37330870",
    longitude: "88.60487160",
  },
  {
    id: 753,
    name: "Rajshahi Division",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "E",
    type: "division",
    latitude: "24.71057760",
    longitude: "88.94138650",
  },
  {
    id: 809,
    name: "Rangamati Hill District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "56",
    type: "district",
    latitude: "22.73241730",
    longitude: "92.29851340",
  },
  {
    id: 759,
    name: "Rangpur District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "55",
    type: "district",
    latitude: "25.74679250",
    longitude: "89.25083350",
  },
  {
    id: 750,
    name: "Rangpur Division",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "F",
    type: "division",
    latitude: "25.84833880",
    longitude: "88.94138650",
  },
  {
    id: 799,
    name: "Satkhira District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "58",
    type: "district",
    latitude: "22.31548120",
    longitude: "89.11145250",
  },
  {
    id: 801,
    name: "Shariatpur District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "62",
    type: "district",
    latitude: "23.24232140",
    longitude: "90.43477110",
  },
  {
    id: 755,
    name: "Sherpur District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "57",
    type: "district",
    latitude: "25.07462350",
    longitude: "90.14949040",
  },
  {
    id: 781,
    name: "Sirajganj District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "59",
    type: "district",
    latitude: "24.31411150",
    longitude: "89.56996150",
  },
  {
    id: 812,
    name: "Sunamganj District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "61",
    type: "district",
    latitude: "25.07145350",
    longitude: "91.39916270",
  },
  {
    id: 767,
    name: "Sylhet District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "60",
    type: "district",
    latitude: "24.89933570",
    longitude: "91.87004730",
  },
  {
    id: 765,
    name: "Sylhet Division",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "G",
    type: "division",
    latitude: "24.70498110",
    longitude: "91.67606910",
  },
  {
    id: 782,
    name: "Tangail District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "63",
    type: "district",
    latitude: "24.39174270",
    longitude: "89.99482570",
  },
  {
    id: 804,
    name: "Thakurgaon District",
    country_id: 19,
    country_code: "BD",
    country_name: "Bangladesh",
    state_code: "64",
    type: "district",
    latitude: "26.04183920",
    longitude: "88.42826160",
  },
  {
    id: 1228,
    name: "Christ Church",
    country_id: 20,
    country_code: "BB",
    country_name: "Barbados",
    state_code: "01",
    type: null,
    latitude: "36.00604070",
    longitude: "-95.92112100",
  },
  {
    id: 1229,
    name: "Saint Andrew",
    country_id: 20,
    country_code: "BB",
    country_name: "Barbados",
    state_code: "02",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 1226,
    name: "Saint George",
    country_id: 20,
    country_code: "BB",
    country_name: "Barbados",
    state_code: "03",
    type: null,
    latitude: "37.09652780",
    longitude: "-113.56841640",
  },
  {
    id: 1224,
    name: "Saint James",
    country_id: 20,
    country_code: "BB",
    country_name: "Barbados",
    state_code: "04",
    type: null,
    latitude: "48.52356600",
    longitude: "-1.32378850",
  },
  {
    id: 1227,
    name: "Saint John",
    country_id: 20,
    country_code: "BB",
    country_name: "Barbados",
    state_code: "05",
    type: null,
    latitude: "45.27331530",
    longitude: "-66.06330800",
  },
  {
    id: 1223,
    name: "Saint Joseph",
    country_id: 20,
    country_code: "BB",
    country_name: "Barbados",
    state_code: "06",
    type: null,
    latitude: "39.76745780",
    longitude: "-94.84668100",
  },
  {
    id: 1221,
    name: "Saint Lucy",
    country_id: 20,
    country_code: "BB",
    country_name: "Barbados",
    state_code: "07",
    type: null,
    latitude: "38.76146250",
    longitude: "-77.44914390",
  },
  {
    id: 1230,
    name: "Saint Michael",
    country_id: 20,
    country_code: "BB",
    country_name: "Barbados",
    state_code: "08",
    type: null,
    latitude: "36.03597700",
    longitude: "-95.84905200",
  },
  {
    id: 1222,
    name: "Saint Peter",
    country_id: 20,
    country_code: "BB",
    country_name: "Barbados",
    state_code: "09",
    type: null,
    latitude: "37.08271190",
    longitude: "-94.51712500",
  },
  {
    id: 1220,
    name: "Saint Philip",
    country_id: 20,
    country_code: "BB",
    country_name: "Barbados",
    state_code: "10",
    type: null,
    latitude: "35.23311400",
    longitude: "-89.43640420",
  },
  {
    id: 1225,
    name: "Saint Thomas",
    country_id: 20,
    country_code: "BB",
    country_name: "Barbados",
    state_code: "11",
    type: null,
    latitude: "18.33809650",
    longitude: "-64.89409460",
  },
  {
    id: 2959,
    name: "Brest Region",
    country_id: 21,
    country_code: "BY",
    country_name: "Belarus",
    state_code: "BR",
    type: null,
    latitude: "52.52966410",
    longitude: "25.46064800",
  },
  {
    id: 2955,
    name: "Gomel Region",
    country_id: 21,
    country_code: "BY",
    country_name: "Belarus",
    state_code: "HO",
    type: null,
    latitude: "52.16487540",
    longitude: "29.13332510",
  },
  {
    id: 2956,
    name: "Grodno Region",
    country_id: 21,
    country_code: "BY",
    country_name: "Belarus",
    state_code: "HR",
    type: null,
    latitude: "53.65999450",
    longitude: "25.34485710",
  },
  {
    id: 2958,
    name: "Minsk",
    country_id: 21,
    country_code: "BY",
    country_name: "Belarus",
    state_code: "HM",
    type: null,
    latitude: "53.90060110",
    longitude: "27.55897200",
  },
  {
    id: 2957,
    name: "Minsk Region",
    country_id: 21,
    country_code: "BY",
    country_name: "Belarus",
    state_code: "MI",
    type: null,
    latitude: "54.10678890",
    longitude: "27.41292450",
  },
  {
    id: 2954,
    name: "Mogilev Region",
    country_id: 21,
    country_code: "BY",
    country_name: "Belarus",
    state_code: "MA",
    type: null,
    latitude: "53.51017910",
    longitude: "30.40064440",
  },
  {
    id: 2960,
    name: "Vitebsk Region",
    country_id: 21,
    country_code: "BY",
    country_name: "Belarus",
    state_code: "VI",
    type: null,
    latitude: "55.29598330",
    longitude: "28.75836270",
  },
  {
    id: 1381,
    name: "Antwerp",
    country_id: 22,
    country_code: "BE",
    country_name: "Belgium",
    state_code: "VAN",
    type: null,
    latitude: "51.21944750",
    longitude: "4.40246430",
  },
  {
    id: 1376,
    name: "Brussels-Capital Region",
    country_id: 22,
    country_code: "BE",
    country_name: "Belgium",
    state_code: "BRU",
    type: null,
    latitude: "50.85034630",
    longitude: "4.35172110",
  },
  {
    id: 1377,
    name: "East Flanders",
    country_id: 22,
    country_code: "BE",
    country_name: "Belgium",
    state_code: "VOV",
    type: null,
    latitude: "51.03621010",
    longitude: "3.73731240",
  },
  {
    id: 1373,
    name: "Flanders",
    country_id: 22,
    country_code: "BE",
    country_name: "Belgium",
    state_code: "VLG",
    type: null,
    latitude: "51.01087060",
    longitude: "3.72646130",
  },
  {
    id: 1374,
    name: "Flemish Brabant",
    country_id: 22,
    country_code: "BE",
    country_name: "Belgium",
    state_code: "VBR",
    type: null,
    latitude: "50.88154340",
    longitude: "4.56459700",
  },
  {
    id: 1375,
    name: "Hainaut",
    country_id: 22,
    country_code: "BE",
    country_name: "Belgium",
    state_code: "WHT",
    type: null,
    latitude: "50.52570760",
    longitude: "4.06210170",
  },
  {
    id: 1384,
    name: "Liège",
    country_id: 22,
    country_code: "BE",
    country_name: "Belgium",
    state_code: "WLG",
    type: null,
    latitude: "50.63255740",
    longitude: "5.57966620",
  },
  {
    id: 1372,
    name: "Limburg",
    country_id: 22,
    country_code: "BE",
    country_name: "Belgium",
    state_code: "VLI",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 1379,
    name: "Luxembourg",
    country_id: 22,
    country_code: "BE",
    country_name: "Belgium",
    state_code: "WLX",
    type: null,
    latitude: "49.81527300",
    longitude: "6.12958300",
  },
  {
    id: 1378,
    name: "Namur",
    country_id: 22,
    country_code: "BE",
    country_name: "Belgium",
    state_code: "WNA",
    type: null,
    latitude: "50.46738830",
    longitude: "4.87198540",
  },
  {
    id: 1380,
    name: "Wallonia",
    country_id: 22,
    country_code: "BE",
    country_name: "Belgium",
    state_code: "WAL",
    type: null,
    latitude: "50.41756370",
    longitude: "4.45100660",
  },
  {
    id: 1382,
    name: "Walloon Brabant",
    country_id: 22,
    country_code: "BE",
    country_name: "Belgium",
    state_code: "WBR",
    type: null,
    latitude: "50.63324100",
    longitude: "4.52431500",
  },
  {
    id: 1383,
    name: "West Flanders",
    country_id: 22,
    country_code: "BE",
    country_name: "Belgium",
    state_code: "VWV",
    type: null,
    latitude: "51.04047470",
    longitude: "2.99942130",
  },
  {
    id: 264,
    name: "Belize District",
    country_id: 23,
    country_code: "BZ",
    country_name: "Belize",
    state_code: "BZ",
    type: null,
    latitude: "17.56776790",
    longitude: "-88.40160410",
  },
  {
    id: 269,
    name: "Cayo District",
    country_id: 23,
    country_code: "BZ",
    country_name: "Belize",
    state_code: "CY",
    type: null,
    latitude: "17.09844450",
    longitude: "-88.94138650",
  },
  {
    id: 266,
    name: "Corozal District",
    country_id: 23,
    country_code: "BZ",
    country_name: "Belize",
    state_code: "CZL",
    type: null,
    latitude: "18.13492380",
    longitude: "-88.24611830",
  },
  {
    id: 268,
    name: "Orange Walk District",
    country_id: 23,
    country_code: "BZ",
    country_name: "Belize",
    state_code: "OW",
    type: null,
    latitude: "17.76035300",
    longitude: "-88.86469800",
  },
  {
    id: 265,
    name: "Stann Creek District",
    country_id: 23,
    country_code: "BZ",
    country_name: "Belize",
    state_code: "SC",
    type: null,
    latitude: "16.81166310",
    longitude: "-88.40160410",
  },
  {
    id: 267,
    name: "Toledo District",
    country_id: 23,
    country_code: "BZ",
    country_name: "Belize",
    state_code: "TOL",
    type: null,
    latitude: "16.24919230",
    longitude: "-88.86469800",
  },
  {
    id: 3077,
    name: "Alibori Department",
    country_id: 24,
    country_code: "BJ",
    country_name: "Benin",
    state_code: "AL",
    type: null,
    latitude: "10.96810930",
    longitude: "2.77798130",
  },
  {
    id: 3076,
    name: "Atakora Department",
    country_id: 24,
    country_code: "BJ",
    country_name: "Benin",
    state_code: "AK",
    type: null,
    latitude: "10.79549310",
    longitude: "1.67606910",
  },
  {
    id: 3079,
    name: "Atlantique Department",
    country_id: 24,
    country_code: "BJ",
    country_name: "Benin",
    state_code: "AQ",
    type: null,
    latitude: "6.65883910",
    longitude: "2.22366670",
  },
  {
    id: 3078,
    name: "Borgou Department",
    country_id: 24,
    country_code: "BJ",
    country_name: "Benin",
    state_code: "BO",
    type: null,
    latitude: "9.53408640",
    longitude: "2.77798130",
  },
  {
    id: 3070,
    name: "Collines Department",
    country_id: 24,
    country_code: "BJ",
    country_name: "Benin",
    state_code: "CO",
    type: null,
    latitude: "8.30222970",
    longitude: "2.30244600",
  },
  {
    id: 3072,
    name: "Donga Department",
    country_id: 24,
    country_code: "BJ",
    country_name: "Benin",
    state_code: "DO",
    type: null,
    latitude: "9.71918670",
    longitude: "1.67606910",
  },
  {
    id: 3071,
    name: "Kouffo Department",
    country_id: 24,
    country_code: "BJ",
    country_name: "Benin",
    state_code: "KO",
    type: null,
    latitude: "7.00358940",
    longitude: "1.75388170",
  },
  {
    id: 3081,
    name: "Littoral Department",
    country_id: 24,
    country_code: "BJ",
    country_name: "Benin",
    state_code: "LI",
    type: null,
    latitude: "6.38069730",
    longitude: "2.44063870",
  },
  {
    id: 3075,
    name: "Mono Department",
    country_id: 24,
    country_code: "BJ",
    country_name: "Benin",
    state_code: "MO",
    type: null,
    latitude: "37.92186080",
    longitude: "-118.95286450",
  },
  {
    id: 3080,
    name: "Ouémé Department",
    country_id: 24,
    country_code: "BJ",
    country_name: "Benin",
    state_code: "OU",
    type: null,
    latitude: "6.61481520",
    longitude: "2.49999180",
  },
  {
    id: 3074,
    name: "Plateau Department",
    country_id: 24,
    country_code: "BJ",
    country_name: "Benin",
    state_code: "PL",
    type: null,
    latitude: "7.34451410",
    longitude: "2.53960300",
  },
  {
    id: 3073,
    name: "Zou Department",
    country_id: 24,
    country_code: "BJ",
    country_name: "Benin",
    state_code: "ZO",
    type: null,
    latitude: "7.34692680",
    longitude: "2.06651970",
  },
  {
    id: 4860,
    name: "Devonshire Parish",
    country_id: 25,
    country_code: "BM",
    country_name: "Bermuda",
    state_code: "DEV",
    type: null,
    latitude: "32.30380620",
    longitude: "-64.76069540",
  },
  {
    id: 4861,
    name: "Hamilton Parish",
    country_id: 25,
    country_code: "BM",
    country_name: "Bermuda",
    state_code: "HA",
    type: null,
    latitude: "32.34494320",
    longitude: "-64.72365000",
  },
  {
    id: 4863,
    name: "Paget Parish",
    country_id: 25,
    country_code: "BM",
    country_name: "Bermuda",
    state_code: "PAG",
    type: null,
    latitude: "32.28107400",
    longitude: "-64.77847870",
  },
  {
    id: 4864,
    name: "Pembroke Parish",
    country_id: 25,
    country_code: "BM",
    country_name: "Bermuda",
    state_code: "PEM",
    type: null,
    latitude: "32.30076720",
    longitude: "-64.79626300",
  },
  {
    id: 4866,
    name: "Saint George's Parish",
    country_id: 25,
    country_code: "BM",
    country_name: "Bermuda",
    state_code: "SGE",
    type: null,
    latitude: "17.12577590",
    longitude: "-62.56198110",
  },
  {
    id: 4867,
    name: "Sandys Parish",
    country_id: 25,
    country_code: "BM",
    country_name: "Bermuda",
    state_code: "SAN",
    type: null,
    latitude: "32.29995280",
    longitude: "-64.86741030",
  },
  {
    id: 4868,
    name: "Smith's Parish,",
    country_id: 25,
    country_code: "BM",
    country_name: "Bermuda",
    state_code: "SMI",
    type: null,
    latitude: "32.31339660",
    longitude: "-64.73105880",
  },
  {
    id: 4869,
    name: "Southampton Parish",
    country_id: 25,
    country_code: "BM",
    country_name: "Bermuda",
    state_code: "SOU",
    type: null,
    latitude: "32.25400950",
    longitude: "-64.82590580",
  },
  {
    id: 4870,
    name: "Warwick Parish",
    country_id: 25,
    country_code: "BM",
    country_name: "Bermuda",
    state_code: "WAR",
    type: null,
    latitude: "32.26615340",
    longitude: "-64.80811980",
  },
  {
    id: 240,
    name: "Bumthang District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "33",
    type: null,
    latitude: "27.64183900",
    longitude: "90.67730460",
  },
  {
    id: 239,
    name: "Chukha District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "12",
    type: null,
    latitude: "27.07843040",
    longitude: "89.47421770",
  },
  {
    id: 238,
    name: "Dagana District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "22",
    type: null,
    latitude: "27.03228610",
    longitude: "89.88793040",
  },
  {
    id: 229,
    name: "Gasa District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "GA",
    type: null,
    latitude: "28.01858860",
    longitude: "89.92532330",
  },
  {
    id: 232,
    name: "Haa District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "13",
    type: null,
    latitude: "27.26516690",
    longitude: "89.17059980",
  },
  {
    id: 234,
    name: "Lhuntse District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "44",
    type: null,
    latitude: "27.82649890",
    longitude: "91.13530200",
  },
  {
    id: 242,
    name: "Mongar District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "42",
    type: null,
    latitude: "27.26170590",
    longitude: "91.28910360",
  },
  {
    id: 237,
    name: "Paro District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "11",
    type: null,
    latitude: "27.42859490",
    longitude: "89.41665160",
  },
  {
    id: 244,
    name: "Pemagatshel District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "43",
    type: null,
    latitude: "27.00238200",
    longitude: "91.34692470",
  },
  {
    id: 235,
    name: "Punakha District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "23",
    type: null,
    latitude: "27.69037160",
    longitude: "89.88793040",
  },
  {
    id: 243,
    name: "Samdrup Jongkhar District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "45",
    type: null,
    latitude: "26.80356820",
    longitude: "91.50392070",
  },
  {
    id: 246,
    name: "Samtse District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "14",
    type: null,
    latitude: "27.02918320",
    longitude: "89.05615320",
  },
  {
    id: 247,
    name: "Sarpang District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "31",
    type: null,
    latitude: "26.93730410",
    longitude: "90.48799160",
  },
  {
    id: 241,
    name: "Thimphu District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "15",
    type: null,
    latitude: "27.47122160",
    longitude: "89.63390410",
  },
  {
    id: 236,
    name: "Trashigang District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "41",
    type: null,
    latitude: "27.25667950",
    longitude: "91.75388170",
  },
  {
    id: 245,
    name: "Trongsa District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "32",
    type: null,
    latitude: "27.50022690",
    longitude: "90.50806340",
  },
  {
    id: 230,
    name: "Tsirang District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "21",
    type: null,
    latitude: "27.03220700",
    longitude: "90.18696440",
  },
  {
    id: 231,
    name: "Wangdue Phodrang District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "24",
    type: null,
    latitude: "27.45260460",
    longitude: "90.06749280",
  },
  {
    id: 233,
    name: "Zhemgang District",
    country_id: 26,
    country_code: "BT",
    country_name: "Bhutan",
    state_code: "34",
    type: null,
    latitude: "27.07697500",
    longitude: "90.82940020",
  },
  {
    id: 3375,
    name: "Beni Department",
    country_id: 27,
    country_code: "BO",
    country_name: "Bolivia",
    state_code: "B",
    type: null,
    latitude: "-14.37827470",
    longitude: "-65.09577920",
  },
  {
    id: 3382,
    name: "Chuquisaca Department",
    country_id: 27,
    country_code: "BO",
    country_name: "Bolivia",
    state_code: "H",
    type: null,
    latitude: "-20.02491440",
    longitude: "-64.14782360",
  },
  {
    id: 3381,
    name: "Cochabamba Department",
    country_id: 27,
    country_code: "BO",
    country_name: "Bolivia",
    state_code: "C",
    type: null,
    latitude: "-17.56816750",
    longitude: "-65.47573600",
  },
  {
    id: 3380,
    name: "La Paz Department",
    country_id: 27,
    country_code: "BO",
    country_name: "Bolivia",
    state_code: "L",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3376,
    name: "Oruro Department",
    country_id: 27,
    country_code: "BO",
    country_name: "Bolivia",
    state_code: "O",
    type: null,
    latitude: "-18.57115790",
    longitude: "-67.76159830",
  },
  {
    id: 3379,
    name: "Pando Department",
    country_id: 27,
    country_code: "BO",
    country_name: "Bolivia",
    state_code: "N",
    type: null,
    latitude: "-10.79889010",
    longitude: "-66.99880110",
  },
  {
    id: 3383,
    name: "Potosí Department",
    country_id: 27,
    country_code: "BO",
    country_name: "Bolivia",
    state_code: "P",
    type: null,
    latitude: "-20.62471300",
    longitude: "-66.99880110",
  },
  {
    id: 3377,
    name: "Santa Cruz Department",
    country_id: 27,
    country_code: "BO",
    country_name: "Bolivia",
    state_code: "S",
    type: null,
    latitude: "-16.74760370",
    longitude: "-62.07509980",
  },
  {
    id: 3378,
    name: "Tarija Department",
    country_id: 27,
    country_code: "BO",
    country_name: "Bolivia",
    state_code: "T",
    type: null,
    latitude: "-21.58315950",
    longitude: "-63.95861110",
  },
  {
    id: 5086,
    name: "Bonaire",
    country_id: 155,
    country_code: "BQ",
    country_name: "Bonaire, Sint Eustatius and Saba",
    state_code: "BQ1",
    type: "special municipality",
    latitude: "12.20189020",
    longitude: "-68.26238220",
  },
  {
    id: 5087,
    name: "Saba",
    country_id: 155,
    country_code: "BQ",
    country_name: "Bonaire, Sint Eustatius and Saba",
    state_code: "BQ2",
    type: "special municipality",
    latitude: "17.63546420",
    longitude: "-63.23267630",
  },
  {
    id: 5088,
    name: "Sint Eustatius",
    country_id: 155,
    country_code: "BQ",
    country_name: "Bonaire, Sint Eustatius and Saba",
    state_code: "BQ3",
    type: "special municipality",
    latitude: "17.48903060",
    longitude: "-62.97355500",
  },
  {
    id: 472,
    name: "Bosnian Podrinje Canton",
    country_id: 28,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    state_code: "05",
    type: null,
    latitude: "43.68749000",
    longitude: "18.82443940",
  },
  {
    id: 460,
    name: "Brčko District",
    country_id: 28,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    state_code: "BRC",
    type: null,
    latitude: "44.84059440",
    longitude: "18.74215300",
  },
  {
    id: 471,
    name: "Canton 10",
    country_id: 28,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    state_code: "10",
    type: null,
    latitude: "43.95341550",
    longitude: "16.94251870",
  },
  {
    id: 462,
    name: "Central Bosnia Canton",
    country_id: 28,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    state_code: "06",
    type: null,
    latitude: "44.13818560",
    longitude: "17.68667140",
  },
  {
    id: 467,
    name: "Federation of Bosnia and Herzegovina",
    country_id: 28,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    state_code: "BIH",
    type: null,
    latitude: "43.88748970",
    longitude: "17.84279300",
  },
  {
    id: 463,
    name: "Herzegovina-Neretva Canton",
    country_id: 28,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    state_code: "07",
    type: null,
    latitude: "43.52651590",
    longitude: "17.76362100",
  },
  {
    id: 464,
    name: "Posavina Canton",
    country_id: 28,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    state_code: "02",
    type: null,
    latitude: "45.07520940",
    longitude: "18.37763040",
  },
  {
    id: 470,
    name: "Republika Srpska",
    country_id: 28,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    state_code: "SRP",
    type: null,
    latitude: "44.72801860",
    longitude: "17.31481360",
  },
  {
    id: 466,
    name: "Sarajevo Canton",
    country_id: 28,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    state_code: "09",
    type: null,
    latitude: "43.85125640",
    longitude: "18.29534420",
  },
  {
    id: 461,
    name: "Tuzla Canton",
    country_id: 28,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    state_code: "03",
    type: null,
    latitude: "44.53434630",
    longitude: "18.69727970",
  },
  {
    id: 465,
    name: "Una-Sana Canton",
    country_id: 28,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    state_code: "01",
    type: null,
    latitude: "44.65031160",
    longitude: "16.31716290",
  },
  {
    id: 469,
    name: "West Herzegovina Canton",
    country_id: 28,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    state_code: "08",
    type: null,
    latitude: "43.43692440",
    longitude: "17.38488310",
  },
  {
    id: 468,
    name: "Zenica-Doboj Canton",
    country_id: 28,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    state_code: "04",
    type: null,
    latitude: "44.21271090",
    longitude: "18.16046250",
  },
  {
    id: 3067,
    name: "Central District",
    country_id: 29,
    country_code: "BW",
    country_name: "Botswana",
    state_code: "CE",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3061,
    name: "Ghanzi District",
    country_id: 29,
    country_code: "BW",
    country_name: "Botswana",
    state_code: "GH",
    type: null,
    latitude: "-21.86523140",
    longitude: "21.85685860",
  },
  {
    id: 3066,
    name: "Kgalagadi District",
    country_id: 29,
    country_code: "BW",
    country_name: "Botswana",
    state_code: "KG",
    type: null,
    latitude: "-24.75502850",
    longitude: "21.85685860",
  },
  {
    id: 3062,
    name: "Kgatleng District",
    country_id: 29,
    country_code: "BW",
    country_name: "Botswana",
    state_code: "KL",
    type: null,
    latitude: "-24.19704450",
    longitude: "26.23046160",
  },
  {
    id: 3069,
    name: "Kweneng District",
    country_id: 29,
    country_code: "BW",
    country_name: "Botswana",
    state_code: "KW",
    type: null,
    latitude: "-23.83672490",
    longitude: "25.28375850",
  },
  {
    id: 3060,
    name: "Ngamiland",
    country_id: 29,
    country_code: "BW",
    country_name: "Botswana",
    state_code: "NG",
    type: null,
    latitude: "-19.19053210",
    longitude: "23.00119890",
  },
  {
    id: 3068,
    name: "North-East District",
    country_id: 29,
    country_code: "BW",
    country_name: "Botswana",
    state_code: "NE",
    type: null,
    latitude: "37.58844610",
    longitude: "-94.68637820",
  },
  {
    id: 3065,
    name: "North-West District",
    country_id: 29,
    country_code: "BW",
    country_name: "Botswana",
    state_code: "NW",
    type: null,
    latitude: "39.34463070",
    longitude: "-76.68542830",
  },
  {
    id: 3064,
    name: "South-East District",
    country_id: 29,
    country_code: "BW",
    country_name: "Botswana",
    state_code: "SE",
    type: null,
    latitude: "31.21637980",
    longitude: "-82.35270440",
  },
  {
    id: 3063,
    name: "Southern District",
    country_id: 29,
    country_code: "BW",
    country_name: "Botswana",
    state_code: "SO",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2012,
    name: "Acre",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "AC",
    type: null,
    latitude: "-9.02379600",
    longitude: "-70.81199500",
  },
  {
    id: 2007,
    name: "Alagoas",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "AL",
    type: null,
    latitude: "-9.57130580",
    longitude: "-36.78195050",
  },
  {
    id: 1999,
    name: "Amapá",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "AP",
    type: null,
    latitude: "0.90199250",
    longitude: "-52.00295650",
  },
  {
    id: 2004,
    name: "Amazonas",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "AM",
    type: null,
    latitude: "-3.07000000",
    longitude: "-61.66000000",
  },
  {
    id: 2002,
    name: "Bahia",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "BA",
    type: null,
    latitude: "-11.40987400",
    longitude: "-41.28085700",
  },
  {
    id: 2016,
    name: "Ceará",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "CE",
    type: null,
    latitude: "-5.49839770",
    longitude: "-39.32062410",
  },
  {
    id: 2017,
    name: "Distrito Federal",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "DF",
    type: null,
    latitude: "-15.79976540",
    longitude: "-47.86447150",
  },
  {
    id: 2018,
    name: "Espírito Santo",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "ES",
    type: null,
    latitude: "-19.18342290",
    longitude: "-40.30886260",
  },
  {
    id: 2000,
    name: "Goiás",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "GO",
    type: null,
    latitude: "-15.82703690",
    longitude: "-49.83622370",
  },
  {
    id: 2015,
    name: "Maranhão",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "MA",
    type: null,
    latitude: "-4.96094980",
    longitude: "-45.27441590",
  },
  {
    id: 2011,
    name: "Mato Grosso",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "MT",
    type: null,
    latitude: "-12.68187120",
    longitude: "-56.92109900",
  },
  {
    id: 2010,
    name: "Mato Grosso do Sul",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "MS",
    type: null,
    latitude: "-20.77222950",
    longitude: "-54.78515310",
  },
  {
    id: 1998,
    name: "Minas Gerais",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "MG",
    type: null,
    latitude: "-18.51217800",
    longitude: "-44.55503080",
  },
  {
    id: 2009,
    name: "Pará",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "PA",
    type: null,
    latitude: "-1.99812710",
    longitude: "-54.93061520",
  },
  {
    id: 2005,
    name: "Paraíba",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "PB",
    type: null,
    latitude: "-7.23996090",
    longitude: "-36.78195050",
  },
  {
    id: 2022,
    name: "Paraná",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "PR",
    type: null,
    latitude: "-25.25208880",
    longitude: "-52.02154150",
  },
  {
    id: 2006,
    name: "Pernambuco",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "PE",
    type: null,
    latitude: "-8.81371730",
    longitude: "-36.95410700",
  },
  {
    id: 2008,
    name: "Piauí",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "PI",
    type: null,
    latitude: "-7.71834010",
    longitude: "-42.72892360",
  },
  {
    id: 1997,
    name: "Rio de Janeiro",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "RJ",
    type: null,
    latitude: "-22.90684670",
    longitude: "-43.17289650",
  },
  {
    id: 2019,
    name: "Rio Grande do Norte",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "RN",
    type: null,
    latitude: "-5.40258030",
    longitude: "-36.95410700",
  },
  {
    id: 2001,
    name: "Rio Grande do Sul",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "RS",
    type: null,
    latitude: "-30.03463160",
    longitude: "-51.21769860",
  },
  {
    id: 2013,
    name: "Rondônia",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "RO",
    type: null,
    latitude: "-11.50573410",
    longitude: "-63.58061100",
  },
  {
    id: 4858,
    name: "Roraima",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "RR",
    type: null,
    latitude: "2.73759710",
    longitude: "-62.07509980",
  },
  {
    id: 2014,
    name: "Santa Catarina",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "SC",
    type: null,
    latitude: "-27.33000000",
    longitude: "-49.44000000",
  },
  {
    id: 2021,
    name: "São Paulo",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "SP",
    type: null,
    latitude: "-23.55051990",
    longitude: "-46.63330940",
  },
  {
    id: 2003,
    name: "Sergipe",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "SE",
    type: null,
    latitude: "-10.57409340",
    longitude: "-37.38565810",
  },
  {
    id: 2020,
    name: "Tocantins",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    state_code: "TO",
    type: null,
    latitude: "-10.17528000",
    longitude: "-48.29824740",
  },
  {
    id: 1217,
    name: "Belait District",
    country_id: 33,
    country_code: "BN",
    country_name: "Brunei",
    state_code: "BE",
    type: null,
    latitude: "4.37507490",
    longitude: "114.61928990",
  },
  {
    id: 1216,
    name: "Brunei-Muara District",
    country_id: 33,
    country_code: "BN",
    country_name: "Brunei",
    state_code: "BM",
    type: null,
    latitude: "4.93112060",
    longitude: "114.95168690",
  },
  {
    id: 1218,
    name: "Temburong District",
    country_id: 33,
    country_code: "BN",
    country_name: "Brunei",
    state_code: "TE",
    type: null,
    latitude: "4.62041280",
    longitude: "115.14148400",
  },
  {
    id: 1219,
    name: "Tutong District",
    country_id: 33,
    country_code: "BN",
    country_name: "Brunei",
    state_code: "TU",
    type: null,
    latitude: "4.71403730",
    longitude: "114.66679390",
  },
  {
    id: 4699,
    name: "Blagoevgrad Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "01",
    type: null,
    latitude: "42.02086140",
    longitude: "23.09433560",
  },
  {
    id: 4715,
    name: "Burgas Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "02",
    type: null,
    latitude: "42.50480000",
    longitude: "27.46260790",
  },
  {
    id: 4718,
    name: "Dobrich Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "08",
    type: null,
    latitude: "43.57278600",
    longitude: "27.82728020",
  },
  {
    id: 4693,
    name: "Gabrovo Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "07",
    type: null,
    latitude: "42.86847000",
    longitude: "25.31688900",
  },
  {
    id: 4704,
    name: "Haskovo Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "26",
    type: null,
    latitude: "41.93441780",
    longitude: "25.55546720",
  },
  {
    id: 4702,
    name: "Kardzhali Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "09",
    type: null,
    latitude: "41.63384160",
    longitude: "25.37766870",
  },
  {
    id: 4703,
    name: "Kyustendil Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "10",
    type: null,
    latitude: "42.28687990",
    longitude: "22.69396350",
  },
  {
    id: 4710,
    name: "Lovech Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "11",
    type: null,
    latitude: "43.13677980",
    longitude: "24.71393350",
  },
  {
    id: 4696,
    name: "Montana Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "12",
    type: null,
    latitude: "43.40851480",
    longitude: "23.22575890",
  },
  {
    id: 4712,
    name: "Pazardzhik Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "13",
    type: null,
    latitude: "42.19275670",
    longitude: "24.33362260",
  },
  {
    id: 4695,
    name: "Pernik Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "14",
    type: null,
    latitude: "42.60519900",
    longitude: "23.03779160",
  },
  {
    id: 4706,
    name: "Pleven Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "15",
    type: null,
    latitude: "43.41701690",
    longitude: "24.60667080",
  },
  {
    id: 4701,
    name: "Plovdiv Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "16",
    type: null,
    latitude: "42.13540790",
    longitude: "24.74529040",
  },
  {
    id: 4698,
    name: "Razgrad Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "17",
    type: null,
    latitude: "43.52717050",
    longitude: "26.52412280",
  },
  {
    id: 4713,
    name: "Ruse Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "18",
    type: null,
    latitude: "43.83559640",
    longitude: "25.96561440",
  },
  {
    id: 4882,
    name: "Shumen",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "27",
    type: null,
    latitude: "43.27123980",
    longitude: "26.93612860",
  },
  {
    id: 4708,
    name: "Silistra Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "19",
    type: null,
    latitude: "44.11471010",
    longitude: "27.26714540",
  },
  {
    id: 4700,
    name: "Sliven Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "20",
    type: null,
    latitude: "42.68167020",
    longitude: "26.32285690",
  },
  {
    id: 4694,
    name: "Smolyan Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "21",
    type: null,
    latitude: "41.57741480",
    longitude: "24.70108710",
  },
  {
    id: 4705,
    name: "Sofia City Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "22",
    type: null,
    latitude: "42.75701090",
    longitude: "23.45046830",
  },
  {
    id: 4719,
    name: "Sofia Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "23",
    type: null,
    latitude: "42.67344000",
    longitude: "23.83349370",
  },
  {
    id: 4707,
    name: "Stara Zagora Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "24",
    type: null,
    latitude: "42.42577090",
    longitude: "25.63448550",
  },
  {
    id: 4714,
    name: "Targovishte Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "25",
    type: null,
    latitude: "43.24623490",
    longitude: "26.56912510",
  },
  {
    id: 4717,
    name: "Varna Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "03",
    type: null,
    latitude: "43.20464770",
    longitude: "27.91054880",
  },
  {
    id: 4709,
    name: "Veliko Tarnovo Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "04",
    type: null,
    latitude: "43.07565390",
    longitude: "25.61715000",
  },
  {
    id: 4697,
    name: "Vidin Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "05",
    type: null,
    latitude: "43.99617390",
    longitude: "22.86795150",
  },
  {
    id: 4711,
    name: "Vratsa Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "06",
    type: null,
    latitude: "43.21018060",
    longitude: "23.55292100",
  },
  {
    id: 4716,
    name: "Yambol Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    state_code: "28",
    type: null,
    latitude: "42.48414940",
    longitude: "26.50352960",
  },
  {
    id: 3160,
    name: "Balé Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "BAL",
    type: null,
    latitude: "11.78206020",
    longitude: "-3.01757120",
  },
  {
    id: 3155,
    name: "Bam Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "BAM",
    type: null,
    latitude: "13.44613300",
    longitude: "-1.59839590",
  },
  {
    id: 3120,
    name: "Banwa Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "BAN",
    type: null,
    latitude: "12.13230530",
    longitude: "-4.15137640",
  },
  {
    id: 3152,
    name: "Bazèga Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "BAZ",
    type: null,
    latitude: "11.97676920",
    longitude: "-1.44346900",
  },
  {
    id: 3138,
    name: "Boucle du Mouhoun Region",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "01",
    type: null,
    latitude: "12.41660000",
    longitude: "-3.41955270",
  },
  {
    id: 3121,
    name: "Bougouriba Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "BGR",
    type: null,
    latitude: "10.87226460",
    longitude: "-3.33889170",
  },
  {
    id: 3131,
    name: "Boulgou",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "BLG",
    type: null,
    latitude: "11.43367660",
    longitude: "-0.37483540",
  },
  {
    id: 3153,
    name: "Cascades Region",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "02",
    type: null,
    latitude: "10.40729920",
    longitude: "-4.56244260",
  },
  {
    id: 3136,
    name: "Centre",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "03",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3162,
    name: "Centre-Est Region",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "04",
    type: null,
    latitude: "11.52476740",
    longitude: "-0.14949880",
  },
  {
    id: 3127,
    name: "Centre-Nord Region",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "05",
    type: null,
    latitude: "13.17244640",
    longitude: "-0.90566230",
  },
  {
    id: 3115,
    name: "Centre-Ouest Region",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "06",
    type: null,
    latitude: "11.87984660",
    longitude: "-2.30244600",
  },
  {
    id: 3149,
    name: "Centre-Sud Region",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "07",
    type: null,
    latitude: "11.52289110",
    longitude: "-1.05861350",
  },
  {
    id: 3167,
    name: "Comoé Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "COM",
    type: null,
    latitude: "10.40729920",
    longitude: "-4.56244260",
  },
  {
    id: 3158,
    name: "Est Region",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "08",
    type: null,
    latitude: "12.43655260",
    longitude: "0.90566230",
  },
  {
    id: 3148,
    name: "Ganzourgou Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "GAN",
    type: null,
    latitude: "12.25376480",
    longitude: "-0.75328090",
  },
  {
    id: 3122,
    name: "Gnagna Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "GNA",
    type: null,
    latitude: "12.89749920",
    longitude: "0.07467670",
  },
  {
    id: 3143,
    name: "Gourma Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "GOU",
    type: null,
    latitude: "12.16244730",
    longitude: "0.67730460",
  },
  {
    id: 3165,
    name: "Hauts-Bassins Region",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "09",
    type: null,
    latitude: "11.49420030",
    longitude: "-4.23333550",
  },
  {
    id: 3129,
    name: "Houet Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "HOU",
    type: null,
    latitude: "11.13204470",
    longitude: "-4.23333550",
  },
  {
    id: 3135,
    name: "Ioba Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "IOB",
    type: null,
    latitude: "11.05620340",
    longitude: "-3.01757120",
  },
  {
    id: 3168,
    name: "Kadiogo Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "KAD",
    type: null,
    latitude: "12.34258970",
    longitude: "-1.44346900",
  },
  {
    id: 3112,
    name: "Kénédougou Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "KEN",
    type: null,
    latitude: "11.39193950",
    longitude: "-4.97665400",
  },
  {
    id: 3132,
    name: "Komondjari Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "KMD",
    type: null,
    latitude: "12.71265270",
    longitude: "0.67730460",
  },
  {
    id: 3157,
    name: "Kompienga Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "KMP",
    type: null,
    latitude: "11.52383620",
    longitude: "0.75328090",
  },
  {
    id: 3146,
    name: "Kossi Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "KOS",
    type: null,
    latitude: "12.96045800",
    longitude: "-3.90626880",
  },
  {
    id: 3133,
    name: "Koulpélogo Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "KOP",
    type: null,
    latitude: "11.52476740",
    longitude: "0.14949880",
  },
  {
    id: 3161,
    name: "Kouritenga Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "KOT",
    type: null,
    latitude: "12.16318130",
    longitude: "-0.22446620",
  },
  {
    id: 3147,
    name: "Kourwéogo Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "KOW",
    type: null,
    latitude: "12.70774950",
    longitude: "-1.75388170",
  },
  {
    id: 3159,
    name: "Léraba Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "LER",
    type: null,
    latitude: "10.66487850",
    longitude: "-5.31025050",
  },
  {
    id: 3151,
    name: "Loroum Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "LOR",
    type: null,
    latitude: "13.81298140",
    longitude: "-2.06651970",
  },
  {
    id: 3123,
    name: "Mouhoun",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "MOU",
    type: null,
    latitude: "12.14323810",
    longitude: "-3.33889170",
  },
  {
    id: 3116,
    name: "Nahouri Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "NAO",
    type: null,
    latitude: "11.25022670",
    longitude: "-1.13530200",
  },
  {
    id: 3113,
    name: "Namentenga Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "NAM",
    type: null,
    latitude: "13.08125840",
    longitude: "-0.52578230",
  },
  {
    id: 3142,
    name: "Nayala Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "NAY",
    type: null,
    latitude: "12.69645580",
    longitude: "-3.01757120",
  },
  {
    id: 3164,
    name: "Nord Region, Burkina Faso",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "10",
    type: null,
    latitude: "13.71825200",
    longitude: "-2.30244600",
  },
  {
    id: 3156,
    name: "Noumbiel Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "NOU",
    type: null,
    latitude: "9.84409460",
    longitude: "-2.97755580",
  },
  {
    id: 3141,
    name: "Oubritenga Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "OUB",
    type: null,
    latitude: "12.70960870",
    longitude: "-1.44346900",
  },
  {
    id: 3144,
    name: "Oudalan Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "OUD",
    type: null,
    latitude: "14.47190200",
    longitude: "-0.45023680",
  },
  {
    id: 3117,
    name: "Passoré Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "PAS",
    type: null,
    latitude: "12.88812210",
    longitude: "-2.22366670",
  },
  {
    id: 3125,
    name: "Plateau-Central Region",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "11",
    type: null,
    latitude: "12.25376480",
    longitude: "-0.75328090",
  },
  {
    id: 3163,
    name: "Poni Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "PON",
    type: null,
    latitude: "10.33259960",
    longitude: "-3.33889170",
  },
  {
    id: 3114,
    name: "Sahel Region",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "12",
    type: null,
    latitude: "14.10008650",
    longitude: "-0.14949880",
  },
  {
    id: 3154,
    name: "Sanguié Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "SNG",
    type: null,
    latitude: "12.15018610",
    longitude: "-2.69838680",
  },
  {
    id: 3126,
    name: "Sanmatenga Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "SMT",
    type: null,
    latitude: "13.35653040",
    longitude: "-1.05861350",
  },
  {
    id: 3139,
    name: "Séno Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "SEN",
    type: null,
    latitude: "14.00722340",
    longitude: "-0.07467670",
  },
  {
    id: 3119,
    name: "Sissili Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "SIS",
    type: null,
    latitude: "11.24412190",
    longitude: "-2.22366670",
  },
  {
    id: 3166,
    name: "Soum Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "SOM",
    type: null,
    latitude: "14.09628410",
    longitude: "-1.36621600",
  },
  {
    id: 3137,
    name: "Sourou Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "SOR",
    type: null,
    latitude: "13.34180300",
    longitude: "-2.93757390",
  },
  {
    id: 3140,
    name: "Sud-Ouest Region",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "13",
    type: null,
    latitude: "10.42314930",
    longitude: "-3.25836260",
  },
  {
    id: 3128,
    name: "Tapoa Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "TAP",
    type: null,
    latitude: "12.24970720",
    longitude: "1.67606910",
  },
  {
    id: 3134,
    name: "Tuy Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "TUI",
    type: null,
    latitude: "38.88868400",
    longitude: "-77.00471900",
  },
  {
    id: 3124,
    name: "Yagha Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "YAG",
    type: null,
    latitude: "13.35761570",
    longitude: "0.75328090",
  },
  {
    id: 3150,
    name: "Yatenga Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "YAT",
    type: null,
    latitude: "13.62493440",
    longitude: "-2.38136210",
  },
  {
    id: 3145,
    name: "Ziro Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "ZIR",
    type: null,
    latitude: "11.60949950",
    longitude: "-1.90992380",
  },
  {
    id: 3130,
    name: "Zondoma Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "ZON",
    type: null,
    latitude: "13.11659260",
    longitude: "-2.42087130",
  },
  {
    id: 3118,
    name: "Zoundwéogo Province",
    country_id: 35,
    country_code: "BF",
    country_name: "Burkina Faso",
    state_code: "ZOU",
    type: null,
    latitude: "11.61411740",
    longitude: "-0.98206680",
  },
  {
    id: 3196,
    name: "Bubanza Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "BB",
    type: null,
    latitude: "-3.15724030",
    longitude: "29.37149090",
  },
  {
    id: 3198,
    name: "Bujumbura Mairie Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "BM",
    type: null,
    latitude: "-3.38841410",
    longitude: "29.34826460",
  },
  {
    id: 3200,
    name: "Bujumbura Rural Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "BL",
    type: null,
    latitude: "-3.50901440",
    longitude: "29.46435900",
  },
  {
    id: 3202,
    name: "Bururi Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "BR",
    type: null,
    latitude: "-3.90068510",
    longitude: "29.51077080",
  },
  {
    id: 3201,
    name: "Cankuzo Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "CA",
    type: null,
    latitude: "-3.15277880",
    longitude: "30.61998950",
  },
  {
    id: 3190,
    name: "Cibitoke Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "CI",
    type: null,
    latitude: "-2.81028970",
    longitude: "29.18557850",
  },
  {
    id: 3197,
    name: "Gitega Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "GI",
    type: null,
    latitude: "-3.49290510",
    longitude: "29.92779470",
  },
  {
    id: 3194,
    name: "Karuzi Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "KR",
    type: null,
    latitude: "-3.13403470",
    longitude: "30.11273500",
  },
  {
    id: 3192,
    name: "Kayanza Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "KY",
    type: null,
    latitude: "-3.00779810",
    longitude: "29.64991620",
  },
  {
    id: 3195,
    name: "Kirundo Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "KI",
    type: null,
    latitude: "-2.57628820",
    longitude: "30.11273500",
  },
  {
    id: 3188,
    name: "Makamba Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "MA",
    type: null,
    latitude: "-4.32570620",
    longitude: "29.69626770",
  },
  {
    id: 3193,
    name: "Muramvya Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "MU",
    type: null,
    latitude: "-3.28983980",
    longitude: "29.64991620",
  },
  {
    id: 3186,
    name: "Muyinga Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "MY",
    type: null,
    latitude: "-2.77935110",
    longitude: "30.29741990",
  },
  {
    id: 3187,
    name: "Mwaro Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "MW",
    type: null,
    latitude: "-3.50259180",
    longitude: "29.64991620",
  },
  {
    id: 3199,
    name: "Ngozi Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "NG",
    type: null,
    latitude: "-2.89582430",
    longitude: "29.88152030",
  },
  {
    id: 3185,
    name: "Rumonge Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "RM",
    type: null,
    latitude: "-3.97540490",
    longitude: "29.43880140",
  },
  {
    id: 3189,
    name: "Rutana Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "RT",
    type: null,
    latitude: "-3.87915230",
    longitude: "30.06652360",
  },
  {
    id: 3191,
    name: "Ruyigi Province",
    country_id: 36,
    country_code: "BI",
    country_name: "Burundi",
    state_code: "RY",
    type: null,
    latitude: "-3.44620700",
    longitude: "30.25127280",
  },
  {
    id: 3984,
    name: "Banteay Meanchey",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "1",
    type: "province",
    latitude: "13.75319140",
    longitude: "102.98961500",
  },
  {
    id: 3976,
    name: "Battambang",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "2",
    type: "province",
    latitude: "13.02869710",
    longitude: "102.98961500",
  },
  {
    id: 3991,
    name: "Kampong Cham",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "3",
    type: "province",
    latitude: "12.09829180",
    longitude: "105.31311850",
  },
  {
    id: 3979,
    name: "Kampong Chhnang",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "4",
    type: "province",
    latitude: "12.13923520",
    longitude: "104.56552730",
  },
  {
    id: 3988,
    name: "Kampong Speu",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "5",
    type: "province",
    latitude: "11.61551090",
    longitude: "104.37919120",
  },
  {
    id: 5070,
    name: "Kampong Thom",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "6",
    type: "province",
    latitude: "12.81674850",
    longitude: "103.84131040",
  },
  {
    id: 3981,
    name: "Kampot",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "7",
    type: "province",
    latitude: "10.73253510",
    longitude: "104.37919120",
  },
  {
    id: 3983,
    name: "Kandal",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "8",
    type: "province",
    latitude: "11.22373830",
    longitude: "105.12589550",
  },
  {
    id: 3978,
    name: "Kep",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "23",
    type: "province",
    latitude: "10.53608900",
    longitude: "104.35591580",
  },
  {
    id: 3982,
    name: "Koh Kong",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "9",
    type: "province",
    latitude: "11.57628040",
    longitude: "103.35872880",
  },
  {
    id: 3986,
    name: "Kratie",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "10",
    type: "province",
    latitude: "12.50436080",
    longitude: "105.96998780",
  },
  {
    id: 3985,
    name: "Mondulkiri",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "11",
    type: "province",
    latitude: "12.78794270",
    longitude: "107.10119310",
  },
  {
    id: 3987,
    name: "Oddar Meanchey",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "22",
    type: "province",
    latitude: "14.16097380",
    longitude: "103.82162610",
  },
  {
    id: 3980,
    name: "Pailin",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "24",
    type: "province",
    latitude: "12.90929620",
    longitude: "102.66755750",
  },
  {
    id: 3994,
    name: "Phnom Penh",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "12",
    type: "autonomous municipality",
    latitude: "11.55637380",
    longitude: "104.92820990",
  },
  {
    id: 3973,
    name: "Preah Vihear",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "13",
    type: "province",
    latitude: "14.00857970",
    longitude: "104.84546190",
  },
  {
    id: 3974,
    name: "Prey Veng",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "14",
    type: "province",
    latitude: "11.38024420",
    longitude: "105.50054830",
  },
  {
    id: 3977,
    name: "Pursat",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "15",
    type: "province",
    latitude: "12.27209560",
    longitude: "103.72891670",
  },
  {
    id: 3990,
    name: "Ratanakiri",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "16",
    type: "province",
    latitude: "13.85766070",
    longitude: "107.10119310",
  },
  {
    id: 3992,
    name: "Siem Reap",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "17",
    type: "province",
    latitude: "13.33026600",
    longitude: "104.10013260",
  },
  {
    id: 3989,
    name: "Sihanoukville",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "18",
    type: "province",
    latitude: "10.75818990",
    longitude: "103.82162610",
  },
  {
    id: 3993,
    name: "Stung Treng",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "19",
    type: "province",
    latitude: "13.57647300",
    longitude: "105.96998780",
  },
  {
    id: 3972,
    name: "Svay Rieng",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "20",
    type: "province",
    latitude: "11.14272200",
    longitude: "105.82902980",
  },
  {
    id: 3975,
    name: "Takeo",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    state_code: "21",
    type: "province",
    latitude: "10.93215190",
    longitude: "104.79877100",
  },
  {
    id: 2663,
    name: "Adamawa",
    country_id: 38,
    country_code: "CM",
    country_name: "Cameroon",
    state_code: "AD",
    type: null,
    latitude: "9.32647510",
    longitude: "12.39838530",
  },
  {
    id: 2660,
    name: "Centre",
    country_id: 38,
    country_code: "CM",
    country_name: "Cameroon",
    state_code: "CE",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2661,
    name: "East",
    country_id: 38,
    country_code: "CM",
    country_name: "Cameroon",
    state_code: "ES",
    type: null,
    latitude: "39.01853360",
    longitude: "-94.27924110",
  },
  {
    id: 2656,
    name: "Far North",
    country_id: 38,
    country_code: "CM",
    country_name: "Cameroon",
    state_code: "EN",
    type: null,
    latitude: "66.76134510",
    longitude: "124.12375300",
  },
  {
    id: 2662,
    name: "Littoral",
    country_id: 38,
    country_code: "CM",
    country_name: "Cameroon",
    state_code: "LT",
    type: null,
    latitude: "48.46227570",
    longitude: "-68.51780710",
  },
  {
    id: 2665,
    name: "North",
    country_id: 38,
    country_code: "CM",
    country_name: "Cameroon",
    state_code: "NO",
    type: null,
    latitude: "37.09024000",
    longitude: "-95.71289100",
  },
  {
    id: 2657,
    name: "Northwest",
    country_id: 38,
    country_code: "CM",
    country_name: "Cameroon",
    state_code: "NW",
    type: null,
    latitude: "36.37118570",
    longitude: "-94.19346060",
  },
  {
    id: 2659,
    name: "South",
    country_id: 38,
    country_code: "CM",
    country_name: "Cameroon",
    state_code: "SU",
    type: null,
    latitude: "37.63159500",
    longitude: "-97.34584090",
  },
  {
    id: 2658,
    name: "Southwest",
    country_id: 38,
    country_code: "CM",
    country_name: "Cameroon",
    state_code: "SW",
    type: null,
    latitude: "36.19088130",
    longitude: "-95.88974480",
  },
  {
    id: 2664,
    name: "West",
    country_id: 38,
    country_code: "CM",
    country_name: "Cameroon",
    state_code: "OU",
    type: null,
    latitude: "37.03649890",
    longitude: "-95.67059870",
  },
  {
    id: 872,
    name: "Alberta",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    state_code: "AB",
    type: "province",
    latitude: "53.93327060",
    longitude: "-116.57650350",
  },
  {
    id: 875,
    name: "British Columbia",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    state_code: "BC",
    type: "province",
    latitude: "53.72666830",
    longitude: "-127.64762050",
  },
  {
    id: 867,
    name: "Manitoba",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    state_code: "MB",
    type: "province",
    latitude: "53.76086080",
    longitude: "-98.81387620",
  },
  {
    id: 868,
    name: "New Brunswick",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    state_code: "NB",
    type: "province",
    latitude: "46.56531630",
    longitude: "-66.46191640",
  },
  {
    id: 877,
    name: "Newfoundland and Labrador",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    state_code: "NL",
    type: "province",
    latitude: "53.13550910",
    longitude: "-57.66043640",
  },
  {
    id: 878,
    name: "Northwest Territories",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    state_code: "NT",
    type: "territory",
    latitude: "64.82554410",
    longitude: "-124.84573340",
  },
  {
    id: 874,
    name: "Nova Scotia",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    state_code: "NS",
    type: "province",
    latitude: "44.68198660",
    longitude: "-63.74431100",
  },
  {
    id: 876,
    name: "Nunavut",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    state_code: "NU",
    type: "territory",
    latitude: "70.29977110",
    longitude: "-83.10757700",
  },
  {
    id: 866,
    name: "Ontario",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    state_code: "ON",
    type: "province",
    latitude: "51.25377500",
    longitude: "-85.32321400",
  },
  {
    id: 871,
    name: "Prince Edward Island",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    state_code: "PE",
    type: "province",
    latitude: "46.51071200",
    longitude: "-63.41681360",
  },
  {
    id: 873,
    name: "Quebec",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    state_code: "QC",
    type: "province",
    latitude: "52.93991590",
    longitude: "-73.54913610",
  },
  {
    id: 870,
    name: "Saskatchewan",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    state_code: "SK",
    type: "province",
    latitude: "52.93991590",
    longitude: "-106.45086390",
  },
  {
    id: 869,
    name: "Yukon",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    state_code: "YT",
    type: "territory",
    latitude: "35.50672150",
    longitude: "-97.76254410",
  },
  {
    id: 2994,
    name: "Barlavento Islands",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "B",
    type: null,
    latitude: "16.82368450",
    longitude: "-23.99348810",
  },
  {
    id: 2999,
    name: "Boa Vista",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "BV",
    type: null,
    latitude: "38.74346600",
    longitude: "-120.73042970",
  },
  {
    id: 2996,
    name: "Brava",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "BR",
    type: null,
    latitude: "40.98977780",
    longitude: "-73.68357150",
  },
  {
    id: 2991,
    name: "Maio Municipality",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "MA",
    type: null,
    latitude: "15.20030980",
    longitude: "-23.16797930",
  },
  {
    id: 2987,
    name: "Mosteiros",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "MO",
    type: null,
    latitude: "37.89043480",
    longitude: "-25.82075560",
  },
  {
    id: 2997,
    name: "Paul",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "PA",
    type: null,
    latitude: "37.06250000",
    longitude: "-95.67706800",
  },
  {
    id: 2989,
    name: "Porto Novo",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "PN",
    type: null,
    latitude: "6.49685740",
    longitude: "2.62885230",
  },
  {
    id: 2988,
    name: "Praia",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "PR",
    type: null,
    latitude: "14.93305000",
    longitude: "-23.51332670",
  },
  {
    id: 2982,
    name: "Ribeira Brava Municipality",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "RB",
    type: null,
    latitude: "16.60707390",
    longitude: "-24.20338430",
  },
  {
    id: 3002,
    name: "Ribeira Grande",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "RG",
    type: null,
    latitude: "37.82103690",
    longitude: "-25.51481370",
  },
  {
    id: 2984,
    name: "Ribeira Grande de Santiago",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "RS",
    type: null,
    latitude: "14.98302980",
    longitude: "-23.65617250",
  },
  {
    id: 2998,
    name: "Sal",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "SL",
    type: null,
    latitude: "26.59581220",
    longitude: "-80.20450830",
  },
  {
    id: 2985,
    name: "Santa Catarina",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "CA",
    type: null,
    latitude: "-27.24233920",
    longitude: "-50.21885560",
  },
  {
    id: 2995,
    name: "Santa Catarina do Fogo",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "CF",
    type: null,
    latitude: "14.93091040",
    longitude: "-24.32225770",
  },
  {
    id: 3004,
    name: "Santa Cruz",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "CR",
    type: null,
    latitude: "36.97411710",
    longitude: "-122.03079630",
  },
  {
    id: 2986,
    name: "São Domingos",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "SD",
    type: null,
    latitude: "15.02861650",
    longitude: "-23.56392200",
  },
  {
    id: 3000,
    name: "São Filipe",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "SF",
    type: null,
    latitude: "14.89516790",
    longitude: "-24.49456360",
  },
  {
    id: 2993,
    name: "São Lourenço dos Órgãos",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "SO",
    type: null,
    latitude: "15.05378410",
    longitude: "-23.60856120",
  },
  {
    id: 2990,
    name: "São Miguel",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "SM",
    type: null,
    latitude: "37.78041100",
    longitude: "-25.49704660",
  },
  {
    id: 3001,
    name: "São Vicente",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "SV",
    type: null,
    latitude: "-23.96071570",
    longitude: "-46.39620220",
  },
  {
    id: 2992,
    name: "Sotavento Islands",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "S",
    type: null,
    latitude: "15.00000000",
    longitude: "-24.00000000",
  },
  {
    id: 2983,
    name: "Tarrafal",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "TA",
    type: null,
    latitude: "15.27605780",
    longitude: "-23.74840770",
  },
  {
    id: 3003,
    name: "Tarrafal de São Nicolau",
    country_id: 40,
    country_code: "CV",
    country_name: "Cape Verde",
    state_code: "TS",
    type: null,
    latitude: "16.56364980",
    longitude: "-24.35494200",
  },
  {
    id: 1259,
    name: "Bamingui-Bangoran Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "BB",
    type: null,
    latitude: "8.27334550",
    longitude: "20.71224650",
  },
  {
    id: 1262,
    name: "Bangui",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "BGF",
    type: null,
    latitude: "4.39467350",
    longitude: "18.55818990",
  },
  {
    id: 1264,
    name: "Basse-Kotto Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "BK",
    type: null,
    latitude: "4.87193190",
    longitude: "21.28450250",
  },
  {
    id: 1258,
    name: "Haut-Mbomou Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "HM",
    type: null,
    latitude: "6.25371340",
    longitude: "25.47335540",
  },
  {
    id: 1268,
    name: "Haute-Kotto Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "HK",
    type: null,
    latitude: "7.79643790",
    longitude: "23.38235450",
  },
  {
    id: 1263,
    name: "Kémo Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "KG",
    type: null,
    latitude: "5.88677940",
    longitude: "19.37832060",
  },
  {
    id: 1256,
    name: "Lobaye Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "LB",
    type: null,
    latitude: "4.35259810",
    longitude: "17.47951730",
  },
  {
    id: 1257,
    name: "Mambéré-Kadéï",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "HS",
    type: null,
    latitude: "4.70556530",
    longitude: "15.96998780",
  },
  {
    id: 1266,
    name: "Mbomou Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "MB",
    type: null,
    latitude: "5.55683700",
    longitude: "23.76328280",
  },
  {
    id: 1253,
    name: "Nana-Grébizi Economic Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "KB",
    type: null,
    latitude: "7.18486070",
    longitude: "19.37832060",
  },
  {
    id: 1260,
    name: "Nana-Mambéré Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "NM",
    type: null,
    latitude: "5.69321350",
    longitude: "15.21948080",
  },
  {
    id: 1255,
    name: "Ombella-M'Poko Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "MP",
    type: null,
    latitude: "5.11888250",
    longitude: "18.42760470",
  },
  {
    id: 1265,
    name: "Ouaka Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "UK",
    type: null,
    latitude: "6.31682160",
    longitude: "20.71224650",
  },
  {
    id: 1254,
    name: "Ouham Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "AC",
    type: null,
    latitude: "7.09091100",
    longitude: "17.66888700",
  },
  {
    id: 1267,
    name: "Ouham-Pendé Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "OP",
    type: null,
    latitude: "6.48509840",
    longitude: "16.15809370",
  },
  {
    id: 1252,
    name: "Sangha-Mbaéré",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "SE",
    type: null,
    latitude: "3.43686070",
    longitude: "16.34637910",
  },
  {
    id: 1261,
    name: "Vakaga Prefecture",
    country_id: 42,
    country_code: "CF",
    country_name: "Central African Republic",
    state_code: "VK",
    type: null,
    latitude: "9.51132960",
    longitude: "22.23840170",
  },
  {
    id: 3583,
    name: "Bahr el Gazel",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "BG",
    type: "province",
    latitude: "14.77022660",
    longitude: "16.91225100",
  },
  {
    id: 3590,
    name: "Batha",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "BA",
    type: "province",
    latitude: "13.93717750",
    longitude: "18.42760470",
  },
  {
    id: 3574,
    name: "Borkou",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "BO",
    type: "province",
    latitude: "17.86888450",
    longitude: "18.80761950",
  },
  {
    id: 5114,
    name: "Chari-Baguirmi",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "CB",
    type: "province",
    latitude: "11.46186260",
    longitude: "15.24463940",
  },
  {
    id: 3575,
    name: "Ennedi-Est",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "EE",
    type: "province",
    latitude: "16.34204960",
    longitude: "23.00119890",
  },
  {
    id: 3584,
    name: "Ennedi-Ouest",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "EO",
    type: "province",
    latitude: "18.97756300",
    longitude: "21.85685860",
  },
  {
    id: 3576,
    name: "Guéra",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "GR",
    type: "province",
    latitude: "11.12190150",
    longitude: "18.42760470",
  },
  {
    id: 3573,
    name: "Hadjer-Lamis",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "HL",
    type: "province",
    latitude: "12.45772730",
    longitude: "16.72346390",
  },
  {
    id: 3588,
    name: "Kanem",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "KA",
    type: "province",
    latitude: "14.87812620",
    longitude: "15.40680790",
  },
  {
    id: 3577,
    name: "Lac",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "LC",
    type: "province",
    latitude: "13.69153770",
    longitude: "14.10013260",
  },
  {
    id: 3585,
    name: "Logone Occidental",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "LO",
    type: "province",
    latitude: "8.75967600",
    longitude: "15.87600400",
  },
  {
    id: 3591,
    name: "Logone Oriental",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "LR",
    type: "province",
    latitude: "8.31499490",
    longitude: "16.34637910",
  },
  {
    id: 3589,
    name: "Mandoul",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "MA",
    type: "province",
    latitude: "8.60309100",
    longitude: "17.47951730",
  },
  {
    id: 3580,
    name: "Mayo-Kebbi Est",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "ME",
    type: "province",
    latitude: "9.40460390",
    longitude: "14.84546190",
  },
  {
    id: 3571,
    name: "Mayo-Kebbi Ouest",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "MO",
    type: "province",
    latitude: "10.41130140",
    longitude: "15.59433880",
  },
  {
    id: 3570,
    name: "Moyen-Chari",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "MC",
    type: "province",
    latitude: "9.06399980",
    longitude: "18.42760470",
  },
  {
    id: 3586,
    name: "N'Djamena",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "ND",
    type: "province",
    latitude: "12.13484570",
    longitude: "15.05574150",
  },
  {
    id: 3582,
    name: "Ouaddaï",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "OD",
    type: "province",
    latitude: "13.74847600",
    longitude: "20.71224650",
  },
  {
    id: 3592,
    name: "Salamat",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "SA",
    type: "province",
    latitude: "10.96916010",
    longitude: "20.71224650",
  },
  {
    id: 3572,
    name: "Sila",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "SI",
    type: "province",
    latitude: "12.13074000",
    longitude: "21.28450250",
  },
  {
    id: 3579,
    name: "Tandjilé",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "TA",
    type: "province",
    latitude: "9.66257290",
    longitude: "16.72346390",
  },
  {
    id: 3587,
    name: "Tibesti",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "TI",
    type: "province",
    latitude: "21.36500310",
    longitude: "16.91225100",
  },
  {
    id: 3581,
    name: "Wadi Fira",
    country_id: 43,
    country_code: "TD",
    country_name: "Chad",
    state_code: "WF",
    type: "province",
    latitude: "15.08924160",
    longitude: "21.47528510",
  },
  {
    id: 2828,
    name: "Aisén del General Carlos Ibañez del Campo",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "AI",
    type: null,
    latitude: "-46.37834500",
    longitude: "-72.30076230",
  },
  {
    id: 2832,
    name: "Antofagasta",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "AN",
    type: null,
    latitude: "-23.83691040",
    longitude: "-69.28775350",
  },
  {
    id: 2829,
    name: "Arica y Parinacota",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "AP",
    type: null,
    latitude: "-18.59404850",
    longitude: "-69.47845410",
  },
  {
    id: 2823,
    name: "Atacama",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "AT",
    type: null,
    latitude: "-27.56605580",
    longitude: "-70.05031400",
  },
  {
    id: 2827,
    name: "Biobío",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "BI",
    type: null,
    latitude: "-37.44644280",
    longitude: "-72.14161320",
  },
  {
    id: 2825,
    name: "Coquimbo",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "CO",
    type: null,
    latitude: "-30.54018100",
    longitude: "-70.81199530",
  },
  {
    id: 2826,
    name: "La Araucanía",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "AR",
    type: null,
    latitude: "-38.94892100",
    longitude: "-72.33111300",
  },
  {
    id: 2838,
    name: "Libertador General Bernardo O'Higgins",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "LI",
    type: null,
    latitude: "-34.57553740",
    longitude: "-71.00223110",
  },
  {
    id: 2835,
    name: "Los Lagos",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "LL",
    type: null,
    latitude: "-41.91977790",
    longitude: "-72.14161320",
  },
  {
    id: 2834,
    name: "Los Ríos",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "LR",
    type: null,
    latitude: "-40.23102170",
    longitude: "-72.33111300",
  },
  {
    id: 2836,
    name: "Magallanes y de la Antártica Chilena",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "MA",
    type: null,
    latitude: "-52.20643160",
    longitude: "-72.16850010",
  },
  {
    id: 2833,
    name: "Maule",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "ML",
    type: null,
    latitude: "-35.51636030",
    longitude: "-71.57239530",
  },
  {
    id: 2831,
    name: "Ñuble",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "NB",
    type: null,
    latitude: "-36.72257430",
    longitude: "-71.76224810",
  },
  {
    id: 2824,
    name: "Región Metropolitana de Santiago",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "RM",
    type: null,
    latitude: "-33.43755450",
    longitude: "-70.65048960",
  },
  {
    id: 2837,
    name: "Tarapacá",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "TA",
    type: null,
    latitude: "-20.20287990",
    longitude: "-69.28775350",
  },
  {
    id: 2830,
    name: "Valparaíso",
    country_id: 44,
    country_code: "CL",
    country_name: "Chile",
    state_code: "VS",
    type: null,
    latitude: "-33.04723800",
    longitude: "-71.61268850",
  },
  {
    id: 2251,
    name: "Anhui",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "AH",
    type: "province",
    latitude: "30.60067730",
    longitude: "117.92490020",
  },
  {
    id: 2257,
    name: "Beijing",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "BJ",
    type: "municipality",
    latitude: "39.90419990",
    longitude: "116.40739630",
  },
  {
    id: 2271,
    name: "Chongqing",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "CQ",
    type: "municipality",
    latitude: "29.43158610",
    longitude: "106.91225100",
  },
  {
    id: 2248,
    name: "Fujian",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "FJ",
    type: "province",
    latitude: "26.48368420",
    longitude: "117.92490020",
  },
  {
    id: 2275,
    name: "Gansu",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "GS",
    type: "province",
    latitude: "35.75183260",
    longitude: "104.28611160",
  },
  {
    id: 2279,
    name: "Guangdong",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "GD",
    type: "province",
    latitude: "23.37903330",
    longitude: "113.76328280",
  },
  {
    id: 2278,
    name: "Guangxi Zhuang",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "GX",
    type: "autonomous region",
    latitude: "23.72475990",
    longitude: "108.80761950",
  },
  {
    id: 2261,
    name: "Guizhou",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "GZ",
    type: "province",
    latitude: "26.84296450",
    longitude: "107.29028390",
  },
  {
    id: 2273,
    name: "Hainan",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "HI",
    type: "province",
    latitude: "19.56639470",
    longitude: "109.94968600",
  },
  {
    id: 2280,
    name: "Hebei",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "HE",
    type: "province",
    latitude: "37.89565940",
    longitude: "114.90422080",
  },
  {
    id: 2265,
    name: "Heilongjiang",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "HL",
    type: "province",
    latitude: "47.12164720",
    longitude: "128.73823100",
  },
  {
    id: 2259,
    name: "Henan",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "HA",
    type: "province",
    latitude: "34.29043020",
    longitude: "113.38235450",
  },
  {
    id: 2267,
    name: "Hong Kong SAR",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "HK",
    type: "special administrative region",
    latitude: "22.31930390",
    longitude: "114.16936110",
  },
  {
    id: 2274,
    name: "Hubei",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "HB",
    type: "province",
    latitude: "30.73781180",
    longitude: "112.23840170",
  },
  {
    id: 2258,
    name: "Hunan",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "HN",
    type: "province",
    latitude: "27.36830090",
    longitude: "109.28193470",
  },
  {
    id: 2269,
    name: "Inner Mongolia",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "NM",
    type: "autonomous region",
    latitude: "43.37822000",
    longitude: "115.05948150",
  },
  {
    id: 2250,
    name: "Jiangsu",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "JS",
    type: "province",
    latitude: "33.14017150",
    longitude: "119.78892480",
  },
  {
    id: 2256,
    name: "Jiangxi",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "JX",
    type: "province",
    latitude: "27.08745640",
    longitude: "114.90422080",
  },
  {
    id: 2253,
    name: "Jilin",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "JL",
    type: "province",
    latitude: "43.83788300",
    longitude: "126.54957200",
  },
  {
    id: 2268,
    name: "Liaoning",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "LN",
    type: "province",
    latitude: "41.94365430",
    longitude: "122.52903760",
  },
  {
    id: 2266,
    name: "Macau SAR",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "MO",
    type: "special administrative region",
    latitude: "22.19874500",
    longitude: "113.54387300",
  },
  {
    id: 2262,
    name: "Ningxia Huizu",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "NX",
    type: "autonomous region",
    latitude: "37.19873100",
    longitude: "106.15809370",
  },
  {
    id: 2270,
    name: "Qinghai",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "QH",
    type: "province",
    latitude: "35.74479800",
    longitude: "96.40773580",
  },
  {
    id: 2272,
    name: "Shaanxi",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "SN",
    type: "province",
    latitude: "35.39399080",
    longitude: "109.18800470",
  },
  {
    id: 2252,
    name: "Shandong",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "SD",
    type: "province",
    latitude: "37.80060640",
    longitude: "-122.26999180",
  },
  {
    id: 2249,
    name: "Shanghai",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "SH",
    type: "municipality",
    latitude: "31.23041600",
    longitude: "121.47370100",
  },
  {
    id: 2254,
    name: "Shanxi",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "SX",
    type: "province",
    latitude: "37.24256490",
    longitude: "111.85685860",
  },
  {
    id: 2277,
    name: "Sichuan",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "SC",
    type: "province",
    latitude: "30.26380320",
    longitude: "102.80547530",
  },
  {
    id: 2255,
    name: "Taiwan",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "TW",
    type: "province",
    latitude: "23.69781000",
    longitude: "120.96051500",
  },
  {
    id: 2276,
    name: "Tianjin",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "TJ",
    type: "municipality",
    latitude: "39.12522910",
    longitude: "117.01534350",
  },
  {
    id: 2263,
    name: "Xinjiang",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "XJ",
    type: "autonomous region",
    latitude: "42.52463570",
    longitude: "87.53958550",
  },
  {
    id: 2264,
    name: "Xizang",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "XZ",
    type: "autonomous region",
    latitude: "30.15336050",
    longitude: "88.78786780",
  },
  {
    id: 2260,
    name: "Yunnan",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "YN",
    type: "province",
    latitude: "24.47528470",
    longitude: "101.34310580",
  },
  {
    id: 2247,
    name: "Zhejiang",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    state_code: "ZJ",
    type: "province",
    latitude: "29.14164320",
    longitude: "119.78892480",
  },
  {
    id: 2895,
    name: "Amazonas",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "AMA",
    type: null,
    latitude: "-1.44291230",
    longitude: "-71.57239530",
  },
  {
    id: 2890,
    name: "Antioquia",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "ANT",
    type: null,
    latitude: "7.19860640",
    longitude: "-75.34121790",
  },
  {
    id: 2881,
    name: "Arauca",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "ARA",
    type: null,
    latitude: "6.54730600",
    longitude: "-71.00223110",
  },
  {
    id: 2900,
    name: "Archipiélago de San Andrés, Providencia y Santa Catalina",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "SAP",
    type: null,
    latitude: "12.55673240",
    longitude: "-81.71852530",
  },
  {
    id: 2880,
    name: "Atlántico",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "ATL",
    type: null,
    latitude: "10.69661590",
    longitude: "-74.87410450",
  },
  {
    id: 4921,
    name: "Bogotá D.C.",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "DC",
    type: "capital district",
    latitude: "4.28204150",
    longitude: "-74.50270420",
  },
  {
    id: 2893,
    name: "Bolívar",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "BOL",
    type: null,
    latitude: "8.67043820",
    longitude: "-74.03001220",
  },
  {
    id: 2903,
    name: "Boyacá",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "BOY",
    type: null,
    latitude: "5.45451100",
    longitude: "-73.36200300",
  },
  {
    id: 2887,
    name: "Caldas",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "CAL",
    type: null,
    latitude: "5.29826000",
    longitude: "-75.24790610",
  },
  {
    id: 2891,
    name: "Caquetá",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "CAQ",
    type: null,
    latitude: "0.86989200",
    longitude: "-73.84190630",
  },
  {
    id: 2892,
    name: "Casanare",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "CAS",
    type: null,
    latitude: "5.75892690",
    longitude: "-71.57239530",
  },
  {
    id: 2884,
    name: "Cauca",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "CAU",
    type: null,
    latitude: "2.70498130",
    longitude: "-76.82596520",
  },
  {
    id: 2899,
    name: "Cesar",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "CES",
    type: null,
    latitude: "9.33729480",
    longitude: "-73.65362090",
  },
  {
    id: 2876,
    name: "Chocó",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "CHO",
    type: null,
    latitude: "5.25280330",
    longitude: "-76.82596520",
  },
  {
    id: 2898,
    name: "Córdoba",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "COR",
    type: null,
    latitude: "8.04929300",
    longitude: "-75.57405000",
  },
  {
    id: 2875,
    name: "Cundinamarca",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "CUN",
    type: null,
    latitude: "5.02600300",
    longitude: "-74.03001220",
  },
  {
    id: 2882,
    name: "Guainía",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "GUA",
    type: null,
    latitude: "2.58539300",
    longitude: "-68.52471490",
  },
  {
    id: 2888,
    name: "Guaviare",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "GUV",
    type: null,
    latitude: "2.04392400",
    longitude: "-72.33111300",
  },
  {
    id: 4871,
    name: "Huila",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "HUI",
    type: "department",
    latitude: "2.53593490",
    longitude: "-75.52766990",
  },
  {
    id: 2889,
    name: "La Guajira",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "LAG",
    type: null,
    latitude: "11.35477430",
    longitude: "-72.52048270",
  },
  {
    id: 2886,
    name: "Magdalena",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "MAG",
    type: null,
    latitude: "10.41130140",
    longitude: "-74.40566120",
  },
  {
    id: 2878,
    name: "Meta",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "MET",
    type: null,
    latitude: "39.76732580",
    longitude: "-104.97535950",
  },
  {
    id: 2897,
    name: "Nariño",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "NAR",
    type: null,
    latitude: "1.28915100",
    longitude: "-77.35794000",
  },
  {
    id: 2877,
    name: "Norte de Santander",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "NSA",
    type: null,
    latitude: "7.94628310",
    longitude: "-72.89880690",
  },
  {
    id: 2896,
    name: "Putumayo",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "PUT",
    type: null,
    latitude: "0.43595060",
    longitude: "-75.52766990",
  },
  {
    id: 2874,
    name: "Quindío",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "QUI",
    type: null,
    latitude: "4.46101910",
    longitude: "-75.66735600",
  },
  {
    id: 2879,
    name: "Risaralda",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "RIS",
    type: null,
    latitude: "5.31584750",
    longitude: "-75.99276520",
  },
  {
    id: 2901,
    name: "Santander",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "SAN",
    type: null,
    latitude: "6.64370760",
    longitude: "-73.65362090",
  },
  {
    id: 2902,
    name: "Sucre",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "SUC",
    type: null,
    latitude: "8.81397700",
    longitude: "-74.72328300",
  },
  {
    id: 2883,
    name: "Tolima",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "TOL",
    type: null,
    latitude: "4.09251680",
    longitude: "-75.15453810",
  },
  {
    id: 2904,
    name: "Valle del Cauca",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "VAC",
    type: null,
    latitude: "3.80088930",
    longitude: "-76.64127120",
  },
  {
    id: 2885,
    name: "Vaupés",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "VAU",
    type: null,
    latitude: "0.85535610",
    longitude: "-70.81199530",
  },
  {
    id: 2894,
    name: "Vichada",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    state_code: "VID",
    type: null,
    latitude: "4.42344520",
    longitude: "-69.28775350",
  },
  {
    id: 2821,
    name: "Anjouan",
    country_id: 49,
    country_code: "KM",
    country_name: "Comoros",
    state_code: "A",
    type: null,
    latitude: "-12.21381450",
    longitude: "44.43706060",
  },
  {
    id: 2822,
    name: "Grande Comore",
    country_id: 49,
    country_code: "KM",
    country_name: "Comoros",
    state_code: "G",
    type: null,
    latitude: "-11.71673380",
    longitude: "43.36807880",
  },
  {
    id: 2820,
    name: "Mohéli",
    country_id: 49,
    country_code: "KM",
    country_name: "Comoros",
    state_code: "M",
    type: null,
    latitude: "-12.33773760",
    longitude: "43.73340890",
  },
  {
    id: 2866,
    name: "Bouenza Department",
    country_id: 50,
    country_code: "CG",
    country_name: "Congo",
    state_code: "11",
    type: null,
    latitude: "-4.11280790",
    longitude: "13.72891670",
  },
  {
    id: 2870,
    name: "Brazzaville",
    country_id: 50,
    country_code: "CG",
    country_name: "Congo",
    state_code: "BZV",
    type: null,
    latitude: "-4.26335970",
    longitude: "15.24288530",
  },
  {
    id: 2864,
    name: "Cuvette Department",
    country_id: 50,
    country_code: "CG",
    country_name: "Congo",
    state_code: "8",
    type: null,
    latitude: "-0.28774460",
    longitude: "16.15809370",
  },
  {
    id: 2869,
    name: "Cuvette-Ouest Department",
    country_id: 50,
    country_code: "CG",
    country_name: "Congo",
    state_code: "15",
    type: null,
    latitude: "0.14475500",
    longitude: "14.47233010",
  },
  {
    id: 2867,
    name: "Kouilou Department",
    country_id: 50,
    country_code: "CG",
    country_name: "Congo",
    state_code: "5",
    type: null,
    latitude: "-4.14284130",
    longitude: "11.88917210",
  },
  {
    id: 2868,
    name: "Lékoumou Department",
    country_id: 50,
    country_code: "CG",
    country_name: "Congo",
    state_code: "2",
    type: null,
    latitude: "-3.17038200",
    longitude: "13.35872880",
  },
  {
    id: 2865,
    name: "Likouala Department",
    country_id: 50,
    country_code: "CG",
    country_name: "Congo",
    state_code: "7",
    type: null,
    latitude: "2.04392400",
    longitude: "17.66888700",
  },
  {
    id: 2872,
    name: "Niari Department",
    country_id: 50,
    country_code: "CG",
    country_name: "Congo",
    state_code: "9",
    type: null,
    latitude: "-3.18427000",
    longitude: "12.25479190",
  },
  {
    id: 2862,
    name: "Plateaux Department",
    country_id: 50,
    country_code: "CG",
    country_name: "Congo",
    state_code: "14",
    type: null,
    latitude: "-2.06800880",
    longitude: "15.40680790",
  },
  {
    id: 2863,
    name: "Pointe-Noire",
    country_id: 50,
    country_code: "CG",
    country_name: "Congo",
    state_code: "16",
    type: null,
    latitude: "-4.76916230",
    longitude: "11.86636200",
  },
  {
    id: 2873,
    name: "Pool Department",
    country_id: 50,
    country_code: "CG",
    country_name: "Congo",
    state_code: "12",
    type: null,
    latitude: "-3.77626280",
    longitude: "14.84546190",
  },
  {
    id: 2871,
    name: "Sangha Department",
    country_id: 50,
    country_code: "CG",
    country_name: "Congo",
    state_code: "13",
    type: null,
    latitude: "1.46623280",
    longitude: "15.40680790",
  },
  {
    id: 1215,
    name: "Alajuela Province",
    country_id: 53,
    country_code: "CR",
    country_name: "Costa Rica",
    state_code: "A",
    type: null,
    latitude: "10.39158300",
    longitude: "-84.43827210",
  },
  {
    id: 1209,
    name: "Guanacaste Province",
    country_id: 53,
    country_code: "CR",
    country_name: "Costa Rica",
    state_code: "G",
    type: null,
    latitude: "10.62673990",
    longitude: "-85.44367060",
  },
  {
    id: 1212,
    name: "Heredia Province",
    country_id: 53,
    country_code: "CR",
    country_name: "Costa Rica",
    state_code: "H",
    type: null,
    latitude: "10.47352300",
    longitude: "-84.01674230",
  },
  {
    id: 1213,
    name: "Limón Province",
    country_id: 53,
    country_code: "CR",
    country_name: "Costa Rica",
    state_code: "L",
    type: null,
    latitude: "9.98963980",
    longitude: "-83.03324170",
  },
  {
    id: 1211,
    name: "Provincia de Cartago",
    country_id: 53,
    country_code: "CR",
    country_name: "Costa Rica",
    state_code: "C",
    type: null,
    latitude: "9.86223110",
    longitude: "-83.92141870",
  },
  {
    id: 1210,
    name: "Puntarenas Province",
    country_id: 53,
    country_code: "CR",
    country_name: "Costa Rica",
    state_code: "P",
    type: null,
    latitude: "9.21695310",
    longitude: "-83.33618800",
  },
  {
    id: 1214,
    name: "San José Province",
    country_id: 53,
    country_code: "CR",
    country_name: "Costa Rica",
    state_code: "SJ",
    type: null,
    latitude: "9.91297270",
    longitude: "-84.07682940",
  },
  {
    id: 2634,
    name: "Abidjan",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "AB",
    type: null,
    latitude: "5.35995170",
    longitude: "-4.00825630",
  },
  {
    id: 2626,
    name: "Agnéby",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "16",
    type: null,
    latitude: "5.32245030",
    longitude: "-4.34495290",
  },
  {
    id: 2636,
    name: "Bafing Region",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "17",
    type: null,
    latitude: "8.32520470",
    longitude: "-7.52472430",
  },
  {
    id: 2643,
    name: "Bas-Sassandra District",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "BS",
    type: null,
    latitude: "5.27983560",
    longitude: "-6.15269850",
  },
  {
    id: 2635,
    name: "Bas-Sassandra Region",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "09",
    type: null,
    latitude: "5.35679160",
    longitude: "-6.74939930",
  },
  {
    id: 2654,
    name: "Comoé District",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "CM",
    type: null,
    latitude: "5.55279300",
    longitude: "-3.25836260",
  },
  {
    id: 2644,
    name: "Denguélé District",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "DN",
    type: null,
    latitude: "48.07077630",
    longitude: "-68.56093410",
  },
  {
    id: 2642,
    name: "Denguélé Region",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "10",
    type: null,
    latitude: "9.46623720",
    longitude: "-7.43813550",
  },
  {
    id: 2645,
    name: "Dix-Huit Montagnes",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "06",
    type: null,
    latitude: "7.37623730",
    longitude: "-7.43813550",
  },
  {
    id: 2633,
    name: "Fromager",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "18",
    type: null,
    latitude: "45.54502130",
    longitude: "-73.60462230",
  },
  {
    id: 2651,
    name: "Gôh-Djiboua District",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "GD",
    type: null,
    latitude: "5.87113930",
    longitude: "-5.56172790",
  },
  {
    id: 2638,
    name: "Haut-Sassandra",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "02",
    type: null,
    latitude: "6.87578480",
    longitude: "-6.57833870",
  },
  {
    id: 2632,
    name: "Lacs District",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "LC",
    type: null,
    latitude: "48.19801690",
    longitude: "-80.45644120",
  },
  {
    id: 2640,
    name: "Lacs Region",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "07",
    type: null,
    latitude: "47.73958660",
    longitude: "-70.41866520",
  },
  {
    id: 2627,
    name: "Lagunes District",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "LG",
    type: null,
    latitude: "5.88273340",
    longitude: "-4.23333550",
  },
  {
    id: 2639,
    name: "Lagunes region",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "01",
    type: null,
    latitude: "5.88273340",
    longitude: "-4.23333550",
  },
  {
    id: 2631,
    name: "Marahoué Region",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "12",
    type: null,
    latitude: "6.88462070",
    longitude: "-5.89871390",
  },
  {
    id: 2629,
    name: "Montagnes District",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "MG",
    type: null,
    latitude: "7.37623730",
    longitude: "-7.43813550",
  },
  {
    id: 2646,
    name: "Moyen-Cavally",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "19",
    type: null,
    latitude: "6.52087930",
    longitude: "-7.61142170",
  },
  {
    id: 2630,
    name: "Moyen-Comoé",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "05",
    type: null,
    latitude: "6.65149170",
    longitude: "-3.50034540",
  },
  {
    id: 2655,
    name: "N'zi-Comoé",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "11",
    type: null,
    latitude: "7.24567490",
    longitude: "-4.23333550",
  },
  {
    id: 2648,
    name: "Sassandra-Marahoué District",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "SM",
    type: null,
    latitude: "6.88033480",
    longitude: "-6.23759470",
  },
  {
    id: 2625,
    name: "Savanes Region",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "03",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2628,
    name: "Sud-Bandama",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "15",
    type: null,
    latitude: "5.53570830",
    longitude: "-5.56172790",
  },
  {
    id: 2652,
    name: "Sud-Comoé",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "13",
    type: null,
    latitude: "5.55279300",
    longitude: "-3.25836260",
  },
  {
    id: 2637,
    name: "Vallée du Bandama District",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "VB",
    type: null,
    latitude: "8.27897800",
    longitude: "-4.89356270",
  },
  {
    id: 2647,
    name: "Vallée du Bandama Region",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "04",
    type: null,
    latitude: "8.27897800",
    longitude: "-4.89356270",
  },
  {
    id: 2650,
    name: "Woroba District",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "WR",
    type: null,
    latitude: "8.24913720",
    longitude: "-6.92091350",
  },
  {
    id: 2649,
    name: "Worodougou",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "14",
    type: null,
    latitude: "8.25489620",
    longitude: "-6.57833870",
  },
  {
    id: 2653,
    name: "Yamoussoukro",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "YM",
    type: null,
    latitude: "6.82762280",
    longitude: "-5.28934330",
  },
  {
    id: 2641,
    name: "Zanzan Region",
    country_id: 54,
    country_code: "CI",
    country_name: "Cote D'Ivoire (Ivory Coast)",
    state_code: "ZZ",
    type: null,
    latitude: "8.82079040",
    longitude: "-3.41955270",
  },
  {
    id: 734,
    name: "Bjelovar-Bilogora",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "07",
    type: "county",
    latitude: "45.89879720",
    longitude: "16.84230930",
  },
  {
    id: 737,
    name: "Brod-Posavina",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "12",
    type: "county",
    latitude: "45.26379510",
    longitude: "17.32645620",
  },
  {
    id: 728,
    name: "Dubrovnik-Neretva",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "19",
    type: "county",
    latitude: "43.07665880",
    longitude: "17.52684710",
  },
  {
    id: 743,
    name: "Istria",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "18",
    type: "county",
    latitude: "45.12864550",
    longitude: "13.90154200",
  },
  {
    id: 5069,
    name: "Karlovac",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "04",
    type: "county",
    latitude: "45.26133520",
    longitude: "15.52542016",
  },
  {
    id: 742,
    name: "Koprivnica-Križevci",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "06",
    type: "county",
    latitude: "46.15689190",
    longitude: "16.83908260",
  },
  {
    id: 729,
    name: "Krapina-Zagorje",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "02",
    type: "county",
    latitude: "46.10133930",
    longitude: "15.88096930",
  },
  {
    id: 731,
    name: "Lika-Senj",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "09",
    type: "county",
    latitude: "44.61922180",
    longitude: "15.47016080",
  },
  {
    id: 726,
    name: "Međimurje",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "20",
    type: "county",
    latitude: "46.37666440",
    longitude: "16.42132980",
  },
  {
    id: 740,
    name: "Osijek-Baranja",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "14",
    type: "county",
    latitude: "45.55764280",
    longitude: "18.39421410",
  },
  {
    id: 724,
    name: "Požega-Slavonia",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "11",
    type: "county",
    latitude: "45.34178680",
    longitude: "17.81143590",
  },
  {
    id: 735,
    name: "Primorje-Gorski Kotar",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "08",
    type: "county",
    latitude: "45.31739960",
    longitude: "14.81674660",
  },
  {
    id: 730,
    name: "Šibenik-Knin",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "15",
    type: "county",
    latitude: "43.92814850",
    longitude: "16.10376940",
  },
  {
    id: 733,
    name: "Sisak-Moslavina",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "03",
    type: "county",
    latitude: "45.38379260",
    longitude: "16.53809940",
  },
  {
    id: 725,
    name: "Split-Dalmatia",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "17",
    type: "county",
    latitude: "43.52403280",
    longitude: "16.81783770",
  },
  {
    id: 739,
    name: "Varaždin",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "05",
    type: "county",
    latitude: "46.23174730",
    longitude: "16.33605590",
  },
  {
    id: 732,
    name: "Virovitica-Podravina",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "10",
    type: "county",
    latitude: "45.65579850",
    longitude: "17.79324720",
  },
  {
    id: 741,
    name: "Vukovar-Syrmia",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "16",
    type: "county",
    latitude: "45.17735520",
    longitude: "18.80535270",
  },
  {
    id: 727,
    name: "Zadar",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "13",
    type: "county",
    latitude: "44.14693900",
    longitude: "15.61649430",
  },
  {
    id: 736,
    name: "Zagreb",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "01",
    type: "county",
    latitude: "45.87066120",
    longitude: "16.39549100",
  },
  {
    id: 738,
    name: "Zagreb",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    state_code: "21",
    type: "city",
    latitude: "45.81501080",
    longitude: "15.98191890",
  },
  {
    id: 283,
    name: "Artemisa Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "15",
    type: null,
    latitude: "22.75229030",
    longitude: "-82.99316070",
  },
  {
    id: 286,
    name: "Camagüey Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "09",
    type: null,
    latitude: "21.21672470",
    longitude: "-77.74520810",
  },
  {
    id: 282,
    name: "Ciego de Ávila Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "08",
    type: null,
    latitude: "21.93295150",
    longitude: "-78.56608520",
  },
  {
    id: 287,
    name: "Cienfuegos Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "06",
    type: null,
    latitude: "22.23797830",
    longitude: "-80.36586500",
  },
  {
    id: 275,
    name: "Granma Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "12",
    type: null,
    latitude: "20.38449020",
    longitude: "-76.64127120",
  },
  {
    id: 285,
    name: "Guantánamo Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "14",
    type: null,
    latitude: "20.14559170",
    longitude: "-74.87410450",
  },
  {
    id: 272,
    name: "Havana Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "03",
    type: null,
    latitude: "23.05406980",
    longitude: "-82.34518900",
  },
  {
    id: 279,
    name: "Holguín Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "11",
    type: null,
    latitude: "20.78378930",
    longitude: "-75.80690820",
  },
  {
    id: 278,
    name: "Isla de la Juventud",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "99",
    type: null,
    latitude: "21.70847370",
    longitude: "-82.82202320",
  },
  {
    id: 281,
    name: "Las Tunas Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "10",
    type: null,
    latitude: "21.06051620",
    longitude: "-76.91820970",
  },
  {
    id: 284,
    name: "Matanzas Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "04",
    type: null,
    latitude: "22.57671230",
    longitude: "-81.33994140",
  },
  {
    id: 276,
    name: "Mayabeque Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "16",
    type: null,
    latitude: "22.89265290",
    longitude: "-81.95348150",
  },
  {
    id: 277,
    name: "Pinar del Río Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "01",
    type: null,
    latitude: "22.40762560",
    longitude: "-83.84730150",
  },
  {
    id: 274,
    name: "Sancti Spíritus Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "07",
    type: null,
    latitude: "21.99382140",
    longitude: "-79.47038850",
  },
  {
    id: 273,
    name: "Santiago de Cuba Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "13",
    type: null,
    latitude: "20.23976820",
    longitude: "-75.99276520",
  },
  {
    id: 280,
    name: "Villa Clara Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    state_code: "05",
    type: null,
    latitude: "22.49372040",
    longitude: "-79.91927020",
  },
  {
    id: 749,
    name: "Famagusta District (Mağusa)",
    country_id: 57,
    country_code: "CY",
    country_name: "Cyprus",
    state_code: "04",
    type: "district",
    latitude: "35.28570230",
    longitude: "33.84112880",
  },
  {
    id: 744,
    name: "Kyrenia District (Keryneia)",
    country_id: 57,
    country_code: "CY",
    country_name: "Cyprus",
    state_code: "06",
    type: "district",
    latitude: "35.29919400",
    longitude: "33.23632460",
  },
  {
    id: 747,
    name: "Larnaca District (Larnaka)",
    country_id: 57,
    country_code: "CY",
    country_name: "Cyprus",
    state_code: "03",
    type: "district",
    latitude: "34.85072060",
    longitude: "33.48319060",
  },
  {
    id: 748,
    name: "Limassol District (Leymasun)",
    country_id: 57,
    country_code: "CY",
    country_name: "Cyprus",
    state_code: "02",
    type: "district",
    latitude: "34.70713010",
    longitude: "33.02261740",
  },
  {
    id: 745,
    name: "Nicosia District (Lefkoşa)",
    country_id: 57,
    country_code: "CY",
    country_name: "Cyprus",
    state_code: "01",
    type: "district",
    latitude: "35.18556590",
    longitude: "33.38227640",
  },
  {
    id: 746,
    name: "Paphos District (Pafos)",
    country_id: 57,
    country_code: "CY",
    country_name: "Cyprus",
    state_code: "05",
    type: "district",
    latitude: "34.91645940",
    longitude: "32.49200880",
  },
  {
    id: 4627,
    name: "Benešov",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "201",
    type: null,
    latitude: "49.69008280",
    longitude: "14.77643990",
  },
  {
    id: 4620,
    name: "Beroun",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "202",
    type: null,
    latitude: "49.95734280",
    longitude: "13.98407150",
  },
  {
    id: 4615,
    name: "Blansko",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "641",
    type: null,
    latitude: "49.36485020",
    longitude: "16.64775520",
  },
  {
    id: 4542,
    name: "Břeclav",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "644",
    type: null,
    latitude: "48.75314000",
    longitude: "16.88251690",
  },
  {
    id: 4568,
    name: "Brno-město",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "642",
    type: null,
    latitude: "49.19506020",
    longitude: "16.60683710",
  },
  {
    id: 4545,
    name: "Brno-venkov",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "643",
    type: null,
    latitude: "49.12501380",
    longitude: "16.45588240",
  },
  {
    id: 4644,
    name: "Bruntál",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "801",
    type: null,
    latitude: "49.98817670",
    longitude: "17.46369410",
  },
  {
    id: 4633,
    name: "Česká Lípa",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "511",
    type: null,
    latitude: "50.67852010",
    longitude: "14.53969910",
  },
  {
    id: 4556,
    name: "České Budějovice",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "311",
    type: null,
    latitude: "48.97755530",
    longitude: "14.51507470",
  },
  {
    id: 4543,
    name: "Český Krumlov",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "312",
    type: null,
    latitude: "48.81273540",
    longitude: "14.31746570",
  },
  {
    id: 4573,
    name: "Cheb",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "411",
    type: null,
    latitude: "50.07953340",
    longitude: "12.36986360",
  },
  {
    id: 4553,
    name: "Chomutov",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "422",
    type: null,
    latitude: "50.45838720",
    longitude: "13.30179100",
  },
  {
    id: 4634,
    name: "Chrudim",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "531",
    type: null,
    latitude: "49.88302160",
    longitude: "15.82908660",
  },
  {
    id: 4609,
    name: "Děčín",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "421",
    type: null,
    latitude: "50.77255630",
    longitude: "14.21276120",
  },
  {
    id: 4641,
    name: "Domažlice",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "321",
    type: null,
    latitude: "49.43970270",
    longitude: "12.93114350",
  },
  {
    id: 4559,
    name: "Frýdek-Místek",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "802",
    type: null,
    latitude: "49.68193050",
    longitude: "18.36732160",
  },
  {
    id: 4611,
    name: "Havlíčkův Brod",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "631",
    type: null,
    latitude: "49.60433640",
    longitude: "15.57965520",
  },
  {
    id: 4561,
    name: "Hodonín",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "645",
    type: null,
    latitude: "48.85293910",
    longitude: "17.12600250",
  },
  {
    id: 4580,
    name: "Hradec Králové",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "521",
    type: null,
    latitude: "50.24148050",
    longitude: "15.67430000",
  },
  {
    id: 4612,
    name: "Jablonec nad Nisou",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "512",
    type: null,
    latitude: "50.72205280",
    longitude: "15.17031350",
  },
  {
    id: 4625,
    name: "Jeseník",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "711",
    type: null,
    latitude: "50.22462490",
    longitude: "17.19804710",
  },
  {
    id: 4640,
    name: "Jičín",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "522",
    type: null,
    latitude: "50.43533250",
    longitude: "15.36104400",
  },
  {
    id: 4613,
    name: "Jihlava",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "632",
    type: null,
    latitude: "49.39837820",
    longitude: "15.58704150",
  },
  {
    id: 4639,
    name: "Jihočeský kraj",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "31",
    type: null,
    latitude: "48.94577890",
    longitude: "14.44160550",
  },
  {
    id: 4602,
    name: "Jihomoravský kraj",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "64",
    type: null,
    latitude: "48.95445280",
    longitude: "16.76768990",
  },
  {
    id: 4624,
    name: "Jindřichův Hradec",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "313",
    type: null,
    latitude: "49.14448230",
    longitude: "15.00613890",
  },
  {
    id: 4581,
    name: "Karlovarský kraj",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "41",
    type: null,
    latitude: "50.14350000",
    longitude: "12.75018990",
  },
  {
    id: 4604,
    name: "Karlovy Vary",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "412",
    type: null,
    latitude: "50.14350000",
    longitude: "12.75018990",
  },
  {
    id: 4586,
    name: "Karviná",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "803",
    type: null,
    latitude: "49.85665240",
    longitude: "18.54321860",
  },
  {
    id: 4631,
    name: "Kladno",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "203",
    type: null,
    latitude: "50.19402580",
    longitude: "14.10436570",
  },
  {
    id: 4591,
    name: "Klatovy",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "322",
    type: null,
    latitude: "49.39555490",
    longitude: "13.29509370",
  },
  {
    id: 4618,
    name: "Kolín",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "204",
    type: null,
    latitude: "49.98832930",
    longitude: "15.05519770",
  },
  {
    id: 4575,
    name: "Kraj Vysočina",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "63",
    type: null,
    latitude: "49.44900520",
    longitude: "15.64059340",
  },
  {
    id: 4614,
    name: "Královéhradecký kraj",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "52",
    type: null,
    latitude: "50.35124840",
    longitude: "15.79764590",
  },
  {
    id: 4593,
    name: "Kroměříž",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "721",
    type: null,
    latitude: "49.29165820",
    longitude: "17.39938000",
  },
  {
    id: 4923,
    name: "Kutná Hora",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "205",
    type: null,
    latitude: "49.94920890",
    longitude: "15.24704400",
  },
  {
    id: 4590,
    name: "Liberec",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "513",
    type: null,
    latitude: "50.75641010",
    longitude: "14.99650410",
  },
  {
    id: 4601,
    name: "Liberecký kraj",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "51",
    type: null,
    latitude: "50.65942400",
    longitude: "14.76324240",
  },
  {
    id: 4605,
    name: "Litoměřice",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "423",
    type: null,
    latitude: "50.53841970",
    longitude: "14.13054580",
  },
  {
    id: 4617,
    name: "Louny",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "424",
    type: null,
    latitude: "50.35398120",
    longitude: "13.80335510",
  },
  {
    id: 4638,
    name: "Mělník",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "206",
    type: null,
    latitude: "50.31044150",
    longitude: "14.51792230",
  },
  {
    id: 4643,
    name: "Mladá Boleslav",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "207",
    type: null,
    latitude: "50.42523170",
    longitude: "14.93624770",
  },
  {
    id: 4600,
    name: "Moravskoslezský kraj",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "80",
    type: null,
    latitude: "49.73053270",
    longitude: "18.23326370",
  },
  {
    id: 4629,
    name: "Most",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "425",
    type: null,
    latitude: "37.15540830",
    longitude: "-94.29488840",
  },
  {
    id: 4550,
    name: "Náchod",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "523",
    type: null,
    latitude: "50.41457220",
    longitude: "16.16563470",
  },
  {
    id: 4548,
    name: "Nový Jičín",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "804",
    type: null,
    latitude: "49.59432510",
    longitude: "18.01353560",
  },
  {
    id: 4582,
    name: "Nymburk",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "208",
    type: null,
    latitude: "50.18558160",
    longitude: "15.04366040",
  },
  {
    id: 4574,
    name: "Olomouc",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "712",
    type: null,
    latitude: "49.59377800",
    longitude: "17.25087870",
  },
  {
    id: 4589,
    name: "Olomoucký kraj",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "71",
    type: null,
    latitude: "49.65865490",
    longitude: "17.08114060",
  },
  {
    id: 4623,
    name: "Opava",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "805",
    type: null,
    latitude: "49.90837570",
    longitude: "17.91633800",
  },
  {
    id: 4584,
    name: "Ostrava-město",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "806",
    type: null,
    latitude: "49.82092260",
    longitude: "18.26252430",
  },
  {
    id: 4547,
    name: "Pardubice",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "532",
    type: null,
    latitude: "49.94444790",
    longitude: "16.28569160",
  },
  {
    id: 4588,
    name: "Pardubický kraj",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "53",
    type: null,
    latitude: "49.94444790",
    longitude: "16.28569160",
  },
  {
    id: 4645,
    name: "Pelhřimov",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "633",
    type: null,
    latitude: "49.43062070",
    longitude: "15.22298300",
  },
  {
    id: 4560,
    name: "Písek",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "314",
    type: null,
    latitude: "49.34199380",
    longitude: "14.24697600",
  },
  {
    id: 4608,
    name: "Plzeň-jih",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "324",
    type: null,
    latitude: "49.59048850",
    longitude: "13.57158610",
  },
  {
    id: 4544,
    name: "Plzeň-město",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "323",
    type: null,
    latitude: "49.73843140",
    longitude: "13.37363710",
  },
  {
    id: 4564,
    name: "Plzeň-sever",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "325",
    type: null,
    latitude: "49.87748930",
    longitude: "13.25374280",
  },
  {
    id: 4607,
    name: "Plzeňský kraj",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "32",
    type: null,
    latitude: "49.41348120",
    longitude: "13.31572460",
  },
  {
    id: 4578,
    name: "Prachatice",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "315",
    type: null,
    latitude: "49.01091000",
    longitude: "14.00000050",
  },
  {
    id: 4606,
    name: "Praha-východ",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "209",
    type: null,
    latitude: "49.93893070",
    longitude: "14.79244720",
  },
  {
    id: 4619,
    name: "Praha-západ",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "20A",
    type: null,
    latitude: "49.89352350",
    longitude: "14.32937790",
  },
  {
    id: 4598,
    name: "Praha, Hlavní město",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "10",
    type: null,
    latitude: "50.07553810",
    longitude: "14.43780050",
  },
  {
    id: 4626,
    name: "Přerov",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "714",
    type: null,
    latitude: "49.46713560",
    longitude: "17.50773320",
  },
  {
    id: 4546,
    name: "Příbram",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "20B",
    type: null,
    latitude: "49.69479590",
    longitude: "14.08238100",
  },
  {
    id: 4551,
    name: "Prostějov",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "713",
    type: null,
    latitude: "49.44184010",
    longitude: "17.12779040",
  },
  {
    id: 4558,
    name: "Rakovník",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "20C",
    type: null,
    latitude: "50.10612300",
    longitude: "13.73966230",
  },
  {
    id: 4583,
    name: "Rokycany",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "326",
    type: null,
    latitude: "49.82628270",
    longitude: "13.68749430",
  },
  {
    id: 4636,
    name: "Rychnov nad Kněžnou",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "524",
    type: null,
    latitude: "50.16596510",
    longitude: "16.27768420",
  },
  {
    id: 4596,
    name: "Semily",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "514",
    type: null,
    latitude: "50.60515760",
    longitude: "15.32814090",
  },
  {
    id: 4595,
    name: "Sokolov",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "413",
    type: null,
    latitude: "50.20134340",
    longitude: "12.60546360",
  },
  {
    id: 4628,
    name: "Strakonice",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "316",
    type: null,
    latitude: "49.26040430",
    longitude: "13.91030850",
  },
  {
    id: 4554,
    name: "Středočeský kraj",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "20",
    type: null,
    latitude: "49.87822230",
    longitude: "14.93629550",
  },
  {
    id: 4642,
    name: "Šumperk",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "715",
    type: null,
    latitude: "49.97784070",
    longitude: "16.97177540",
  },
  {
    id: 4571,
    name: "Svitavy",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "533",
    type: null,
    latitude: "49.75516290",
    longitude: "16.46918610",
  },
  {
    id: 4565,
    name: "Tábor",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "317",
    type: null,
    latitude: "49.36462930",
    longitude: "14.71912930",
  },
  {
    id: 4646,
    name: "Tachov",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "327",
    type: null,
    latitude: "49.79878030",
    longitude: "12.63619210",
  },
  {
    id: 4621,
    name: "Teplice",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "426",
    type: null,
    latitude: "50.65846050",
    longitude: "13.75132270",
  },
  {
    id: 4597,
    name: "Třebíč",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "634",
    type: null,
    latitude: "49.21478690",
    longitude: "15.87955160",
  },
  {
    id: 4579,
    name: "Trutnov",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "525",
    type: null,
    latitude: "50.56538380",
    longitude: "15.90909230",
  },
  {
    id: 4592,
    name: "Uherské Hradiště",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "722",
    type: null,
    latitude: "49.05979690",
    longitude: "17.49585010",
  },
  {
    id: 4576,
    name: "Ústecký kraj",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "42",
    type: null,
    latitude: "50.61190370",
    longitude: "13.78700860",
  },
  {
    id: 4599,
    name: "Ústí nad Labem",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "427",
    type: null,
    latitude: "50.61190370",
    longitude: "13.78700860",
  },
  {
    id: 4647,
    name: "Ústí nad Orlicí",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "534",
    type: null,
    latitude: "49.97218010",
    longitude: "16.39966170",
  },
  {
    id: 4572,
    name: "Vsetín",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "723",
    type: null,
    latitude: "49.37932500",
    longitude: "18.06181620",
  },
  {
    id: 4622,
    name: "Vyškov",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "646",
    type: null,
    latitude: "49.21274450",
    longitude: "16.98559270",
  },
  {
    id: 4648,
    name: "Žďár nad Sázavou",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "635",
    type: null,
    latitude: "49.56430120",
    longitude: "15.93910300",
  },
  {
    id: 4563,
    name: "Zlín",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "724",
    type: null,
    latitude: "49.16960520",
    longitude: "17.80252200",
  },
  {
    id: 4552,
    name: "Zlínský kraj",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "72",
    type: null,
    latitude: "49.21622960",
    longitude: "17.77203530",
  },
  {
    id: 4630,
    name: "Znojmo",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    state_code: "647",
    type: null,
    latitude: "48.92723270",
    longitude: "16.10378080",
  },
  {
    id: 2753,
    name: "Bas-Uélé",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "BU",
    type: null,
    latitude: "3.99010090",
    longitude: "24.90422080",
  },
  {
    id: 2744,
    name: "Équateur",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "EQ",
    type: null,
    latitude: "-1.83123900",
    longitude: "-78.18340600",
  },
  {
    id: 2750,
    name: "Haut-Katanga",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "HK",
    type: null,
    latitude: "-10.41020750",
    longitude: "27.54958460",
  },
  {
    id: 2758,
    name: "Haut-Lomami",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "HL",
    type: null,
    latitude: "-7.70527520",
    longitude: "24.90422080",
  },
  {
    id: 2734,
    name: "Haut-Uélé",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "HU",
    type: null,
    latitude: "3.58451540",
    longitude: "28.29943500",
  },
  {
    id: 2751,
    name: "Ituri",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "IT",
    type: null,
    latitude: "1.59576820",
    longitude: "29.41793240",
  },
  {
    id: 2757,
    name: "Kasaï",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "KS",
    type: null,
    latitude: "-5.04719790",
    longitude: "20.71224650",
  },
  {
    id: 2742,
    name: "Kasaï Central",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "KC",
    type: null,
    latitude: "-8.44045910",
    longitude: "20.41659340",
  },
  {
    id: 2735,
    name: "Kasaï Oriental",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "KE",
    type: null,
    latitude: "-6.03362300",
    longitude: "23.57285010",
  },
  {
    id: 2741,
    name: "Kinshasa",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "KN",
    type: null,
    latitude: "-4.44193110",
    longitude: "15.26629310",
  },
  {
    id: 2746,
    name: "Kongo Central",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "BC",
    type: null,
    latitude: "-5.23656850",
    longitude: "13.91439900",
  },
  {
    id: 2740,
    name: "Kwango",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "KG",
    type: null,
    latitude: "-6.43374090",
    longitude: "17.66888700",
  },
  {
    id: 2759,
    name: "Kwilu",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "KL",
    type: null,
    latitude: "-5.11888250",
    longitude: "18.42760470",
  },
  {
    id: 2747,
    name: "Lomami",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "LO",
    type: null,
    latitude: "-6.14539310",
    longitude: "24.52426400",
  },
  {
    id: 4953,
    name: "Lualaba",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "LU",
    type: null,
    latitude: "-10.48086980",
    longitude: "25.62978160",
  },
  {
    id: 2755,
    name: "Mai-Ndombe",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "MN",
    type: null,
    latitude: "-2.63574340",
    longitude: "18.42760470",
  },
  {
    id: 2745,
    name: "Maniema",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "MA",
    type: null,
    latitude: "-3.07309290",
    longitude: "26.04138890",
  },
  {
    id: 2752,
    name: "Mongala",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "MO",
    type: null,
    latitude: "1.99623240",
    longitude: "21.47528510",
  },
  {
    id: 2749,
    name: "Nord-Kivu",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "NK",
    type: null,
    latitude: "-0.79177290",
    longitude: "29.04599270",
  },
  {
    id: 2739,
    name: "Nord-Ubangi",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "NU",
    type: null,
    latitude: "3.78787260",
    longitude: "21.47528510",
  },
  {
    id: 2743,
    name: "Sankuru",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "SA",
    type: null,
    latitude: "-2.84374530",
    longitude: "23.38235450",
  },
  {
    id: 2738,
    name: "Sud-Kivu",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "SK",
    type: null,
    latitude: "-3.01165800",
    longitude: "28.29943500",
  },
  {
    id: 2748,
    name: "Sud-Ubangi",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "SU",
    type: null,
    latitude: "3.22999420",
    longitude: "19.18800470",
  },
  {
    id: 2733,
    name: "Tanganyika",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "TA",
    type: null,
    latitude: "-6.27401180",
    longitude: "27.92490020",
  },
  {
    id: 2756,
    name: "Tshopo",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "TO",
    type: null,
    latitude: "0.54554620",
    longitude: "24.90422080",
  },
  {
    id: 2732,
    name: "Tshuapa",
    country_id: 51,
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    state_code: "TU",
    type: null,
    latitude: "-0.99030230",
    longitude: "23.02888440",
  },
  {
    id: 1530,
    name: "Capital Region of Denmark",
    country_id: 59,
    country_code: "DK",
    country_name: "Denmark",
    state_code: "84",
    type: null,
    latitude: "55.67518120",
    longitude: "12.54932610",
  },
  {
    id: 1531,
    name: "Central Denmark Region",
    country_id: 59,
    country_code: "DK",
    country_name: "Denmark",
    state_code: "82",
    type: null,
    latitude: "56.30213900",
    longitude: "9.30277700",
  },
  {
    id: 1532,
    name: "North Denmark Region",
    country_id: 59,
    country_code: "DK",
    country_name: "Denmark",
    state_code: "81",
    type: null,
    latitude: "56.83074160",
    longitude: "9.49305270",
  },
  {
    id: 1529,
    name: "Region of Southern Denmark",
    country_id: 59,
    country_code: "DK",
    country_name: "Denmark",
    state_code: "83",
    type: null,
    latitude: "55.33077140",
    longitude: "9.09249030",
  },
  {
    id: 1528,
    name: "Region Zealand",
    country_id: 59,
    country_code: "DK",
    country_name: "Denmark",
    state_code: "85",
    type: null,
    latitude: "55.46325180",
    longitude: "11.72149790",
  },
  {
    id: 2933,
    name: "Ali Sabieh Region",
    country_id: 60,
    country_code: "DJ",
    country_name: "Djibouti",
    state_code: "AS",
    type: null,
    latitude: "11.19289730",
    longitude: "42.94169800",
  },
  {
    id: 2932,
    name: "Arta Region",
    country_id: 60,
    country_code: "DJ",
    country_name: "Djibouti",
    state_code: "AR",
    type: null,
    latitude: "11.52555280",
    longitude: "42.84794740",
  },
  {
    id: 2930,
    name: "Dikhil Region",
    country_id: 60,
    country_code: "DJ",
    country_name: "Djibouti",
    state_code: "DI",
    type: null,
    latitude: "11.10543360",
    longitude: "42.37047440",
  },
  {
    id: 2929,
    name: "Djibouti",
    country_id: 60,
    country_code: "DJ",
    country_name: "Djibouti",
    state_code: "DJ",
    type: null,
    latitude: "11.82513800",
    longitude: "42.59027500",
  },
  {
    id: 2928,
    name: "Obock Region",
    country_id: 60,
    country_code: "DJ",
    country_name: "Djibouti",
    state_code: "OB",
    type: null,
    latitude: "12.38956910",
    longitude: "43.01948970",
  },
  {
    id: 2931,
    name: "Tadjourah Region",
    country_id: 60,
    country_code: "DJ",
    country_name: "Djibouti",
    state_code: "TA",
    type: null,
    latitude: "11.93388850",
    longitude: "42.39383750",
  },
  {
    id: 4082,
    name: "Saint Andrew Parish",
    country_id: 61,
    country_code: "DM",
    country_name: "Dominica",
    state_code: "02",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 4078,
    name: "Saint David Parish",
    country_id: 61,
    country_code: "DM",
    country_name: "Dominica",
    state_code: "03",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 4079,
    name: "Saint George Parish",
    country_id: 61,
    country_code: "DM",
    country_name: "Dominica",
    state_code: "04",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 4076,
    name: "Saint John Parish",
    country_id: 61,
    country_code: "DM",
    country_name: "Dominica",
    state_code: "05",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 4085,
    name: "Saint Joseph Parish",
    country_id: 61,
    country_code: "DM",
    country_name: "Dominica",
    state_code: "06",
    type: null,
    latitude: "39.02227120",
    longitude: "-94.71765040",
  },
  {
    id: 4083,
    name: "Saint Luke Parish",
    country_id: 61,
    country_code: "DM",
    country_name: "Dominica",
    state_code: "07",
    type: null,
    latitude: "42.10513630",
    longitude: "-80.05707220",
  },
  {
    id: 4077,
    name: "Saint Mark Parish",
    country_id: 61,
    country_code: "DM",
    country_name: "Dominica",
    state_code: "08",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 4080,
    name: "Saint Patrick Parish",
    country_id: 61,
    country_code: "DM",
    country_name: "Dominica",
    state_code: "09",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 4084,
    name: "Saint Paul Parish",
    country_id: 61,
    country_code: "DM",
    country_name: "Dominica",
    state_code: "10",
    type: null,
    latitude: "38.86146000",
    longitude: "-90.74356190",
  },
  {
    id: 4081,
    name: "Saint Peter Parish",
    country_id: 61,
    country_code: "DM",
    country_name: "Dominica",
    state_code: "11",
    type: null,
    latitude: "40.45241940",
    longitude: "-80.00850560",
  },
  {
    id: 4114,
    name: "Azua Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "02",
    type: null,
    latitude: "18.45527090",
    longitude: "-70.73809280",
  },
  {
    id: 4105,
    name: "Baoruco Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "03",
    type: null,
    latitude: "18.48798980",
    longitude: "-71.41822490",
  },
  {
    id: 4090,
    name: "Barahona Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "04",
    type: null,
    latitude: "18.21390660",
    longitude: "-71.10437590",
  },
  {
    id: 4107,
    name: "Dajabón Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "05",
    type: null,
    latitude: "19.54992410",
    longitude: "-71.70865140",
  },
  {
    id: 4095,
    name: "Distrito Nacional",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "01",
    type: null,
    latitude: "18.48605750",
    longitude: "-69.93121170",
  },
  {
    id: 4113,
    name: "Duarte Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "06",
    type: null,
    latitude: "19.20908230",
    longitude: "-70.02700040",
  },
  {
    id: 4086,
    name: "El Seibo Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "08",
    type: null,
    latitude: "18.76584960",
    longitude: "-69.04066800",
  },
  {
    id: 4102,
    name: "Espaillat Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "09",
    type: null,
    latitude: "19.62776580",
    longitude: "-70.27867750",
  },
  {
    id: 4106,
    name: "Hato Mayor Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "30",
    type: null,
    latitude: "18.76357990",
    longitude: "-69.25576370",
  },
  {
    id: 4089,
    name: "Hermanas Mirabal Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "19",
    type: null,
    latitude: "19.37475590",
    longitude: "-70.35132350",
  },
  {
    id: 4097,
    name: "Independencia",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "10",
    type: null,
    latitude: "32.63357480",
    longitude: "-115.42892940",
  },
  {
    id: 4109,
    name: "La Altagracia Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "11",
    type: null,
    latitude: "18.58502360",
    longitude: "-68.62010720",
  },
  {
    id: 4087,
    name: "La Romana Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "12",
    type: null,
    latitude: "18.43102710",
    longitude: "-68.98373730",
  },
  {
    id: 4116,
    name: "La Vega Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "13",
    type: null,
    latitude: "19.22115540",
    longitude: "-70.52887530",
  },
  {
    id: 4094,
    name: "María Trinidad Sánchez Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "14",
    type: null,
    latitude: "19.37345970",
    longitude: "-69.85144390",
  },
  {
    id: 4099,
    name: "Monseñor Nouel Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "28",
    type: null,
    latitude: "18.92152340",
    longitude: "-70.38368150",
  },
  {
    id: 4115,
    name: "Monte Cristi Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "15",
    type: null,
    latitude: "19.73968990",
    longitude: "-71.44339840",
  },
  {
    id: 4111,
    name: "Monte Plata Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "29",
    type: null,
    latitude: "18.80808780",
    longitude: "-69.78691460",
  },
  {
    id: 4101,
    name: "Pedernales Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "16",
    type: null,
    latitude: "17.85376260",
    longitude: "-71.33032090",
  },
  {
    id: 4096,
    name: "Peravia Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "17",
    type: null,
    latitude: "18.27865940",
    longitude: "-70.33358870",
  },
  {
    id: 4092,
    name: "Puerto Plata Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "18",
    type: null,
    latitude: "19.75432250",
    longitude: "-70.83328470",
  },
  {
    id: 4103,
    name: "Samaná Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "20",
    type: null,
    latitude: "19.20583710",
    longitude: "-69.33629490",
  },
  {
    id: 4091,
    name: "San Cristóbal Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "21",
    type: null,
    latitude: "18.41804140",
    longitude: "-70.10658490",
  },
  {
    id: 4112,
    name: "San José de Ocoa Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "31",
    type: null,
    latitude: "18.54385800",
    longitude: "-70.50418160",
  },
  {
    id: 4098,
    name: "San Juan Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "22",
    type: null,
    latitude: "-31.52871270",
    longitude: "-68.53604030",
  },
  {
    id: 4110,
    name: "San Pedro de Macorís",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "23",
    type: null,
    latitude: "18.46266000",
    longitude: "-69.30512340",
  },
  {
    id: 4088,
    name: "Sánchez Ramírez Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "24",
    type: null,
    latitude: "19.05270600",
    longitude: "-70.14922640",
  },
  {
    id: 4108,
    name: "Santiago Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "25",
    type: null,
    latitude: "-33.45000000",
    longitude: "-70.66670000",
  },
  {
    id: 4100,
    name: "Santiago Rodríguez Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "26",
    type: null,
    latitude: "19.47131810",
    longitude: "-71.33958010",
  },
  {
    id: 4093,
    name: "Santo Domingo Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "32",
    type: null,
    latitude: "18.51042530",
    longitude: "-69.84040540",
  },
  {
    id: 4104,
    name: "Valverde Province",
    country_id: 62,
    country_code: "DO",
    country_name: "Dominican Republic",
    state_code: "27",
    type: null,
    latitude: "19.58812210",
    longitude: "-70.98033100",
  },
  {
    id: 4520,
    name: "Aileu municipality",
    country_id: 63,
    country_code: "TL",
    country_name: "East Timor",
    state_code: "AL",
    type: null,
    latitude: "-8.70439940",
    longitude: "125.60954740",
  },
  {
    id: 4518,
    name: "Ainaro Municipality",
    country_id: 63,
    country_code: "TL",
    country_name: "East Timor",
    state_code: "AN",
    type: null,
    latitude: "-9.01131710",
    longitude: "125.52200120",
  },
  {
    id: 4521,
    name: "Baucau Municipality",
    country_id: 63,
    country_code: "TL",
    country_name: "East Timor",
    state_code: "BA",
    type: null,
    latitude: "-8.47143080",
    longitude: "126.45759910",
  },
  {
    id: 4525,
    name: "Bobonaro Municipality",
    country_id: 63,
    country_code: "TL",
    country_name: "East Timor",
    state_code: "BO",
    type: null,
    latitude: "-8.96554060",
    longitude: "125.25879640",
  },
  {
    id: 4522,
    name: "Cova Lima Municipality",
    country_id: 63,
    country_code: "TL",
    country_name: "East Timor",
    state_code: "CO",
    type: null,
    latitude: "-9.26503750",
    longitude: "125.25879640",
  },
  {
    id: 4524,
    name: "Dili municipality",
    country_id: 63,
    country_code: "TL",
    country_name: "East Timor",
    state_code: "DI",
    type: null,
    latitude: "-8.24496130",
    longitude: "125.58766970",
  },
  {
    id: 4516,
    name: "Ermera District",
    country_id: 63,
    country_code: "TL",
    country_name: "East Timor",
    state_code: "ER",
    type: null,
    latitude: "-8.75248020",
    longitude: "125.39872940",
  },
  {
    id: 4523,
    name: "Lautém Municipality",
    country_id: 63,
    country_code: "TL",
    country_name: "East Timor",
    state_code: "LA",
    type: null,
    latitude: "-8.36423070",
    longitude: "126.90438450",
  },
  {
    id: 4515,
    name: "Liquiçá Municipality",
    country_id: 63,
    country_code: "TL",
    country_name: "East Timor",
    state_code: "LI",
    type: null,
    latitude: "-8.66740950",
    longitude: "125.25879640",
  },
  {
    id: 4517,
    name: "Manatuto District",
    country_id: 63,
    country_code: "TL",
    country_name: "East Timor",
    state_code: "MT",
    type: null,
    latitude: "-8.51556080",
    longitude: "126.01592550",
  },
  {
    id: 4519,
    name: "Manufahi Municipality",
    country_id: 63,
    country_code: "TL",
    country_name: "East Timor",
    state_code: "MF",
    type: null,
    latitude: "-9.01454950",
    longitude: "125.82799590",
  },
  {
    id: 4514,
    name: "Viqueque Municipality",
    country_id: 63,
    country_code: "TL",
    country_name: "East Timor",
    state_code: "VI",
    type: null,
    latitude: "-8.85979180",
    longitude: "126.36335160",
  },
  {
    id: 2923,
    name: "Azuay",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "A",
    type: "province",
    latitude: "-2.89430680",
    longitude: "-78.99683440",
  },
  {
    id: 2920,
    name: "Bolívar",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "B",
    type: "province",
    latitude: "-1.70958280",
    longitude: "-79.04504290",
  },
  {
    id: 2917,
    name: "Cañar",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "F",
    type: "province",
    latitude: "-2.55893150",
    longitude: "-78.93881910",
  },
  {
    id: 2915,
    name: "Carchi",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "C",
    type: "province",
    latitude: "0.50269120",
    longitude: "-77.90425210",
  },
  {
    id: 2925,
    name: "Chimborazo",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "H",
    type: "province",
    latitude: "-1.66479950",
    longitude: "-78.65432550",
  },
  {
    id: 2921,
    name: "Cotopaxi",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "X",
    type: "province",
    latitude: "-0.83842060",
    longitude: "-78.66626780",
  },
  {
    id: 2924,
    name: "El Oro",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "O",
    type: "province",
    latitude: "-3.25924130",
    longitude: "-79.95835410",
  },
  {
    id: 2922,
    name: "Esmeraldas",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "E",
    type: "province",
    latitude: "0.96817890",
    longitude: "-79.65172020",
  },
  {
    id: 2905,
    name: "Galápagos",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "W",
    type: "province",
    latitude: "-0.95376910",
    longitude: "-90.96560190",
  },
  {
    id: 2914,
    name: "Guayas",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "G",
    type: "province",
    latitude: "-1.95748390",
    longitude: "-79.91927020",
  },
  {
    id: 2911,
    name: "Imbabura",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "I",
    type: "province",
    latitude: "0.34997680",
    longitude: "-78.12601290",
  },
  {
    id: 5068,
    name: "Loja",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "L",
    type: "province",
    latitude: "-3.99313000",
    longitude: "-79.20422000",
  },
  {
    id: 2910,
    name: "Los Ríos",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "R",
    type: "province",
    latitude: "-1.02306070",
    longitude: "-79.46088970",
  },
  {
    id: 2913,
    name: "Manabí",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "M",
    type: "province",
    latitude: "-1.05434340",
    longitude: "-80.45264400",
  },
  {
    id: 2918,
    name: "Morona-Santiago",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "S",
    type: "province",
    latitude: "-2.30510620",
    longitude: "-78.11468660",
  },
  {
    id: 2916,
    name: "Napo",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "N",
    type: "province",
    latitude: "-0.99559640",
    longitude: "-77.81296840",
  },
  {
    id: 2926,
    name: "Orellana",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "D",
    type: "province",
    latitude: "-0.45451630",
    longitude: "-76.99502860",
  },
  {
    id: 2907,
    name: "Pastaza",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "Y",
    type: "province",
    latitude: "-1.48822650",
    longitude: "-78.00310570",
  },
  {
    id: 2927,
    name: "Pichincha",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "P",
    type: "province",
    latitude: "-0.14648470",
    longitude: "-78.47519450",
  },
  {
    id: 2912,
    name: "Santa Elena",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "SE",
    type: "province",
    latitude: "-2.22671050",
    longitude: "-80.85949900",
  },
  {
    id: 2919,
    name: "Santo Domingo de los Tsáchilas",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "SD",
    type: "province",
    latitude: "-0.25218820",
    longitude: "-79.18793830",
  },
  {
    id: 2906,
    name: "Sucumbíos",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "U",
    type: "province",
    latitude: "0.08892310",
    longitude: "-76.88975570",
  },
  {
    id: 2908,
    name: "Tungurahua",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "T",
    type: "province",
    latitude: "-1.26352840",
    longitude: "-78.56608520",
  },
  {
    id: 2909,
    name: "Zamora Chinchipe",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    state_code: "Z",
    type: "province",
    latitude: "-4.06558920",
    longitude: "-78.95035250",
  },
  {
    id: 3235,
    name: "Alexandria",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "ALX",
    type: null,
    latitude: "30.87605680",
    longitude: "29.74260400",
  },
  {
    id: 3225,
    name: "Aswan",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "ASN",
    type: null,
    latitude: "23.69664980",
    longitude: "32.71813750",
  },
  {
    id: 3236,
    name: "Asyut",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "AST",
    type: null,
    latitude: "27.21338310",
    longitude: "31.44561790",
  },
  {
    id: 3241,
    name: "Beheira",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "BH",
    type: null,
    latitude: "30.84809860",
    longitude: "30.34355060",
  },
  {
    id: 3230,
    name: "Beni Suef",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "BNS",
    type: null,
    latitude: "28.89388370",
    longitude: "31.44561790",
  },
  {
    id: 3223,
    name: "Cairo",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "C",
    type: null,
    latitude: "29.95375640",
    longitude: "31.53700030",
  },
  {
    id: 3245,
    name: "Dakahlia",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "DK",
    type: null,
    latitude: "31.16560440",
    longitude: "31.49131820",
  },
  {
    id: 3224,
    name: "Damietta",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "DT",
    type: null,
    latitude: "31.36257990",
    longitude: "31.67393710",
  },
  {
    id: 3238,
    name: "Faiyum",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "FYM",
    type: null,
    latitude: "29.30840210",
    longitude: "30.84284970",
  },
  {
    id: 3234,
    name: "Gharbia",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "GH",
    type: null,
    latitude: "30.87535560",
    longitude: "31.03351000",
  },
  {
    id: 3239,
    name: "Giza",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "GZ",
    type: null,
    latitude: "28.76662160",
    longitude: "29.23207840",
  },
  {
    id: 3244,
    name: "Ismailia",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "IS",
    type: null,
    latitude: "30.58309340",
    longitude: "32.26538870",
  },
  {
    id: 3222,
    name: "Kafr el-Sheikh",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "KFS",
    type: null,
    latitude: "31.30854440",
    longitude: "30.80394740",
  },
  {
    id: 3242,
    name: "Luxor",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "LX",
    type: null,
    latitude: "25.39444440",
    longitude: "32.49200880",
  },
  {
    id: 3231,
    name: "Matrouh",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "MT",
    type: null,
    latitude: "29.56963500",
    longitude: "26.41938900",
  },
  {
    id: 3243,
    name: "Minya",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "MN",
    type: null,
    latitude: "28.28472900",
    longitude: "30.52790960",
  },
  {
    id: 3228,
    name: "Monufia",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "MNF",
    type: null,
    latitude: "30.59724550",
    longitude: "30.98763210",
  },
  {
    id: 3246,
    name: "New Valley",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "WAD",
    type: null,
    latitude: "24.54556380",
    longitude: "27.17353160",
  },
  {
    id: 3227,
    name: "North Sinai",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "SIN",
    type: null,
    latitude: "30.28236500",
    longitude: "33.61757700",
  },
  {
    id: 3229,
    name: "Port Said",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "PTS",
    type: null,
    latitude: "31.07586060",
    longitude: "32.26538870",
  },
  {
    id: 3232,
    name: "Qalyubia",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "KB",
    type: null,
    latitude: "30.32923680",
    longitude: "31.21684660",
  },
  {
    id: 3247,
    name: "Qena",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "KN",
    type: null,
    latitude: "26.23460330",
    longitude: "32.98883190",
  },
  {
    id: 3240,
    name: "Red Sea",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "BA",
    type: null,
    latitude: "24.68263160",
    longitude: "34.15319470",
  },
  {
    id: 5067,
    name: "Sharqia",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "SHR",
    type: null,
    latitude: "30.67305450",
    longitude: "31.15932470",
  },
  {
    id: 3226,
    name: "Sohag",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "SHG",
    type: null,
    latitude: "26.69383400",
    longitude: "32.17460500",
  },
  {
    id: 3237,
    name: "South Sinai",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "JS",
    type: null,
    latitude: "29.31018280",
    longitude: "34.15319470",
  },
  {
    id: 3233,
    name: "Suez",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    state_code: "SUZ",
    type: null,
    latitude: "29.36822550",
    longitude: "32.17460500",
  },
  {
    id: 4139,
    name: "Ahuachapán Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "AH",
    type: null,
    latitude: "13.82161480",
    longitude: "-89.92532330",
  },
  {
    id: 4132,
    name: "Cabañas Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "CA",
    type: null,
    latitude: "13.86482880",
    longitude: "-88.74939980",
  },
  {
    id: 4131,
    name: "Chalatenango Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "CH",
    type: null,
    latitude: "14.19166480",
    longitude: "-89.17059980",
  },
  {
    id: 4137,
    name: "Cuscatlán Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "CU",
    type: null,
    latitude: "13.86619570",
    longitude: "-89.05615320",
  },
  {
    id: 4134,
    name: "La Libertad Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "LI",
    type: null,
    latitude: "13.68176610",
    longitude: "-89.36062980",
  },
  {
    id: 4136,
    name: "La Paz Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "PA",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 4138,
    name: "La Unión Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "UN",
    type: null,
    latitude: "13.48864430",
    longitude: "-87.89424510",
  },
  {
    id: 4130,
    name: "Morazán Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "MO",
    type: null,
    latitude: "13.76820000",
    longitude: "-88.12913870",
  },
  {
    id: 4135,
    name: "San Miguel Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "SM",
    type: null,
    latitude: "13.44510410",
    longitude: "-88.24611830",
  },
  {
    id: 4133,
    name: "San Salvador Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "SS",
    type: null,
    latitude: "13.77399970",
    longitude: "-89.20867730",
  },
  {
    id: 4127,
    name: "San Vicente Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "SV",
    type: null,
    latitude: "13.58685610",
    longitude: "-88.74939980",
  },
  {
    id: 4128,
    name: "Santa Ana Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "SA",
    type: null,
    latitude: "14.14611210",
    longitude: "-89.51200840",
  },
  {
    id: 4140,
    name: "Sonsonate Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "SO",
    type: null,
    latitude: "13.68235800",
    longitude: "-89.66281110",
  },
  {
    id: 4129,
    name: "Usulután Department",
    country_id: 66,
    country_code: "SV",
    country_name: "El Salvador",
    state_code: "US",
    type: null,
    latitude: "13.44706340",
    longitude: "-88.55653100",
  },
  {
    id: 3444,
    name: "Annobón Province",
    country_id: 67,
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    state_code: "AN",
    type: null,
    latitude: "-1.42687820",
    longitude: "5.63528010",
  },
  {
    id: 3446,
    name: "Bioko Norte Province",
    country_id: 67,
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    state_code: "BN",
    type: null,
    latitude: "3.65950720",
    longitude: "8.79218360",
  },
  {
    id: 3443,
    name: "Bioko Sur Province",
    country_id: 67,
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    state_code: "BS",
    type: null,
    latitude: "3.42097850",
    longitude: "8.61606740",
  },
  {
    id: 3445,
    name: "Centro Sur Province",
    country_id: 67,
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    state_code: "CS",
    type: null,
    latitude: "1.34360840",
    longitude: "10.43965600",
  },
  {
    id: 3442,
    name: "Insular Region",
    country_id: 67,
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    state_code: "I",
    type: null,
    latitude: "37.09024000",
    longitude: "-95.71289100",
  },
  {
    id: 3439,
    name: "Kié-Ntem Province",
    country_id: 67,
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    state_code: "KN",
    type: null,
    latitude: "2.02809300",
    longitude: "11.07117580",
  },
  {
    id: 3441,
    name: "Litoral Province",
    country_id: 67,
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    state_code: "LI",
    type: null,
    latitude: "1.57502440",
    longitude: "9.81249350",
  },
  {
    id: 3438,
    name: "Río Muni",
    country_id: 67,
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    state_code: "C",
    type: null,
    latitude: "1.46106060",
    longitude: "9.67868940",
  },
  {
    id: 3440,
    name: "Wele-Nzas Province",
    country_id: 67,
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    state_code: "WN",
    type: null,
    latitude: "1.41661620",
    longitude: "11.07117580",
  },
  {
    id: 3425,
    name: "Anseba Region",
    country_id: 68,
    country_code: "ER",
    country_name: "Eritrea",
    state_code: "AN",
    type: null,
    latitude: "16.47455310",
    longitude: "37.80876930",
  },
  {
    id: 3427,
    name: "Debub Region",
    country_id: 68,
    country_code: "ER",
    country_name: "Eritrea",
    state_code: "DU",
    type: null,
    latitude: "14.94786920",
    longitude: "39.15436770",
  },
  {
    id: 3428,
    name: "Gash-Barka Region",
    country_id: 68,
    country_code: "ER",
    country_name: "Eritrea",
    state_code: "GB",
    type: null,
    latitude: "15.40688250",
    longitude: "37.63866220",
  },
  {
    id: 3426,
    name: "Maekel Region",
    country_id: 68,
    country_code: "ER",
    country_name: "Eritrea",
    state_code: "MA",
    type: null,
    latitude: "15.35514090",
    longitude: "38.86236830",
  },
  {
    id: 3424,
    name: "Northern Red Sea Region",
    country_id: 68,
    country_code: "ER",
    country_name: "Eritrea",
    state_code: "SK",
    type: null,
    latitude: "16.25839970",
    longitude: "38.82054540",
  },
  {
    id: 3429,
    name: "Southern Red Sea Region",
    country_id: 68,
    country_code: "ER",
    country_name: "Eritrea",
    state_code: "DK",
    type: null,
    latitude: "13.51371030",
    longitude: "41.76064720",
  },
  {
    id: 3567,
    name: "Harju County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "37",
    type: null,
    latitude: "59.33342390",
    longitude: "25.24669740",
  },
  {
    id: 3555,
    name: "Hiiu County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "39",
    type: null,
    latitude: "58.92395530",
    longitude: "22.59194680",
  },
  {
    id: 3569,
    name: "Ida-Viru County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "44",
    type: null,
    latitude: "59.25926630",
    longitude: "27.41365350",
  },
  {
    id: 3566,
    name: "Järva County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "51",
    type: null,
    latitude: "58.88667130",
    longitude: "25.50006240",
  },
  {
    id: 3565,
    name: "Jõgeva County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "49",
    type: null,
    latitude: "58.75061430",
    longitude: "26.36048780",
  },
  {
    id: 3568,
    name: "Lääne County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "57",
    type: null,
    latitude: "58.97227420",
    longitude: "23.87408340",
  },
  {
    id: 3564,
    name: "Lääne-Viru County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "59",
    type: null,
    latitude: "59.30188160",
    longitude: "26.32803120",
  },
  {
    id: 3562,
    name: "Pärnu County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "67",
    type: null,
    latitude: "58.52619520",
    longitude: "24.40201590",
  },
  {
    id: 3563,
    name: "Põlva County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "65",
    type: null,
    latitude: "58.11606220",
    longitude: "27.20663940",
  },
  {
    id: 3559,
    name: "Rapla County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "70",
    type: null,
    latitude: "58.84926250",
    longitude: "24.73465690",
  },
  {
    id: 3561,
    name: "Saare County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "74",
    type: null,
    latitude: "58.48497210",
    longitude: "22.61364080",
  },
  {
    id: 3557,
    name: "Tartu County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "78",
    type: null,
    latitude: "58.40571280",
    longitude: "26.80157600",
  },
  {
    id: 3558,
    name: "Valga County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "82",
    type: null,
    latitude: "57.91034410",
    longitude: "26.16018190",
  },
  {
    id: 3556,
    name: "Viljandi County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "84",
    type: null,
    latitude: "58.28217460",
    longitude: "25.57522330",
  },
  {
    id: 3560,
    name: "Võru County",
    country_id: 69,
    country_code: "EE",
    country_name: "Estonia",
    state_code: "86",
    type: null,
    latitude: "57.73773720",
    longitude: "27.13989380",
  },
  {
    id: 11,
    name: "Addis Ababa",
    country_id: 70,
    country_code: "ET",
    country_name: "Ethiopia",
    state_code: "AA",
    type: null,
    latitude: "8.98060340",
    longitude: "38.75776050",
  },
  {
    id: 6,
    name: "Afar Region",
    country_id: 70,
    country_code: "ET",
    country_name: "Ethiopia",
    state_code: "AF",
    type: null,
    latitude: "11.75593880",
    longitude: "40.95868800",
  },
  {
    id: 3,
    name: "Amhara Region",
    country_id: 70,
    country_code: "ET",
    country_name: "Ethiopia",
    state_code: "AM",
    type: null,
    latitude: "11.34942470",
    longitude: "37.97845850",
  },
  {
    id: 9,
    name: "Benishangul-Gumuz Region",
    country_id: 70,
    country_code: "ET",
    country_name: "Ethiopia",
    state_code: "BE",
    type: null,
    latitude: "10.78028890",
    longitude: "35.56578620",
  },
  {
    id: 8,
    name: "Dire Dawa",
    country_id: 70,
    country_code: "ET",
    country_name: "Ethiopia",
    state_code: "DD",
    type: null,
    latitude: "9.60087470",
    longitude: "41.85014200",
  },
  {
    id: 10,
    name: "Gambela Region",
    country_id: 70,
    country_code: "ET",
    country_name: "Ethiopia",
    state_code: "GA",
    type: null,
    latitude: "7.92196870",
    longitude: "34.15319470",
  },
  {
    id: 7,
    name: "Harari Region",
    country_id: 70,
    country_code: "ET",
    country_name: "Ethiopia",
    state_code: "HA",
    type: null,
    latitude: "9.31486600",
    longitude: "42.19677160",
  },
  {
    id: 5,
    name: "Oromia Region",
    country_id: 70,
    country_code: "ET",
    country_name: "Ethiopia",
    state_code: "OR",
    type: null,
    latitude: "7.54603770",
    longitude: "40.63468510",
  },
  {
    id: 2,
    name: "Somali Region",
    country_id: 70,
    country_code: "ET",
    country_name: "Ethiopia",
    state_code: "SO",
    type: null,
    latitude: "6.66122930",
    longitude: "43.79084530",
  },
  {
    id: 1,
    name: "Southern Nations, Nationalities, and Peoples' Region",
    country_id: 70,
    country_code: "ET",
    country_name: "Ethiopia",
    state_code: "SN",
    type: null,
    latitude: "6.51569110",
    longitude: "36.95410700",
  },
  {
    id: 4,
    name: "Tigray Region",
    country_id: 70,
    country_code: "ET",
    country_name: "Ethiopia",
    state_code: "TI",
    type: null,
    latitude: "14.03233360",
    longitude: "38.31657250",
  },
  {
    id: 1917,
    name: "Ba",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "01",
    type: null,
    latitude: "36.06138930",
    longitude: "-95.80058720",
  },
  {
    id: 1930,
    name: "Bua",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "02",
    type: null,
    latitude: "43.09645840",
    longitude: "-89.50088000",
  },
  {
    id: 1924,
    name: "Cakaudrove",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "03",
    type: null,
    latitude: "-16.58141050",
    longitude: "179.51200840",
  },
  {
    id: 1929,
    name: "Central Division",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "C",
    type: null,
    latitude: "34.04400660",
    longitude: "-118.24727380",
  },
  {
    id: 1932,
    name: "Eastern Division",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "E",
    type: null,
    latitude: "32.80943050",
    longitude: "-117.12899370",
  },
  {
    id: 1934,
    name: "Kadavu",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "04",
    type: null,
    latitude: "-19.01271220",
    longitude: "178.18766760",
  },
  {
    id: 1933,
    name: "Lau",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "05",
    type: null,
    latitude: "31.66870150",
    longitude: "-106.39557630",
  },
  {
    id: 1916,
    name: "Lomaiviti",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "06",
    type: null,
    latitude: "-17.70900000",
    longitude: "179.09100000",
  },
  {
    id: 1922,
    name: "Macuata",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "07",
    type: null,
    latitude: "-16.48649220",
    longitude: "179.28472510",
  },
  {
    id: 1919,
    name: "Nadroga-Navosa",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "08",
    type: null,
    latitude: "-17.98652780",
    longitude: "177.65811300",
  },
  {
    id: 1927,
    name: "Naitasiri",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "09",
    type: null,
    latitude: "-17.89757540",
    longitude: "178.20715980",
  },
  {
    id: 1928,
    name: "Namosi",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "10",
    type: null,
    latitude: "-18.08641760",
    longitude: "178.12913870",
  },
  {
    id: 1921,
    name: "Northern Division",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "N",
    type: null,
    latitude: "32.87687660",
    longitude: "-117.21563450",
  },
  {
    id: 1926,
    name: "Ra",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "11",
    type: null,
    latitude: "37.10031530",
    longitude: "-95.67442460",
  },
  {
    id: 1920,
    name: "Rewa",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "12",
    type: null,
    latitude: "34.79235170",
    longitude: "-82.36092640",
  },
  {
    id: 1931,
    name: "Rotuma",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "R",
    type: null,
    latitude: "-12.50250690",
    longitude: "177.07241640",
  },
  {
    id: 1925,
    name: "Serua",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "13",
    type: null,
    latitude: "-18.18047490",
    longitude: "178.05097900",
  },
  {
    id: 1918,
    name: "Tailevu",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "14",
    type: null,
    latitude: "-17.82691110",
    longitude: "178.29324800",
  },
  {
    id: 1923,
    name: "Western Division",
    country_id: 73,
    country_code: "FJ",
    country_name: "Fiji Islands",
    state_code: "W",
    type: null,
    latitude: "42.96621980",
    longitude: "-78.70211340",
  },
  {
    id: 1509,
    name: "Åland Islands",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "01",
    type: "region",
    latitude: "60.17852470",
    longitude: "19.91561050",
  },
  {
    id: 1511,
    name: "Central Finland",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "08",
    type: "region",
    latitude: "62.56667430",
    longitude: "25.55494450",
  },
  {
    id: 1494,
    name: "Central Ostrobothnia",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "07",
    type: "region",
    latitude: "63.56217350",
    longitude: "24.00136310",
  },
  {
    id: 1507,
    name: "Finland Proper",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "19",
    type: "region",
    latitude: "60.36279140",
    longitude: "22.44393690",
  },
  {
    id: 1496,
    name: "Kainuu",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "05",
    type: "region",
    latitude: "64.37365640",
    longitude: "28.74374750",
  },
  {
    id: 1512,
    name: "Kymenlaakso",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "09",
    type: "region",
    latitude: "60.78051200",
    longitude: "26.88293360",
  },
  {
    id: 1500,
    name: "Lapland",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "10",
    type: "region",
    latitude: "67.92223040",
    longitude: "26.50464380",
  },
  {
    id: 1504,
    name: "North Karelia",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "13",
    type: "region",
    latitude: "62.80620780",
    longitude: "30.15538870",
  },
  {
    id: 1505,
    name: "Northern Ostrobothnia",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "14",
    type: "region",
    latitude: "65.27949300",
    longitude: "26.28904170",
  },
  {
    id: 1503,
    name: "Northern Savonia",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "15",
    type: "region",
    latitude: "63.08448000",
    longitude: "27.02535040",
  },
  {
    id: 1508,
    name: "Ostrobothnia",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "12",
    type: "region",
    latitude: "63.11817570",
    longitude: "21.90610620",
  },
  {
    id: 1502,
    name: "Päijänne Tavastia",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "16",
    type: "region",
    latitude: "61.32300410",
    longitude: "25.73224960",
  },
  {
    id: 1506,
    name: "Pirkanmaa",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "11",
    type: "region",
    latitude: "61.69869180",
    longitude: "23.78955980",
  },
  {
    id: 1501,
    name: "Satakunta",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "17",
    type: "region",
    latitude: "61.59327580",
    longitude: "22.14830810",
  },
  {
    id: 1497,
    name: "South Karelia",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "02",
    type: "region",
    latitude: "61.11819490",
    longitude: "28.10243720",
  },
  {
    id: 1498,
    name: "Southern Ostrobothnia",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "03",
    type: "region",
    latitude: "62.94330990",
    longitude: "23.52852670",
  },
  {
    id: 1495,
    name: "Southern Savonia",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "04",
    type: "region",
    latitude: "61.69451480",
    longitude: "27.80050150",
  },
  {
    id: 1493,
    name: "Tavastia Proper",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "06",
    type: "region",
    latitude: "60.90701500",
    longitude: "24.30054980",
  },
  {
    id: 1510,
    name: "Uusimaa",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    state_code: "18",
    type: "region",
    latitude: "60.21872000",
    longitude: "25.27162100",
  },
  {
    id: 4967,
    name: "Ain",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "01",
    type: "metropolitan department",
    latitude: "46.06508600",
    longitude: "4.88861500",
  },
  {
    id: 4968,
    name: "Aisne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "02",
    type: "metropolitan department",
    latitude: "49.45289210",
    longitude: "3.04651110",
  },
  {
    id: 4969,
    name: "Allier",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "03",
    type: "metropolitan department",
    latitude: "46.36708630",
    longitude: "2.58082770",
  },
  {
    id: 4970,
    name: "Alpes-de-Haute-Provence",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "04",
    type: "metropolitan department",
    latitude: "44.16377520",
    longitude: "5.67247800",
  },
  {
    id: 4972,
    name: "Alpes-Maritimes",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "06",
    type: "metropolitan department",
    latitude: "43.92041700",
    longitude: "6.61678220",
  },
  {
    id: 4811,
    name: "Alsace",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "6AE",
    type: "European collectivity",
    latitude: "48.31817950",
    longitude: "7.44162410",
  },
  {
    id: 4973,
    name: "Ardèche",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "07",
    type: "metropolitan department",
    latitude: "44.81486950",
    longitude: "3.81334830",
  },
  {
    id: 4974,
    name: "Ardennes",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "08",
    type: "metropolitan department",
    latitude: "49.69759510",
    longitude: "4.14895760",
  },
  {
    id: 4975,
    name: "Ariège",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "09",
    type: "metropolitan department",
    latitude: "42.94347830",
    longitude: "0.94048640",
  },
  {
    id: 4976,
    name: "Aube",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "10",
    type: "metropolitan department",
    latitude: "48.31975470",
    longitude: "3.56371040",
  },
  {
    id: 4977,
    name: "Aude",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "11",
    type: "metropolitan department",
    latitude: "43.05411400",
    longitude: "1.90384760",
  },
  {
    id: 4798,
    name: "Auvergne-Rhône-Alpes",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "ARA",
    type: "metropolitan region",
    latitude: "45.44714310",
    longitude: "4.38525070",
  },
  {
    id: 4978,
    name: "Aveyron",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "12",
    type: "metropolitan department",
    latitude: "44.31563620",
    longitude: "2.08523790",
  },
  {
    id: 5035,
    name: "Bas-Rhin",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "67",
    type: "metropolitan department",
    latitude: "48.59864440",
    longitude: "7.02666760",
  },
  {
    id: 4979,
    name: "Bouches-du-Rhône",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "13",
    type: "metropolitan department",
    latitude: "43.54038650",
    longitude: "4.46138290",
  },
  {
    id: 4825,
    name: "Bourgogne-Franche-Comté",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "BFC",
    type: "metropolitan region",
    latitude: "47.28051270",
    longitude: "4.99943720",
  },
  {
    id: 4807,
    name: "Bretagne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "BRE",
    type: "metropolitan region",
    latitude: "48.20204710",
    longitude: "-2.93264350",
  },
  {
    id: 4981,
    name: "Calvados",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "14",
    type: "metropolitan department",
    latitude: "49.09035140",
    longitude: "-0.91706480",
  },
  {
    id: 4982,
    name: "Cantal",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "15",
    type: "metropolitan department",
    latitude: "45.04921770",
    longitude: "2.15672720",
  },
  {
    id: 4818,
    name: "Centre-Val de Loire",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "CVL",
    type: "metropolitan region",
    latitude: "47.75156860",
    longitude: "1.67506310",
  },
  {
    id: 4983,
    name: "Charente",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "16",
    type: "metropolitan department",
    latitude: "45.66584790",
    longitude: "-0.31845770",
  },
  {
    id: 4984,
    name: "Charente-Maritime",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "17",
    type: "metropolitan department",
    latitude: "45.72968280",
    longitude: "-1.33881160",
  },
  {
    id: 4985,
    name: "Cher",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "18",
    type: "metropolitan department",
    latitude: "47.02436280",
    longitude: "1.86627320",
  },
  {
    id: 5064,
    name: "Clipperton",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "CP",
    type: "dependency",
    latitude: "10.28335410",
    longitude: "-109.22542150",
  },
  {
    id: 4986,
    name: "Corrèze",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "19",
    type: "metropolitan department",
    latitude: "45.34237070",
    longitude: "1.31717330",
  },
  {
    id: 4806,
    name: "Corse",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "20R",
    type: "metropolitan collectivity with special status",
    latitude: "42.03960420",
    longitude: "9.01289260",
  },
  {
    id: 4996,
    name: "Corse-du-Sud",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "2A",
    type: "metropolitan department",
    latitude: "41.85720550",
    longitude: "8.41091830",
  },
  {
    id: 4987,
    name: "Côte-d'Or",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "21",
    type: "metropolitan department",
    latitude: "47.46513020",
    longitude: "4.23154950",
  },
  {
    id: 4988,
    name: "Côtes-d'Armor",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "22",
    type: "metropolitan department",
    latitude: "48.46633360",
    longitude: "-3.34789610",
  },
  {
    id: 4989,
    name: "Creuse",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "23",
    type: "metropolitan department",
    latitude: "46.05903940",
    longitude: "1.43150500",
  },
  {
    id: 5047,
    name: "Deux-Sèvres",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "79",
    type: "metropolitan department",
    latitude: "46.53868170",
    longitude: "-0.90199480",
  },
  {
    id: 4990,
    name: "Dordogne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "24",
    type: "metropolitan department",
    latitude: "45.14234160",
    longitude: "0.14274080",
  },
  {
    id: 4991,
    name: "Doubs",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "25",
    type: "metropolitan department",
    latitude: "46.93217740",
    longitude: "6.34762140",
  },
  {
    id: 4992,
    name: "Drôme",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "26",
    type: "metropolitan department",
    latitude: "44.72933570",
    longitude: "4.67821580",
  },
  {
    id: 5059,
    name: "Essonne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "91",
    type: "metropolitan department",
    latitude: "48.53046150",
    longitude: "1.96990560",
  },
  {
    id: 4993,
    name: "Eure",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "27",
    type: "metropolitan department",
    latitude: "49.07540350",
    longitude: "0.48937320",
  },
  {
    id: 4994,
    name: "Eure-et-Loir",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "28",
    type: "metropolitan department",
    latitude: "48.44697840",
    longitude: "0.81470250",
  },
  {
    id: 4995,
    name: "Finistère",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "29",
    type: "metropolitan department",
    latitude: "48.22696100",
    longitude: "-4.82437330",
  },
  {
    id: 4822,
    name: "French Guiana",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "973",
    type: "overseas region",
    latitude: "3.93388900",
    longitude: "-53.12578200",
  },
  {
    id: 4824,
    name: "French Polynesia",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "PF",
    type: "overseas collectivity",
    latitude: "-17.67974200",
    longitude: "-149.40684300",
  },
  {
    id: 5065,
    name: "French Southern and Antarctic Lands",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "TF",
    type: "overseas territory",
    latitude: "-47.54466040",
    longitude: "51.28375420",
  },
  {
    id: 4998,
    name: "Gard",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "30",
    type: "metropolitan department",
    latitude: "43.95952760",
    longitude: "3.49356810",
  },
  {
    id: 5000,
    name: "Gers",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "32",
    type: "metropolitan department",
    latitude: "43.69505340",
    longitude: "-0.09997280",
  },
  {
    id: 5001,
    name: "Gironde",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "33",
    type: "metropolitan department",
    latitude: "44.89584690",
    longitude: "-1.59405320",
  },
  {
    id: 4820,
    name: "Grand-Est",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "GES",
    type: "metropolitan region",
    latitude: "48.69980300",
    longitude: "6.18780740",
  },
  {
    id: 4829,
    name: "Guadeloupe",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "971",
    type: "overseas region",
    latitude: "16.26500000",
    longitude: "-61.55100000",
  },
  {
    id: 5036,
    name: "Haut-Rhin",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "68",
    type: "metropolitan department",
    latitude: "47.86537740",
    longitude: "6.67113810",
  },
  {
    id: 4997,
    name: "Haute-Corse",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "2B",
    type: "metropolitan department",
    latitude: "42.42958660",
    longitude: "8.50625610",
  },
  {
    id: 4999,
    name: "Haute-Garonne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "31",
    type: "metropolitan department",
    latitude: "43.30505550",
    longitude: "0.68455150",
  },
  {
    id: 5011,
    name: "Haute-Loire",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "43",
    type: "metropolitan department",
    latitude: "45.08538060",
    longitude: "3.22607070",
  },
  {
    id: 5020,
    name: "Haute-Marne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "52",
    type: "metropolitan department",
    latitude: "48.13248210",
    longitude: "4.69834990",
  },
  {
    id: 5039,
    name: "Haute-Saône",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "70",
    type: "metropolitan department",
    latitude: "47.63789960",
    longitude: "5.53550550",
  },
  {
    id: 5043,
    name: "Haute-Savoie",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "74",
    type: "metropolitan department",
    latitude: "46.04452770",
    longitude: "5.86413800",
  },
  {
    id: 5055,
    name: "Haute-Vienne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "87",
    type: "metropolitan department",
    latitude: "45.91868780",
    longitude: "0.70972060",
  },
  {
    id: 4971,
    name: "Hautes-Alpes",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "05",
    type: "metropolitan department",
    latitude: "44.65626820",
    longitude: "5.68732110",
  },
  {
    id: 5033,
    name: "Hautes-Pyrénées",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "65",
    type: "metropolitan department",
    latitude: "43.14294620",
    longitude: "-0.40097360",
  },
  {
    id: 4828,
    name: "Hauts-de-France",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "HDF",
    type: "metropolitan region",
    latitude: "50.48011530",
    longitude: "2.79372650",
  },
  {
    id: 5060,
    name: "Hauts-de-Seine",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "92",
    type: "metropolitan department",
    latitude: "48.84030080",
    longitude: "2.10125590",
  },
  {
    id: 5002,
    name: "Hérault",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "34",
    type: "metropolitan department",
    latitude: "43.59111200",
    longitude: "2.80661080",
  },
  {
    id: 4796,
    name: "Île-de-France",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "IDF",
    type: "metropolitan region",
    latitude: "48.84991980",
    longitude: "2.63704110",
  },
  {
    id: 5003,
    name: "Ille-et-Vilaine",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "35",
    type: "metropolitan department",
    latitude: "48.17624840",
    longitude: "-2.21304010",
  },
  {
    id: 5004,
    name: "Indre",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "36",
    type: "metropolitan department",
    latitude: "46.81175500",
    longitude: "0.97555230",
  },
  {
    id: 5005,
    name: "Indre-et-Loire",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "37",
    type: "metropolitan department",
    latitude: "47.22285820",
    longitude: "0.14896190",
  },
  {
    id: 5006,
    name: "Isère",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "38",
    type: "metropolitan department",
    latitude: "45.28922710",
    longitude: "4.99023550",
  },
  {
    id: 5007,
    name: "Jura",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "39",
    type: "metropolitan department",
    latitude: "46.78287410",
    longitude: "5.16918440",
  },
  {
    id: 4823,
    name: "La Réunion",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "974",
    type: "overseas region",
    latitude: "-21.11514100",
    longitude: "55.53638400",
  },
  {
    id: 5008,
    name: "Landes",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "40",
    type: "metropolitan department",
    latitude: "44.00950800",
    longitude: "-1.25385790",
  },
  {
    id: 5009,
    name: "Loir-et-Cher",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "41",
    type: "metropolitan department",
    latitude: "47.65937600",
    longitude: "0.85376310",
  },
  {
    id: 5010,
    name: "Loire",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "42",
    type: "metropolitan department",
    latitude: "46.35228120",
    longitude: "-1.17563390",
  },
  {
    id: 5012,
    name: "Loire-Atlantique",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "44",
    type: "metropolitan department",
    latitude: "47.34757210",
    longitude: "-2.34663120",
  },
  {
    id: 5013,
    name: "Loiret",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "45",
    type: "metropolitan department",
    latitude: "47.91354310",
    longitude: "1.76009900",
  },
  {
    id: 5014,
    name: "Lot",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "46",
    type: "metropolitan department",
    latitude: "44.62460700",
    longitude: "1.03576310",
  },
  {
    id: 5015,
    name: "Lot-et-Garonne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "47",
    type: "metropolitan department",
    latitude: "44.36873140",
    longitude: "-0.09161690",
  },
  {
    id: 5016,
    name: "Lozère",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "48",
    type: "metropolitan department",
    latitude: "44.54227790",
    longitude: "2.92934590",
  },
  {
    id: 5017,
    name: "Maine-et-Loire",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "49",
    type: "metropolitan department",
    latitude: "47.38900340",
    longitude: "-1.12025270",
  },
  {
    id: 5018,
    name: "Manche",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "50",
    type: "metropolitan department",
    latitude: "49.08817340",
    longitude: "-2.46272090",
  },
  {
    id: 5019,
    name: "Marne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "51",
    type: "metropolitan department",
    latitude: "48.96107450",
    longitude: "3.65737670",
  },
  {
    id: 4827,
    name: "Martinique",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "972",
    type: "overseas region",
    latitude: "14.64152800",
    longitude: "-61.02417400",
  },
  {
    id: 5021,
    name: "Mayenne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "53",
    type: "metropolitan department",
    latitude: "48.30668420",
    longitude: "-0.64901820",
  },
  {
    id: 4797,
    name: "Mayotte",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "976",
    type: "overseas region",
    latitude: "-12.82750000",
    longitude: "45.16624400",
  },
  {
    id: 5038,
    name: "Métropole de Lyon",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "69M",
    type: "metropolitan department",
    latitude: "45.74826290",
    longitude: "4.59584040",
  },
  {
    id: 5022,
    name: "Meurthe-et-Moselle",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "54",
    type: "metropolitan department",
    latitude: "48.95566150",
    longitude: "5.71423500",
  },
  {
    id: 5023,
    name: "Meuse",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "55",
    type: "metropolitan department",
    latitude: "49.01246200",
    longitude: "4.81087340",
  },
  {
    id: 5024,
    name: "Morbihan",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "56",
    type: "metropolitan department",
    latitude: "47.74395180",
    longitude: "-3.44555240",
  },
  {
    id: 5025,
    name: "Moselle",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "57",
    type: "metropolitan department",
    latitude: "49.02045660",
    longitude: "6.20553220",
  },
  {
    id: 5026,
    name: "Nièvre",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "58",
    type: "metropolitan department",
    latitude: "47.11921640",
    longitude: "2.97797130",
  },
  {
    id: 5027,
    name: "Nord",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "59",
    type: "metropolitan department",
    latitude: "50.52854770",
    longitude: "2.60007760",
  },
  {
    id: 4804,
    name: "Normandie",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "NOR",
    type: "metropolitan region",
    latitude: "48.87987040",
    longitude: "0.17125290",
  },
  {
    id: 4795,
    name: "Nouvelle-Aquitaine",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "NAQ",
    type: "metropolitan region",
    latitude: "45.70871820",
    longitude: "0.62689100",
  },
  {
    id: 4799,
    name: "Occitanie",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "OCC",
    type: "metropolitan region",
    latitude: "43.89272320",
    longitude: "3.28276250",
  },
  {
    id: 5028,
    name: "Oise",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "60",
    type: "metropolitan department",
    latitude: "49.41173350",
    longitude: "1.86688250",
  },
  {
    id: 5029,
    name: "Orne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "61",
    type: "metropolitan department",
    latitude: "48.57576440",
    longitude: "-0.50242950",
  },
  {
    id: 4816,
    name: "Paris",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "75C",
    type: "metropolitan collectivity with special status",
    latitude: "48.85661400",
    longitude: "2.35222190",
  },
  {
    id: 5030,
    name: "Pas-de-Calais",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "62",
    type: "metropolitan department",
    latitude: "50.51446990",
    longitude: "1.81149800",
  },
  {
    id: 4802,
    name: "Pays-de-la-Loire",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "PDL",
    type: "metropolitan region",
    latitude: "47.76328360",
    longitude: "-0.32996870",
  },
  {
    id: 4812,
    name: "Provence-Alpes-Côte-d’Azur",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "PAC",
    type: "metropolitan region",
    latitude: "43.93516910",
    longitude: "6.06791940",
  },
  {
    id: 5031,
    name: "Puy-de-Dôme",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "63",
    type: "metropolitan department",
    latitude: "45.77141850",
    longitude: "2.62626760",
  },
  {
    id: 5032,
    name: "Pyrénées-Atlantiques",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "64",
    type: "metropolitan department",
    latitude: "43.18681700",
    longitude: "-1.44170710",
  },
  {
    id: 5034,
    name: "Pyrénées-Orientales",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "66",
    type: "metropolitan department",
    latitude: "42.62541790",
    longitude: "1.88929580",
  },
  {
    id: 5037,
    name: "Rhône",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "69",
    type: "metropolitan department",
    latitude: "44.93433000",
    longitude: "4.24093290",
  },
  {
    id: 4821,
    name: "Saint Pierre and Miquelon",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "PM",
    type: "overseas collectivity",
    latitude: "46.88520000",
    longitude: "-56.31590000",
  },
  {
    id: 4794,
    name: "Saint-Barthélemy",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "BL",
    type: "overseas collectivity",
    latitude: "17.90051340",
    longitude: "-62.82058710",
  },
  {
    id: 4809,
    name: "Saint-Martin",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "MF",
    type: "overseas collectivity",
    latitude: "18.07082980",
    longitude: "-63.05008090",
  },
  {
    id: 5040,
    name: "Saône-et-Loire",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "71",
    type: "metropolitan department",
    latitude: "46.65548830",
    longitude: "3.98350500",
  },
  {
    id: 5041,
    name: "Sarthe",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "72",
    type: "metropolitan department",
    latitude: "48.02627330",
    longitude: "-0.32613170",
  },
  {
    id: 5042,
    name: "Savoie",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "73",
    type: "metropolitan department",
    latitude: "45.49469900",
    longitude: "5.84329840",
  },
  {
    id: 5045,
    name: "Seine-et-Marne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "77",
    type: "metropolitan department",
    latitude: "48.61853940",
    longitude: "2.41525610",
  },
  {
    id: 5044,
    name: "Seine-Maritime",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "76",
    type: "metropolitan department",
    latitude: "49.66096810",
    longitude: "0.36775610",
  },
  {
    id: 5061,
    name: "Seine-Saint-Denis",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "93",
    type: "metropolitan department",
    latitude: "48.90993180",
    longitude: "2.30573790",
  },
  {
    id: 5048,
    name: "Somme",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "80",
    type: "metropolitan department",
    latitude: "49.96859220",
    longitude: "1.73106960",
  },
  {
    id: 5049,
    name: "Tarn",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "81",
    type: "metropolitan department",
    latitude: "43.79149770",
    longitude: "1.67588930",
  },
  {
    id: 5050,
    name: "Tarn-et-Garonne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "82",
    type: "metropolitan department",
    latitude: "44.08089500",
    longitude: "1.08916570",
  },
  {
    id: 5058,
    name: "Territoire de Belfort",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "90",
    type: "metropolitan department",
    latitude: "47.62930720",
    longitude: "6.66962000",
  },
  {
    id: 5063,
    name: "Val-d'Oise",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "95",
    type: "metropolitan department",
    latitude: "49.07518180",
    longitude: "1.82169140",
  },
  {
    id: 5062,
    name: "Val-de-Marne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "94",
    type: "metropolitan department",
    latitude: "48.77470040",
    longitude: "2.32210390",
  },
  {
    id: 5051,
    name: "Var",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "83",
    type: "metropolitan department",
    latitude: "43.39507300",
    longitude: "5.73424170",
  },
  {
    id: 5052,
    name: "Vaucluse",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "84",
    type: "metropolitan department",
    latitude: "44.04475000",
    longitude: "4.64277180",
  },
  {
    id: 5053,
    name: "Vendée",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "85",
    type: "metropolitan department",
    latitude: "46.67541030",
    longitude: "-2.02983920",
  },
  {
    id: 5054,
    name: "Vienne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "86",
    type: "metropolitan department",
    latitude: "45.52213140",
    longitude: "4.84531360",
  },
  {
    id: 5056,
    name: "Vosges",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "88",
    type: "metropolitan department",
    latitude: "48.16301730",
    longitude: "5.73556000",
  },
  {
    id: 4810,
    name: "Wallis and Futuna",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "WF",
    type: "overseas collectivity",
    latitude: "-14.29380000",
    longitude: "-178.11650000",
  },
  {
    id: 5057,
    name: "Yonne",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "89",
    type: "metropolitan department",
    latitude: "47.85476140",
    longitude: "3.03394040",
  },
  {
    id: 5046,
    name: "Yvelines",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    state_code: "78",
    type: "metropolitan department",
    latitude: "48.76153010",
    longitude: "1.27729490",
  },
  {
    id: 2727,
    name: "Estuaire Province",
    country_id: 79,
    country_code: "GA",
    country_name: "Gabon",
    state_code: "1",
    type: null,
    latitude: "0.44328640",
    longitude: "10.08072980",
  },
  {
    id: 2726,
    name: "Haut-Ogooué Province",
    country_id: 79,
    country_code: "GA",
    country_name: "Gabon",
    state_code: "2",
    type: null,
    latitude: "-1.47625440",
    longitude: "13.91439900",
  },
  {
    id: 2730,
    name: "Moyen-Ogooué Province",
    country_id: 79,
    country_code: "GA",
    country_name: "Gabon",
    state_code: "3",
    type: null,
    latitude: "-0.44278400",
    longitude: "10.43965600",
  },
  {
    id: 2731,
    name: "Ngounié Province",
    country_id: 79,
    country_code: "GA",
    country_name: "Gabon",
    state_code: "4",
    type: null,
    latitude: "-1.49303030",
    longitude: "10.98070030",
  },
  {
    id: 2725,
    name: "Nyanga Province",
    country_id: 79,
    country_code: "GA",
    country_name: "Gabon",
    state_code: "5",
    type: null,
    latitude: "-2.88210330",
    longitude: "11.16173560",
  },
  {
    id: 2724,
    name: "Ogooué-Ivindo Province",
    country_id: 79,
    country_code: "GA",
    country_name: "Gabon",
    state_code: "6",
    type: null,
    latitude: "0.88183110",
    longitude: "13.17403480",
  },
  {
    id: 2729,
    name: "Ogooué-Lolo Province",
    country_id: 79,
    country_code: "GA",
    country_name: "Gabon",
    state_code: "7",
    type: null,
    latitude: "-0.88440930",
    longitude: "12.43805810",
  },
  {
    id: 2728,
    name: "Ogooué-Maritime Province",
    country_id: 79,
    country_code: "GA",
    country_name: "Gabon",
    state_code: "8",
    type: null,
    latitude: "-1.34659750",
    longitude: "9.72326730",
  },
  {
    id: 2723,
    name: "Woleu-Ntem Province",
    country_id: 79,
    country_code: "GA",
    country_name: "Gabon",
    state_code: "9",
    type: null,
    latitude: "2.29898270",
    longitude: "11.44669140",
  },
  {
    id: 2666,
    name: "Banjul",
    country_id: 80,
    country_code: "GM",
    country_name: "Gambia The",
    state_code: "B",
    type: null,
    latitude: "13.45487610",
    longitude: "-16.57903230",
  },
  {
    id: 2669,
    name: "Central River Division",
    country_id: 80,
    country_code: "GM",
    country_name: "Gambia The",
    state_code: "M",
    type: null,
    latitude: "13.59944690",
    longitude: "-14.89216680",
  },
  {
    id: 2670,
    name: "Lower River Division",
    country_id: 80,
    country_code: "GM",
    country_name: "Gambia The",
    state_code: "L",
    type: null,
    latitude: "13.35533060",
    longitude: "-15.92299000",
  },
  {
    id: 2671,
    name: "North Bank Division",
    country_id: 80,
    country_code: "GM",
    country_name: "Gambia The",
    state_code: "N",
    type: null,
    latitude: "13.52854360",
    longitude: "-16.01699710",
  },
  {
    id: 2668,
    name: "Upper River Division",
    country_id: 80,
    country_code: "GM",
    country_name: "Gambia The",
    state_code: "U",
    type: null,
    latitude: "13.42573660",
    longitude: "-14.00723480",
  },
  {
    id: 2667,
    name: "West Coast Division",
    country_id: 80,
    country_code: "GM",
    country_name: "Gambia The",
    state_code: "W",
    type: null,
    latitude: "5.97727980",
    longitude: "116.07542880",
  },
  {
    id: 900,
    name: "Adjara",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "AJ",
    type: null,
    latitude: "41.60056260",
    longitude: "42.06883830",
  },
  {
    id: 901,
    name: "Autonomous Republic of Abkhazia",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "AB",
    type: null,
    latitude: "43.00155440",
    longitude: "41.02340700",
  },
  {
    id: 907,
    name: "Guria",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "GU",
    type: null,
    latitude: "41.94427360",
    longitude: "42.04580910",
  },
  {
    id: 905,
    name: "Imereti",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "IM",
    type: null,
    latitude: "42.23010800",
    longitude: "42.90086640",
  },
  {
    id: 910,
    name: "Kakheti",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "KA",
    type: null,
    latitude: "41.64816020",
    longitude: "45.69055540",
  },
  {
    id: 897,
    name: "Khelvachauri Municipality",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "29",
    type: null,
    latitude: "41.58019260",
    longitude: "41.66107420",
  },
  {
    id: 904,
    name: "Kvemo Kartli",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "KK",
    type: null,
    latitude: "41.47918330",
    longitude: "44.65604510",
  },
  {
    id: 902,
    name: "Mtskheta-Mtianeti",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "MM",
    type: null,
    latitude: "42.16821850",
    longitude: "44.65060580",
  },
  {
    id: 909,
    name: "Racha-Lechkhumi and Kvemo Svaneti",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "RL",
    type: null,
    latitude: "42.67188730",
    longitude: "43.05628360",
  },
  {
    id: 908,
    name: "Samegrelo-Zemo Svaneti",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "SZ",
    type: null,
    latitude: "42.73522470",
    longitude: "42.16893620",
  },
  {
    id: 906,
    name: "Samtskhe-Javakheti",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "SJ",
    type: null,
    latitude: "41.54792960",
    longitude: "43.27764000",
  },
  {
    id: 898,
    name: "Senaki Municipality",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "50",
    type: null,
    latitude: "42.26963600",
    longitude: "42.06568960",
  },
  {
    id: 903,
    name: "Shida Kartli",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "SK",
    type: null,
    latitude: "42.07569440",
    longitude: "43.95404620",
  },
  {
    id: 899,
    name: "Tbilisi",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    state_code: "TB",
    type: null,
    latitude: "41.71513770",
    longitude: "44.82709600",
  },
  {
    id: 3006,
    name: "Baden-Württemberg",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "BW",
    type: null,
    latitude: "48.66160370",
    longitude: "9.35013360",
  },
  {
    id: 3009,
    name: "Bavaria",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "BY",
    type: null,
    latitude: "48.79044720",
    longitude: "11.49788950",
  },
  {
    id: 3010,
    name: "Berlin",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "BE",
    type: null,
    latitude: "52.52000660",
    longitude: "13.40495400",
  },
  {
    id: 3013,
    name: "Brandenburg",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "BB",
    type: null,
    latitude: "52.41252870",
    longitude: "12.53164440",
  },
  {
    id: 3014,
    name: "Bremen",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "HB",
    type: null,
    latitude: "53.07929620",
    longitude: "8.80169360",
  },
  {
    id: 3016,
    name: "Hamburg",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "HH",
    type: null,
    latitude: "53.55108460",
    longitude: "9.99368190",
  },
  {
    id: 3018,
    name: "Hesse",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "HE",
    type: null,
    latitude: "50.65205150",
    longitude: "9.16243760",
  },
  {
    id: 3008,
    name: "Lower Saxony",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "NI",
    type: null,
    latitude: "52.63670360",
    longitude: "9.84507660",
  },
  {
    id: 3007,
    name: "Mecklenburg-Vorpommern",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "MV",
    type: null,
    latitude: "53.61265050",
    longitude: "12.42959530",
  },
  {
    id: 3017,
    name: "North Rhine-Westphalia",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "NW",
    type: null,
    latitude: "51.43323670",
    longitude: "7.66159380",
  },
  {
    id: 3019,
    name: "Rhineland-Palatinate",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "RP",
    type: null,
    latitude: "50.11834600",
    longitude: "7.30895270",
  },
  {
    id: 3020,
    name: "Saarland",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "SL",
    type: null,
    latitude: "49.39642340",
    longitude: "7.02296070",
  },
  {
    id: 3021,
    name: "Saxony",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "SN",
    type: null,
    latitude: "51.10454070",
    longitude: "13.20173840",
  },
  {
    id: 3011,
    name: "Saxony-Anhalt",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "ST",
    type: null,
    latitude: "51.95026490",
    longitude: "11.69227340",
  },
  {
    id: 3005,
    name: "Schleswig-Holstein",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "SH",
    type: null,
    latitude: "54.21936720",
    longitude: "9.69611670",
  },
  {
    id: 3015,
    name: "Thuringia",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    state_code: "TH",
    type: null,
    latitude: "51.01098920",
    longitude: "10.84534600",
  },
  {
    id: 53,
    name: "Ahafo",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "AF",
    type: "region",
    latitude: "7.58213720",
    longitude: "-2.54974630",
  },
  {
    id: 48,
    name: "Ashanti",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "AH",
    type: "region",
    latitude: "6.74704360",
    longitude: "-1.52086240",
  },
  {
    id: 4959,
    name: "Bono",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "BO",
    type: "region",
    latitude: "7.65000000",
    longitude: "-2.50000000",
  },
  {
    id: 4958,
    name: "Bono East",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "BE",
    type: "region",
    latitude: "7.75000000",
    longitude: "-1.05000000",
  },
  {
    id: 52,
    name: "Central",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "CP",
    type: "region",
    latitude: "5.50000000",
    longitude: "-1.00000000",
  },
  {
    id: 50,
    name: "Eastern",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "EP",
    type: "region",
    latitude: "6.50000000",
    longitude: "-0.50000000",
  },
  {
    id: 54,
    name: "Greater Accra",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "AA",
    type: "region",
    latitude: "5.81428360",
    longitude: "0.07467670",
  },
  {
    id: 4960,
    name: "North East",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "NE",
    type: "region",
    latitude: "10.51666700",
    longitude: "-0.36666700",
  },
  {
    id: 51,
    name: "Northern",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "NP",
    type: "region",
    latitude: "9.50000000",
    longitude: "-1.00000000",
  },
  {
    id: 4961,
    name: "Oti",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "OT",
    type: "region",
    latitude: "7.90000000",
    longitude: "0.30000000",
  },
  {
    id: 4962,
    name: "Savannah",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "SV",
    type: "region",
    latitude: "9.08333300",
    longitude: "-1.81666700",
  },
  {
    id: 55,
    name: "Upper East",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "UE",
    type: "region",
    latitude: "10.70824990",
    longitude: "-0.98206680",
  },
  {
    id: 57,
    name: "Upper West",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "UW",
    type: "region",
    latitude: "10.25297570",
    longitude: "-2.14502450",
  },
  {
    id: 56,
    name: "Volta",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "TV",
    type: "region",
    latitude: "6.57813730",
    longitude: "0.45023680",
  },
  {
    id: 49,
    name: "Western",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "WP",
    type: "region",
    latitude: "5.50000000",
    longitude: "-2.50000000",
  },
  {
    id: 4963,
    name: "Western North",
    country_id: 83,
    country_code: "GH",
    country_name: "Ghana",
    state_code: "WN",
    type: "region",
    latitude: "6.30000000",
    longitude: "-2.80000000",
  },
  {
    id: 2116,
    name: "Achaea Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "13",
    type: null,
    latitude: "38.11587290",
    longitude: "21.95224910",
  },
  {
    id: 2123,
    name: "Aetolia-Acarnania Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "01",
    type: null,
    latitude: "38.70843860",
    longitude: "21.37989280",
  },
  {
    id: 2098,
    name: "Arcadia Prefecture",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "12",
    type: null,
    latitude: "37.55578250",
    longitude: "22.33377690",
  },
  {
    id: 2105,
    name: "Argolis Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "11",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2122,
    name: "Attica Region",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "I",
    type: null,
    latitude: "38.04575680",
    longitude: "23.85847370",
  },
  {
    id: 2126,
    name: "Boeotia Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "03",
    type: null,
    latitude: "38.36636640",
    longitude: "23.09650640",
  },
  {
    id: 2128,
    name: "Central Greece Region",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "H",
    type: null,
    latitude: "38.60439840",
    longitude: "22.71521310",
  },
  {
    id: 2125,
    name: "Central Macedonia",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "B",
    type: null,
    latitude: "40.62117300",
    longitude: "23.19180210",
  },
  {
    id: 2115,
    name: "Chania Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "94",
    type: null,
    latitude: "35.51382980",
    longitude: "24.01803670",
  },
  {
    id: 2124,
    name: "Corfu Prefecture",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "22",
    type: null,
    latitude: "39.62498380",
    longitude: "19.92234610",
  },
  {
    id: 2129,
    name: "Corinthia Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "15",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2109,
    name: "Crete Region",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "M",
    type: null,
    latitude: "35.24011700",
    longitude: "24.80926910",
  },
  {
    id: 2130,
    name: "Drama Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "52",
    type: null,
    latitude: "41.23400230",
    longitude: "24.23904980",
  },
  {
    id: 2120,
    name: "East Attica Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "A2",
    type: null,
    latitude: "38.20540930",
    longitude: "23.85847370",
  },
  {
    id: 2117,
    name: "East Macedonia and Thrace",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "A",
    type: null,
    latitude: "41.12951260",
    longitude: "24.88771910",
  },
  {
    id: 2110,
    name: "Epirus Region",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "D",
    type: null,
    latitude: "39.57064130",
    longitude: "20.76428430",
  },
  {
    id: 2101,
    name: "Euboea",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "04",
    type: null,
    latitude: "38.52360360",
    longitude: "23.85847370",
  },
  {
    id: 2102,
    name: "Grevena Prefecture",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "51",
    type: null,
    latitude: "40.08376260",
    longitude: "21.42732990",
  },
  {
    id: 2099,
    name: "Imathia Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "53",
    type: null,
    latitude: "40.60600670",
    longitude: "22.14302150",
  },
  {
    id: 2113,
    name: "Ioannina Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "33",
    type: null,
    latitude: "39.66502880",
    longitude: "20.85374660",
  },
  {
    id: 2131,
    name: "Ionian Islands Region",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "F",
    type: null,
    latitude: "37.96948980",
    longitude: "21.38023720",
  },
  {
    id: 2095,
    name: "Karditsa Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "41",
    type: null,
    latitude: "39.36402580",
    longitude: "21.92140490",
  },
  {
    id: 2100,
    name: "Kastoria Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "56",
    type: null,
    latitude: "40.51926910",
    longitude: "21.26871710",
  },
  {
    id: 2127,
    name: "Kefalonia Prefecture",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "23",
    type: null,
    latitude: "38.17536750",
    longitude: "20.56921790",
  },
  {
    id: 2111,
    name: "Kilkis Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "57",
    type: null,
    latitude: "40.99370710",
    longitude: "22.87536740",
  },
  {
    id: 2112,
    name: "Kozani Prefecture",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "58",
    type: null,
    latitude: "40.30055860",
    longitude: "21.78877370",
  },
  {
    id: 2106,
    name: "Laconia",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "16",
    type: null,
    latitude: "43.52785460",
    longitude: "-71.47035090",
  },
  {
    id: 2132,
    name: "Larissa Prefecture",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "42",
    type: null,
    latitude: "39.63902240",
    longitude: "22.41912540",
  },
  {
    id: 2104,
    name: "Lefkada Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "24",
    type: null,
    latitude: "38.83336630",
    longitude: "20.70691080",
  },
  {
    id: 2107,
    name: "Pella Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "59",
    type: null,
    latitude: "40.91480390",
    longitude: "22.14302150",
  },
  {
    id: 2119,
    name: "Peloponnese Region",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "J",
    type: null,
    latitude: "37.50794720",
    longitude: "22.37349000",
  },
  {
    id: 2114,
    name: "Phthiotis Prefecture",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "06",
    type: null,
    latitude: "38.99978500",
    longitude: "22.33377690",
  },
  {
    id: 2103,
    name: "Preveza Prefecture",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "34",
    type: null,
    latitude: "38.95926490",
    longitude: "20.75171550",
  },
  {
    id: 2121,
    name: "Serres Prefecture",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "62",
    type: null,
    latitude: "41.08638540",
    longitude: "23.54838190",
  },
  {
    id: 2118,
    name: "South Aegean",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "L",
    type: null,
    latitude: "37.08553020",
    longitude: "25.14892150",
  },
  {
    id: 2097,
    name: "Thessaloniki Regional Unit",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "54",
    type: null,
    latitude: "40.64006290",
    longitude: "22.94441910",
  },
  {
    id: 2096,
    name: "West Greece Region",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "G",
    type: null,
    latitude: "38.51154960",
    longitude: "21.57067860",
  },
  {
    id: 2108,
    name: "West Macedonia Region",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    state_code: "C",
    type: null,
    latitude: "40.30040580",
    longitude: "21.79035590",
  },
  {
    id: 3867,
    name: "Carriacou and Petite Martinique",
    country_id: 87,
    country_code: "GD",
    country_name: "Grenada",
    state_code: "10",
    type: null,
    latitude: "12.47858880",
    longitude: "-61.44938420",
  },
  {
    id: 3865,
    name: "Saint Andrew Parish",
    country_id: 87,
    country_code: "GD",
    country_name: "Grenada",
    state_code: "01",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3869,
    name: "Saint David Parish",
    country_id: 87,
    country_code: "GD",
    country_name: "Grenada",
    state_code: "02",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3864,
    name: "Saint George Parish",
    country_id: 87,
    country_code: "GD",
    country_name: "Grenada",
    state_code: "03",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3868,
    name: "Saint John Parish",
    country_id: 87,
    country_code: "GD",
    country_name: "Grenada",
    state_code: "04",
    type: null,
    latitude: "30.11183310",
    longitude: "-90.48799160",
  },
  {
    id: 3866,
    name: "Saint Mark Parish",
    country_id: 87,
    country_code: "GD",
    country_name: "Grenada",
    state_code: "05",
    type: null,
    latitude: "40.58818630",
    longitude: "-73.94957010",
  },
  {
    id: 3863,
    name: "Saint Patrick Parish",
    country_id: 87,
    country_code: "GD",
    country_name: "Grenada",
    state_code: "06",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3671,
    name: "Alta Verapaz Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "AV",
    type: null,
    latitude: "15.59428830",
    longitude: "-90.14949880",
  },
  {
    id: 3674,
    name: "Baja Verapaz Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "BV",
    type: null,
    latitude: "15.12558670",
    longitude: "-90.37483540",
  },
  {
    id: 3675,
    name: "Chimaltenango Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "CM",
    type: null,
    latitude: "14.56347870",
    longitude: "-90.98206680",
  },
  {
    id: 3666,
    name: "Chiquimula Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "CQ",
    type: null,
    latitude: "14.75149990",
    longitude: "-89.47421770",
  },
  {
    id: 3662,
    name: "El Progreso Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "PR",
    type: null,
    latitude: "14.93887320",
    longitude: "-90.07467670",
  },
  {
    id: 3677,
    name: "Escuintla Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "ES",
    type: null,
    latitude: "14.19109120",
    longitude: "-90.98206680",
  },
  {
    id: 3672,
    name: "Guatemala Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "GU",
    type: null,
    latitude: "14.56494010",
    longitude: "-90.52578230",
  },
  {
    id: 3670,
    name: "Huehuetenango Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "HU",
    type: null,
    latitude: "15.58799140",
    longitude: "-91.67606910",
  },
  {
    id: 3659,
    name: "Izabal Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "IZ",
    type: null,
    latitude: "15.49765170",
    longitude: "-88.86469800",
  },
  {
    id: 3658,
    name: "Jalapa Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "JA",
    type: null,
    latitude: "14.61214460",
    longitude: "-89.96267990",
  },
  {
    id: 3673,
    name: "Jutiapa Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "JU",
    type: null,
    latitude: "14.19308020",
    longitude: "-89.92532330",
  },
  {
    id: 3669,
    name: "Petén Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "PE",
    type: null,
    latitude: "16.91203300",
    longitude: "-90.29957850",
  },
  {
    id: 3668,
    name: "Quetzaltenango Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "QZ",
    type: null,
    latitude: "14.79243300",
    longitude: "-91.71495800",
  },
  {
    id: 3657,
    name: "Quiché Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "QC",
    type: null,
    latitude: "15.49838080",
    longitude: "-90.98206680",
  },
  {
    id: 3664,
    name: "Retalhuleu Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "RE",
    type: null,
    latitude: "14.52454850",
    longitude: "-91.68578800",
  },
  {
    id: 3676,
    name: "Sacatepéquez Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "SA",
    type: null,
    latitude: "14.51783790",
    longitude: "-90.71527490",
  },
  {
    id: 3667,
    name: "San Marcos Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "SM",
    type: null,
    latitude: "14.93095690",
    longitude: "-91.90992380",
  },
  {
    id: 3665,
    name: "Santa Rosa Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "SR",
    type: null,
    latitude: "38.44057590",
    longitude: "-122.70375430",
  },
  {
    id: 3661,
    name: "Sololá Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "SO",
    type: null,
    latitude: "14.74852300",
    longitude: "-91.28910360",
  },
  {
    id: 3660,
    name: "Suchitepéquez Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "SU",
    type: null,
    latitude: "14.42159820",
    longitude: "-91.40482490",
  },
  {
    id: 3663,
    name: "Totonicapán Department",
    country_id: 90,
    country_code: "GT",
    country_name: "Guatemala",
    state_code: "TO",
    type: null,
    latitude: "14.91734020",
    longitude: "-91.36139230",
  },
  {
    id: 2672,
    name: "Beyla Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "BE",
    type: null,
    latitude: "8.91981780",
    longitude: "-8.30884410",
  },
  {
    id: 2699,
    name: "Boffa Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "BF",
    type: null,
    latitude: "10.18082540",
    longitude: "-14.03916150",
  },
  {
    id: 2709,
    name: "Boké Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "BK",
    type: null,
    latitude: "11.08473790",
    longitude: "-14.37919120",
  },
  {
    id: 2676,
    name: "Boké Region",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "B",
    type: null,
    latitude: "11.18646720",
    longitude: "-14.10013260",
  },
  {
    id: 2686,
    name: "Conakry",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "C",
    type: null,
    latitude: "9.64118550",
    longitude: "-13.57840120",
  },
  {
    id: 2705,
    name: "Coyah Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "CO",
    type: null,
    latitude: "9.77155350",
    longitude: "-13.31252990",
  },
  {
    id: 2679,
    name: "Dabola Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "DB",
    type: null,
    latitude: "10.72978060",
    longitude: "-11.11078540",
  },
  {
    id: 2706,
    name: "Dalaba Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "DL",
    type: null,
    latitude: "10.68681760",
    longitude: "-12.24906970",
  },
  {
    id: 2688,
    name: "Dinguiraye Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "DI",
    type: null,
    latitude: "11.68442220",
    longitude: "-10.80000510",
  },
  {
    id: 2681,
    name: "Dubréka Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "DU",
    type: null,
    latitude: "9.79073480",
    longitude: "-13.51477350",
  },
  {
    id: 2682,
    name: "Faranah Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "FA",
    type: null,
    latitude: "9.90573990",
    longitude: "-10.80000510",
  },
  {
    id: 2683,
    name: "Forécariah Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "FO",
    type: null,
    latitude: "9.38861870",
    longitude: "-13.08179030",
  },
  {
    id: 2675,
    name: "Fria Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "FR",
    type: null,
    latitude: "10.36745430",
    longitude: "-13.58418710",
  },
  {
    id: 2685,
    name: "Gaoual Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "GA",
    type: null,
    latitude: "11.57628040",
    longitude: "-13.35872880",
  },
  {
    id: 2711,
    name: "Guéckédou Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "GU",
    type: null,
    latitude: "8.56496880",
    longitude: "-10.13111630",
  },
  {
    id: 2704,
    name: "Kankan Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "KA",
    type: null,
    latitude: "10.30344650",
    longitude: "-9.36730840",
  },
  {
    id: 2697,
    name: "Kankan Region",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "K",
    type: null,
    latitude: "10.12092300",
    longitude: "-9.54509740",
  },
  {
    id: 2710,
    name: "Kérouané Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "KE",
    type: null,
    latitude: "9.25366430",
    longitude: "-9.01289260",
  },
  {
    id: 2693,
    name: "Kindia Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "KD",
    type: null,
    latitude: "10.10132920",
    longitude: "-12.71351210",
  },
  {
    id: 2701,
    name: "Kindia Region",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "D",
    type: null,
    latitude: "10.17816940",
    longitude: "-12.98961500",
  },
  {
    id: 2691,
    name: "Kissidougou Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "KS",
    type: null,
    latitude: "9.22520220",
    longitude: "-10.08072980",
  },
  {
    id: 2692,
    name: "Koubia Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "KB",
    type: null,
    latitude: "11.58235400",
    longitude: "-11.89202370",
  },
  {
    id: 2703,
    name: "Koundara Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "KN",
    type: null,
    latitude: "12.48940210",
    longitude: "-13.30675620",
  },
  {
    id: 2695,
    name: "Kouroussa Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "KO",
    type: null,
    latitude: "10.64892290",
    longitude: "-9.88505860",
  },
  {
    id: 2680,
    name: "Labé Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "LA",
    type: null,
    latitude: "11.35419390",
    longitude: "-12.34638750",
  },
  {
    id: 2677,
    name: "Labé Region",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "L",
    type: null,
    latitude: "11.32320420",
    longitude: "-12.28913140",
  },
  {
    id: 2690,
    name: "Lélouma Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "LE",
    type: null,
    latitude: "11.18333300",
    longitude: "-12.93333300",
  },
  {
    id: 2708,
    name: "Lola Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "LO",
    type: null,
    latitude: "7.96138180",
    longitude: "-8.39649380",
  },
  {
    id: 2702,
    name: "Macenta Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "MC",
    type: null,
    latitude: "8.46157950",
    longitude: "-9.27855830",
  },
  {
    id: 2700,
    name: "Mali Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "ML",
    type: null,
    latitude: "11.98370900",
    longitude: "-12.25479190",
  },
  {
    id: 2689,
    name: "Mamou Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "MM",
    type: null,
    latitude: "10.57360240",
    longitude: "-11.88917210",
  },
  {
    id: 2698,
    name: "Mamou Region",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "M",
    type: null,
    latitude: "10.57360240",
    longitude: "-11.88917210",
  },
  {
    id: 2673,
    name: "Mandiana Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "MD",
    type: null,
    latitude: "10.61728270",
    longitude: "-8.69857160",
  },
  {
    id: 2678,
    name: "Nzérékoré Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "NZ",
    type: null,
    latitude: "7.74783590",
    longitude: "-8.82525020",
  },
  {
    id: 2684,
    name: "Nzérékoré Region",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "N",
    type: null,
    latitude: "8.03858700",
    longitude: "-8.83627550",
  },
  {
    id: 2694,
    name: "Pita Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "PI",
    type: null,
    latitude: "10.80620860",
    longitude: "-12.71351210",
  },
  {
    id: 2707,
    name: "Siguiri Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "SI",
    type: null,
    latitude: "11.41481130",
    longitude: "-9.17883040",
  },
  {
    id: 2687,
    name: "Télimélé Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "TE",
    type: null,
    latitude: "10.90893640",
    longitude: "-13.02993310",
  },
  {
    id: 2696,
    name: "Tougué Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "TO",
    type: null,
    latitude: "11.38415830",
    longitude: "-11.61577730",
  },
  {
    id: 2674,
    name: "Yomou Prefecture",
    country_id: 92,
    country_code: "GN",
    country_name: "Guinea",
    state_code: "YO",
    type: null,
    latitude: "7.56962790",
    longitude: "-9.25915710",
  },
  {
    id: 2720,
    name: "Bafatá",
    country_id: 93,
    country_code: "GW",
    country_name: "Guinea-Bissau",
    state_code: "BA",
    type: null,
    latitude: "12.17352430",
    longitude: "-14.65295200",
  },
  {
    id: 2714,
    name: "Biombo Region",
    country_id: 93,
    country_code: "GW",
    country_name: "Guinea-Bissau",
    state_code: "BM",
    type: null,
    latitude: "11.85290610",
    longitude: "-15.73511710",
  },
  {
    id: 2722,
    name: "Bolama Region",
    country_id: 93,
    country_code: "GW",
    country_name: "Guinea-Bissau",
    state_code: "BL",
    type: null,
    latitude: "11.14805910",
    longitude: "-16.13457050",
  },
  {
    id: 2713,
    name: "Cacheu Region",
    country_id: 93,
    country_code: "GW",
    country_name: "Guinea-Bissau",
    state_code: "CA",
    type: null,
    latitude: "12.05514160",
    longitude: "-16.06401790",
  },
  {
    id: 2719,
    name: "Gabú Region",
    country_id: 93,
    country_code: "GW",
    country_name: "Guinea-Bissau",
    state_code: "GA",
    type: null,
    latitude: "11.89624880",
    longitude: "-14.10013260",
  },
  {
    id: 2721,
    name: "Leste Province",
    country_id: 93,
    country_code: "GW",
    country_name: "Guinea-Bissau",
    state_code: "L",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2717,
    name: "Norte Province",
    country_id: 93,
    country_code: "GW",
    country_name: "Guinea-Bissau",
    state_code: "N",
    type: null,
    latitude: "7.87218110",
    longitude: "123.88577470",
  },
  {
    id: 2718,
    name: "Oio Region",
    country_id: 93,
    country_code: "GW",
    country_name: "Guinea-Bissau",
    state_code: "OI",
    type: null,
    latitude: "12.27607090",
    longitude: "-15.31311850",
  },
  {
    id: 2715,
    name: "Quinara Region",
    country_id: 93,
    country_code: "GW",
    country_name: "Guinea-Bissau",
    state_code: "QU",
    type: null,
    latitude: "11.79556200",
    longitude: "-15.17268160",
  },
  {
    id: 2716,
    name: "Sul Province",
    country_id: 93,
    country_code: "GW",
    country_name: "Guinea-Bissau",
    state_code: "S",
    type: null,
    latitude: "-10.28665780",
    longitude: "20.71224650",
  },
  {
    id: 2712,
    name: "Tombali Region",
    country_id: 93,
    country_code: "GW",
    country_name: "Guinea-Bissau",
    state_code: "TO",
    type: null,
    latitude: "11.36326960",
    longitude: "-14.98561760",
  },
  {
    id: 2764,
    name: "Barima-Waini",
    country_id: 94,
    country_code: "GY",
    country_name: "Guyana",
    state_code: "BA",
    type: null,
    latitude: "7.48824190",
    longitude: "-59.65644940",
  },
  {
    id: 2760,
    name: "Cuyuni-Mazaruni",
    country_id: 94,
    country_code: "GY",
    country_name: "Guyana",
    state_code: "CU",
    type: null,
    latitude: "6.46421410",
    longitude: "-60.21107520",
  },
  {
    id: 2767,
    name: "Demerara-Mahaica",
    country_id: 94,
    country_code: "GY",
    country_name: "Guyana",
    state_code: "DE",
    type: null,
    latitude: "6.54642600",
    longitude: "-58.09820460",
  },
  {
    id: 2766,
    name: "East Berbice-Corentyne",
    country_id: 94,
    country_code: "GY",
    country_name: "Guyana",
    state_code: "EB",
    type: null,
    latitude: "2.74779220",
    longitude: "-57.46272590",
  },
  {
    id: 2768,
    name: "Essequibo Islands-West Demerara",
    country_id: 94,
    country_code: "GY",
    country_name: "Guyana",
    state_code: "ES",
    type: null,
    latitude: "6.57201320",
    longitude: "-58.46299970",
  },
  {
    id: 2762,
    name: "Mahaica-Berbice",
    country_id: 94,
    country_code: "GY",
    country_name: "Guyana",
    state_code: "MA",
    type: null,
    latitude: "6.23849600",
    longitude: "-57.91625550",
  },
  {
    id: 2765,
    name: "Pomeroon-Supenaam",
    country_id: 94,
    country_code: "GY",
    country_name: "Guyana",
    state_code: "PM",
    type: null,
    latitude: "7.12941660",
    longitude: "-58.92062950",
  },
  {
    id: 2761,
    name: "Potaro-Siparuni",
    country_id: 94,
    country_code: "GY",
    country_name: "Guyana",
    state_code: "PT",
    type: null,
    latitude: "4.78558530",
    longitude: "-59.28799770",
  },
  {
    id: 2763,
    name: "Upper Demerara-Berbice",
    country_id: 94,
    country_code: "GY",
    country_name: "Guyana",
    state_code: "UD",
    type: null,
    latitude: "5.30648790",
    longitude: "-58.18929210",
  },
  {
    id: 2769,
    name: "Upper Takutu-Upper Essequibo",
    country_id: 94,
    country_code: "GY",
    country_name: "Guyana",
    state_code: "UT",
    type: null,
    latitude: "2.92395950",
    longitude: "-58.73736340",
  },
  {
    id: 4123,
    name: "Artibonite",
    country_id: 95,
    country_code: "HT",
    country_name: "Haiti",
    state_code: "AR",
    type: null,
    latitude: "19.36290200",
    longitude: "-72.42581450",
  },
  {
    id: 4125,
    name: "Centre",
    country_id: 95,
    country_code: "HT",
    country_name: "Haiti",
    state_code: "CE",
    type: null,
    latitude: "32.83702510",
    longitude: "-96.77738820",
  },
  {
    id: 4119,
    name: "Grand'Anse",
    country_id: 95,
    country_code: "HT",
    country_name: "Haiti",
    state_code: "GA",
    type: null,
    latitude: "12.01666670",
    longitude: "-61.76666670",
  },
  {
    id: 4118,
    name: "Nippes",
    country_id: 95,
    country_code: "HT",
    country_name: "Haiti",
    state_code: "NI",
    type: null,
    latitude: "18.39907350",
    longitude: "-73.41802110",
  },
  {
    id: 4117,
    name: "Nord",
    country_id: 95,
    country_code: "HT",
    country_name: "Haiti",
    state_code: "ND",
    type: null,
    latitude: "43.19052600",
    longitude: "-89.43792100",
  },
  {
    id: 4121,
    name: "Nord-Est",
    country_id: 95,
    country_code: "HT",
    country_name: "Haiti",
    state_code: "NE",
    type: null,
    latitude: "19.48897230",
    longitude: "-71.85713310",
  },
  {
    id: 4126,
    name: "Nord-Ouest",
    country_id: 95,
    country_code: "HT",
    country_name: "Haiti",
    state_code: "NO",
    type: null,
    latitude: "19.83740090",
    longitude: "-73.04052770",
  },
  {
    id: 4120,
    name: "Ouest",
    country_id: 95,
    country_code: "HT",
    country_name: "Haiti",
    state_code: "OU",
    type: null,
    latitude: "45.45472490",
    longitude: "-73.65023650",
  },
  {
    id: 4122,
    name: "Sud",
    country_id: 95,
    country_code: "HT",
    country_name: "Haiti",
    state_code: "SD",
    type: null,
    latitude: "29.92132480",
    longitude: "-90.09737720",
  },
  {
    id: 4124,
    name: "Sud-Est",
    country_id: 95,
    country_code: "HT",
    country_name: "Haiti",
    state_code: "SE",
    type: null,
    latitude: "18.27835980",
    longitude: "-72.35479150",
  },
  {
    id: 4047,
    name: "Atlántida Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "AT",
    type: null,
    latitude: "15.66962830",
    longitude: "-87.14228950",
  },
  {
    id: 4045,
    name: "Bay Islands Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "IB",
    type: null,
    latitude: "16.48266140",
    longitude: "-85.87932520",
  },
  {
    id: 4041,
    name: "Choluteca Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "CH",
    type: null,
    latitude: "13.25043250",
    longitude: "-87.14228950",
  },
  {
    id: 4051,
    name: "Colón Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "CL",
    type: null,
    latitude: "15.64259650",
    longitude: "-85.52002400",
  },
  {
    id: 4042,
    name: "Comayagua Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "CM",
    type: null,
    latitude: "14.55348280",
    longitude: "-87.61863790",
  },
  {
    id: 4049,
    name: "Copán Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "CP",
    type: null,
    latitude: "14.93608380",
    longitude: "-88.86469800",
  },
  {
    id: 4046,
    name: "Cortés Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "CR",
    type: null,
    latitude: "15.49235080",
    longitude: "-88.09007620",
  },
  {
    id: 4043,
    name: "El Paraíso Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "EP",
    type: null,
    latitude: "13.98212940",
    longitude: "-86.49965460",
  },
  {
    id: 4052,
    name: "Francisco Morazán Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "FM",
    type: null,
    latitude: "14.45411000",
    longitude: "-87.06242610",
  },
  {
    id: 4048,
    name: "Gracias a Dios Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "GD",
    type: null,
    latitude: "15.34180600",
    longitude: "-84.60604490",
  },
  {
    id: 4044,
    name: "Intibucá Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "IN",
    type: null,
    latitude: "14.37273400",
    longitude: "-88.24611830",
  },
  {
    id: 4058,
    name: "La Paz Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "LP",
    type: null,
    latitude: "-15.08924160",
    longitude: "-68.52471490",
  },
  {
    id: 4054,
    name: "Lempira Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "LE",
    type: null,
    latitude: "14.18876980",
    longitude: "-88.55653100",
  },
  {
    id: 4056,
    name: "Ocotepeque Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "OC",
    type: null,
    latitude: "14.51703470",
    longitude: "-89.05615320",
  },
  {
    id: 4050,
    name: "Olancho Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "OL",
    type: null,
    latitude: "14.80674060",
    longitude: "-85.76666450",
  },
  {
    id: 4053,
    name: "Santa Bárbara Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "SB",
    type: null,
    latitude: "15.12027950",
    longitude: "-88.40160410",
  },
  {
    id: 4055,
    name: "Valle Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "VA",
    type: null,
    latitude: "13.57829360",
    longitude: "-87.57912870",
  },
  {
    id: 4057,
    name: "Yoro Department",
    country_id: 97,
    country_code: "HN",
    country_name: "Honduras",
    state_code: "YO",
    type: null,
    latitude: "15.29496790",
    longitude: "-87.14228950",
  },
  {
    id: 4889,
    name: "Central and Western District",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "HCW",
    type: null,
    latitude: "22.28666000",
    longitude: "114.15497000",
  },
  {
    id: 4891,
    name: "Eastern",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "HEA",
    type: null,
    latitude: "22.28411000",
    longitude: "114.22414000",
  },
  {
    id: 4888,
    name: "Islands District",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "NIS",
    type: null,
    latitude: "22.26114000",
    longitude: "113.94608000",
  },
  {
    id: 4895,
    name: "Kowloon City",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "KKC",
    type: null,
    latitude: "22.32820000",
    longitude: "114.19155000",
  },
  {
    id: 4898,
    name: "Kwai Tsing",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "NKT",
    type: null,
    latitude: "22.35488000",
    longitude: "114.08401000",
  },
  {
    id: 4897,
    name: "Kwun Tong",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "KKT",
    type: null,
    latitude: "22.31326000",
    longitude: "114.22581000",
  },
  {
    id: 4900,
    name: "North",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "NNO",
    type: null,
    latitude: "22.49471000",
    longitude: "114.13812000",
  },
  {
    id: 4887,
    name: "Sai Kung District",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "NSK",
    type: null,
    latitude: "22.38143000",
    longitude: "114.27052000",
  },
  {
    id: 4901,
    name: "Sha Tin",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "NST",
    type: null,
    latitude: "22.38715000",
    longitude: "114.19534000",
  },
  {
    id: 4894,
    name: "Sham Shui Po",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "KSS",
    type: null,
    latitude: "22.33074000",
    longitude: "114.16220000",
  },
  {
    id: 4892,
    name: "Southern",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "HSO",
    type: null,
    latitude: "22.24725000",
    longitude: "114.15884000",
  },
  {
    id: 4885,
    name: "Tai Po District",
    country_id: 98,
    country_code: "KH",
    country_name: "Hong Kong S.A.R.",
    state_code: "NTP",
    type: null,
    latitude: "22.45085000",
    longitude: "114.16422000",
  },
  {
    id: 4884,
    name: "Tsuen Wan District",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "NTW",
    type: null,
    latitude: "22.36281000",
    longitude: "114.12907000",
  },
  {
    id: 4899,
    name: "Tuen Mun",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "NTM",
    type: null,
    latitude: "22.39163000",
    longitude: "113.97708850",
  },
  {
    id: 4890,
    name: "Wan Chai",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "HWC",
    type: null,
    latitude: "22.27968000",
    longitude: "114.17168000",
  },
  {
    id: 4896,
    name: "Wong Tai Sin",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "KWT",
    type: null,
    latitude: "22.33353000",
    longitude: "114.19686000",
  },
  {
    id: 4893,
    name: "Yau Tsim Mong",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "KYT",
    type: null,
    latitude: "22.32138000",
    longitude: "114.17260000",
  },
  {
    id: 4883,
    name: "Yuen Long District",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    state_code: "NYL",
    type: null,
    latitude: "22.44559000",
    longitude: "114.02218000",
  },
  {
    id: 1048,
    name: "Bács-Kiskun",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "BK",
    type: "county",
    latitude: "46.56614370",
    longitude: "19.42724640",
  },
  {
    id: 1055,
    name: "Baranya",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "BA",
    type: "county",
    latitude: "46.04845850",
    longitude: "18.27191730",
  },
  {
    id: 1060,
    name: "Békés",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "BE",
    type: "county",
    latitude: "46.67048990",
    longitude: "21.04349960",
  },
  {
    id: 1036,
    name: "Békéscsaba",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "BC",
    type: "city with county rights",
    latitude: "46.67359390",
    longitude: "21.08773090",
  },
  {
    id: 1058,
    name: "Borsod-Abaúj-Zemplén",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "BZ",
    type: "county",
    latitude: "48.29394010",
    longitude: "20.69341120",
  },
  {
    id: 1064,
    name: "Budapest",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "BU",
    type: "capital city",
    latitude: "47.49791200",
    longitude: "19.04023500",
  },
  {
    id: 1031,
    name: "Csongrád County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "CS",
    type: "county",
    latitude: "46.41670500",
    longitude: "20.25661610",
  },
  {
    id: 1032,
    name: "Debrecen",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "DE",
    type: "city with county rights",
    latitude: "47.53160490",
    longitude: "21.62731240",
  },
  {
    id: 1049,
    name: "Dunaújváros",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "DU",
    type: "city with county rights",
    latitude: "46.96190590",
    longitude: "18.93552270",
  },
  {
    id: 1037,
    name: "Eger",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "EG",
    type: "city with county rights",
    latitude: "47.90253480",
    longitude: "20.37722840",
  },
  {
    id: 1028,
    name: "Érd",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "ER",
    type: "city with county rights",
    latitude: "47.39197180",
    longitude: "18.90454400",
  },
  {
    id: 1044,
    name: "Fejér County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "FE",
    type: "county",
    latitude: "47.12179320",
    longitude: "18.52948150",
  },
  {
    id: 1041,
    name: "Győr",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "GY",
    type: "city with county rights",
    latitude: "47.68745690",
    longitude: "17.65039740",
  },
  {
    id: 1042,
    name: "Győr-Moson-Sopron County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "GS",
    type: "county",
    latitude: "47.65092850",
    longitude: "17.25058830",
  },
  {
    id: 1063,
    name: "Hajdú-Bihar County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "HB",
    type: "county",
    latitude: "47.46883550",
    longitude: "21.54532270",
  },
  {
    id: 1040,
    name: "Heves County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "HE",
    type: "county",
    latitude: "47.80576170",
    longitude: "20.20385590",
  },
  {
    id: 1027,
    name: "Hódmezővásárhely",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "HV",
    type: "city with county rights",
    latitude: "46.41812620",
    longitude: "20.33003150",
  },
  {
    id: 1043,
    name: "Jász-Nagykun-Szolnok County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "JN",
    type: "county",
    latitude: "47.25555790",
    longitude: "20.52324560",
  },
  {
    id: 1067,
    name: "Kaposvár",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "KV",
    type: "city with county rights",
    latitude: "46.35936060",
    longitude: "17.79676390",
  },
  {
    id: 1056,
    name: "Kecskemét",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "KM",
    type: "city with county rights",
    latitude: "46.89637110",
    longitude: "19.68968610",
  },
  {
    id: 5085,
    name: "Komárom-Esztergom",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "KE",
    type: "county",
    latitude: "47.57797860",
    longitude: "18.12568550",
  },
  {
    id: 1065,
    name: "Miskolc",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "MI",
    type: "city with county rights",
    latitude: "48.10347750",
    longitude: "20.77843840",
  },
  {
    id: 1030,
    name: "Nagykanizsa",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "NK",
    type: "city with county rights",
    latitude: "46.45902180",
    longitude: "16.98967960",
  },
  {
    id: 1051,
    name: "Nógrád County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "NO",
    type: "county",
    latitude: "47.90410310",
    longitude: "19.04985040",
  },
  {
    id: 1034,
    name: "Nyíregyháza",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "NY",
    type: "city with county rights",
    latitude: "47.94953240",
    longitude: "21.72440530",
  },
  {
    id: 1053,
    name: "Pécs",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "PS",
    type: "city with county rights",
    latitude: "46.07273450",
    longitude: "18.23226600",
  },
  {
    id: 1059,
    name: "Pest County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "PE",
    type: "county",
    latitude: "47.44800010",
    longitude: "19.46181280",
  },
  {
    id: 1068,
    name: "Salgótarján",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "ST",
    type: "city with county rights",
    latitude: "48.09352370",
    longitude: "19.79998130",
  },
  {
    id: 1035,
    name: "Somogy County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "SO",
    type: "county",
    latitude: "46.55485900",
    longitude: "17.58667320",
  },
  {
    id: 1057,
    name: "Sopron",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "SN",
    type: "city with county rights",
    latitude: "47.68166190",
    longitude: "16.58447950",
  },
  {
    id: 1045,
    name: "Szabolcs-Szatmár-Bereg County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "SZ",
    type: "county",
    latitude: "48.03949540",
    longitude: "22.00333000",
  },
  {
    id: 1029,
    name: "Szeged",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "SD",
    type: "city with county rights",
    latitude: "46.25301020",
    longitude: "20.14142530",
  },
  {
    id: 1033,
    name: "Székesfehérvár",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "SF",
    type: "city with county rights",
    latitude: "47.18602620",
    longitude: "18.42213580",
  },
  {
    id: 1061,
    name: "Szekszárd",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "SS",
    type: "city with county rights",
    latitude: "46.34743260",
    longitude: "18.70622930",
  },
  {
    id: 1047,
    name: "Szolnok",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "SK",
    type: "city with county rights",
    latitude: "47.16213550",
    longitude: "20.18247120",
  },
  {
    id: 1052,
    name: "Szombathely",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "SH",
    type: "city with county rights",
    latitude: "47.23068510",
    longitude: "16.62184410",
  },
  {
    id: 1066,
    name: "Tatabánya",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "TB",
    type: "city with county rights",
    latitude: "47.56924600",
    longitude: "18.40481800",
  },
  {
    id: 1038,
    name: "Tolna County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "TO",
    type: "county",
    latitude: "46.47627540",
    longitude: "18.55706270",
  },
  {
    id: 1039,
    name: "Vas County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "VA",
    type: "county",
    latitude: "47.09291110",
    longitude: "16.68121830",
  },
  {
    id: 1062,
    name: "Veszprém",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "VM",
    type: "city with county rights",
    latitude: "47.10280870",
    longitude: "17.90930190",
  },
  {
    id: 1054,
    name: "Veszprém County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "VE",
    type: "county",
    latitude: "47.09309740",
    longitude: "17.91007630",
  },
  {
    id: 1046,
    name: "Zala County",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "ZA",
    type: "county",
    latitude: "46.73844040",
    longitude: "16.91522520",
  },
  {
    id: 1050,
    name: "Zalaegerszeg",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    state_code: "ZE",
    type: "county",
    latitude: "46.84169360",
    longitude: "16.84163220",
  },
  {
    id: 3431,
    name: "Capital Region",
    country_id: 100,
    country_code: "IS",
    country_name: "Iceland",
    state_code: "1",
    type: null,
    latitude: "38.56569570",
    longitude: "-92.18169490",
  },
  {
    id: 3433,
    name: "Eastern Region",
    country_id: 100,
    country_code: "IS",
    country_name: "Iceland",
    state_code: "7",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3437,
    name: "Northeastern Region",
    country_id: 100,
    country_code: "IS",
    country_name: "Iceland",
    state_code: "6",
    type: null,
    latitude: "43.29942850",
    longitude: "-74.21793260",
  },
  {
    id: 3435,
    name: "Northwestern Region",
    country_id: 100,
    country_code: "IS",
    country_name: "Iceland",
    state_code: "5",
    type: null,
    latitude: "41.91339320",
    longitude: "-73.04716880",
  },
  {
    id: 3430,
    name: "Southern Peninsula Region",
    country_id: 100,
    country_code: "IS",
    country_name: "Iceland",
    state_code: "2",
    type: null,
    latitude: "63.91548030",
    longitude: "-22.36496670",
  },
  {
    id: 3434,
    name: "Southern Region",
    country_id: 100,
    country_code: "IS",
    country_name: "Iceland",
    state_code: "8",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3436,
    name: "Western Region",
    country_id: 100,
    country_code: "IS",
    country_name: "Iceland",
    state_code: "3",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3432,
    name: "Westfjords",
    country_id: 100,
    country_code: "IS",
    country_name: "Iceland",
    state_code: "4",
    type: null,
    latitude: "65.91961500",
    longitude: "-21.88117640",
  },
  {
    id: 4023,
    name: "Andaman and Nicobar Islands",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "AN",
    type: "Union territory",
    latitude: "11.74008670",
    longitude: "92.65864010",
  },
  {
    id: 4017,
    name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "AP",
    type: "state",
    latitude: "15.91289980",
    longitude: "79.73998750",
  },
  {
    id: 4024,
    name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "AR",
    type: "state",
    latitude: "28.21799940",
    longitude: "94.72775280",
  },
  {
    id: 4027,
    name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "AS",
    type: "state",
    latitude: "26.20060430",
    longitude: "92.93757390",
  },
  {
    id: 4037,
    name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "BR",
    type: "state",
    latitude: "25.09607420",
    longitude: "85.31311940",
  },
  {
    id: 4031,
    name: "Chandigarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "CH",
    type: "Union territory",
    latitude: "30.73331480",
    longitude: "76.77941790",
  },
  {
    id: 4040,
    name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "CT",
    type: "state",
    latitude: "21.27865670",
    longitude: "81.86614420",
  },
  {
    id: 4033,
    name: "Dadra and Nagar Haveli and Daman and Diu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "DH",
    type: "Union territory",
    latitude: "20.39737360",
    longitude: "72.83279910",
  },
  {
    id: 4021,
    name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "DL",
    type: "Union territory",
    latitude: "28.70405920",
    longitude: "77.10249020",
  },
  {
    id: 4009,
    name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "GA",
    type: "state",
    latitude: "15.29932650",
    longitude: "74.12399600",
  },
  {
    id: 4030,
    name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "GJ",
    type: "state",
    latitude: "22.25865200",
    longitude: "71.19238050",
  },
  {
    id: 4007,
    name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "HR",
    type: "state",
    latitude: "29.05877570",
    longitude: "76.08560100",
  },
  {
    id: 4020,
    name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "HP",
    type: "state",
    latitude: "31.10482940",
    longitude: "77.17339010",
  },
  {
    id: 4029,
    name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "JK",
    type: "Union territory",
    latitude: "33.27783900",
    longitude: "75.34121790",
  },
  {
    id: 4025,
    name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "JH",
    type: "state",
    latitude: "23.61018080",
    longitude: "85.27993540",
  },
  {
    id: 4026,
    name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "KA",
    type: "state",
    latitude: "15.31727750",
    longitude: "75.71388840",
  },
  {
    id: 4028,
    name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "KL",
    type: "state",
    latitude: "10.85051590",
    longitude: "76.27108330",
  },
  {
    id: 4852,
    name: "Ladakh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "LA",
    type: "Union territory",
    latitude: "34.22684750",
    longitude: "77.56194190",
  },
  {
    id: 4019,
    name: "Lakshadweep",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "LD",
    type: "Union territory",
    latitude: "10.32802650",
    longitude: "72.78463360",
  },
  {
    id: 4039,
    name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "MP",
    type: "state",
    latitude: "22.97342290",
    longitude: "78.65689420",
  },
  {
    id: 4008,
    name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "MH",
    type: "state",
    latitude: "19.75147980",
    longitude: "75.71388840",
  },
  {
    id: 4010,
    name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "MN",
    type: "state",
    latitude: "24.66371730",
    longitude: "93.90626880",
  },
  {
    id: 4006,
    name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "ML",
    type: "state",
    latitude: "25.46703080",
    longitude: "91.36621600",
  },
  {
    id: 4036,
    name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "MZ",
    type: "state",
    latitude: "23.16454300",
    longitude: "92.93757390",
  },
  {
    id: 4018,
    name: "Nagaland",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "NL",
    type: "state",
    latitude: "26.15843540",
    longitude: "94.56244260",
  },
  {
    id: 4013,
    name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "OR",
    type: "state",
    latitude: "20.95166580",
    longitude: "85.09852360",
  },
  {
    id: 4011,
    name: "Puducherry",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "PY",
    type: "Union territory",
    latitude: "11.94159150",
    longitude: "79.80831330",
  },
  {
    id: 4015,
    name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "PB",
    type: "state",
    latitude: "31.14713050",
    longitude: "75.34121790",
  },
  {
    id: 4014,
    name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "RJ",
    type: "state",
    latitude: "27.02380360",
    longitude: "74.21793260",
  },
  {
    id: 4034,
    name: "Sikkim",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "SK",
    type: "state",
    latitude: "27.53297180",
    longitude: "88.51221780",
  },
  {
    id: 4035,
    name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "TN",
    type: "state",
    latitude: "11.12712250",
    longitude: "78.65689420",
  },
  {
    id: 4012,
    name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "TG",
    type: "state",
    latitude: "18.11243720",
    longitude: "79.01929970",
  },
  {
    id: 4038,
    name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "TR",
    type: "state",
    latitude: "23.94084820",
    longitude: "91.98815270",
  },
  {
    id: 4022,
    name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "UP",
    type: "state",
    latitude: "26.84670880",
    longitude: "80.94615920",
  },
  {
    id: 4016,
    name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "UT",
    type: "state",
    latitude: "30.06675300",
    longitude: "79.01929970",
  },
  {
    id: 4853,
    name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    state_code: "WB",
    type: "state",
    latitude: "22.98675690",
    longitude: "87.85497550",
  },
  {
    id: 1822,
    name: "Aceh",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "AC",
    type: "province",
    latitude: "4.69513500",
    longitude: "96.74939930",
  },
  {
    id: 1826,
    name: "Bali",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "BA",
    type: "province",
    latitude: "-8.34053890",
    longitude: "115.09195090",
  },
  {
    id: 1810,
    name: "Banten",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "BT",
    type: "province",
    latitude: "-6.40581720",
    longitude: "106.06401790",
  },
  {
    id: 1793,
    name: "Bengkulu",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "BE",
    type: "province",
    latitude: "-3.79284510",
    longitude: "102.26076410",
  },
  {
    id: 1829,
    name: "DI Yogyakarta",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "YO",
    type: "province",
    latitude: "-7.87538490",
    longitude: "110.42620880",
  },
  {
    id: 1805,
    name: "DKI Jakarta",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "JK",
    type: "capital district",
    latitude: "-6.20876340",
    longitude: "106.84559900",
  },
  {
    id: 1812,
    name: "Gorontalo",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "GO",
    type: "province",
    latitude: "0.54354420",
    longitude: "123.05676930",
  },
  {
    id: 1815,
    name: "Jambi",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "JA",
    type: "province",
    latitude: "-1.61012290",
    longitude: "103.61312030",
  },
  {
    id: 1825,
    name: "Jawa Barat",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "JB",
    type: "province",
    latitude: "-7.09091100",
    longitude: "107.66888700",
  },
  {
    id: 1802,
    name: "Jawa Tengah",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "JT",
    type: "province",
    latitude: "-7.15097500",
    longitude: "110.14025940",
  },
  {
    id: 1827,
    name: "Jawa Timur",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "JI",
    type: "province",
    latitude: "-7.53606390",
    longitude: "112.23840170",
  },
  {
    id: 1806,
    name: "Kalimantan Barat",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "KB",
    type: "province",
    latitude: "0.47734750",
    longitude: "106.61314050",
  },
  {
    id: 1819,
    name: "Kalimantan Selatan",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "KS",
    type: "province",
    latitude: "-3.09264150",
    longitude: "115.28375850",
  },
  {
    id: 1794,
    name: "Kalimantan Tengah",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "KT",
    type: "province",
    latitude: "-1.68148780",
    longitude: "113.38235450",
  },
  {
    id: 1804,
    name: "Kalimantan Timur",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "KI",
    type: "province",
    latitude: "0.53865860",
    longitude: "116.41938900",
  },
  {
    id: 1824,
    name: "Kalimantan Utara",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "KU",
    type: "province",
    latitude: "3.07309290",
    longitude: "116.04138890",
  },
  {
    id: 1820,
    name: "Kepulauan Bangka Belitung",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "BB",
    type: "province",
    latitude: "-2.74105130",
    longitude: "106.44058720",
  },
  {
    id: 1807,
    name: "Kepulauan Riau",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "KR",
    type: "province",
    latitude: "3.94565140",
    longitude: "108.14286690",
  },
  {
    id: 1811,
    name: "Lampung",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "LA",
    type: "province",
    latitude: "-4.55858490",
    longitude: "105.40680790",
  },
  {
    id: 1800,
    name: "Maluku",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "MA",
    type: "province",
    latitude: "-3.23846160",
    longitude: "130.14527340",
  },
  {
    id: 1801,
    name: "Maluku Utara",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "MU",
    type: "province",
    latitude: "1.57099930",
    longitude: "127.80876930",
  },
  {
    id: 1814,
    name: "Nusa Tenggara Barat",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "NB",
    type: "province",
    latitude: "-8.65293340",
    longitude: "117.36164760",
  },
  {
    id: 1818,
    name: "Nusa Tenggara Timur",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "NT",
    type: "province",
    latitude: "-8.65738190",
    longitude: "121.07937050",
  },
  {
    id: 1798,
    name: "Papua",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "PA",
    type: "province",
    latitude: "-5.01222020",
    longitude: "141.34701590",
  },
  {
    id: 1799,
    name: "Papua Barat",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "PB",
    type: "province",
    latitude: "-1.33611540",
    longitude: "133.17471620",
  },
  {
    id: 1809,
    name: "Riau",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "RI",
    type: "province",
    latitude: "0.29334690",
    longitude: "101.70682940",
  },
  {
    id: 1817,
    name: "Sulawesi Barat",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "SR",
    type: "province",
    latitude: "-2.84413710",
    longitude: "119.23207840",
  },
  {
    id: 1795,
    name: "Sulawesi Selatan",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "SN",
    type: "province",
    latitude: "-3.66879940",
    longitude: "119.97405340",
  },
  {
    id: 1813,
    name: "Sulawesi Tengah",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "ST",
    type: "province",
    latitude: "-1.43002540",
    longitude: "121.44561790",
  },
  {
    id: 1796,
    name: "Sulawesi Tenggara",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "SG",
    type: "province",
    latitude: "-4.14491000",
    longitude: "122.17460500",
  },
  {
    id: 1808,
    name: "Sulawesi Utara",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "SA",
    type: "province",
    latitude: "0.62469320",
    longitude: "123.97500180",
  },
  {
    id: 1828,
    name: "Sumatera Barat",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "SB",
    type: "province",
    latitude: "-0.73993970",
    longitude: "100.80000510",
  },
  {
    id: 1816,
    name: "Sumatera Selatan",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "SS",
    type: "province",
    latitude: "-3.31943740",
    longitude: "103.91439900",
  },
  {
    id: 1792,
    name: "Sumatera Utara",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    state_code: "SU",
    type: "province",
    latitude: "2.11535470",
    longitude: "99.54509740",
  },
  {
    id: 3929,
    name: "Alborz",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "30",
    type: "province",
    latitude: "35.99604670",
    longitude: "50.92892460",
  },
  {
    id: 3934,
    name: "Ardabil",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "24",
    type: "province",
    latitude: "38.48532760",
    longitude: "47.89112090",
  },
  {
    id: 3932,
    name: "Bushehr",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "18",
    type: "province",
    latitude: "28.76207390",
    longitude: "51.51500770",
  },
  {
    id: 3921,
    name: "Chaharmahal and Bakhtiari",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "14",
    type: "province",
    latitude: "31.99704190",
    longitude: "50.66138490",
  },
  {
    id: 3944,
    name: "East Azerbaijan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "03",
    type: "province",
    latitude: "37.90357330",
    longitude: "46.26821090",
  },
  {
    id: 3939,
    name: "Fars",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "07",
    type: "province",
    latitude: "29.10438130",
    longitude: "53.04589300",
  },
  {
    id: 3920,
    name: "Gilan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "01",
    type: "province",
    latitude: "37.28094550",
    longitude: "49.59241340",
  },
  {
    id: 3933,
    name: "Golestan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "27",
    type: "province",
    latitude: "37.28981230",
    longitude: "55.13758340",
  },
  {
    id: 4920,
    name: "Hamadan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "13",
    type: "province",
    latitude: "34.91936070",
    longitude: "47.48329250",
  },
  {
    id: 3937,
    name: "Hormozgan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "22",
    type: "province",
    latitude: "27.13872300",
    longitude: "55.13758340",
  },
  {
    id: 3918,
    name: "Ilam",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "16",
    type: "province",
    latitude: "33.29576180",
    longitude: "46.67053400",
  },
  {
    id: 3923,
    name: "Isfahan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "10",
    type: "province",
    latitude: "33.27710730",
    longitude: "52.36133780",
  },
  {
    id: 3943,
    name: "Kerman",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "08",
    type: "province",
    latitude: "29.48500890",
    longitude: "57.64390480",
  },
  {
    id: 3919,
    name: "Kermanshah",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "05",
    type: "province",
    latitude: "34.45762330",
    longitude: "46.67053400",
  },
  {
    id: 3917,
    name: "Khuzestan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "06",
    type: "province",
    latitude: "31.43601490",
    longitude: "49.04131200",
  },
  {
    id: 3926,
    name: "Kohgiluyeh and Boyer-Ahmad",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "17",
    type: "province",
    latitude: "30.72458600",
    longitude: "50.84563230",
  },
  {
    id: 3935,
    name: "Kurdistan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "12",
    type: "province",
    latitude: "35.95535790",
    longitude: "47.13621250",
  },
  {
    id: 3928,
    name: "Lorestan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "15",
    type: "province",
    latitude: "33.58183940",
    longitude: "48.39881860",
  },
  {
    id: 3916,
    name: "Markazi",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "00",
    type: "province",
    latitude: "34.61230500",
    longitude: "49.85472660",
  },
  {
    id: 3938,
    name: "Mazandaran",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "02",
    type: "province",
    latitude: "36.22623930",
    longitude: "52.53186040",
  },
  {
    id: 3942,
    name: "North Khorasan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "28",
    type: "province",
    latitude: "37.47103530",
    longitude: "57.10131880",
  },
  {
    id: 3941,
    name: "Qazvin",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "26",
    type: "province",
    latitude: "36.08813170",
    longitude: "49.85472660",
  },
  {
    id: 3922,
    name: "Qom",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "25",
    type: "province",
    latitude: "34.64157640",
    longitude: "50.87460350",
  },
  {
    id: 3927,
    name: "Razavi Khorasan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "09",
    type: "province",
    latitude: "35.10202530",
    longitude: "59.10417580",
  },
  {
    id: 3940,
    name: "Semnan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "20",
    type: "province",
    latitude: "35.22555850",
    longitude: "54.43421380",
  },
  {
    id: 3931,
    name: "Sistan and Baluchestan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "11",
    type: "province",
    latitude: "27.52999060",
    longitude: "60.58206760",
  },
  {
    id: 3930,
    name: "South Khorasan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "29",
    type: "province",
    latitude: "32.51756430",
    longitude: "59.10417580",
  },
  {
    id: 3945,
    name: "Tehran",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "23",
    type: "province",
    latitude: "35.72484160",
    longitude: "51.38165300",
  },
  {
    id: 3924,
    name: "West Azarbaijan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "04",
    type: "province",
    latitude: "37.45500620",
    longitude: "45.00000000",
  },
  {
    id: 3936,
    name: "Yazd",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "21",
    type: "province",
    latitude: "32.10063870",
    longitude: "54.43421380",
  },
  {
    id: 3925,
    name: "Zanjan",
    country_id: 103,
    country_code: "IR",
    country_name: "Iran",
    state_code: "19",
    type: "province",
    latitude: "36.50181850",
    longitude: "48.39881860",
  },
  {
    id: 3964,
    name: "Al Anbar",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "AN",
    type: "governorate",
    latitude: "32.55976140",
    longitude: "41.91964710",
  },
  {
    id: 3958,
    name: "Al Muthanna",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "MU",
    type: "governorate",
    latitude: "29.91331710",
    longitude: "45.29938620",
  },
  {
    id: 3956,
    name: "Al-Qādisiyyah",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "QA",
    type: "governorate",
    latitude: "32.04369100",
    longitude: "45.14945050",
  },
  {
    id: 3955,
    name: "Babylon",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "BB",
    type: "governorate",
    latitude: "32.46819100",
    longitude: "44.55019350",
  },
  {
    id: 3959,
    name: "Baghdad",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "BG",
    type: "governorate",
    latitude: "33.31526180",
    longitude: "44.36606530",
  },
  {
    id: 3960,
    name: "Basra",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "BA",
    type: "governorate",
    latitude: "30.51142520",
    longitude: "47.82962530",
  },
  {
    id: 3954,
    name: "Dhi Qar",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "DQ",
    type: "governorate",
    latitude: "31.10422920",
    longitude: "46.36246860",
  },
  {
    id: 3965,
    name: "Diyala",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "DI",
    type: "governorate",
    latitude: "33.77334870",
    longitude: "45.14945050",
  },
  {
    id: 3967,
    name: "Dohuk",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "DA",
    type: "governorate",
    latitude: "36.90772520",
    longitude: "43.06316890",
  },
  {
    id: 3968,
    name: "Erbil",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "AR",
    type: "governorate",
    latitude: "36.55706280",
    longitude: "44.38512630",
  },
  {
    id: 3957,
    name: "Karbala",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "KA",
    type: "governorate",
    latitude: "32.40454930",
    longitude: "43.86732220",
  },
  {
    id: 3971,
    name: "Kirkuk",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "KI",
    type: "governorate",
    latitude: "35.32920140",
    longitude: "43.94367880",
  },
  {
    id: 3966,
    name: "Maysan",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "MA",
    type: "governorate",
    latitude: "31.87340020",
    longitude: "47.13621250",
  },
  {
    id: 3962,
    name: "Najaf",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "NA",
    type: "governorate",
    latitude: "31.35174860",
    longitude: "44.09603110",
  },
  {
    id: 3963,
    name: "Nineveh",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "NI",
    type: "governorate",
    latitude: "36.22957400",
    longitude: "42.23624350",
  },
  {
    id: 3961,
    name: "Saladin",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "SD",
    type: "governorate",
    latitude: "34.53375270",
    longitude: "43.48373800",
  },
  {
    id: 3969,
    name: "Sulaymaniyah",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "SU",
    type: "governorate",
    latitude: "35.54663480",
    longitude: "45.30036830",
  },
  {
    id: 3970,
    name: "Wasit",
    country_id: 104,
    country_code: "IQ",
    country_name: "Iraq",
    state_code: "WA",
    type: "governorate",
    latitude: "32.60240940",
    longitude: "45.75209850",
  },
  {
    id: 1095,
    name: "Carlow",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "CW",
    type: "county",
    latitude: "52.72322170",
    longitude: "-6.81082950",
  },
  {
    id: 1088,
    name: "Cavan",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "CN",
    type: "county",
    latitude: "53.97654240",
    longitude: "-7.29966230",
  },
  {
    id: 1091,
    name: "Clare",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "CE",
    type: "county",
    latitude: "43.04664000",
    longitude: "-87.89958100",
  },
  {
    id: 1087,
    name: "Connacht",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "C",
    type: "province",
    latitude: "53.83762430",
    longitude: "-8.95844810",
  },
  {
    id: 1074,
    name: "Cork",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "CO",
    type: "county",
    latitude: "51.89851430",
    longitude: "-8.47560350",
  },
  {
    id: 1071,
    name: "Donegal",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "DL",
    type: "county",
    latitude: "54.65489930",
    longitude: "-8.10409670",
  },
  {
    id: 1072,
    name: "Dublin",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "D",
    type: "county",
    latitude: "53.34980530",
    longitude: "-6.26030970",
  },
  {
    id: 1079,
    name: "Galway",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "G",
    type: "county",
    latitude: "53.35645090",
    longitude: "-8.85341130",
  },
  {
    id: 1077,
    name: "Kerry",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "KY",
    type: "county",
    latitude: "52.15446070",
    longitude: "-9.56686330",
  },
  {
    id: 1082,
    name: "Kildare",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "KE",
    type: "county",
    latitude: "53.21204340",
    longitude: "-6.81947080",
  },
  {
    id: 1090,
    name: "Kilkenny",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "KK",
    type: "county",
    latitude: "52.57769570",
    longitude: "-7.21800200",
  },
  {
    id: 1096,
    name: "Laois",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "LS",
    type: "county",
    latitude: "52.99429500",
    longitude: "-7.33230070",
  },
  {
    id: 1073,
    name: "Leinster",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "L",
    type: "province",
    latitude: "53.32715380",
    longitude: "-7.51408410",
  },
  {
    id: 1094,
    name: "Limerick",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "LK",
    type: "county",
    latitude: "52.50905170",
    longitude: "-8.74749550",
  },
  {
    id: 1076,
    name: "Longford",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "LD",
    type: "county",
    latitude: "53.72749820",
    longitude: "-7.79315270",
  },
  {
    id: 1083,
    name: "Louth",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "LH",
    type: "county",
    latitude: "53.92523240",
    longitude: "-6.48894230",
  },
  {
    id: 1084,
    name: "Mayo",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "MO",
    type: "county",
    latitude: "54.01526040",
    longitude: "-9.42893690",
  },
  {
    id: 1092,
    name: "Meath",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "MH",
    type: "county",
    latitude: "53.60554800",
    longitude: "-6.65641690",
  },
  {
    id: 1075,
    name: "Monaghan",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "MN",
    type: "county",
    latitude: "54.24920460",
    longitude: "-6.96831320",
  },
  {
    id: 1080,
    name: "Munster",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "M",
    type: "province",
    latitude: "51.94711970",
    longitude: "7.58453200",
  },
  {
    id: 1078,
    name: "Offaly",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "OY",
    type: "county",
    latitude: "53.23568710",
    longitude: "-7.71222290",
  },
  {
    id: 1081,
    name: "Roscommon",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "RN",
    type: "county",
    latitude: "53.75926040",
    longitude: "-8.26816210",
  },
  {
    id: 1070,
    name: "Sligo",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "SO",
    type: "county",
    latitude: "54.15532770",
    longitude: "-8.60645320",
  },
  {
    id: 1069,
    name: "Tipperary",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "TA",
    type: "county",
    latitude: "52.47378940",
    longitude: "-8.16185140",
  },
  {
    id: 1086,
    name: "Ulster",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "U",
    type: "province",
    latitude: "54.76165550",
    longitude: "-6.96122730",
  },
  {
    id: 1089,
    name: "Waterford",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "WD",
    type: "county",
    latitude: "52.19435490",
    longitude: "-7.62275120",
  },
  {
    id: 1097,
    name: "Westmeath",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "WH",
    type: "county",
    latitude: "53.53453080",
    longitude: "-7.46532170",
  },
  {
    id: 1093,
    name: "Wexford",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "WX",
    type: "county",
    latitude: "52.47936030",
    longitude: "-6.58399130",
  },
  {
    id: 1085,
    name: "Wicklow",
    country_id: 105,
    country_code: "IE",
    country_name: "Ireland",
    state_code: "WW",
    type: "county",
    latitude: "52.98623130",
    longitude: "-6.36725430",
  },
  {
    id: 1367,
    name: "Central District",
    country_id: 106,
    country_code: "IL",
    country_name: "Israel",
    state_code: "M",
    type: null,
    latitude: "47.60875830",
    longitude: "-122.29642350",
  },
  {
    id: 1369,
    name: "Haifa District",
    country_id: 106,
    country_code: "IL",
    country_name: "Israel",
    state_code: "HA",
    type: null,
    latitude: "32.48141110",
    longitude: "34.99475100",
  },
  {
    id: 1370,
    name: "Jerusalem District",
    country_id: 106,
    country_code: "IL",
    country_name: "Israel",
    state_code: "JM",
    type: null,
    latitude: "31.76482430",
    longitude: "34.99475100",
  },
  {
    id: 1366,
    name: "Northern District",
    country_id: 106,
    country_code: "IL",
    country_name: "Israel",
    state_code: "Z",
    type: null,
    latitude: "36.15118640",
    longitude: "-95.99517630",
  },
  {
    id: 1368,
    name: "Southern District",
    country_id: 106,
    country_code: "IL",
    country_name: "Israel",
    state_code: "D",
    type: null,
    latitude: "40.71375860",
    longitude: "-74.00090590",
  },
  {
    id: 1371,
    name: "Tel Aviv District",
    country_id: 106,
    country_code: "IL",
    country_name: "Israel",
    state_code: "TA",
    type: null,
    latitude: "32.09290750",
    longitude: "34.80721650",
  },
  {
    id: 1679,
    name: "Abruzzo",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "65",
    type: "region",
    latitude: "42.19201190",
    longitude: "13.72891670",
  },
  {
    id: 1727,
    name: "Agrigento",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "AG",
    type: "free municipal consortium",
    latitude: "37.31052020",
    longitude: "13.58579780",
  },
  {
    id: 1783,
    name: "Alessandria",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "AL",
    type: "province",
    latitude: "44.81755870",
    longitude: "8.70466270",
  },
  {
    id: 1672,
    name: "Ancona",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "AN",
    type: "province",
    latitude: "43.54932450",
    longitude: "13.26634790",
  },
  {
    id: 1716,
    name: "Aosta Valley",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "23",
    type: "autonomous region",
    latitude: "45.73888780",
    longitude: "7.42618660",
  },
  {
    id: 1688,
    name: "Apulia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "75",
    type: "region",
    latitude: "40.79283930",
    longitude: "17.10119310",
  },
  {
    id: 1681,
    name: "Ascoli Piceno",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "AP",
    type: "province",
    latitude: "42.86389330",
    longitude: "13.58997330",
  },
  {
    id: 1780,
    name: "Asti",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "AT",
    type: "province",
    latitude: "44.90076520",
    longitude: "8.20643150",
  },
  {
    id: 1692,
    name: "Avellino",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "AV",
    type: "province",
    latitude: "40.99645100",
    longitude: "15.12589550",
  },
  {
    id: 1686,
    name: "Barletta-Andria-Trani",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "BT",
    type: "province",
    latitude: "41.20045430",
    longitude: "16.20514840",
  },
  {
    id: 1706,
    name: "Basilicata",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "77",
    type: "region",
    latitude: "40.64307660",
    longitude: "15.96998780",
  },
  {
    id: 1689,
    name: "Belluno",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "BL",
    type: "province",
    latitude: "46.24976590",
    longitude: "12.19695650",
  },
  {
    id: 1701,
    name: "Benevento",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "BN",
    type: "province",
    latitude: "41.20350930",
    longitude: "14.75209390",
  },
  {
    id: 1704,
    name: "Bergamo",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "BG",
    type: "province",
    latitude: "45.69826420",
    longitude: "9.67726980",
  },
  {
    id: 1778,
    name: "Biella",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "BI",
    type: "province",
    latitude: "45.56281760",
    longitude: "8.05827170",
  },
  {
    id: 1717,
    name: "Brescia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "BS",
    type: "province",
    latitude: "45.54155260",
    longitude: "10.21180190",
  },
  {
    id: 1714,
    name: "Brindisi",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "BR",
    type: "province",
    latitude: "40.61126630",
    longitude: "17.76362100",
  },
  {
    id: 1703,
    name: "Calabria",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "78",
    type: "region",
    latitude: "39.30877140",
    longitude: "16.34637910",
  },
  {
    id: 1718,
    name: "Caltanissetta",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "CL",
    type: "free municipal consortium",
    latitude: "37.48601300",
    longitude: "14.06149820",
  },
  {
    id: 1669,
    name: "Campania",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "72",
    type: "region",
    latitude: "40.66708870",
    longitude: "15.10681130",
  },
  {
    id: 1721,
    name: "Campobasso",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "CB",
    type: "province",
    latitude: "41.67388650",
    longitude: "14.75209390",
  },
  {
    id: 1731,
    name: "Caserta",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "CE",
    type: "province",
    latitude: "41.20783540",
    longitude: "14.10013260",
  },
  {
    id: 1728,
    name: "Catanzaro",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "CZ",
    type: "province",
    latitude: "38.88963480",
    longitude: "16.44058720",
  },
  {
    id: 1739,
    name: "Chieti",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "CH",
    type: "province",
    latitude: "42.03344280",
    longitude: "14.37919120",
  },
  {
    id: 1740,
    name: "Como",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "CO",
    type: "province",
    latitude: "45.80804160",
    longitude: "9.08517930",
  },
  {
    id: 1742,
    name: "Cosenza",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "CS",
    type: "province",
    latitude: "39.56441050",
    longitude: "16.25221430",
  },
  {
    id: 1751,
    name: "Cremona",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "CR",
    type: "province",
    latitude: "45.20143750",
    longitude: "9.98365820",
  },
  {
    id: 1754,
    name: "Crotone",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "KR",
    type: "province",
    latitude: "39.13098560",
    longitude: "17.00670310",
  },
  {
    id: 1775,
    name: "Cuneo",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "CN",
    type: "province",
    latitude: "44.59703140",
    longitude: "7.61142170",
  },
  {
    id: 1773,
    name: "Emilia-Romagna",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "45",
    type: "region",
    latitude: "44.59676070",
    longitude: "11.21863960",
  },
  {
    id: 1723,
    name: "Enna",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "EN",
    type: "free municipal consortium",
    latitude: "37.56762160",
    longitude: "14.27953490",
  },
  {
    id: 1744,
    name: "Fermo",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "FM",
    type: "province",
    latitude: "43.09313670",
    longitude: "13.58997330",
  },
  {
    id: 1746,
    name: "Ferrara",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "FE",
    type: "province",
    latitude: "44.76636800",
    longitude: "11.76440680",
  },
  {
    id: 1771,
    name: "Foggia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "FG",
    type: "province",
    latitude: "41.63844800",
    longitude: "15.59433880",
  },
  {
    id: 1779,
    name: "Forlì-Cesena",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "FC",
    type: "province",
    latitude: "43.99476810",
    longitude: "11.98046130",
  },
  {
    id: 1756,
    name: "Friuli–Venezia Giulia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "36",
    type: "autonomous region",
    latitude: "46.22591770",
    longitude: "13.10336460",
  },
  {
    id: 1776,
    name: "Frosinone",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "FR",
    type: "province",
    latitude: "41.65765280",
    longitude: "13.63627150",
  },
  {
    id: 1777,
    name: "Gorizia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "GO",
    type: "decentralized regional entity",
    latitude: "45.90538990",
    longitude: "13.51637250",
  },
  {
    id: 1787,
    name: "Grosseto",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "GR",
    type: "province",
    latitude: "42.85180070",
    longitude: "11.25237920",
  },
  {
    id: 1788,
    name: "Imperia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "IM",
    type: "province",
    latitude: "43.94186600",
    longitude: "7.82863680",
  },
  {
    id: 1789,
    name: "Isernia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "IS",
    type: "province",
    latitude: "41.58915550",
    longitude: "14.19309180",
  },
  {
    id: 1781,
    name: "L'Aquila",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "AQ",
    type: "province",
    latitude: "42.12563170",
    longitude: "13.63627150",
  },
  {
    id: 1791,
    name: "La Spezia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "SP",
    type: "province",
    latitude: "44.24479130",
    longitude: "9.76786870",
  },
  {
    id: 1674,
    name: "Latina",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "LT",
    type: "province",
    latitude: "41.40874760",
    longitude: "13.08179030",
  },
  {
    id: 1678,
    name: "Lazio",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "62",
    type: "region",
    latitude: "41.81224100",
    longitude: "12.73851000",
  },
  {
    id: 1675,
    name: "Lecce",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "LE",
    type: "province",
    latitude: "40.23473930",
    longitude: "18.14286690",
  },
  {
    id: 1677,
    name: "Lecco",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "LC",
    type: "province",
    latitude: "45.93829410",
    longitude: "9.38572900",
  },
  {
    id: 1768,
    name: "Liguria",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "42",
    type: "region",
    latitude: "44.31679170",
    longitude: "8.39649380",
  },
  {
    id: 1745,
    name: "Livorno",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "LI",
    type: "province",
    latitude: "43.02398480",
    longitude: "10.66471010",
  },
  {
    id: 1747,
    name: "Lodi",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "LO",
    type: "province",
    latitude: "45.24050360",
    longitude: "9.52925120",
  },
  {
    id: 1705,
    name: "Lombardy",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "25",
    type: "region",
    latitude: "45.47906710",
    longitude: "9.84524330",
  },
  {
    id: 1749,
    name: "Lucca",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "LU",
    type: "province",
    latitude: "43.83767360",
    longitude: "10.49505300",
  },
  {
    id: 1750,
    name: "Macerata",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "MC",
    type: "province",
    latitude: "43.24593220",
    longitude: "13.26634790",
  },
  {
    id: 1758,
    name: "Mantua",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "MN",
    type: "province",
    latitude: "45.16677280",
    longitude: "10.77536130",
  },
  {
    id: 1670,
    name: "Marche",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "57",
    type: "region",
    latitude: "43.30456200",
    longitude: "13.71947000",
  },
  {
    id: 1759,
    name: "Massa and Carrara",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "MS",
    type: "province",
    latitude: "44.22139980",
    longitude: "10.03596610",
  },
  {
    id: 1760,
    name: "Matera",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "MT",
    type: "province",
    latitude: "40.66634960",
    longitude: "16.60436360",
  },
  {
    id: 1761,
    name: "Medio Campidano",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "VS",
    type: "province",
    latitude: "39.53173890",
    longitude: "8.70407500",
  },
  {
    id: 1757,
    name: "Modena",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "MO",
    type: "province",
    latitude: "44.55137990",
    longitude: "10.91804800",
  },
  {
    id: 1695,
    name: "Molise",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "67",
    type: "region",
    latitude: "41.67388650",
    longitude: "14.75209390",
  },
  {
    id: 1769,
    name: "Monza and Brianza",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "MB",
    type: "province",
    latitude: "45.62359900",
    longitude: "9.25880150",
  },
  {
    id: 1774,
    name: "Novara",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "NO",
    type: "province",
    latitude: "45.54851330",
    longitude: "8.51507930",
  },
  {
    id: 1790,
    name: "Nuoro",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "NU",
    type: "province",
    latitude: "40.32869040",
    longitude: "9.45615500",
  },
  {
    id: 1786,
    name: "Oristano",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "OR",
    type: "province",
    latitude: "40.05990680",
    longitude: "8.74811670",
  },
  {
    id: 1665,
    name: "Padua",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "PD",
    type: "province",
    latitude: "45.36618640",
    longitude: "11.82091390",
  },
  {
    id: 1668,
    name: "Palermo",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "PA",
    type: "province",
    latitude: "38.11569000",
    longitude: "13.36148680",
  },
  {
    id: 1666,
    name: "Parma",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "PR",
    type: "province",
    latitude: "44.80153220",
    longitude: "10.32793540",
  },
  {
    id: 1676,
    name: "Pavia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "PV",
    type: "province",
    latitude: "45.32181660",
    longitude: "8.84662360",
  },
  {
    id: 1691,
    name: "Perugia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "PG",
    type: "province",
    latitude: "42.93800400",
    longitude: "12.62162110",
  },
  {
    id: 1693,
    name: "Pesaro and Urbino",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "PU",
    type: "province",
    latitude: "43.61301180",
    longitude: "12.71351210",
  },
  {
    id: 1694,
    name: "Pescara",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "PE",
    type: "province",
    latitude: "42.35706550",
    longitude: "13.96080910",
  },
  {
    id: 1696,
    name: "Piacenza",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "PC",
    type: "province",
    latitude: "44.82631120",
    longitude: "9.52914470",
  },
  {
    id: 1702,
    name: "Piedmont",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "21",
    type: "region",
    latitude: "45.05223660",
    longitude: "7.51538850",
  },
  {
    id: 1685,
    name: "Pisa",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "PI",
    type: "province",
    latitude: "43.72283150",
    longitude: "10.40171940",
  },
  {
    id: 1687,
    name: "Pistoia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "PT",
    type: "province",
    latitude: "43.95437330",
    longitude: "10.89030990",
  },
  {
    id: 1690,
    name: "Pordenone",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "PN",
    type: "decentralized regional entity",
    latitude: "46.03788620",
    longitude: "12.71083500",
  },
  {
    id: 1697,
    name: "Potenza",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "PZ",
    type: "province",
    latitude: "40.41821940",
    longitude: "15.87600400",
  },
  {
    id: 1700,
    name: "Prato",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "PO",
    type: "province",
    latitude: "44.04539000",
    longitude: "11.11644520",
  },
  {
    id: 1729,
    name: "Ragusa",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "RG",
    type: "free municipal consortium",
    latitude: "36.92692730",
    longitude: "14.72551290",
  },
  {
    id: 1707,
    name: "Ravenna",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "RA",
    type: "province",
    latitude: "44.41844430",
    longitude: "12.20359980",
  },
  {
    id: 1708,
    name: "Reggio Emilia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "RE",
    type: "province",
    latitude: "44.58565800",
    longitude: "10.55647360",
  },
  {
    id: 1712,
    name: "Rieti",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "RI",
    type: "province",
    latitude: "42.36744050",
    longitude: "12.89750980",
  },
  {
    id: 1713,
    name: "Rimini",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "RN",
    type: "province",
    latitude: "44.06782880",
    longitude: "12.56951580",
  },
  {
    id: 1719,
    name: "Rovigo",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "RO",
    type: "province",
    latitude: "45.02418180",
    longitude: "11.82381620",
  },
  {
    id: 1720,
    name: "Salerno",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "SA",
    type: "province",
    latitude: "40.42878320",
    longitude: "15.21948080",
  },
  {
    id: 1715,
    name: "Sardinia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "88",
    type: "autonomous region",
    latitude: "40.12087520",
    longitude: "9.01289260",
  },
  {
    id: 1722,
    name: "Sassari",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "SS",
    type: "province",
    latitude: "40.79679070",
    longitude: "8.57504070",
  },
  {
    id: 1732,
    name: "Savona",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "SV",
    type: "province",
    latitude: "44.28879950",
    longitude: "8.26505800",
  },
  {
    id: 1709,
    name: "Sicily",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "82",
    type: "autonomous region",
    latitude: "37.59999380",
    longitude: "14.01535570",
  },
  {
    id: 1734,
    name: "Siena",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "SI",
    type: "province",
    latitude: "43.29377320",
    longitude: "11.43391480",
  },
  {
    id: 1667,
    name: "Siracusa",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "SR",
    type: "free municipal consortium",
    latitude: "37.06569240",
    longitude: "15.28571090",
  },
  {
    id: 1741,
    name: "Sondrio",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "SO",
    type: "province",
    latitude: "46.17276360",
    longitude: "9.79949170",
  },
  {
    id: 1730,
    name: "South Sardinia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "SU",
    type: "province",
    latitude: "39.38935350",
    longitude: "8.93970000",
  },
  {
    id: 1743,
    name: "Taranto",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "TA",
    type: "province",
    latitude: "40.57409010",
    longitude: "17.24299760",
  },
  {
    id: 1752,
    name: "Teramo",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "TE",
    type: "province",
    latitude: "42.58956080",
    longitude: "13.63627150",
  },
  {
    id: 1755,
    name: "Terni",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "TR",
    type: "province",
    latitude: "42.56345340",
    longitude: "12.52980280",
  },
  {
    id: 1733,
    name: "Trapani",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "TP",
    type: "free municipal consortium",
    latitude: "38.01831160",
    longitude: "12.51482650",
  },
  {
    id: 1725,
    name: "Trentino-South Tyrol",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "32",
    type: "autonomous region",
    latitude: "46.43366620",
    longitude: "11.16932960",
  },
  {
    id: 1762,
    name: "Treviso",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "TV",
    type: "province",
    latitude: "45.66685170",
    longitude: "12.24306170",
  },
  {
    id: 1763,
    name: "Trieste",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "TS",
    type: "decentralized regional entity",
    latitude: "45.68948230",
    longitude: "13.78330720",
  },
  {
    id: 1664,
    name: "Tuscany",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "52",
    type: "region",
    latitude: "43.77105130",
    longitude: "11.24862080",
  },
  {
    id: 1764,
    name: "Udine",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "UD",
    type: "decentralized regional entity",
    latitude: "46.14079720",
    longitude: "13.16628960",
  },
  {
    id: 1683,
    name: "Umbria",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "55",
    type: "region",
    latitude: "42.93800400",
    longitude: "12.62162110",
  },
  {
    id: 1765,
    name: "Varese",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "VA",
    type: "province",
    latitude: "45.79902600",
    longitude: "8.73009450",
  },
  {
    id: 1753,
    name: "Veneto",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "34",
    type: "region",
    latitude: "45.44146620",
    longitude: "12.31525950",
  },
  {
    id: 1726,
    name: "Verbano-Cusio-Ossola",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "VB",
    type: "province",
    latitude: "46.13996880",
    longitude: "8.27246490",
  },
  {
    id: 1785,
    name: "Vercelli",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "VC",
    type: "province",
    latitude: "45.32022040",
    longitude: "8.41850800",
  },
  {
    id: 1736,
    name: "Verona",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "VR",
    type: "province",
    latitude: "45.44184980",
    longitude: "11.07353160",
  },
  {
    id: 1737,
    name: "Vibo Valentia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "VV",
    type: "province",
    latitude: "38.63785650",
    longitude: "16.20514840",
  },
  {
    id: 1738,
    name: "Vicenza",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "VI",
    type: "province",
    latitude: "45.69839950",
    longitude: "11.56614650",
  },
  {
    id: 1735,
    name: "Viterbo",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    state_code: "VT",
    type: "province",
    latitude: "42.40024200",
    longitude: "11.88917210",
  },
  {
    id: 3753,
    name: "Clarendon Parish",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "13",
    type: null,
    latitude: "17.95571830",
    longitude: "-77.24051530",
  },
  {
    id: 3749,
    name: "Hanover Parish",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "09",
    type: null,
    latitude: "18.40977070",
    longitude: "-78.13363800",
  },
  {
    id: 3748,
    name: "Kingston Parish",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "01",
    type: null,
    latitude: "17.96832710",
    longitude: "-76.78270200",
  },
  {
    id: 3754,
    name: "Manchester Parish",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "12",
    type: null,
    latitude: "18.06696540",
    longitude: "-77.51607880",
  },
  {
    id: 3752,
    name: "Portland Parish",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "04",
    type: null,
    latitude: "18.08442740",
    longitude: "-76.41002670",
  },
  {
    id: 3751,
    name: "Saint Andrew",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "02",
    type: null,
    latitude: "37.22451030",
    longitude: "-95.70211890",
  },
  {
    id: 3744,
    name: "Saint Ann Parish",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "06",
    type: null,
    latitude: "37.28714520",
    longitude: "-77.41035330",
  },
  {
    id: 3746,
    name: "Saint Catherine Parish",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "14",
    type: null,
    latitude: "18.03641340",
    longitude: "-77.05644640",
  },
  {
    id: 3743,
    name: "Saint Elizabeth Parish",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "11",
    type: null,
    latitude: "38.99253080",
    longitude: "-94.58992000",
  },
  {
    id: 3745,
    name: "Saint James Parish",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "08",
    type: null,
    latitude: "30.01792920",
    longitude: "-90.79132270",
  },
  {
    id: 3747,
    name: "Saint Mary Parish",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "05",
    type: null,
    latitude: "36.09252200",
    longitude: "-95.97384400",
  },
  {
    id: 3750,
    name: "Saint Thomas Parish",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "03",
    type: null,
    latitude: "41.44253890",
    longitude: "-81.74022180",
  },
  {
    id: 3755,
    name: "Trelawny Parish",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "07",
    type: null,
    latitude: "18.35261430",
    longitude: "-77.60778650",
  },
  {
    id: 3742,
    name: "Westmoreland Parish",
    country_id: 108,
    country_code: "JM",
    country_name: "Jamaica",
    state_code: "10",
    type: null,
    latitude: "18.29443780",
    longitude: "-78.15644320",
  },
  {
    id: 827,
    name: "Aichi Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "23",
    type: null,
    latitude: "35.01825050",
    longitude: "137.29238930",
  },
  {
    id: 829,
    name: "Akita Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "05",
    type: null,
    latitude: "40.13762930",
    longitude: "140.33434100",
  },
  {
    id: 839,
    name: "Aomori Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "02",
    type: null,
    latitude: "40.76570770",
    longitude: "140.91758790",
  },
  {
    id: 821,
    name: "Chiba Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "12",
    type: null,
    latitude: "35.33541550",
    longitude: "140.18325160",
  },
  {
    id: 865,
    name: "Ehime Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "38",
    type: null,
    latitude: "33.60253060",
    longitude: "132.78575830",
  },
  {
    id: 848,
    name: "Fukui Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "18",
    type: null,
    latitude: "35.89622700",
    longitude: "136.21115790",
  },
  {
    id: 861,
    name: "Fukuoka Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "40",
    type: null,
    latitude: "33.56625590",
    longitude: "130.71585700",
  },
  {
    id: 847,
    name: "Fukushima Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "07",
    type: null,
    latitude: "37.38343730",
    longitude: "140.18325160",
  },
  {
    id: 858,
    name: "Gifu Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "21",
    type: null,
    latitude: "35.74374910",
    longitude: "136.98051030",
  },
  {
    id: 862,
    name: "Gunma Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "10",
    type: null,
    latitude: "36.56053880",
    longitude: "138.87999720",
  },
  {
    id: 828,
    name: "Hiroshima Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "34",
    type: null,
    latitude: "34.88234080",
    longitude: "133.01948970",
  },
  {
    id: 832,
    name: "Hokkaidō Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "01",
    type: null,
    latitude: "43.22032660",
    longitude: "142.86347370",
  },
  {
    id: 831,
    name: "Hyōgo Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "28",
    type: null,
    latitude: "34.85795180",
    longitude: "134.54537870",
  },
  {
    id: 851,
    name: "Ibaraki Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "08",
    type: null,
    latitude: "36.21935710",
    longitude: "140.18325160",
  },
  {
    id: 830,
    name: "Ishikawa Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "17",
    type: null,
    latitude: "36.32603170",
    longitude: "136.52896530",
  },
  {
    id: 856,
    name: "Iwate Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "03",
    type: null,
    latitude: "39.58329890",
    longitude: "141.25345740",
  },
  {
    id: 864,
    name: "Kagawa Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "37",
    type: null,
    latitude: "34.22259150",
    longitude: "134.01991520",
  },
  {
    id: 840,
    name: "Kagoshima Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "46",
    type: null,
    latitude: "31.39119580",
    longitude: "130.87785860",
  },
  {
    id: 842,
    name: "Kanagawa Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "14",
    type: null,
    latitude: "35.49135350",
    longitude: "139.28414300",
  },
  {
    id: 4924,
    name: "Kōchi Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "39",
    type: null,
    latitude: "33.28791610",
    longitude: "132.27592620",
  },
  {
    id: 846,
    name: "Kumamoto Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "43",
    type: null,
    latitude: "32.85944270",
    longitude: "130.79691490",
  },
  {
    id: 834,
    name: "Kyōto Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "26",
    type: null,
    latitude: "35.15666090",
    longitude: "135.52519820",
  },
  {
    id: 833,
    name: "Mie Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "24",
    type: null,
    latitude: "33.81439010",
    longitude: "136.04870470",
  },
  {
    id: 857,
    name: "Miyagi Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "04",
    type: null,
    latitude: "38.63061200",
    longitude: "141.11930480",
  },
  {
    id: 855,
    name: "Miyazaki Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "45",
    type: null,
    latitude: "32.60360220",
    longitude: "131.44125100",
  },
  {
    id: 843,
    name: "Nagano Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "20",
    type: null,
    latitude: "36.15439410",
    longitude: "137.92182040",
  },
  {
    id: 849,
    name: "Nagasaki Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "42",
    type: null,
    latitude: "33.24885250",
    longitude: "129.69309120",
  },
  {
    id: 824,
    name: "Nara Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "29",
    type: null,
    latitude: "34.29755280",
    longitude: "135.82797340",
  },
  {
    id: 841,
    name: "Niigata Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "15",
    type: null,
    latitude: "37.51783860",
    longitude: "138.92697940",
  },
  {
    id: 822,
    name: "Ōita Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "44",
    type: null,
    latitude: "33.15892990",
    longitude: "131.36111210",
  },
  {
    id: 820,
    name: "Okayama Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "33",
    type: null,
    latitude: "34.89634070",
    longitude: "133.63753140",
  },
  {
    id: 853,
    name: "Okinawa Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "47",
    type: null,
    latitude: "26.12019110",
    longitude: "127.70250120",
  },
  {
    id: 859,
    name: "Ōsaka Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "27",
    type: null,
    latitude: "34.64133150",
    longitude: "135.56293940",
  },
  {
    id: 863,
    name: "Saga Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "41",
    type: null,
    latitude: "33.30783710",
    longitude: "130.22712430",
  },
  {
    id: 860,
    name: "Saitama Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "11",
    type: null,
    latitude: "35.99625130",
    longitude: "139.44660050",
  },
  {
    id: 845,
    name: "Shiga Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "25",
    type: null,
    latitude: "35.32920140",
    longitude: "136.05632120",
  },
  {
    id: 826,
    name: "Shimane Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "32",
    type: null,
    latitude: "35.12440940",
    longitude: "132.62934460",
  },
  {
    id: 825,
    name: "Shizuoka Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "22",
    type: null,
    latitude: "35.09293970",
    longitude: "138.31902760",
  },
  {
    id: 854,
    name: "Tochigi Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "09",
    type: null,
    latitude: "36.67147390",
    longitude: "139.85472660",
  },
  {
    id: 836,
    name: "Tokushima Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "36",
    type: null,
    latitude: "33.94196550",
    longitude: "134.32365570",
  },
  {
    id: 823,
    name: "Tokyo",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "13",
    type: null,
    latitude: "35.67619190",
    longitude: "139.65031060",
  },
  {
    id: 850,
    name: "Tottori Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "31",
    type: null,
    latitude: "35.35731610",
    longitude: "133.40666180",
  },
  {
    id: 838,
    name: "Toyama Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "16",
    type: null,
    latitude: "36.69582660",
    longitude: "137.21370710",
  },
  {
    id: 844,
    name: "Wakayama Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "30",
    type: null,
    latitude: "33.94809140",
    longitude: "135.37453580",
  },
  {
    id: 837,
    name: "Yamagata Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "06",
    type: null,
    latitude: "38.53705640",
    longitude: "140.14351980",
  },
  {
    id: 835,
    name: "Yamaguchi Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "35",
    type: null,
    latitude: "34.27967690",
    longitude: "131.52127420",
  },
  {
    id: 852,
    name: "Yamanashi Prefecture",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    state_code: "19",
    type: null,
    latitude: "35.66351130",
    longitude: "138.63888790",
  },
  {
    id: 963,
    name: "Ajloun",
    country_id: 111,
    country_code: "JO",
    country_name: "Jordan",
    state_code: "AJ",
    type: null,
    latitude: "32.33255840",
    longitude: "35.75168440",
  },
  {
    id: 965,
    name: "Amman",
    country_id: 111,
    country_code: "JO",
    country_name: "Jordan",
    state_code: "AM",
    type: null,
    latitude: "31.94536330",
    longitude: "35.92838950",
  },
  {
    id: 959,
    name: "Aqaba",
    country_id: 111,
    country_code: "JO",
    country_name: "Jordan",
    state_code: "AQ",
    type: null,
    latitude: "29.53208600",
    longitude: "35.00628210",
  },
  {
    id: 961,
    name: "Balqa",
    country_id: 111,
    country_code: "JO",
    country_name: "Jordan",
    state_code: "BA",
    type: null,
    latitude: "32.03668060",
    longitude: "35.72884800",
  },
  {
    id: 960,
    name: "Irbid",
    country_id: 111,
    country_code: "JO",
    country_name: "Jordan",
    state_code: "IR",
    type: null,
    latitude: "32.55696360",
    longitude: "35.84789650",
  },
  {
    id: 966,
    name: "Jerash",
    country_id: 111,
    country_code: "JO",
    country_name: "Jordan",
    state_code: "JA",
    type: null,
    latitude: "32.27472370",
    longitude: "35.89609540",
  },
  {
    id: 956,
    name: "Karak",
    country_id: 111,
    country_code: "JO",
    country_name: "Jordan",
    state_code: "KA",
    type: null,
    latitude: "31.18535270",
    longitude: "35.70476820",
  },
  {
    id: 964,
    name: "Ma'an",
    country_id: 111,
    country_code: "JO",
    country_name: "Jordan",
    state_code: "MN",
    type: null,
    latitude: "30.19267890",
    longitude: "35.72493190",
  },
  {
    id: 958,
    name: "Madaba",
    country_id: 111,
    country_code: "JO",
    country_name: "Jordan",
    state_code: "MD",
    type: null,
    latitude: "31.71960970",
    longitude: "35.79327540",
  },
  {
    id: 962,
    name: "Mafraq",
    country_id: 111,
    country_code: "JO",
    country_name: "Jordan",
    state_code: "MA",
    type: null,
    latitude: "32.34169230",
    longitude: "36.20201750",
  },
  {
    id: 957,
    name: "Tafilah",
    country_id: 111,
    country_code: "JO",
    country_name: "Jordan",
    state_code: "AT",
    type: null,
    latitude: "30.83380630",
    longitude: "35.61605130",
  },
  {
    id: 967,
    name: "Zarqa",
    country_id: 111,
    country_code: "JO",
    country_name: "Jordan",
    state_code: "AZ",
    type: null,
    latitude: "32.06085050",
    longitude: "36.09421210",
  },
  {
    id: 145,
    name: "Akmola Region",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "AKM",
    type: null,
    latitude: "51.91653200",
    longitude: "69.41104940",
  },
  {
    id: 151,
    name: "Aktobe Region",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "AKT",
    type: null,
    latitude: "48.77970780",
    longitude: "57.99743780",
  },
  {
    id: 152,
    name: "Almaty",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "ALA",
    type: null,
    latitude: "43.22201460",
    longitude: "76.85124850",
  },
  {
    id: 143,
    name: "Almaty Region",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "ALM",
    type: null,
    latitude: "45.01192270",
    longitude: "78.42293920",
  },
  {
    id: 153,
    name: "Atyrau Region",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "ATY",
    type: null,
    latitude: "47.10761880",
    longitude: "51.91413300",
  },
  {
    id: 155,
    name: "Baikonur",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "BAY",
    type: null,
    latitude: "45.96458510",
    longitude: "63.30524280",
  },
  {
    id: 154,
    name: "East Kazakhstan Region",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "VOS",
    type: null,
    latitude: "48.70626870",
    longitude: "80.79225340",
  },
  {
    id: 147,
    name: "Jambyl Region",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "ZHA",
    type: null,
    latitude: "44.22203080",
    longitude: "72.36579670",
  },
  {
    id: 150,
    name: "Karaganda Region",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "KAR",
    type: null,
    latitude: "47.90221820",
    longitude: "71.77068070",
  },
  {
    id: 157,
    name: "Kostanay Region",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "KUS",
    type: null,
    latitude: "51.50770960",
    longitude: "64.04790730",
  },
  {
    id: 142,
    name: "Kyzylorda Region",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "KZY",
    type: null,
    latitude: "44.69226130",
    longitude: "62.65718850",
  },
  {
    id: 141,
    name: "Mangystau Region",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "MAN",
    type: null,
    latitude: "44.59080200",
    longitude: "53.84995080",
  },
  {
    id: 144,
    name: "North Kazakhstan Region",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "SEV",
    type: null,
    latitude: "54.16220660",
    longitude: "69.93870710",
  },
  {
    id: 156,
    name: "Nur-Sultan",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "AST",
    type: null,
    latitude: "51.16052270",
    longitude: "71.47035580",
  },
  {
    id: 146,
    name: "Pavlodar Region",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "PAV",
    type: null,
    latitude: "52.28784440",
    longitude: "76.97334530",
  },
  {
    id: 149,
    name: "Turkestan Region",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "YUZ",
    type: null,
    latitude: "43.36669580",
    longitude: "68.40944050",
  },
  {
    id: 148,
    name: "West Kazakhstan Province",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    state_code: "ZAP",
    type: null,
    latitude: "49.56797270",
    longitude: "50.80666160",
  },
  {
    id: 181,
    name: "Baringo",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "01",
    type: "county",
    latitude: "0.85549880",
    longitude: "36.08934060",
  },
  {
    id: 210,
    name: "Bomet",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "02",
    type: "county",
    latitude: "-0.80150090",
    longitude: "35.30272260",
  },
  {
    id: 168,
    name: "Bungoma",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "03",
    type: "county",
    latitude: "0.56952520",
    longitude: "34.55837660",
  },
  {
    id: 161,
    name: "Busia",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "04",
    type: "county",
    latitude: "0.43465060",
    longitude: "34.24215970",
  },
  {
    id: 201,
    name: "Elgeyo-Marakwet",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "05",
    type: "county",
    latitude: "1.04982370",
    longitude: "35.47819260",
  },
  {
    id: 163,
    name: "Embu",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "06",
    type: "county",
    latitude: "-0.65604770",
    longitude: "37.72376780",
  },
  {
    id: 196,
    name: "Garissa",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "07",
    type: "county",
    latitude: "-0.45322930",
    longitude: "39.64609880",
  },
  {
    id: 195,
    name: "Homa Bay",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "08",
    type: "county",
    latitude: "-0.62206550",
    longitude: "34.33103640",
  },
  {
    id: 170,
    name: "Isiolo",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "09",
    type: "county",
    latitude: "0.35243520",
    longitude: "38.48499230",
  },
  {
    id: 197,
    name: "Kajiado",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "10",
    type: "county",
    latitude: "-2.09807510",
    longitude: "36.78195050",
  },
  {
    id: 158,
    name: "Kakamega",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "11",
    type: "county",
    latitude: "0.30789400",
    longitude: "34.77407930",
  },
  {
    id: 193,
    name: "Kericho",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "12",
    type: "county",
    latitude: "-0.18279130",
    longitude: "35.47819260",
  },
  {
    id: 199,
    name: "Kiambu",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "13",
    type: "county",
    latitude: "-1.03137010",
    longitude: "36.86807910",
  },
  {
    id: 174,
    name: "Kilifi",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "14",
    type: "county",
    latitude: "-3.51065080",
    longitude: "39.90932690",
  },
  {
    id: 167,
    name: "Kirinyaga",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "15",
    type: "county",
    latitude: "-0.65905650",
    longitude: "37.38272340",
  },
  {
    id: 159,
    name: "Kisii",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "16",
    type: "county",
    latitude: "-0.67733400",
    longitude: "34.77960300",
  },
  {
    id: 171,
    name: "Kisumu",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "17",
    type: "county",
    latitude: "-0.09170160",
    longitude: "34.76795680",
  },
  {
    id: 211,
    name: "Kitui",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "18",
    type: "county",
    latitude: "-1.68328220",
    longitude: "38.31657250",
  },
  {
    id: 173,
    name: "Kwale",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "19",
    type: "county",
    latitude: "-4.18161150",
    longitude: "39.46056120",
  },
  {
    id: 164,
    name: "Laikipia",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "20",
    type: "county",
    latitude: "0.36060630",
    longitude: "36.78195050",
  },
  {
    id: 166,
    name: "Lamu",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "21",
    type: "county",
    latitude: "-2.23550580",
    longitude: "40.47200040",
  },
  {
    id: 184,
    name: "Machakos",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "22",
    type: "county",
    latitude: "-1.51768370",
    longitude: "37.26341460",
  },
  {
    id: 188,
    name: "Makueni",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "23",
    type: "county",
    latitude: "-2.25587340",
    longitude: "37.89366630",
  },
  {
    id: 187,
    name: "Mandera",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "24",
    type: "county",
    latitude: "3.57379910",
    longitude: "40.95868800",
  },
  {
    id: 194,
    name: "Marsabit",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "25",
    type: "county",
    latitude: "2.44264030",
    longitude: "37.97845850",
  },
  {
    id: 198,
    name: "Meru",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "26",
    type: "county",
    latitude: "0.35571740",
    longitude: "37.80876930",
  },
  {
    id: 190,
    name: "Migori",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "27",
    type: "county",
    latitude: "-0.93657020",
    longitude: "34.41982430",
  },
  {
    id: 200,
    name: "Mombasa",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "28",
    type: "county",
    latitude: "-3.97682910",
    longitude: "39.71371810",
  },
  {
    id: 178,
    name: "Murang'a",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "29",
    type: "county",
    latitude: "-0.78392810",
    longitude: "37.04003390",
  },
  {
    id: 191,
    name: "Nairobi City",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "30",
    type: "county",
    latitude: "-1.29206590",
    longitude: "36.82194620",
  },
  {
    id: 203,
    name: "Nakuru",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "31",
    type: "county",
    latitude: "-0.30309880",
    longitude: "36.08002600",
  },
  {
    id: 165,
    name: "Nandi",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "32",
    type: "county",
    latitude: "0.18358670",
    longitude: "35.12687810",
  },
  {
    id: 175,
    name: "Narok",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "33",
    type: "county",
    latitude: "-1.10411100",
    longitude: "36.08934060",
  },
  {
    id: 209,
    name: "Nyamira",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "34",
    type: "county",
    latitude: "-0.56694050",
    longitude: "34.93412340",
  },
  {
    id: 192,
    name: "Nyandarua",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "35",
    type: "county",
    latitude: "-0.18038550",
    longitude: "36.52296410",
  },
  {
    id: 180,
    name: "Nyeri",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "36",
    type: "county",
    latitude: "-0.41969150",
    longitude: "37.04003390",
  },
  {
    id: 207,
    name: "Samburu",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "37",
    type: "county",
    latitude: "1.21545060",
    longitude: "36.95410700",
  },
  {
    id: 186,
    name: "Siaya",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "38",
    type: "county",
    latitude: "-0.06173280",
    longitude: "34.24215970",
  },
  {
    id: 176,
    name: "Taita–Taveta",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "39",
    type: "county",
    latitude: "-3.31606870",
    longitude: "38.48499230",
  },
  {
    id: 205,
    name: "Tana River",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "40",
    type: "county",
    latitude: "-1.65184680",
    longitude: "39.65181650",
  },
  {
    id: 185,
    name: "Tharaka-Nithi",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "41",
    type: "county",
    latitude: "-0.29648510",
    longitude: "37.72376780",
  },
  {
    id: 183,
    name: "Trans Nzoia",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "42",
    type: "county",
    latitude: "1.05666670",
    longitude: "34.95066250",
  },
  {
    id: 206,
    name: "Turkana",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "43",
    type: "county",
    latitude: "3.31224770",
    longitude: "35.56578620",
  },
  {
    id: 169,
    name: "Uasin Gishu",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "44",
    type: "county",
    latitude: "0.55276380",
    longitude: "35.30272260",
  },
  {
    id: 202,
    name: "Vihiga",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "45",
    type: "county",
    latitude: "0.07675530",
    longitude: "34.70776650",
  },
  {
    id: 182,
    name: "Wajir",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "46",
    type: "county",
    latitude: "1.63604750",
    longitude: "40.30886260",
  },
  {
    id: 208,
    name: "West Pokot",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    state_code: "47",
    type: "county",
    latitude: "1.62100760",
    longitude: "35.39050460",
  },
  {
    id: 1831,
    name: "Gilbert Islands",
    country_id: 114,
    country_code: "KI",
    country_name: "Kiribati",
    state_code: "G",
    type: null,
    latitude: "0.35242620",
    longitude: "174.75526340",
  },
  {
    id: 1832,
    name: "Line Islands",
    country_id: 114,
    country_code: "KI",
    country_name: "Kiribati",
    state_code: "L",
    type: null,
    latitude: "1.74294390",
    longitude: "-157.21328260",
  },
  {
    id: 1830,
    name: "Phoenix Islands",
    country_id: 114,
    country_code: "KI",
    country_name: "Kiribati",
    state_code: "P",
    type: null,
    latitude: "33.32843690",
    longitude: "-111.98247740",
  },
  {
    id: 4876,
    name: "Đakovica District (Gjakove)",
    country_id: 248,
    country_code: "XK",
    country_name: "Kosovo",
    state_code: "XDG",
    type: null,
    latitude: "42.43757560",
    longitude: "20.37854380",
  },
  {
    id: 4877,
    name: "Gjilan District",
    country_id: 248,
    country_code: "XK",
    country_name: "Kosovo",
    state_code: "XGJ",
    type: null,
    latitude: "42.46352060",
    longitude: "21.46940110",
  },
  {
    id: 4878,
    name: "Kosovska Mitrovica District",
    country_id: 248,
    country_code: "XK",
    country_name: "Kosovo",
    state_code: "XKM",
    type: null,
    latitude: "42.89139090",
    longitude: "20.86599950",
  },
  {
    id: 3738,
    name: "Peć District",
    country_id: 248,
    country_code: "XK",
    country_name: "Kosovo",
    state_code: "XPE",
    type: null,
    latitude: "42.65921550",
    longitude: "20.28876240",
  },
  {
    id: 4879,
    name: "Pristina (Priştine)",
    country_id: 248,
    country_code: "XK",
    country_name: "Kosovo",
    state_code: "XPI",
    type: null,
    latitude: "42.66291380",
    longitude: "21.16550280",
  },
  {
    id: 3723,
    name: "Prizren District",
    country_id: 248,
    country_code: "XK",
    country_name: "Kosovo",
    state_code: "XPR",
    type: null,
    latitude: "42.21525220",
    longitude: "20.74147720",
  },
  {
    id: 4874,
    name: "Uroševac District (Ferizaj)",
    country_id: 248,
    country_code: "XK",
    country_name: "Kosovo",
    state_code: "XUF",
    type: null,
    latitude: "42.37018440",
    longitude: "21.14832810",
  },
  {
    id: 977,
    name: "Al Ahmadi",
    country_id: 117,
    country_code: "KW",
    country_name: "Kuwait",
    state_code: "AH",
    type: null,
    latitude: "28.57451250",
    longitude: "48.10247430",
  },
  {
    id: 975,
    name: "Al Farwaniyah",
    country_id: 117,
    country_code: "KW",
    country_name: "Kuwait",
    state_code: "FA",
    type: null,
    latitude: "29.27335700",
    longitude: "47.94001540",
  },
  {
    id: 972,
    name: "Al Jahra",
    country_id: 117,
    country_code: "KW",
    country_name: "Kuwait",
    state_code: "JA",
    type: null,
    latitude: "29.99318310",
    longitude: "47.76347310",
  },
  {
    id: 976,
    name: "Capital",
    country_id: 117,
    country_code: "KW",
    country_name: "Kuwait",
    state_code: "KU",
    type: null,
    latitude: "26.22851610",
    longitude: "50.58604970",
  },
  {
    id: 973,
    name: "Hawalli",
    country_id: 117,
    country_code: "KW",
    country_name: "Kuwait",
    state_code: "HA",
    type: null,
    latitude: "29.30567160",
    longitude: "48.03076130",
  },
  {
    id: 974,
    name: "Mubarak Al-Kabeer",
    country_id: 117,
    country_code: "KW",
    country_name: "Kuwait",
    state_code: "MU",
    type: null,
    latitude: "29.21224000",
    longitude: "48.06051080",
  },
  {
    id: 998,
    name: "Batken Region",
    country_id: 118,
    country_code: "KG",
    country_name: "Kyrgyzstan",
    state_code: "B",
    type: null,
    latitude: "39.97214250",
    longitude: "69.85974060",
  },
  {
    id: 1001,
    name: "Bishkek",
    country_id: 118,
    country_code: "KG",
    country_name: "Kyrgyzstan",
    state_code: "GB",
    type: null,
    latitude: "42.87462120",
    longitude: "74.56976170",
  },
  {
    id: 1004,
    name: "Chuy Region",
    country_id: 118,
    country_code: "KG",
    country_name: "Kyrgyzstan",
    state_code: "C",
    type: null,
    latitude: "42.56550000",
    longitude: "74.40566120",
  },
  {
    id: 1002,
    name: "Issyk-Kul Region",
    country_id: 118,
    country_code: "KG",
    country_name: "Kyrgyzstan",
    state_code: "Y",
    type: null,
    latitude: "42.18594280",
    longitude: "77.56194190",
  },
  {
    id: 1000,
    name: "Jalal-Abad Region",
    country_id: 118,
    country_code: "KG",
    country_name: "Kyrgyzstan",
    state_code: "J",
    type: null,
    latitude: "41.10680800",
    longitude: "72.89880690",
  },
  {
    id: 999,
    name: "Naryn Region",
    country_id: 118,
    country_code: "KG",
    country_name: "Kyrgyzstan",
    state_code: "N",
    type: null,
    latitude: "41.29432270",
    longitude: "75.34121790",
  },
  {
    id: 1003,
    name: "Osh",
    country_id: 118,
    country_code: "KG",
    country_name: "Kyrgyzstan",
    state_code: "GO",
    type: null,
    latitude: "36.06313990",
    longitude: "-95.91828950",
  },
  {
    id: 1005,
    name: "Osh Region",
    country_id: 118,
    country_code: "KG",
    country_name: "Kyrgyzstan",
    state_code: "O",
    type: null,
    latitude: "39.84073660",
    longitude: "72.89880690",
  },
  {
    id: 997,
    name: "Talas Region",
    country_id: 118,
    country_code: "KG",
    country_name: "Kyrgyzstan",
    state_code: "T",
    type: null,
    latitude: "42.28673390",
    longitude: "72.52048270",
  },
  {
    id: 982,
    name: "Attapeu Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "AT",
    type: null,
    latitude: "14.93634000",
    longitude: "107.10119310",
  },
  {
    id: 991,
    name: "Bokeo Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "BK",
    type: null,
    latitude: "20.28726620",
    longitude: "100.70978670",
  },
  {
    id: 985,
    name: "Bolikhamsai Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "BL",
    type: null,
    latitude: "18.43629240",
    longitude: "104.47233010",
  },
  {
    id: 996,
    name: "Champasak Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "CH",
    type: null,
    latitude: "14.65786640",
    longitude: "105.96998780",
  },
  {
    id: 989,
    name: "Houaphanh Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "HO",
    type: null,
    latitude: "20.32541750",
    longitude: "104.10013260",
  },
  {
    id: 986,
    name: "Khammouane Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "KH",
    type: null,
    latitude: "17.63840660",
    longitude: "105.21948080",
  },
  {
    id: 992,
    name: "Luang Namtha Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "LM",
    type: null,
    latitude: "20.91701870",
    longitude: "101.16173560",
  },
  {
    id: 978,
    name: "Luang Prabang Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "LP",
    type: null,
    latitude: "20.06562290",
    longitude: "102.62162110",
  },
  {
    id: 988,
    name: "Oudomxay Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "OU",
    type: null,
    latitude: "20.49219290",
    longitude: "101.88917210",
  },
  {
    id: 987,
    name: "Phongsaly Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "PH",
    type: null,
    latitude: "21.59193770",
    longitude: "102.25479190",
  },
  {
    id: 993,
    name: "Sainyabuli Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "XA",
    type: null,
    latitude: "19.39078860",
    longitude: "101.52480550",
  },
  {
    id: 981,
    name: "Salavan Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "SL",
    type: null,
    latitude: "15.81710730",
    longitude: "106.25221430",
  },
  {
    id: 990,
    name: "Savannakhet Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "SV",
    type: null,
    latitude: "16.50653810",
    longitude: "105.59433880",
  },
  {
    id: 984,
    name: "Sekong Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "XE",
    type: null,
    latitude: "15.57674460",
    longitude: "107.00670310",
  },
  {
    id: 979,
    name: "Vientiane Prefecture",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "VT",
    type: null,
    latitude: "18.11054100",
    longitude: "102.52980280",
  },
  {
    id: 980,
    name: "Vientiane Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "VI",
    type: null,
    latitude: "18.57050630",
    longitude: "102.62162110",
  },
  {
    id: 994,
    name: "Xaisomboun",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "XN",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 983,
    name: "Xaisomboun Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "XS",
    type: null,
    latitude: "18.43629240",
    longitude: "104.47233010",
  },
  {
    id: 995,
    name: "Xiangkhouang Province",
    country_id: 119,
    country_code: "LA",
    country_name: "Laos",
    state_code: "XI",
    type: null,
    latitude: "19.60930030",
    longitude: "103.72891670",
  },
  {
    id: 4445,
    name: "Aglona Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "001",
    type: null,
    latitude: "56.10890060",
    longitude: "27.12862270",
  },
  {
    id: 4472,
    name: "Aizkraukle Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "002",
    type: null,
    latitude: "56.64610800",
    longitude: "25.23708540",
  },
  {
    id: 4496,
    name: "Aizpute Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "003",
    type: null,
    latitude: "56.71825960",
    longitude: "21.60727590",
  },
  {
    id: 4499,
    name: "Aknīste Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "004",
    type: null,
    latitude: "56.16130370",
    longitude: "25.74848270",
  },
  {
    id: 4484,
    name: "Aloja Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "005",
    type: null,
    latitude: "57.76713600",
    longitude: "24.87708390",
  },
  {
    id: 4485,
    name: "Alsunga Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "006",
    type: null,
    latitude: "56.98285310",
    longitude: "21.55559190",
  },
  {
    id: 4487,
    name: "Alūksne Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "007",
    type: null,
    latitude: "57.42544850",
    longitude: "27.04249680",
  },
  {
    id: 4497,
    name: "Amata Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "008",
    type: null,
    latitude: "56.99387260",
    longitude: "25.26276750",
  },
  {
    id: 4457,
    name: "Ape Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "009",
    type: null,
    latitude: "57.53926970",
    longitude: "26.69416490",
  },
  {
    id: 4481,
    name: "Auce Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "010",
    type: null,
    latitude: "56.46016800",
    longitude: "22.90547810",
  },
  {
    id: 4427,
    name: "Babīte Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "012",
    type: null,
    latitude: "56.95415500",
    longitude: "23.94539900",
  },
  {
    id: 4482,
    name: "Baldone Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "013",
    type: null,
    latitude: "56.74246000",
    longitude: "24.39115440",
  },
  {
    id: 4498,
    name: "Baltinava Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "014",
    type: null,
    latitude: "56.94584680",
    longitude: "27.64410660",
  },
  {
    id: 4505,
    name: "Balvi Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "015",
    type: null,
    latitude: "57.13262400",
    longitude: "27.26466850",
  },
  {
    id: 4465,
    name: "Bauska Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "016",
    type: null,
    latitude: "56.41018680",
    longitude: "24.20006890",
  },
  {
    id: 4471,
    name: "Beverīna Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "017",
    type: null,
    latitude: "57.51971090",
    longitude: "25.60736540",
  },
  {
    id: 4468,
    name: "Brocēni Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "018",
    type: null,
    latitude: "56.73475410",
    longitude: "22.63573710",
  },
  {
    id: 4411,
    name: "Burtnieki Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "019",
    type: null,
    latitude: "57.69490040",
    longitude: "25.27647770",
  },
  {
    id: 4454,
    name: "Carnikava Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "020",
    type: null,
    latitude: "57.10241210",
    longitude: "24.21086620",
  },
  {
    id: 4469,
    name: "Cēsis Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "022",
    type: null,
    latitude: "57.31028970",
    longitude: "25.26761250",
  },
  {
    id: 4414,
    name: "Cesvaine Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "021",
    type: null,
    latitude: "56.96792640",
    longitude: "26.30831720",
  },
  {
    id: 4410,
    name: "Cibla Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "023",
    type: null,
    latitude: "56.61023440",
    longitude: "27.86965980",
  },
  {
    id: 4504,
    name: "Dagda Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "024",
    type: null,
    latitude: "56.09560890",
    longitude: "27.53245900",
  },
  {
    id: 4463,
    name: "Daugavpils",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "DGV",
    type: null,
    latitude: "55.87473600",
    longitude: "26.53617900",
  },
  {
    id: 4492,
    name: "Daugavpils Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "025",
    type: null,
    latitude: "55.89917830",
    longitude: "26.61020120",
  },
  {
    id: 4437,
    name: "Dobele Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "026",
    type: null,
    latitude: "56.62630500",
    longitude: "23.28090660",
  },
  {
    id: 4428,
    name: "Dundaga Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "027",
    type: null,
    latitude: "57.50491670",
    longitude: "22.35051140",
  },
  {
    id: 4458,
    name: "Durbe Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "028",
    type: null,
    latitude: "56.62798570",
    longitude: "21.49162450",
  },
  {
    id: 4448,
    name: "Engure Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "029",
    type: null,
    latitude: "57.16235000",
    longitude: "23.21966340",
  },
  {
    id: 4444,
    name: "Ērgļi Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "030",
    type: null,
    latitude: "56.92370650",
    longitude: "25.67538520",
  },
  {
    id: 4510,
    name: "Garkalne Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "031",
    type: null,
    latitude: "57.01903870",
    longitude: "24.38261810",
  },
  {
    id: 4470,
    name: "Grobiņa Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "032",
    type: null,
    latitude: "56.53963200",
    longitude: "21.16689200",
  },
  {
    id: 4400,
    name: "Gulbene Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "033",
    type: null,
    latitude: "57.21556450",
    longitude: "26.64529550",
  },
  {
    id: 4441,
    name: "Iecava Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "034",
    type: null,
    latitude: "56.59867930",
    longitude: "24.19962720",
  },
  {
    id: 4511,
    name: "Ikšķile Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "035",
    type: null,
    latitude: "56.83736670",
    longitude: "24.49747450",
  },
  {
    id: 4399,
    name: "Ilūkste Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "036",
    type: null,
    latitude: "55.97825470",
    longitude: "26.29650880",
  },
  {
    id: 4449,
    name: "Inčukalns Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "037",
    type: null,
    latitude: "57.09943420",
    longitude: "24.68555700",
  },
  {
    id: 4475,
    name: "Jaunjelgava Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "038",
    type: null,
    latitude: "56.52836590",
    longitude: "25.39214430",
  },
  {
    id: 4407,
    name: "Jaunpiebalga Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "039",
    type: null,
    latitude: "57.14334710",
    longitude: "25.99518880",
  },
  {
    id: 4489,
    name: "Jaunpils Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "040",
    type: null,
    latitude: "56.73141940",
    longitude: "23.01256160",
  },
  {
    id: 4464,
    name: "Jēkabpils",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "JKB",
    type: null,
    latitude: "56.50145500",
    longitude: "25.87829900",
  },
  {
    id: 4438,
    name: "Jēkabpils Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "042",
    type: null,
    latitude: "56.29193200",
    longitude: "25.98120170",
  },
  {
    id: 4500,
    name: "Jelgava",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "JEL",
    type: null,
    latitude: "56.65110910",
    longitude: "23.72135410",
  },
  {
    id: 4424,
    name: "Jelgava Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "041",
    type: null,
    latitude: "56.58956890",
    longitude: "23.66104810",
  },
  {
    id: 4446,
    name: "Jūrmala",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "JUR",
    type: null,
    latitude: "56.94707900",
    longitude: "23.61684850",
  },
  {
    id: 4420,
    name: "Kandava Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "043",
    type: null,
    latitude: "57.03406730",
    longitude: "22.78018130",
  },
  {
    id: 4453,
    name: "Kārsava Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "044",
    type: null,
    latitude: "56.76458420",
    longitude: "27.73582950",
  },
  {
    id: 4412,
    name: "Ķegums Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "051",
    type: null,
    latitude: "56.74753570",
    longitude: "24.71736450",
  },
  {
    id: 4435,
    name: "Ķekava Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "052",
    type: null,
    latitude: "56.80643510",
    longitude: "24.19394930",
  },
  {
    id: 4495,
    name: "Kocēni Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "045",
    type: null,
    latitude: "57.52262920",
    longitude: "25.33495070",
  },
  {
    id: 4452,
    name: "Koknese Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "046",
    type: null,
    latitude: "56.72055600",
    longitude: "25.48939090",
  },
  {
    id: 4474,
    name: "Krāslava Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "047",
    type: null,
    latitude: "55.89514640",
    longitude: "27.18145770",
  },
  {
    id: 4422,
    name: "Krimulda Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "048",
    type: null,
    latitude: "57.17912730",
    longitude: "24.71401270",
  },
  {
    id: 4413,
    name: "Krustpils Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "049",
    type: null,
    latitude: "56.54155780",
    longitude: "26.24463970",
  },
  {
    id: 4490,
    name: "Kuldīga Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "050",
    type: null,
    latitude: "56.96872570",
    longitude: "21.96137390",
  },
  {
    id: 4512,
    name: "Lielvārde Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "053",
    type: null,
    latitude: "56.73929760",
    longitude: "24.97116180",
  },
  {
    id: 4460,
    name: "Liepāja",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "LPX",
    type: null,
    latitude: "56.50466780",
    longitude: "21.01080600",
  },
  {
    id: 4488,
    name: "Līgatne Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "055",
    type: null,
    latitude: "57.19442040",
    longitude: "25.09406810",
  },
  {
    id: 4418,
    name: "Limbaži Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "054",
    type: null,
    latitude: "57.54032270",
    longitude: "24.71344510",
  },
  {
    id: 4401,
    name: "Līvāni Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "056",
    type: null,
    latitude: "56.35509420",
    longitude: "26.17251900",
  },
  {
    id: 4419,
    name: "Lubāna Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "057",
    type: null,
    latitude: "56.89992690",
    longitude: "26.71987890",
  },
  {
    id: 4501,
    name: "Ludza Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "058",
    type: null,
    latitude: "56.54595900",
    longitude: "27.71431990",
  },
  {
    id: 4433,
    name: "Madona Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "059",
    type: null,
    latitude: "56.85989230",
    longitude: "26.22762010",
  },
  {
    id: 4461,
    name: "Mālpils Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "061",
    type: null,
    latitude: "57.00841190",
    longitude: "24.95742780",
  },
  {
    id: 4450,
    name: "Mārupe Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "062",
    type: null,
    latitude: "56.89657170",
    longitude: "24.04600490",
  },
  {
    id: 4513,
    name: "Mazsalaca Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "060",
    type: null,
    latitude: "57.92677490",
    longitude: "25.06698950",
  },
  {
    id: 4451,
    name: "Mērsrags Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "063",
    type: null,
    latitude: "57.33068810",
    longitude: "23.10237070",
  },
  {
    id: 4398,
    name: "Naukšēni Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "064",
    type: null,
    latitude: "57.92953610",
    longitude: "25.51192660",
  },
  {
    id: 4432,
    name: "Nereta Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "065",
    type: null,
    latitude: "56.19866550",
    longitude: "25.32529690",
  },
  {
    id: 4436,
    name: "Nīca Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "066",
    type: null,
    latitude: "56.34649830",
    longitude: "21.06549300",
  },
  {
    id: 4416,
    name: "Ogre Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "067",
    type: null,
    latitude: "56.81473550",
    longitude: "24.60445550",
  },
  {
    id: 4417,
    name: "Olaine Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "068",
    type: null,
    latitude: "56.79523530",
    longitude: "24.01535890",
  },
  {
    id: 4442,
    name: "Ozolnieki Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "069",
    type: null,
    latitude: "56.67563050",
    longitude: "23.89948160",
  },
  {
    id: 4507,
    name: "Pārgauja Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "070",
    type: null,
    latitude: "57.36481220",
    longitude: "24.98220450",
  },
  {
    id: 4467,
    name: "Pāvilosta Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "071",
    type: null,
    latitude: "56.88654240",
    longitude: "21.19468490",
  },
  {
    id: 4405,
    name: "Pļaviņas Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "072",
    type: null,
    latitude: "56.61773130",
    longitude: "25.71940430",
  },
  {
    id: 4483,
    name: "Preiļi Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "073",
    type: null,
    latitude: "56.15111570",
    longitude: "26.74397670",
  },
  {
    id: 4429,
    name: "Priekule Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "074",
    type: null,
    latitude: "56.41794130",
    longitude: "21.55033360",
  },
  {
    id: 4506,
    name: "Priekuļi Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "075",
    type: null,
    latitude: "57.36171380",
    longitude: "25.44104230",
  },
  {
    id: 4479,
    name: "Rauna Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "076",
    type: null,
    latitude: "57.33169300",
    longitude: "25.61003390",
  },
  {
    id: 4509,
    name: "Rēzekne",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "REZ",
    type: null,
    latitude: "56.50992230",
    longitude: "27.33313570",
  },
  {
    id: 4455,
    name: "Rēzekne Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "077",
    type: null,
    latitude: "56.32736380",
    longitude: "27.32843310",
  },
  {
    id: 4502,
    name: "Riebiņi Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "078",
    type: null,
    latitude: "56.34361900",
    longitude: "26.80181380",
  },
  {
    id: 4491,
    name: "Riga",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "RIX",
    type: null,
    latitude: "56.94964870",
    longitude: "24.10518650",
  },
  {
    id: 4440,
    name: "Roja Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "079",
    type: null,
    latitude: "57.50467130",
    longitude: "22.80121640",
  },
  {
    id: 4493,
    name: "Ropaži Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "080",
    type: null,
    latitude: "56.96157860",
    longitude: "24.60104760",
  },
  {
    id: 4503,
    name: "Rucava Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "081",
    type: null,
    latitude: "56.15931240",
    longitude: "21.16181210",
  },
  {
    id: 4423,
    name: "Rugāji Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "082",
    type: null,
    latitude: "57.00560230",
    longitude: "27.13172030",
  },
  {
    id: 4426,
    name: "Rūjiena Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "084",
    type: null,
    latitude: "57.89372910",
    longitude: "25.33910080",
  },
  {
    id: 4404,
    name: "Rundāle Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "083",
    type: null,
    latitude: "56.40972100",
    longitude: "24.01241390",
  },
  {
    id: 4434,
    name: "Sala Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "085",
    type: null,
    latitude: "59.96796130",
    longitude: "16.49782170",
  },
  {
    id: 4396,
    name: "Salacgrīva Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "086",
    type: null,
    latitude: "57.75808830",
    longitude: "24.35431810",
  },
  {
    id: 4402,
    name: "Salaspils Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "087",
    type: null,
    latitude: "56.86081520",
    longitude: "24.34978810",
  },
  {
    id: 4439,
    name: "Saldus Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "088",
    type: null,
    latitude: "56.66650880",
    longitude: "22.49354930",
  },
  {
    id: 4443,
    name: "Saulkrasti Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "089",
    type: null,
    latitude: "57.25794180",
    longitude: "24.41831460",
  },
  {
    id: 4408,
    name: "Sēja Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "090",
    type: null,
    latitude: "57.20069950",
    longitude: "24.59228210",
  },
  {
    id: 4476,
    name: "Sigulda Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "091",
    type: null,
    latitude: "57.10550920",
    longitude: "24.83142590",
  },
  {
    id: 4415,
    name: "Skrīveri Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "092",
    type: null,
    latitude: "56.67613910",
    longitude: "25.09788490",
  },
  {
    id: 4447,
    name: "Skrunda Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "093",
    type: null,
    latitude: "56.66434580",
    longitude: "22.00457290",
  },
  {
    id: 4462,
    name: "Smiltene Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "094",
    type: null,
    latitude: "57.42303320",
    longitude: "25.90027800",
  },
  {
    id: 4478,
    name: "Stopiņi Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "095",
    type: null,
    latitude: "56.93644900",
    longitude: "24.28729490",
  },
  {
    id: 4494,
    name: "Strenči Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "096",
    type: null,
    latitude: "57.62254710",
    longitude: "25.80480860",
  },
  {
    id: 4459,
    name: "Talsi Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "097",
    type: null,
    latitude: "57.34152080",
    longitude: "22.57131250",
  },
  {
    id: 4480,
    name: "Tērvete Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "098",
    type: null,
    latitude: "56.41192010",
    longitude: "23.31883320",
  },
  {
    id: 4409,
    name: "Tukums Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "099",
    type: null,
    latitude: "56.96728680",
    longitude: "23.15243790",
  },
  {
    id: 4508,
    name: "Vaiņode Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "100",
    type: null,
    latitude: "56.41542710",
    longitude: "21.85139840",
  },
  {
    id: 4425,
    name: "Valka Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "101",
    type: null,
    latitude: "57.77439000",
    longitude: "26.01700500",
  },
  {
    id: 4473,
    name: "Valmiera",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "VMR",
    type: null,
    latitude: "57.53846590",
    longitude: "25.42636180",
  },
  {
    id: 4431,
    name: "Varakļāni Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "102",
    type: null,
    latitude: "56.66880060",
    longitude: "26.56364140",
  },
  {
    id: 4406,
    name: "Vārkava Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "103",
    type: null,
    latitude: "56.24657440",
    longitude: "26.56643710",
  },
  {
    id: 4466,
    name: "Vecpiebalga Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "104",
    type: null,
    latitude: "57.06033560",
    longitude: "25.81615920",
  },
  {
    id: 4397,
    name: "Vecumnieki Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "105",
    type: null,
    latitude: "56.60623370",
    longitude: "24.52218910",
  },
  {
    id: 4421,
    name: "Ventspils",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "VEN",
    type: null,
    latitude: "57.39372160",
    longitude: "21.56470660",
  },
  {
    id: 4403,
    name: "Ventspils Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "106",
    type: null,
    latitude: "57.28336820",
    longitude: "21.85875580",
  },
  {
    id: 4456,
    name: "Viesīte Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "107",
    type: null,
    latitude: "56.31130850",
    longitude: "25.50704640",
  },
  {
    id: 4477,
    name: "Viļaka Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "108",
    type: null,
    latitude: "57.17222630",
    longitude: "27.66731880",
  },
  {
    id: 4486,
    name: "Viļāni Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "109",
    type: null,
    latitude: "56.54561710",
    longitude: "26.91679270",
  },
  {
    id: 4430,
    name: "Zilupe Municipality",
    country_id: 120,
    country_code: "LV",
    country_name: "Latvia",
    state_code: "110",
    type: null,
    latitude: "56.30189850",
    longitude: "28.13395900",
  },
  {
    id: 2285,
    name: "Akkar",
    country_id: 121,
    country_code: "LB",
    country_name: "Lebanon",
    state_code: "AK",
    type: null,
    latitude: "34.53287630",
    longitude: "36.13281320",
  },
  {
    id: 2283,
    name: "Baalbek-Hermel",
    country_id: 121,
    country_code: "LB",
    country_name: "Lebanon",
    state_code: "BH",
    type: null,
    latitude: "34.26585560",
    longitude: "36.34980970",
  },
  {
    id: 2286,
    name: "Beirut",
    country_id: 121,
    country_code: "LB",
    country_name: "Lebanon",
    state_code: "BA",
    type: null,
    latitude: "33.88861060",
    longitude: "35.49547720",
  },
  {
    id: 2287,
    name: "Beqaa",
    country_id: 121,
    country_code: "LB",
    country_name: "Lebanon",
    state_code: "BI",
    type: null,
    latitude: "33.84626620",
    longitude: "35.90194890",
  },
  {
    id: 2282,
    name: "Mount Lebanon",
    country_id: 121,
    country_code: "LB",
    country_name: "Lebanon",
    state_code: "JL",
    type: null,
    latitude: "33.81008580",
    longitude: "35.59731390",
  },
  {
    id: 2288,
    name: "Nabatieh",
    country_id: 121,
    country_code: "LB",
    country_name: "Lebanon",
    state_code: "NA",
    type: null,
    latitude: "33.37716930",
    longitude: "35.48382930",
  },
  {
    id: 2284,
    name: "North",
    country_id: 121,
    country_code: "LB",
    country_name: "Lebanon",
    state_code: "AS",
    type: null,
    latitude: "34.43806250",
    longitude: "35.83082330",
  },
  {
    id: 2281,
    name: "South",
    country_id: 121,
    country_code: "LB",
    country_name: "Lebanon",
    state_code: "JA",
    type: null,
    latitude: "33.27214790",
    longitude: "35.20327780",
  },
  {
    id: 3030,
    name: "Berea District",
    country_id: 122,
    country_code: "LS",
    country_name: "Lesotho",
    state_code: "D",
    type: null,
    latitude: "41.36616140",
    longitude: "-81.85430260",
  },
  {
    id: 3029,
    name: "Butha-Buthe District",
    country_id: 122,
    country_code: "LS",
    country_name: "Lesotho",
    state_code: "B",
    type: null,
    latitude: "-28.76537540",
    longitude: "28.24681480",
  },
  {
    id: 3026,
    name: "Leribe District",
    country_id: 122,
    country_code: "LS",
    country_name: "Lesotho",
    state_code: "C",
    type: null,
    latitude: "-28.86380650",
    longitude: "28.04788260",
  },
  {
    id: 3022,
    name: "Mafeteng District",
    country_id: 122,
    country_code: "LS",
    country_name: "Lesotho",
    state_code: "E",
    type: null,
    latitude: "-29.80410080",
    longitude: "27.50261740",
  },
  {
    id: 3028,
    name: "Maseru District",
    country_id: 122,
    country_code: "LS",
    country_name: "Lesotho",
    state_code: "A",
    type: null,
    latitude: "-29.51656500",
    longitude: "27.83114280",
  },
  {
    id: 3023,
    name: "Mohale's Hoek District",
    country_id: 122,
    country_code: "LS",
    country_name: "Lesotho",
    state_code: "F",
    type: null,
    latitude: "-30.14259170",
    longitude: "27.46738450",
  },
  {
    id: 3024,
    name: "Mokhotlong District",
    country_id: 122,
    country_code: "LS",
    country_name: "Lesotho",
    state_code: "J",
    type: null,
    latitude: "-29.25731930",
    longitude: "28.95286450",
  },
  {
    id: 3025,
    name: "Qacha's Nek District",
    country_id: 122,
    country_code: "LS",
    country_name: "Lesotho",
    state_code: "H",
    type: null,
    latitude: "-30.11145650",
    longitude: "28.67897900",
  },
  {
    id: 3027,
    name: "Quthing District",
    country_id: 122,
    country_code: "LS",
    country_name: "Lesotho",
    state_code: "G",
    type: null,
    latitude: "-30.40156870",
    longitude: "27.70801330",
  },
  {
    id: 3031,
    name: "Thaba-Tseka District",
    country_id: 122,
    country_code: "LS",
    country_name: "Lesotho",
    state_code: "K",
    type: null,
    latitude: "-29.52389750",
    longitude: "28.60897520",
  },
  {
    id: 3041,
    name: "Bomi County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "BM",
    type: null,
    latitude: "6.75629260",
    longitude: "-10.84514670",
  },
  {
    id: 3034,
    name: "Bong County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "BG",
    type: null,
    latitude: "6.82950190",
    longitude: "-9.36730840",
  },
  {
    id: 3044,
    name: "Gbarpolu County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "GP",
    type: null,
    latitude: "7.49526370",
    longitude: "-10.08072980",
  },
  {
    id: 3040,
    name: "Grand Bassa County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "GB",
    type: null,
    latitude: "6.23084520",
    longitude: "-9.81249350",
  },
  {
    id: 3036,
    name: "Grand Cape Mount County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "CM",
    type: null,
    latitude: "7.04677580",
    longitude: "-11.07117580",
  },
  {
    id: 3039,
    name: "Grand Gedeh County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "GG",
    type: null,
    latitude: "5.92220780",
    longitude: "-8.22129790",
  },
  {
    id: 3045,
    name: "Grand Kru County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "GK",
    type: null,
    latitude: "4.76138620",
    longitude: "-8.22129790",
  },
  {
    id: 3037,
    name: "Lofa County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "LO",
    type: null,
    latitude: "8.19111840",
    longitude: "-9.72326730",
  },
  {
    id: 3043,
    name: "Margibi County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "MG",
    type: null,
    latitude: "6.51518750",
    longitude: "-10.30488970",
  },
  {
    id: 3042,
    name: "Maryland County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "MY",
    type: null,
    latitude: "39.04575490",
    longitude: "-76.64127120",
  },
  {
    id: 3032,
    name: "Montserrado County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "MO",
    type: null,
    latitude: "6.55258150",
    longitude: "-10.52961150",
  },
  {
    id: 3046,
    name: "Nimba",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "NI",
    type: null,
    latitude: "7.61666670",
    longitude: "-8.41666670",
  },
  {
    id: 3033,
    name: "River Cess County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "RI",
    type: null,
    latitude: "5.90253280",
    longitude: "-9.45615500",
  },
  {
    id: 3038,
    name: "River Gee County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "RG",
    type: null,
    latitude: "5.26048940",
    longitude: "-7.87216000",
  },
  {
    id: 3035,
    name: "Sinoe County",
    country_id: 123,
    country_code: "LR",
    country_name: "Liberia",
    state_code: "SI",
    type: null,
    latitude: "5.49871000",
    longitude: "-8.66005860",
  },
  {
    id: 2964,
    name: "Al Wahat District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "WA",
    type: null,
    latitude: "29.04668080",
    longitude: "21.85685860",
  },
  {
    id: 2981,
    name: "Benghazi",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "BA",
    type: null,
    latitude: "32.11942420",
    longitude: "20.08679090",
  },
  {
    id: 2966,
    name: "Derna District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "DR",
    type: null,
    latitude: "32.75561300",
    longitude: "22.63774320",
  },
  {
    id: 2969,
    name: "Ghat District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "GT",
    type: null,
    latitude: "24.96403710",
    longitude: "10.17592850",
  },
  {
    id: 2980,
    name: "Jabal al Akhdar",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "JA",
    type: null,
    latitude: "23.18560810",
    longitude: "57.37138790",
  },
  {
    id: 2974,
    name: "Jabal al Gharbi District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "JG",
    type: null,
    latitude: "30.26380320",
    longitude: "12.80547530",
  },
  {
    id: 2979,
    name: "Jafara",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "JI",
    type: null,
    latitude: "32.45259040",
    longitude: "12.94355360",
  },
  {
    id: 2970,
    name: "Jufra",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "JU",
    type: null,
    latitude: "27.98351350",
    longitude: "16.91225100",
  },
  {
    id: 2972,
    name: "Kufra District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "KF",
    type: null,
    latitude: "23.31123890",
    longitude: "21.85685860",
  },
  {
    id: 2968,
    name: "Marj District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "MJ",
    type: null,
    latitude: "32.05503630",
    longitude: "21.18911510",
  },
  {
    id: 2978,
    name: "Misrata District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "MI",
    type: null,
    latitude: "32.32558840",
    longitude: "15.09925560",
  },
  {
    id: 2961,
    name: "Murqub",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "MB",
    type: null,
    latitude: "32.45996770",
    longitude: "14.10013260",
  },
  {
    id: 2967,
    name: "Murzuq District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "MQ",
    type: null,
    latitude: "25.91822620",
    longitude: "13.92600010",
  },
  {
    id: 2976,
    name: "Nalut District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "NL",
    type: null,
    latitude: "31.87423480",
    longitude: "10.97504840",
  },
  {
    id: 2962,
    name: "Nuqat al Khams",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "NQ",
    type: null,
    latitude: "32.69149090",
    longitude: "11.88917210",
  },
  {
    id: 2965,
    name: "Sabha District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "SB",
    type: null,
    latitude: "27.03654060",
    longitude: "14.42902360",
  },
  {
    id: 2977,
    name: "Sirte District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "SR",
    type: null,
    latitude: "31.18968900",
    longitude: "16.57019270",
  },
  {
    id: 2971,
    name: "Tripoli District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "TB",
    type: null,
    latitude: "32.64080210",
    longitude: "13.26634790",
  },
  {
    id: 2973,
    name: "Wadi al Hayaa District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "WD",
    type: null,
    latitude: "26.42259260",
    longitude: "12.62162110",
  },
  {
    id: 2975,
    name: "Wadi al Shatii District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "WS",
    type: null,
    latitude: "27.73514680",
    longitude: "12.43805810",
  },
  {
    id: 2963,
    name: "Zawiya District",
    country_id: 124,
    country_code: "LY",
    country_name: "Libya",
    state_code: "ZA",
    type: null,
    latitude: "32.76302820",
    longitude: "12.73649620",
  },
  {
    id: 458,
    name: "Balzers",
    country_id: 125,
    country_code: "LI",
    country_name: "Liechtenstein",
    state_code: "01",
    type: null,
    latitude: "42.05283570",
    longitude: "-88.03668480",
  },
  {
    id: 451,
    name: "Eschen",
    country_id: 125,
    country_code: "LI",
    country_name: "Liechtenstein",
    state_code: "02",
    type: null,
    latitude: "40.76695740",
    longitude: "-73.95228210",
  },
  {
    id: 457,
    name: "Gamprin",
    country_id: 125,
    country_code: "LI",
    country_name: "Liechtenstein",
    state_code: "03",
    type: null,
    latitude: "47.21324900",
    longitude: "9.50251950",
  },
  {
    id: 455,
    name: "Mauren",
    country_id: 125,
    country_code: "LI",
    country_name: "Liechtenstein",
    state_code: "04",
    type: null,
    latitude: "47.21892850",
    longitude: "9.54173500",
  },
  {
    id: 454,
    name: "Planken",
    country_id: 125,
    country_code: "LI",
    country_name: "Liechtenstein",
    state_code: "05",
    type: null,
    latitude: "40.66505760",
    longitude: "-73.50479800",
  },
  {
    id: 453,
    name: "Ruggell",
    country_id: 125,
    country_code: "LI",
    country_name: "Liechtenstein",
    state_code: "06",
    type: null,
    latitude: "47.25292220",
    longitude: "9.54021270",
  },
  {
    id: 450,
    name: "Schaan",
    country_id: 125,
    country_code: "LI",
    country_name: "Liechtenstein",
    state_code: "07",
    type: null,
    latitude: "47.12043400",
    longitude: "9.59416020",
  },
  {
    id: 449,
    name: "Schellenberg",
    country_id: 125,
    country_code: "LI",
    country_name: "Liechtenstein",
    state_code: "08",
    type: null,
    latitude: "47.23096600",
    longitude: "9.54678430",
  },
  {
    id: 459,
    name: "Triesen",
    country_id: 125,
    country_code: "LI",
    country_name: "Liechtenstein",
    state_code: "09",
    type: null,
    latitude: "47.10979880",
    longitude: "9.52482960",
  },
  {
    id: 456,
    name: "Triesenberg",
    country_id: 125,
    country_code: "LI",
    country_name: "Liechtenstein",
    state_code: "10",
    type: null,
    latitude: "47.12245110",
    longitude: "9.57019850",
  },
  {
    id: 452,
    name: "Vaduz",
    country_id: 125,
    country_code: "LI",
    country_name: "Liechtenstein",
    state_code: "11",
    type: null,
    latitude: "47.14103030",
    longitude: "9.52092770",
  },
  {
    id: 1561,
    name: "Akmenė District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "01",
    type: null,
    latitude: "56.24550290",
    longitude: "22.74711690",
  },
  {
    id: 1605,
    name: "Alytus City Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "02",
    type: null,
    latitude: "54.39629380",
    longitude: "24.04587610",
  },
  {
    id: 1574,
    name: "Alytus County",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "AL",
    type: null,
    latitude: "54.20002140",
    longitude: "24.15126340",
  },
  {
    id: 1599,
    name: "Alytus District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "03",
    type: null,
    latitude: "54.32974960",
    longitude: "24.19609310",
  },
  {
    id: 1603,
    name: "Birštonas Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "05",
    type: null,
    latitude: "54.56696640",
    longitude: "24.00930980",
  },
  {
    id: 1566,
    name: "Biržai District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "06",
    type: null,
    latitude: "56.20177190",
    longitude: "24.75601180",
  },
  {
    id: 1579,
    name: "Druskininkai municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "07",
    type: null,
    latitude: "53.99336850",
    longitude: "24.03424380",
  },
  {
    id: 1559,
    name: "Elektrėnai municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "08",
    type: null,
    latitude: "54.76539340",
    longitude: "24.77405830",
  },
  {
    id: 1562,
    name: "Ignalina District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "09",
    type: null,
    latitude: "55.40903420",
    longitude: "26.32848930",
  },
  {
    id: 1567,
    name: "Jonava District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "10",
    type: null,
    latitude: "55.07272420",
    longitude: "24.27933370",
  },
  {
    id: 1581,
    name: "Joniškis District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "11",
    type: null,
    latitude: "56.23607300",
    longitude: "23.61365790",
  },
  {
    id: 1555,
    name: "Jurbarkas District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "12",
    type: null,
    latitude: "55.07740700",
    longitude: "22.74195690",
  },
  {
    id: 1583,
    name: "Kaišiadorys District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "13",
    type: null,
    latitude: "54.85886690",
    longitude: "24.42779290",
  },
  {
    id: 1591,
    name: "Kalvarija municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "14",
    type: null,
    latitude: "54.37616740",
    longitude: "23.19203210",
  },
  {
    id: 1580,
    name: "Kaunas City Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "15",
    type: null,
    latitude: "54.91453260",
    longitude: "23.90535180",
  },
  {
    id: 1556,
    name: "Kaunas County",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "KU",
    type: null,
    latitude: "54.98728630",
    longitude: "23.95257360",
  },
  {
    id: 1565,
    name: "Kaunas District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "16",
    type: null,
    latitude: "54.99362360",
    longitude: "23.63249410",
  },
  {
    id: 1575,
    name: "Kazlų Rūda municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "17",
    type: null,
    latitude: "54.78075260",
    longitude: "23.48402430",
  },
  {
    id: 1584,
    name: "Kėdainiai District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "18",
    type: null,
    latitude: "55.35609470",
    longitude: "23.98326830",
  },
  {
    id: 1618,
    name: "Kelmė District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "19",
    type: null,
    latitude: "55.62663520",
    longitude: "22.87817200",
  },
  {
    id: 1597,
    name: "Klaipeda City Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "20",
    type: null,
    latitude: "55.70329480",
    longitude: "21.14427950",
  },
  {
    id: 1600,
    name: "Klaipėda County",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "KL",
    type: null,
    latitude: "55.65197440",
    longitude: "21.37439560",
  },
  {
    id: 1604,
    name: "Klaipėda District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "21",
    type: null,
    latitude: "55.68416150",
    longitude: "21.44164640",
  },
  {
    id: 1571,
    name: "Kretinga District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "22",
    type: null,
    latitude: "55.88384200",
    longitude: "21.23509190",
  },
  {
    id: 1585,
    name: "Kupiškis District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "23",
    type: null,
    latitude: "55.84287410",
    longitude: "25.02958160",
  },
  {
    id: 1611,
    name: "Lazdijai District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "24",
    type: null,
    latitude: "54.23432670",
    longitude: "23.51565050",
  },
  {
    id: 1570,
    name: "Marijampolė County",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "MR",
    type: null,
    latitude: "54.78199710",
    longitude: "23.13413650",
  },
  {
    id: 1610,
    name: "Marijampolė Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "25",
    type: null,
    latitude: "54.57110940",
    longitude: "23.48593710",
  },
  {
    id: 1557,
    name: "Mažeikiai District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "26",
    type: null,
    latitude: "56.30924390",
    longitude: "22.34146800",
  },
  {
    id: 1582,
    name: "Molėtai District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "27",
    type: null,
    latitude: "55.22653090",
    longitude: "25.41800110",
  },
  {
    id: 1563,
    name: "Neringa Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "28",
    type: null,
    latitude: "55.45724030",
    longitude: "21.08390050",
  },
  {
    id: 1612,
    name: "Pagėgiai municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "29",
    type: null,
    latitude: "55.17213200",
    longitude: "21.96836140",
  },
  {
    id: 1595,
    name: "Pakruojis District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "30",
    type: null,
    latitude: "56.07326050",
    longitude: "23.93899060",
  },
  {
    id: 1588,
    name: "Palanga City Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "31",
    type: null,
    latitude: "55.92019800",
    longitude: "21.06776140",
  },
  {
    id: 1589,
    name: "Panevėžys City Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "32",
    type: null,
    latitude: "55.73479150",
    longitude: "24.35747740",
  },
  {
    id: 1558,
    name: "Panevėžys County",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "PN",
    type: null,
    latitude: "55.97480490",
    longitude: "25.07947670",
  },
  {
    id: 1614,
    name: "Panevėžys District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "33",
    type: null,
    latitude: "55.61667280",
    longitude: "24.31422830",
  },
  {
    id: 1616,
    name: "Pasvalys District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "34",
    type: null,
    latitude: "56.06046190",
    longitude: "24.39629100",
  },
  {
    id: 1553,
    name: "Plungė District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "35",
    type: null,
    latitude: "55.91078400",
    longitude: "21.84540690",
  },
  {
    id: 1578,
    name: "Prienai District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "36",
    type: null,
    latitude: "54.63835800",
    longitude: "23.94680090",
  },
  {
    id: 1568,
    name: "Radviliškis District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "37",
    type: null,
    latitude: "55.81083990",
    longitude: "23.54648700",
  },
  {
    id: 1587,
    name: "Raseiniai District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "38",
    type: null,
    latitude: "55.38194990",
    longitude: "23.11561290",
  },
  {
    id: 1590,
    name: "Rietavas municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "39",
    type: null,
    latitude: "55.70217190",
    longitude: "21.99865640",
  },
  {
    id: 1615,
    name: "Rokiškis District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "40",
    type: null,
    latitude: "55.95550390",
    longitude: "25.58592490",
  },
  {
    id: 1576,
    name: "Šakiai District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "41",
    type: null,
    latitude: "54.95267100",
    longitude: "23.04801990",
  },
  {
    id: 1577,
    name: "Šalčininkai District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "42",
    type: null,
    latitude: "54.30976700",
    longitude: "25.38756400",
  },
  {
    id: 1609,
    name: "Šiauliai City Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "43",
    type: null,
    latitude: "55.93490850",
    longitude: "23.31368230",
  },
  {
    id: 1586,
    name: "Šiauliai County",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "SA",
    type: null,
    latitude: "55.99857510",
    longitude: "23.13800510",
  },
  {
    id: 1554,
    name: "Šiauliai District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "44",
    type: null,
    latitude: "55.97214560",
    longitude: "23.03323710",
  },
  {
    id: 1613,
    name: "Šilalė District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "45",
    type: null,
    latitude: "55.49268000",
    longitude: "22.18455590",
  },
  {
    id: 1607,
    name: "Šilutė District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "46",
    type: null,
    latitude: "55.35041400",
    longitude: "21.46598590",
  },
  {
    id: 1594,
    name: "Širvintos District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "47",
    type: null,
    latitude: "55.04310200",
    longitude: "24.95698100",
  },
  {
    id: 1617,
    name: "Skuodas District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "48",
    type: null,
    latitude: "56.27021690",
    longitude: "21.52143310",
  },
  {
    id: 1560,
    name: "Švenčionys District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "49",
    type: null,
    latitude: "55.10280980",
    longitude: "26.00718550",
  },
  {
    id: 1573,
    name: "Tauragė County",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "TA",
    type: null,
    latitude: "55.30725860",
    longitude: "22.35729390",
  },
  {
    id: 1572,
    name: "Tauragė District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "50",
    type: null,
    latitude: "55.25036600",
    longitude: "22.29095000",
  },
  {
    id: 1569,
    name: "Telšiai County",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "TE",
    type: null,
    latitude: "56.10266160",
    longitude: "22.11139150",
  },
  {
    id: 1608,
    name: "Telšiai District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "51",
    type: null,
    latitude: "55.91752150",
    longitude: "22.34518400",
  },
  {
    id: 1593,
    name: "Trakai District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "52",
    type: null,
    latitude: "54.63791130",
    longitude: "24.93468940",
  },
  {
    id: 1596,
    name: "Ukmergė District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "53",
    type: null,
    latitude: "55.24526500",
    longitude: "24.77607490",
  },
  {
    id: 1621,
    name: "Utena County",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "UT",
    type: null,
    latitude: "55.53189690",
    longitude: "25.79046990",
  },
  {
    id: 1598,
    name: "Utena District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "54",
    type: null,
    latitude: "55.50846140",
    longitude: "25.68326420",
  },
  {
    id: 1602,
    name: "Varėna District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "55",
    type: null,
    latitude: "54.22033300",
    longitude: "24.57899700",
  },
  {
    id: 1620,
    name: "Vilkaviškis District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "56",
    type: null,
    latitude: "54.65194500",
    longitude: "23.03515500",
  },
  {
    id: 1606,
    name: "Vilnius City Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "57",
    type: null,
    latitude: "54.67107610",
    longitude: "25.28787210",
  },
  {
    id: 1601,
    name: "Vilnius County",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "VL",
    type: null,
    latitude: "54.80865020",
    longitude: "25.21821390",
  },
  {
    id: 1592,
    name: "Vilnius District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "58",
    type: null,
    latitude: "54.77325780",
    longitude: "25.58671130",
  },
  {
    id: 1564,
    name: "Visaginas Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "59",
    type: null,
    latitude: "55.59411800",
    longitude: "26.43079540",
  },
  {
    id: 1619,
    name: "Zarasai District Municipality",
    country_id: 126,
    country_code: "LT",
    country_name: "Lithuania",
    state_code: "60",
    type: null,
    latitude: "55.73096090",
    longitude: "26.24529500",
  },
  {
    id: 1518,
    name: "Canton of Capellen",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "CA",
    type: null,
    latitude: "49.64039310",
    longitude: "5.95538460",
  },
  {
    id: 1521,
    name: "Canton of Clervaux",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "CL",
    type: null,
    latitude: "50.05463130",
    longitude: "6.02858750",
  },
  {
    id: 1513,
    name: "Canton of Diekirch",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "DI",
    type: null,
    latitude: "49.86717840",
    longitude: "6.15956330",
  },
  {
    id: 1515,
    name: "Canton of Echternach",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "EC",
    type: null,
    latitude: "49.81141330",
    longitude: "6.41756350",
  },
  {
    id: 1517,
    name: "Canton of Esch-sur-Alzette",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "ES",
    type: null,
    latitude: "49.50088050",
    longitude: "5.98609250",
  },
  {
    id: 1525,
    name: "Canton of Grevenmacher",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "GR",
    type: null,
    latitude: "49.68084100",
    longitude: "6.44075930",
  },
  {
    id: 1527,
    name: "Canton of Luxembourg",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "LU",
    type: null,
    latitude: "49.63010250",
    longitude: "6.15201850",
  },
  {
    id: 1522,
    name: "Canton of Mersch",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "ME",
    type: null,
    latitude: "49.75429060",
    longitude: "6.12921850",
  },
  {
    id: 1516,
    name: "Canton of Redange",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "RD",
    type: null,
    latitude: "49.76455000",
    longitude: "5.88948000",
  },
  {
    id: 1519,
    name: "Canton of Remich",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "RM",
    type: null,
    latitude: "49.54501700",
    longitude: "6.36742220",
  },
  {
    id: 1523,
    name: "Canton of Vianden",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "VD",
    type: null,
    latitude: "49.93419240",
    longitude: "6.20199170",
  },
  {
    id: 1526,
    name: "Canton of Wiltz",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "WI",
    type: null,
    latitude: "49.96622000",
    longitude: "5.93243060",
  },
  {
    id: 1524,
    name: "Diekirch District",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "D",
    type: null,
    latitude: "49.86717200",
    longitude: "6.15963620",
  },
  {
    id: 1520,
    name: "Grevenmacher District",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "G",
    type: null,
    latitude: "49.68085100",
    longitude: "6.44075240",
  },
  {
    id: 1514,
    name: "Luxembourg District",
    country_id: 127,
    country_code: "LU",
    country_name: "Luxembourg",
    state_code: "L",
    type: null,
    latitude: "49.59537060",
    longitude: "6.13331780",
  },
  {
    id: 2951,
    name: "Antananarivo Province",
    country_id: 130,
    country_code: "MG",
    country_name: "Madagascar",
    state_code: "T",
    type: null,
    latitude: "-18.70514740",
    longitude: "46.82528380",
  },
  {
    id: 2950,
    name: "Antsiranana Province",
    country_id: 130,
    country_code: "MG",
    country_name: "Madagascar",
    state_code: "D",
    type: null,
    latitude: "-13.77153900",
    longitude: "49.52799960",
  },
  {
    id: 2948,
    name: "Fianarantsoa Province",
    country_id: 130,
    country_code: "MG",
    country_name: "Madagascar",
    state_code: "F",
    type: null,
    latitude: "-22.35362400",
    longitude: "46.82528380",
  },
  {
    id: 2953,
    name: "Mahajanga Province",
    country_id: 130,
    country_code: "MG",
    country_name: "Madagascar",
    state_code: "M",
    type: null,
    latitude: "-16.52388300",
    longitude: "46.51626200",
  },
  {
    id: 2952,
    name: "Toamasina Province",
    country_id: 130,
    country_code: "MG",
    country_name: "Madagascar",
    state_code: "A",
    type: null,
    latitude: "-18.14428110",
    longitude: "49.39578360",
  },
  {
    id: 2949,
    name: "Toliara Province",
    country_id: 130,
    country_code: "MG",
    country_name: "Madagascar",
    state_code: "U",
    type: null,
    latitude: "-23.35161910",
    longitude: "43.68549360",
  },
  {
    id: 3096,
    name: "Balaka District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "BA",
    type: null,
    latitude: "-15.05065950",
    longitude: "35.08285880",
  },
  {
    id: 3102,
    name: "Blantyre District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "BL",
    type: null,
    latitude: "-15.67785410",
    longitude: "34.95066250",
  },
  {
    id: 3092,
    name: "Central Region",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "C",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3107,
    name: "Chikwawa District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "CK",
    type: null,
    latitude: "-16.19584460",
    longitude: "34.77407930",
  },
  {
    id: 3109,
    name: "Chiradzulu District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "CR",
    type: null,
    latitude: "-15.74231510",
    longitude: "35.25879640",
  },
  {
    id: 3087,
    name: "Chitipa district",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "CT",
    type: null,
    latitude: "-9.70376550",
    longitude: "33.27002530",
  },
  {
    id: 3097,
    name: "Dedza District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "DE",
    type: null,
    latitude: "-14.18945850",
    longitude: "34.24215970",
  },
  {
    id: 3090,
    name: "Dowa District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "DO",
    type: null,
    latitude: "-13.60412560",
    longitude: "33.88577470",
  },
  {
    id: 3091,
    name: "Karonga District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "KR",
    type: null,
    latitude: "-9.90363650",
    longitude: "33.97500180",
  },
  {
    id: 3094,
    name: "Kasungu District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "KS",
    type: null,
    latitude: "-13.13670650",
    longitude: "33.25879300",
  },
  {
    id: 3093,
    name: "Likoma District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "LK",
    type: null,
    latitude: "-12.05840050",
    longitude: "34.73540310",
  },
  {
    id: 3101,
    name: "Lilongwe District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "LI",
    type: null,
    latitude: "-14.04752280",
    longitude: "33.61757700",
  },
  {
    id: 3082,
    name: "Machinga District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "MH",
    type: null,
    latitude: "-14.94072630",
    longitude: "35.47819260",
  },
  {
    id: 3110,
    name: "Mangochi District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "MG",
    type: null,
    latitude: "-14.13882480",
    longitude: "35.03881640",
  },
  {
    id: 3099,
    name: "Mchinji District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "MC",
    type: null,
    latitude: "-13.74015250",
    longitude: "32.98883190",
  },
  {
    id: 3103,
    name: "Mulanje District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "MU",
    type: null,
    latitude: "-15.93464340",
    longitude: "35.52200120",
  },
  {
    id: 3084,
    name: "Mwanza District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "MW",
    type: null,
    latitude: "-2.46711970",
    longitude: "32.89868120",
  },
  {
    id: 3104,
    name: "Mzimba District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "MZ",
    type: null,
    latitude: "-11.74754520",
    longitude: "33.52800720",
  },
  {
    id: 3095,
    name: "Nkhata Bay District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "NB",
    type: null,
    latitude: "-11.71850420",
    longitude: "34.33103640",
  },
  {
    id: 3100,
    name: "Nkhotakota District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "NK",
    type: null,
    latitude: "-12.75419610",
    longitude: "34.24215970",
  },
  {
    id: 3105,
    name: "Northern Region",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "N",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3085,
    name: "Nsanje District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "NS",
    type: null,
    latitude: "-16.72882020",
    longitude: "35.17087410",
  },
  {
    id: 3088,
    name: "Ntcheu District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "NU",
    type: null,
    latitude: "-14.90375380",
    longitude: "34.77407930",
  },
  {
    id: 3111,
    name: "Ntchisi District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "NI",
    type: null,
    latitude: "-13.28419920",
    longitude: "33.88577470",
  },
  {
    id: 3108,
    name: "Phalombe District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "PH",
    type: null,
    latitude: "-15.70920380",
    longitude: "35.65328480",
  },
  {
    id: 3089,
    name: "Rumphi District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "RU",
    type: null,
    latitude: "-10.78515370",
    longitude: "34.33103640",
  },
  {
    id: 3086,
    name: "Salima District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "SA",
    type: null,
    latitude: "-13.68095860",
    longitude: "34.41982430",
  },
  {
    id: 3106,
    name: "Southern Region",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "S",
    type: null,
    latitude: "32.75049570",
    longitude: "-97.33154760",
  },
  {
    id: 3098,
    name: "Thyolo District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "TH",
    type: null,
    latitude: "-16.12991770",
    longitude: "35.12687810",
  },
  {
    id: 3083,
    name: "Zomba District",
    country_id: 131,
    country_code: "MW",
    country_name: "Malawi",
    state_code: "ZO",
    type: null,
    latitude: "-15.37658570",
    longitude: "35.33565180",
  },
  {
    id: 1950,
    name: "Johor",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "01",
    type: null,
    latitude: "1.48536820",
    longitude: "103.76181540",
  },
  {
    id: 1947,
    name: "Kedah",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "02",
    type: null,
    latitude: "6.11839640",
    longitude: "100.36845950",
  },
  {
    id: 1946,
    name: "Kelantan",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "03",
    type: null,
    latitude: "6.12539690",
    longitude: "102.23807100",
  },
  {
    id: 1949,
    name: "Kuala Lumpur",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "14",
    type: null,
    latitude: "3.13900300",
    longitude: "101.68685500",
  },
  {
    id: 1935,
    name: "Labuan",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "15",
    type: null,
    latitude: "5.28314560",
    longitude: "115.23082500",
  },
  {
    id: 1941,
    name: "Malacca",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "04",
    type: null,
    latitude: "2.18959400",
    longitude: "102.25008680",
  },
  {
    id: 1948,
    name: "Negeri Sembilan",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "05",
    type: null,
    latitude: "2.72580580",
    longitude: "101.94237820",
  },
  {
    id: 1940,
    name: "Pahang",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "06",
    type: null,
    latitude: "3.81263180",
    longitude: "103.32562040",
  },
  {
    id: 1939,
    name: "Penang",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "07",
    type: null,
    latitude: "5.41639350",
    longitude: "100.33267860",
  },
  {
    id: 1943,
    name: "Perak",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "08",
    type: null,
    latitude: "4.59211260",
    longitude: "101.09010900",
  },
  {
    id: 1938,
    name: "Perlis",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "09",
    type: null,
    latitude: "29.92270940",
    longitude: "-90.12285590",
  },
  {
    id: 1945,
    name: "Putrajaya",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "16",
    type: null,
    latitude: "2.92636100",
    longitude: "101.69644500",
  },
  {
    id: 1936,
    name: "Sabah",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "12",
    type: null,
    latitude: "5.97883980",
    longitude: "116.07531990",
  },
  {
    id: 1937,
    name: "Sarawak",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "13",
    type: null,
    latitude: "1.55327830",
    longitude: "110.35921270",
  },
  {
    id: 1944,
    name: "Selangor",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "10",
    type: null,
    latitude: "3.07383790",
    longitude: "101.51834690",
  },
  {
    id: 1942,
    name: "Terengganu",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    state_code: "11",
    type: null,
    latitude: "5.31169160",
    longitude: "103.13241540",
  },
  {
    id: 2594,
    name: "Addu Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "01",
    type: null,
    latitude: "-0.63009950",
    longitude: "73.15856260",
  },
  {
    id: 2587,
    name: "Alif Alif Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "02",
    type: null,
    latitude: "4.08500000",
    longitude: "72.85154790",
  },
  {
    id: 2600,
    name: "Alif Dhaal Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "00",
    type: null,
    latitude: "3.65433020",
    longitude: "72.80427970",
  },
  {
    id: 2604,
    name: "Central Province",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "CE",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2590,
    name: "Dhaalu Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "17",
    type: null,
    latitude: "2.84685020",
    longitude: "72.94605660",
  },
  {
    id: 2599,
    name: "Faafu Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "14",
    type: null,
    latitude: "3.23094090",
    longitude: "72.94605660",
  },
  {
    id: 2598,
    name: "Gaafu Alif Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "27",
    type: null,
    latitude: "0.61248130",
    longitude: "73.32370800",
  },
  {
    id: 2603,
    name: "Gaafu Dhaalu Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "28",
    type: null,
    latitude: "0.35880400",
    longitude: "73.18216230",
  },
  {
    id: 2595,
    name: "Gnaviyani Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "29",
    type: null,
    latitude: "-0.30064250",
    longitude: "73.42391430",
  },
  {
    id: 2586,
    name: "Haa Alif Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "07",
    type: null,
    latitude: "6.99034880",
    longitude: "72.94605660",
  },
  {
    id: 2597,
    name: "Haa Dhaalu Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "23",
    type: null,
    latitude: "6.57827170",
    longitude: "72.94605660",
  },
  {
    id: 2596,
    name: "Kaafu Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "26",
    type: null,
    latitude: "4.45589790",
    longitude: "73.55941280",
  },
  {
    id: 2601,
    name: "Laamu Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "05",
    type: null,
    latitude: "1.94307370",
    longitude: "73.41802110",
  },
  {
    id: 2607,
    name: "Lhaviyani Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "03",
    type: null,
    latitude: "5.37470210",
    longitude: "73.51229280",
  },
  {
    id: 2609,
    name: "Malé",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "MLE",
    type: null,
    latitude: "46.34888670",
    longitude: "10.90724890",
  },
  {
    id: 2608,
    name: "Meemu Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "12",
    type: null,
    latitude: "3.00903450",
    longitude: "73.51229280",
  },
  {
    id: 2592,
    name: "Noonu Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "25",
    type: null,
    latitude: "5.85512760",
    longitude: "73.32370800",
  },
  {
    id: 2589,
    name: "North Central Province",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "NC",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2588,
    name: "North Province",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "NO",
    type: null,
    latitude: "8.88550270",
    longitude: "80.27673270",
  },
  {
    id: 2602,
    name: "Raa Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "13",
    type: null,
    latitude: "5.60064570",
    longitude: "72.94605660",
  },
  {
    id: 2585,
    name: "Shaviyani Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "24",
    type: null,
    latitude: "6.17511000",
    longitude: "73.13496050",
  },
  {
    id: 2606,
    name: "South Central Province",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "SC",
    type: null,
    latitude: "7.25649960",
    longitude: "80.72144170",
  },
  {
    id: 2605,
    name: "South Province",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "SU",
    type: null,
    latitude: "-21.74820060",
    longitude: "166.17837390",
  },
  {
    id: 2591,
    name: "Thaa Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "08",
    type: null,
    latitude: "2.43111610",
    longitude: "73.18216230",
  },
  {
    id: 2593,
    name: "Upper South Province",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "US",
    type: null,
    latitude: "0.23070000",
    longitude: "73.27948460",
  },
  {
    id: 2584,
    name: "Vaavu Atoll",
    country_id: 133,
    country_code: "MV",
    country_name: "Maldives",
    state_code: "04",
    type: null,
    latitude: "3.39554380",
    longitude: "73.51229280",
  },
  {
    id: 253,
    name: "Bamako",
    country_id: 134,
    country_code: "ML",
    country_name: "Mali",
    state_code: "BKO",
    type: null,
    latitude: "12.63923160",
    longitude: "-8.00288920",
  },
  {
    id: 258,
    name: "Gao Region",
    country_id: 134,
    country_code: "ML",
    country_name: "Mali",
    state_code: "7",
    type: null,
    latitude: "16.90663320",
    longitude: "1.52086240",
  },
  {
    id: 252,
    name: "Kayes Region",
    country_id: 134,
    country_code: "ML",
    country_name: "Mali",
    state_code: "1",
    type: null,
    latitude: "14.08183080",
    longitude: "-9.90181310",
  },
  {
    id: 257,
    name: "Kidal Region",
    country_id: 134,
    country_code: "ML",
    country_name: "Mali",
    state_code: "8",
    type: null,
    latitude: "18.79868320",
    longitude: "1.83183340",
  },
  {
    id: 250,
    name: "Koulikoro Region",
    country_id: 134,
    country_code: "ML",
    country_name: "Mali",
    state_code: "2",
    type: null,
    latitude: "13.80180740",
    longitude: "-7.43813550",
  },
  {
    id: 251,
    name: "Ménaka Region",
    country_id: 134,
    country_code: "ML",
    country_name: "Mali",
    state_code: "9",
    type: null,
    latitude: "15.91564210",
    longitude: "2.39617400",
  },
  {
    id: 255,
    name: "Mopti Region",
    country_id: 134,
    country_code: "ML",
    country_name: "Mali",
    state_code: "5",
    type: null,
    latitude: "14.63380390",
    longitude: "-3.41955270",
  },
  {
    id: 249,
    name: "Ségou Region",
    country_id: 134,
    country_code: "ML",
    country_name: "Mali",
    state_code: "4",
    type: null,
    latitude: "13.83944560",
    longitude: "-6.06791940",
  },
  {
    id: 254,
    name: "Sikasso Region",
    country_id: 134,
    country_code: "ML",
    country_name: "Mali",
    state_code: "3",
    type: null,
    latitude: "10.89051860",
    longitude: "-7.43813550",
  },
  {
    id: 256,
    name: "Taoudénit Region",
    country_id: 134,
    country_code: "ML",
    country_name: "Mali",
    state_code: "10",
    type: null,
    latitude: "22.67641320",
    longitude: "-3.97891430",
  },
  {
    id: 248,
    name: "Tombouctou Region",
    country_id: 134,
    country_code: "ML",
    country_name: "Mali",
    state_code: "6",
    type: null,
    latitude: "21.05267060",
    longitude: "-3.74350900",
  },
  {
    id: 110,
    name: "Attard",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "01",
    type: null,
    latitude: "35.89049670",
    longitude: "14.41993220",
  },
  {
    id: 108,
    name: "Balzan",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "02",
    type: null,
    latitude: "35.89574140",
    longitude: "14.45340650",
  },
  {
    id: 107,
    name: "Birgu",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "03",
    type: null,
    latitude: "35.88792140",
    longitude: "14.52256200",
  },
  {
    id: 97,
    name: "Birkirkara",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "04",
    type: null,
    latitude: "35.89547060",
    longitude: "14.46650720",
  },
  {
    id: 88,
    name: "Birżebbuġa",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "05",
    type: null,
    latitude: "35.81359890",
    longitude: "14.52474630",
  },
  {
    id: 138,
    name: "Cospicua",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "06",
    type: null,
    latitude: "35.88067530",
    longitude: "14.52183380",
  },
  {
    id: 117,
    name: "Dingli",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "07",
    type: null,
    latitude: "35.86273090",
    longitude: "14.38501070",
  },
  {
    id: 129,
    name: "Fgura",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "08",
    type: null,
    latitude: "35.87382690",
    longitude: "14.52329010",
  },
  {
    id: 84,
    name: "Floriana",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "09",
    type: null,
    latitude: "45.49521850",
    longitude: "-73.71395760",
  },
  {
    id: 134,
    name: "Fontana",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "10",
    type: null,
    latitude: "34.09223350",
    longitude: "-117.43504800",
  },
  {
    id: 130,
    name: "Għajnsielem",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "13",
    type: null,
    latitude: "36.02479660",
    longitude: "14.28029610",
  },
  {
    id: 92,
    name: "Għarb",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "14",
    type: null,
    latitude: "36.06890900",
    longitude: "14.20180980",
  },
  {
    id: 120,
    name: "Għargħur",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "15",
    type: null,
    latitude: "35.92205690",
    longitude: "14.45631760",
  },
  {
    id: 106,
    name: "Għasri",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "16",
    type: null,
    latitude: "36.06680750",
    longitude: "14.21924750",
  },
  {
    id: 124,
    name: "Għaxaq",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "17",
    type: null,
    latitude: "35.84403590",
    longitude: "14.51600900",
  },
  {
    id: 118,
    name: "Gudja",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "11",
    type: null,
    latitude: "35.84698030",
    longitude: "14.50290400",
  },
  {
    id: 113,
    name: "Gżira",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "12",
    type: null,
    latitude: "35.90589700",
    longitude: "14.49533380",
  },
  {
    id: 105,
    name: "Ħamrun",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "18",
    type: null,
    latitude: "35.88612370",
    longitude: "14.48834420",
  },
  {
    id: 93,
    name: "Iklin",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "19",
    type: null,
    latitude: "35.90987740",
    longitude: "14.45777320",
  },
  {
    id: 99,
    name: "Kalkara",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "21",
    type: null,
    latitude: "35.89142420",
    longitude: "14.53202780",
  },
  {
    id: 91,
    name: "Kerċem",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "22",
    type: null,
    latitude: "36.04479390",
    longitude: "14.22506050",
  },
  {
    id: 82,
    name: "Kirkop",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "23",
    type: null,
    latitude: "35.84378620",
    longitude: "14.48543240",
  },
  {
    id: 126,
    name: "Lija",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "24",
    type: null,
    latitude: "49.18007600",
    longitude: "-123.10331700",
  },
  {
    id: 77,
    name: "Luqa",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "25",
    type: null,
    latitude: "35.85828650",
    longitude: "14.48688830",
  },
  {
    id: 128,
    name: "Marsa",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "26",
    type: null,
    latitude: "34.03195870",
    longitude: "-118.44555350",
  },
  {
    id: 137,
    name: "Marsaskala",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "27",
    type: null,
    latitude: "35.86036400",
    longitude: "14.55678760",
  },
  {
    id: 78,
    name: "Marsaxlokk",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "28",
    type: null,
    latitude: "35.84116990",
    longitude: "14.53930970",
  },
  {
    id: 89,
    name: "Mdina",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "29",
    type: null,
    latitude: "35.88809300",
    longitude: "14.40683570",
  },
  {
    id: 102,
    name: "Mellieħa",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "30",
    type: null,
    latitude: "35.95235290",
    longitude: "14.35009750",
  },
  {
    id: 109,
    name: "Mġarr",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "31",
    type: null,
    latitude: "35.91893270",
    longitude: "14.36173430",
  },
  {
    id: 140,
    name: "Mosta",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "32",
    type: null,
    latitude: "35.91415040",
    longitude: "14.42284270",
  },
  {
    id: 74,
    name: "Mqabba",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "33",
    type: null,
    latitude: "35.84441430",
    longitude: "14.46941860",
  },
  {
    id: 96,
    name: "Msida",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "34",
    type: null,
    latitude: "35.89563880",
    longitude: "14.48688830",
  },
  {
    id: 131,
    name: "Mtarfa",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "35",
    type: null,
    latitude: "35.88951250",
    longitude: "14.39519530",
  },
  {
    id: 132,
    name: "Munxar",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "36",
    type: null,
    latitude: "36.02880580",
    longitude: "14.22506050",
  },
  {
    id: 133,
    name: "Nadur",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "37",
    type: null,
    latitude: "36.04470190",
    longitude: "14.29192730",
  },
  {
    id: 112,
    name: "Naxxar",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "38",
    type: null,
    latitude: "35.93175180",
    longitude: "14.43157460",
  },
  {
    id: 115,
    name: "Paola",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "39",
    type: null,
    latitude: "38.57223530",
    longitude: "-94.87912940",
  },
  {
    id: 125,
    name: "Pembroke",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "40",
    type: null,
    latitude: "34.68016260",
    longitude: "-79.19503730",
  },
  {
    id: 127,
    name: "Pietà",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "41",
    type: null,
    latitude: "42.21862000",
    longitude: "-83.73464700",
  },
  {
    id: 79,
    name: "Qala",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "42",
    type: null,
    latitude: "36.03886280",
    longitude: "14.31810100",
  },
  {
    id: 119,
    name: "Qormi",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "43",
    type: null,
    latitude: "35.87643880",
    longitude: "14.46941860",
  },
  {
    id: 111,
    name: "Qrendi",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "44",
    type: null,
    latitude: "35.83284880",
    longitude: "14.45486210",
  },
  {
    id: 83,
    name: "Rabat",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "46",
    type: null,
    latitude: "33.97159040",
    longitude: "-6.84981290",
  },
  {
    id: 87,
    name: "Saint Lawrence",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "50",
    type: null,
    latitude: "38.95780560",
    longitude: "-95.25656890",
  },
  {
    id: 75,
    name: "San Ġwann",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "49",
    type: null,
    latitude: "35.90773650",
    longitude: "14.47524160",
  },
  {
    id: 116,
    name: "Sannat",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "52",
    type: null,
    latitude: "36.01926430",
    longitude: "14.25994370",
  },
  {
    id: 94,
    name: "Santa Luċija",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "53",
    type: null,
    latitude: "35.85614200",
    longitude: "14.50436000",
  },
  {
    id: 90,
    name: "Santa Venera",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "54",
    type: null,
    latitude: "35.89022010",
    longitude: "14.47669740",
  },
  {
    id: 136,
    name: "Senglea",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "20",
    type: null,
    latitude: "35.88730410",
    longitude: "14.51673710",
  },
  {
    id: 98,
    name: "Siġġiewi",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "55",
    type: null,
    latitude: "35.84637420",
    longitude: "14.43157460",
  },
  {
    id: 104,
    name: "Sliema",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "56",
    type: null,
    latitude: "35.91100810",
    longitude: "14.50290400",
  },
  {
    id: 100,
    name: "St. Julian's",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "48",
    type: null,
    latitude: "42.21225130",
    longitude: "-85.89171270",
  },
  {
    id: 139,
    name: "St. Paul's Bay",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "51",
    type: null,
    latitude: "35.93601700",
    longitude: "14.39665030",
  },
  {
    id: 86,
    name: "Swieqi",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "57",
    type: null,
    latitude: "35.91911820",
    longitude: "14.46941860",
  },
  {
    id: 122,
    name: "Ta' Xbiex",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "58",
    type: null,
    latitude: "35.89914480",
    longitude: "14.49635190",
  },
  {
    id: 103,
    name: "Tarxien",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "59",
    type: null,
    latitude: "35.86725520",
    longitude: "14.51164050",
  },
  {
    id: 95,
    name: "Valletta",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "60",
    type: null,
    latitude: "35.89890850",
    longitude: "14.51455280",
  },
  {
    id: 101,
    name: "Victoria",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "45",
    type: null,
    latitude: "28.80526740",
    longitude: "-97.00359820",
  },
  {
    id: 114,
    name: "Xagħra",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "61",
    type: null,
    latitude: "36.05084500",
    longitude: "14.26748200",
  },
  {
    id: 121,
    name: "Xewkija",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "62",
    type: null,
    latitude: "36.02992360",
    longitude: "14.25994370",
  },
  {
    id: 81,
    name: "Xgħajra",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "63",
    type: null,
    latitude: "35.88682820",
    longitude: "14.54723910",
  },
  {
    id: 123,
    name: "Żabbar",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "64",
    type: null,
    latitude: "35.87247150",
    longitude: "14.54513540",
  },
  {
    id: 85,
    name: "Żebbuġ Gozo",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "65",
    type: null,
    latitude: "36.07164030",
    longitude: "14.24540800",
  },
  {
    id: 80,
    name: "Żebbuġ Malta",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "66",
    type: null,
    latitude: "35.87646480",
    longitude: "14.43908400",
  },
  {
    id: 135,
    name: "Żejtun",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "67",
    type: null,
    latitude: "35.85487140",
    longitude: "14.53639690",
  },
  {
    id: 76,
    name: "Żurrieq",
    country_id: 135,
    country_code: "MT",
    country_name: "Malta",
    state_code: "68",
    type: null,
    latitude: "35.82163060",
    longitude: "14.48106480",
  },
  {
    id: 2574,
    name: "Ralik Chain",
    country_id: 137,
    country_code: "MH",
    country_name: "Marshall Islands",
    state_code: "L",
    type: null,
    latitude: "8.13614600",
    longitude: "164.88679560",
  },
  {
    id: 2573,
    name: "Ratak Chain",
    country_id: 137,
    country_code: "MH",
    country_name: "Marshall Islands",
    state_code: "T",
    type: null,
    latitude: "10.27632760",
    longitude: "170.55009370",
  },
  {
    id: 3344,
    name: "Adrar",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "07",
    type: "region",
    latitude: "19.86521760",
    longitude: "-12.80547530",
  },
  {
    id: 3349,
    name: "Assaba",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "03",
    type: "region",
    latitude: "16.77595580",
    longitude: "-11.52480550",
  },
  {
    id: 3339,
    name: "Brakna",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "05",
    type: "region",
    latitude: "17.23175610",
    longitude: "-13.17403480",
  },
  {
    id: 3346,
    name: "Dakhlet Nouadhibou",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "08",
    type: "region",
    latitude: "20.59855880",
    longitude: "-16.25221430",
  },
  {
    id: 3341,
    name: "Gorgol",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "04",
    type: "region",
    latitude: "15.97173570",
    longitude: "-12.62162110",
  },
  {
    id: 3350,
    name: "Guidimaka",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "10",
    type: "region",
    latitude: "15.25573310",
    longitude: "-12.25479190",
  },
  {
    id: 3338,
    name: "Hodh Ech Chargui",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "01",
    type: "region",
    latitude: "18.67370260",
    longitude: "-7.09287700",
  },
  {
    id: 3351,
    name: "Hodh El Gharbi",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "02",
    type: "region",
    latitude: "16.69121490",
    longitude: "-9.54509740",
  },
  {
    id: 3342,
    name: "Inchiri",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "12",
    type: "region",
    latitude: "20.02805610",
    longitude: "-15.40680790",
  },
  {
    id: 3343,
    name: "Nouakchott-Nord",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "14",
    type: "region",
    latitude: "18.11302050",
    longitude: "-15.89949560",
  },
  {
    id: 3352,
    name: "Nouakchott-Ouest",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "13",
    type: "region",
    latitude: "18.15113570",
    longitude: "-15.99349100",
  },
  {
    id: 3347,
    name: "Nouakchott-Sud",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "15",
    type: "region",
    latitude: "17.97092880",
    longitude: "-15.94648740",
  },
  {
    id: 3345,
    name: "Tagant",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "09",
    type: "region",
    latitude: "18.54675270",
    longitude: "-9.90181310",
  },
  {
    id: 3340,
    name: "Tiris Zemmour",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "11",
    type: "region",
    latitude: "24.57737640",
    longitude: "-9.90181310",
  },
  {
    id: 3348,
    name: "Trarza",
    country_id: 139,
    country_code: "MR",
    country_name: "Mauritania",
    state_code: "06",
    type: "region",
    latitude: "17.86649640",
    longitude: "-14.65878210",
  },
  {
    id: 3248,
    name: "Agalega Islands",
    country_id: 140,
    country_code: "MU",
    country_name: "Mauritius",
    state_code: "AG",
    type: "dependency",
    latitude: "-10.40000000",
    longitude: "56.61666670",
  },
  {
    id: 3259,
    name: "Black River",
    country_id: 140,
    country_code: "MU",
    country_name: "Mauritius",
    state_code: "BL",
    type: "district",
    latitude: "-20.37084920",
    longitude: "57.39486490",
  },
  {
    id: 3254,
    name: "Flacq",
    country_id: 140,
    country_code: "MU",
    country_name: "Mauritius",
    state_code: "FL",
    type: "district",
    latitude: "-20.22578360",
    longitude: "57.71192740",
  },
  {
    id: 3264,
    name: "Grand Port",
    country_id: 140,
    country_code: "MU",
    country_name: "Mauritius",
    state_code: "GP",
    type: "district",
    latitude: "-20.38515460",
    longitude: "57.66657420",
  },
  {
    id: 3253,
    name: "Moka",
    country_id: 140,
    country_code: "MU",
    country_name: "Mauritius",
    state_code: "MO",
    type: "district",
    latitude: "-20.23997820",
    longitude: "57.57592600",
  },
  {
    id: 3250,
    name: "Pamplemousses",
    country_id: 140,
    country_code: "MU",
    country_name: "Mauritius",
    state_code: "PA",
    type: "district",
    latitude: "-20.11360080",
    longitude: "57.57592600",
  },
  {
    id: 3263,
    name: "Plaines Wilhems",
    country_id: 140,
    country_code: "MU",
    country_name: "Mauritius",
    state_code: "PW",
    type: "district",
    latitude: "-20.30548720",
    longitude: "57.48535610",
  },
  {
    id: 3260,
    name: "Port Louis",
    country_id: 140,
    country_code: "MU",
    country_name: "Mauritius",
    state_code: "PL",
    type: "district",
    latitude: "-20.16089120",
    longitude: "57.50122220",
  },
  {
    id: 3261,
    name: "Rivière du Rempart",
    country_id: 140,
    country_code: "MU",
    country_name: "Mauritius",
    state_code: "RR",
    type: "district",
    latitude: "-20.05609830",
    longitude: "57.65523890",
  },
  {
    id: 3249,
    name: "Rodrigues Island",
    country_id: 140,
    country_code: "MU",
    country_name: "Mauritius",
    state_code: "RO",
    type: "dependency",
    latitude: "-19.72453850",
    longitude: "63.42721850",
  },
  {
    id: 3251,
    name: "Saint Brandon Islands",
    country_id: 140,
    country_code: "MU",
    country_name: "Mauritius",
    state_code: "CC",
    type: "dependency",
    latitude: "-16.58333300",
    longitude: "59.61666700",
  },
  {
    id: 3257,
    name: "Savanne",
    country_id: 140,
    country_code: "MU",
    country_name: "Mauritius",
    state_code: "SA",
    type: "district",
    latitude: "-20.47395300",
    longitude: "57.48535610",
  },
  {
    id: 3456,
    name: "Aguascalientes",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "AGU",
    type: "state",
    latitude: "21.88525620",
    longitude: "-102.29156770",
  },
  {
    id: 3457,
    name: "Baja California",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "BCN",
    type: "state",
    latitude: "30.84063380",
    longitude: "-115.28375850",
  },
  {
    id: 3460,
    name: "Baja California Sur",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "BCS",
    type: "state",
    latitude: "26.04444460",
    longitude: "-111.66607250",
  },
  {
    id: 3475,
    name: "Campeche",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "CAM",
    type: "state",
    latitude: "19.83012510",
    longitude: "-90.53490870",
  },
  {
    id: 3451,
    name: "Chiapas",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "CHP",
    type: "state",
    latitude: "16.75693180",
    longitude: "-93.12923530",
  },
  {
    id: 3447,
    name: "Chihuahua",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "CHH",
    type: "state",
    latitude: "28.63299570",
    longitude: "-106.06910040",
  },
  {
    id: 3473,
    name: "Ciudad de México",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "CDMX",
    type: "federal district",
    latitude: "19.43260770",
    longitude: "-99.13320800",
  },
  {
    id: 3471,
    name: "Coahuila de Zaragoza",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "COA",
    type: "state",
    latitude: "27.05867600",
    longitude: "-101.70682940",
  },
  {
    id: 3472,
    name: "Colima",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "COL",
    type: "state",
    latitude: "19.24523420",
    longitude: "-103.72408680",
  },
  {
    id: 3453,
    name: "Durango",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "DUR",
    type: "state",
    latitude: "37.27528000",
    longitude: "-107.88006670",
  },
  {
    id: 3450,
    name: "Estado de México",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "MEX",
    type: "state",
    latitude: "23.63450100",
    longitude: "-102.55278400",
  },
  {
    id: 3469,
    name: "Guanajuato",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "GUA",
    type: "state",
    latitude: "21.01901450",
    longitude: "-101.25735860",
  },
  {
    id: 3459,
    name: "Guerrero",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "GRO",
    type: "state",
    latitude: "17.43919260",
    longitude: "-99.54509740",
  },
  {
    id: 3470,
    name: "Hidalgo",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "HID",
    type: "state",
    latitude: "26.10035470",
    longitude: "-98.26306840",
  },
  {
    id: 4857,
    name: "Jalisco",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "JAL",
    type: "state",
    latitude: "20.65953820",
    longitude: "-103.34943760",
  },
  {
    id: 3474,
    name: "Michoacán de Ocampo",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "MIC",
    type: "state",
    latitude: "19.56651920",
    longitude: "-101.70682940",
  },
  {
    id: 3465,
    name: "Morelos",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "MOR",
    type: "state",
    latitude: "18.68130490",
    longitude: "-99.10134980",
  },
  {
    id: 3477,
    name: "Nayarit",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "NAY",
    type: "state",
    latitude: "21.75138440",
    longitude: "-104.84546190",
  },
  {
    id: 3452,
    name: "Nuevo León",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "NLE",
    type: "state",
    latitude: "25.59217200",
    longitude: "-99.99619470",
  },
  {
    id: 3448,
    name: "Oaxaca",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "OAX",
    type: "state",
    latitude: "17.07318420",
    longitude: "-96.72658890",
  },
  {
    id: 3476,
    name: "Puebla",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "PUE",
    type: "state",
    latitude: "19.04143980",
    longitude: "-98.20627270",
  },
  {
    id: 3455,
    name: "Querétaro",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "QUE",
    type: "state",
    latitude: "20.58879320",
    longitude: "-100.38988810",
  },
  {
    id: 3467,
    name: "Quintana Roo",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "ROO",
    type: "state",
    latitude: "19.18173930",
    longitude: "-88.47913760",
  },
  {
    id: 3461,
    name: "San Luis Potosí",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "SLP",
    type: "state",
    latitude: "22.15646990",
    longitude: "-100.98554090",
  },
  {
    id: 3449,
    name: "Sinaloa",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "SIN",
    type: "state",
    latitude: "25.17210910",
    longitude: "-107.47951730",
  },
  {
    id: 3468,
    name: "Sonora",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "SON",
    type: "state",
    latitude: "37.98294960",
    longitude: "-120.38217240",
  },
  {
    id: 3454,
    name: "Tabasco",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "TAB",
    type: "state",
    latitude: "17.84091730",
    longitude: "-92.61892730",
  },
  {
    id: 3463,
    name: "Tamaulipas",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "TAM",
    type: "state",
    latitude: "24.26694000",
    longitude: "-98.83627550",
  },
  {
    id: 3458,
    name: "Tlaxcala",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "TLA",
    type: "state",
    latitude: "19.31815400",
    longitude: "-98.23749540",
  },
  {
    id: 3464,
    name: "Veracruz de Ignacio de la Llave",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "VER",
    type: "state",
    latitude: "19.17377300",
    longitude: "-96.13422410",
  },
  {
    id: 3466,
    name: "Yucatán",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "YUC",
    type: "state",
    latitude: "20.70987860",
    longitude: "-89.09433770",
  },
  {
    id: 3462,
    name: "Zacatecas",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    state_code: "ZAC",
    type: "state",
    latitude: "22.77085550",
    longitude: "-102.58324260",
  },
  {
    id: 2580,
    name: "Chuuk State",
    country_id: 143,
    country_code: "FM",
    country_name: "Micronesia",
    state_code: "TRK",
    type: null,
    latitude: "7.13867590",
    longitude: "151.55930650",
  },
  {
    id: 2583,
    name: "Kosrae State",
    country_id: 143,
    country_code: "FM",
    country_name: "Micronesia",
    state_code: "KSA",
    type: null,
    latitude: "5.30956180",
    longitude: "162.98148770",
  },
  {
    id: 2581,
    name: "Pohnpei State",
    country_id: 143,
    country_code: "FM",
    country_name: "Micronesia",
    state_code: "PNI",
    type: null,
    latitude: "6.85412540",
    longitude: "158.26238220",
  },
  {
    id: 2582,
    name: "Yap State",
    country_id: 143,
    country_code: "FM",
    country_name: "Micronesia",
    state_code: "YAP",
    type: null,
    latitude: "8.67164900",
    longitude: "142.84393350",
  },
  {
    id: 4368,
    name: "Anenii Noi District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "AN",
    type: null,
    latitude: "46.87956630",
    longitude: "29.23121750",
  },
  {
    id: 4393,
    name: "Bălți Municipality",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "BA",
    type: null,
    latitude: "47.75399470",
    longitude: "27.91841480",
  },
  {
    id: 4379,
    name: "Basarabeasca District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "BS",
    type: null,
    latitude: "46.42370600",
    longitude: "28.89354920",
  },
  {
    id: 4362,
    name: "Bender Municipality",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "BD",
    type: null,
    latitude: "46.82275510",
    longitude: "29.46201010",
  },
  {
    id: 4375,
    name: "Briceni District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "BR",
    type: null,
    latitude: "48.36320220",
    longitude: "27.07503980",
  },
  {
    id: 4391,
    name: "Cahul District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "CA",
    type: null,
    latitude: "45.89394040",
    longitude: "28.18902750",
  },
  {
    id: 4366,
    name: "Călărași District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "CL",
    type: null,
    latitude: "47.28694600",
    longitude: "28.27453100",
  },
  {
    id: 4380,
    name: "Cantemir District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "CT",
    type: null,
    latitude: "46.27717420",
    longitude: "28.20096530",
  },
  {
    id: 4365,
    name: "Căușeni District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "CS",
    type: null,
    latitude: "46.65547150",
    longitude: "29.40912220",
  },
  {
    id: 4373,
    name: "Chișinău Municipality",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "CU",
    type: null,
    latitude: "47.01045290",
    longitude: "28.86381020",
  },
  {
    id: 4360,
    name: "Cimișlia District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "CM",
    type: null,
    latitude: "46.52508510",
    longitude: "28.77218350",
  },
  {
    id: 4390,
    name: "Criuleni District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "CR",
    type: null,
    latitude: "47.21361140",
    longitude: "29.15575190",
  },
  {
    id: 4384,
    name: "Dondușeni District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "DO",
    type: null,
    latitude: "48.23383050",
    longitude: "27.59980870",
  },
  {
    id: 4392,
    name: "Drochia District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "DR",
    type: null,
    latitude: "48.07977880",
    longitude: "27.86041140",
  },
  {
    id: 4383,
    name: "Dubăsari District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "DU",
    type: null,
    latitude: "47.26439420",
    longitude: "29.15503480",
  },
  {
    id: 4387,
    name: "Edineț District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "ED",
    type: null,
    latitude: "48.16789910",
    longitude: "27.29361430",
  },
  {
    id: 4381,
    name: "Fălești District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "FA",
    type: null,
    latitude: "47.56477250",
    longitude: "27.72655930",
  },
  {
    id: 4370,
    name: "Florești District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "FL",
    type: null,
    latitude: "47.86678490",
    longitude: "28.33918640",
  },
  {
    id: 4385,
    name: "Gagauzia",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "GA",
    type: null,
    latitude: "46.09794350",
    longitude: "28.63846450",
  },
  {
    id: 4367,
    name: "Glodeni District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "GL",
    type: null,
    latitude: "47.77901560",
    longitude: "27.51680100",
  },
  {
    id: 4382,
    name: "Hîncești District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "HI",
    type: null,
    latitude: "46.82811470",
    longitude: "28.58508890",
  },
  {
    id: 4369,
    name: "Ialoveni District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "IA",
    type: null,
    latitude: "46.86308600",
    longitude: "28.82342180",
  },
  {
    id: 4363,
    name: "Nisporeni District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "NI",
    type: null,
    latitude: "47.07513490",
    longitude: "28.17681550",
  },
  {
    id: 4389,
    name: "Ocnița District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "OC",
    type: null,
    latitude: "48.41104350",
    longitude: "27.47680920",
  },
  {
    id: 4361,
    name: "Orhei District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "OR",
    type: null,
    latitude: "47.38604000",
    longitude: "28.83030820",
  },
  {
    id: 4394,
    name: "Rezina District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "RE",
    type: null,
    latitude: "47.71804470",
    longitude: "28.88710240",
  },
  {
    id: 4376,
    name: "Rîșcani District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "RI",
    type: null,
    latitude: "47.90701530",
    longitude: "27.53749960",
  },
  {
    id: 4364,
    name: "Sîngerei District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "SI",
    type: null,
    latitude: "47.63891340",
    longitude: "28.13718160",
  },
  {
    id: 4388,
    name: "Șoldănești District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "SD",
    type: null,
    latitude: "47.81473890",
    longitude: "28.78895860",
  },
  {
    id: 4374,
    name: "Soroca District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "SO",
    type: null,
    latitude: "48.15497430",
    longitude: "28.28707830",
  },
  {
    id: 4378,
    name: "Ștefan Vodă District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "SV",
    type: null,
    latitude: "46.55404880",
    longitude: "29.70224200",
  },
  {
    id: 4377,
    name: "Strășeni District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "ST",
    type: null,
    latitude: "47.14502670",
    longitude: "28.61367360",
  },
  {
    id: 4372,
    name: "Taraclia District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "TA",
    type: null,
    latitude: "45.89865100",
    longitude: "28.66716440",
  },
  {
    id: 4371,
    name: "Telenești District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "TE",
    type: null,
    latitude: "47.49839620",
    longitude: "28.36760190",
  },
  {
    id: 4395,
    name: "Transnistria autonomous territorial unit",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "SN",
    type: null,
    latitude: "47.21529720",
    longitude: "29.46380540",
  },
  {
    id: 4386,
    name: "Ungheni District",
    country_id: 144,
    country_code: "MD",
    country_name: "Moldova",
    state_code: "UN",
    type: null,
    latitude: "47.23057670",
    longitude: "27.78926610",
  },
  {
    id: 4917,
    name: "La Colle",
    country_id: 145,
    country_code: "MC",
    country_name: "Monaco",
    state_code: "CL",
    type: null,
    latitude: "43.73274650",
    longitude: "7.41372760",
  },
  {
    id: 4918,
    name: "La Condamine",
    country_id: 145,
    country_code: "MC",
    country_name: "Monaco",
    state_code: "CO",
    type: null,
    latitude: "43.73506650",
    longitude: "7.41990600",
  },
  {
    id: 4919,
    name: "Moneghetti",
    country_id: 145,
    country_code: "MC",
    country_name: "Monaco",
    state_code: "MG",
    type: null,
    latitude: "43.73649270",
    longitude: "7.41533830",
  },
  {
    id: 1973,
    name: "Arkhangai Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "073",
    type: null,
    latitude: "47.89711010",
    longitude: "100.72401650",
  },
  {
    id: 1969,
    name: "Bayan-Ölgii Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "071",
    type: null,
    latitude: "48.39832540",
    longitude: "89.66259150",
  },
  {
    id: 1976,
    name: "Bayankhongor Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "069",
    type: null,
    latitude: "45.15267070",
    longitude: "100.10736670",
  },
  {
    id: 1961,
    name: "Bulgan Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "067",
    type: null,
    latitude: "48.96909130",
    longitude: "102.88317230",
  },
  {
    id: 1962,
    name: "Darkhan-Uul Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "037",
    type: null,
    latitude: "49.46484340",
    longitude: "105.97459190",
  },
  {
    id: 1963,
    name: "Dornod Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "061",
    type: null,
    latitude: "47.46581540",
    longitude: "115.39271200",
  },
  {
    id: 1981,
    name: "Dornogovi Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "063",
    type: null,
    latitude: "43.96538890",
    longitude: "109.17734590",
  },
  {
    id: 1970,
    name: "Dundgovi Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "059",
    type: null,
    latitude: "45.58227860",
    longitude: "106.76442090",
  },
  {
    id: 1972,
    name: "Govi-Altai Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "065",
    type: null,
    latitude: "45.45112270",
    longitude: "95.85057660",
  },
  {
    id: 1978,
    name: "Govisümber Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "064",
    type: null,
    latitude: "46.47627540",
    longitude: "108.55706270",
  },
  {
    id: 1974,
    name: "Khentii Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "039",
    type: null,
    latitude: "47.60812090",
    longitude: "109.93728560",
  },
  {
    id: 1964,
    name: "Khovd Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "043",
    type: null,
    latitude: "47.11296540",
    longitude: "92.31107520",
  },
  {
    id: 1975,
    name: "Khövsgöl Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "041",
    type: null,
    latitude: "50.22044840",
    longitude: "100.32137680",
  },
  {
    id: 1967,
    name: "Ömnögovi Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "053",
    type: null,
    latitude: "43.50002400",
    longitude: "104.28611160",
  },
  {
    id: 1966,
    name: "Orkhon Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "035",
    type: null,
    latitude: "49.00470500",
    longitude: "104.30165270",
  },
  {
    id: 1965,
    name: "Övörkhangai Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "055",
    type: null,
    latitude: "45.76243920",
    longitude: "103.09170320",
  },
  {
    id: 1980,
    name: "Selenge Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "049",
    type: null,
    latitude: "50.00592730",
    longitude: "106.44341080",
  },
  {
    id: 1977,
    name: "Sükhbaatar Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "051",
    type: null,
    latitude: "46.56531630",
    longitude: "113.53808360",
  },
  {
    id: 1968,
    name: "Töv Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "047",
    type: null,
    latitude: "47.21240560",
    longitude: "106.41541000",
  },
  {
    id: 1971,
    name: "Uvs Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "046",
    type: null,
    latitude: "49.64497070",
    longitude: "93.27365760",
  },
  {
    id: 1979,
    name: "Zavkhan Province",
    country_id: 146,
    country_code: "MN",
    country_name: "Mongolia",
    state_code: "057",
    type: null,
    latitude: "48.23881470",
    longitude: "96.07030190",
  },
  {
    id: 23,
    name: "Andrijevica Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "01",
    type: null,
    latitude: "42.73624770",
    longitude: "19.78595560",
  },
  {
    id: 13,
    name: "Bar Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "02",
    type: null,
    latitude: "42.12781190",
    longitude: "19.14043800",
  },
  {
    id: 21,
    name: "Berane Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "03",
    type: null,
    latitude: "42.82572890",
    longitude: "19.90205090",
  },
  {
    id: 25,
    name: "Bijelo Polje Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "04",
    type: null,
    latitude: "43.08465260",
    longitude: "19.71154720",
  },
  {
    id: 30,
    name: "Budva Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "05",
    type: null,
    latitude: "42.31407200",
    longitude: "18.83138320",
  },
  {
    id: 14,
    name: "Danilovgrad Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "07",
    type: null,
    latitude: "42.58357000",
    longitude: "19.14043800",
  },
  {
    id: 24,
    name: "Gusinje Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "22",
    type: null,
    latitude: "42.55634550",
    longitude: "19.83060510",
  },
  {
    id: 31,
    name: "Kolašin Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "09",
    type: null,
    latitude: "42.76019160",
    longitude: "19.42591140",
  },
  {
    id: 26,
    name: "Kotor Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "10",
    type: null,
    latitude: "42.57402610",
    longitude: "18.64131450",
  },
  {
    id: 22,
    name: "Mojkovac Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "11",
    type: null,
    latitude: "42.96880180",
    longitude: "19.52110630",
  },
  {
    id: 17,
    name: "Nikšić Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "12",
    type: null,
    latitude: "42.79971840",
    longitude: "18.76009630",
  },
  {
    id: 28,
    name: "Old Royal Capital Cetinje",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "06",
    type: null,
    latitude: "42.39309590",
    longitude: "18.91159640",
  },
  {
    id: 12,
    name: "Petnjica Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "23",
    type: null,
    latitude: "42.93534800",
    longitude: "20.02114490",
  },
  {
    id: 19,
    name: "Plav Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "13",
    type: null,
    latitude: "42.60013370",
    longitude: "19.94075410",
  },
  {
    id: 20,
    name: "Pljevlja Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "14",
    type: null,
    latitude: "43.27233830",
    longitude: "19.28315310",
  },
  {
    id: 16,
    name: "Plužine Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "15",
    type: null,
    latitude: "43.15933840",
    longitude: "18.85514840",
  },
  {
    id: 27,
    name: "Podgorica Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "16",
    type: null,
    latitude: "42.36938340",
    longitude: "19.28315310",
  },
  {
    id: 15,
    name: "Rožaje Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "17",
    type: null,
    latitude: "42.84083890",
    longitude: "20.16706280",
  },
  {
    id: 18,
    name: "Šavnik Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "18",
    type: null,
    latitude: "42.96037560",
    longitude: "19.14043800",
  },
  {
    id: 29,
    name: "Tivat Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "19",
    type: null,
    latitude: "42.42348000",
    longitude: "18.71851840",
  },
  {
    id: 33,
    name: "Ulcinj Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "20",
    type: null,
    latitude: "41.96527950",
    longitude: "19.30694320",
  },
  {
    id: 32,
    name: "Žabljak Municipality",
    country_id: 147,
    country_code: "ME",
    country_name: "Montenegro",
    state_code: "21",
    type: null,
    latitude: "43.15551520",
    longitude: "19.12260180",
  },
  {
    id: 4928,
    name: "Agadir-Ida-Ou-Tanane",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "AGD",
    type: "prefecture",
    latitude: "30.64620910",
    longitude: "-9.83390610",
  },
  {
    id: 3320,
    name: "Al Haouz",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "HAO",
    type: "province",
    latitude: "31.29567290",
    longitude: "-7.87216000",
  },
  {
    id: 3267,
    name: "Al Hoceïma",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "HOC",
    type: "province",
    latitude: "35.24455890",
    longitude: "-3.93174680",
  },
  {
    id: 3266,
    name: "Aousserd (EH)",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "AOU",
    type: "province",
    latitude: "22.55215380",
    longitude: "-14.32973530",
  },
  {
    id: 3297,
    name: "Assa-Zag (EH-partial)",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "ASZ",
    type: "province",
    latitude: "28.14023950",
    longitude: "-9.72326730",
  },
  {
    id: 3321,
    name: "Azilal",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "AZI",
    type: "province",
    latitude: "32.00426200",
    longitude: "-6.57833870",
  },
  {
    id: 3272,
    name: "Béni Mellal",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "BEM",
    type: "province",
    latitude: "32.34244300",
    longitude: "-6.37579900",
  },
  {
    id: 3278,
    name: "Béni Mellal-Khénifra",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "05",
    type: "region",
    latitude: "32.57191840",
    longitude: "-6.06791940",
  },
  {
    id: 3304,
    name: "Benslimane",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "BES",
    type: "province",
    latitude: "33.61896980",
    longitude: "-7.13055360",
  },
  {
    id: 3285,
    name: "Berkane",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "BER",
    type: "province",
    latitude: "34.88408760",
    longitude: "-2.34188700",
  },
  {
    id: 4929,
    name: "Berrechid",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "BRR",
    type: "province",
    latitude: "33.26025230",
    longitude: "-7.59848370",
  },
  {
    id: 3275,
    name: "Boujdour (EH)",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "BOD",
    type: "province",
    latitude: "26.12524930",
    longitude: "-14.48473470",
  },
  {
    id: 3270,
    name: "Boulemane",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "BOM",
    type: "province",
    latitude: "33.36251590",
    longitude: "-4.73033970",
  },
  {
    id: 4930,
    name: "Casablanca",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "CAS",
    type: "prefecture",
    latitude: "33.57226780",
    longitude: "-7.65703260",
  },
  {
    id: 3303,
    name: "Casablanca-Settat",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "06",
    type: "region",
    latitude: "33.21608720",
    longitude: "-7.43813550",
  },
  {
    id: 3310,
    name: "Chefchaouen",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "CHE",
    type: "province",
    latitude: "35.01817200",
    longitude: "-5.14320680",
  },
  {
    id: 3274,
    name: "Chichaoua",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "CHI",
    type: "province",
    latitude: "31.53835810",
    longitude: "-8.76463880",
  },
  {
    id: 3302,
    name: "Chtouka-Ait Baha",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "CHT",
    type: "province",
    latitude: "30.10724220",
    longitude: "-9.27855830",
  },
  {
    id: 3306,
    name: "Dakhla-Oued Ed-Dahab (EH)",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "12",
    type: "region",
    latitude: "22.73378920",
    longitude: "-14.28611160",
  },
  {
    id: 3290,
    name: "Drâa-Tafilalet",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "08",
    type: "region",
    latitude: "31.14995380",
    longitude: "-5.39395510",
  },
  {
    id: 4931,
    name: "Driouch",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "DRI",
    type: "province",
    latitude: "34.97603200",
    longitude: "-3.39644930",
  },
  {
    id: 3291,
    name: "El Hajeb",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "HAJ",
    type: "province",
    latitude: "33.68573500",
    longitude: "-5.36778440",
  },
  {
    id: 3280,
    name: "El Jadida",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "JDI",
    type: "province",
    latitude: "33.23163260",
    longitude: "-8.50071160",
  },
  {
    id: 3309,
    name: "El Kelâa des Sraghna",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "KES",
    type: "province",
    latitude: "32.05227670",
    longitude: "-7.35165580",
  },
  {
    id: 3299,
    name: "Errachidia",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "ERR",
    type: "province",
    latitude: "31.90512750",
    longitude: "-4.72775280",
  },
  {
    id: 3292,
    name: "Es-Semara (EH-partial)",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "ESM",
    type: "province",
    latitude: "26.74185600",
    longitude: "-11.67836710",
  },
  {
    id: 3316,
    name: "Essaouira",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "ESI",
    type: "province",
    latitude: "31.50849260",
    longitude: "-9.75950410",
  },
  {
    id: 3300,
    name: "Fahs-Anjra",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "FAH",
    type: "province",
    latitude: "35.76019920",
    longitude: "-5.66683060",
  },
  {
    id: 4932,
    name: "Fès",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "FES",
    type: "prefecture",
    latitude: "34.02395790",
    longitude: "-5.03675990",
  },
  {
    id: 3313,
    name: "Fès-Meknès",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "03",
    type: "region",
    latitude: "34.06252900",
    longitude: "-4.72775280",
  },
  {
    id: 3301,
    name: "Figuig",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "FIG",
    type: "province",
    latitude: "32.10926130",
    longitude: "-1.22980600",
  },
  {
    id: 4933,
    name: "Fquih Ben Salah",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "FQH",
    type: "province",
    latitude: "32.50016800",
    longitude: "-6.71007170",
  },
  {
    id: 3265,
    name: "Guelmim",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "GUE",
    type: "province",
    latitude: "28.98836590",
    longitude: "-10.05274980",
  },
  {
    id: 3305,
    name: "Guelmim-Oued Noun (EH-partial)",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "10",
    type: "region",
    latitude: "28.48442810",
    longitude: "-10.08072980",
  },
  {
    id: 4934,
    name: "Guercif",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "GUF",
    type: "province",
    latitude: "34.23450360",
    longitude: "-3.38130050",
  },
  {
    id: 3325,
    name: "Ifrane",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "IFR",
    type: "province",
    latitude: "33.52280620",
    longitude: "-5.11095520",
  },
  {
    id: 3294,
    name: "Inezgane-Ait Melloul",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "INE",
    type: "prefecture",
    latitude: "30.35090980",
    longitude: "-9.38951100",
  },
  {
    id: 3307,
    name: "Jerada",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "JRA",
    type: "province",
    latitude: "34.30617910",
    longitude: "-2.17941360",
  },
  {
    id: 3308,
    name: "Kénitra",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "KEN",
    type: "province",
    latitude: "34.25405030",
    longitude: "-6.58901660",
  },
  {
    id: 3276,
    name: "Khémisset",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "KHE",
    type: "province",
    latitude: "33.81537040",
    longitude: "-6.05733020",
  },
  {
    id: 3317,
    name: "Khénifra",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "KHN",
    type: "province",
    latitude: "32.93404710",
    longitude: "-5.66157100",
  },
  {
    id: 3326,
    name: "Khouribga",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "KHO",
    type: "province",
    latitude: "32.88602300",
    longitude: "-6.92086550",
  },
  {
    id: 3271,
    name: "L'Oriental",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "02",
    type: "region",
    latitude: "37.06968300",
    longitude: "-94.51227700",
  },
  {
    id: 3293,
    name: "Laâyoune (EH)",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "LAA",
    type: "province",
    latitude: "27.15003840",
    longitude: "-13.19907580",
  },
  {
    id: 3298,
    name: "Laâyoune-Sakia El Hamra (EH-partial)",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "11",
    type: "region",
    latitude: "27.86831940",
    longitude: "-11.98046130",
  },
  {
    id: 3268,
    name: "Larache",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "LAR",
    type: "province",
    latitude: "35.17442710",
    longitude: "-6.14739640",
  },
  {
    id: 4936,
    name: "M’diq-Fnideq",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "MDF",
    type: "prefecture",
    latitude: "35.77330190",
    longitude: "-5.51433000",
  },
  {
    id: 4935,
    name: "Marrakech",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "MAR",
    type: "prefecture",
    latitude: "31.63460230",
    longitude: "-8.07789320",
  },
  {
    id: 3288,
    name: "Marrakesh-Safi",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "07",
    type: "region",
    latitude: "31.73308330",
    longitude: "-8.13385580",
  },
  {
    id: 3284,
    name: "Médiouna",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "MED",
    type: "province",
    latitude: "33.45409390",
    longitude: "-7.51660200",
  },
  {
    id: 4937,
    name: "Meknès",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "MEK",
    type: "prefecture",
    latitude: "33.88100000",
    longitude: "-5.57303970",
  },
  {
    id: 4938,
    name: "Midelt",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "MID",
    type: "province",
    latitude: "32.68550790",
    longitude: "-4.75017090",
  },
  {
    id: 4939,
    name: "Mohammadia",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "MOH",
    type: "prefecture",
    latitude: "33.68737490",
    longitude: "-7.42391420",
  },
  {
    id: 3315,
    name: "Moulay Yacoub",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "MOU",
    type: "province",
    latitude: "34.08744790",
    longitude: "-5.17840190",
  },
  {
    id: 3281,
    name: "Nador",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "NAD",
    type: "province",
    latitude: "34.91719260",
    longitude: "-2.85771050",
  },
  {
    id: 3287,
    name: "Nouaceur",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "NOU",
    type: "province",
    latitude: "33.36703930",
    longitude: "-7.57325370",
  },
  {
    id: 3269,
    name: "Ouarzazate",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "OUA",
    type: "province",
    latitude: "30.93354360",
    longitude: "-6.93701600",
  },
  {
    id: 3319,
    name: "Oued Ed-Dahab (EH)",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "OUD",
    type: "province",
    latitude: "22.73378920",
    longitude: "-14.28611160",
  },
  {
    id: 4941,
    name: "Ouezzane",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "OUZ",
    type: "province",
    latitude: "34.80634500",
    longitude: "-5.59145050",
  },
  {
    id: 4940,
    name: "Oujda-Angad",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "OUJ",
    type: "prefecture",
    latitude: "34.68375040",
    longitude: "-2.29932390",
  },
  {
    id: 4942,
    name: "Rabat",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "RAB",
    type: "prefecture",
    latitude: "33.96919900",
    longitude: "-6.92730290",
  },
  {
    id: 4927,
    name: "Rabat-Salé-Kénitra",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "04",
    type: "region",
    latitude: "34.07686400",
    longitude: "-7.34544760",
  },
  {
    id: 4943,
    name: "Rehamna",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "REH",
    type: "province",
    latitude: "32.20329050",
    longitude: "-8.56896710",
  },
  {
    id: 3311,
    name: "Safi",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "SAF",
    type: "province",
    latitude: "32.29898720",
    longitude: "-9.10134980",
  },
  {
    id: 4944,
    name: "Salé",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "SAL",
    type: "prefecture",
    latitude: "34.03775700",
    longitude: "-6.84270730",
  },
  {
    id: 3289,
    name: "Sefrou",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "SEF",
    type: "province",
    latitude: "33.83052440",
    longitude: "-4.83531540",
  },
  {
    id: 3282,
    name: "Settat",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "SET",
    type: "province",
    latitude: "32.99242420",
    longitude: "-7.62226650",
  },
  {
    id: 4945,
    name: "Sidi Bennour",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "SIB",
    type: "province",
    latitude: "32.64926020",
    longitude: "-8.44714530",
  },
  {
    id: 4946,
    name: "Sidi Ifni",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "SIF",
    type: "province",
    latitude: "29.36657970",
    longitude: "-10.21084850",
  },
  {
    id: 3279,
    name: "Sidi Kacem",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "SIK",
    type: "province",
    latitude: "34.22601720",
    longitude: "-5.71291640",
  },
  {
    id: 4952,
    name: "Sidi Slimane",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "SIL",
    type: "province",
    latitude: "34.27378280",
    longitude: "-5.98059720",
  },
  {
    id: 4947,
    name: "Skhirate-Témara",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "SKH",
    type: "prefecture",
    latitude: "33.76224250",
    longitude: "-7.04190520",
  },
  {
    id: 3295,
    name: "Souss-Massa",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "09",
    type: "region",
    latitude: "30.27506110",
    longitude: "-8.13385580",
  },
  {
    id: 3286,
    name: "Tan-Tan (EH-partial)",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "TNT",
    type: "province",
    latitude: "28.03012000",
    longitude: "-11.16173560",
  },
  {
    id: 4950,
    name: "Tanger-Assilah",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "TNG",
    type: "prefecture",
    latitude: "35.76325390",
    longitude: "-5.90450980",
  },
  {
    id: 3324,
    name: "Tanger-Tétouan-Al Hoceïma",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "01",
    type: "region",
    latitude: "35.26295580",
    longitude: "-5.56172790",
  },
  {
    id: 3323,
    name: "Taounate",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "TAO",
    type: "province",
    latitude: "34.53691700",
    longitude: "-4.63986930",
  },
  {
    id: 3322,
    name: "Taourirt",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "TAI",
    type: "province",
    latitude: "34.21259800",
    longitude: "-2.69838680",
  },
  {
    id: 4948,
    name: "Tarfaya (EH-partial)",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "TAF",
    type: "province",
    latitude: "27.93777010",
    longitude: "-12.92940630",
  },
  {
    id: 3314,
    name: "Taroudannt",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "TAR",
    type: "province",
    latitude: "30.47271260",
    longitude: "-8.87487650",
  },
  {
    id: 3312,
    name: "Tata",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "TAT",
    type: "province",
    latitude: "29.75087700",
    longitude: "-7.97563430",
  },
  {
    id: 3296,
    name: "Taza",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "TAZ",
    type: "province",
    latitude: "34.27889530",
    longitude: "-3.58126920",
  },
  {
    id: 3318,
    name: "Tétouan",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "TET",
    type: "province",
    latitude: "35.58889950",
    longitude: "-5.36255160",
  },
  {
    id: 4949,
    name: "Tinghir",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "TIN",
    type: "province",
    latitude: "31.48507940",
    longitude: "-6.20192980",
  },
  {
    id: 3277,
    name: "Tiznit",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "TIZ",
    type: "province",
    latitude: "29.69339200",
    longitude: "-9.73215700",
  },
  {
    id: 4951,
    name: "Youssoufia",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "YUS",
    type: "province",
    latitude: "32.02006790",
    longitude: "-8.86926480",
  },
  {
    id: 3283,
    name: "Zagora",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    state_code: "ZAG",
    type: "province",
    latitude: "30.57860930",
    longitude: "-5.89871390",
  },
  {
    id: 3327,
    name: "Cabo Delgado Province",
    country_id: 150,
    country_code: "MZ",
    country_name: "Mozambique",
    state_code: "P",
    type: null,
    latitude: "-12.33354740",
    longitude: "39.32062410",
  },
  {
    id: 3329,
    name: "Gaza Province",
    country_id: 150,
    country_code: "MZ",
    country_name: "Mozambique",
    state_code: "G",
    type: null,
    latitude: "-23.02219280",
    longitude: "32.71813750",
  },
  {
    id: 3330,
    name: "Inhambane Province",
    country_id: 150,
    country_code: "MZ",
    country_name: "Mozambique",
    state_code: "I",
    type: null,
    latitude: "-22.85279970",
    longitude: "34.55087580",
  },
  {
    id: 3337,
    name: "Manica Province",
    country_id: 150,
    country_code: "MZ",
    country_name: "Mozambique",
    state_code: "B",
    type: null,
    latitude: "-19.50597870",
    longitude: "33.43835300",
  },
  {
    id: 3335,
    name: "Maputo",
    country_id: 150,
    country_code: "MZ",
    country_name: "Mozambique",
    state_code: "MPM",
    type: null,
    latitude: "-25.96924800",
    longitude: "32.57317460",
  },
  {
    id: 3332,
    name: "Maputo Province",
    country_id: 150,
    country_code: "MZ",
    country_name: "Mozambique",
    state_code: "L",
    type: null,
    latitude: "-25.25698760",
    longitude: "32.53727410",
  },
  {
    id: 3336,
    name: "Nampula Province",
    country_id: 150,
    country_code: "MZ",
    country_name: "Mozambique",
    state_code: "N",
    type: null,
    latitude: "-14.76049310",
    longitude: "39.32062410",
  },
  {
    id: 3333,
    name: "Niassa Province",
    country_id: 150,
    country_code: "MZ",
    country_name: "Mozambique",
    state_code: "A",
    type: null,
    latitude: "-12.78262020",
    longitude: "36.60939260",
  },
  {
    id: 3331,
    name: "Sofala Province",
    country_id: 150,
    country_code: "MZ",
    country_name: "Mozambique",
    state_code: "S",
    type: null,
    latitude: "-19.20390730",
    longitude: "34.86241660",
  },
  {
    id: 3334,
    name: "Tete Province",
    country_id: 150,
    country_code: "MZ",
    country_name: "Mozambique",
    state_code: "T",
    type: null,
    latitude: "-15.65960560",
    longitude: "32.71813750",
  },
  {
    id: 3328,
    name: "Zambezia Province",
    country_id: 150,
    country_code: "MZ",
    country_name: "Mozambique",
    state_code: "Q",
    type: null,
    latitude: "-16.56389870",
    longitude: "36.60939260",
  },
  {
    id: 2142,
    name: "Ayeyarwady Region",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "07",
    type: null,
    latitude: "17.03421250",
    longitude: "95.22666750",
  },
  {
    id: 2141,
    name: "Bago",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "02",
    type: null,
    latitude: "17.32207110",
    longitude: "96.46632860",
  },
  {
    id: 2137,
    name: "Chin State",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "14",
    type: null,
    latitude: "22.00869780",
    longitude: "93.58126920",
  },
  {
    id: 2143,
    name: "Kachin State",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "11",
    type: null,
    latitude: "25.85090400",
    longitude: "97.43813550",
  },
  {
    id: 2144,
    name: "Kayah State",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "12",
    type: null,
    latitude: "19.23420610",
    longitude: "97.26528580",
  },
  {
    id: 2133,
    name: "Kayin State",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "13",
    type: null,
    latitude: "16.94593460",
    longitude: "97.95928630",
  },
  {
    id: 2136,
    name: "Magway Region",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "03",
    type: null,
    latitude: "19.88713860",
    longitude: "94.72775280",
  },
  {
    id: 2134,
    name: "Mandalay Region",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "04",
    type: null,
    latitude: "21.56190580",
    longitude: "95.89871390",
  },
  {
    id: 2147,
    name: "Mon State",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "15",
    type: null,
    latitude: "16.30031330",
    longitude: "97.69822720",
  },
  {
    id: 2146,
    name: "Naypyidaw Union Territory",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "18",
    type: null,
    latitude: "19.93862450",
    longitude: "96.15269850",
  },
  {
    id: 2138,
    name: "Rakhine State",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "16",
    type: null,
    latitude: "20.10408180",
    longitude: "93.58126920",
  },
  {
    id: 2145,
    name: "Sagaing Region",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "01",
    type: null,
    latitude: "24.42838100",
    longitude: "95.39395510",
  },
  {
    id: 2139,
    name: "Shan State",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "17",
    type: null,
    latitude: "22.03619850",
    longitude: "98.13385580",
  },
  {
    id: 2140,
    name: "Tanintharyi Region",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "05",
    type: null,
    latitude: "12.47068760",
    longitude: "99.01289260",
  },
  {
    id: 2135,
    name: "Yangon Region",
    country_id: 151,
    country_code: "MM",
    country_name: "Myanmar",
    state_code: "06",
    type: null,
    latitude: "16.91434880",
    longitude: "96.15269850",
  },
  {
    id: 43,
    name: "Erongo Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "ER",
    type: null,
    latitude: "-22.25656820",
    longitude: "15.40680790",
  },
  {
    id: 38,
    name: "Hardap Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "HA",
    type: null,
    latitude: "-24.23101340",
    longitude: "17.66888700",
  },
  {
    id: 45,
    name: "Karas Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "KA",
    type: null,
    latitude: "-26.84296450",
    longitude: "17.29028390",
  },
  {
    id: 36,
    name: "Kavango East Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "KE",
    type: null,
    latitude: "-18.27104800",
    longitude: "18.42760470",
  },
  {
    id: 35,
    name: "Kavango West Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "KW",
    type: null,
    latitude: "-18.27104800",
    longitude: "18.42760470",
  },
  {
    id: 44,
    name: "Khomas Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "KH",
    type: null,
    latitude: "-22.63778540",
    longitude: "17.10119310",
  },
  {
    id: 34,
    name: "Kunene Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "KU",
    type: null,
    latitude: "-19.40863170",
    longitude: "13.91439900",
  },
  {
    id: 40,
    name: "Ohangwena Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "OW",
    type: null,
    latitude: "-17.59792910",
    longitude: "16.81783770",
  },
  {
    id: 41,
    name: "Omaheke Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "OH",
    type: null,
    latitude: "-21.84666510",
    longitude: "19.18800470",
  },
  {
    id: 39,
    name: "Omusati Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "OS",
    type: null,
    latitude: "-18.40702940",
    longitude: "14.84546190",
  },
  {
    id: 37,
    name: "Oshana Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "ON",
    type: null,
    latitude: "-18.43050640",
    longitude: "15.68817880",
  },
  {
    id: 42,
    name: "Oshikoto Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "OT",
    type: null,
    latitude: "-18.41525750",
    longitude: "16.91225100",
  },
  {
    id: 46,
    name: "Otjozondjupa Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "OD",
    type: null,
    latitude: "-20.54869160",
    longitude: "17.66888700",
  },
  {
    id: 47,
    name: "Zambezi Region",
    country_id: 152,
    country_code: "NA",
    country_name: "Namibia",
    state_code: "CA",
    type: null,
    latitude: "-17.81934190",
    longitude: "23.95364660",
  },
  {
    id: 4656,
    name: "Aiwo District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "01",
    type: null,
    latitude: "-0.53400120",
    longitude: "166.91388730",
  },
  {
    id: 4658,
    name: "Anabar District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "02",
    type: null,
    latitude: "-0.51335170",
    longitude: "166.94846240",
  },
  {
    id: 4667,
    name: "Anetan District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "03",
    type: null,
    latitude: "-0.50643430",
    longitude: "166.94270060",
  },
  {
    id: 4663,
    name: "Anibare District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "04",
    type: null,
    latitude: "-0.52947580",
    longitude: "166.95134320",
  },
  {
    id: 4660,
    name: "Baiti District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "05",
    type: null,
    latitude: "-0.51043100",
    longitude: "166.92757440",
  },
  {
    id: 4665,
    name: "Boe District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "06",
    type: null,
    latitude: "39.07327760",
    longitude: "-94.57104980",
  },
  {
    id: 4662,
    name: "Buada District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "07",
    type: null,
    latitude: "-0.53287770",
    longitude: "166.92685410",
  },
  {
    id: 4666,
    name: "Denigomodu District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "08",
    type: null,
    latitude: "-0.52479640",
    longitude: "166.91676890",
  },
  {
    id: 4654,
    name: "Ewa District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "09",
    type: null,
    latitude: "-0.50872410",
    longitude: "166.93693840",
  },
  {
    id: 4661,
    name: "Ijuw District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "10",
    type: null,
    latitude: "-0.52027670",
    longitude: "166.95710460",
  },
  {
    id: 4657,
    name: "Meneng District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "11",
    type: null,
    latitude: "-0.54672400",
    longitude: "166.93837900",
  },
  {
    id: 4659,
    name: "Nibok District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "12",
    type: null,
    latitude: "-0.51962080",
    longitude: "166.91893010",
  },
  {
    id: 4655,
    name: "Uaboe District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "13",
    type: null,
    latitude: "-0.52022220",
    longitude: "166.93117610",
  },
  {
    id: 4664,
    name: "Yaren District",
    country_id: 153,
    country_code: "NR",
    country_name: "Nauru",
    state_code: "14",
    type: null,
    latitude: "-0.54668570",
    longitude: "166.92109130",
  },
  {
    id: 2082,
    name: "Bagmati Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "BA",
    type: null,
    latitude: "28.03675770",
    longitude: "85.43755740",
  },
  {
    id: 2071,
    name: "Bheri Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "BH",
    type: null,
    latitude: "28.51745600",
    longitude: "81.77870210",
  },
  {
    id: 2073,
    name: "Central Region",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "1",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2080,
    name: "Dhaulagiri Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "DH",
    type: null,
    latitude: "28.61117600",
    longitude: "83.50702030",
  },
  {
    id: 2069,
    name: "Eastern Development Region",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "4",
    type: null,
    latitude: "27.33090720",
    longitude: "87.06242610",
  },
  {
    id: 2068,
    name: "Far-Western Development Region",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "5",
    type: null,
    latitude: "29.29878710",
    longitude: "80.98710740",
  },
  {
    id: 2081,
    name: "Gandaki Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "GA",
    type: null,
    latitude: "28.37320370",
    longitude: "84.43827210",
  },
  {
    id: 2076,
    name: "Janakpur Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "JA",
    type: null,
    latitude: "27.21108990",
    longitude: "86.01215730",
  },
  {
    id: 2079,
    name: "Karnali Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "KA",
    type: null,
    latitude: "29.38625550",
    longitude: "82.38857830",
  },
  {
    id: 2072,
    name: "Kosi Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "KO",
    type: null,
    latitude: "27.05365240",
    longitude: "87.30161320",
  },
  {
    id: 2074,
    name: "Lumbini Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "LU",
    type: null,
    latitude: "27.45000000",
    longitude: "83.25000000",
  },
  {
    id: 2083,
    name: "Mahakali Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "MA",
    type: null,
    latitude: "29.36010790",
    longitude: "80.54384500",
  },
  {
    id: 2070,
    name: "Mechi Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "ME",
    type: null,
    latitude: "26.87600070",
    longitude: "87.93348030",
  },
  {
    id: 2066,
    name: "Mid-Western Region",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "2",
    type: null,
    latitude: "38.41118410",
    longitude: "-90.38320980",
  },
  {
    id: 2075,
    name: "Narayani Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "NA",
    type: null,
    latitude: "27.36117660",
    longitude: "84.85679320",
  },
  {
    id: 2077,
    name: "Rapti Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "RA",
    type: null,
    latitude: "28.27434700",
    longitude: "82.38857830",
  },
  {
    id: 2084,
    name: "Sagarmatha Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "SA",
    type: null,
    latitude: "27.32382630",
    longitude: "86.74163740",
  },
  {
    id: 2078,
    name: "Seti Zone",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "SE",
    type: null,
    latitude: "29.69054270",
    longitude: "81.33994140",
  },
  {
    id: 2067,
    name: "Western Region",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    state_code: "3",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2624,
    name: "Bonaire",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "BQ1",
    type: "special municipality",
    latitude: "12.20189020",
    longitude: "-68.26238220",
  },
  {
    id: 2613,
    name: "Drenthe",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "DR",
    type: "province",
    latitude: "52.94760120",
    longitude: "6.62305860",
  },
  {
    id: 2619,
    name: "Flevoland",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "FL",
    type: "province",
    latitude: "52.52797810",
    longitude: "5.59535080",
  },
  {
    id: 2622,
    name: "Friesland",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "FR",
    type: "province",
    latitude: "53.16416420",
    longitude: "5.78175420",
  },
  {
    id: 2611,
    name: "Gelderland",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "GE",
    type: "province",
    latitude: "52.04515500",
    longitude: "5.87182350",
  },
  {
    id: 2617,
    name: "Groningen",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "GR",
    type: "province",
    latitude: "53.21938350",
    longitude: "6.56650170",
  },
  {
    id: 2615,
    name: "Limburg",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "LI",
    type: "province",
    latitude: "51.44272380",
    longitude: "6.06087260",
  },
  {
    id: 2623,
    name: "North Brabant",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "NB",
    type: "province",
    latitude: "51.48265370",
    longitude: "5.23216870",
  },
  {
    id: 2612,
    name: "North Holland",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "NH",
    type: "province",
    latitude: "52.52058690",
    longitude: "4.78847400",
  },
  {
    id: 2618,
    name: "Overijssel",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "OV",
    type: "province",
    latitude: "52.43878140",
    longitude: "6.50164110",
  },
  {
    id: 2621,
    name: "Saba",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "BQ2",
    type: "special municipality",
    latitude: "17.63546420",
    longitude: "-63.23267630",
  },
  {
    id: 2616,
    name: "Sint Eustatius",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "BQ3",
    type: "special municipality",
    latitude: "17.48903060",
    longitude: "-62.97355500",
  },
  {
    id: 2614,
    name: "South Holland",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "ZH",
    type: "province",
    latitude: "51.99667920",
    longitude: "4.55973970",
  },
  {
    id: 2610,
    name: "Utrecht",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "UT",
    type: "province",
    latitude: "52.09073740",
    longitude: "5.12142010",
  },
  {
    id: 2620,
    name: "Zeeland",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    state_code: "ZE",
    type: "province",
    latitude: "51.49403090",
    longitude: "3.84968150",
  },
  {
    id: 4072,
    name: "Auckland Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "AUK",
    type: null,
    latitude: "-36.66753280",
    longitude: "174.77333250",
  },
  {
    id: 4074,
    name: "Bay of Plenty Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "BOP",
    type: null,
    latitude: "-37.42339170",
    longitude: "176.74163740",
  },
  {
    id: 4066,
    name: "Canterbury Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "CAN",
    type: null,
    latitude: "-43.75422750",
    longitude: "171.16372450",
  },
  {
    id: 4067,
    name: "Chatham Islands",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "CIT",
    type: null,
    latitude: "-44.00575230",
    longitude: "-176.54006740",
  },
  {
    id: 4068,
    name: "Gisborne District",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "GIS",
    type: null,
    latitude: "-38.13581740",
    longitude: "178.32393090",
  },
  {
    id: 4075,
    name: "Hawke's Bay Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "HKB",
    type: null,
    latitude: "-39.60165970",
    longitude: "176.58044730",
  },
  {
    id: 4060,
    name: "Manawatu-Wanganui Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "MWT",
    type: null,
    latitude: "-39.72733560",
    longitude: "175.43755740",
  },
  {
    id: 4063,
    name: "Marlborough Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "MBH",
    type: null,
    latitude: "-41.59168830",
    longitude: "173.76240530",
  },
  {
    id: 4070,
    name: "Nelson Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "NSN",
    type: null,
    latitude: "-41.29853970",
    longitude: "173.24414910",
  },
  {
    id: 4059,
    name: "Northland Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "NTL",
    type: null,
    latitude: "-35.41361720",
    longitude: "173.93208060",
  },
  {
    id: 4062,
    name: "Otago Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "OTA",
    type: null,
    latitude: "-45.47906710",
    longitude: "170.15475670",
  },
  {
    id: 4071,
    name: "Southland Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "STL",
    type: null,
    latitude: "-45.84891590",
    longitude: "167.67553870",
  },
  {
    id: 4069,
    name: "Taranaki Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "TKI",
    type: null,
    latitude: "-39.35381490",
    longitude: "174.43827210",
  },
  {
    id: 4073,
    name: "Tasman District",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "TAS",
    type: null,
    latitude: "-41.45711840",
    longitude: "172.82097400",
  },
  {
    id: 4061,
    name: "Waikato Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "WKO",
    type: null,
    latitude: "-37.61908620",
    longitude: "175.02334600",
  },
  {
    id: 4065,
    name: "Wellington Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "WGN",
    type: null,
    latitude: "-41.02993230",
    longitude: "175.43755740",
  },
  {
    id: 4064,
    name: "West Coast Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    state_code: "WTC",
    type: null,
    latitude: "62.41136340",
    longitude: "-149.07297140",
  },
  {
    id: 946,
    name: "Boaco",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "BO",
    type: "department",
    latitude: "12.46928400",
    longitude: "-85.66146820",
  },
  {
    id: 950,
    name: "Carazo",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "CA",
    type: "department",
    latitude: "11.72747290",
    longitude: "-86.21584970",
  },
  {
    id: 954,
    name: "Chinandega",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "CI",
    type: "department",
    latitude: "12.88200620",
    longitude: "-87.14228950",
  },
  {
    id: 940,
    name: "Chontales",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "CO",
    type: "department",
    latitude: "11.93947170",
    longitude: "-85.18940450",
  },
  {
    id: 945,
    name: "Estelí",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "ES",
    type: "department",
    latitude: "13.08511390",
    longitude: "-86.36301970",
  },
  {
    id: 943,
    name: "Granada",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "GR",
    type: "department",
    latitude: "11.93440730",
    longitude: "-85.95600050",
  },
  {
    id: 955,
    name: "Jinotega",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "JI",
    type: "department",
    latitude: "13.08839070",
    longitude: "-85.99939970",
  },
  {
    id: 944,
    name: "León",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "LE",
    type: "department",
    latitude: "12.50920370",
    longitude: "-86.66110830",
  },
  {
    id: 948,
    name: "Madriz",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "MD",
    type: "department",
    latitude: "13.47260050",
    longitude: "-86.45920910",
  },
  {
    id: 941,
    name: "Managua",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "MN",
    type: "department",
    latitude: "12.13916990",
    longitude: "-86.33767610",
  },
  {
    id: 953,
    name: "Masaya",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "MS",
    type: "department",
    latitude: "11.97593280",
    longitude: "-86.07334980",
  },
  {
    id: 947,
    name: "Matagalpa",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "MT",
    type: "department",
    latitude: "12.94984360",
    longitude: "-85.43755740",
  },
  {
    id: 951,
    name: "North Caribbean Coast",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "AN",
    type: "autonomous region",
    latitude: "13.83944560",
    longitude: "-83.93208060",
  },
  {
    id: 4964,
    name: "Nueva Segovia",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "NS",
    type: "department",
    latitude: "13.76570610",
    longitude: "-86.53700390",
  },
  {
    id: 949,
    name: "Río San Juan",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "SJ",
    type: "department",
    latitude: "11.47816100",
    longitude: "-84.77333250",
  },
  {
    id: 942,
    name: "Rivas",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "RI",
    type: "department",
    latitude: "11.40234900",
    longitude: "-85.68457800",
  },
  {
    id: 952,
    name: "South Caribbean Coast",
    country_id: 159,
    country_code: "NI",
    country_name: "Nicaragua",
    state_code: "AS",
    type: "autonomous region",
    latitude: "12.19185020",
    longitude: "-84.10128610",
  },
  {
    id: 71,
    name: "Agadez Region",
    country_id: 160,
    country_code: "NE",
    country_name: "Niger",
    state_code: "1",
    type: null,
    latitude: "20.66707520",
    longitude: "12.07182810",
  },
  {
    id: 72,
    name: "Diffa Region",
    country_id: 160,
    country_code: "NE",
    country_name: "Niger",
    state_code: "2",
    type: null,
    latitude: "13.67686470",
    longitude: "12.71351210",
  },
  {
    id: 68,
    name: "Dosso Region",
    country_id: 160,
    country_code: "NE",
    country_name: "Niger",
    state_code: "3",
    type: null,
    latitude: "13.15139470",
    longitude: "3.41955270",
  },
  {
    id: 70,
    name: "Maradi Region",
    country_id: 160,
    country_code: "NE",
    country_name: "Niger",
    state_code: "4",
    type: null,
    latitude: "13.80180740",
    longitude: "7.43813550",
  },
  {
    id: 73,
    name: "Tahoua Region",
    country_id: 160,
    country_code: "NE",
    country_name: "Niger",
    state_code: "5",
    type: null,
    latitude: "16.09025430",
    longitude: "5.39395510",
  },
  {
    id: 67,
    name: "Tillabéri Region",
    country_id: 160,
    country_code: "NE",
    country_name: "Niger",
    state_code: "6",
    type: null,
    latitude: "14.64895250",
    longitude: "2.14502450",
  },
  {
    id: 69,
    name: "Zinder Region",
    country_id: 160,
    country_code: "NE",
    country_name: "Niger",
    state_code: "7",
    type: null,
    latitude: "15.17188810",
    longitude: "10.26001250",
  },
  {
    id: 303,
    name: "Abia",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "AB",
    type: "state",
    latitude: "5.45273540",
    longitude: "7.52484140",
  },
  {
    id: 293,
    name: "Abuja Federal Capital Territory",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "FC",
    type: "capital territory",
    latitude: "8.89406910",
    longitude: "7.18604020",
  },
  {
    id: 320,
    name: "Adamawa",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "AD",
    type: "state",
    latitude: "9.32647510",
    longitude: "12.39838530",
  },
  {
    id: 304,
    name: "Akwa Ibom",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "AK",
    type: "state",
    latitude: "4.90573710",
    longitude: "7.85366750",
  },
  {
    id: 315,
    name: "Anambra",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "AN",
    type: "state",
    latitude: "6.22089970",
    longitude: "6.93695590",
  },
  {
    id: 312,
    name: "Bauchi",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "BA",
    type: "state",
    latitude: "10.77606240",
    longitude: "9.99919430",
  },
  {
    id: 305,
    name: "Bayelsa",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "BY",
    type: "state",
    latitude: "4.77190710",
    longitude: "6.06985260",
  },
  {
    id: 291,
    name: "Benue",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "BE",
    type: "state",
    latitude: "7.33690240",
    longitude: "8.74036870",
  },
  {
    id: 307,
    name: "Borno",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "BO",
    type: "state",
    latitude: "11.88463560",
    longitude: "13.15196650",
  },
  {
    id: 314,
    name: "Cross River",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "CR",
    type: "state",
    latitude: "5.87017240",
    longitude: "8.59880140",
  },
  {
    id: 316,
    name: "Delta",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "DE",
    type: "state",
    latitude: "33.74537840",
    longitude: "-90.73545080",
  },
  {
    id: 311,
    name: "Ebonyi",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "EB",
    type: "state",
    latitude: "6.26492320",
    longitude: "8.01373020",
  },
  {
    id: 318,
    name: "Edo",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "ED",
    type: "state",
    latitude: "6.63418310",
    longitude: "5.93040560",
  },
  {
    id: 309,
    name: "Ekiti",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "EK",
    type: "state",
    latitude: "7.71898620",
    longitude: "5.31095050",
  },
  {
    id: 289,
    name: "Enugu",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "EN",
    type: "state",
    latitude: "6.53635300",
    longitude: "7.43561940",
  },
  {
    id: 310,
    name: "Gombe",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "GO",
    type: "state",
    latitude: "10.36377950",
    longitude: "11.19275870",
  },
  {
    id: 308,
    name: "Imo",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "IM",
    type: "state",
    latitude: "5.57201220",
    longitude: "7.05882190",
  },
  {
    id: 288,
    name: "Jigawa",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "JI",
    type: "state",
    latitude: "12.22801200",
    longitude: "9.56158670",
  },
  {
    id: 294,
    name: "Kaduna",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "KD",
    type: "state",
    latitude: "10.37640060",
    longitude: "7.70945370",
  },
  {
    id: 300,
    name: "Kano",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "KN",
    type: "state",
    latitude: "11.74706980",
    longitude: "8.52471070",
  },
  {
    id: 313,
    name: "Katsina",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "KT",
    type: "state",
    latitude: "12.37967070",
    longitude: "7.63057480",
  },
  {
    id: 290,
    name: "Kebbi",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "KE",
    type: "state",
    latitude: "11.49420030",
    longitude: "4.23333550",
  },
  {
    id: 298,
    name: "Kogi",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "KO",
    type: "state",
    latitude: "7.73373250",
    longitude: "6.69058360",
  },
  {
    id: 295,
    name: "Kwara",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "KW",
    type: "state",
    latitude: "8.96689610",
    longitude: "4.38740510",
  },
  {
    id: 306,
    name: "Lagos",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "LA",
    type: "state",
    latitude: "6.52437930",
    longitude: "3.37920570",
  },
  {
    id: 301,
    name: "Nasarawa",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "NA",
    type: "state",
    latitude: "8.49979080",
    longitude: "8.19969370",
  },
  {
    id: 317,
    name: "Niger",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "NI",
    type: "state",
    latitude: "9.93092240",
    longitude: "5.59832100",
  },
  {
    id: 323,
    name: "Ogun",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "OG",
    type: "state",
    latitude: "6.99797470",
    longitude: "3.47373780",
  },
  {
    id: 321,
    name: "Ondo",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "ON",
    type: "state",
    latitude: "6.91486820",
    longitude: "5.14781440",
  },
  {
    id: 322,
    name: "Osun",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "OS",
    type: "state",
    latitude: "7.56289640",
    longitude: "4.51995930",
  },
  {
    id: 296,
    name: "Oyo",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "OY",
    type: "state",
    latitude: "8.15738090",
    longitude: "3.61465340",
  },
  {
    id: 302,
    name: "Plateau",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "PL",
    type: "state",
    latitude: "9.21820930",
    longitude: "9.51794880",
  },
  {
    id: 4926,
    name: "Rivers",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "RI",
    type: "state",
    latitude: "5.02134200",
    longitude: "6.43760220",
  },
  {
    id: 292,
    name: "Sokoto",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "SO",
    type: "state",
    latitude: "13.05331430",
    longitude: "5.32227220",
  },
  {
    id: 319,
    name: "Taraba",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "TA",
    type: "state",
    latitude: "7.99936160",
    longitude: "10.77398630",
  },
  {
    id: 297,
    name: "Yobe",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "YO",
    type: "state",
    latitude: "12.29387600",
    longitude: "11.43904110",
  },
  {
    id: 299,
    name: "Zamfara",
    country_id: 161,
    country_code: "NG",
    country_name: "Nigeria",
    state_code: "ZA",
    type: "state",
    latitude: "12.12218050",
    longitude: "6.22358190",
  },
  {
    id: 3998,
    name: "Chagang Province",
    country_id: 115,
    country_code: "KP",
    country_name: "North Korea",
    state_code: "04",
    type: null,
    latitude: "40.72028090",
    longitude: "126.56211370",
  },
  {
    id: 3999,
    name: "Kangwon Province",
    country_id: 115,
    country_code: "KP",
    country_name: "North Korea",
    state_code: "07",
    type: null,
    latitude: "38.84323930",
    longitude: "127.55970670",
  },
  {
    id: 3995,
    name: "North Hamgyong Province",
    country_id: 115,
    country_code: "KP",
    country_name: "North Korea",
    state_code: "09",
    type: null,
    latitude: "41.81487580",
    longitude: "129.45819550",
  },
  {
    id: 4004,
    name: "North Hwanghae Province",
    country_id: 115,
    country_code: "KP",
    country_name: "North Korea",
    state_code: "06",
    type: null,
    latitude: "38.37860850",
    longitude: "126.43643630",
  },
  {
    id: 4002,
    name: "North Pyongan Province",
    country_id: 115,
    country_code: "KP",
    country_name: "North Korea",
    state_code: "03",
    type: null,
    latitude: "39.92556180",
    longitude: "125.39280250",
  },
  {
    id: 4005,
    name: "Pyongyang",
    country_id: 115,
    country_code: "KP",
    country_name: "North Korea",
    state_code: "01",
    type: null,
    latitude: "39.03921930",
    longitude: "125.76252410",
  },
  {
    id: 4001,
    name: "Rason",
    country_id: 115,
    country_code: "KP",
    country_name: "North Korea",
    state_code: "13",
    type: null,
    latitude: "42.25690630",
    longitude: "130.29771860",
  },
  {
    id: 3996,
    name: "Ryanggang Province",
    country_id: 115,
    country_code: "KP",
    country_name: "North Korea",
    state_code: "10",
    type: null,
    latitude: "41.23189210",
    longitude: "128.50763590",
  },
  {
    id: 4000,
    name: "South Hamgyong Province",
    country_id: 115,
    country_code: "KP",
    country_name: "North Korea",
    state_code: "08",
    type: null,
    latitude: "40.37253390",
    longitude: "128.29888400",
  },
  {
    id: 4003,
    name: "South Hwanghae Province",
    country_id: 115,
    country_code: "KP",
    country_name: "North Korea",
    state_code: "05",
    type: null,
    latitude: "38.20072150",
    longitude: "125.47819260",
  },
  {
    id: 3997,
    name: "South Pyongan Province",
    country_id: 115,
    country_code: "KP",
    country_name: "North Korea",
    state_code: "02",
    type: null,
    latitude: "39.35391780",
    longitude: "126.16827100",
  },
  {
    id: 703,
    name: "Aerodrom Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "01",
    type: null,
    latitude: "41.94643630",
    longitude: "21.49317130",
  },
  {
    id: 656,
    name: "Aračinovo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "02",
    type: null,
    latitude: "42.02473810",
    longitude: "21.57664070",
  },
  {
    id: 716,
    name: "Berovo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "03",
    type: null,
    latitude: "41.66619290",
    longitude: "22.76288300",
  },
  {
    id: 679,
    name: "Bitola Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "04",
    type: null,
    latitude: "41.03633020",
    longitude: "21.33219740",
  },
  {
    id: 649,
    name: "Bogdanci Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "05",
    type: null,
    latitude: "41.18696160",
    longitude: "22.59602680",
  },
  {
    id: 721,
    name: "Bogovinje Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "06",
    type: null,
    latitude: "41.92363710",
    longitude: "20.91638870",
  },
  {
    id: 652,
    name: "Bosilovo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "07",
    type: null,
    latitude: "41.49048640",
    longitude: "22.78671740",
  },
  {
    id: 660,
    name: "Brvenica Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "08",
    type: null,
    latitude: "41.96814820",
    longitude: "20.98195860",
  },
  {
    id: 694,
    name: "Butel Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "09",
    type: null,
    latitude: "42.08950680",
    longitude: "21.46336100",
  },
  {
    id: 704,
    name: "Čair Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "79",
    type: null,
    latitude: "41.99303550",
    longitude: "21.43653180",
  },
  {
    id: 676,
    name: "Čaška Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "80",
    type: null,
    latitude: "41.64743800",
    longitude: "21.69141150",
  },
  {
    id: 702,
    name: "Centar Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "77",
    type: null,
    latitude: "41.96989340",
    longitude: "21.42162670",
  },
  {
    id: 720,
    name: "Centar Župa Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "78",
    type: null,
    latitude: "41.46522590",
    longitude: "20.59305480",
  },
  {
    id: 644,
    name: "Češinovo-Obleševo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "81",
    type: null,
    latitude: "41.86393160",
    longitude: "22.26224600",
  },
  {
    id: 715,
    name: "Čučer-Sandevo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "82",
    type: null,
    latitude: "42.14839460",
    longitude: "21.40374070",
  },
  {
    id: 645,
    name: "Debarca Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "22",
    type: null,
    latitude: "41.35840770",
    longitude: "20.85529190",
  },
  {
    id: 695,
    name: "Delčevo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "23",
    type: null,
    latitude: "41.96843870",
    longitude: "22.76288300",
  },
  {
    id: 687,
    name: "Demir Hisar Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "25",
    type: null,
    latitude: "41.22708300",
    longitude: "21.14142260",
  },
  {
    id: 655,
    name: "Demir Kapija Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "24",
    type: null,
    latitude: "41.37955380",
    longitude: "22.21455710",
  },
  {
    id: 697,
    name: "Dojran Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "26",
    type: null,
    latitude: "41.24366720",
    longitude: "22.69137640",
  },
  {
    id: 675,
    name: "Dolneni Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "27",
    type: null,
    latitude: "41.46409350",
    longitude: "21.40374070",
  },
  {
    id: 657,
    name: "Drugovo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "28",
    type: null,
    latitude: "41.44081530",
    longitude: "20.92682010",
  },
  {
    id: 707,
    name: "Gazi Baba Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "17",
    type: null,
    latitude: "42.01629610",
    longitude: "21.49913340",
  },
  {
    id: 648,
    name: "Gevgelija Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "18",
    type: null,
    latitude: "41.21186060",
    longitude: "22.38146240",
  },
  {
    id: 722,
    name: "Gjorče Petrov Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "29",
    type: null,
    latitude: "42.06063740",
    longitude: "21.32027360",
  },
  {
    id: 693,
    name: "Gostivar Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "19",
    type: null,
    latitude: "41.80255410",
    longitude: "20.90893780",
  },
  {
    id: 708,
    name: "Gradsko Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "20",
    type: null,
    latitude: "41.59916080",
    longitude: "21.88070640",
  },
  {
    id: 684,
    name: "Greater Skopje",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "85",
    type: null,
    latitude: "41.99812940",
    longitude: "21.42543550",
  },
  {
    id: 690,
    name: "Ilinden Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "34",
    type: null,
    latitude: "41.99574430",
    longitude: "21.56769750",
  },
  {
    id: 678,
    name: "Jegunovce Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "35",
    type: null,
    latitude: "42.07407200",
    longitude: "21.12204780",
  },
  {
    id: 674,
    name: "Karbinci",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "37",
    type: null,
    latitude: "41.81801590",
    longitude: "22.23247580",
  },
  {
    id: 681,
    name: "Karpoš Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "38",
    type: null,
    latitude: "41.97096610",
    longitude: "21.39181680",
  },
  {
    id: 713,
    name: "Kavadarci Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "36",
    type: null,
    latitude: "41.28900680",
    longitude: "21.99994350",
  },
  {
    id: 688,
    name: "Kičevo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "40",
    type: null,
    latitude: "41.51291120",
    longitude: "20.95250650",
  },
  {
    id: 686,
    name: "Kisela Voda Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "39",
    type: null,
    latitude: "41.92748000",
    longitude: "21.49317130",
  },
  {
    id: 723,
    name: "Kočani Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "42",
    type: null,
    latitude: "41.98583740",
    longitude: "22.40530460",
  },
  {
    id: 665,
    name: "Konče Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "41",
    type: null,
    latitude: "41.51710110",
    longitude: "22.38146240",
  },
  {
    id: 641,
    name: "Kratovo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "43",
    type: null,
    latitude: "42.05371410",
    longitude: "22.07148350",
  },
  {
    id: 677,
    name: "Kriva Palanka Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "44",
    type: null,
    latitude: "42.20584540",
    longitude: "22.33079650",
  },
  {
    id: 647,
    name: "Krivogaštani Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "45",
    type: null,
    latitude: "41.30823060",
    longitude: "21.36796890",
  },
  {
    id: 714,
    name: "Kruševo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "46",
    type: null,
    latitude: "41.37693310",
    longitude: "21.26065540",
  },
  {
    id: 683,
    name: "Kumanovo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "47",
    type: null,
    latitude: "42.07326130",
    longitude: "21.78531430",
  },
  {
    id: 659,
    name: "Lipkovo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "48",
    type: null,
    latitude: "42.20066260",
    longitude: "21.61837550",
  },
  {
    id: 705,
    name: "Lozovo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "49",
    type: null,
    latitude: "41.78181390",
    longitude: "21.90008270",
  },
  {
    id: 701,
    name: "Makedonska Kamenica Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "51",
    type: null,
    latitude: "42.06946040",
    longitude: "22.54834900",
  },
  {
    id: 692,
    name: "Makedonski Brod Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "52",
    type: null,
    latitude: "41.51330880",
    longitude: "21.21743290",
  },
  {
    id: 669,
    name: "Mavrovo and Rostuša Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "50",
    type: null,
    latitude: "41.60924270",
    longitude: "20.60124880",
  },
  {
    id: 653,
    name: "Mogila Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "53",
    type: null,
    latitude: "41.14796450",
    longitude: "21.45143690",
  },
  {
    id: 664,
    name: "Negotino Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "54",
    type: null,
    latitude: "41.49899850",
    longitude: "22.09532970",
  },
  {
    id: 696,
    name: "Novaci Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "55",
    type: null,
    latitude: "41.04426610",
    longitude: "21.45888940",
  },
  {
    id: 718,
    name: "Novo Selo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "56",
    type: null,
    latitude: "41.43255800",
    longitude: "22.88204890",
  },
  {
    id: 699,
    name: "Ohrid Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "58",
    type: null,
    latitude: "41.06820880",
    longitude: "20.75992660",
  },
  {
    id: 682,
    name: "Oslomej Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "57",
    type: null,
    latitude: "41.57583910",
    longitude: "21.02219600",
  },
  {
    id: 685,
    name: "Pehčevo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "60",
    type: null,
    latitude: "41.77371320",
    longitude: "22.88204890",
  },
  {
    id: 698,
    name: "Petrovec Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "59",
    type: null,
    latitude: "41.90298970",
    longitude: "21.68992100",
  },
  {
    id: 670,
    name: "Plasnica Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "61",
    type: null,
    latitude: "41.45463490",
    longitude: "21.10565390",
  },
  {
    id: 666,
    name: "Prilep Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "62",
    type: null,
    latitude: "41.26931420",
    longitude: "21.71376940",
  },
  {
    id: 646,
    name: "Probištip Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "63",
    type: null,
    latitude: "41.95891460",
    longitude: "22.16686700",
  },
  {
    id: 709,
    name: "Radoviš Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "64",
    type: null,
    latitude: "41.64955310",
    longitude: "22.47682870",
  },
  {
    id: 717,
    name: "Rankovce Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "65",
    type: null,
    latitude: "42.18081410",
    longitude: "22.09532970",
  },
  {
    id: 712,
    name: "Resen Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "66",
    type: null,
    latitude: "40.93680930",
    longitude: "21.04604070",
  },
  {
    id: 691,
    name: "Rosoman Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "67",
    type: null,
    latitude: "41.48480060",
    longitude: "21.88070640",
  },
  {
    id: 667,
    name: "Saraj Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "68",
    type: null,
    latitude: "41.98694960",
    longitude: "21.26065540",
  },
  {
    id: 719,
    name: "Sopište Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "70",
    type: null,
    latitude: "41.86384920",
    longitude: "21.30834990",
  },
  {
    id: 643,
    name: "Staro Nagoričane Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "71",
    type: null,
    latitude: "42.21916920",
    longitude: "21.90455410",
  },
  {
    id: 661,
    name: "Štip Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "83",
    type: null,
    latitude: "41.70792970",
    longitude: "22.19071220",
  },
  {
    id: 700,
    name: "Struga Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "72",
    type: null,
    latitude: "41.31737440",
    longitude: "20.66456830",
  },
  {
    id: 710,
    name: "Strumica Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "73",
    type: null,
    latitude: "41.43780040",
    longitude: "22.64274280",
  },
  {
    id: 711,
    name: "Studeničani Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "74",
    type: null,
    latitude: "41.92256390",
    longitude: "21.53639650",
  },
  {
    id: 680,
    name: "Šuto Orizari Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "84",
    type: null,
    latitude: "42.02904160",
    longitude: "21.40970270",
  },
  {
    id: 640,
    name: "Sveti Nikole Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "69",
    type: null,
    latitude: "41.89803120",
    longitude: "21.99994350",
  },
  {
    id: 654,
    name: "Tearce Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "75",
    type: null,
    latitude: "42.07775110",
    longitude: "21.05349230",
  },
  {
    id: 663,
    name: "Tetovo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "76",
    type: null,
    latitude: "42.02748600",
    longitude: "20.95066360",
  },
  {
    id: 671,
    name: "Valandovo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "10",
    type: null,
    latitude: "41.32119090",
    longitude: "22.50066930",
  },
  {
    id: 658,
    name: "Vasilevo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "11",
    type: null,
    latitude: "41.47416990",
    longitude: "22.64221280",
  },
  {
    id: 651,
    name: "Veles Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "13",
    type: null,
    latitude: "41.72744260",
    longitude: "21.71376940",
  },
  {
    id: 662,
    name: "Vevčani Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "12",
    type: null,
    latitude: "41.24075430",
    longitude: "20.59156490",
  },
  {
    id: 672,
    name: "Vinica Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "14",
    type: null,
    latitude: "41.85710200",
    longitude: "22.57218810",
  },
  {
    id: 650,
    name: "Vraneštica Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "15",
    type: null,
    latitude: "41.48290870",
    longitude: "21.05796320",
  },
  {
    id: 689,
    name: "Vrapčište Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "16",
    type: null,
    latitude: "41.87911600",
    longitude: "20.83145000",
  },
  {
    id: 642,
    name: "Zajas Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "31",
    type: null,
    latitude: "41.60303280",
    longitude: "20.87913430",
  },
  {
    id: 706,
    name: "Zelenikovo Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "32",
    type: null,
    latitude: "41.87338120",
    longitude: "21.60272500",
  },
  {
    id: 668,
    name: "Želino Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "30",
    type: null,
    latitude: "41.90065310",
    longitude: "21.11757670",
  },
  {
    id: 673,
    name: "Zrnovci Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    state_code: "33",
    type: null,
    latitude: "41.82282210",
    longitude: "22.41722560",
  },
  {
    id: 1014,
    name: "Agder",
    country_id: 165,
    country_code: "NO",
    country_name: "Norway",
    state_code: "42",
    type: "county",
    latitude: "58.74069340",
    longitude: "6.75315210",
  },
  {
    id: 1009,
    name: "Innlandet",
    country_id: 165,
    country_code: "NO",
    country_name: "Norway",
    state_code: "34",
    type: "county",
    latitude: "61.19357870",
    longitude: "5.50832660",
  },
  {
    id: 1026,
    name: "Jan Mayen",
    country_id: 165,
    country_code: "NO",
    country_name: "Norway",
    state_code: "22",
    type: "arctic region",
    latitude: "71.03181800",
    longitude: "-8.29203460",
  },
  {
    id: 1020,
    name: "Møre og Romsdal",
    country_id: 165,
    country_code: "NO",
    country_name: "Norway",
    state_code: "15",
    type: "county",
    latitude: "62.84068330",
    longitude: "7.00714300",
  },
  {
    id: 1025,
    name: "Nordland",
    country_id: 165,
    country_code: "NO",
    country_name: "Norway",
    state_code: "18",
    type: "county",
    latitude: "67.69305800",
    longitude: "12.70739360",
  },
  {
    id: 1007,
    name: "Oslo",
    country_id: 165,
    country_code: "NO",
    country_name: "Norway",
    state_code: "03",
    type: "county",
    latitude: "59.91386880",
    longitude: "10.75224540",
  },
  {
    id: 1021,
    name: "Rogaland",
    country_id: 165,
    country_code: "NO",
    country_name: "Norway",
    state_code: "11",
    type: "county",
    latitude: "59.14895440",
    longitude: "6.01434320",
  },
  {
    id: 1013,
    name: "Svalbard",
    country_id: 165,
    country_code: "NO",
    country_name: "Norway",
    state_code: "21",
    type: "arctic region",
    latitude: "77.87497250",
    longitude: "20.97518210",
  },
  {
    id: 1015,
    name: "Troms og Finnmark",
    country_id: 165,
    country_code: "NO",
    country_name: "Norway",
    state_code: "54",
    type: "county",
    latitude: "69.77890670",
    longitude: "18.99401840",
  },
  {
    id: 1006,
    name: "Trøndelag",
    country_id: 165,
    country_code: "NO",
    country_name: "Norway",
    state_code: "50",
    type: "county",
    latitude: "63.54201250",
    longitude: "10.93692670",
  },
  {
    id: 1024,
    name: "Vestfold og Telemark",
    country_id: 165,
    country_code: "NO",
    country_name: "Norway",
    state_code: "38",
    type: "county",
    latitude: "59.41174820",
    longitude: "7.76471750",
  },
  {
    id: 1018,
    name: "Vestland",
    country_id: 165,
    country_code: "NO",
    country_name: "Norway",
    state_code: "46",
    type: "county",
    latitude: "60.90694420",
    longitude: "3.96270810",
  },
  {
    id: 1011,
    name: "Viken",
    country_id: 165,
    country_code: "NO",
    country_name: "Norway",
    state_code: "30",
    type: "county",
    latitude: "59.96530050",
    longitude: "7.45051440",
  },
  {
    id: 3058,
    name: "Ad Dakhiliyah",
    country_id: 166,
    country_code: "OM",
    country_name: "Oman",
    state_code: "DA",
    type: null,
    latitude: "22.85887580",
    longitude: "57.53943560",
  },
  {
    id: 3047,
    name: "Ad Dhahirah",
    country_id: 166,
    country_code: "OM",
    country_name: "Oman",
    state_code: "ZA",
    type: null,
    latitude: "23.21616740",
    longitude: "56.49074440",
  },
  {
    id: 3048,
    name: "Al Batinah North",
    country_id: 166,
    country_code: "OM",
    country_name: "Oman",
    state_code: "BS",
    type: null,
    latitude: "24.34198460",
    longitude: "56.72989040",
  },
  {
    id: 3050,
    name: "Al Batinah Region",
    country_id: 166,
    country_code: "OM",
    country_name: "Oman",
    state_code: "BA",
    type: null,
    latitude: "24.34198460",
    longitude: "56.72989040",
  },
  {
    id: 3049,
    name: "Al Batinah South",
    country_id: 166,
    country_code: "OM",
    country_name: "Oman",
    state_code: "BJ",
    type: null,
    latitude: "23.43149030",
    longitude: "57.42397960",
  },
  {
    id: 3059,
    name: "Al Buraimi",
    country_id: 166,
    country_code: "OM",
    country_name: "Oman",
    state_code: "BU",
    type: null,
    latitude: "24.16714130",
    longitude: "56.11422530",
  },
  {
    id: 3056,
    name: "Al Wusta",
    country_id: 166,
    country_code: "OM",
    country_name: "Oman",
    state_code: "WU",
    type: null,
    latitude: "19.95710780",
    longitude: "56.27568460",
  },
  {
    id: 3053,
    name: "Ash Sharqiyah North",
    country_id: 166,
    country_code: "OM",
    country_name: "Oman",
    state_code: "SS",
    type: null,
    latitude: "22.71411960",
    longitude: "58.53080640",
  },
  {
    id: 3051,
    name: "Ash Sharqiyah Region",
    country_id: 166,
    country_code: "OM",
    country_name: "Oman",
    state_code: "SH",
    type: null,
    latitude: "22.71411960",
    longitude: "58.53080640",
  },
  {
    id: 3054,
    name: "Ash Sharqiyah South",
    country_id: 166,
    country_code: "OM",
    country_name: "Oman",
    state_code: "SJ",
    type: null,
    latitude: "22.01582490",
    longitude: "59.32519220",
  },
  {
    id: 3057,
    name: "Dhofar",
    country_id: 166,
    country_code: "OM",
    country_name: "Oman",
    state_code: "ZU",
    type: null,
    latitude: "17.03221210",
    longitude: "54.14252140",
  },
  {
    id: 3052,
    name: "Musandam",
    country_id: 166,
    country_code: "OM",
    country_name: "Oman",
    state_code: "MU",
    type: null,
    latitude: "26.19861440",
    longitude: "56.24609490",
  },
  {
    id: 3055,
    name: "Muscat",
    country_id: 166,
    country_code: "OM",
    country_name: "Oman",
    state_code: "MA",
    type: null,
    latitude: "23.58803070",
    longitude: "58.38287170",
  },
  {
    id: 3172,
    name: "Azad Kashmir",
    country_id: 167,
    country_code: "PK",
    country_name: "Pakistan",
    state_code: "JK",
    type: null,
    latitude: "33.92590550",
    longitude: "73.78103340",
  },
  {
    id: 3174,
    name: "Balochistan",
    country_id: 167,
    country_code: "PK",
    country_name: "Pakistan",
    state_code: "BA",
    type: null,
    latitude: "28.49073320",
    longitude: "65.09577920",
  },
  {
    id: 3173,
    name: "Federally Administered Tribal Areas",
    country_id: 167,
    country_code: "PK",
    country_name: "Pakistan",
    state_code: "TA",
    type: null,
    latitude: "32.66747600",
    longitude: "69.85974060",
  },
  {
    id: 3170,
    name: "Gilgit-Baltistan",
    country_id: 167,
    country_code: "PK",
    country_name: "Pakistan",
    state_code: "GB",
    type: null,
    latitude: "35.80256670",
    longitude: "74.98318080",
  },
  {
    id: 3169,
    name: "Islamabad Capital Territory",
    country_id: 167,
    country_code: "PK",
    country_name: "Pakistan",
    state_code: "IS",
    type: null,
    latitude: "33.72049970",
    longitude: "73.04052770",
  },
  {
    id: 3171,
    name: "Khyber Pakhtunkhwa",
    country_id: 167,
    country_code: "PK",
    country_name: "Pakistan",
    state_code: "KP",
    type: null,
    latitude: "34.95262050",
    longitude: "72.33111300",
  },
  {
    id: 3176,
    name: "Punjab",
    country_id: 167,
    country_code: "PK",
    country_name: "Pakistan",
    state_code: "PB",
    type: null,
    latitude: "31.14713050",
    longitude: "75.34121790",
  },
  {
    id: 3175,
    name: "Sindh",
    country_id: 167,
    country_code: "PK",
    country_name: "Pakistan",
    state_code: "SD",
    type: null,
    latitude: "25.89430180",
    longitude: "68.52471490",
  },
  {
    id: 4540,
    name: "Aimeliik",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "002",
    type: null,
    latitude: "7.44558590",
    longitude: "134.50308780",
  },
  {
    id: 4528,
    name: "Airai",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "004",
    type: null,
    latitude: "7.39661180",
    longitude: "134.56902250",
  },
  {
    id: 4538,
    name: "Angaur",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "010",
    type: null,
    latitude: "6.90922300",
    longitude: "134.13879340",
  },
  {
    id: 4529,
    name: "Hatohobei",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "050",
    type: null,
    latitude: "3.00706580",
    longitude: "131.12377810",
  },
  {
    id: 4539,
    name: "Kayangel",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "100",
    type: null,
    latitude: "8.07000000",
    longitude: "134.70277800",
  },
  {
    id: 4532,
    name: "Koror",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "150",
    type: null,
    latitude: "7.33756460",
    longitude: "134.48894690",
  },
  {
    id: 4530,
    name: "Melekeok",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "212",
    type: null,
    latitude: "7.51502860",
    longitude: "134.59725180",
  },
  {
    id: 4537,
    name: "Ngaraard",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "214",
    type: null,
    latitude: "7.60794000",
    longitude: "134.63486450",
  },
  {
    id: 4533,
    name: "Ngarchelong",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "218",
    type: null,
    latitude: "7.71054690",
    longitude: "134.63016460",
  },
  {
    id: 4527,
    name: "Ngardmau",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "222",
    type: null,
    latitude: "7.58504860",
    longitude: "134.55960890",
  },
  {
    id: 4531,
    name: "Ngatpang",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "224",
    type: null,
    latitude: "7.47109940",
    longitude: "134.52664660",
  },
  {
    id: 4536,
    name: "Ngchesar",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "226",
    type: null,
    latitude: "7.45232800",
    longitude: "134.57843420",
  },
  {
    id: 4541,
    name: "Ngeremlengui",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "227",
    type: null,
    latitude: "7.51983970",
    longitude: "134.55960890",
  },
  {
    id: 4534,
    name: "Ngiwal",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "228",
    type: null,
    latitude: "7.56147640",
    longitude: "134.61606190",
  },
  {
    id: 4526,
    name: "Peleliu",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "350",
    type: null,
    latitude: "7.00229060",
    longitude: "134.24316280",
  },
  {
    id: 4535,
    name: "Sonsorol",
    country_id: 168,
    country_code: "PW",
    country_name: "Palau",
    state_code: "370",
    type: null,
    latitude: "5.32681190",
    longitude: "132.22391170",
  },
  {
    id: 5118,
    name: "Bethlehem",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "BTH",
    type: "governorate",
    latitude: "31.70539960",
    longitude: "35.19368770",
  },
  {
    id: 5119,
    name: "Deir El Balah",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "DEB",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 5120,
    name: "Gaza",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "GZA",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 5121,
    name: "Hebron",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "HBN",
    type: "governorate",
    latitude: "31.53260010",
    longitude: "35.06394750",
  },
  {
    id: 5122,
    name: "Jenin",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "JEN",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 5123,
    name: "Jericho and Al Aghwar",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "JRH",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 5124,
    name: "Jerusalem",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "JEM",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 5125,
    name: "Khan Yunis",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "KYS",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 5126,
    name: "Nablus",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "NBS",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 5127,
    name: "North Gaza",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "NGZ",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 5128,
    name: "Qalqilya",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "QQA",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 5129,
    name: "Rafah",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "RFH",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 5130,
    name: "Ramallah",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "RBH",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 5131,
    name: "Salfit",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "SLT",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 5132,
    name: "Tubas",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "TBS",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 5133,
    name: "Tulkarm",
    country_id: 169,
    country_code: "PS",
    country_name: "Palestinian Territory Occupied",
    state_code: "TKM",
    type: "governorate",
    latitude: null,
    longitude: null,
  },
  {
    id: 1393,
    name: "Bocas del Toro Province",
    country_id: 170,
    country_code: "PA",
    country_name: "Panama",
    state_code: "1",
    type: null,
    latitude: "9.41655210",
    longitude: "-82.52077870",
  },
  {
    id: 1397,
    name: "Chiriquí Province",
    country_id: 170,
    country_code: "PA",
    country_name: "Panama",
    state_code: "4",
    type: null,
    latitude: "8.58489800",
    longitude: "-82.38857830",
  },
  {
    id: 1387,
    name: "Coclé Province",
    country_id: 170,
    country_code: "PA",
    country_name: "Panama",
    state_code: "2",
    type: null,
    latitude: "8.62660680",
    longitude: "-80.36586500",
  },
  {
    id: 1386,
    name: "Colón Province",
    country_id: 170,
    country_code: "PA",
    country_name: "Panama",
    state_code: "3",
    type: null,
    latitude: "9.18519890",
    longitude: "-80.05349230",
  },
  {
    id: 1385,
    name: "Darién Province",
    country_id: 170,
    country_code: "PA",
    country_name: "Panama",
    state_code: "5",
    type: null,
    latitude: "7.86817130",
    longitude: "-77.83672820",
  },
  {
    id: 1396,
    name: "Emberá-Wounaan Comarca",
    country_id: 170,
    country_code: "PA",
    country_name: "Panama",
    state_code: "EM",
    type: null,
    latitude: "8.37669830",
    longitude: "-77.65361250",
  },
  {
    id: 1388,
    name: "Guna Yala",
    country_id: 170,
    country_code: "PA",
    country_name: "Panama",
    state_code: "KY",
    type: null,
    latitude: "9.23443950",
    longitude: "-78.19262500",
  },
  {
    id: 1389,
    name: "Herrera Province",
    country_id: 170,
    country_code: "PA",
    country_name: "Panama",
    state_code: "6",
    type: null,
    latitude: "7.77042820",
    longitude: "-80.72144170",
  },
  {
    id: 1390,
    name: "Los Santos Province",
    country_id: 170,
    country_code: "PA",
    country_name: "Panama",
    state_code: "7",
    type: null,
    latitude: "7.59093020",
    longitude: "-80.36586500",
  },
  {
    id: 1391,
    name: "Ngöbe-Buglé Comarca",
    country_id: 170,
    country_code: "PA",
    country_name: "Panama",
    state_code: "NB",
    type: null,
    latitude: "8.65958330",
    longitude: "-81.77870210",
  },
  {
    id: 1394,
    name: "Panamá Oeste Province",
    country_id: 170,
    country_code: "PA",
    country_name: "Panama",
    state_code: "10",
    type: null,
    latitude: "9.11967510",
    longitude: "-79.29021330",
  },
  {
    id: 1395,
    name: "Panamá Province",
    country_id: 170,
    country_code: "PA",
    country_name: "Panama",
    state_code: "8",
    type: null,
    latitude: "9.11967510",
    longitude: "-79.29021330",
  },
  {
    id: 1392,
    name: "Veraguas Province",
    country_id: 170,
    country_code: "PA",
    country_name: "Panama",
    state_code: "9",
    type: null,
    latitude: "8.12310330",
    longitude: "-81.07546570",
  },
  {
    id: 4831,
    name: "Bougainville",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "NSB",
    type: null,
    latitude: "-6.37539190",
    longitude: "155.38071010",
  },
  {
    id: 4847,
    name: "Central Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "CPM",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 4846,
    name: "Chimbu Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "CPK",
    type: null,
    latitude: "-6.30876820",
    longitude: "144.87312190",
  },
  {
    id: 4834,
    name: "East New Britain",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "EBR",
    type: null,
    latitude: "-4.61289430",
    longitude: "151.88773210",
  },
  {
    id: 4845,
    name: "Eastern Highlands Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "EHG",
    type: null,
    latitude: "-6.58616740",
    longitude: "145.66896360",
  },
  {
    id: 4848,
    name: "Enga Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "EPW",
    type: null,
    latitude: "-5.30058490",
    longitude: "143.56356370",
  },
  {
    id: 4839,
    name: "Gulf",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "GPK",
    type: null,
    latitude: "37.05483150",
    longitude: "-94.43704190",
  },
  {
    id: 4833,
    name: "Hela",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "HLA",
    type: null,
    latitude: "42.33295160",
    longitude: "-83.04826180",
  },
  {
    id: 4832,
    name: "Jiwaka Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "JWK",
    type: null,
    latitude: "-5.86911540",
    longitude: "144.69727740",
  },
  {
    id: 4843,
    name: "Madang Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "MPM",
    type: null,
    latitude: "-4.98497330",
    longitude: "145.13758340",
  },
  {
    id: 4842,
    name: "Manus Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "MRL",
    type: null,
    latitude: "-2.09411690",
    longitude: "146.87609510",
  },
  {
    id: 4849,
    name: "Milne Bay Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "MBA",
    type: null,
    latitude: "-9.52214510",
    longitude: "150.67496530",
  },
  {
    id: 4835,
    name: "Morobe Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "MPL",
    type: null,
    latitude: "-6.80137370",
    longitude: "146.56164700",
  },
  {
    id: 4841,
    name: "New Ireland Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "NIK",
    type: null,
    latitude: "-4.28532560",
    longitude: "152.92059180",
  },
  {
    id: 4838,
    name: "Oro Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "NPP",
    type: null,
    latitude: "-8.89880630",
    longitude: "148.18929210",
  },
  {
    id: 4837,
    name: "Port Moresby",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "NCD",
    type: null,
    latitude: "-9.44380040",
    longitude: "147.18026710",
  },
  {
    id: 4836,
    name: "Sandaun Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "SAN",
    type: null,
    latitude: "-3.71261790",
    longitude: "141.68342750",
  },
  {
    id: 4844,
    name: "Southern Highlands Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "SHM",
    type: null,
    latitude: "-6.41790830",
    longitude: "143.56356370",
  },
  {
    id: 4830,
    name: "West New Britain Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "WBK",
    type: null,
    latitude: "-5.70474320",
    longitude: "150.02594660",
  },
  {
    id: 4840,
    name: "Western Highlands Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "WHM",
    type: null,
    latitude: "-5.62681280",
    longitude: "144.25931180",
  },
  {
    id: 4850,
    name: "Western Province",
    country_id: 171,
    country_code: "PG",
    country_name: "Papua new Guinea",
    state_code: "WPD",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2785,
    name: "Alto Paraguay Department",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "16",
    type: null,
    latitude: "-20.08525080",
    longitude: "-59.47209040",
  },
  {
    id: 2784,
    name: "Alto Paraná Department",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "10",
    type: null,
    latitude: "-25.60755460",
    longitude: "-54.96118360",
  },
  {
    id: 2782,
    name: "Amambay Department",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "13",
    type: null,
    latitude: "-22.55902720",
    longitude: "-56.02499820",
  },
  {
    id: 2780,
    name: "Boquerón Department",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "19",
    type: null,
    latitude: "-21.74492540",
    longitude: "-60.95400730",
  },
  {
    id: 2773,
    name: "Caaguazú",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "5",
    type: null,
    latitude: "-25.46458180",
    longitude: "-56.01385100",
  },
  {
    id: 2775,
    name: "Caazapá",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "6",
    type: null,
    latitude: "-26.18277130",
    longitude: "-56.37123270",
  },
  {
    id: 2771,
    name: "Canindeyú",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "14",
    type: null,
    latitude: "-24.13787350",
    longitude: "-55.66896360",
  },
  {
    id: 2777,
    name: "Central Department",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "11",
    type: null,
    latitude: "36.15592290",
    longitude: "-95.96620750",
  },
  {
    id: 2779,
    name: "Concepción Department",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "1",
    type: null,
    latitude: "-23.42142640",
    longitude: "-57.43444510",
  },
  {
    id: 2783,
    name: "Cordillera Department",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "3",
    type: null,
    latitude: "-25.22894910",
    longitude: "-57.01116810",
  },
  {
    id: 2772,
    name: "Guairá Department",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "4",
    type: null,
    latitude: "-25.88109320",
    longitude: "-56.29293810",
  },
  {
    id: 2778,
    name: "Itapúa",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "7",
    type: null,
    latitude: "-26.79236230",
    longitude: "-55.66896360",
  },
  {
    id: 2786,
    name: "Misiones Department",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "8",
    type: null,
    latitude: "-26.84335120",
    longitude: "-57.10131880",
  },
  {
    id: 2781,
    name: "Ñeembucú Department",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "12",
    type: null,
    latitude: "-27.02991140",
    longitude: "-57.82539500",
  },
  {
    id: 2774,
    name: "Paraguarí Department",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "9",
    type: null,
    latitude: "-25.62621740",
    longitude: "-57.15206420",
  },
  {
    id: 2770,
    name: "Presidente Hayes Department",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "15",
    type: null,
    latitude: "-23.35126050",
    longitude: "-58.73736340",
  },
  {
    id: 2776,
    name: "San Pedro Department",
    country_id: 172,
    country_code: "PY",
    country_name: "Paraguay",
    state_code: "2",
    type: null,
    latitude: "-24.19486680",
    longitude: "-56.56164700",
  },
  {
    id: 3685,
    name: "Amazonas",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "AMA",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3680,
    name: "Áncash",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "ANC",
    type: null,
    latitude: "-9.32504970",
    longitude: "-77.56194190",
  },
  {
    id: 3699,
    name: "Apurímac",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "APU",
    type: null,
    latitude: "-14.05045330",
    longitude: "-73.08774900",
  },
  {
    id: 3681,
    name: "Arequipa",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "ARE",
    type: null,
    latitude: "-16.40904740",
    longitude: "-71.53745100",
  },
  {
    id: 3692,
    name: "Ayacucho",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "AYA",
    type: null,
    latitude: "-13.16387370",
    longitude: "-74.22356410",
  },
  {
    id: 3688,
    name: "Cajamarca",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "CAJ",
    type: null,
    latitude: "-7.16174650",
    longitude: "-78.51278550",
  },
  {
    id: 3701,
    name: "Callao",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "CAL",
    type: null,
    latitude: "-12.05084910",
    longitude: "-77.12598430",
  },
  {
    id: 3691,
    name: "Cusco",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "CUS",
    type: null,
    latitude: "-13.53195000",
    longitude: "-71.96746260",
  },
  {
    id: 3679,
    name: "Huancavelica",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "HUV",
    type: null,
    latitude: "-12.78619780",
    longitude: "-74.97640240",
  },
  {
    id: 3687,
    name: "Huanuco",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "HUC",
    type: null,
    latitude: "-9.92076480",
    longitude: "-76.24108430",
  },
  {
    id: 3700,
    name: "Ica",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "ICA",
    type: null,
    latitude: "42.35288320",
    longitude: "-71.04300970",
  },
  {
    id: 3693,
    name: "Junín",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "JUN",
    type: null,
    latitude: "-11.15819250",
    longitude: "-75.99263060",
  },
  {
    id: 3683,
    name: "La Libertad",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "LAL",
    type: null,
    latitude: "13.49069700",
    longitude: "-89.30846070",
  },
  {
    id: 3702,
    name: "Lambayeque",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "LAM",
    type: null,
    latitude: "-6.71976660",
    longitude: "-79.90807570",
  },
  {
    id: 3695,
    name: "Lima",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "LIM",
    type: null,
    latitude: "-12.04637310",
    longitude: "-77.04275400",
  },
  {
    id: 4922,
    name: "Loreto",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "LOR",
    type: null,
    latitude: "-4.37416430",
    longitude: "-76.13042640",
  },
  {
    id: 3678,
    name: "Madre de Dios",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "MDD",
    type: null,
    latitude: "-11.76687050",
    longitude: "-70.81199530",
  },
  {
    id: 3698,
    name: "Moquegua",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "MOQ",
    type: null,
    latitude: "-17.19273610",
    longitude: "-70.93281380",
  },
  {
    id: 3686,
    name: "Pasco",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "PAS",
    type: null,
    latitude: "46.23050490",
    longitude: "-119.09223160",
  },
  {
    id: 3697,
    name: "Piura",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "PIU",
    type: null,
    latitude: "-5.17828840",
    longitude: "-80.65488820",
  },
  {
    id: 3682,
    name: "Puno",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "PUN",
    type: null,
    latitude: "-15.84022180",
    longitude: "-70.02188050",
  },
  {
    id: 3694,
    name: "San Martín",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "SAM",
    type: null,
    latitude: "37.08494640",
    longitude: "-121.61022160",
  },
  {
    id: 3696,
    name: "Tacna",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "TAC",
    type: null,
    latitude: "-18.00656790",
    longitude: "-70.24627410",
  },
  {
    id: 3689,
    name: "Tumbes",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "TUM",
    type: null,
    latitude: "-3.55649210",
    longitude: "-80.42708850",
  },
  {
    id: 3684,
    name: "Ucayali",
    country_id: 173,
    country_code: "PE",
    country_name: "Peru",
    state_code: "UCA",
    type: null,
    latitude: "-9.82511830",
    longitude: "-73.08774900",
  },
  {
    id: 1324,
    name: "Abra",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "ABR",
    type: "province",
    latitude: "42.49708300",
    longitude: "-96.38441000",
  },
  {
    id: 1323,
    name: "Agusan del Norte",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "AGN",
    type: "province",
    latitude: "8.94562590",
    longitude: "125.53192340",
  },
  {
    id: 1326,
    name: "Agusan del Sur",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "AGS",
    type: "province",
    latitude: "8.04638880",
    longitude: "126.06153840",
  },
  {
    id: 1331,
    name: "Aklan",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "AKL",
    type: "province",
    latitude: "11.81661090",
    longitude: "122.09415410",
  },
  {
    id: 1337,
    name: "Albay",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "ALB",
    type: "province",
    latitude: "13.17748270",
    longitude: "123.52800720",
  },
  {
    id: 1336,
    name: "Antique",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "ANT",
    type: "province",
    latitude: "37.03586950",
    longitude: "-95.63616940",
  },
  {
    id: 1334,
    name: "Apayao",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "APA",
    type: "province",
    latitude: "18.01203040",
    longitude: "121.17103890",
  },
  {
    id: 1341,
    name: "Aurora",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "AUR",
    type: "province",
    latitude: "36.97089100",
    longitude: "-93.71797900",
  },
  {
    id: 1316,
    name: "Autonomous Region in Muslim Mindanao",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "14",
    type: "region",
    latitude: "6.95683130",
    longitude: "124.24215970",
  },
  {
    id: 1346,
    name: "Basilan",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "BAS",
    type: "province",
    latitude: "6.42963490",
    longitude: "121.98701650",
  },
  {
    id: 1344,
    name: "Bataan",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "BAN",
    type: "province",
    latitude: "14.64168420",
    longitude: "120.48184460",
  },
  {
    id: 1352,
    name: "Batanes",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "BTN",
    type: "province",
    latitude: "20.44850740",
    longitude: "121.97081290",
  },
  {
    id: 1359,
    name: "Batangas",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "BTG",
    type: "province",
    latitude: "13.75646510",
    longitude: "121.05830760",
  },
  {
    id: 1363,
    name: "Benguet",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "BEN",
    type: "province",
    latitude: "16.55772570",
    longitude: "120.80394740",
  },
  {
    id: 1304,
    name: "Bicol",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "05",
    type: "region",
    latitude: "13.42098850",
    longitude: "123.41367360",
  },
  {
    id: 1274,
    name: "Biliran",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "BIL",
    type: "province",
    latitude: "11.58331520",
    longitude: "124.46418480",
  },
  {
    id: 1272,
    name: "Bohol",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "BOH",
    type: "province",
    latitude: "9.84999110",
    longitude: "124.14354270",
  },
  {
    id: 1270,
    name: "Bukidnon",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "BUK",
    type: "province",
    latitude: "8.05150540",
    longitude: "124.92299460",
  },
  {
    id: 1278,
    name: "Bulacan",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "BUL",
    type: "province",
    latitude: "14.79427350",
    longitude: "120.87990080",
  },
  {
    id: 1279,
    name: "Cagayan",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "CAG",
    type: "province",
    latitude: "18.24896290",
    longitude: "121.87878330",
  },
  {
    id: 1342,
    name: "Cagayan Valley",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "02",
    type: "region",
    latitude: "16.97537580",
    longitude: "121.81070790",
  },
  {
    id: 1294,
    name: "Calabarzon",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "40",
    type: "region",
    latitude: "14.10078030",
    longitude: "121.07937050",
  },
  {
    id: 1283,
    name: "Camarines Norte",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "CAN",
    type: "province",
    latitude: "14.13902650",
    longitude: "122.76330360",
  },
  {
    id: 1287,
    name: "Camarines Sur",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "CAS",
    type: "province",
    latitude: "13.52501970",
    longitude: "123.34861470",
  },
  {
    id: 1285,
    name: "Camiguin",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "CAM",
    type: "province",
    latitude: "9.17321640",
    longitude: "124.72987650",
  },
  {
    id: 1292,
    name: "Capiz",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "CAP",
    type: "province",
    latitude: "11.55288160",
    longitude: "122.74072300",
  },
  {
    id: 1314,
    name: "Caraga",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "13",
    type: "region",
    latitude: "8.80145620",
    longitude: "125.74068820",
  },
  {
    id: 1301,
    name: "Catanduanes",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "CAT",
    type: "province",
    latitude: "13.70886840",
    longitude: "124.24215970",
  },
  {
    id: 1307,
    name: "Cavite",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "CAV",
    type: "province",
    latitude: "14.47912970",
    longitude: "120.89696340",
  },
  {
    id: 1306,
    name: "Cebu",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "CEB",
    type: "province",
    latitude: "10.31569920",
    longitude: "123.88543660",
  },
  {
    id: 1345,
    name: "Central Luzon",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "03",
    type: "region",
    latitude: "15.48277220",
    longitude: "120.71200230",
  },
  {
    id: 1308,
    name: "Central Visayas",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "07",
    type: "region",
    latitude: "9.81687500",
    longitude: "124.06414190",
  },
  {
    id: 1311,
    name: "Compostela Valley",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "COM",
    type: "province",
    latitude: "7.51251500",
    longitude: "126.17626150",
  },
  {
    id: 1335,
    name: "Cordillera Administrative",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "15",
    type: "region",
    latitude: "17.35125420",
    longitude: "121.17188510",
  },
  {
    id: 1320,
    name: "Cotabato",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "NCO",
    type: "province",
    latitude: "7.20466680",
    longitude: "124.23104390",
  },
  {
    id: 1340,
    name: "Davao",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "11",
    type: "region",
    latitude: "7.30416220",
    longitude: "126.08934060",
  },
  {
    id: 1319,
    name: "Davao del Norte",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "DAV",
    type: "province",
    latitude: "7.56176990",
    longitude: "125.65328480",
  },
  {
    id: 1318,
    name: "Davao del Sur",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "DAS",
    type: "province",
    latitude: "6.76626870",
    longitude: "125.32842690",
  },
  {
    id: 1309,
    name: "Davao Occidental",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "DVO",
    type: "province",
    latitude: "6.09413960",
    longitude: "125.60954740",
  },
  {
    id: 1289,
    name: "Davao Oriental",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "DAO",
    type: "province",
    latitude: "7.31715850",
    longitude: "126.54198870",
  },
  {
    id: 1291,
    name: "Dinagat Islands",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "DIN",
    type: "province",
    latitude: "10.12818160",
    longitude: "125.60954740",
  },
  {
    id: 1290,
    name: "Eastern Samar",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "EAS",
    type: "province",
    latitude: "11.50007310",
    longitude: "125.49999080",
  },
  {
    id: 1322,
    name: "Eastern Visayas",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "08",
    type: "region",
    latitude: "12.24455330",
    longitude: "125.03881640",
  },
  {
    id: 1303,
    name: "Guimaras",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "GUI",
    type: "province",
    latitude: "10.59286610",
    longitude: "122.63250810",
  },
  {
    id: 1300,
    name: "Ifugao",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "IFU",
    type: "province",
    latitude: "16.83307920",
    longitude: "121.17103890",
  },
  {
    id: 1355,
    name: "Ilocos",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "01",
    type: "region",
    latitude: "16.08321440",
    longitude: "120.61998950",
  },
  {
    id: 1298,
    name: "Ilocos Norte",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "ILN",
    type: "province",
    latitude: "18.16472810",
    longitude: "120.71155920",
  },
  {
    id: 1321,
    name: "Ilocos Sur",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "ILS",
    type: "province",
    latitude: "17.22786640",
    longitude: "120.57395790",
  },
  {
    id: 1315,
    name: "Iloilo",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "ILI",
    type: "province",
    latitude: "10.72015010",
    longitude: "122.56210630",
  },
  {
    id: 1313,
    name: "Isabela",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "ISA",
    type: "province",
    latitude: "18.50077590",
    longitude: "-67.02434620",
  },
  {
    id: 1312,
    name: "Kalinga",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "KAL",
    type: "province",
    latitude: "17.47404220",
    longitude: "121.35416310",
  },
  {
    id: 1317,
    name: "La Union",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "LUN",
    type: "province",
    latitude: "38.87668780",
    longitude: "-77.12809120",
  },
  {
    id: 1328,
    name: "Laguna",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "LAG",
    type: "province",
    latitude: "33.54271890",
    longitude: "-117.78535680",
  },
  {
    id: 1327,
    name: "Lanao del Norte",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "LAN",
    type: "province",
    latitude: "7.87218110",
    longitude: "123.88577470",
  },
  {
    id: 1333,
    name: "Lanao del Sur",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "LAS",
    type: "province",
    latitude: "7.82317600",
    longitude: "124.41982430",
  },
  {
    id: 1332,
    name: "Leyte",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "LEY",
    type: "province",
    latitude: "10.86245360",
    longitude: "124.88111950",
  },
  {
    id: 1330,
    name: "Maguindanao",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "MAG",
    type: "province",
    latitude: "6.94225810",
    longitude: "124.41982430",
  },
  {
    id: 1329,
    name: "Marinduque",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "MAD",
    type: "province",
    latitude: "13.47671710",
    longitude: "121.90321920",
  },
  {
    id: 1338,
    name: "Masbate",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "MAS",
    type: "province",
    latitude: "12.35743460",
    longitude: "123.55040760",
  },
  {
    id: 1347,
    name: "Metro Manila",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "NCR",
    type: "province",
    latitude: "14.60905370",
    longitude: "121.02225650",
  },
  {
    id: 1299,
    name: "Mimaropa",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "41",
    type: "region",
    latitude: "9.84320650",
    longitude: "118.73647830",
  },
  {
    id: 1343,
    name: "Misamis Occidental",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "MSC",
    type: "province",
    latitude: "8.33749030",
    longitude: "123.70706190",
  },
  {
    id: 1348,
    name: "Misamis Oriental",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "MSR",
    type: "province",
    latitude: "8.50455580",
    longitude: "124.62195920",
  },
  {
    id: 1353,
    name: "Mountain Province",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "MOU",
    type: "province",
    latitude: "40.70754370",
    longitude: "-73.95010330",
  },
  {
    id: 1351,
    name: "Negros Occidental",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "NEC",
    type: "province",
    latitude: "10.29256090",
    longitude: "123.02465180",
  },
  {
    id: 1350,
    name: "Negros Oriental",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "NER",
    type: "province",
    latitude: "9.62820830",
    longitude: "122.98883190",
  },
  {
    id: 1339,
    name: "Northern Mindanao",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "10",
    type: "region",
    latitude: "8.02016350",
    longitude: "124.68565090",
  },
  {
    id: 1349,
    name: "Northern Samar",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "NSA",
    type: "province",
    latitude: "12.36131990",
    longitude: "124.77407930",
  },
  {
    id: 1360,
    name: "Nueva Ecija",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "NUE",
    type: "province",
    latitude: "15.57837500",
    longitude: "121.11126150",
  },
  {
    id: 1358,
    name: "Nueva Vizcaya",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "NUV",
    type: "province",
    latitude: "16.33011070",
    longitude: "121.17103890",
  },
  {
    id: 1356,
    name: "Occidental Mindoro",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "MDC",
    type: "province",
    latitude: "13.10241110",
    longitude: "120.76512840",
  },
  {
    id: 1354,
    name: "Oriental Mindoro",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "MDR",
    type: "province",
    latitude: "13.05645980",
    longitude: "121.40694170",
  },
  {
    id: 1361,
    name: "Palawan",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "PLW",
    type: "province",
    latitude: "9.83494930",
    longitude: "118.73836150",
  },
  {
    id: 1365,
    name: "Pampanga",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "PAM",
    type: "province",
    latitude: "15.07940900",
    longitude: "120.61998950",
  },
  {
    id: 1364,
    name: "Pangasinan",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "PAN",
    type: "province",
    latitude: "15.89490550",
    longitude: "120.28631830",
  },
  {
    id: 1275,
    name: "Quezon",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "QUE",
    type: "province",
    latitude: "14.03139060",
    longitude: "122.11309090",
  },
  {
    id: 1273,
    name: "Quirino",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "QUI",
    type: "province",
    latitude: "16.27004240",
    longitude: "121.53700030",
  },
  {
    id: 1271,
    name: "Rizal",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "RIZ",
    type: "province",
    latitude: "14.60374460",
    longitude: "121.30840880",
  },
  {
    id: 1269,
    name: "Romblon",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "ROM",
    type: "province",
    latitude: "12.57780160",
    longitude: "122.26914600",
  },
  {
    id: 1277,
    name: "Sarangani",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "SAR",
    type: "province",
    latitude: "5.92671750",
    longitude: "124.99475100",
  },
  {
    id: 1276,
    name: "Siquijor",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "SIG",
    type: "province",
    latitude: "9.19987790",
    longitude: "123.59519250",
  },
  {
    id: 1310,
    name: "Soccsksargen",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "12",
    type: "region",
    latitude: "6.27069180",
    longitude: "124.68565090",
  },
  {
    id: 1281,
    name: "Sorsogon",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "SOR",
    type: "province",
    latitude: "12.99270950",
    longitude: "124.01474640",
  },
  {
    id: 1280,
    name: "South Cotabato",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "SCO",
    type: "province",
    latitude: "6.33575650",
    longitude: "124.77407930",
  },
  {
    id: 1284,
    name: "Southern Leyte",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "SLE",
    type: "province",
    latitude: "10.33462060",
    longitude: "125.17087410",
  },
  {
    id: 1282,
    name: "Sultan Kudarat",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "SUK",
    type: "province",
    latitude: "6.50694010",
    longitude: "124.41982430",
  },
  {
    id: 1288,
    name: "Sulu",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "SLU",
    type: "province",
    latitude: "5.97490110",
    longitude: "121.03351000",
  },
  {
    id: 1286,
    name: "Surigao del Norte",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "SUN",
    type: "province",
    latitude: "9.51482800",
    longitude: "125.69699840",
  },
  {
    id: 1296,
    name: "Surigao del Sur",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "SUR",
    type: "province",
    latitude: "8.54049060",
    longitude: "126.11447580",
  },
  {
    id: 1295,
    name: "Tarlac",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "TAR",
    type: "province",
    latitude: "15.47547860",
    longitude: "120.59634920",
  },
  {
    id: 1293,
    name: "Tawi-Tawi",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "TAW",
    type: "province",
    latitude: "5.13381100",
    longitude: "119.95092600",
  },
  {
    id: 5115,
    name: "Western Samar",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "WSA",
    type: "province",
    latitude: "12.00002060",
    longitude: "124.99124520",
  },
  {
    id: 1305,
    name: "Western Visayas",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "06",
    type: "region",
    latitude: "11.00498360",
    longitude: "122.53727410",
  },
  {
    id: 1297,
    name: "Zambales",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "ZMB",
    type: "province",
    latitude: "15.50817660",
    longitude: "119.96978080",
  },
  {
    id: 1302,
    name: "Zamboanga del Norte",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "ZAN",
    type: "province",
    latitude: "8.38862820",
    longitude: "123.16888830",
  },
  {
    id: 1357,
    name: "Zamboanga del Sur",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "ZAS",
    type: "province",
    latitude: "7.83830540",
    longitude: "123.29666570",
  },
  {
    id: 1325,
    name: "Zamboanga Peninsula",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "09",
    type: "region",
    latitude: "8.15407700",
    longitude: "123.25879300",
  },
  {
    id: 1362,
    name: "Zamboanga Sibugay",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    state_code: "ZSI",
    type: "province",
    latitude: "7.52252470",
    longitude: "122.31075170",
  },
  {
    id: 1634,
    name: "Greater Poland Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "WP",
    type: null,
    latitude: "52.27998600",
    longitude: "17.35229390",
  },
  {
    id: 1625,
    name: "Kuyavian-Pomeranian Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "KP",
    type: null,
    latitude: "53.16483630",
    longitude: "18.48342240",
  },
  {
    id: 1635,
    name: "Lesser Poland Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "MA",
    type: null,
    latitude: "49.72253060",
    longitude: "20.25033580",
  },
  {
    id: 1629,
    name: "Lower Silesian Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "DS",
    type: null,
    latitude: "51.13398610",
    longitude: "16.88419610",
  },
  {
    id: 1638,
    name: "Lublin Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "LU",
    type: null,
    latitude: "51.24935190",
    longitude: "23.10113920",
  },
  {
    id: 1631,
    name: "Lubusz Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "LB",
    type: null,
    latitude: "52.22746120",
    longitude: "15.25591030",
  },
  {
    id: 1636,
    name: "Łódź Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "LD",
    type: null,
    latitude: "51.46347710",
    longitude: "19.17269740",
  },
  {
    id: 1637,
    name: "Masovian Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "MZ",
    type: null,
    latitude: "51.89271820",
    longitude: "21.00216790",
  },
  {
    id: 1622,
    name: "Opole Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "OP",
    type: null,
    latitude: "50.80037610",
    longitude: "17.93798900",
  },
  {
    id: 1626,
    name: "Podkarpackie Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "PK",
    type: null,
    latitude: "50.05747490",
    longitude: "22.08956910",
  },
  {
    id: 1632,
    name: "Podlaskie Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "PD",
    type: null,
    latitude: "53.06971590",
    longitude: "22.96746390",
  },
  {
    id: 1624,
    name: "Pomeranian Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "PM",
    type: null,
    latitude: "54.29442520",
    longitude: "18.15311640",
  },
  {
    id: 1623,
    name: "Silesian Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "SL",
    type: null,
    latitude: "50.57165950",
    longitude: "19.32197680",
  },
  {
    id: 1630,
    name: "Świętokrzyskie Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "SK",
    type: null,
    latitude: "50.62610410",
    longitude: "20.94062790",
  },
  {
    id: 1628,
    name: "Warmian-Masurian Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "WN",
    type: null,
    latitude: "53.86711170",
    longitude: "20.70278610",
  },
  {
    id: 1633,
    name: "West Pomeranian Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    state_code: "ZP",
    type: null,
    latitude: "53.46578910",
    longitude: "15.18225810",
  },
  {
    id: 2233,
    name: "Açores",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "20",
    type: null,
    latitude: "37.74124880",
    longitude: "-25.67559440",
  },
  {
    id: 2235,
    name: "Aveiro",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "01",
    type: null,
    latitude: "40.72090230",
    longitude: "-8.57210160",
  },
  {
    id: 2230,
    name: "Beja",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "02",
    type: null,
    latitude: "37.96877860",
    longitude: "-7.87216000",
  },
  {
    id: 2244,
    name: "Braga",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "03",
    type: null,
    latitude: "41.55038800",
    longitude: "-8.42613010",
  },
  {
    id: 2229,
    name: "Bragança",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "04",
    type: null,
    latitude: "41.80616520",
    longitude: "-6.75674270",
  },
  {
    id: 2241,
    name: "Castelo Branco",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "05",
    type: null,
    latitude: "39.86313230",
    longitude: "-7.48141630",
  },
  {
    id: 2246,
    name: "Coimbra",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "06",
    type: null,
    latitude: "40.20579940",
    longitude: "-8.41369000",
  },
  {
    id: 2236,
    name: "Évora",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "07",
    type: null,
    latitude: "38.57444680",
    longitude: "-7.90765530",
  },
  {
    id: 2239,
    name: "Faro",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "08",
    type: null,
    latitude: "37.01935480",
    longitude: "-7.93043970",
  },
  {
    id: 4859,
    name: "Guarda",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "09",
    type: null,
    latitude: "40.53859720",
    longitude: "-7.26757720",
  },
  {
    id: 2240,
    name: "Leiria",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "10",
    type: null,
    latitude: "39.77095320",
    longitude: "-8.79218360",
  },
  {
    id: 2228,
    name: "Lisbon",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "11",
    type: null,
    latitude: "38.72232630",
    longitude: "-9.13927140",
  },
  {
    id: 2231,
    name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "30",
    type: null,
    latitude: "32.76070740",
    longitude: "-16.95947230",
  },
  {
    id: 2232,
    name: "Portalegre",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "12",
    type: null,
    latitude: "39.29670860",
    longitude: "-7.42847550",
  },
  {
    id: 2243,
    name: "Porto",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "13",
    type: null,
    latitude: "41.14766290",
    longitude: "-8.60789730",
  },
  {
    id: 2238,
    name: "Santarém",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "14",
    type: null,
    latitude: "39.23666870",
    longitude: "-8.68599440",
  },
  {
    id: 2242,
    name: "Setúbal",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "15",
    type: null,
    latitude: "38.52409330",
    longitude: "-8.89258760",
  },
  {
    id: 2245,
    name: "Viana do Castelo",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "16",
    type: null,
    latitude: "41.69180460",
    longitude: "-8.83445100",
  },
  {
    id: 2234,
    name: "Vila Real",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "17",
    type: null,
    latitude: "41.30035270",
    longitude: "-7.74572740",
  },
  {
    id: 2237,
    name: "Viseu",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    state_code: "18",
    type: null,
    latitude: "40.65884240",
    longitude: "-7.91475600",
  },
  {
    id: 5134,
    name: "Adjuntas",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "001",
    type: "municipalities",
    latitude: "18.16348480",
    longitude: "-66.72315800",
  },
  {
    id: 5135,
    name: "Aguada",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "003",
    type: "municipalities",
    latitude: "18.38015790",
    longitude: "-67.18870400",
  },
  {
    id: 5136,
    name: "Aguadilla",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "005",
    type: "municipalities",
    latitude: "18.42744540",
    longitude: "-67.15406980",
  },
  {
    id: 5137,
    name: "Aguas Buenas",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "007",
    type: "municipalities",
    latitude: "18.25689890",
    longitude: "-66.10294420",
  },
  {
    id: 5138,
    name: "Aibonito",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "009",
    type: "municipalities",
    latitude: "18.13995940",
    longitude: "-66.26600160",
  },
  {
    id: 5139,
    name: "Añasco",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "011",
    type: "municipalities",
    latitude: "18.28544760",
    longitude: "-67.14029350",
  },
  {
    id: 5140,
    name: "Arecibo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "013",
    type: "municipalities",
    latitude: "18.47051370",
    longitude: "-66.72184720",
  },
  {
    id: 5081,
    name: "Arecibo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "AR",
    type: "region",
    latitude: "18.47055556",
    longitude: "-66.72083333",
  },
  {
    id: 5141,
    name: "Arroyo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "015",
    type: "municipalities",
    latitude: "17.99642200",
    longitude: "-66.09248790",
  },
  {
    id: 5142,
    name: "Barceloneta",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "017",
    type: "municipalities",
    latitude: "41.38010610",
    longitude: "2.18969570",
  },
  {
    id: 5143,
    name: "Barranquitas",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "019",
    type: "municipalities",
    latitude: "18.18662420",
    longitude: "-66.30628020",
  },
  {
    id: 5076,
    name: "Bayamon",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "BY",
    type: "region",
    latitude: "18.17777778",
    longitude: "-66.11333333",
  },
  {
    id: 5144,
    name: "Bayamón",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "021",
    type: "municipalities",
    latitude: "18.38939600",
    longitude: "-66.16532240",
  },
  {
    id: 5145,
    name: "Cabo Rojo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "023",
    type: "municipalities",
    latitude: "18.08662650",
    longitude: "-67.14573470",
  },
  {
    id: 5079,
    name: "Caguas",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "CG",
    type: "region",
    latitude: "18.23333333",
    longitude: "-66.03333333",
  },
  {
    id: 5146,
    name: "Caguas",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "025",
    type: "municipalities",
    latitude: "18.23879950",
    longitude: "-66.03524900",
  },
  {
    id: 5147,
    name: "Camuy",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "027",
    type: "municipalities",
    latitude: "18.48383300",
    longitude: "-66.84489940",
  },
  {
    id: 5148,
    name: "Canóvanas",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "029",
    type: "municipalities",
    latitude: "18.37487480",
    longitude: "-65.89975330",
  },
  {
    id: 5077,
    name: "Carolina",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "CL",
    type: "region",
    latitude: "18.38888889",
    longitude: "-65.96666667",
  },
  {
    id: 5149,
    name: "Carolina",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "031",
    type: "municipalities",
    latitude: "18.36808770",
    longitude: "-66.04247340",
  },
  {
    id: 5150,
    name: "Cataño",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "033",
    type: "municipalities",
    latitude: "18.44653550",
    longitude: "-66.13557750",
  },
  {
    id: 5151,
    name: "Cayey",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "035",
    type: "municipalities",
    latitude: "18.11190510",
    longitude: "-66.16600000",
  },
  {
    id: 5152,
    name: "Ceiba",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "037",
    type: "municipalities",
    latitude: "18.24751770",
    longitude: "-65.90849530",
  },
  {
    id: 5153,
    name: "Ciales",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "039",
    type: "municipalities",
    latitude: "18.33606220",
    longitude: "-66.46878230",
  },
  {
    id: 5154,
    name: "Cidra",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "041",
    type: "municipalities",
    latitude: "18.17579140",
    longitude: "-66.16127790",
  },
  {
    id: 5155,
    name: "Coamo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "043",
    type: "municipalities",
    latitude: "18.07996160",
    longitude: "-66.35794730",
  },
  {
    id: 5156,
    name: "Comerío",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "045",
    type: "municipalities",
    latitude: "18.21920010",
    longitude: "-66.22560220",
  },
  {
    id: 5157,
    name: "Corozal",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "047",
    type: "municipalities",
    latitude: "18.40308020",
    longitude: "-88.39675360",
  },
  {
    id: 5158,
    name: "Culebra",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "049",
    type: "municipalities",
    latitude: "18.31039400",
    longitude: "-65.30307050",
  },
  {
    id: 5159,
    name: "Dorado",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "051",
    type: "municipalities",
    latitude: "43.14805560",
    longitude: "-77.57722220",
  },
  {
    id: 5160,
    name: "Fajardo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "053",
    type: "municipalities",
    latitude: "18.32521480",
    longitude: "-65.65393560",
  },
  {
    id: 5161,
    name: "Florida",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "054",
    type: "municipalities",
    latitude: "27.66482740",
    longitude: "-81.51575350",
  },
  {
    id: 5162,
    name: "Guánica",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "055",
    type: "municipalities",
    latitude: "17.97251450",
    longitude: "-66.90862640",
  },
  {
    id: 5163,
    name: "Guayama",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "057",
    type: "municipalities",
    latitude: "17.98413280",
    longitude: "-66.11377670",
  },
  {
    id: 5164,
    name: "Guayanilla",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "059",
    type: "municipalities",
    latitude: "18.01913140",
    longitude: "-66.79184200",
  },
  {
    id: 5080,
    name: "Guaynabo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "GN",
    type: "region",
    latitude: "18.36666667",
    longitude: "-66.10000000",
  },
  {
    id: 5165,
    name: "Guaynabo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "061",
    type: "municipalities",
    latitude: "18.36129540",
    longitude: "-66.11029570",
  },
  {
    id: 5166,
    name: "Gurabo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "063",
    type: "municipalities",
    latitude: "18.25439870",
    longitude: "-65.97294210",
  },
  {
    id: 5167,
    name: "Hatillo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "065",
    type: "municipalities",
    latitude: "18.42846420",
    longitude: "-66.78753210",
  },
  {
    id: 5168,
    name: "Hormigueros",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "067",
    type: "municipalities",
    latitude: "18.13346380",
    longitude: "-67.11281230",
  },
  {
    id: 5169,
    name: "Humacao",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "069",
    type: "municipalities",
    latitude: "18.15157360",
    longitude: "-65.82485290",
  },
  {
    id: 5170,
    name: "Isabela",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "071",
    type: "municipalities",
    latitude: "16.97537580",
    longitude: "121.81070790",
  },
  {
    id: 5171,
    name: "Jayuya",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "073",
    type: "municipalities",
    latitude: "18.21856740",
    longitude: "-66.59156170",
  },
  {
    id: 5172,
    name: "Juana Díaz",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "075",
    type: "municipalities",
    latitude: "18.05343720",
    longitude: "-66.50750790",
  },
  {
    id: 5173,
    name: "Juncos",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "077",
    type: "municipalities",
    latitude: "18.22745580",
    longitude: "-65.92099700",
  },
  {
    id: 5174,
    name: "Lajas",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "079",
    type: "municipalities",
    latitude: "18.04996200",
    longitude: "-67.05934490",
  },
  {
    id: 5175,
    name: "Lares",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "081",
    type: "municipalities",
    latitude: "34.02508020",
    longitude: "-118.45945930",
  },
  {
    id: 5176,
    name: "Las Marías",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "083",
    type: "municipalities",
    latitude: "35.83822380",
    longitude: "-78.61035660",
  },
  {
    id: 5177,
    name: "Las Piedras",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "085",
    type: "municipalities",
    latitude: "18.18557530",
    longitude: "-65.87362450",
  },
  {
    id: 5178,
    name: "Loíza",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "087",
    type: "municipalities",
    latitude: "18.43299040",
    longitude: "-65.87836000",
  },
  {
    id: 5179,
    name: "Luquillo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "089",
    type: "municipalities",
    latitude: "18.37245070",
    longitude: "-65.71655110",
  },
  {
    id: 5180,
    name: "Manatí",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "091",
    type: "municipalities",
    latitude: "18.41812150",
    longitude: "-66.52627830",
  },
  {
    id: 5181,
    name: "Maricao",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "093",
    type: "municipalities",
    latitude: "18.18079020",
    longitude: "-66.97990010",
  },
  {
    id: 5182,
    name: "Maunabo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "095",
    type: "municipalities",
    latitude: "18.00718850",
    longitude: "-65.89932890",
  },
  {
    id: 5083,
    name: "Mayagüez",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "MG",
    type: "region",
    latitude: "18.20111111",
    longitude: "-67.13972222",
  },
  {
    id: 5183,
    name: "Mayagüez",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "097",
    type: "municipalities",
    latitude: "18.20134520",
    longitude: "-67.14515490",
  },
  {
    id: 5184,
    name: "Moca",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "099",
    type: "municipalities",
    latitude: "18.39679290",
    longitude: "-67.14790350",
  },
  {
    id: 5185,
    name: "Morovis",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "101",
    type: "municipalities",
    latitude: "18.32578500",
    longitude: "-66.40655920",
  },
  {
    id: 5186,
    name: "Naguabo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "103",
    type: "municipalities",
    latitude: "18.21162470",
    longitude: "-65.73488410",
  },
  {
    id: 5187,
    name: "Naranjito",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "105",
    type: "municipalities",
    latitude: "18.30078610",
    longitude: "-66.24489040",
  },
  {
    id: 5188,
    name: "Orocovis",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "107",
    type: "municipalities",
    latitude: "18.22692240",
    longitude: "-66.39116860",
  },
  {
    id: 5189,
    name: "Patillas",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "109",
    type: "municipalities",
    latitude: "18.00373810",
    longitude: "-66.01340590",
  },
  {
    id: 5190,
    name: "Peñuelas",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "111",
    type: "municipalities",
    latitude: "18.06335770",
    longitude: "-66.72738960",
  },
  {
    id: 5078,
    name: "Ponce",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "PO",
    type: "region",
    latitude: "18.00000000",
    longitude: "-66.61666667",
  },
  {
    id: 5191,
    name: "Ponce",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "113",
    type: "municipalities",
    latitude: "18.01107680",
    longitude: "-66.61406160",
  },
  {
    id: 5192,
    name: "Quebradillas",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "115",
    type: "municipalities",
    latitude: "18.47383300",
    longitude: "-66.93851200",
  },
  {
    id: 5193,
    name: "Rincón",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "117",
    type: "municipalities",
    latitude: "18.34015140",
    longitude: "-67.24994590",
  },
  {
    id: 5194,
    name: "Río Grande",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "119",
    type: "municipalities",
    latitude: "28.81063826",
    longitude: "-101.83538780",
  },
  {
    id: 5195,
    name: "Sabana Grande",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "121",
    type: "municipalities",
    latitude: "18.07773920",
    longitude: "-66.96045490",
  },
  {
    id: 5196,
    name: "Salinas",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "123",
    type: "municipalities",
    latitude: "36.67773720",
    longitude: "-121.65550130",
  },
  {
    id: 5197,
    name: "San Germán",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "125",
    type: "municipalities",
    latitude: "18.08070820",
    longitude: "-67.04110960",
  },
  {
    id: 5198,
    name: "San Juan",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "127",
    type: "municipalities",
    latitude: "18.46320300",
    longitude: "-66.11475710",
  },
  {
    id: 5075,
    name: "San Juan",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "SJ",
    type: "region",
    latitude: "18.45000000",
    longitude: "-66.06666667",
  },
  {
    id: 5199,
    name: "San Lorenzo",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "129",
    type: "municipalities",
    latitude: "18.18869120",
    longitude: "-65.97658620",
  },
  {
    id: 5200,
    name: "San Sebastián",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "131",
    type: "municipalities",
    latitude: "43.31833400",
    longitude: "-1.98123130",
  },
  {
    id: 5201,
    name: "Santa Isabel",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "133",
    type: "municipalities",
    latitude: "17.96607750",
    longitude: "-66.40489200",
  },
  {
    id: 5202,
    name: "Toa Alta",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "135",
    type: "municipalities",
    latitude: "18.38828230",
    longitude: "-66.24822370",
  },
  {
    id: 5082,
    name: "Toa Baja",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "TB",
    type: "region",
    latitude: "18.44388900",
    longitude: "-66.25972200",
  },
  {
    id: 5203,
    name: "Toa Baja",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "137",
    type: "municipalities",
    latitude: "18.44447090",
    longitude: "-66.25432930",
  },
  {
    id: 5084,
    name: "Trujillo Alto",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "TA",
    type: "region",
    latitude: "18.36277800",
    longitude: "-66.01750000",
  },
  {
    id: 5204,
    name: "Trujillo Alto",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "139",
    type: "municipalities",
    latitude: "18.35467190",
    longitude: "-66.00738760",
  },
  {
    id: 5205,
    name: "Utuado",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "141",
    type: "municipalities",
    latitude: "18.26550950",
    longitude: "-66.70045190",
  },
  {
    id: 5206,
    name: "Vega Alta",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "143",
    type: "municipalities",
    latitude: "18.41217030",
    longitude: "-66.33128050",
  },
  {
    id: 5207,
    name: "Vega Baja",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "145",
    type: "municipalities",
    latitude: "18.44614590",
    longitude: "-66.40419670",
  },
  {
    id: 5208,
    name: "Vieques",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "147",
    type: "municipalities",
    latitude: "18.12628540",
    longitude: "-65.44009850",
  },
  {
    id: 5209,
    name: "Villalba",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "149",
    type: "municipalities",
    latitude: "18.12175540",
    longitude: "-66.49857870",
  },
  {
    id: 5210,
    name: "Yabucoa",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "151",
    type: "municipalities",
    latitude: "18.05052010",
    longitude: "-65.87932880",
  },
  {
    id: 5211,
    name: "Yauco",
    country_id: 178,
    country_code: "PR",
    country_name: "Puerto Rico",
    state_code: "153",
    type: "municipalities",
    latitude: "18.03496400",
    longitude: "-66.84989830",
  },
  {
    id: 3182,
    name: "Al Daayen",
    country_id: 179,
    country_code: "QA",
    country_name: "Qatar",
    state_code: "ZA",
    type: null,
    latitude: "25.57845590",
    longitude: "51.48213870",
  },
  {
    id: 3183,
    name: "Al Khor",
    country_id: 179,
    country_code: "QA",
    country_name: "Qatar",
    state_code: "KH",
    type: null,
    latitude: "25.68040780",
    longitude: "51.49685020",
  },
  {
    id: 3177,
    name: "Al Rayyan Municipality",
    country_id: 179,
    country_code: "QA",
    country_name: "Qatar",
    state_code: "RA",
    type: null,
    latitude: "25.25225510",
    longitude: "51.43887130",
  },
  {
    id: 3179,
    name: "Al Wakrah",
    country_id: 179,
    country_code: "QA",
    country_name: "Qatar",
    state_code: "WA",
    type: null,
    latitude: "25.16593140",
    longitude: "51.59755240",
  },
  {
    id: 3178,
    name: "Al-Shahaniya",
    country_id: 179,
    country_code: "QA",
    country_name: "Qatar",
    state_code: "SH",
    type: null,
    latitude: "25.41063860",
    longitude: "51.18460250",
  },
  {
    id: 3181,
    name: "Doha",
    country_id: 179,
    country_code: "QA",
    country_name: "Qatar",
    state_code: "DA",
    type: null,
    latitude: "25.28544730",
    longitude: "51.53103980",
  },
  {
    id: 3180,
    name: "Madinat ash Shamal",
    country_id: 179,
    country_code: "QA",
    country_name: "Qatar",
    state_code: "MS",
    type: null,
    latitude: "26.11827430",
    longitude: "51.21572650",
  },
  {
    id: 3184,
    name: "Umm Salal Municipality",
    country_id: 179,
    country_code: "QA",
    country_name: "Qatar",
    state_code: "US",
    type: null,
    latitude: "25.48752420",
    longitude: "51.39656800",
  },
  {
    id: 4724,
    name: "Alba",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "AB",
    type: null,
    latitude: "44.70091530",
    longitude: "8.03569110",
  },
  {
    id: 4739,
    name: "Arad County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "AR",
    type: null,
    latitude: "46.22836510",
    longitude: "21.65978190",
  },
  {
    id: 4722,
    name: "Arges",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "AG",
    type: null,
    latitude: "45.07225270",
    longitude: "24.81427260",
  },
  {
    id: 4744,
    name: "Bacău County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "BC",
    type: null,
    latitude: "46.32581840",
    longitude: "26.66237800",
  },
  {
    id: 4723,
    name: "Bihor County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "BH",
    type: null,
    latitude: "47.01575160",
    longitude: "22.17226600",
  },
  {
    id: 4733,
    name: "Bistrița-Năsăud County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "BN",
    type: null,
    latitude: "47.24861070",
    longitude: "24.53228140",
  },
  {
    id: 4740,
    name: "Botoșani County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "BT",
    type: null,
    latitude: "47.89240420",
    longitude: "26.75917810",
  },
  {
    id: 4736,
    name: "Braila",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "BR",
    type: null,
    latitude: "45.26524630",
    longitude: "27.95947140",
  },
  {
    id: 4759,
    name: "Brașov County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "BV",
    type: null,
    latitude: "45.77818440",
    longitude: "25.22258000",
  },
  {
    id: 4730,
    name: "Bucharest",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "B",
    type: null,
    latitude: "44.42676740",
    longitude: "26.10253840",
  },
  {
    id: 4756,
    name: "Buzău County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "BZ",
    type: null,
    latitude: "45.33509120",
    longitude: "26.71075780",
  },
  {
    id: 4732,
    name: "Călărași County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "CL",
    type: null,
    latitude: "44.36587150",
    longitude: "26.75486070",
  },
  {
    id: 4753,
    name: "Caraș-Severin County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "CS",
    type: null,
    latitude: "45.11396460",
    longitude: "22.07409930",
  },
  {
    id: 4734,
    name: "Cluj County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "CJ",
    type: null,
    latitude: "46.79417970",
    longitude: "23.61214920",
  },
  {
    id: 4737,
    name: "Constanța County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "CT",
    type: null,
    latitude: "44.21298700",
    longitude: "28.25500550",
  },
  {
    id: 4754,
    name: "Covasna County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "CV",
    type: null,
    latitude: "45.94263470",
    longitude: "25.89189840",
  },
  {
    id: 4745,
    name: "Dâmbovița County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "DB",
    type: null,
    latitude: "44.92898930",
    longitude: "25.42538500",
  },
  {
    id: 4742,
    name: "Dolj County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "DJ",
    type: null,
    latitude: "44.16230220",
    longitude: "23.63250540",
  },
  {
    id: 4747,
    name: "Galați County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "GL",
    type: null,
    latitude: "45.78005690",
    longitude: "27.82515760",
  },
  {
    id: 4726,
    name: "Giurgiu County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "GR",
    type: null,
    latitude: "43.90370760",
    longitude: "25.96992650",
  },
  {
    id: 4750,
    name: "Gorj County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "GJ",
    type: null,
    latitude: "44.94855950",
    longitude: "23.24270790",
  },
  {
    id: 4749,
    name: "Harghita County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "HR",
    type: null,
    latitude: "46.49285070",
    longitude: "25.64566960",
  },
  {
    id: 4721,
    name: "Hunedoara County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "HD",
    type: null,
    latitude: "45.79363800",
    longitude: "22.99759930",
  },
  {
    id: 4743,
    name: "Ialomița County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "IL",
    type: null,
    latitude: "44.60313300",
    longitude: "27.37899140",
  },
  {
    id: 4735,
    name: "Iași County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "IS",
    type: null,
    latitude: "47.26796530",
    longitude: "27.21856620",
  },
  {
    id: 4725,
    name: "Ilfov County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "IF",
    type: null,
    latitude: "44.53554800",
    longitude: "26.23248860",
  },
  {
    id: 4760,
    name: "Maramureș County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "MM",
    type: null,
    latitude: "46.55699040",
    longitude: "24.67232150",
  },
  {
    id: 4751,
    name: "Mehedinți County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "MH",
    type: null,
    latitude: "44.55150530",
    longitude: "22.90441570",
  },
  {
    id: 4915,
    name: "Mureș County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "MS",
    type: null,
    latitude: "46.55699040",
    longitude: "24.67232150",
  },
  {
    id: 4731,
    name: "Neamț County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "NT",
    type: null,
    latitude: "46.97586850",
    longitude: "26.38187640",
  },
  {
    id: 4738,
    name: "Olt County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "OT",
    type: null,
    latitude: "44.20079700",
    longitude: "24.50229810",
  },
  {
    id: 4729,
    name: "Prahova County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "PH",
    type: null,
    latitude: "45.08919060",
    longitude: "26.08293130",
  },
  {
    id: 4741,
    name: "Sălaj County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "SJ",
    type: null,
    latitude: "47.20908130",
    longitude: "23.21219010",
  },
  {
    id: 4746,
    name: "Satu Mare County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "SM",
    type: null,
    latitude: "47.76689050",
    longitude: "22.92413770",
  },
  {
    id: 4755,
    name: "Sibiu County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "SB",
    type: null,
    latitude: "45.92691060",
    longitude: "24.22548070",
  },
  {
    id: 4720,
    name: "Suceava County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "SV",
    type: null,
    latitude: "47.55055480",
    longitude: "25.74106200",
  },
  {
    id: 4728,
    name: "Teleorman County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "TR",
    type: null,
    latitude: "44.01604910",
    longitude: "25.29866280",
  },
  {
    id: 4748,
    name: "Timiș County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "TM",
    type: null,
    latitude: "45.81389020",
    longitude: "21.33310550",
  },
  {
    id: 4727,
    name: "Tulcea County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "TL",
    type: null,
    latitude: "45.04505650",
    longitude: "29.03249120",
  },
  {
    id: 4757,
    name: "Vâlcea County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "VL",
    type: null,
    latitude: "45.07980510",
    longitude: "24.08352830",
  },
  {
    id: 4752,
    name: "Vaslui County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "VS",
    type: null,
    latitude: "46.46310590",
    longitude: "27.73980310",
  },
  {
    id: 4758,
    name: "Vrancea County",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    state_code: "VN",
    type: null,
    latitude: "45.81348760",
    longitude: "27.06575310",
  },
  {
    id: 1911,
    name: "Altai Krai",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "ALT",
    type: null,
    latitude: "51.79362980",
    longitude: "82.67585960",
  },
  {
    id: 1876,
    name: "Altai Republic",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "AL",
    type: null,
    latitude: "50.61819240",
    longitude: "86.21993080",
  },
  {
    id: 1858,
    name: "Amur Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "AMU",
    type: null,
    latitude: "54.60350650",
    longitude: "127.48017210",
  },
  {
    id: 1849,
    name: "Arkhangelsk",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "ARK",
    type: null,
    latitude: "64.54585490",
    longitude: "40.55057690",
  },
  {
    id: 1866,
    name: "Astrakhan Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "AST",
    type: null,
    latitude: "46.13211660",
    longitude: "48.06101150",
  },
  {
    id: 1903,
    name: "Belgorod Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "BEL",
    type: null,
    latitude: "50.71069260",
    longitude: "37.75333770",
  },
  {
    id: 1867,
    name: "Bryansk Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "BRY",
    type: null,
    latitude: "53.04085990",
    longitude: "33.26909000",
  },
  {
    id: 1893,
    name: "Chechen Republic",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "CE",
    type: null,
    latitude: "43.40233010",
    longitude: "45.71874680",
  },
  {
    id: 1845,
    name: "Chelyabinsk Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "CHE",
    type: null,
    latitude: "54.43194220",
    longitude: "60.87889630",
  },
  {
    id: 1859,
    name: "Chukotka Autonomous Okrug",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "CHU",
    type: null,
    latitude: "65.62983550",
    longitude: "171.69521590",
  },
  {
    id: 1914,
    name: "Chuvash Republic",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "CU",
    type: null,
    latitude: "55.55959920",
    longitude: "46.92835350",
  },
  {
    id: 1880,
    name: "Irkutsk",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "IRK",
    type: null,
    latitude: "52.28548340",
    longitude: "104.28902220",
  },
  {
    id: 1864,
    name: "Ivanovo Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "IVA",
    type: null,
    latitude: "57.10568540",
    longitude: "41.48300840",
  },
  {
    id: 1835,
    name: "Jewish Autonomous Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "YEV",
    type: null,
    latitude: "48.48081470",
    longitude: "131.76573670",
  },
  {
    id: 1892,
    name: "Kabardino-Balkar Republic",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KB",
    type: null,
    latitude: "43.39324690",
    longitude: "43.56284980",
  },
  {
    id: 1902,
    name: "Kaliningrad",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KGD",
    type: null,
    latitude: "54.71042640",
    longitude: "20.45221440",
  },
  {
    id: 1844,
    name: "Kaluga Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KLU",
    type: null,
    latitude: "54.38726660",
    longitude: "35.18890940",
  },
  {
    id: 1865,
    name: "Kamchatka Krai",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KAM",
    type: null,
    latitude: "61.43439810",
    longitude: "166.78841310",
  },
  {
    id: 1869,
    name: "Karachay-Cherkess Republic",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KC",
    type: null,
    latitude: "43.88451430",
    longitude: "41.73039390",
  },
  {
    id: 1897,
    name: "Kemerovo Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KEM",
    type: null,
    latitude: "54.75746480",
    longitude: "87.40552880",
  },
  {
    id: 1873,
    name: "Khabarovsk Krai",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KHA",
    type: null,
    latitude: "50.58884310",
    longitude: "135.00000000",
  },
  {
    id: 1838,
    name: "Khanty-Mansi Autonomous Okrug",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KHM",
    type: null,
    latitude: "62.22870620",
    longitude: "70.64100570",
  },
  {
    id: 1890,
    name: "Kirov Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KIR",
    type: null,
    latitude: "58.41985290",
    longitude: "50.20972480",
  },
  {
    id: 1899,
    name: "Komi Republic",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KO",
    type: null,
    latitude: "63.86305390",
    longitude: "54.83126900",
  },
  {
    id: 1910,
    name: "Kostroma Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KOS",
    type: null,
    latitude: "58.55010690",
    longitude: "43.95411020",
  },
  {
    id: 1891,
    name: "Krasnodar Krai",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KDA",
    type: null,
    latitude: "45.64152890",
    longitude: "39.70559770",
  },
  {
    id: 1840,
    name: "Krasnoyarsk Krai",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KYA",
    type: null,
    latitude: "64.24797580",
    longitude: "95.11041760",
  },
  {
    id: 1915,
    name: "Kurgan Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KGN",
    type: null,
    latitude: "55.44815480",
    longitude: "65.11809750",
  },
  {
    id: 1855,
    name: "Kursk Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KRS",
    type: null,
    latitude: "51.76340260",
    longitude: "35.38118120",
  },
  {
    id: 1896,
    name: "Leningrad Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "LEN",
    type: null,
    latitude: "60.07932080",
    longitude: "31.89266450",
  },
  {
    id: 1889,
    name: "Lipetsk Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "LIP",
    type: null,
    latitude: "52.52647020",
    longitude: "39.20322690",
  },
  {
    id: 1839,
    name: "Magadan Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "MAG",
    type: null,
    latitude: "62.66434170",
    longitude: "153.91499100",
  },
  {
    id: 1870,
    name: "Mari El Republic",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "ME",
    type: null,
    latitude: "56.43845700",
    longitude: "47.96077580",
  },
  {
    id: 1901,
    name: "Moscow",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "MOW",
    type: null,
    latitude: "55.75582600",
    longitude: "37.61729990",
  },
  {
    id: 1882,
    name: "Moscow Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "MOS",
    type: null,
    latitude: "55.34039600",
    longitude: "38.29176510",
  },
  {
    id: 1843,
    name: "Murmansk Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "MUR",
    type: null,
    latitude: "67.84426740",
    longitude: "35.08841020",
  },
  {
    id: 1836,
    name: "Nenets Autonomous Okrug",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "NEN",
    type: null,
    latitude: "67.60783370",
    longitude: "57.63383310",
  },
  {
    id: 1857,
    name: "Nizhny Novgorod Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "NIZ",
    type: null,
    latitude: "55.79951590",
    longitude: "44.02967690",
  },
  {
    id: 1834,
    name: "Novgorod Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "NGR",
    type: null,
    latitude: "58.24275520",
    longitude: "32.56651900",
  },
  {
    id: 1888,
    name: "Novosibirsk",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "NVS",
    type: null,
    latitude: "54.98326930",
    longitude: "82.89638310",
  },
  {
    id: 1846,
    name: "Omsk Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "OMS",
    type: null,
    latitude: "55.05546690",
    longitude: "73.31673420",
  },
  {
    id: 1886,
    name: "Orenburg Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "ORE",
    type: null,
    latitude: "51.76340260",
    longitude: "54.61881880",
  },
  {
    id: 1908,
    name: "Oryol Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "ORL",
    type: null,
    latitude: "52.78564140",
    longitude: "36.92423440",
  },
  {
    id: 1909,
    name: "Penza Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "PNZ",
    type: null,
    latitude: "53.14121050",
    longitude: "44.09400480",
  },
  {
    id: 1871,
    name: "Perm Krai",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "PER",
    type: null,
    latitude: "58.82319290",
    longitude: "56.58724810",
  },
  {
    id: 1833,
    name: "Primorsky Krai",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "PRI",
    type: null,
    latitude: "45.05256410",
    longitude: "135.00000000",
  },
  {
    id: 1863,
    name: "Pskov Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "PSK",
    type: null,
    latitude: "56.77085990",
    longitude: "29.09400900",
  },
  {
    id: 1852,
    name: "Republic of Adygea",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "AD",
    type: null,
    latitude: "44.82291550",
    longitude: "40.17544630",
  },
  {
    id: 1854,
    name: "Republic of Bashkortostan",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "BA",
    type: null,
    latitude: "54.23121720",
    longitude: "56.16452570",
  },
  {
    id: 1842,
    name: "Republic of Buryatia",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "BU",
    type: null,
    latitude: "54.83311460",
    longitude: "112.40605300",
  },
  {
    id: 1850,
    name: "Republic of Dagestan",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "DA",
    type: null,
    latitude: "42.14318860",
    longitude: "47.09497990",
  },
  {
    id: 1884,
    name: "Republic of Ingushetia",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "IN",
    type: null,
    latitude: "43.40516980",
    longitude: "44.82029990",
  },
  {
    id: 1883,
    name: "Republic of Kalmykia",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KL",
    type: null,
    latitude: "46.18671760",
    longitude: "45.00000000",
  },
  {
    id: 1841,
    name: "Republic of Karelia",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KR",
    type: null,
    latitude: "63.15587020",
    longitude: "32.99055520",
  },
  {
    id: 1877,
    name: "Republic of Khakassia",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "KK",
    type: null,
    latitude: "53.04522810",
    longitude: "90.39821450",
  },
  {
    id: 1898,
    name: "Republic of Mordovia",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "MO",
    type: null,
    latitude: "54.23694410",
    longitude: "44.06839700",
  },
  {
    id: 1853,
    name: "Republic of North Ossetia-Alania",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "SE",
    type: null,
    latitude: "43.04513020",
    longitude: "44.28709720",
  },
  {
    id: 1861,
    name: "Republic of Tatarstan",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "TA",
    type: null,
    latitude: "55.18023640",
    longitude: "50.72639450",
  },
  {
    id: 1837,
    name: "Rostov Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "ROS",
    type: null,
    latitude: "47.68532470",
    longitude: "41.82589520",
  },
  {
    id: 1905,
    name: "Ryazan Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "RYA",
    type: null,
    latitude: "54.38759640",
    longitude: "41.25956610",
  },
  {
    id: 1879,
    name: "Saint Petersburg",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "SPE",
    type: null,
    latitude: "59.93105840",
    longitude: "30.36090960",
  },
  {
    id: 1848,
    name: "Sakha Republic",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "SA",
    type: null,
    latitude: "66.76134510",
    longitude: "124.12375300",
  },
  {
    id: 1875,
    name: "Sakhalin",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "SAK",
    type: null,
    latitude: "50.69098480",
    longitude: "142.95056890",
  },
  {
    id: 1862,
    name: "Samara Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "SAM",
    type: null,
    latitude: "53.41838390",
    longitude: "50.47255280",
  },
  {
    id: 1887,
    name: "Saratov Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "SAR",
    type: null,
    latitude: "51.83692630",
    longitude: "46.75393970",
  },
  {
    id: 1912,
    name: "Sevastopol",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "UA-40",
    type: null,
    latitude: "44.61665000",
    longitude: "33.52536710",
  },
  {
    id: 1885,
    name: "Smolensk Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "SMO",
    type: null,
    latitude: "54.98829940",
    longitude: "32.66773780",
  },
  {
    id: 1868,
    name: "Stavropol Krai",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "STA",
    type: null,
    latitude: "44.66809930",
    longitude: "43.52021400",
  },
  {
    id: 1894,
    name: "Sverdlovsk",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "SVE",
    type: null,
    latitude: "56.84309930",
    longitude: "60.64540860",
  },
  {
    id: 1878,
    name: "Tambov Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "TAM",
    type: null,
    latitude: "52.64165890",
    longitude: "41.42164510",
  },
  {
    id: 1872,
    name: "Tomsk Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "TOM",
    type: null,
    latitude: "58.89698820",
    longitude: "82.67655000",
  },
  {
    id: 1895,
    name: "Tula Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "TUL",
    type: null,
    latitude: "54.16376800",
    longitude: "37.56495070",
  },
  {
    id: 1900,
    name: "Tuva Republic",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "TY",
    type: null,
    latitude: "51.88726690",
    longitude: "95.62601720",
  },
  {
    id: 1860,
    name: "Tver Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "TVE",
    type: null,
    latitude: "57.00216540",
    longitude: "33.98531420",
  },
  {
    id: 1907,
    name: "Tyumen Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "TYU",
    type: null,
    latitude: "56.96343870",
    longitude: "66.94827800",
  },
  {
    id: 1913,
    name: "Udmurt Republic",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "UD",
    type: null,
    latitude: "57.06702180",
    longitude: "53.02779480",
  },
  {
    id: 1856,
    name: "Ulyanovsk Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "ULY",
    type: null,
    latitude: "53.97933570",
    longitude: "47.77624250",
  },
  {
    id: 1881,
    name: "Vladimir Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "VLA",
    type: null,
    latitude: "56.15534650",
    longitude: "40.59266850",
  },
  {
    id: 4916,
    name: "Volgograd Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "VGG",
    type: null,
    latitude: "49.25873930",
    longitude: "39.81544630",
  },
  {
    id: 1874,
    name: "Vologda Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "VLG",
    type: null,
    latitude: "59.87067110",
    longitude: "40.65554110",
  },
  {
    id: 1906,
    name: "Voronezh Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "VOR",
    type: null,
    latitude: "50.85897130",
    longitude: "39.86443740",
  },
  {
    id: 1847,
    name: "Yamalo-Nenets Autonomous Okrug",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "YAN",
    type: null,
    latitude: "66.06530570",
    longitude: "76.93451930",
  },
  {
    id: 1851,
    name: "Yaroslavl Oblast",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "YAR",
    type: null,
    latitude: "57.89915230",
    longitude: "38.83886330",
  },
  {
    id: 1904,
    name: "Zabaykalsky Krai",
    country_id: 182,
    country_code: "RU",
    country_name: "Russia",
    state_code: "ZAB",
    type: null,
    latitude: "53.09287710",
    longitude: "116.96765610",
  },
  {
    id: 261,
    name: "Eastern Province",
    country_id: 183,
    country_code: "RW",
    country_name: "Rwanda",
    state_code: "02",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 262,
    name: "Kigali district",
    country_id: 183,
    country_code: "RW",
    country_name: "Rwanda",
    state_code: "01",
    type: null,
    latitude: "-1.94407270",
    longitude: "30.06188510",
  },
  {
    id: 263,
    name: "Northern Province",
    country_id: 183,
    country_code: "RW",
    country_name: "Rwanda",
    state_code: "03",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 259,
    name: "Southern Province",
    country_id: 183,
    country_code: "RW",
    country_name: "Rwanda",
    state_code: "05",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 260,
    name: "Western Province",
    country_id: 183,
    country_code: "RW",
    country_name: "Rwanda",
    state_code: "04",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 3833,
    name: "Christ Church Nichola Town Parish",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "01",
    type: null,
    latitude: "17.36048120",
    longitude: "-62.76178370",
  },
  {
    id: 3832,
    name: "Nevis",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "N",
    type: null,
    latitude: "17.15535580",
    longitude: "-62.57960260",
  },
  {
    id: 3836,
    name: "Saint Anne Sandy Point Parish",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "02",
    type: null,
    latitude: "17.37253330",
    longitude: "-62.84411330",
  },
  {
    id: 3837,
    name: "Saint George Gingerland Parish",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "04",
    type: null,
    latitude: "17.12577590",
    longitude: "-62.56198110",
  },
  {
    id: 3835,
    name: "Saint James Windward Parish",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "05",
    type: null,
    latitude: "17.17696330",
    longitude: "-62.57960260",
  },
  {
    id: 3845,
    name: "Saint John Capisterre Parish",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "06",
    type: null,
    latitude: "17.38103410",
    longitude: "-62.79118330",
  },
  {
    id: 3840,
    name: "Saint John Figtree Parish",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "07",
    type: null,
    latitude: "17.11557480",
    longitude: "-62.60310040",
  },
  {
    id: 3841,
    name: "Saint Kitts",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "K",
    type: null,
    latitude: "17.34337960",
    longitude: "-62.75590430",
  },
  {
    id: 3844,
    name: "Saint Mary Cayon Parish",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "08",
    type: null,
    latitude: "17.34620710",
    longitude: "-62.73826710",
  },
  {
    id: 3834,
    name: "Saint Paul Capisterre Parish",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "09",
    type: null,
    latitude: "17.40166830",
    longitude: "-62.82573320",
  },
  {
    id: 3838,
    name: "Saint Paul Charlestown Parish",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "10",
    type: null,
    latitude: "17.13462970",
    longitude: "-62.61338160",
  },
  {
    id: 3831,
    name: "Saint Peter Basseterre Parish",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "11",
    type: null,
    latitude: "17.31029110",
    longitude: "-62.71475330",
  },
  {
    id: 3839,
    name: "Saint Thomas Lowland Parish",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "12",
    type: null,
    latitude: "17.16505130",
    longitude: "-62.60897530",
  },
  {
    id: 3842,
    name: "Saint Thomas Middle Island Parish",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "13",
    type: null,
    latitude: "17.33488130",
    longitude: "-62.80882510",
  },
  {
    id: 3843,
    name: "Trinity Palmetto Point Parish",
    country_id: 185,
    country_code: "KN",
    country_name: "Saint Kitts And Nevis",
    state_code: "15",
    type: null,
    latitude: "17.30635190",
    longitude: "-62.76178370",
  },
  {
    id: 3757,
    name: "Anse la Raye Quarter",
    country_id: 186,
    country_code: "LC",
    country_name: "Saint Lucia",
    state_code: "01",
    type: null,
    latitude: "13.94594240",
    longitude: "-61.03694680",
  },
  {
    id: 3761,
    name: "Canaries",
    country_id: 186,
    country_code: "LC",
    country_name: "Saint Lucia",
    state_code: "12",
    type: null,
    latitude: "28.29156370",
    longitude: "-16.62913040",
  },
  {
    id: 3758,
    name: "Castries Quarter",
    country_id: 186,
    country_code: "LC",
    country_name: "Saint Lucia",
    state_code: "02",
    type: null,
    latitude: "14.01010940",
    longitude: "-60.98746870",
  },
  {
    id: 3760,
    name: "Choiseul Quarter",
    country_id: 186,
    country_code: "LC",
    country_name: "Saint Lucia",
    state_code: "03",
    type: null,
    latitude: "13.77501540",
    longitude: "-61.04859100",
  },
  {
    id: 3767,
    name: "Dauphin Quarter",
    country_id: 186,
    country_code: "LC",
    country_name: "Saint Lucia",
    state_code: "04",
    type: null,
    latitude: "14.01033960",
    longitude: "-60.91909880",
  },
  {
    id: 3756,
    name: "Dennery Quarter",
    country_id: 186,
    country_code: "LC",
    country_name: "Saint Lucia",
    state_code: "05",
    type: null,
    latitude: "13.92673930",
    longitude: "-60.91909880",
  },
  {
    id: 3766,
    name: "Gros Islet Quarter",
    country_id: 186,
    country_code: "LC",
    country_name: "Saint Lucia",
    state_code: "06",
    type: null,
    latitude: "14.08435780",
    longitude: "-60.94527940",
  },
  {
    id: 3759,
    name: "Laborie Quarter",
    country_id: 186,
    country_code: "LC",
    country_name: "Saint Lucia",
    state_code: "07",
    type: null,
    latitude: "13.75227830",
    longitude: "-60.99328890",
  },
  {
    id: 3762,
    name: "Micoud Quarter",
    country_id: 186,
    country_code: "LC",
    country_name: "Saint Lucia",
    state_code: "08",
    type: null,
    latitude: "13.82118710",
    longitude: "-60.90019340",
  },
  {
    id: 3765,
    name: "Praslin Quarter",
    country_id: 186,
    country_code: "LC",
    country_name: "Saint Lucia",
    state_code: "09",
    type: null,
    latitude: "13.87523920",
    longitude: "-60.89946630",
  },
  {
    id: 3764,
    name: "Soufrière Quarter",
    country_id: 186,
    country_code: "LC",
    country_name: "Saint Lucia",
    state_code: "10",
    type: null,
    latitude: "13.85709860",
    longitude: "-61.05732480",
  },
  {
    id: 3763,
    name: "Vieux Fort Quarter",
    country_id: 186,
    country_code: "LC",
    country_name: "Saint Lucia",
    state_code: "11",
    type: null,
    latitude: "13.72060800",
    longitude: "-60.94964330",
  },
  {
    id: 3389,
    name: "Charlotte Parish",
    country_id: 188,
    country_code: "VC",
    country_name: "Saint Vincent And The Grenadines",
    state_code: "01",
    type: null,
    latitude: "13.21754510",
    longitude: "-61.16362440",
  },
  {
    id: 3388,
    name: "Grenadines Parish",
    country_id: 188,
    country_code: "VC",
    country_name: "Saint Vincent And The Grenadines",
    state_code: "06",
    type: null,
    latitude: "13.01229650",
    longitude: "-61.22773010",
  },
  {
    id: 3386,
    name: "Saint Andrew Parish",
    country_id: 188,
    country_code: "VC",
    country_name: "Saint Vincent And The Grenadines",
    state_code: "02",
    type: null,
    latitude: "43.02429990",
    longitude: "-81.20250000",
  },
  {
    id: 3387,
    name: "Saint David Parish",
    country_id: 188,
    country_code: "VC",
    country_name: "Saint Vincent And The Grenadines",
    state_code: "03",
    type: null,
    latitude: "43.85230950",
    longitude: "-79.52366540",
  },
  {
    id: 3384,
    name: "Saint George Parish",
    country_id: 188,
    country_code: "VC",
    country_name: "Saint Vincent And The Grenadines",
    state_code: "04",
    type: null,
    latitude: "42.95760900",
    longitude: "-81.32670500",
  },
  {
    id: 3385,
    name: "Saint Patrick Parish",
    country_id: 188,
    country_code: "VC",
    country_name: "Saint Vincent And The Grenadines",
    state_code: "05",
    type: null,
    latitude: "39.75091860",
    longitude: "-94.84505560",
  },
  {
    id: 4763,
    name: "A'ana",
    country_id: 191,
    country_code: "WS",
    country_name: "Samoa",
    state_code: "AA",
    type: null,
    latitude: "-13.89841800",
    longitude: "-171.97529950",
  },
  {
    id: 4761,
    name: "Aiga-i-le-Tai",
    country_id: 191,
    country_code: "WS",
    country_name: "Samoa",
    state_code: "AL",
    type: null,
    latitude: "-13.85137910",
    longitude: "-172.03254010",
  },
  {
    id: 4765,
    name: "Atua",
    country_id: 191,
    country_code: "WS",
    country_name: "Samoa",
    state_code: "AT",
    type: null,
    latitude: "-13.97870530",
    longitude: "-171.62542830",
  },
  {
    id: 4764,
    name: "Fa'asaleleaga",
    country_id: 191,
    country_code: "WS",
    country_name: "Samoa",
    state_code: "FA",
    type: null,
    latitude: "-13.63076380",
    longitude: "-172.23659810",
  },
  {
    id: 4769,
    name: "Gaga'emauga",
    country_id: 191,
    country_code: "WS",
    country_name: "Samoa",
    state_code: "GE",
    type: null,
    latitude: "-13.54286660",
    longitude: "-172.36688700",
  },
  {
    id: 4771,
    name: "Gaga'ifomauga",
    country_id: 191,
    country_code: "WS",
    country_name: "Samoa",
    state_code: "GI",
    type: null,
    latitude: "-13.54680070",
    longitude: "-172.49693310",
  },
  {
    id: 4767,
    name: "Palauli",
    country_id: 191,
    country_code: "WS",
    country_name: "Samoa",
    state_code: "PA",
    type: null,
    latitude: "-13.72945790",
    longitude: "-172.45361150",
  },
  {
    id: 4762,
    name: "Satupa'itea",
    country_id: 191,
    country_code: "WS",
    country_name: "Samoa",
    state_code: "SA",
    type: null,
    latitude: "-13.65382140",
    longitude: "-172.61592710",
  },
  {
    id: 4770,
    name: "Tuamasaga",
    country_id: 191,
    country_code: "WS",
    country_name: "Samoa",
    state_code: "TU",
    type: null,
    latitude: "-13.91635920",
    longitude: "-171.82243620",
  },
  {
    id: 4768,
    name: "Va'a-o-Fonoti",
    country_id: 191,
    country_code: "WS",
    country_name: "Samoa",
    state_code: "VF",
    type: null,
    latitude: "-13.94709030",
    longitude: "-171.54318720",
  },
  {
    id: 4766,
    name: "Vaisigano",
    country_id: 191,
    country_code: "WS",
    country_name: "Samoa",
    state_code: "VS",
    type: null,
    latitude: "-13.54138270",
    longitude: "-172.70233830",
  },
  {
    id: 59,
    name: "Acquaviva",
    country_id: 192,
    country_code: "SM",
    country_name: "San Marino",
    state_code: "01",
    type: null,
    latitude: "41.86715970",
    longitude: "14.74694790",
  },
  {
    id: 61,
    name: "Borgo Maggiore",
    country_id: 192,
    country_code: "SM",
    country_name: "San Marino",
    state_code: "06",
    type: null,
    latitude: "43.95748820",
    longitude: "12.45525460",
  },
  {
    id: 60,
    name: "Chiesanuova",
    country_id: 192,
    country_code: "SM",
    country_name: "San Marino",
    state_code: "02",
    type: null,
    latitude: "45.42261720",
    longitude: "7.65038540",
  },
  {
    id: 64,
    name: "Domagnano",
    country_id: 192,
    country_code: "SM",
    country_name: "San Marino",
    state_code: "03",
    type: null,
    latitude: "43.95019290",
    longitude: "12.46815370",
  },
  {
    id: 62,
    name: "Faetano",
    country_id: 192,
    country_code: "SM",
    country_name: "San Marino",
    state_code: "04",
    type: null,
    latitude: "43.93489670",
    longitude: "12.48965540",
  },
  {
    id: 66,
    name: "Fiorentino",
    country_id: 192,
    country_code: "SM",
    country_name: "San Marino",
    state_code: "05",
    type: null,
    latitude: "43.90783370",
    longitude: "12.45812090",
  },
  {
    id: 63,
    name: "Montegiardino",
    country_id: 192,
    country_code: "SM",
    country_name: "San Marino",
    state_code: "08",
    type: null,
    latitude: "43.90529990",
    longitude: "12.48105420",
  },
  {
    id: 58,
    name: "San Marino",
    country_id: 192,
    country_code: "SM",
    country_name: "San Marino",
    state_code: "07",
    type: null,
    latitude: "43.94236000",
    longitude: "12.45777700",
  },
  {
    id: 65,
    name: "Serravalle",
    country_id: 192,
    country_code: "SM",
    country_name: "San Marino",
    state_code: "09",
    type: null,
    latitude: "44.72320840",
    longitude: "8.85740050",
  },
  {
    id: 270,
    name: "Príncipe Province",
    country_id: 193,
    country_code: "ST",
    country_name: "Sao Tome and Principe",
    state_code: "P",
    type: null,
    latitude: "1.61393810",
    longitude: "7.40569280",
  },
  {
    id: 271,
    name: "São Tomé Province",
    country_id: 193,
    country_code: "ST",
    country_name: "Sao Tome and Principe",
    state_code: "S",
    type: null,
    latitude: "0.33019240",
    longitude: "6.73334300",
  },
  {
    id: 2853,
    name: "'Asir",
    country_id: 194,
    country_code: "SA",
    country_name: "Saudi Arabia",
    state_code: "14",
    type: "region",
    latitude: "19.09690620",
    longitude: "42.86378750",
  },
  {
    id: 2859,
    name: "Al Bahah",
    country_id: 194,
    country_code: "SA",
    country_name: "Saudi Arabia",
    state_code: "11",
    type: "region",
    latitude: "20.27227390",
    longitude: "41.44125100",
  },
  {
    id: 2857,
    name: "Al Jawf",
    country_id: 194,
    country_code: "SA",
    country_name: "Saudi Arabia",
    state_code: "12",
    type: "region",
    latitude: "29.88735600",
    longitude: "39.32062410",
  },
  {
    id: 2851,
    name: "Al Madinah",
    country_id: 194,
    country_code: "SA",
    country_name: "Saudi Arabia",
    state_code: "03",
    type: "region",
    latitude: "24.84039770",
    longitude: "39.32062410",
  },
  {
    id: 2861,
    name: "Al-Qassim",
    country_id: 194,
    country_code: "SA",
    country_name: "Saudi Arabia",
    state_code: "05",
    type: "region",
    latitude: "26.20782600",
    longitude: "43.48373800",
  },
  {
    id: 2856,
    name: "Eastern Province",
    country_id: 194,
    country_code: "SA",
    country_name: "Saudi Arabia",
    state_code: "04",
    type: "region",
    latitude: "24.04399320",
    longitude: "45.65892250",
  },
  {
    id: 2855,
    name: "Ha'il",
    country_id: 194,
    country_code: "SA",
    country_name: "Saudi Arabia",
    state_code: "06",
    type: "region",
    latitude: "27.70761430",
    longitude: "41.91964710",
  },
  {
    id: 2858,
    name: "Jizan",
    country_id: 194,
    country_code: "SA",
    country_name: "Saudi Arabia",
    state_code: "09",
    type: "region",
    latitude: "17.17381760",
    longitude: "42.70761070",
  },
  {
    id: 2850,
    name: "Makkah",
    country_id: 194,
    country_code: "SA",
    country_name: "Saudi Arabia",
    state_code: "02",
    type: "region",
    latitude: "21.52355840",
    longitude: "41.91964710",
  },
  {
    id: 2860,
    name: "Najran",
    country_id: 194,
    country_code: "SA",
    country_name: "Saudi Arabia",
    state_code: "10",
    type: "region",
    latitude: "18.35146640",
    longitude: "45.60071080",
  },
  {
    id: 2854,
    name: "Northern Borders",
    country_id: 194,
    country_code: "SA",
    country_name: "Saudi Arabia",
    state_code: "08",
    type: "region",
    latitude: "30.07991620",
    longitude: "42.86378750",
  },
  {
    id: 2849,
    name: "Riyadh",
    country_id: 194,
    country_code: "SA",
    country_name: "Saudi Arabia",
    state_code: "01",
    type: "region",
    latitude: "22.75543850",
    longitude: "46.20915470",
  },
  {
    id: 2852,
    name: "Tabuk",
    country_id: 194,
    country_code: "SA",
    country_name: "Saudi Arabia",
    state_code: "07",
    type: "region",
    latitude: "28.24533350",
    longitude: "37.63866220",
  },
  {
    id: 473,
    name: "Dakar",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "DK",
    type: null,
    latitude: "14.71667700",
    longitude: "-17.46768610",
  },
  {
    id: 480,
    name: "Diourbel Region",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "DB",
    type: null,
    latitude: "14.72830850",
    longitude: "-16.25221430",
  },
  {
    id: 479,
    name: "Fatick",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "FK",
    type: null,
    latitude: "14.33901670",
    longitude: "-16.41114250",
  },
  {
    id: 475,
    name: "Kaffrine",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "KA",
    type: null,
    latitude: "14.10520200",
    longitude: "-15.54157550",
  },
  {
    id: 483,
    name: "Kaolack",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "KL",
    type: null,
    latitude: "14.16520830",
    longitude: "-16.07577490",
  },
  {
    id: 481,
    name: "Kédougou",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "KE",
    type: null,
    latitude: "12.56046070",
    longitude: "-12.17470770",
  },
  {
    id: 474,
    name: "Kolda",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "KD",
    type: null,
    latitude: "12.91074950",
    longitude: "-14.95056710",
  },
  {
    id: 485,
    name: "Louga",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "LG",
    type: null,
    latitude: "15.61417680",
    longitude: "-16.22868000",
  },
  {
    id: 476,
    name: "Matam",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "MT",
    type: null,
    latitude: "15.66002250",
    longitude: "-13.25769060",
  },
  {
    id: 477,
    name: "Saint-Louis",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "SL",
    type: null,
    latitude: "38.62700250",
    longitude: "-90.19940420",
  },
  {
    id: 482,
    name: "Sédhiou",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "SE",
    type: null,
    latitude: "12.70460400",
    longitude: "-15.55623040",
  },
  {
    id: 486,
    name: "Tambacounda Region",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "TC",
    type: null,
    latitude: "13.56190110",
    longitude: "-13.17403480",
  },
  {
    id: 484,
    name: "Thiès Region",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "TH",
    type: null,
    latitude: "14.79100520",
    longitude: "-16.93586040",
  },
  {
    id: 478,
    name: "Ziguinchor",
    country_id: 195,
    country_code: "SN",
    country_name: "Senegal",
    state_code: "ZG",
    type: null,
    latitude: "12.56414790",
    longitude: "-16.26398250",
  },
  {
    id: 3728,
    name: "Belgrade",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "00",
    type: null,
    latitude: "44.78656800",
    longitude: "20.44892160",
  },
  {
    id: 3717,
    name: "Bor District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "14",
    type: null,
    latitude: "44.06989180",
    longitude: "22.09850860",
  },
  {
    id: 3732,
    name: "Braničevo District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "11",
    type: null,
    latitude: "44.69822460",
    longitude: "21.54467750",
  },
  {
    id: 3716,
    name: "Central Banat District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "02",
    type: null,
    latitude: "45.47884850",
    longitude: "20.60825220",
  },
  {
    id: 3715,
    name: "Jablanica District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "23",
    type: null,
    latitude: "42.94815600",
    longitude: "21.81293210",
  },
  {
    id: 3724,
    name: "Kolubara District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "09",
    type: null,
    latitude: "44.35098110",
    longitude: "20.00043050",
  },
  {
    id: 3719,
    name: "Mačva District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "08",
    type: null,
    latitude: "44.59253140",
    longitude: "19.50822460",
  },
  {
    id: 3727,
    name: "Moravica District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "17",
    type: null,
    latitude: "43.84147000",
    longitude: "20.29049870",
  },
  {
    id: 3722,
    name: "Nišava District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "20",
    type: null,
    latitude: "43.37389020",
    longitude: "21.93223310",
  },
  {
    id: 3714,
    name: "North Bačka District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "01",
    type: null,
    latitude: "45.98033940",
    longitude: "19.59070010",
  },
  {
    id: 3736,
    name: "North Banat District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "03",
    type: null,
    latitude: "45.90683900",
    longitude: "19.99934170",
  },
  {
    id: 3721,
    name: "Pčinja District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "24",
    type: null,
    latitude: "42.58363620",
    longitude: "22.14302150",
  },
  {
    id: 3712,
    name: "Pirot District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "22",
    type: null,
    latitude: "43.08740360",
    longitude: "22.59830440",
  },
  {
    id: 3741,
    name: "Podunavlje District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "10",
    type: null,
    latitude: "44.47291560",
    longitude: "20.99014260",
  },
  {
    id: 3737,
    name: "Pomoravlje District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "13",
    type: null,
    latitude: "43.95913790",
    longitude: "21.27135300",
  },
  {
    id: 3720,
    name: "Rasina District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "19",
    type: null,
    latitude: "43.52635250",
    longitude: "21.15881780",
  },
  {
    id: 3725,
    name: "Raška District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "18",
    type: null,
    latitude: "43.33734610",
    longitude: "20.57340050",
  },
  {
    id: 3711,
    name: "South Bačka District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "06",
    type: null,
    latitude: "45.48903440",
    longitude: "19.69761870",
  },
  {
    id: 3713,
    name: "South Banat District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "04",
    type: null,
    latitude: "45.00274570",
    longitude: "21.05425090",
  },
  {
    id: 3740,
    name: "Srem District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "07",
    type: null,
    latitude: "45.00291710",
    longitude: "19.80137730",
  },
  {
    id: 3734,
    name: "Šumadija District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "12",
    type: null,
    latitude: "44.20506780",
    longitude: "20.78565650",
  },
  {
    id: 3718,
    name: "Toplica District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "21",
    type: null,
    latitude: "43.19065920",
    longitude: "21.34077620",
  },
  {
    id: 3733,
    name: "Vojvodina",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "VO",
    type: null,
    latitude: "45.26086510",
    longitude: "19.83193380",
  },
  {
    id: 3726,
    name: "West Bačka District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "05",
    type: null,
    latitude: "45.73553850",
    longitude: "19.18973640",
  },
  {
    id: 3731,
    name: "Zaječar District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "15",
    type: null,
    latitude: "43.90150480",
    longitude: "22.27380110",
  },
  {
    id: 3729,
    name: "Zlatibor District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    state_code: "16",
    type: null,
    latitude: "43.64541700",
    longitude: "19.71014550",
  },
  {
    id: 513,
    name: "Anse Boileau",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "02",
    type: null,
    latitude: "-4.70472680",
    longitude: "55.48593630",
  },
  {
    id: 502,
    name: "Anse Royale",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "05",
    type: null,
    latitude: "-4.74079880",
    longitude: "55.50810120",
  },
  {
    id: 506,
    name: "Anse-aux-Pins",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "01",
    type: null,
    latitude: "-4.69004430",
    longitude: "55.51502890",
  },
  {
    id: 508,
    name: "Au Cap",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "04",
    type: null,
    latitude: "-4.70597230",
    longitude: "55.50810120",
  },
  {
    id: 497,
    name: "Baie Lazare",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "06",
    type: null,
    latitude: "-4.74825250",
    longitude: "55.48593630",
  },
  {
    id: 514,
    name: "Baie Sainte Anne",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "07",
    type: null,
    latitude: "47.05259000",
    longitude: "-64.95245790",
  },
  {
    id: 512,
    name: "Beau Vallon",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "08",
    type: null,
    latitude: "-4.62109670",
    longitude: "55.42778020",
  },
  {
    id: 515,
    name: "Bel Air",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "09",
    type: null,
    latitude: "34.10024550",
    longitude: "-118.45946300",
  },
  {
    id: 505,
    name: "Bel Ombre",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "10",
    type: null,
    latitude: "-20.50100950",
    longitude: "57.42596240",
  },
  {
    id: 517,
    name: "Cascade",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "11",
    type: null,
    latitude: "44.51628210",
    longitude: "-116.04179830",
  },
  {
    id: 503,
    name: "Glacis",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "12",
    type: null,
    latitude: "47.11573030",
    longitude: "-70.30281830",
  },
  {
    id: 500,
    name: "Grand'Anse Mahé",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "13",
    type: null,
    latitude: "-4.67739200",
    longitude: "55.46377700",
  },
  {
    id: 504,
    name: "Grand'Anse Praslin",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "14",
    type: null,
    latitude: "-4.31762190",
    longitude: "55.70783630",
  },
  {
    id: 495,
    name: "La Digue",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "15",
    type: null,
    latitude: "49.76669220",
    longitude: "-97.15466290",
  },
  {
    id: 516,
    name: "La Rivière Anglaise",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "16",
    type: null,
    latitude: "-4.61061500",
    longitude: "55.45408410",
  },
  {
    id: 499,
    name: "Les Mamelles",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "24",
    type: null,
    latitude: "38.82505050",
    longitude: "-90.48345170",
  },
  {
    id: 494,
    name: "Mont Buxton",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "17",
    type: null,
    latitude: "-4.61666670",
    longitude: "55.44577680",
  },
  {
    id: 498,
    name: "Mont Fleuri",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "18",
    type: null,
    latitude: "-4.63565430",
    longitude: "55.45546880",
  },
  {
    id: 511,
    name: "Plaisance",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "19",
    type: null,
    latitude: "45.60709500",
    longitude: "-75.11427450",
  },
  {
    id: 510,
    name: "Pointe La Rue",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "20",
    type: null,
    latitude: "-4.68048900",
    longitude: "55.51918570",
  },
  {
    id: 507,
    name: "Port Glaud",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "21",
    type: null,
    latitude: "-4.64885230",
    longitude: "55.41947530",
  },
  {
    id: 501,
    name: "Roche Caiman",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "25",
    type: null,
    latitude: "-4.63960280",
    longitude: "55.46793150",
  },
  {
    id: 496,
    name: "Saint Louis",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "22",
    type: null,
    latitude: "38.62700250",
    longitude: "-90.19940420",
  },
  {
    id: 509,
    name: "Takamaka",
    country_id: 197,
    country_code: "SC",
    country_name: "Seychelles",
    state_code: "23",
    type: null,
    latitude: "37.96459170",
    longitude: "-1.22177270",
  },
  {
    id: 914,
    name: "Eastern Province",
    country_id: 198,
    country_code: "SL",
    country_name: "Sierra Leone",
    state_code: "E",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 911,
    name: "Northern Province",
    country_id: 198,
    country_code: "SL",
    country_name: "Sierra Leone",
    state_code: "N",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 912,
    name: "Southern Province",
    country_id: 198,
    country_code: "SL",
    country_name: "Sierra Leone",
    state_code: "S",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 913,
    name: "Western Area",
    country_id: 198,
    country_code: "SL",
    country_name: "Sierra Leone",
    state_code: "W",
    type: null,
    latitude: "40.25459690",
    longitude: "-80.24554440",
  },
  {
    id: 4651,
    name: "Central Singapore Community Development Council",
    country_id: 199,
    country_code: "SG",
    country_name: "Singapore",
    state_code: "01",
    type: null,
    latitude: "1.28951400",
    longitude: "103.81438790",
  },
  {
    id: 4649,
    name: "North East Community Development Council",
    country_id: 199,
    country_code: "SG",
    country_name: "Singapore",
    state_code: "02",
    type: null,
    latitude: "45.01181130",
    longitude: "-93.24681070",
  },
  {
    id: 4653,
    name: "North West Community Development Council",
    country_id: 199,
    country_code: "SG",
    country_name: "Singapore",
    state_code: "03",
    type: null,
    latitude: "39.10709300",
    longitude: "-94.45733600",
  },
  {
    id: 4650,
    name: "South East Community Development Council",
    country_id: 199,
    country_code: "SG",
    country_name: "Singapore",
    state_code: "04",
    type: null,
    latitude: "39.28630700",
    longitude: "-76.56912370",
  },
  {
    id: 4652,
    name: "South West Community Development Council",
    country_id: 199,
    country_code: "SG",
    country_name: "Singapore",
    state_code: "05",
    type: null,
    latitude: "39.92569100",
    longitude: "-75.23105800",
  },
  {
    id: 4352,
    name: "Banská Bystrica Region",
    country_id: 200,
    country_code: "SK",
    country_name: "Slovakia",
    state_code: "BC",
    type: null,
    latitude: "48.53124990",
    longitude: "19.38287400",
  },
  {
    id: 4356,
    name: "Bratislava Region",
    country_id: 200,
    country_code: "SK",
    country_name: "Slovakia",
    state_code: "BL",
    type: null,
    latitude: "48.31183040",
    longitude: "17.19732990",
  },
  {
    id: 4353,
    name: "Košice Region",
    country_id: 200,
    country_code: "SK",
    country_name: "Slovakia",
    state_code: "KI",
    type: null,
    latitude: "48.63757370",
    longitude: "21.08342250",
  },
  {
    id: 4357,
    name: "Nitra Region",
    country_id: 200,
    country_code: "SK",
    country_name: "Slovakia",
    state_code: "NI",
    type: null,
    latitude: "48.01437650",
    longitude: "18.54165040",
  },
  {
    id: 4354,
    name: "Prešov Region",
    country_id: 200,
    country_code: "SK",
    country_name: "Slovakia",
    state_code: "PV",
    type: null,
    latitude: "49.17167730",
    longitude: "21.37420010",
  },
  {
    id: 4358,
    name: "Trenčín Region",
    country_id: 200,
    country_code: "SK",
    country_name: "Slovakia",
    state_code: "TC",
    type: null,
    latitude: "48.80867580",
    longitude: "18.23240260",
  },
  {
    id: 4355,
    name: "Trnava Region",
    country_id: 200,
    country_code: "SK",
    country_name: "Slovakia",
    state_code: "TA",
    type: null,
    latitude: "48.39438980",
    longitude: "17.72162050",
  },
  {
    id: 4359,
    name: "Žilina Region",
    country_id: 200,
    country_code: "SK",
    country_name: "Slovakia",
    state_code: "ZI",
    type: null,
    latitude: "49.20314350",
    longitude: "19.36457330",
  },
  {
    id: 4183,
    name: "Ajdovščina Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "001",
    type: null,
    latitude: "45.88707760",
    longitude: "13.90428180",
  },
  {
    id: 4326,
    name: "Ankaran Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "213",
    type: null,
    latitude: "45.57845100",
    longitude: "13.73691740",
  },
  {
    id: 4301,
    name: "Beltinci Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "002",
    type: null,
    latitude: "46.60791530",
    longitude: "16.23651270",
  },
  {
    id: 4166,
    name: "Benedikt Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "148",
    type: null,
    latitude: "46.61558410",
    longitude: "15.89572810",
  },
  {
    id: 4179,
    name: "Bistrica ob Sotli Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "149",
    type: null,
    latitude: "46.05655790",
    longitude: "15.66259470",
  },
  {
    id: 4202,
    name: "Bled Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "003",
    type: null,
    latitude: "46.36832660",
    longitude: "14.11457980",
  },
  {
    id: 4278,
    name: "Bloke Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "150",
    type: null,
    latitude: "45.77281410",
    longitude: "14.50634590",
  },
  {
    id: 4282,
    name: "Bohinj Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "004",
    type: null,
    latitude: "46.30056520",
    longitude: "13.94271950",
  },
  {
    id: 4200,
    name: "Borovnica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "005",
    type: null,
    latitude: "45.90445250",
    longitude: "14.38241890",
  },
  {
    id: 4181,
    name: "Bovec Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "006",
    type: null,
    latitude: "46.33804950",
    longitude: "13.55241740",
  },
  {
    id: 4141,
    name: "Braslovče Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "151",
    type: null,
    latitude: "46.28361920",
    longitude: "15.04183200",
  },
  {
    id: 4240,
    name: "Brda Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "007",
    type: null,
    latitude: "45.99756520",
    longitude: "13.52704740",
  },
  {
    id: 4215,
    name: "Brežice Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "009",
    type: null,
    latitude: "45.90410960",
    longitude: "15.59436390",
  },
  {
    id: 4165,
    name: "Brezovica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "008",
    type: null,
    latitude: "45.95593510",
    longitude: "14.43499520",
  },
  {
    id: 4147,
    name: "Cankova Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "152",
    type: null,
    latitude: "46.71823700",
    longitude: "16.01972220",
  },
  {
    id: 4310,
    name: "Cerklje na Gorenjskem Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "012",
    type: null,
    latitude: "46.25170540",
    longitude: "14.48579790",
  },
  {
    id: 4162,
    name: "Cerknica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "013",
    type: null,
    latitude: "45.79662550",
    longitude: "14.39217700",
  },
  {
    id: 4178,
    name: "Cerkno Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "014",
    type: null,
    latitude: "46.12884140",
    longitude: "13.98940270",
  },
  {
    id: 4176,
    name: "Cerkvenjak Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "153",
    type: null,
    latitude: "46.56707110",
    longitude: "15.94297530",
  },
  {
    id: 4191,
    name: "City Municipality of Celje",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "011",
    type: null,
    latitude: "46.23974950",
    longitude: "15.26770630",
  },
  {
    id: 4236,
    name: "City Municipality of Novo Mesto",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "085",
    type: null,
    latitude: "45.80108240",
    longitude: "15.17100890",
  },
  {
    id: 4151,
    name: "Črenšovci Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "015",
    type: null,
    latitude: "46.57200290",
    longitude: "16.28773460",
  },
  {
    id: 4232,
    name: "Črna na Koroškem Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "016",
    type: null,
    latitude: "46.47045290",
    longitude: "14.84999980",
  },
  {
    id: 4291,
    name: "Črnomelj Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "017",
    type: null,
    latitude: "45.53612250",
    longitude: "15.19441430",
  },
  {
    id: 4304,
    name: "Destrnik Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "018",
    type: null,
    latitude: "46.49223680",
    longitude: "15.87779560",
  },
  {
    id: 4167,
    name: "Divača Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "019",
    type: null,
    latitude: "45.68060690",
    longitude: "13.97203120",
  },
  {
    id: 4295,
    name: "Dobje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "154",
    type: null,
    latitude: "46.13700370",
    longitude: "15.39412900",
  },
  {
    id: 4216,
    name: "Dobrepolje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "020",
    type: null,
    latitude: "45.85249510",
    longitude: "14.70831090",
  },
  {
    id: 4252,
    name: "Dobrna Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "155",
    type: null,
    latitude: "46.33561410",
    longitude: "15.22597320",
  },
  {
    id: 4308,
    name: "Dobrova–Polhov Gradec Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "021",
    type: null,
    latitude: "46.06488960",
    longitude: "14.31681950",
  },
  {
    id: 4189,
    name: "Dobrovnik Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "156",
    type: null,
    latitude: "46.65386620",
    longitude: "16.35065940",
  },
  {
    id: 4173,
    name: "Dol pri Ljubljani Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "022",
    type: null,
    latitude: "46.08843860",
    longitude: "14.64247920",
  },
  {
    id: 4281,
    name: "Dolenjske Toplice Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "157",
    type: null,
    latitude: "45.73457110",
    longitude: "15.01294930",
  },
  {
    id: 4159,
    name: "Domžale Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "023",
    type: null,
    latitude: "46.14382690",
    longitude: "14.63752790",
  },
  {
    id: 4290,
    name: "Dornava Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "024",
    type: null,
    latitude: "46.44435130",
    longitude: "15.98891590",
  },
  {
    id: 4345,
    name: "Dravograd Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "025",
    type: null,
    latitude: "46.58921900",
    longitude: "15.02460210",
  },
  {
    id: 4213,
    name: "Duplek Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "026",
    type: null,
    latitude: "46.50100160",
    longitude: "15.75463070",
  },
  {
    id: 4293,
    name: "Gorenja Vas–Poljane Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "027",
    type: null,
    latitude: "46.11165820",
    longitude: "14.11493480",
  },
  {
    id: 4210,
    name: "Gorišnica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "028",
    type: null,
    latitude: "46.41202710",
    longitude: "16.01330890",
  },
  {
    id: 4284,
    name: "Gorje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "207",
    type: null,
    latitude: "46.38024580",
    longitude: "14.06853390",
  },
  {
    id: 4343,
    name: "Gornja Radgona Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "029",
    type: null,
    latitude: "46.67670990",
    longitude: "15.99108470",
  },
  {
    id: 4339,
    name: "Gornji Grad Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "030",
    type: null,
    latitude: "46.29617120",
    longitude: "14.80623470",
  },
  {
    id: 4271,
    name: "Gornji Petrovci Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "031",
    type: null,
    latitude: "46.80371280",
    longitude: "16.21913790",
  },
  {
    id: 4217,
    name: "Grad Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "158",
    type: null,
    latitude: "46.80873200",
    longitude: "16.10920600",
  },
  {
    id: 4336,
    name: "Grosuplje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "032",
    type: null,
    latitude: "45.95576450",
    longitude: "14.65889900",
  },
  {
    id: 4145,
    name: "Hajdina Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "159",
    type: null,
    latitude: "46.41850140",
    longitude: "15.82447220",
  },
  {
    id: 4175,
    name: "Hoče–Slivnica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "160",
    type: null,
    latitude: "46.47785800",
    longitude: "15.64760050",
  },
  {
    id: 4327,
    name: "Hodoš Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "161",
    type: null,
    latitude: "46.83141340",
    longitude: "16.32106800",
  },
  {
    id: 4193,
    name: "Horjul Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "162",
    type: null,
    latitude: "46.02253780",
    longitude: "14.29862690",
  },
  {
    id: 4341,
    name: "Hrastnik Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "034",
    type: null,
    latitude: "46.14172880",
    longitude: "15.08448940",
  },
  {
    id: 4321,
    name: "Hrpelje–Kozina Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "035",
    type: null,
    latitude: "45.60911920",
    longitude: "13.93791480",
  },
  {
    id: 4152,
    name: "Idrija Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "036",
    type: null,
    latitude: "46.00409390",
    longitude: "13.97754930",
  },
  {
    id: 4286,
    name: "Ig Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "037",
    type: null,
    latitude: "45.95888680",
    longitude: "14.52705280",
  },
  {
    id: 4305,
    name: "Ivančna Gorica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "039",
    type: null,
    latitude: "45.93958410",
    longitude: "14.80476260",
  },
  {
    id: 4322,
    name: "Izola Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "040",
    type: null,
    latitude: "45.53135570",
    longitude: "13.66646490",
  },
  {
    id: 4337,
    name: "Jesenice Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "041",
    type: null,
    latitude: "46.43670470",
    longitude: "14.05260570",
  },
  {
    id: 4203,
    name: "Jezersko Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "163",
    type: null,
    latitude: "46.39427940",
    longitude: "14.49855590",
  },
  {
    id: 4266,
    name: "Juršinci Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "042",
    type: null,
    latitude: "46.48986510",
    longitude: "15.98092300",
  },
  {
    id: 4180,
    name: "Kamnik Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "043",
    type: null,
    latitude: "46.22216660",
    longitude: "14.60707270",
  },
  {
    id: 4227,
    name: "Kanal ob Soči Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "044",
    type: null,
    latitude: "46.06735300",
    longitude: "13.62033500",
  },
  {
    id: 4150,
    name: "Kidričevo Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "045",
    type: null,
    latitude: "46.39575720",
    longitude: "15.79259060",
  },
  {
    id: 4243,
    name: "Kobarid Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "046",
    type: null,
    latitude: "46.24569710",
    longitude: "13.57869490",
  },
  {
    id: 4325,
    name: "Kobilje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "047",
    type: null,
    latitude: "46.68518000",
    longitude: "16.39367190",
  },
  {
    id: 4335,
    name: "Kočevje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "048",
    type: null,
    latitude: "45.64280000",
    longitude: "14.86158380",
  },
  {
    id: 4315,
    name: "Komen Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "049",
    type: null,
    latitude: "45.81752350",
    longitude: "13.74827110",
  },
  {
    id: 4283,
    name: "Komenda Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "164",
    type: null,
    latitude: "46.20648800",
    longitude: "14.53824990",
  },
  {
    id: 4319,
    name: "Koper City Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "050",
    type: null,
    latitude: "45.54805900",
    longitude: "13.73018770",
  },
  {
    id: 4254,
    name: "Kostanjevica na Krki Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "197",
    type: null,
    latitude: "45.83166380",
    longitude: "15.44119060",
  },
  {
    id: 4331,
    name: "Kostel Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "165",
    type: null,
    latitude: "45.49282550",
    longitude: "14.87082350",
  },
  {
    id: 4186,
    name: "Kozje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "051",
    type: null,
    latitude: "46.07332110",
    longitude: "15.55967190",
  },
  {
    id: 4287,
    name: "Kranj City Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "052",
    type: null,
    latitude: "46.25850210",
    longitude: "14.35435690",
  },
  {
    id: 4340,
    name: "Kranjska Gora Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "053",
    type: null,
    latitude: "46.48452930",
    longitude: "13.78571450",
  },
  {
    id: 4238,
    name: "Križevci Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "166",
    type: null,
    latitude: "46.57018210",
    longitude: "16.10926530",
  },
  {
    id: 4197,
    name: "Kungota",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "055",
    type: null,
    latitude: "46.64187930",
    longitude: "15.60362880",
  },
  {
    id: 4211,
    name: "Kuzma Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "056",
    type: null,
    latitude: "46.83510380",
    longitude: "16.08071000",
  },
  {
    id: 4338,
    name: "Laško Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "057",
    type: null,
    latitude: "46.15422360",
    longitude: "15.23614910",
  },
  {
    id: 4142,
    name: "Lenart Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "058",
    type: null,
    latitude: "46.58344240",
    longitude: "15.82621250",
  },
  {
    id: 4225,
    name: "Lendava Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "059",
    type: null,
    latitude: "46.55134830",
    longitude: "16.44198390",
  },
  {
    id: 4347,
    name: "Litija Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "060",
    type: null,
    latitude: "46.05732260",
    longitude: "14.83096360",
  },
  {
    id: 4270,
    name: "Ljubljana City Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "061",
    type: null,
    latitude: "46.05694650",
    longitude: "14.50575150",
  },
  {
    id: 4294,
    name: "Ljubno Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "062",
    type: null,
    latitude: "46.34431250",
    longitude: "14.83354920",
  },
  {
    id: 4351,
    name: "Ljutomer Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "063",
    type: null,
    latitude: "46.51908480",
    longitude: "16.18932160",
  },
  {
    id: 4306,
    name: "Log–Dragomer Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "208",
    type: null,
    latitude: "46.01787470",
    longitude: "14.36877670",
  },
  {
    id: 4350,
    name: "Logatec Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "064",
    type: null,
    latitude: "45.91761100",
    longitude: "14.23514510",
  },
  {
    id: 4174,
    name: "Loška Dolina Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "065",
    type: null,
    latitude: "45.64779080",
    longitude: "14.49731470",
  },
  {
    id: 4158,
    name: "Loški Potok Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "066",
    type: null,
    latitude: "45.69096370",
    longitude: "14.59859700",
  },
  {
    id: 4156,
    name: "Lovrenc na Pohorju Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "167",
    type: null,
    latitude: "46.54196380",
    longitude: "15.40004430",
  },
  {
    id: 4219,
    name: "Luče Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "067",
    type: null,
    latitude: "46.35449250",
    longitude: "14.74715040",
  },
  {
    id: 4302,
    name: "Lukovica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "068",
    type: null,
    latitude: "46.16962930",
    longitude: "14.69072590",
  },
  {
    id: 4157,
    name: "Majšperk Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "069",
    type: null,
    latitude: "46.35030190",
    longitude: "15.73405950",
  },
  {
    id: 4224,
    name: "Makole Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "198",
    type: null,
    latitude: "46.31686970",
    longitude: "15.66641260",
  },
  {
    id: 4242,
    name: "Maribor City Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "070",
    type: null,
    latitude: "46.55064960",
    longitude: "15.62054390",
  },
  {
    id: 4244,
    name: "Markovci Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "168",
    type: null,
    latitude: "46.38793090",
    longitude: "15.95860140",
  },
  {
    id: 4349,
    name: "Medvode Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "071",
    type: null,
    latitude: "46.14190800",
    longitude: "14.40325960",
  },
  {
    id: 4348,
    name: "Mengeš Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "072",
    type: null,
    latitude: "46.16591220",
    longitude: "14.57196940",
  },
  {
    id: 4323,
    name: "Metlika Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "073",
    type: null,
    latitude: "45.64807150",
    longitude: "15.31778380",
  },
  {
    id: 4265,
    name: "Mežica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "074",
    type: null,
    latitude: "46.52150270",
    longitude: "14.85213400",
  },
  {
    id: 4223,
    name: "Miklavž na Dravskem Polju Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "169",
    type: null,
    latitude: "46.50826280",
    longitude: "15.69520650",
  },
  {
    id: 4220,
    name: "Miren–Kostanjevica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "075",
    type: null,
    latitude: "45.84360290",
    longitude: "13.62766470",
  },
  {
    id: 4298,
    name: "Mirna Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "212",
    type: null,
    latitude: "45.95156470",
    longitude: "15.06209770",
  },
  {
    id: 4237,
    name: "Mirna Peč Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "170",
    type: null,
    latitude: "45.84815740",
    longitude: "15.08794500",
  },
  {
    id: 4212,
    name: "Mislinja Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "076",
    type: null,
    latitude: "46.44294030",
    longitude: "15.19876780",
  },
  {
    id: 4297,
    name: "Mokronog–Trebelno Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "199",
    type: null,
    latitude: "45.90885290",
    longitude: "15.15967360",
  },
  {
    id: 4168,
    name: "Moravče Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "077",
    type: null,
    latitude: "46.13627810",
    longitude: "14.74600100",
  },
  {
    id: 4218,
    name: "Moravske Toplice Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "078",
    type: null,
    latitude: "46.68569320",
    longitude: "16.22245820",
  },
  {
    id: 4190,
    name: "Mozirje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "079",
    type: null,
    latitude: "46.33943500",
    longitude: "14.96024130",
  },
  {
    id: 4318,
    name: "Municipality of Apače",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "195",
    type: null,
    latitude: "46.69746790",
    longitude: "15.91025340",
  },
  {
    id: 4309,
    name: "Municipality of Cirkulane",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "196",
    type: null,
    latitude: "46.32983220",
    longitude: "15.99806660",
  },
  {
    id: 4344,
    name: "Municipality of Ilirska Bistrica",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "038",
    type: null,
    latitude: "45.57913230",
    longitude: "14.28097290",
  },
  {
    id: 4314,
    name: "Municipality of Krško",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "054",
    type: null,
    latitude: "45.95896090",
    longitude: "15.49235550",
  },
  {
    id: 4187,
    name: "Municipality of Škofljica",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "123",
    type: null,
    latitude: "45.98409620",
    longitude: "14.57466260",
  },
  {
    id: 4313,
    name: "Murska Sobota City Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "080",
    type: null,
    latitude: "46.64321470",
    longitude: "16.15157540",
  },
  {
    id: 4208,
    name: "Muta Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "081",
    type: null,
    latitude: "46.60973660",
    longitude: "15.16299950",
  },
  {
    id: 4177,
    name: "Naklo Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "082",
    type: null,
    latitude: "46.27186630",
    longitude: "14.31569320",
  },
  {
    id: 4329,
    name: "Nazarje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "083",
    type: null,
    latitude: "46.28217410",
    longitude: "14.92256290",
  },
  {
    id: 4205,
    name: "Nova Gorica City Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "084",
    type: null,
    latitude: "45.97627600",
    longitude: "13.73088810",
  },
  {
    id: 4320,
    name: "Odranci Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "086",
    type: null,
    latitude: "46.59010170",
    longitude: "16.27881650",
  },
  {
    id: 4143,
    name: "Oplotnica",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "171",
    type: null,
    latitude: "46.38716300",
    longitude: "15.44581310",
  },
  {
    id: 4221,
    name: "Ormož Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "087",
    type: null,
    latitude: "46.43533330",
    longitude: "16.15437400",
  },
  {
    id: 4199,
    name: "Osilnica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "088",
    type: null,
    latitude: "45.54184670",
    longitude: "14.71563030",
  },
  {
    id: 4172,
    name: "Pesnica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "089",
    type: null,
    latitude: "46.60887550",
    longitude: "15.67570510",
  },
  {
    id: 4201,
    name: "Piran Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "090",
    type: null,
    latitude: "45.52888560",
    longitude: "13.56807350",
  },
  {
    id: 4184,
    name: "Pivka Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "091",
    type: null,
    latitude: "45.67892960",
    longitude: "14.25426890",
  },
  {
    id: 4146,
    name: "Podčetrtek Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "092",
    type: null,
    latitude: "46.17395420",
    longitude: "15.60138160",
  },
  {
    id: 4161,
    name: "Podlehnik Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "172",
    type: null,
    latitude: "46.33107820",
    longitude: "15.87858360",
  },
  {
    id: 4234,
    name: "Podvelka Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "093",
    type: null,
    latitude: "46.62219520",
    longitude: "15.38899220",
  },
  {
    id: 4239,
    name: "Poljčane Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "200",
    type: null,
    latitude: "46.31398530",
    longitude: "15.57847910",
  },
  {
    id: 4272,
    name: "Polzela Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "173",
    type: null,
    latitude: "46.28089700",
    longitude: "15.07373210",
  },
  {
    id: 4330,
    name: "Postojna Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "094",
    type: null,
    latitude: "45.77493900",
    longitude: "14.21342630",
  },
  {
    id: 4188,
    name: "Prebold Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "174",
    type: null,
    latitude: "46.23591360",
    longitude: "15.09369120",
  },
  {
    id: 4303,
    name: "Preddvor Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "095",
    type: null,
    latitude: "46.30171390",
    longitude: "14.42181650",
  },
  {
    id: 4274,
    name: "Prevalje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "175",
    type: null,
    latitude: "46.56211460",
    longitude: "14.88478610",
  },
  {
    id: 4228,
    name: "Ptuj City Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "096",
    type: null,
    latitude: "46.41995350",
    longitude: "15.86968840",
  },
  {
    id: 4288,
    name: "Puconci Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "097",
    type: null,
    latitude: "46.72004180",
    longitude: "16.09977920",
  },
  {
    id: 4204,
    name: "Rače–Fram Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "098",
    type: null,
    latitude: "46.45420830",
    longitude: "15.63294670",
  },
  {
    id: 4195,
    name: "Radeče Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "099",
    type: null,
    latitude: "46.06669540",
    longitude: "15.18204380",
  },
  {
    id: 4292,
    name: "Radenci Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "100",
    type: null,
    latitude: "46.62311210",
    longitude: "16.05069030",
  },
  {
    id: 4275,
    name: "Radlje ob Dravi Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "101",
    type: null,
    latitude: "46.61357320",
    longitude: "15.23544380",
  },
  {
    id: 4231,
    name: "Radovljica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "102",
    type: null,
    latitude: "46.33558270",
    longitude: "14.20945340",
  },
  {
    id: 4155,
    name: "Ravne na Koroškem Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "103",
    type: null,
    latitude: "46.55211940",
    longitude: "14.95990840",
  },
  {
    id: 4206,
    name: "Razkrižje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "176",
    type: null,
    latitude: "46.52263390",
    longitude: "16.26686380",
  },
  {
    id: 4160,
    name: "Rečica ob Savinji Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "209",
    type: null,
    latitude: "46.32337900",
    longitude: "14.92236700",
  },
  {
    id: 4253,
    name: "Renče–Vogrsko Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "201",
    type: null,
    latitude: "45.89546170",
    longitude: "13.67856730",
  },
  {
    id: 4235,
    name: "Ribnica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "104",
    type: null,
    latitude: "45.74003030",
    longitude: "14.72657820",
  },
  {
    id: 4207,
    name: "Ribnica na Pohorju Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "177",
    type: null,
    latitude: "46.53561450",
    longitude: "15.26745380",
  },
  {
    id: 4233,
    name: "Rogaška Slatina Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "106",
    type: null,
    latitude: "46.24539730",
    longitude: "15.62650140",
  },
  {
    id: 4264,
    name: "Rogašovci Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "105",
    type: null,
    latitude: "46.80557850",
    longitude: "16.03452370",
  },
  {
    id: 4209,
    name: "Rogatec Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "107",
    type: null,
    latitude: "46.22866260",
    longitude: "15.69913380",
  },
  {
    id: 4280,
    name: "Ruše Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "108",
    type: null,
    latitude: "46.52062650",
    longitude: "15.48178690",
  },
  {
    id: 4222,
    name: "Šalovci Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "033",
    type: null,
    latitude: "46.85335680",
    longitude: "16.25917910",
  },
  {
    id: 4230,
    name: "Selnica ob Dravi Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "178",
    type: null,
    latitude: "46.55139180",
    longitude: "15.49294100",
  },
  {
    id: 4346,
    name: "Semič Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "109",
    type: null,
    latitude: "45.65205340",
    longitude: "15.18207010",
  },
  {
    id: 4317,
    name: "Šempeter–Vrtojba Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "183",
    type: null,
    latitude: "45.92900950",
    longitude: "13.64155940",
  },
  {
    id: 4299,
    name: "Šenčur Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "117",
    type: null,
    latitude: "46.24336990",
    longitude: "14.41922230",
  },
  {
    id: 4324,
    name: "Šentilj Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "118",
    type: null,
    latitude: "46.68628390",
    longitude: "15.71035670",
  },
  {
    id: 4241,
    name: "Šentjernej Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "119",
    type: null,
    latitude: "45.84341300",
    longitude: "15.33783120",
  },
  {
    id: 4171,
    name: "Šentjur Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "120",
    type: null,
    latitude: "46.26543390",
    longitude: "15.40800000",
  },
  {
    id: 4311,
    name: "Šentrupert Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "211",
    type: null,
    latitude: "45.98731420",
    longitude: "15.08297830",
  },
  {
    id: 4268,
    name: "Sevnica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "110",
    type: null,
    latitude: "46.00703170",
    longitude: "15.30456790",
  },
  {
    id: 4149,
    name: "Sežana Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "111",
    type: null,
    latitude: "45.72751090",
    longitude: "13.86619310",
  },
  {
    id: 4170,
    name: "Škocjan Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "121",
    type: null,
    latitude: "45.91754540",
    longitude: "15.31017360",
  },
  {
    id: 4316,
    name: "Škofja Loka Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "122",
    type: null,
    latitude: "46.14098440",
    longitude: "14.28118730",
  },
  {
    id: 4169,
    name: "Slovenj Gradec City Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "112",
    type: null,
    latitude: "46.48777180",
    longitude: "15.07294780",
  },
  {
    id: 4332,
    name: "Slovenska Bistrica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "113",
    type: null,
    latitude: "46.39198130",
    longitude: "15.57278690",
  },
  {
    id: 4198,
    name: "Slovenske Konjice Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "114",
    type: null,
    latitude: "46.33691910",
    longitude: "15.42147080",
  },
  {
    id: 4285,
    name: "Šmarje pri Jelšah Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "124",
    type: null,
    latitude: "46.22870250",
    longitude: "15.51903530",
  },
  {
    id: 4289,
    name: "Šmarješke Toplice Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "206",
    type: null,
    latitude: "45.86803770",
    longitude: "15.23474220",
  },
  {
    id: 4296,
    name: "Šmartno ob Paki Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "125",
    type: null,
    latitude: "46.32903720",
    longitude: "15.03339370",
  },
  {
    id: 4279,
    name: "Šmartno pri Litiji Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "194",
    type: null,
    latitude: "46.04549710",
    longitude: "14.84101330",
  },
  {
    id: 4277,
    name: "Sodražica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "179",
    type: null,
    latitude: "45.76165650",
    longitude: "14.63528530",
  },
  {
    id: 4261,
    name: "Solčava Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "180",
    type: null,
    latitude: "46.40235260",
    longitude: "14.68023040",
  },
  {
    id: 4248,
    name: "Šoštanj Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "126",
    type: null,
    latitude: "46.37828360",
    longitude: "15.04613780",
  },
  {
    id: 4263,
    name: "Središče ob Dravi",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "202",
    type: null,
    latitude: "46.39592820",
    longitude: "16.27049150",
  },
  {
    id: 4259,
    name: "Starše Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "115",
    type: null,
    latitude: "46.46743310",
    longitude: "15.76405460",
  },
  {
    id: 4185,
    name: "Štore Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "127",
    type: null,
    latitude: "46.22225140",
    longitude: "15.31261160",
  },
  {
    id: 4333,
    name: "Straža Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "203",
    type: null,
    latitude: "45.77684280",
    longitude: "15.09486940",
  },
  {
    id: 4164,
    name: "Sveta Ana Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "181",
    type: null,
    latitude: "46.65000000",
    longitude: "15.84527800",
  },
  {
    id: 4260,
    name: "Sveta Trojica v Slovenskih Goricah Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "204",
    type: null,
    latitude: "46.56808090",
    longitude: "15.88230640",
  },
  {
    id: 4229,
    name: "Sveti Andraž v Slovenskih Goricah Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "182",
    type: null,
    latitude: "46.51897470",
    longitude: "15.94982620",
  },
  {
    id: 4255,
    name: "Sveti Jurij ob Ščavnici Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "116",
    type: null,
    latitude: "46.56874520",
    longitude: "16.02225280",
  },
  {
    id: 4328,
    name: "Sveti Jurij v Slovenskih Goricah Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "210",
    type: null,
    latitude: "46.61707910",
    longitude: "15.78046770",
  },
  {
    id: 4273,
    name: "Sveti Tomaž Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "205",
    type: null,
    latitude: "46.48352830",
    longitude: "16.07944200",
  },
  {
    id: 4194,
    name: "Tabor Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "184",
    type: null,
    latitude: "46.21079210",
    longitude: "15.01742490",
  },
  {
    id: 4312,
    name: "Tišina Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "010",
    type: null,
    latitude: "46.65418840",
    longitude: "16.07547810",
  },
  {
    id: 4247,
    name: "Tolmin Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "128",
    type: null,
    latitude: "46.18571880",
    longitude: "13.73198380",
  },
  {
    id: 4246,
    name: "Trbovlje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "129",
    type: null,
    latitude: "46.15035630",
    longitude: "15.04531370",
  },
  {
    id: 4214,
    name: "Trebnje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "130",
    type: null,
    latitude: "45.90801630",
    longitude: "15.01319050",
  },
  {
    id: 4153,
    name: "Trnovska Vas Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "185",
    type: null,
    latitude: "46.52940350",
    longitude: "15.88531180",
  },
  {
    id: 4250,
    name: "Tržič Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "131",
    type: null,
    latitude: "46.35935140",
    longitude: "14.30066230",
  },
  {
    id: 4334,
    name: "Trzin Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "186",
    type: null,
    latitude: "46.12982410",
    longitude: "14.55776370",
  },
  {
    id: 4251,
    name: "Turnišče Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "132",
    type: null,
    latitude: "46.61375040",
    longitude: "16.32021000",
  },
  {
    id: 4267,
    name: "Velika Polana Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "187",
    type: null,
    latitude: "46.57317150",
    longitude: "16.34441260",
  },
  {
    id: 4144,
    name: "Velike Lašče Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "134",
    type: null,
    latitude: "45.83365910",
    longitude: "14.63623630",
  },
  {
    id: 4257,
    name: "Veržej Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "188",
    type: null,
    latitude: "46.58411350",
    longitude: "16.16208000",
  },
  {
    id: 4300,
    name: "Videm Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "135",
    type: null,
    latitude: "46.36383300",
    longitude: "15.87812120",
  },
  {
    id: 4196,
    name: "Vipava Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "136",
    type: null,
    latitude: "45.84126740",
    longitude: "13.96096130",
  },
  {
    id: 4148,
    name: "Vitanje Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "137",
    type: null,
    latitude: "46.38153230",
    longitude: "15.29506870",
  },
  {
    id: 4154,
    name: "Vodice Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "138",
    type: null,
    latitude: "46.18966430",
    longitude: "14.49385390",
  },
  {
    id: 4245,
    name: "Vojnik Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "139",
    type: null,
    latitude: "46.29205810",
    longitude: "15.30205800",
  },
  {
    id: 4163,
    name: "Vransko Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "189",
    type: null,
    latitude: "46.23900600",
    longitude: "14.95272490",
  },
  {
    id: 4262,
    name: "Vrhnika Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "140",
    type: null,
    latitude: "45.95027190",
    longitude: "14.32764220",
  },
  {
    id: 4226,
    name: "Vuzenica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "141",
    type: null,
    latitude: "46.59808360",
    longitude: "15.16572370",
  },
  {
    id: 4269,
    name: "Zagorje ob Savi Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "142",
    type: null,
    latitude: "46.13452020",
    longitude: "14.99643840",
  },
  {
    id: 4258,
    name: "Žalec Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "190",
    type: null,
    latitude: "46.25197120",
    longitude: "15.16500720",
  },
  {
    id: 4182,
    name: "Zavrč Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "143",
    type: null,
    latitude: "46.35713000",
    longitude: "16.04777470",
  },
  {
    id: 4256,
    name: "Železniki Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "146",
    type: null,
    latitude: "46.22563770",
    longitude: "14.16936170",
  },
  {
    id: 4249,
    name: "Žetale Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "191",
    type: null,
    latitude: "46.27428330",
    longitude: "15.79133590",
  },
  {
    id: 4192,
    name: "Žiri Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "147",
    type: null,
    latitude: "46.04724990",
    longitude: "14.10984510",
  },
  {
    id: 4276,
    name: "Žirovnica Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "192",
    type: null,
    latitude: "46.39544030",
    longitude: "14.15396320",
  },
  {
    id: 4342,
    name: "Zreče Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "144",
    type: null,
    latitude: "46.41777860",
    longitude: "15.37094310",
  },
  {
    id: 4307,
    name: "Žužemberk Municipality",
    country_id: 201,
    country_code: "SI",
    country_name: "Slovenia",
    state_code: "193",
    type: null,
    latitude: "45.82003500",
    longitude: "14.95359190",
  },
  {
    id: 4784,
    name: "Central Province",
    country_id: 202,
    country_code: "SB",
    country_name: "Solomon Islands",
    state_code: "CE",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 4781,
    name: "Choiseul Province",
    country_id: 202,
    country_code: "SB",
    country_name: "Solomon Islands",
    state_code: "CH",
    type: null,
    latitude: "-7.05014940",
    longitude: "156.95114590",
  },
  {
    id: 4785,
    name: "Guadalcanal Province",
    country_id: 202,
    country_code: "SB",
    country_name: "Solomon Islands",
    state_code: "GU",
    type: null,
    latitude: "-9.57732840",
    longitude: "160.14558050",
  },
  {
    id: 4778,
    name: "Honiara",
    country_id: 202,
    country_code: "SB",
    country_name: "Solomon Islands",
    state_code: "CT",
    type: null,
    latitude: "-9.44563810",
    longitude: "159.97289990",
  },
  {
    id: 4780,
    name: "Isabel Province",
    country_id: 202,
    country_code: "SB",
    country_name: "Solomon Islands",
    state_code: "IS",
    type: null,
    latitude: "-8.05923530",
    longitude: "159.14470810",
  },
  {
    id: 4782,
    name: "Makira-Ulawa Province",
    country_id: 202,
    country_code: "SB",
    country_name: "Solomon Islands",
    state_code: "MK",
    type: null,
    latitude: "-10.57374470",
    longitude: "161.80969410",
  },
  {
    id: 4783,
    name: "Malaita Province",
    country_id: 202,
    country_code: "SB",
    country_name: "Solomon Islands",
    state_code: "ML",
    type: null,
    latitude: "-8.94461680",
    longitude: "160.90712360",
  },
  {
    id: 4787,
    name: "Rennell and Bellona Province",
    country_id: 202,
    country_code: "SB",
    country_name: "Solomon Islands",
    state_code: "RB",
    type: null,
    latitude: "-11.61314350",
    longitude: "160.16939490",
  },
  {
    id: 4779,
    name: "Temotu Province",
    country_id: 202,
    country_code: "SB",
    country_name: "Solomon Islands",
    state_code: "TE",
    type: null,
    latitude: "-10.68692900",
    longitude: "166.06239790",
  },
  {
    id: 4786,
    name: "Western Province",
    country_id: 202,
    country_code: "SB",
    country_name: "Solomon Islands",
    state_code: "WE",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 925,
    name: "Awdal Region",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "AW",
    type: null,
    latitude: "10.63342850",
    longitude: "43.32946600",
  },
  {
    id: 917,
    name: "Bakool",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "BK",
    type: null,
    latitude: "4.36572210",
    longitude: "44.09603110",
  },
  {
    id: 927,
    name: "Banaadir",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "BN",
    type: null,
    latitude: "2.11873750",
    longitude: "45.33694590",
  },
  {
    id: 930,
    name: "Bari",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "BR",
    type: null,
    latitude: "41.11714320",
    longitude: "16.87187150",
  },
  {
    id: 926,
    name: "Bay",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "BY",
    type: null,
    latitude: "37.03655340",
    longitude: "-95.61747670",
  },
  {
    id: 918,
    name: "Galguduud",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "GA",
    type: null,
    latitude: "5.18501280",
    longitude: "46.82528380",
  },
  {
    id: 928,
    name: "Gedo",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "GE",
    type: null,
    latitude: "3.50392270",
    longitude: "42.23624350",
  },
  {
    id: 915,
    name: "Hiran",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "HI",
    type: null,
    latitude: "4.32101500",
    longitude: "45.29938620",
  },
  {
    id: 924,
    name: "Lower Juba",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "JH",
    type: null,
    latitude: "0.22402100",
    longitude: "41.60118140",
  },
  {
    id: 921,
    name: "Lower Shebelle",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "SH",
    type: null,
    latitude: "1.87664580",
    longitude: "44.24790150",
  },
  {
    id: 922,
    name: "Middle Juba",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "JD",
    type: null,
    latitude: "2.07804880",
    longitude: "41.60118140",
  },
  {
    id: 923,
    name: "Middle Shebelle",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "SD",
    type: null,
    latitude: "2.92502470",
    longitude: "45.90396890",
  },
  {
    id: 916,
    name: "Mudug",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "MU",
    type: null,
    latitude: "6.56567260",
    longitude: "47.76375650",
  },
  {
    id: 920,
    name: "Nugal",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "NU",
    type: null,
    latitude: "43.27938610",
    longitude: "17.03392050",
  },
  {
    id: 919,
    name: "Sanaag Region",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "SA",
    type: null,
    latitude: "10.39382180",
    longitude: "47.76375650",
  },
  {
    id: 929,
    name: "Togdheer Region",
    country_id: 203,
    country_code: "SO",
    country_name: "Somalia",
    state_code: "TO",
    type: null,
    latitude: "9.44605870",
    longitude: "45.29938620",
  },
  {
    id: 938,
    name: "Eastern Cape",
    country_id: 204,
    country_code: "ZA",
    country_name: "South Africa",
    state_code: "EC",
    type: null,
    latitude: "-32.29684020",
    longitude: "26.41938900",
  },
  {
    id: 932,
    name: "Free State",
    country_id: 204,
    country_code: "ZA",
    country_name: "South Africa",
    state_code: "FS",
    type: null,
    latitude: "37.68585250",
    longitude: "-97.28112560",
  },
  {
    id: 936,
    name: "Gauteng",
    country_id: 204,
    country_code: "ZA",
    country_name: "South Africa",
    state_code: "GP",
    type: null,
    latitude: "-26.27075930",
    longitude: "28.11226790",
  },
  {
    id: 935,
    name: "KwaZulu-Natal",
    country_id: 204,
    country_code: "ZA",
    country_name: "South Africa",
    state_code: "KZN",
    type: null,
    latitude: "-28.53055390",
    longitude: "30.89582420",
  },
  {
    id: 933,
    name: "Limpopo",
    country_id: 204,
    country_code: "ZA",
    country_name: "South Africa",
    state_code: "LP",
    type: null,
    latitude: "-23.40129460",
    longitude: "29.41793240",
  },
  {
    id: 937,
    name: "Mpumalanga",
    country_id: 204,
    country_code: "ZA",
    country_name: "South Africa",
    state_code: "MP",
    type: null,
    latitude: "-25.56533600",
    longitude: "30.52790960",
  },
  {
    id: 934,
    name: "North West",
    country_id: 204,
    country_code: "ZA",
    country_name: "South Africa",
    state_code: "NW",
    type: null,
    latitude: "32.75885200",
    longitude: "-97.32880600",
  },
  {
    id: 931,
    name: "Northern Cape",
    country_id: 204,
    country_code: "ZA",
    country_name: "South Africa",
    state_code: "NC",
    type: null,
    latitude: "-29.04668080",
    longitude: "21.85685860",
  },
  {
    id: 939,
    name: "Western Cape",
    country_id: 204,
    country_code: "ZA",
    country_name: "South Africa",
    state_code: "WC",
    type: null,
    latitude: "-33.22779180",
    longitude: "21.85685860",
  },
  {
    id: 3860,
    name: "Busan",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "26",
    type: null,
    latitude: "35.17955430",
    longitude: "129.07564160",
  },
  {
    id: 3846,
    name: "Daegu",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "27",
    type: null,
    latitude: "35.87143540",
    longitude: "128.60144500",
  },
  {
    id: 3850,
    name: "Daejeon",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "30",
    type: null,
    latitude: "36.35041190",
    longitude: "127.38454750",
  },
  {
    id: 3862,
    name: "Gangwon Province",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "42",
    type: null,
    latitude: "37.82280000",
    longitude: "128.15550000",
  },
  {
    id: 3858,
    name: "Gwangju",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "29",
    type: null,
    latitude: "35.15954540",
    longitude: "126.85260120",
  },
  {
    id: 3847,
    name: "Gyeonggi Province",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "41",
    type: null,
    latitude: "37.41380000",
    longitude: "127.51830000",
  },
  {
    id: 3848,
    name: "Incheon",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "28",
    type: null,
    latitude: "37.45625570",
    longitude: "126.70520620",
  },
  {
    id: 3853,
    name: "Jeju",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "49",
    type: null,
    latitude: "33.95682780",
    longitude: "-84.13135000",
  },
  {
    id: 3854,
    name: "North Chungcheong Province",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "43",
    type: null,
    latitude: "36.80000000",
    longitude: "127.70000000",
  },
  {
    id: 3855,
    name: "North Gyeongsang Province",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "47",
    type: null,
    latitude: "36.49190000",
    longitude: "128.88890000",
  },
  {
    id: 3851,
    name: "North Jeolla Province",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "45",
    type: null,
    latitude: "35.71750000",
    longitude: "127.15300000",
  },
  {
    id: 3861,
    name: "Sejong City",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "50",
    type: null,
    latitude: "34.05233230",
    longitude: "-118.30848970",
  },
  {
    id: 3849,
    name: "Seoul",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "11",
    type: null,
    latitude: "37.56653500",
    longitude: "126.97796920",
  },
  {
    id: 3859,
    name: "South Chungcheong Province",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "44",
    type: null,
    latitude: "36.51840000",
    longitude: "126.80000000",
  },
  {
    id: 3857,
    name: "South Gyeongsang Province",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "48",
    type: null,
    latitude: "35.46060000",
    longitude: "128.21320000",
  },
  {
    id: 3856,
    name: "South Jeolla Province",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "46",
    type: null,
    latitude: "34.86790000",
    longitude: "126.99100000",
  },
  {
    id: 3852,
    name: "Ulsan",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    state_code: "31",
    type: null,
    latitude: "35.53837730",
    longitude: "129.31135960",
  },
  {
    id: 2092,
    name: "Central Equatoria",
    country_id: 206,
    country_code: "SS",
    country_name: "South Sudan",
    state_code: "EC",
    type: null,
    latitude: "4.61440630",
    longitude: "31.26263660",
  },
  {
    id: 2093,
    name: "Eastern Equatoria",
    country_id: 206,
    country_code: "SS",
    country_name: "South Sudan",
    state_code: "EE",
    type: null,
    latitude: "5.06929950",
    longitude: "33.43835300",
  },
  {
    id: 2094,
    name: "Jonglei State",
    country_id: 206,
    country_code: "SS",
    country_name: "South Sudan",
    state_code: "JG",
    type: null,
    latitude: "7.18196190",
    longitude: "32.35609520",
  },
  {
    id: 2090,
    name: "Lakes",
    country_id: 206,
    country_code: "SS",
    country_name: "South Sudan",
    state_code: "LK",
    type: null,
    latitude: "37.16282550",
    longitude: "-95.69116230",
  },
  {
    id: 2088,
    name: "Northern Bahr el Ghazal",
    country_id: 206,
    country_code: "SS",
    country_name: "South Sudan",
    state_code: "BN",
    type: null,
    latitude: "8.53604490",
    longitude: "26.79678490",
  },
  {
    id: 2085,
    name: "Unity",
    country_id: 206,
    country_code: "SS",
    country_name: "South Sudan",
    state_code: "UY",
    type: null,
    latitude: "37.78712760",
    longitude: "-122.40340790",
  },
  {
    id: 2086,
    name: "Upper Nile",
    country_id: 206,
    country_code: "SS",
    country_name: "South Sudan",
    state_code: "NU",
    type: null,
    latitude: "9.88942020",
    longitude: "32.71813750",
  },
  {
    id: 2087,
    name: "Warrap",
    country_id: 206,
    country_code: "SS",
    country_name: "South Sudan",
    state_code: "WR",
    type: null,
    latitude: "8.08862380",
    longitude: "28.64106410",
  },
  {
    id: 2091,
    name: "Western Bahr el Ghazal",
    country_id: 206,
    country_code: "SS",
    country_name: "South Sudan",
    state_code: "BW",
    type: null,
    latitude: "8.64523990",
    longitude: "25.28375850",
  },
  {
    id: 2089,
    name: "Western Equatoria",
    country_id: 206,
    country_code: "SS",
    country_name: "South Sudan",
    state_code: "EW",
    type: null,
    latitude: "5.34717990",
    longitude: "28.29943500",
  },
  {
    id: 5089,
    name: "A Coruña",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "C",
    type: "province",
    latitude: "43.36190400",
    longitude: "-8.43019320",
  },
  {
    id: 5109,
    name: "Albacete",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "AB",
    type: "province",
    latitude: "38.99223120",
    longitude: "-1.87809890",
  },
  {
    id: 5108,
    name: "Alicante",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "A",
    type: "province",
    latitude: "38.35795460",
    longitude: "-0.54256340",
  },
  {
    id: 5095,
    name: "Almeria",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "AL",
    type: "province",
    latitude: "36.84152680",
    longitude: "-2.47462610",
  },
  {
    id: 5093,
    name: "Araba",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "VI",
    type: "province",
    latitude: "42.83951190",
    longitude: "-3.84237740",
  },
  {
    id: 1160,
    name: "Asturias",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "O",
    type: "province",
    latitude: "43.36139530",
    longitude: "-5.85932670",
  },
  {
    id: 1189,
    name: "Ávila",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "AV",
    type: "province",
    latitude: "40.69345110",
    longitude: "-4.89356270",
  },
  {
    id: 5092,
    name: "Badajoz",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "BA",
    type: "province",
    latitude: "38.87937480",
    longitude: "-7.02269830",
  },
  {
    id: 5102,
    name: "Barcelona",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "B",
    type: "province",
    latitude: "41.39266790",
    longitude: "2.14018910",
  },
  {
    id: 5094,
    name: "Bizkaia",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "BI",
    type: "province",
    latitude: "43.21921990",
    longitude: "-3.21110870",
  },
  {
    id: 1146,
    name: "Burgos",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "BU",
    type: "province",
    latitude: "42.33807580",
    longitude: "-3.58126920",
  },
  {
    id: 1190,
    name: "Caceres",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "CC",
    type: "province",
    latitude: "39.47163130",
    longitude: "-6.42573840",
  },
  {
    id: 5096,
    name: "Cádiz",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "CA",
    type: "province",
    latitude: "36.51638510",
    longitude: "-6.29997670",
  },
  {
    id: 1170,
    name: "Cantabria",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "S",
    type: "province",
    latitude: "43.18283960",
    longitude: "-3.98784270",
  },
  {
    id: 5110,
    name: "Castellón",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "CS",
    type: "province",
    latitude: "39.98114350",
    longitude: "0.00884070",
  },
  {
    id: 5105,
    name: "Ciudad Real",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "CR",
    type: "province",
    latitude: "38.98607580",
    longitude: "-3.94449750",
  },
  {
    id: 5097,
    name: "Córdoba",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "CO",
    type: "province",
    latitude: "36.51638510",
    longitude: "-6.29997670",
  },
  {
    id: 5106,
    name: "Cuenca",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "CU",
    type: "province",
    latitude: "40.06200360",
    longitude: "-2.16553440",
  },
  {
    id: 1191,
    name: "Gipuzkoa",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "SS",
    type: "province",
    latitude: "43.14523600",
    longitude: "-2.44618250",
  },
  {
    id: 5103,
    name: "Girona",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "GI",
    type: "province",
    latitude: "41.98034450",
    longitude: "2.80115770",
  },
  {
    id: 5098,
    name: "Granada",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "GR",
    type: "province",
    latitude: "37.18094110",
    longitude: "-3.62629100",
  },
  {
    id: 5107,
    name: "Guadalajara",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "GU",
    type: "province",
    latitude: "40.63222140",
    longitude: "-3.19068200",
  },
  {
    id: 5099,
    name: "Huelva",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "H",
    type: "province",
    latitude: "37.27086660",
    longitude: "-6.95719990",
  },
  {
    id: 1177,
    name: "Huesca",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "HU",
    type: "province",
    latitude: "41.59762750",
    longitude: "-0.90566230",
  },
  {
    id: 1174,
    name: "Islas Baleares",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "PM",
    type: "province",
    latitude: "39.35877590",
    longitude: "2.73563280",
  },
  {
    id: 5100,
    name: "Jaén",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "J",
    type: "province",
    latitude: "37.78009310",
    longitude: "-3.81437450",
  },
  {
    id: 1171,
    name: "La Rioja",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "LO",
    type: "province",
    latitude: "42.28707330",
    longitude: "-2.53960300",
  },
  {
    id: 1185,
    name: "Las Palmas",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "GC",
    type: "province",
    latitude: "28.29156370",
    longitude: "-16.62913040",
  },
  {
    id: 1200,
    name: "Léon",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "LE",
    type: "province",
    latitude: "42.59870410",
    longitude: "-5.56708390",
  },
  {
    id: 5104,
    name: "Lleida",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "L",
    type: "province",
    latitude: "41.61837310",
    longitude: "0.60242530",
  },
  {
    id: 5090,
    name: "Lugo",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "LU",
    type: "province",
    latitude: "43.01231370",
    longitude: "-7.57400960",
  },
  {
    id: 1158,
    name: "Madrid",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "M",
    type: "province",
    latitude: "40.41675150",
    longitude: "-3.70383220",
  },
  {
    id: 5101,
    name: "Málaga",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "MA",
    type: "province",
    latitude: "36.71820150",
    longitude: "-4.51930600",
  },
  {
    id: 1176,
    name: "Murcia",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "MU",
    type: "province",
    latitude: "38.13981410",
    longitude: "-1.36621600",
  },
  {
    id: 1204,
    name: "Navarra",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "NA",
    type: "province",
    latitude: "42.69539090",
    longitude: "-1.67606910",
  },
  {
    id: 5091,
    name: "Ourense",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "OR",
    type: "province",
    latitude: "42.33836130",
    longitude: "-7.88119510",
  },
  {
    id: 1157,
    name: "Palencia",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "P",
    type: "province",
    latitude: "42.00968320",
    longitude: "-4.52879490",
  },
  {
    id: 1167,
    name: "Pontevedra",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "PO",
    type: "province",
    latitude: "42.43385950",
    longitude: "-8.65685520",
  },
  {
    id: 1147,
    name: "Salamanca",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "SA",
    type: "province",
    latitude: "40.95152630",
    longitude: "-6.23759470",
  },
  {
    id: 5112,
    name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "TF",
    type: "province",
    latitude: "28.45789140",
    longitude: "-16.32135390",
  },
  {
    id: 1192,
    name: "Segovia",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "SG",
    type: "province",
    latitude: "40.94292960",
    longitude: "-4.10889420",
  },
  {
    id: 1193,
    name: "Sevilla",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "SE",
    type: "province",
    latitude: "37.37535010",
    longitude: "-6.02509730",
  },
  {
    id: 1208,
    name: "Soria",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "SO",
    type: "province",
    latitude: "41.76654640",
    longitude: "-2.47903060",
  },
  {
    id: 1203,
    name: "Tarragona",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "T",
    type: "province",
    latitude: "41.12586420",
    longitude: "1.20356420",
  },
  {
    id: 5111,
    name: "Teruel",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "TE",
    type: "province",
    latitude: "40.34504100",
    longitude: "-1.11847440",
  },
  {
    id: 1205,
    name: "Toledo",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "TO",
    type: "province",
    latitude: "39.86232000",
    longitude: "-4.06946920",
  },
  {
    id: 1175,
    name: "Valencia",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "V",
    type: "province",
    latitude: "39.48401080",
    longitude: "-0.75328090",
  },
  {
    id: 1183,
    name: "Valladolid",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "VA",
    type: "province",
    latitude: "41.65173750",
    longitude: "-4.72449500",
  },
  {
    id: 1161,
    name: "Zamora",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "ZA",
    type: "province",
    latitude: "41.60957440",
    longitude: "-5.89871390",
  },
  {
    id: 5113,
    name: "Zaragoza",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    state_code: "Z",
    type: "province",
    latitude: "41.65175010",
    longitude: "-0.93000020",
  },
  {
    id: 2799,
    name: "Ampara District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "52",
    type: null,
    latitude: "7.29116850",
    longitude: "81.67237610",
  },
  {
    id: 2816,
    name: "Anuradhapura District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "71",
    type: null,
    latitude: "8.33183050",
    longitude: "80.40290170",
  },
  {
    id: 2790,
    name: "Badulla District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "81",
    type: null,
    latitude: "6.99340090",
    longitude: "81.05498150",
  },
  {
    id: 2818,
    name: "Batticaloa District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "51",
    type: null,
    latitude: "7.82927810",
    longitude: "81.47183870",
  },
  {
    id: 2798,
    name: "Central Province",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "2",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2815,
    name: "Colombo District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "11",
    type: null,
    latitude: "6.92695570",
    longitude: "79.86173060",
  },
  {
    id: 2808,
    name: "Eastern Province",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "5",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2792,
    name: "Galle District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "31",
    type: null,
    latitude: "6.05774900",
    longitude: "80.21755720",
  },
  {
    id: 2804,
    name: "Gampaha District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "12",
    type: null,
    latitude: "7.07126190",
    longitude: "80.00877460",
  },
  {
    id: 2791,
    name: "Hambantota District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "33",
    type: null,
    latitude: "6.15358160",
    longitude: "81.12714900",
  },
  {
    id: 2787,
    name: "Jaffna District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "41",
    type: null,
    latitude: "9.69304680",
    longitude: "80.16518540",
  },
  {
    id: 2789,
    name: "Kalutara District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "13",
    type: null,
    latitude: "6.60846860",
    longitude: "80.14285840",
  },
  {
    id: 2788,
    name: "Kandy District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "21",
    type: null,
    latitude: "7.29315880",
    longitude: "80.63501070",
  },
  {
    id: 2797,
    name: "Kegalle District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "92",
    type: null,
    latitude: "7.12040530",
    longitude: "80.32131060",
  },
  {
    id: 2793,
    name: "Kilinochchi District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "42",
    type: null,
    latitude: "9.36779710",
    longitude: "80.32131060",
  },
  {
    id: 2805,
    name: "Mannar District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "43",
    type: null,
    latitude: "8.98095310",
    longitude: "79.90439750",
  },
  {
    id: 2810,
    name: "Matale District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "22",
    type: null,
    latitude: "7.46596460",
    longitude: "80.62342590",
  },
  {
    id: 2806,
    name: "Matara District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "32",
    type: null,
    latitude: "5.94493480",
    longitude: "80.54879970",
  },
  {
    id: 2819,
    name: "Monaragala District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "82",
    type: null,
    latitude: "6.87277810",
    longitude: "81.35068320",
  },
  {
    id: 2814,
    name: "Mullaitivu District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "45",
    type: null,
    latitude: "9.26753880",
    longitude: "80.81282540",
  },
  {
    id: 2800,
    name: "North Central Province",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "7",
    type: null,
    latitude: "8.19956380",
    longitude: "80.63269160",
  },
  {
    id: 2817,
    name: "North Western Province",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "6",
    type: null,
    latitude: "7.75840910",
    longitude: "80.18750650",
  },
  {
    id: 2813,
    name: "Northern Province",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "4",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2794,
    name: "Nuwara Eliya District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "23",
    type: null,
    latitude: "6.96065320",
    longitude: "80.76927580",
  },
  {
    id: 2812,
    name: "Polonnaruwa District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "72",
    type: null,
    latitude: "7.93955670",
    longitude: "81.00034030",
  },
  {
    id: 2796,
    name: "Puttalam District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "62",
    type: null,
    latitude: "8.02599150",
    longitude: "79.84712720",
  },
  {
    id: 2807,
    name: "Ratnapura district",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "91",
    type: null,
    latitude: "6.70551680",
    longitude: "80.38483890",
  },
  {
    id: 2803,
    name: "Sabaragamuwa Province",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "9",
    type: null,
    latitude: "6.73959410",
    longitude: "80.36586500",
  },
  {
    id: 2801,
    name: "Southern Province",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "3",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 2795,
    name: "Trincomalee District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "53",
    type: null,
    latitude: "8.60130690",
    longitude: "81.11960750",
  },
  {
    id: 2811,
    name: "Uva Province",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "8",
    type: null,
    latitude: "6.84276120",
    longitude: "81.33994140",
  },
  {
    id: 2809,
    name: "Vavuniya District",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "44",
    type: null,
    latitude: "8.75947390",
    longitude: "80.50003340",
  },
  {
    id: 2802,
    name: "Western Province",
    country_id: 208,
    country_code: "LK",
    country_name: "Sri Lanka",
    state_code: "1",
    type: null,
    latitude: null,
    longitude: null,
  },
  {
    id: 885,
    name: "Al Jazirah",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "GZ",
    type: null,
    latitude: "14.88596110",
    longitude: "33.43835300",
  },
  {
    id: 886,
    name: "Al Qadarif",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "GD",
    type: null,
    latitude: "14.02430700",
    longitude: "35.36856790",
  },
  {
    id: 887,
    name: "Blue Nile",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "NB",
    type: null,
    latitude: "47.59867300",
    longitude: "-122.33441900",
  },
  {
    id: 896,
    name: "Central Darfur",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "DC",
    type: null,
    latitude: "14.37827470",
    longitude: "24.90422080",
  },
  {
    id: 892,
    name: "East Darfur",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "DE",
    type: null,
    latitude: "14.37827470",
    longitude: "24.90422080",
  },
  {
    id: 884,
    name: "Kassala",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "KA",
    type: null,
    latitude: "15.45813320",
    longitude: "36.40396290",
  },
  {
    id: 881,
    name: "Khartoum",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "KH",
    type: null,
    latitude: "15.50065440",
    longitude: "32.55989940",
  },
  {
    id: 890,
    name: "North Darfur",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "DN",
    type: null,
    latitude: "15.76619690",
    longitude: "24.90422080",
  },
  {
    id: 893,
    name: "North Kordofan",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "KN",
    type: null,
    latitude: "13.83064410",
    longitude: "29.41793240",
  },
  {
    id: 895,
    name: "Northern",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "NO",
    type: null,
    latitude: "38.06381700",
    longitude: "-84.46286480",
  },
  {
    id: 880,
    name: "Red Sea",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "RS",
    type: null,
    latitude: "20.28023200",
    longitude: "38.51257300",
  },
  {
    id: 891,
    name: "River Nile",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "NR",
    type: null,
    latitude: "23.97275950",
    longitude: "32.87492060",
  },
  {
    id: 882,
    name: "Sennar",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "SI",
    type: null,
    latitude: "13.56746900",
    longitude: "33.56720450",
  },
  {
    id: 894,
    name: "South Darfur",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "DS",
    type: null,
    latitude: "11.64886390",
    longitude: "24.90422080",
  },
  {
    id: 883,
    name: "South Kordofan",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "KS",
    type: null,
    latitude: "11.19901920",
    longitude: "29.41793240",
  },
  {
    id: 888,
    name: "West Darfur",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "DW",
    type: null,
    latitude: "12.84635610",
    longitude: "23.00119890",
  },
  {
    id: 889,
    name: "West Kordofan",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "GK",
    type: null,
    latitude: "11.19901920",
    longitude: "29.41793240",
  },
  {
    id: 879,
    name: "White Nile",
    country_id: 209,
    country_code: "SD",
    country_name: "Sudan",
    state_code: "NW",
    type: null,
    latitude: "9.33215160",
    longitude: "31.46153000",
  },
  {
    id: 2846,
    name: "Brokopondo District",
    country_id: 210,
    country_code: "SR",
    country_name: "Suriname",
    state_code: "BR",
    type: null,
    latitude: "4.77102470",
    longitude: "-55.04933750",
  },
  {
    id: 2839,
    name: "Commewijne District",
    country_id: 210,
    country_code: "SR",
    country_name: "Suriname",
    state_code: "CM",
    type: null,
    latitude: "5.74021100",
    longitude: "-54.87312190",
  },
  {
    id: 2842,
    name: "Coronie District",
    country_id: 210,
    country_code: "SR",
    country_name: "Suriname",
    state_code: "CR",
    type: null,
    latitude: "5.69432710",
    longitude: "-56.29293810",
  },
  {
    id: 2845,
    name: "Marowijne District",
    country_id: 210,
    country_code: "SR",
    country_name: "Suriname",
    state_code: "MA",
    type: null,
    latitude: "5.62681280",
    longitude: "-54.25931180",
  },
  {
    id: 2840,
    name: "Nickerie District",
    country_id: 210,
    country_code: "SR",
    country_name: "Suriname",
    state_code: "NI",
    type: null,
    latitude: "5.58554690",
    longitude: "-56.83111170",
  },
  {
    id: 2841,
    name: "Para District",
    country_id: 210,
    country_code: "SR",
    country_name: "Suriname",
    state_code: "PR",
    type: null,
    latitude: "5.48173180",
    longitude: "-55.22592070",
  },
  {
    id: 2843,
    name: "Paramaribo District",
    country_id: 210,
    country_code: "SR",
    country_name: "Suriname",
    state_code: "PM",
    type: null,
    latitude: "5.85203550",
    longitude: "-55.20382780",
  },
  {
    id: 2848,
    name: "Saramacca District",
    country_id: 210,
    country_code: "SR",
    country_name: "Suriname",
    state_code: "SA",
    type: null,
    latitude: "5.72408130",
    longitude: "-55.66896360",
  },
  {
    id: 2847,
    name: "Sipaliwini District",
    country_id: 210,
    country_code: "SR",
    country_name: "Suriname",
    state_code: "SI",
    type: null,
    latitude: "3.65673820",
    longitude: "-56.20353870",
  },
  {
    id: 2844,
    name: "Wanica District",
    country_id: 210,
    country_code: "SR",
    country_name: "Suriname",
    state_code: "WA",
    type: null,
    latitude: "5.73237620",
    longitude: "-55.27012350",
  },
  {
    id: 969,
    name: "Hhohho District",
    country_id: 212,
    country_code: "SZ",
    country_name: "Swaziland",
    state_code: "HH",
    type: null,
    latitude: "-26.13656620",
    longitude: "31.35416310",
  },
  {
    id: 970,
    name: "Lubombo District",
    country_id: 212,
    country_code: "SZ",
    country_name: "Swaziland",
    state_code: "LU",
    type: null,
    latitude: "-26.78517730",
    longitude: "31.81070790",
  },
  {
    id: 968,
    name: "Manzini District",
    country_id: 212,
    country_code: "SZ",
    country_name: "Swaziland",
    state_code: "MA",
    type: null,
    latitude: "-26.50819990",
    longitude: "31.37131640",
  },
  {
    id: 971,
    name: "Shiselweni District",
    country_id: 212,
    country_code: "SZ",
    country_name: "Swaziland",
    state_code: "SH",
    type: null,
    latitude: "-26.98275770",
    longitude: "31.35416310",
  },
  {
    id: 1537,
    name: "Blekinge",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "K",
    type: null,
    latitude: "56.27838370",
    longitude: "15.01800580",
  },
  {
    id: 1534,
    name: "Dalarna County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "W",
    type: null,
    latitude: "61.09170120",
    longitude: "14.66636530",
  },
  {
    id: 1533,
    name: "Gävleborg County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "X",
    type: null,
    latitude: "61.30119930",
    longitude: "16.15342140",
  },
  {
    id: 1546,
    name: "Gotland County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "I",
    type: null,
    latitude: "57.46841210",
    longitude: "18.48674470",
  },
  {
    id: 1548,
    name: "Halland County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "N",
    type: null,
    latitude: "56.89668050",
    longitude: "12.80339930",
  },
  {
    id: 5117,
    name: "Jämtland County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "0",
    type: "SE-Z",
    latitude: "63.28306200",
    longitude: "14.23828100",
  },
  {
    id: 1550,
    name: "Jönköping County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "F",
    type: null,
    latitude: "57.37084340",
    longitude: "14.34391740",
  },
  {
    id: 1544,
    name: "Kalmar County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "H",
    type: null,
    latitude: "57.23501560",
    longitude: "16.18493490",
  },
  {
    id: 1542,
    name: "Kronoberg County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "G",
    type: null,
    latitude: "56.71834030",
    longitude: "14.41146730",
  },
  {
    id: 1538,
    name: "Norrbotten County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "BD",
    type: null,
    latitude: "66.83092160",
    longitude: "20.39919660",
  },
  {
    id: 1539,
    name: "Örebro County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "T",
    type: null,
    latitude: "59.53503600",
    longitude: "15.00657310",
  },
  {
    id: 1536,
    name: "Östergötland County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "E",
    type: null,
    latitude: "58.34536350",
    longitude: "15.51978440",
  },
  {
    id: 1541,
    name: "Skåne County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "M",
    type: null,
    latitude: "55.99025720",
    longitude: "13.59576920",
  },
  {
    id: 1540,
    name: "Södermanland County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "D",
    type: null,
    latitude: "59.03363490",
    longitude: "16.75188990",
  },
  {
    id: 1551,
    name: "Stockholm County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "AB",
    type: null,
    latitude: "59.60249580",
    longitude: "18.13843830",
  },
  {
    id: 1545,
    name: "Uppsala County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "C",
    type: null,
    latitude: "60.00922620",
    longitude: "17.27145880",
  },
  {
    id: 1535,
    name: "Värmland County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "S",
    type: null,
    latitude: "59.72940650",
    longitude: "13.23540240",
  },
  {
    id: 1543,
    name: "Västerbotten County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "AC",
    type: null,
    latitude: "65.33373110",
    longitude: "16.51616940",
  },
  {
    id: 1552,
    name: "Västernorrland County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "Y",
    type: null,
    latitude: "63.42764730",
    longitude: "17.72924440",
  },
  {
    id: 1549,
    name: "Västmanland County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "U",
    type: null,
    latitude: "59.67138790",
    longitude: "16.21589530",
  },
  {
    id: 1547,
    name: "Västra Götaland County",
    country_id: 213,
    country_code: "SE",
    country_name: "Sweden",
    state_code: "O",
    type: null,
    latitude: "58.25279260",
    longitude: "13.05964250",
  },
  {
    id: 1639,
    name: "Aargau",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "AG",
    type: "canton",
    latitude: "47.38766640",
    longitude: "8.25542950",
  },
  {
    id: 1655,
    name: "Appenzell Ausserrhoden",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "AR",
    type: "canton",
    latitude: "47.36648100",
    longitude: "9.30009160",
  },
  {
    id: 1649,
    name: "Appenzell Innerrhoden",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "AI",
    type: "canton",
    latitude: "47.31619250",
    longitude: "9.43165730",
  },
  {
    id: 1641,
    name: "Basel-Land",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "BL",
    type: "canton",
    latitude: "47.44181220",
    longitude: "7.76440020",
  },
  {
    id: 4957,
    name: "Basel-Stadt",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "BS",
    type: "canton",
    latitude: "47.56666700",
    longitude: "7.60000000",
  },
  {
    id: 1645,
    name: "Bern",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "BE",
    type: "canton",
    latitude: "46.79886210",
    longitude: "7.70807010",
  },
  {
    id: 1640,
    name: "Fribourg",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "FR",
    type: "canton",
    latitude: "46.68167480",
    longitude: "7.11726350",
  },
  {
    id: 1647,
    name: "Geneva",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "GE",
    type: "canton",
    latitude: "46.21800730",
    longitude: "6.12169250",
  },
  {
    id: 1661,
    name: "Glarus",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "GL",
    type: "canton",
    latitude: "47.04112320",
    longitude: "9.06790000",
  },
  {
    id: 1660,
    name: "Graubünden",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "GR",
    type: "canton",
    latitude: "46.65698710",
    longitude: "9.57802570",
  },
  {
    id: 1658,
    name: "Jura",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "JU",
    type: "canton",
    latitude: "47.34444740",
    longitude: "7.14306080",
  },
  {
    id: 1663,
    name: "Lucerne",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "LU",
    type: "canton",
    latitude: "47.07956710",
    longitude: "8.16624450",
  },
  {
    id: 1659,
    name: "Neuchâtel",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "NE",
    type: "canton",
    latitude: "46.98998740",
    longitude: "6.92927320",
  },
  {
    id: 1652,
    name: "Nidwalden",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "NW",
    type: "canton",
    latitude: "46.92670160",
    longitude: "8.38499820",
  },
  {
    id: 1650,
    name: "Obwalden",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "OW",
    type: "canton",
    latitude: "46.87785800",
    longitude: "8.25124900",
  },
  {
    id: 1654,
    name: "Schaffhausen",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "SH",
    type: "canton",
    latitude: "47.70093640",
    longitude: "8.56800400",
  },
  {
    id: 1653,
    name: "Schwyz",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "SZ",
    type: "canton",
    latitude: "47.02071380",
    longitude: "8.65298840",
  },
  {
    id: 1662,
    name: "Solothurn",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "SO",
    type: "canton",
    latitude: "47.33207170",
    longitude: "7.63883850",
  },
  {
    id: 1644,
    name: "St. Gallen",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "SG",
    type: "canton",
    latitude: "47.14562540",
    longitude: "9.35043320",
  },
  {
    id: 1657,
    name: "Thurgau",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "TG",
    type: "canton",
    latitude: "47.60378560",
    longitude: "9.05573710",
  },
  {
    id: 1643,
    name: "Ticino",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "TI",
    type: "canton",
    latitude: "46.33173400",
    longitude: "8.80045290",
  },
  {
    id: 1642,
    name: "Uri",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "UR",
    type: "canton",
    latitude: "41.48606470",
    longitude: "-71.53085370",
  },
  {
    id: 1648,
    name: "Valais",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "VS",
    type: "canton",
    latitude: "46.19046140",
    longitude: "7.54492260",
  },
  {
    id: 1651,
    name: "Vaud",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "VD",
    type: "canton",
    latitude: "46.56131350",
    longitude: "6.53676500",
  },
  {
    id: 1646,
    name: "Zug",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "ZG",
    type: "canton",
    latitude: "47.16615050",
    longitude: "8.51547490",
  },
  {
    id: 1656,
    name: "Zürich",
    country_id: 214,
    country_code: "CH",
    country_name: "Switzerland",
    state_code: "ZH",
    type: "canton",
    latitude: "47.35953600",
    longitude: "8.63564520",
  },
  {
    id: 2941,
    name: "Al-Hasakah",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "HA",
    type: null,
    latitude: "36.40551500",
    longitude: "40.79691490",
  },
  {
    id: 2944,
    name: "Al-Raqqah",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "RA",
    type: null,
    latitude: "35.95941060",
    longitude: "38.99810520",
  },
  {
    id: 2946,
    name: "Aleppo",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "HL",
    type: null,
    latitude: "36.22623930",
    longitude: "37.46813960",
  },
  {
    id: 2936,
    name: "As-Suwayda",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "SU",
    type: null,
    latitude: "32.79891560",
    longitude: "36.78195050",
  },
  {
    id: 2939,
    name: "Damascus",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "DI",
    type: null,
    latitude: "33.51514440",
    longitude: "36.39313540",
  },
  {
    id: 2945,
    name: "Daraa",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "DR",
    type: null,
    latitude: "32.92488130",
    longitude: "36.17626150",
  },
  {
    id: 2937,
    name: "Deir ez-Zor",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "DY",
    type: null,
    latitude: "35.28797980",
    longitude: "40.30886260",
  },
  {
    id: 2934,
    name: "Hama",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "HM",
    type: null,
    latitude: "35.18878650",
    longitude: "37.21158290",
  },
  {
    id: 2942,
    name: "Homs",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "HI",
    type: null,
    latitude: "34.25671230",
    longitude: "38.31657250",
  },
  {
    id: 2940,
    name: "Idlib",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "ID",
    type: null,
    latitude: "35.82687980",
    longitude: "36.69572160",
  },
  {
    id: 2938,
    name: "Latakia",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "LA",
    type: null,
    latitude: "35.61297910",
    longitude: "36.00232250",
  },
  {
    id: 2943,
    name: "Quneitra",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "QU",
    type: null,
    latitude: "33.07763180",
    longitude: "35.89341360",
  },
  {
    id: 2935,
    name: "Rif Dimashq",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "RD",
    type: null,
    latitude: "33.51672890",
    longitude: "36.95410700",
  },
  {
    id: 2947,
    name: "Tartus",
    country_id: 215,
    country_code: "SY",
    country_name: "Syria",
    state_code: "TA",
    type: null,
    latitude: "35.00066520",
    longitude: "36.00232250",
  },
  {
    id: 3404,
    name: "Changhua",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "CHA",
    type: "county",
    latitude: "24.05179630",
    longitude: "120.51613520",
  },
  {
    id: 3408,
    name: "Chiayi",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "CYI",
    type: "city",
    latitude: "23.45184280",
    longitude: "120.25546150",
  },
  {
    id: 3418,
    name: "Chiayi",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "CYQ",
    type: "county",
    latitude: "23.48007510",
    longitude: "120.44911130",
  },
  {
    id: 3423,
    name: "Hsinchu",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "HSQ",
    type: "county",
    latitude: "24.83872260",
    longitude: "121.01772460",
  },
  {
    id: 3417,
    name: "Hsinchu",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "HSZ",
    type: "city",
    latitude: "24.81382870",
    longitude: "120.96747980",
  },
  {
    id: 3411,
    name: "Hualien",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "HUA",
    type: "county",
    latitude: "23.98715890",
    longitude: "121.60157140",
  },
  {
    id: 3412,
    name: "Kaohsiung",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "KHH",
    type: "special municipality",
    latitude: "22.62727840",
    longitude: "120.30143530",
  },
  {
    id: 4965,
    name: "Keelung",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "KEE",
    type: "city",
    latitude: "25.12418620",
    longitude: "121.64758340",
  },
  {
    id: 3415,
    name: "Kinmen",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "KIN",
    type: "county",
    latitude: "24.34877920",
    longitude: "118.32856440",
  },
  {
    id: 3420,
    name: "Lienchiang",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "LIE",
    type: "county",
    latitude: "26.15055560",
    longitude: "119.92888890",
  },
  {
    id: 3413,
    name: "Miaoli",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "MIA",
    type: "county",
    latitude: "24.56015900",
    longitude: "120.82142650",
  },
  {
    id: 3407,
    name: "Nantou",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "NAN",
    type: "county",
    latitude: "23.96099810",
    longitude: "120.97186380",
  },
  {
    id: 4966,
    name: "New Taipei",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "NWT",
    type: "special municipality",
    latitude: "24.98752780",
    longitude: "121.36459470",
  },
  {
    id: 3403,
    name: "Penghu",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "PEN",
    type: "county",
    latitude: "23.57118990",
    longitude: "119.57931570",
  },
  {
    id: 3405,
    name: "Pingtung",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "PIF",
    type: "county",
    latitude: "22.55197590",
    longitude: "120.54875970",
  },
  {
    id: 3406,
    name: "Taichung",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "TXG",
    type: "special municipality",
    latitude: "24.14773580",
    longitude: "120.67364820",
  },
  {
    id: 3421,
    name: "Tainan",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "TNN",
    type: "special municipality",
    latitude: "22.99972810",
    longitude: "120.22702770",
  },
  {
    id: 3422,
    name: "Taipei",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "TPE",
    type: "special municipality",
    latitude: "25.03296940",
    longitude: "121.56541770",
  },
  {
    id: 3410,
    name: "Taitung",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "TTT",
    type: "county",
    latitude: "22.79724470",
    longitude: "121.07137020",
  },
  {
    id: 3419,
    name: "Taoyuan",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "TAO",
    type: "special municipality",
    latitude: "24.99362810",
    longitude: "121.30097980",
  },
  {
    id: 3402,
    name: "Yilan",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "ILA",
    type: "county",
    latitude: "24.70210730",
    longitude: "121.73775020",
  },
  {
    id: 3416,
    name: "Yunlin",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    state_code: "YUN",
    type: "county",
    latitude: "23.70920330",
    longitude: "120.43133730",
  },
  {
    id: 3397,
    name: "districts of Republican Subordination",
    country_id: 217,
    country_code: "TJ",
    country_name: "Tajikistan",
    state_code: "RA",
    type: null,
    latitude: "39.08579020",
    longitude: "70.24083250",
  },
  {
    id: 3399,
    name: "Gorno-Badakhshan Autonomous Province",
    country_id: 217,
    country_code: "TJ",
    country_name: "Tajikistan",
    state_code: "GB",
    type: null,
    latitude: "38.41273200",
    longitude: "73.08774900",
  },
  {
    id: 3398,
    name: "Khatlon Province",
    country_id: 217,
    country_code: "TJ",
    country_name: "Tajikistan",
    state_code: "KT",
    type: null,
    latitude: "37.91135620",
    longitude: "69.09702300",
  },
  {
    id: 3400,
    name: "Sughd Province",
    country_id: 217,
    country_code: "TJ",
    country_name: "Tajikistan",
    state_code: "SU",
    type: null,
    latitude: "39.51553260",
    longitude: "69.09702300",
  },
  {
    id: 1491,
    name: "Arusha",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "01",
    type: "Region",
    latitude: "-3.38692540",
    longitude: "36.68299270",
  },
  {
    id: 1490,
    name: "Dar es Salaam",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "02",
    type: "Region",
    latitude: "-6.79235400",
    longitude: "39.20832840",
  },
  {
    id: 1466,
    name: "Dodoma",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "03",
    type: "Region",
    latitude: "-6.57382280",
    longitude: "36.26308460",
  },
  {
    id: 1481,
    name: "Geita",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "27",
    type: "Region",
    latitude: "-2.82422570",
    longitude: "32.26538870",
  },
  {
    id: 1489,
    name: "Iringa",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "04",
    type: "Region",
    latitude: "-7.78874420",
    longitude: "35.56578620",
  },
  {
    id: 1465,
    name: "Kagera",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "05",
    type: "Region",
    latitude: "-1.30011150",
    longitude: "31.26263660",
  },
  {
    id: 1482,
    name: "Katavi",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "28",
    type: "Region",
    latitude: "-6.36771250",
    longitude: "31.26263660",
  },
  {
    id: 1478,
    name: "Kigoma",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "08",
    type: "Region",
    latitude: "-4.88240920",
    longitude: "29.66150550",
  },
  {
    id: 1467,
    name: "Kilimanjaro",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "09",
    type: "Region",
    latitude: "-4.13369270",
    longitude: "37.80876930",
  },
  {
    id: 1483,
    name: "Lindi",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "12",
    type: "Region",
    latitude: "-9.23433940",
    longitude: "38.31657250",
  },
  {
    id: 1484,
    name: "Manyara",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "26",
    type: "Region",
    latitude: "-4.31500580",
    longitude: "36.95410700",
  },
  {
    id: 1468,
    name: "Mara",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "13",
    type: "Region",
    latitude: "-1.77535380",
    longitude: "34.15319470",
  },
  {
    id: 4955,
    name: "Mbeya",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "14",
    type: "Region",
    latitude: "-8.28661120",
    longitude: "32.81325370",
  },
  {
    id: 1470,
    name: "Morogoro",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "16",
    type: "Region",
    latitude: "-8.81371730",
    longitude: "36.95410700",
  },
  {
    id: 1476,
    name: "Mtwara",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "17",
    type: "Region",
    latitude: "-10.33984550",
    longitude: "40.16574660",
  },
  {
    id: 1479,
    name: "Mwanza",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "18",
    type: "Region",
    latitude: "-2.46711970",
    longitude: "32.89868120",
  },
  {
    id: 1480,
    name: "Njombe",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "29",
    type: "Region",
    latitude: "-9.24226320",
    longitude: "35.12687810",
  },
  {
    id: 1488,
    name: "Pemba North",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "06",
    type: "Region",
    latitude: "-5.03193520",
    longitude: "39.77555710",
  },
  {
    id: 1472,
    name: "Pemba South",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "10",
    type: "Region",
    latitude: "-5.31469610",
    longitude: "39.75495110",
  },
  {
    id: 1485,
    name: "Pwani",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "19",
    type: "Region",
    latitude: "-7.32377140",
    longitude: "38.82054540",
  },
  {
    id: 1477,
    name: "Rukwa",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "20",
    type: "Region",
    latitude: "-8.01094440",
    longitude: "31.44561790",
  },
  {
    id: 1486,
    name: "Ruvuma",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "21",
    type: "Region",
    latitude: "-10.68787170",
    longitude: "36.26308460",
  },
  {
    id: 1463,
    name: "Shinyanga",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "22",
    type: "Region",
    latitude: "-3.68099610",
    longitude: "33.42714030",
  },
  {
    id: 1464,
    name: "Simiyu",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "30",
    type: "Region",
    latitude: "-2.83087380",
    longitude: "34.15319470",
  },
  {
    id: 1474,
    name: "Singida",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "23",
    type: "Region",
    latitude: "-6.74533520",
    longitude: "34.15319470",
  },
  {
    id: 4956,
    name: "Songwe",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "31",
    type: "Region",
    latitude: "-8.27261200",
    longitude: "31.71131740",
  },
  {
    id: 1469,
    name: "Tabora",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "24",
    type: "Region",
    latitude: "-5.03421380",
    longitude: "32.80844960",
  },
  {
    id: 1487,
    name: "Tanga",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "25",
    type: "Region",
    latitude: "-5.30497890",
    longitude: "38.31657250",
  },
  {
    id: 1473,
    name: "Zanzibar North",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "07",
    type: "Region",
    latitude: "-5.93950930",
    longitude: "39.27910110",
  },
  {
    id: 1471,
    name: "Zanzibar South",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "11",
    type: "Region",
    latitude: "-6.26428510",
    longitude: "39.44502810",
  },
  {
    id: 1475,
    name: "Zanzibar West",
    country_id: 218,
    country_code: "TZ",
    country_name: "Tanzania",
    state_code: "15",
    type: "Region",
    latitude: "-6.22981360",
    longitude: "39.25832930",
  },
  {
    id: 3523,
    name: "Amnat Charoen",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "37",
    type: null,
    latitude: "15.86567830",
    longitude: "104.62577740",
  },
  {
    id: 3519,
    name: "Ang Thong",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "15",
    type: null,
    latitude: "14.58960540",
    longitude: "100.45505200",
  },
  {
    id: 3554,
    name: "Bangkok",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "10",
    type: null,
    latitude: "13.75633090",
    longitude: "100.50176510",
  },
  {
    id: 3533,
    name: "Bueng Kan",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "38",
    type: null,
    latitude: "18.36091040",
    longitude: "103.64644630",
  },
  {
    id: 3534,
    name: "Buri Ram",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "31",
    type: null,
    latitude: "14.99510030",
    longitude: "103.11159150",
  },
  {
    id: 3552,
    name: "Chachoengsao",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "24",
    type: null,
    latitude: "13.69041940",
    longitude: "101.07795960",
  },
  {
    id: 3522,
    name: "Chai Nat",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "18",
    type: null,
    latitude: "15.18519710",
    longitude: "100.12512500",
  },
  {
    id: 4954,
    name: "Chaiyaphum",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "36",
    type: null,
    latitude: "16.00749740",
    longitude: "101.61291720",
  },
  {
    id: 3486,
    name: "Chanthaburi",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "22",
    type: null,
    latitude: "12.61124850",
    longitude: "102.10378060",
  },
  {
    id: 3491,
    name: "Chiang Mai",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "50",
    type: null,
    latitude: "18.78834390",
    longitude: "98.98530080",
  },
  {
    id: 3498,
    name: "Chiang Rai",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "57",
    type: null,
    latitude: "19.91047980",
    longitude: "99.84057600",
  },
  {
    id: 3513,
    name: "Chon Buri",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "20",
    type: null,
    latitude: "13.36114310",
    longitude: "100.98467170",
  },
  {
    id: 3526,
    name: "Chumphon",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "86",
    type: null,
    latitude: "10.49304960",
    longitude: "99.18001990",
  },
  {
    id: 3550,
    name: "Kalasin",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "46",
    type: null,
    latitude: "16.43850800",
    longitude: "103.50609940",
  },
  {
    id: 3516,
    name: "Kamphaeng Phet",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "62",
    type: null,
    latitude: "16.48277980",
    longitude: "99.52266180",
  },
  {
    id: 3511,
    name: "Kanchanaburi",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "71",
    type: null,
    latitude: "14.10113930",
    longitude: "99.41794310",
  },
  {
    id: 3485,
    name: "Khon Kaen",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "40",
    type: null,
    latitude: "16.43219380",
    longitude: "102.82362140",
  },
  {
    id: 3478,
    name: "Krabi",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "81",
    type: null,
    latitude: "8.08629970",
    longitude: "98.90628350",
  },
  {
    id: 3544,
    name: "Lampang",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "52",
    type: null,
    latitude: "18.28553950",
    longitude: "99.51278950",
  },
  {
    id: 3483,
    name: "Lamphun",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "51",
    type: null,
    latitude: "18.57446060",
    longitude: "99.00872210",
  },
  {
    id: 3509,
    name: "Loei",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "42",
    type: null,
    latitude: "17.48602320",
    longitude: "101.72230020",
  },
  {
    id: 3543,
    name: "Lop Buri",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "16",
    type: null,
    latitude: "14.79950810",
    longitude: "100.65337060",
  },
  {
    id: 3505,
    name: "Mae Hong Son",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "58",
    type: null,
    latitude: "19.30202960",
    longitude: "97.96543680",
  },
  {
    id: 3517,
    name: "Maha Sarakham",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "44",
    type: null,
    latitude: "16.01320150",
    longitude: "103.16151690",
  },
  {
    id: 3546,
    name: "Mukdahan",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "49",
    type: null,
    latitude: "16.54359140",
    longitude: "104.70241210",
  },
  {
    id: 3535,
    name: "Nakhon Nayok",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "26",
    type: null,
    latitude: "14.20694660",
    longitude: "101.21305110",
  },
  {
    id: 3503,
    name: "Nakhon Pathom",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "73",
    type: null,
    latitude: "13.81402930",
    longitude: "100.03729290",
  },
  {
    id: 3548,
    name: "Nakhon Phanom",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "48",
    type: null,
    latitude: "17.39203900",
    longitude: "104.76955080",
  },
  {
    id: 3497,
    name: "Nakhon Ratchasima",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "30",
    type: null,
    latitude: "14.97384930",
    longitude: "102.08365200",
  },
  {
    id: 3492,
    name: "Nakhon Sawan",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "60",
    type: null,
    latitude: "15.69873820",
    longitude: "100.11996000",
  },
  {
    id: 3520,
    name: "Nakhon Si Thammarat",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "80",
    type: null,
    latitude: "8.43248310",
    longitude: "99.95990330",
  },
  {
    id: 3530,
    name: "Nan",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "55",
    type: null,
    latitude: "45.52220800",
    longitude: "-122.98632810",
  },
  {
    id: 3553,
    name: "Narathiwat",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "96",
    type: null,
    latitude: "6.42546070",
    longitude: "101.82531430",
  },
  {
    id: 3480,
    name: "Nong Bua Lam Phu",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "39",
    type: null,
    latitude: "17.22182470",
    longitude: "102.42603680",
  },
  {
    id: 3484,
    name: "Nong Khai",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "43",
    type: null,
    latitude: "17.87828030",
    longitude: "102.74126380",
  },
  {
    id: 3495,
    name: "Nonthaburi",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "12",
    type: null,
    latitude: "13.85910840",
    longitude: "100.52165080",
  },
  {
    id: 3500,
    name: "Pathum Thani",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "13",
    type: null,
    latitude: "14.02083910",
    longitude: "100.52502760",
  },
  {
    id: 3540,
    name: "Pattani",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "94",
    type: null,
    latitude: "6.76183080",
    longitude: "101.32325490",
  },
  {
    id: 3507,
    name: "Pattaya",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "S",
    type: null,
    latitude: "12.92355570",
    longitude: "100.88245510",
  },
  {
    id: 3549,
    name: "Phangnga",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "82",
    type: null,
    latitude: "8.45014140",
    longitude: "98.52553170",
  },
  {
    id: 3488,
    name: "Phatthalung",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "93",
    type: null,
    latitude: "7.61668230",
    longitude: "100.07402310",
  },
  {
    id: 3538,
    name: "Phayao",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "56",
    type: null,
    latitude: "19.21543670",
    longitude: "100.20236920",
  },
  {
    id: 3515,
    name: "Phetchabun",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "67",
    type: null,
    latitude: "16.30166900",
    longitude: "101.11928040",
  },
  {
    id: 3532,
    name: "Phetchaburi",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "76",
    type: null,
    latitude: "12.96492150",
    longitude: "99.64258830",
  },
  {
    id: 3514,
    name: "Phichit",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "66",
    type: null,
    latitude: "16.27408760",
    longitude: "100.33469910",
  },
  {
    id: 3506,
    name: "Phitsanulok",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "65",
    type: null,
    latitude: "16.82112380",
    longitude: "100.26585160",
  },
  {
    id: 3494,
    name: "Phra Nakhon Si Ayutthaya",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "14",
    type: null,
    latitude: "14.36923250",
    longitude: "100.58766340",
  },
  {
    id: 3528,
    name: "Phrae",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "54",
    type: null,
    latitude: "18.14457740",
    longitude: "100.14028310",
  },
  {
    id: 3536,
    name: "Phuket",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "83",
    type: null,
    latitude: "7.88044790",
    longitude: "98.39225040",
  },
  {
    id: 3542,
    name: "Prachin Buri",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "25",
    type: null,
    latitude: "14.04206990",
    longitude: "101.66008740",
  },
  {
    id: 3508,
    name: "Prachuap Khiri Khan",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "77",
    type: null,
    latitude: "11.79383890",
    longitude: "99.79575640",
  },
  {
    id: 3479,
    name: "Ranong",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "85",
    type: null,
    latitude: "9.95287020",
    longitude: "98.60846410",
  },
  {
    id: 3499,
    name: "Ratchaburi",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "70",
    type: null,
    latitude: "13.52828930",
    longitude: "99.81342110",
  },
  {
    id: 3518,
    name: "Rayong",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "21",
    type: null,
    latitude: "12.68139570",
    longitude: "101.28162610",
  },
  {
    id: 3510,
    name: "Roi Et",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "45",
    type: null,
    latitude: "16.05381960",
    longitude: "103.65200360",
  },
  {
    id: 3529,
    name: "Sa Kaeo",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "27",
    type: null,
    latitude: "13.82403800",
    longitude: "102.06458390",
  },
  {
    id: 3501,
    name: "Sakon Nakhon",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "47",
    type: null,
    latitude: "17.16642110",
    longitude: "104.14860550",
  },
  {
    id: 3481,
    name: "Samut Prakan",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "11",
    type: null,
    latitude: "13.59909610",
    longitude: "100.59983190",
  },
  {
    id: 3504,
    name: "Samut Sakhon",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "74",
    type: null,
    latitude: "13.54752160",
    longitude: "100.27439560",
  },
  {
    id: 3502,
    name: "Samut Songkhram",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "75",
    type: null,
    latitude: "13.40982170",
    longitude: "100.00226450",
  },
  {
    id: 3487,
    name: "Saraburi",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "19",
    type: null,
    latitude: "14.52891540",
    longitude: "100.91014210",
  },
  {
    id: 3537,
    name: "Satun",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "91",
    type: null,
    latitude: "6.62381580",
    longitude: "100.06737440",
  },
  {
    id: 3547,
    name: "Si Sa Ket",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "33",
    type: null,
    latitude: "15.11860090",
    longitude: "104.32200950",
  },
  {
    id: 3490,
    name: "Sing Buri",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "17",
    type: null,
    latitude: "14.89362530",
    longitude: "100.39673140",
  },
  {
    id: 3539,
    name: "Songkhla",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "90",
    type: null,
    latitude: "7.18976590",
    longitude: "100.59538130",
  },
  {
    id: 3545,
    name: "Sukhothai",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "64",
    type: null,
    latitude: "43.64855560",
    longitude: "-79.37466390",
  },
  {
    id: 3524,
    name: "Suphan Buri",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "72",
    type: null,
    latitude: "14.47448920",
    longitude: "100.11771280",
  },
  {
    id: 3482,
    name: "Surat Thani",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "84",
    type: null,
    latitude: "9.13419490",
    longitude: "99.33341980",
  },
  {
    id: 3531,
    name: "Surin",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "32",
    type: null,
    latitude: "37.03582710",
    longitude: "-95.62763670",
  },
  {
    id: 3525,
    name: "Tak",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "63",
    type: null,
    latitude: "45.02996460",
    longitude: "-93.10498150",
  },
  {
    id: 3541,
    name: "Trang",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "92",
    type: null,
    latitude: "7.56448330",
    longitude: "99.62393340",
  },
  {
    id: 3496,
    name: "Trat",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "23",
    type: null,
    latitude: "12.24275630",
    longitude: "102.51747340",
  },
  {
    id: 3512,
    name: "Ubon Ratchathani",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "34",
    type: null,
    latitude: "15.24484530",
    longitude: "104.84729950",
  },
  {
    id: 3527,
    name: "Udon Thani",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "41",
    type: null,
    latitude: "17.36469690",
    longitude: "102.81589240",
  },
  {
    id: 3551,
    name: "Uthai Thani",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "61",
    type: null,
    latitude: "15.38350010",
    longitude: "100.02455270",
  },
  {
    id: 3489,
    name: "Uttaradit",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "53",
    type: null,
    latitude: "17.62008860",
    longitude: "100.09929420",
  },
  {
    id: 3493,
    name: "Yala",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "95",
    type: null,
    latitude: "44.05791170",
    longitude: "-123.16538480",
  },
  {
    id: 3521,
    name: "Yasothon",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    state_code: "35",
    type: null,
    latitude: "15.79264100",
    longitude: "104.14528270",
  },
  {
    id: 3601,
    name: "Acklins",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "AK",
    type: null,
    latitude: "22.36577080",
    longitude: "-74.05351260",
  },
  {
    id: 3628,
    name: "Acklins and Crooked Islands",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "AC",
    type: null,
    latitude: "22.36577080",
    longitude: "-74.05351260",
  },
  {
    id: 3593,
    name: "Berry Islands",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "BY",
    type: null,
    latitude: "25.62500420",
    longitude: "-77.82522030",
  },
  {
    id: 3629,
    name: "Bimini",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "BI",
    type: null,
    latitude: "24.64153250",
    longitude: "-79.85062260",
  },
  {
    id: 3605,
    name: "Black Point",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "BP",
    type: null,
    latitude: "41.39510240",
    longitude: "-71.46505560",
  },
  {
    id: 3611,
    name: "Cat Island",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "CI",
    type: null,
    latitude: "30.22801360",
    longitude: "-89.10149330",
  },
  {
    id: 3603,
    name: "Central Abaco",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "CO",
    type: null,
    latitude: "26.35550290",
    longitude: "-77.14851630",
  },
  {
    id: 3631,
    name: "Central Andros",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "CS",
    type: null,
    latitude: "24.46884820",
    longitude: "-77.97386500",
  },
  {
    id: 3596,
    name: "Central Eleuthera",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "CE",
    type: null,
    latitude: "25.13620370",
    longitude: "-76.14359150",
  },
  {
    id: 3621,
    name: "Crooked Island",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "CK",
    type: null,
    latitude: "22.63909820",
    longitude: "-74.00650900",
  },
  {
    id: 3614,
    name: "East Grand Bahama",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "EG",
    type: null,
    latitude: "26.65828230",
    longitude: "-78.22482910",
  },
  {
    id: 3612,
    name: "Exuma",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "EX",
    type: null,
    latitude: "23.61925980",
    longitude: "-75.96954650",
  },
  {
    id: 3626,
    name: "Freeport",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "FP",
    type: null,
    latitude: "42.29668610",
    longitude: "-89.62122710",
  },
  {
    id: 3619,
    name: "Fresh Creek",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "FC",
    type: null,
    latitude: "40.65437560",
    longitude: "-73.89479390",
  },
  {
    id: 3597,
    name: "Governor's Harbour",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "GH",
    type: null,
    latitude: "25.19480960",
    longitude: "-76.24396220",
  },
  {
    id: 3632,
    name: "Grand Cay",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "GC",
    type: null,
    latitude: "27.21626150",
    longitude: "-78.32305590",
  },
  {
    id: 3595,
    name: "Green Turtle Cay",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "GT",
    type: null,
    latitude: "26.77471070",
    longitude: "-77.32957080",
  },
  {
    id: 3613,
    name: "Harbour Island",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "HI",
    type: null,
    latitude: "25.50011000",
    longitude: "-76.63405110",
  },
  {
    id: 3598,
    name: "High Rock",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "HR",
    type: null,
    latitude: "46.68434150",
    longitude: "-121.90174610",
  },
  {
    id: 3624,
    name: "Hope Town",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "HT",
    type: null,
    latitude: "26.50095040",
    longitude: "-76.99598720",
  },
  {
    id: 3609,
    name: "Inagua",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "IN",
    type: null,
    latitude: "21.06560660",
    longitude: "-73.32370800",
  },
  {
    id: 3618,
    name: "Kemps Bay",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "KB",
    type: null,
    latitude: "24.02364000",
    longitude: "-77.54534900",
  },
  {
    id: 3610,
    name: "Long Island",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "LI",
    type: null,
    latitude: "40.78914200",
    longitude: "-73.13496100",
  },
  {
    id: 3625,
    name: "Mangrove Cay",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "MC",
    type: null,
    latitude: "24.14814250",
    longitude: "-77.76809520",
  },
  {
    id: 3604,
    name: "Marsh Harbour",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "MH",
    type: null,
    latitude: "26.52416530",
    longitude: "-77.09098090",
  },
  {
    id: 3633,
    name: "Mayaguana District",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "MG",
    type: null,
    latitude: "22.40177140",
    longitude: "-73.06413960",
  },
  {
    id: 4881,
    name: "New Providence",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "NP",
    type: null,
    latitude: "40.69843480",
    longitude: "-74.40154050",
  },
  {
    id: 3594,
    name: "Nichollstown and Berry Islands",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "NB",
    type: null,
    latitude: "25.72362340",
    longitude: "-77.83101040",
  },
  {
    id: 3616,
    name: "North Abaco",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "NO",
    type: null,
    latitude: "26.78716970",
    longitude: "-77.43577390",
  },
  {
    id: 3617,
    name: "North Andros",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "NS",
    type: null,
    latitude: "24.70638050",
    longitude: "-78.01953870",
  },
  {
    id: 3602,
    name: "North Eleuthera",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "NE",
    type: null,
    latitude: "25.46475170",
    longitude: "-76.67592200",
  },
  {
    id: 3615,
    name: "Ragged Island",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "RI",
    type: null,
    latitude: "41.59743100",
    longitude: "-71.26020200",
  },
  {
    id: 3623,
    name: "Rock Sound",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "RS",
    type: null,
    latitude: "39.01424430",
    longitude: "-95.67089890",
  },
  {
    id: 3600,
    name: "Rum Cay District",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "RC",
    type: null,
    latitude: "23.68546760",
    longitude: "-74.83901620",
  },
  {
    id: 3620,
    name: "San Salvador and Rum Cay",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "SR",
    type: null,
    latitude: "23.68546760",
    longitude: "-74.83901620",
  },
  {
    id: 3627,
    name: "San Salvador Island",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "SS",
    type: null,
    latitude: "24.07755460",
    longitude: "-74.47600880",
  },
  {
    id: 3606,
    name: "Sandy Point",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "SP",
    type: null,
    latitude: "39.01454640",
    longitude: "-76.39989250",
  },
  {
    id: 3608,
    name: "South Abaco",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "SO",
    type: null,
    latitude: "26.06405910",
    longitude: "-77.26350380",
  },
  {
    id: 3622,
    name: "South Andros",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "SA",
    type: null,
    latitude: "23.97135560",
    longitude: "-77.60778650",
  },
  {
    id: 3607,
    name: "South Eleuthera",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "SE",
    type: null,
    latitude: "24.77085620",
    longitude: "-76.21314740",
  },
  {
    id: 3630,
    name: "Spanish Wells",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "SW",
    type: null,
    latitude: "26.32505990",
    longitude: "-81.79803280",
  },
  {
    id: 3599,
    name: "West Grand Bahama",
    country_id: 17,
    country_code: "BS",
    country_name: "The Bahamas",
    state_code: "WG",
    type: null,
    latitude: "26.65944700",
    longitude: "-78.52065000",
  },
  {
    id: 2575,
    name: "Centrale Region",
    country_id: 220,
    country_code: "TG",
    country_name: "Togo",
    state_code: "C",
    type: null,
    latitude: "8.65860290",
    longitude: "1.05861350",
  },
  {
    id: 2579,
    name: "Kara Region",
    country_id: 220,
    country_code: "TG",
    country_name: "Togo",
    state_code: "K",
    type: null,
    latitude: "9.72163930",
    longitude: "1.05861350",
  },
  {
    id: 2576,
    name: "Maritime",
    country_id: 220,
    country_code: "TG",
    country_name: "Togo",
    state_code: "M",
    type: null,
    latitude: "41.65514930",
    longitude: "-83.52784670",
  },
  {
    id: 2577,
    name: "Plateaux Region",
    country_id: 220,
    country_code: "TG",
    country_name: "Togo",
    state_code: "P",
    type: null,
    latitude: "7.61013780",
    longitude: "1.05861350",
  },
  {
    id: 2578,
    name: "Savanes Region",
    country_id: 220,
    country_code: "TG",
    country_name: "Togo",
    state_code: "S",
    type: null,
    latitude: "10.52917810",
    longitude: "0.52578230",
  },
  {
    id: 3913,
    name: "Haʻapai",
    country_id: 222,
    country_code: "TO",
    country_name: "Tonga",
    state_code: "02",
    type: null,
    latitude: "-19.75000000",
    longitude: "-174.36666700",
  },
  {
    id: 3915,
    name: "ʻEua",
    country_id: 222,
    country_code: "TO",
    country_name: "Tonga",
    state_code: "01",
    type: null,
    latitude: "37.09024000",
    longitude: "-95.71289100",
  },
  {
    id: 3914,
    name: "Niuas",
    country_id: 222,
    country_code: "TO",
    country_name: "Tonga",
    state_code: "03",
    type: null,
    latitude: "-15.95940000",
    longitude: "-173.78300000",
  },
  {
    id: 3912,
    name: "Tongatapu",
    country_id: 222,
    country_code: "TO",
    country_name: "Tonga",
    state_code: "04",
    type: null,
    latitude: "-21.14659680",
    longitude: "-175.25154820",
  },
  {
    id: 3911,
    name: "Vavaʻu",
    country_id: 222,
    country_code: "TO",
    country_name: "Tonga",
    state_code: "05",
    type: null,
    latitude: "-18.62275600",
    longitude: "-173.99029820",
  },
  {
    id: 3362,
    name: "Arima",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "ARI",
    type: null,
    latitude: "46.79316040",
    longitude: "-71.25843110",
  },
  {
    id: 3366,
    name: "Chaguanas",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "CHA",
    type: null,
    latitude: "10.51683870",
    longitude: "-61.41144820",
  },
  {
    id: 3354,
    name: "Couva-Tabaquite-Talparo Regional Corporation",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "CTT",
    type: null,
    latitude: "10.42971450",
    longitude: "-61.37352100",
  },
  {
    id: 3367,
    name: "Diego Martin Regional Corporation",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "DMN",
    type: null,
    latitude: "10.73622860",
    longitude: "-61.55448360",
  },
  {
    id: 3355,
    name: "Eastern Tobago",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "ETO",
    type: null,
    latitude: "11.29793480",
    longitude: "-60.55885240",
  },
  {
    id: 3365,
    name: "Penal-Debe Regional Corporation",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "PED",
    type: null,
    latitude: "10.13374020",
    longitude: "-61.44354740",
  },
  {
    id: 3360,
    name: "Point Fortin",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "PTF",
    type: null,
    latitude: "10.17027370",
    longitude: "-61.67133860",
  },
  {
    id: 3363,
    name: "Port of Spain",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "POS",
    type: null,
    latitude: "10.66031960",
    longitude: "-61.50856250",
  },
  {
    id: 3368,
    name: "Princes Town Regional Corporation",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "PRT",
    type: null,
    latitude: "10.17867460",
    longitude: "-61.28019960",
  },
  {
    id: 3356,
    name: "Rio Claro-Mayaro Regional Corporation",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "MRC",
    type: null,
    latitude: "10.24128320",
    longitude: "-61.09372060",
  },
  {
    id: 3359,
    name: "San Fernando",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "SFO",
    type: null,
    latitude: "34.28194610",
    longitude: "-118.43897190",
  },
  {
    id: 3357,
    name: "San Juan-Laventille Regional Corporation",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "SJL",
    type: null,
    latitude: "10.69085780",
    longitude: "-61.45522130",
  },
  {
    id: 3361,
    name: "Sangre Grande Regional Corporation",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "SGE",
    type: null,
    latitude: "10.58529390",
    longitude: "-61.13158130",
  },
  {
    id: 3364,
    name: "Siparia Regional Corporation",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "SIP",
    type: null,
    latitude: "10.12456260",
    longitude: "-61.56032440",
  },
  {
    id: 3358,
    name: "Tunapuna-Piarco Regional Corporation",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "TUP",
    type: null,
    latitude: "10.68590960",
    longitude: "-61.30352480",
  },
  {
    id: 3353,
    name: "Western Tobago",
    country_id: 223,
    country_code: "TT",
    country_name: "Trinidad And Tobago",
    state_code: "WTO",
    type: null,
    latitude: "11.18970720",
    longitude: "-60.77954520",
  },
  {
    id: 2550,
    name: "Ariana",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "12",
    type: "governorate",
    latitude: "36.99227510",
    longitude: "10.12551640",
  },
  {
    id: 2572,
    name: "Béja",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "31",
    type: "governorate",
    latitude: "35.17227160",
    longitude: "8.83076260",
  },
  {
    id: 2566,
    name: "Ben Arous",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "13",
    type: "governorate",
    latitude: "36.64356060",
    longitude: "10.21515780",
  },
  {
    id: 2551,
    name: "Bizerte",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "23",
    type: "governorate",
    latitude: "37.16093970",
    longitude: "9.63413500",
  },
  {
    id: 2558,
    name: "Gabès",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "81",
    type: "governorate",
    latitude: "33.94596480",
    longitude: "9.72326730",
  },
  {
    id: 2556,
    name: "Gafsa",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "71",
    type: "governorate",
    latitude: "34.37885050",
    longitude: "8.66005860",
  },
  {
    id: 2552,
    name: "Jendouba",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "32",
    type: "governorate",
    latitude: "36.71818620",
    longitude: "8.74811670",
  },
  {
    id: 2564,
    name: "Kairouan",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "41",
    type: "governorate",
    latitude: "35.67116630",
    longitude: "10.10054690",
  },
  {
    id: 2570,
    name: "Kasserine",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "42",
    type: "governorate",
    latitude: "35.08091480",
    longitude: "8.66005860",
  },
  {
    id: 2562,
    name: "Kebili",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "73",
    type: "governorate",
    latitude: "33.70715510",
    longitude: "8.97146230",
  },
  {
    id: 2561,
    name: "Kef",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "33",
    type: "governorate",
    latitude: "36.12305120",
    longitude: "8.66005860",
  },
  {
    id: 2568,
    name: "Mahdia",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "53",
    type: "governorate",
    latitude: "35.33525580",
    longitude: "10.89030990",
  },
  {
    id: 2555,
    name: "Manouba",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "14",
    type: "governorate",
    latitude: "36.84465040",
    longitude: "9.85714160",
  },
  {
    id: 2560,
    name: "Medenine",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "82",
    type: "governorate",
    latitude: "33.22805650",
    longitude: "10.89030990",
  },
  {
    id: 2553,
    name: "Monastir",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "52",
    type: "governorate",
    latitude: "35.76425150",
    longitude: "10.81128850",
  },
  {
    id: 5116,
    name: "Nabeul",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "21",
    type: "governorate",
    latitude: "36.45245910",
    longitude: "10.68032220",
  },
  {
    id: 2557,
    name: "Sfax",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "61",
    type: "governorate",
    latitude: "34.86065810",
    longitude: "10.34978950",
  },
  {
    id: 2567,
    name: "Sidi Bouzid",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "43",
    type: "governorate",
    latitude: "35.03543860",
    longitude: "9.48393920",
  },
  {
    id: 2563,
    name: "Siliana",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "34",
    type: "governorate",
    latitude: "36.08872080",
    longitude: "9.36453350",
  },
  {
    id: 2571,
    name: "Sousse",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "51",
    type: "governorate",
    latitude: "35.90222670",
    longitude: "10.34978950",
  },
  {
    id: 2559,
    name: "Tataouine",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "83",
    type: "governorate",
    latitude: "32.13441220",
    longitude: "10.08072980",
  },
  {
    id: 2569,
    name: "Tozeur",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "72",
    type: "governorate",
    latitude: "33.97894910",
    longitude: "8.04651850",
  },
  {
    id: 2554,
    name: "Tunis",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "11",
    type: "governorate",
    latitude: "36.83749460",
    longitude: "10.19273890",
  },
  {
    id: 2565,
    name: "Zaghouan",
    country_id: 224,
    country_code: "TN",
    country_name: "Tunisia",
    state_code: "22",
    type: "governorate",
    latitude: "36.40911880",
    longitude: "10.14231720",
  },
  {
    id: 2212,
    name: "Adana",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "01",
    type: "province",
    latitude: "37.26123150",
    longitude: "35.39050460",
  },
  {
    id: 2155,
    name: "Adıyaman",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "02",
    type: "province",
    latitude: "37.90782910",
    longitude: "38.48499230",
  },
  {
    id: 2179,
    name: "Afyonkarahisar",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "03",
    type: "province",
    latitude: "38.73910990",
    longitude: "30.71200230",
  },
  {
    id: 2193,
    name: "Ağrı",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "04",
    type: "province",
    latitude: "39.62692180",
    longitude: "43.02159650",
  },
  {
    id: 2210,
    name: "Aksaray",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "68",
    type: "province",
    latitude: "38.33520430",
    longitude: "33.97500180",
  },
  {
    id: 2161,
    name: "Amasya",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "05",
    type: "province",
    latitude: "40.65166080",
    longitude: "35.90379660",
  },
  {
    id: 2217,
    name: "Ankara",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "06",
    type: "province",
    latitude: "39.78052450",
    longitude: "32.71813750",
  },
  {
    id: 2169,
    name: "Antalya",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "07",
    type: "province",
    latitude: "37.09516720",
    longitude: "31.07937050",
  },
  {
    id: 2185,
    name: "Ardahan",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "75",
    type: "province",
    latitude: "41.11129640",
    longitude: "42.78316740",
  },
  {
    id: 2191,
    name: "Artvin",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "08",
    type: "province",
    latitude: "41.07866400",
    longitude: "41.76282230",
  },
  {
    id: 2187,
    name: "Aydın",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "09",
    type: "province",
    latitude: "37.81170330",
    longitude: "28.48639630",
  },
  {
    id: 2175,
    name: "Balıkesir",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "10",
    type: "province",
    latitude: "39.76167820",
    longitude: "28.11226790",
  },
  {
    id: 2148,
    name: "Bartın",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "74",
    type: "province",
    latitude: "41.58105090",
    longitude: "32.46097940",
  },
  {
    id: 2194,
    name: "Batman",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "72",
    type: "province",
    latitude: "37.83624960",
    longitude: "41.36057390",
  },
  {
    id: 2177,
    name: "Bayburt",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "69",
    type: "province",
    latitude: "40.26032000",
    longitude: "40.22804800",
  },
  {
    id: 2221,
    name: "Bilecik",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "11",
    type: "province",
    latitude: "40.05665550",
    longitude: "30.06652360",
  },
  {
    id: 2153,
    name: "Bingöl",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "12",
    type: "province",
    latitude: "39.06263540",
    longitude: "40.76960950",
  },
  {
    id: 2215,
    name: "Bitlis",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "13",
    type: "province",
    latitude: "38.65231330",
    longitude: "42.42020280",
  },
  {
    id: 2172,
    name: "Bolu",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "14",
    type: "province",
    latitude: "40.57597660",
    longitude: "31.57880860",
  },
  {
    id: 2209,
    name: "Burdur",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "15",
    type: "province",
    latitude: "37.46126690",
    longitude: "30.06652360",
  },
  {
    id: 2163,
    name: "Bursa",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "16",
    type: "province",
    latitude: "40.06554590",
    longitude: "29.23207840",
  },
  {
    id: 2216,
    name: "Çanakkale",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "17",
    type: "province",
    latitude: "40.05101040",
    longitude: "26.98524220",
  },
  {
    id: 2168,
    name: "Çankırı",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "18",
    type: "province",
    latitude: "40.53690730",
    longitude: "33.58838930",
  },
  {
    id: 2173,
    name: "Çorum",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "19",
    type: "province",
    latitude: "40.49982110",
    longitude: "34.59862630",
  },
  {
    id: 2157,
    name: "Denizli",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "20",
    type: "province",
    latitude: "37.61283950",
    longitude: "29.23207840",
  },
  {
    id: 2226,
    name: "Diyarbakır",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "21",
    type: "province",
    latitude: "38.10663720",
    longitude: "40.54268960",
  },
  {
    id: 2202,
    name: "Düzce",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "81",
    type: "province",
    latitude: "40.87705310",
    longitude: "31.31927130",
  },
  {
    id: 2151,
    name: "Edirne",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "22",
    type: "province",
    latitude: "41.15172220",
    longitude: "26.51379640",
  },
  {
    id: 2159,
    name: "Elazığ",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "23",
    type: "province",
    latitude: "38.49648040",
    longitude: "39.21990290",
  },
  {
    id: 2160,
    name: "Erzincan",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "24",
    type: "province",
    latitude: "39.76819140",
    longitude: "39.05013060",
  },
  {
    id: 2165,
    name: "Erzurum",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "25",
    type: "province",
    latitude: "40.07467990",
    longitude: "41.66945620",
  },
  {
    id: 2164,
    name: "Eskişehir",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "26",
    type: "province",
    latitude: "39.63296570",
    longitude: "31.26263660",
  },
  {
    id: 2203,
    name: "Gaziantep",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "27",
    type: "province",
    latitude: "37.07638820",
    longitude: "37.38272340",
  },
  {
    id: 2186,
    name: "Giresun",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "28",
    type: "province",
    latitude: "40.64616720",
    longitude: "38.59355110",
  },
  {
    id: 2204,
    name: "Gümüşhane",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "29",
    type: "province",
    latitude: "40.28036730",
    longitude: "39.31432530",
  },
  {
    id: 2190,
    name: "Hakkâri",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "30",
    type: "province",
    latitude: "37.44593190",
    longitude: "43.74498410",
  },
  {
    id: 2211,
    name: "Hatay",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "31",
    type: "province",
    latitude: "36.40184880",
    longitude: "36.34980970",
  },
  {
    id: 2166,
    name: "Iğdır",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "76",
    type: "province",
    latitude: "39.88798410",
    longitude: "44.00483650",
  },
  {
    id: 2222,
    name: "Isparta",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "32",
    type: "province",
    latitude: "38.02114640",
    longitude: "31.07937050",
  },
  {
    id: 2170,
    name: "İstanbul",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "34",
    type: "province",
    latitude: "41.16343020",
    longitude: "28.76644080",
  },
  {
    id: 2205,
    name: "İzmir",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "35",
    type: "province",
    latitude: "38.35916930",
    longitude: "27.26761160",
  },
  {
    id: 2227,
    name: "Kahramanmaraş",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "46",
    type: "province",
    latitude: "37.75030360",
    longitude: "36.95410700",
  },
  {
    id: 2223,
    name: "Karabük",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "78",
    type: "province",
    latitude: "41.18748900",
    longitude: "32.74174190",
  },
  {
    id: 2184,
    name: "Karaman",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "70",
    type: "province",
    latitude: "37.24363360",
    longitude: "33.61757700",
  },
  {
    id: 2208,
    name: "Kars",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "36",
    type: "province",
    latitude: "40.28076360",
    longitude: "42.99195270",
  },
  {
    id: 2197,
    name: "Kastamonu",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "37",
    type: "province",
    latitude: "41.41038630",
    longitude: "33.69983340",
  },
  {
    id: 2200,
    name: "Kayseri",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "38",
    type: "province",
    latitude: "38.62568540",
    longitude: "35.74068820",
  },
  {
    id: 2154,
    name: "Kilis",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "79",
    type: "province",
    latitude: "36.82047750",
    longitude: "37.16873390",
  },
  {
    id: 2178,
    name: "Kırıkkale",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "71",
    type: "province",
    latitude: "39.88768780",
    longitude: "33.75552480",
  },
  {
    id: 2176,
    name: "Kırklareli",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "39",
    type: "province",
    latitude: "41.72597950",
    longitude: "27.48383900",
  },
  {
    id: 2180,
    name: "Kırşehir",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "40",
    type: "province",
    latitude: "39.22689050",
    longitude: "33.97500180",
  },
  {
    id: 2195,
    name: "Kocaeli",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "41",
    type: "province",
    latitude: "40.85327040",
    longitude: "29.88152030",
  },
  {
    id: 2171,
    name: "Konya",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "42",
    type: "province",
    latitude: "37.98381340",
    longitude: "32.71813750",
  },
  {
    id: 2149,
    name: "Kütahya",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "43",
    type: "province",
    latitude: "39.35813700",
    longitude: "29.60354950",
  },
  {
    id: 2158,
    name: "Malatya",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "44",
    type: "province",
    latitude: "38.40150570",
    longitude: "37.95362980",
  },
  {
    id: 2198,
    name: "Manisa",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "45",
    type: "province",
    latitude: "38.84193730",
    longitude: "28.11226790",
  },
  {
    id: 2224,
    name: "Mardin",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "47",
    type: "province",
    latitude: "37.34429290",
    longitude: "40.61964870",
  },
  {
    id: 2156,
    name: "Mersin",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "33",
    type: "province",
    latitude: "36.81208580",
    longitude: "34.64147500",
  },
  {
    id: 2182,
    name: "Muğla",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "48",
    type: "province",
    latitude: "37.18358190",
    longitude: "28.48639630",
  },
  {
    id: 2162,
    name: "Muş",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "49",
    type: "province",
    latitude: "38.94618880",
    longitude: "41.75389310",
  },
  {
    id: 2196,
    name: "Nevşehir",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "50",
    type: "province",
    latitude: "38.69393990",
    longitude: "34.68565090",
  },
  {
    id: 2189,
    name: "Niğde",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "51",
    type: "province",
    latitude: "38.09930860",
    longitude: "34.68565090",
  },
  {
    id: 2174,
    name: "Ordu",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "52",
    type: "province",
    latitude: "40.79905800",
    longitude: "37.38990050",
  },
  {
    id: 2214,
    name: "Osmaniye",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "80",
    type: "province",
    latitude: "37.21302580",
    longitude: "36.17626150",
  },
  {
    id: 2219,
    name: "Rize",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "53",
    type: "province",
    latitude: "40.95814970",
    longitude: "40.92269850",
  },
  {
    id: 2150,
    name: "Sakarya",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "54",
    type: "province",
    latitude: "40.78885500",
    longitude: "30.40595400",
  },
  {
    id: 2220,
    name: "Samsun",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "55",
    type: "province",
    latitude: "41.18648590",
    longitude: "36.13226780",
  },
  {
    id: 2183,
    name: "Şanlıurfa",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "63",
    type: "province",
    latitude: "37.35691020",
    longitude: "39.15436770",
  },
  {
    id: 2207,
    name: "Siirt",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "56",
    type: "province",
    latitude: "37.86588620",
    longitude: "42.14945230",
  },
  {
    id: 4854,
    name: "Sinop",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "57",
    type: "province",
    latitude: "41.55947490",
    longitude: "34.85805320",
  },
  {
    id: 2181,
    name: "Sivas",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "58",
    type: "province",
    latitude: "39.44880390",
    longitude: "37.12944970",
  },
  {
    id: 2225,
    name: "Şırnak",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "73",
    type: "province",
    latitude: "37.41874810",
    longitude: "42.49183380",
  },
  {
    id: 2167,
    name: "Tekirdağ",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "59",
    type: "province",
    latitude: "41.11212270",
    longitude: "27.26761160",
  },
  {
    id: 2199,
    name: "Tokat",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "60",
    type: "province",
    latitude: "40.39027130",
    longitude: "36.62518630",
  },
  {
    id: 2206,
    name: "Trabzon",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "61",
    type: "province",
    latitude: "40.79924100",
    longitude: "39.58479440",
  },
  {
    id: 2192,
    name: "Tunceli",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "62",
    type: "province",
    latitude: "39.30735540",
    longitude: "39.43877780",
  },
  {
    id: 2201,
    name: "Uşak",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "64",
    type: "province",
    latitude: "38.54313190",
    longitude: "29.23207840",
  },
  {
    id: 2152,
    name: "Van",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "65",
    type: "province",
    latitude: "38.36794170",
    longitude: "43.71827870",
  },
  {
    id: 2218,
    name: "Yalova",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "77",
    type: "province",
    latitude: "40.57759860",
    longitude: "29.20883030",
  },
  {
    id: 2188,
    name: "Yozgat",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "66",
    type: "province",
    latitude: "39.72719790",
    longitude: "35.10778580",
  },
  {
    id: 2213,
    name: "Zonguldak",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    state_code: "67",
    type: "province",
    latitude: "41.31249170",
    longitude: "31.85982510",
  },
  {
    id: 3374,
    name: "Ahal Region",
    country_id: 226,
    country_code: "TM",
    country_name: "Turkmenistan",
    state_code: "A",
    type: null,
    latitude: "38.63993980",
    longitude: "59.47209040",
  },
  {
    id: 3371,
    name: "Ashgabat",
    country_id: 226,
    country_code: "TM",
    country_name: "Turkmenistan",
    state_code: "S",
    type: null,
    latitude: "37.96007660",
    longitude: "58.32606290",
  },
  {
    id: 3372,
    name: "Balkan Region",
    country_id: 226,
    country_code: "TM",
    country_name: "Turkmenistan",
    state_code: "B",
    type: null,
    latitude: "41.81014720",
    longitude: "21.09373110",
  },
  {
    id: 3373,
    name: "Daşoguz Region",
    country_id: 226,
    country_code: "TM",
    country_name: "Turkmenistan",
    state_code: "D",
    type: null,
    latitude: "41.83687370",
    longitude: "59.96519040",
  },
  {
    id: 3370,
    name: "Lebap Region",
    country_id: 226,
    country_code: "TM",
    country_name: "Turkmenistan",
    state_code: "L",
    type: null,
    latitude: "38.12724620",
    longitude: "64.71624150",
  },
  {
    id: 3369,
    name: "Mary Region",
    country_id: 226,
    country_code: "TM",
    country_name: "Turkmenistan",
    state_code: "M",
    type: null,
    latitude: "36.94816230",
    longitude: "62.45041540",
  },
  {
    id: 3951,
    name: "Funafuti",
    country_id: 228,
    country_code: "TV",
    country_name: "Tuvalu",
    state_code: "FUN",
    type: null,
    latitude: "-8.52114710",
    longitude: "179.19619260",
  },
  {
    id: 3947,
    name: "Nanumanga",
    country_id: 228,
    country_code: "TV",
    country_name: "Tuvalu",
    state_code: "NMG",
    type: null,
    latitude: "-6.28580190",
    longitude: "176.31992800",
  },
  {
    id: 3949,
    name: "Nanumea",
    country_id: 228,
    country_code: "TV",
    country_name: "Tuvalu",
    state_code: "NMA",
    type: null,
    latitude: "-5.68816170",
    longitude: "176.13701480",
  },
  {
    id: 3946,
    name: "Niutao Island Council",
    country_id: 228,
    country_code: "TV",
    country_name: "Tuvalu",
    state_code: "NIT",
    type: null,
    latitude: "-6.10642580",
    longitude: "177.34384290",
  },
  {
    id: 3948,
    name: "Nui",
    country_id: 228,
    country_code: "TV",
    country_name: "Tuvalu",
    state_code: "NUI",
    type: null,
    latitude: "-7.23887680",
    longitude: "177.14852320",
  },
  {
    id: 3952,
    name: "Nukufetau",
    country_id: 228,
    country_code: "TV",
    country_name: "Tuvalu",
    state_code: "NKF",
    type: null,
    latitude: "-8.00000000",
    longitude: "178.50000000",
  },
  {
    id: 3953,
    name: "Nukulaelae",
    country_id: 228,
    country_code: "TV",
    country_name: "Tuvalu",
    state_code: "NKL",
    type: null,
    latitude: "-9.38111100",
    longitude: "179.85222200",
  },
  {
    id: 3950,
    name: "Vaitupu",
    country_id: 228,
    country_code: "TV",
    country_name: "Tuvalu",
    state_code: "VAI",
    type: null,
    latitude: "-7.47673270",
    longitude: "178.67476750",
  },
  {
    id: 329,
    name: "Abim District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "314",
    type: null,
    latitude: "2.70669800",
    longitude: "33.65953370",
  },
  {
    id: 361,
    name: "Adjumani District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "301",
    type: null,
    latitude: "3.25485270",
    longitude: "31.71954590",
  },
  {
    id: 392,
    name: "Agago District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "322",
    type: null,
    latitude: "2.92508200",
    longitude: "33.34861470",
  },
  {
    id: 344,
    name: "Alebtong District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "323",
    type: null,
    latitude: "2.25457730",
    longitude: "33.34861470",
  },
  {
    id: 416,
    name: "Amolatar District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "315",
    type: null,
    latitude: "1.60544020",
    longitude: "32.80844960",
  },
  {
    id: 353,
    name: "Amudat District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "324",
    type: null,
    latitude: "1.79162240",
    longitude: "34.90655100",
  },
  {
    id: 352,
    name: "Amuria District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "216",
    type: null,
    latitude: "2.03017000",
    longitude: "33.64275330",
  },
  {
    id: 335,
    name: "Amuru District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "316",
    type: null,
    latitude: "2.96678780",
    longitude: "32.08374450",
  },
  {
    id: 328,
    name: "Apac District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "302",
    type: null,
    latitude: "1.87302630",
    longitude: "32.62774550",
  },
  {
    id: 447,
    name: "Arua District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "303",
    type: null,
    latitude: "2.99598460",
    longitude: "31.17103890",
  },
  {
    id: 441,
    name: "Budaka District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "217",
    type: null,
    latitude: "1.10162770",
    longitude: "33.93039910",
  },
  {
    id: 349,
    name: "Bududa District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "218",
    type: null,
    latitude: "1.00296930",
    longitude: "34.33381230",
  },
  {
    id: 387,
    name: "Bugiri District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "201",
    type: null,
    latitude: "0.53161270",
    longitude: "33.75177230",
  },
  {
    id: 391,
    name: "Buhweju District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "420",
    type: null,
    latitude: "-0.29113590",
    longitude: "30.29741990",
  },
  {
    id: 377,
    name: "Buikwe District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "117",
    type: null,
    latitude: "0.31440460",
    longitude: "32.98883190",
  },
  {
    id: 343,
    name: "Bukedea District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "219",
    type: null,
    latitude: "1.35568980",
    longitude: "34.10867930",
  },
  {
    id: 375,
    name: "Bukomansimbi District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "118",
    type: null,
    latitude: "-0.14327520",
    longitude: "31.60548930",
  },
  {
    id: 385,
    name: "Bukwo District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "220",
    type: null,
    latitude: "1.28186510",
    longitude: "34.72987650",
  },
  {
    id: 428,
    name: "Bulambuli District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "225",
    type: null,
    latitude: "1.47988460",
    longitude: "34.37544140",
  },
  {
    id: 389,
    name: "Buliisa District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "416",
    type: null,
    latitude: "2.02996070",
    longitude: "31.53700030",
  },
  {
    id: 419,
    name: "Bundibugyo District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "401",
    type: null,
    latitude: "0.68517630",
    longitude: "30.02029640",
  },
  {
    id: 381,
    name: "Bunyangabu District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "430",
    type: null,
    latitude: "0.48709180",
    longitude: "30.20510960",
  },
  {
    id: 386,
    name: "Bushenyi District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "402",
    type: null,
    latitude: "-0.48709180",
    longitude: "30.20510960",
  },
  {
    id: 431,
    name: "Busia District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "202",
    type: null,
    latitude: "0.40447310",
    longitude: "34.01958270",
  },
  {
    id: 365,
    name: "Butaleja District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "221",
    type: null,
    latitude: "0.84749220",
    longitude: "33.84112880",
  },
  {
    id: 384,
    name: "Butambala District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "119",
    type: null,
    latitude: "0.17425000",
    longitude: "32.10646680",
  },
  {
    id: 388,
    name: "Butebo District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "233",
    type: null,
    latitude: "1.21411240",
    longitude: "33.90808960",
  },
  {
    id: 414,
    name: "Buvuma District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "120",
    type: null,
    latitude: "-0.37649120",
    longitude: "33.25879300",
  },
  {
    id: 380,
    name: "Buyende District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "226",
    type: null,
    latitude: "1.24136820",
    longitude: "33.12390490",
  },
  {
    id: 396,
    name: "Central Region",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "C",
    type: null,
    latitude: "44.29687500",
    longitude: "-94.74017330",
  },
  {
    id: 341,
    name: "Dokolo District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "317",
    type: null,
    latitude: "1.96364210",
    longitude: "33.03387670",
  },
  {
    id: 372,
    name: "Eastern Region",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "E",
    type: null,
    latitude: "6.23740360",
    longitude: "-0.45023680",
  },
  {
    id: 366,
    name: "Gomba District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "121",
    type: null,
    latitude: "0.22297910",
    longitude: "31.67393710",
  },
  {
    id: 413,
    name: "Gulu District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "304",
    type: null,
    latitude: "2.81857760",
    longitude: "32.44672380",
  },
  {
    id: 339,
    name: "Ibanda District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "417",
    type: null,
    latitude: "-0.09648900",
    longitude: "30.57395790",
  },
  {
    id: 340,
    name: "Iganga District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "203",
    type: null,
    latitude: "0.66001370",
    longitude: "33.48319060",
  },
  {
    id: 383,
    name: "Isingiro District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "418",
    type: null,
    latitude: "-0.84354300",
    longitude: "30.80394740",
  },
  {
    id: 367,
    name: "Jinja District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "204",
    type: null,
    latitude: "0.53437430",
    longitude: "33.30371430",
  },
  {
    id: 434,
    name: "Kaabong District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "318",
    type: null,
    latitude: "3.51262150",
    longitude: "33.97500180",
  },
  {
    id: 426,
    name: "Kabale District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "404",
    type: null,
    latitude: "-1.24930840",
    longitude: "30.06652360",
  },
  {
    id: 326,
    name: "Kabarole District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "405",
    type: null,
    latitude: "0.58507910",
    longitude: "30.25127280",
  },
  {
    id: 336,
    name: "Kaberamaido District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "213",
    type: null,
    latitude: "1.69633220",
    longitude: "33.21385100",
  },
  {
    id: 403,
    name: "Kagadi District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "427",
    type: null,
    latitude: "0.94007610",
    longitude: "30.81256380",
  },
  {
    id: 399,
    name: "Kakumiro District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "428",
    type: null,
    latitude: "0.78080350",
    longitude: "31.32413890",
  },
  {
    id: 405,
    name: "Kalangala District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "101",
    type: null,
    latitude: "-0.63505780",
    longitude: "32.53727410",
  },
  {
    id: 398,
    name: "Kaliro District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "222",
    type: null,
    latitude: "1.04311070",
    longitude: "33.48319060",
  },
  {
    id: 394,
    name: "Kalungu District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "122",
    type: null,
    latitude: "-0.09528310",
    longitude: "31.76513620",
  },
  {
    id: 382,
    name: "Kampala District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "102",
    type: null,
    latitude: "0.34759640",
    longitude: "32.58251970",
  },
  {
    id: 334,
    name: "Kamuli District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "205",
    type: null,
    latitude: "0.91871070",
    longitude: "33.12390490",
  },
  {
    id: 360,
    name: "Kamwenge District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "413",
    type: null,
    latitude: "0.22579300",
    longitude: "30.48184460",
  },
  {
    id: 373,
    name: "Kanungu District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "414",
    type: null,
    latitude: "-0.81952530",
    longitude: "29.74260400",
  },
  {
    id: 432,
    name: "Kapchorwa District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "206",
    type: null,
    latitude: "1.33502050",
    longitude: "34.39763560",
  },
  {
    id: 440,
    name: "Kasese District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "406",
    type: null,
    latitude: "0.06462850",
    longitude: "30.06652360",
  },
  {
    id: 420,
    name: "Katakwi District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "207",
    type: null,
    latitude: "1.97310300",
    longitude: "34.06414190",
  },
  {
    id: 368,
    name: "Kayunga District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "112",
    type: null,
    latitude: "0.98601820",
    longitude: "32.85357550",
  },
  {
    id: 436,
    name: "Kibaale District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "407",
    type: null,
    latitude: "0.90668020",
    longitude: "31.07937050",
  },
  {
    id: 347,
    name: "Kiboga District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "103",
    type: null,
    latitude: "0.96575900",
    longitude: "31.71954590",
  },
  {
    id: 338,
    name: "Kibuku District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "227",
    type: null,
    latitude: "1.04528740",
    longitude: "33.79925360",
  },
  {
    id: 355,
    name: "Kiruhura District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "419",
    type: null,
    latitude: "-0.19279980",
    longitude: "30.80394740",
  },
  {
    id: 346,
    name: "Kiryandongo District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "421",
    type: null,
    latitude: "2.01799070",
    longitude: "32.08374450",
  },
  {
    id: 409,
    name: "Kisoro District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "408",
    type: null,
    latitude: "-1.22094300",
    longitude: "29.64991620",
  },
  {
    id: 348,
    name: "Kitgum District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "305",
    type: null,
    latitude: "3.33968290",
    longitude: "33.16888830",
  },
  {
    id: 345,
    name: "Koboko District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "319",
    type: null,
    latitude: "3.52370580",
    longitude: "31.03351000",
  },
  {
    id: 401,
    name: "Kole District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "325",
    type: null,
    latitude: "2.37010970",
    longitude: "32.76330360",
  },
  {
    id: 443,
    name: "Kotido District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "306",
    type: null,
    latitude: "3.04156790",
    longitude: "33.88577470",
  },
  {
    id: 425,
    name: "Kumi District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "208",
    type: null,
    latitude: "1.48769990",
    longitude: "33.93039910",
  },
  {
    id: 369,
    name: "Kween District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "228",
    type: null,
    latitude: "1.44387900",
    longitude: "34.59713200",
  },
  {
    id: 325,
    name: "Kyankwanzi District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "123",
    type: null,
    latitude: "1.09660370",
    longitude: "31.71954590",
  },
  {
    id: 437,
    name: "Kyegegwa District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "422",
    type: null,
    latitude: "0.48181930",
    longitude: "31.05500930",
  },
  {
    id: 402,
    name: "Kyenjojo District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "415",
    type: null,
    latitude: "0.60929230",
    longitude: "30.64012310",
  },
  {
    id: 448,
    name: "Kyotera District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "125",
    type: null,
    latitude: "-0.63589880",
    longitude: "31.54556370",
  },
  {
    id: 411,
    name: "Lamwo District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "326",
    type: null,
    latitude: "3.57075680",
    longitude: "32.53727410",
  },
  {
    id: 342,
    name: "Lira District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "307",
    type: null,
    latitude: "2.23161690",
    longitude: "32.94376670",
  },
  {
    id: 445,
    name: "Luuka District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "229",
    type: null,
    latitude: "0.72505990",
    longitude: "33.30371430",
  },
  {
    id: 433,
    name: "Luwero District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "104",
    type: null,
    latitude: "0.82711180",
    longitude: "32.62774550",
  },
  {
    id: 417,
    name: "Lwengo District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "124",
    type: null,
    latitude: "-0.41652880",
    longitude: "31.39989950",
  },
  {
    id: 376,
    name: "Lyantonde District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "114",
    type: null,
    latitude: "-0.22406960",
    longitude: "31.21684660",
  },
  {
    id: 438,
    name: "Manafwa District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "223",
    type: null,
    latitude: "0.90635990",
    longitude: "34.28660910",
  },
  {
    id: 421,
    name: "Maracha District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "320",
    type: null,
    latitude: "3.28731270",
    longitude: "30.94030230",
  },
  {
    id: 356,
    name: "Masaka District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "105",
    type: null,
    latitude: "-0.44636910",
    longitude: "31.90179540",
  },
  {
    id: 354,
    name: "Masindi District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "409",
    type: null,
    latitude: "1.49203630",
    longitude: "31.71954590",
  },
  {
    id: 418,
    name: "Mayuge District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "214",
    type: null,
    latitude: "-0.21829820",
    longitude: "33.57280270",
  },
  {
    id: 350,
    name: "Mbale District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "209",
    type: null,
    latitude: "1.03442740",
    longitude: "34.19768820",
  },
  {
    id: 415,
    name: "Mbarara District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "410",
    type: null,
    latitude: "-0.60715960",
    longitude: "30.65450220",
  },
  {
    id: 435,
    name: "Mitooma District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "423",
    type: null,
    latitude: "-0.61932760",
    longitude: "30.02029640",
  },
  {
    id: 364,
    name: "Mityana District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "115",
    type: null,
    latitude: "0.44548450",
    longitude: "32.08374450",
  },
  {
    id: 395,
    name: "Moroto District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "308",
    type: null,
    latitude: "2.61685450",
    longitude: "34.59713200",
  },
  {
    id: 363,
    name: "Moyo District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "309",
    type: null,
    latitude: "3.56964640",
    longitude: "31.67393710",
  },
  {
    id: 327,
    name: "Mpigi District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "106",
    type: null,
    latitude: "0.22735280",
    longitude: "32.32492360",
  },
  {
    id: 371,
    name: "Mubende District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "107",
    type: null,
    latitude: "0.57727580",
    longitude: "31.53700030",
  },
  {
    id: 410,
    name: "Mukono District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "108",
    type: null,
    latitude: "0.28354760",
    longitude: "32.76330360",
  },
  {
    id: 393,
    name: "Nakapiripirit District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "311",
    type: null,
    latitude: "1.96061730",
    longitude: "34.59713200",
  },
  {
    id: 423,
    name: "Nakaseke District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "116",
    type: null,
    latitude: "1.22308480",
    longitude: "32.08374450",
  },
  {
    id: 406,
    name: "Nakasongola District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "109",
    type: null,
    latitude: "1.34897210",
    longitude: "32.44672380",
  },
  {
    id: 351,
    name: "Namayingo District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "230",
    type: null,
    latitude: "-0.28035750",
    longitude: "33.75177230",
  },
  {
    id: 400,
    name: "Namisindwa District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "234",
    type: null,
    latitude: "0.90710100",
    longitude: "34.35740370",
  },
  {
    id: 337,
    name: "Namutumba District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "224",
    type: null,
    latitude: "0.84926100",
    longitude: "33.66233010",
  },
  {
    id: 430,
    name: "Napak District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "327",
    type: null,
    latitude: "2.36299450",
    longitude: "34.24215970",
  },
  {
    id: 446,
    name: "Nebbi District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "310",
    type: null,
    latitude: "2.44093920",
    longitude: "31.35416310",
  },
  {
    id: 424,
    name: "Ngora District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "231",
    type: null,
    latitude: "1.49081150",
    longitude: "33.75177230",
  },
  {
    id: 332,
    name: "Northern Region",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "N",
    type: null,
    latitude: "9.54392690",
    longitude: "-0.90566230",
  },
  {
    id: 422,
    name: "Ntoroko District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "424",
    type: null,
    latitude: "1.07881780",
    longitude: "30.38966510",
  },
  {
    id: 404,
    name: "Ntungamo District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "411",
    type: null,
    latitude: "-0.98073410",
    longitude: "30.25127280",
  },
  {
    id: 378,
    name: "Nwoya District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "328",
    type: null,
    latitude: "2.56244400",
    longitude: "31.90179540",
  },
  {
    id: 374,
    name: "Omoro District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "331",
    type: null,
    latitude: "2.71522300",
    longitude: "32.49200880",
  },
  {
    id: 390,
    name: "Otuke District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "329",
    type: null,
    latitude: "2.52140590",
    longitude: "33.34861470",
  },
  {
    id: 397,
    name: "Oyam District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "321",
    type: null,
    latitude: "2.27762810",
    longitude: "32.44672380",
  },
  {
    id: 408,
    name: "Pader District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "312",
    type: null,
    latitude: "2.94306820",
    longitude: "32.80844960",
  },
  {
    id: 357,
    name: "Pakwach District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "332",
    type: null,
    latitude: "2.46071410",
    longitude: "31.49417380",
  },
  {
    id: 412,
    name: "Pallisa District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "210",
    type: null,
    latitude: "1.23242060",
    longitude: "33.75177230",
  },
  {
    id: 439,
    name: "Rakai District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "110",
    type: null,
    latitude: "-0.70691350",
    longitude: "31.53700030",
  },
  {
    id: 358,
    name: "Rubanda District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "429",
    type: null,
    latitude: "-1.18611900",
    longitude: "29.84535760",
  },
  {
    id: 442,
    name: "Rubirizi District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "425",
    type: null,
    latitude: "-0.26424100",
    longitude: "30.10840330",
  },
  {
    id: 331,
    name: "Rukiga District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "431",
    type: null,
    latitude: "-1.13263370",
    longitude: "30.04341200",
  },
  {
    id: 324,
    name: "Rukungiri District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "412",
    type: null,
    latitude: "-0.75184900",
    longitude: "29.92779470",
  },
  {
    id: 427,
    name: "Sembabule District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "111",
    type: null,
    latitude: "0.06377150",
    longitude: "31.35416310",
  },
  {
    id: 333,
    name: "Serere District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "232",
    type: null,
    latitude: "1.49940330",
    longitude: "33.54900780",
  },
  {
    id: 407,
    name: "Sheema District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "426",
    type: null,
    latitude: "-0.55152980",
    longitude: "30.38966510",
  },
  {
    id: 429,
    name: "Sironko District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "215",
    type: null,
    latitude: "1.23022740",
    longitude: "34.24910640",
  },
  {
    id: 444,
    name: "Soroti District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "211",
    type: null,
    latitude: "1.72291170",
    longitude: "33.52800720",
  },
  {
    id: 359,
    name: "Tororo District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "212",
    type: null,
    latitude: "0.68709940",
    longitude: "34.06414190",
  },
  {
    id: 362,
    name: "Wakiso District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "113",
    type: null,
    latitude: "0.06301900",
    longitude: "32.44672380",
  },
  {
    id: 370,
    name: "Western Region",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "W",
    type: null,
    latitude: "40.76672150",
    longitude: "-111.88772030",
  },
  {
    id: 330,
    name: "Yumbe District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "313",
    type: null,
    latitude: "3.46980230",
    longitude: "31.24832910",
  },
  {
    id: 379,
    name: "Zombo District",
    country_id: 229,
    country_code: "UG",
    country_name: "Uganda",
    state_code: "330",
    type: null,
    latitude: "2.55442930",
    longitude: "30.94173680",
  },
  {
    id: 4689,
    name: "Autonomous Republic of Crimea",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "43",
    type: "republic",
    latitude: "44.95211700",
    longitude: "34.10241700",
  },
  {
    id: 4680,
    name: "Cherkaska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "71",
    type: "region",
    latitude: "49.44443300",
    longitude: "32.05976700",
  },
  {
    id: 4692,
    name: "Chernihivska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "74",
    type: "region",
    latitude: "51.49820000",
    longitude: "31.28934990",
  },
  {
    id: 4678,
    name: "Chernivetska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "77",
    type: "region",
    latitude: "48.29168300",
    longitude: "25.93521700",
  },
  {
    id: 4675,
    name: "Dnipropetrovska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "12",
    type: "region",
    latitude: "48.46471700",
    longitude: "35.04618300",
  },
  {
    id: 4691,
    name: "Donetska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "14",
    type: "region",
    latitude: "48.01588300",
    longitude: "37.80285000",
  },
  {
    id: 4682,
    name: "Ivano-Frankivska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "26",
    type: "region",
    latitude: "48.92263300",
    longitude: "24.71111700",
  },
  {
    id: 4686,
    name: "Kharkivska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "63",
    type: "region",
    latitude: "49.99350000",
    longitude: "36.23038300",
  },
  {
    id: 4684,
    name: "Khersonska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "65",
    type: "region",
    latitude: "46.63541700",
    longitude: "32.61686700",
  },
  {
    id: 4681,
    name: "Khmelnytska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "68",
    type: "region",
    latitude: "49.42298300",
    longitude: "26.98713310",
  },
  {
    id: 4677,
    name: "Kirovohradska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "35",
    type: "region",
    latitude: "48.50793300",
    longitude: "32.26231700",
  },
  {
    id: 4676,
    name: "Kyiv",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "30",
    type: "city",
    latitude: "50.45010000",
    longitude: "30.52340000",
  },
  {
    id: 4671,
    name: "Kyivska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "32",
    type: "region",
    latitude: "50.05295060",
    longitude: "30.76671340",
  },
  {
    id: 4673,
    name: "Luhanska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "09",
    type: "region",
    latitude: "48.57404100",
    longitude: "39.30781500",
  },
  {
    id: 4672,
    name: "Lvivska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "46",
    type: "region",
    latitude: "49.83968300",
    longitude: "24.02971700",
  },
  {
    id: 4679,
    name: "Mykolaivska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "48",
    type: "region",
    latitude: "46.97503300",
    longitude: "31.99458290",
  },
  {
    id: 4688,
    name: "Odeska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "51",
    type: "region",
    latitude: "46.48458300",
    longitude: "30.73260000",
  },
  {
    id: 5071,
    name: "Poltavska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "53",
    type: "region",
    latitude: "49.64291960",
    longitude: "32.66753390",
  },
  {
    id: 4683,
    name: "Rivnenska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "56",
    type: "region",
    latitude: "50.61990000",
    longitude: "26.25161700",
  },
  {
    id: 4685,
    name: "Sumska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "59",
    type: "region",
    latitude: "50.90770000",
    longitude: "34.79810000",
  },
  {
    id: 4674,
    name: "Ternopilska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "61",
    type: "region",
    latitude: "49.55351700",
    longitude: "25.59476700",
  },
  {
    id: 4669,
    name: "Vinnytska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "05",
    type: "region",
    latitude: "49.23308300",
    longitude: "28.46821690",
  },
  {
    id: 4690,
    name: "Volynska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "07",
    type: "region",
    latitude: "50.74723300",
    longitude: "25.32538300",
  },
  {
    id: 4670,
    name: "Zakarpatska Oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "21",
    type: "region",
    latitude: "48.62080000",
    longitude: "22.28788300",
  },
  {
    id: 4687,
    name: "Zaporizka oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "23",
    type: "region",
    latitude: "47.83880000",
    longitude: "35.13956700",
  },
  {
    id: 4668,
    name: "Zhytomyrska oblast",
    country_id: 230,
    country_code: "UA",
    country_name: "Ukraine",
    state_code: "18",
    type: "region",
    latitude: "50.25465000",
    longitude: "28.65866690",
  },
  {
    id: 3396,
    name: "Abu Dhabi Emirate",
    country_id: 231,
    country_code: "AE",
    country_name: "United Arab Emirates",
    state_code: "AZ",
    type: null,
    latitude: "24.45388400",
    longitude: "54.37734380",
  },
  {
    id: 3395,
    name: "Ajman Emirate",
    country_id: 231,
    country_code: "AE",
    country_name: "United Arab Emirates",
    state_code: "AJ",
    type: null,
    latitude: "25.40521650",
    longitude: "55.51364330",
  },
  {
    id: 3391,
    name: "Dubai",
    country_id: 231,
    country_code: "AE",
    country_name: "United Arab Emirates",
    state_code: "DU",
    type: null,
    latitude: "25.20484930",
    longitude: "55.27078280",
  },
  {
    id: 3393,
    name: "Fujairah",
    country_id: 231,
    country_code: "AE",
    country_name: "United Arab Emirates",
    state_code: "FU",
    type: null,
    latitude: "25.12880990",
    longitude: "56.32648490",
  },
  {
    id: 3394,
    name: "Ras al-Khaimah",
    country_id: 231,
    country_code: "AE",
    country_name: "United Arab Emirates",
    state_code: "RK",
    type: null,
    latitude: "25.67413430",
    longitude: "55.98041730",
  },
  {
    id: 3390,
    name: "Sharjah Emirate",
    country_id: 231,
    country_code: "AE",
    country_name: "United Arab Emirates",
    state_code: "SH",
    type: null,
    latitude: "25.07539740",
    longitude: "55.75784030",
  },
  {
    id: 3392,
    name: "Umm al-Quwain",
    country_id: 231,
    country_code: "AE",
    country_name: "United Arab Emirates",
    state_code: "UQ",
    type: null,
    latitude: "25.54263240",
    longitude: "55.54753480",
  },
  {
    id: 2463,
    name: "Aberdeen",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ABE",
    type: null,
    latitude: "57.14971700",
    longitude: "-2.09427800",
  },
  {
    id: 2401,
    name: "Aberdeenshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ABD",
    type: null,
    latitude: "57.28687230",
    longitude: "-2.38156840",
  },
  {
    id: 2387,
    name: "Angus",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ANS",
    type: null,
    latitude: "37.27578860",
    longitude: "-95.65010330",
  },
  {
    id: 2533,
    name: "Antrim",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ANT",
    type: null,
    latitude: "54.71953380",
    longitude: "-6.20724980",
  },
  {
    id: 2412,
    name: "Antrim and Newtownabbey",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ANN",
    type: null,
    latitude: "54.69568870",
    longitude: "-5.94810690",
  },
  {
    id: 2498,
    name: "Ards",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ARD",
    type: null,
    latitude: "42.13918510",
    longitude: "-87.86149720",
  },
  {
    id: 2523,
    name: "Ards and North Down",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "AND",
    type: null,
    latitude: "54.58996450",
    longitude: "-5.59849720",
  },
  {
    id: 2392,
    name: "Argyll and Bute",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "AGB",
    type: null,
    latitude: "56.40062140",
    longitude: "-5.48074800",
  },
  {
    id: 2331,
    name: "Armagh City and District Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ARM",
    type: null,
    latitude: "54.39325920",
    longitude: "-6.45634010",
  },
  {
    id: 2324,
    name: "Armagh, Banbridge and Craigavon",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ABC",
    type: null,
    latitude: "54.39325920",
    longitude: "-6.45634010",
  },
  {
    id: 2378,
    name: "Ascension Island",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SH-AC",
    type: null,
    latitude: "-7.94671660",
    longitude: "-14.35591580",
  },
  {
    id: 2363,
    name: "Ballymena Borough",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BLA",
    type: null,
    latitude: "54.86426000",
    longitude: "-6.27910740",
  },
  {
    id: 2361,
    name: "Ballymoney",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BLY",
    type: null,
    latitude: "55.07048880",
    longitude: "-6.51737080",
  },
  {
    id: 2315,
    name: "Banbridge",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BNB",
    type: null,
    latitude: "54.34872900",
    longitude: "-6.27048030",
  },
  {
    id: 2499,
    name: "Barnsley",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BNS",
    type: null,
    latitude: "34.29949560",
    longitude: "-84.98458090",
  },
  {
    id: 2339,
    name: "Bath and North East Somerset",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BAS",
    type: null,
    latitude: "51.32501020",
    longitude: "-2.47662410",
  },
  {
    id: 2507,
    name: "Bedford",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BDF",
    type: null,
    latitude: "32.84401700",
    longitude: "-97.14306710",
  },
  {
    id: 2311,
    name: "Belfast district",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BFS",
    type: null,
    latitude: "54.61703660",
    longitude: "-5.95318610",
  },
  {
    id: 2425,
    name: "Birmingham",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BIR",
    type: null,
    latitude: "33.51858920",
    longitude: "-86.81035670",
  },
  {
    id: 2329,
    name: "Blackburn with Darwen",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BBD",
    type: null,
    latitude: "53.69575220",
    longitude: "-2.46829850",
  },
  {
    id: 2451,
    name: "Blackpool",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BPL",
    type: null,
    latitude: "53.81750530",
    longitude: "-3.03567480",
  },
  {
    id: 2530,
    name: "Blaenau Gwent County Borough",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BGW",
    type: null,
    latitude: "51.78757790",
    longitude: "-3.20439310",
  },
  {
    id: 2504,
    name: "Bolton",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BOL",
    type: null,
    latitude: "44.37264760",
    longitude: "-72.87876250",
  },
  {
    id: 2342,
    name: "Bournemouth",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BMH",
    type: null,
    latitude: "50.71916400",
    longitude: "-1.88076900",
  },
  {
    id: 2470,
    name: "Bracknell Forest",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BRC",
    type: null,
    latitude: "51.41538280",
    longitude: "-0.75364950",
  },
  {
    id: 2529,
    name: "Bradford",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BRD",
    type: null,
    latitude: "53.79598400",
    longitude: "-1.75939800",
  },
  {
    id: 2452,
    name: "Bridgend County Borough",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BGE",
    type: null,
    latitude: "51.50831990",
    longitude: "-3.58120750",
  },
  {
    id: 2395,
    name: "Brighton and Hove",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BNH",
    type: null,
    latitude: "50.82262880",
    longitude: "-0.13704700",
  },
  {
    id: 2405,
    name: "Buckinghamshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BKM",
    type: null,
    latitude: "51.80722040",
    longitude: "-0.81276640",
  },
  {
    id: 2459,
    name: "Bury",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BUR",
    type: null,
    latitude: "53.59334980",
    longitude: "-2.29660540",
  },
  {
    id: 2298,
    name: "Caerphilly County Borough",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CAY",
    type: null,
    latitude: "51.66044650",
    longitude: "-3.21787240",
  },
  {
    id: 2517,
    name: "Calderdale",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CLD",
    type: null,
    latitude: "53.72478450",
    longitude: "-1.86583570",
  },
  {
    id: 2423,
    name: "Cambridgeshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CAM",
    type: null,
    latitude: "52.20529730",
    longitude: "0.12181950",
  },
  {
    id: 2484,
    name: "Carmarthenshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CMN",
    type: null,
    latitude: "51.85723090",
    longitude: "-4.31159590",
  },
  {
    id: 2439,
    name: "Carrickfergus Borough Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CKF",
    type: null,
    latitude: "54.72568430",
    longitude: "-5.80937190",
  },
  {
    id: 2525,
    name: "Castlereagh",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CSR",
    type: null,
    latitude: "54.57567900",
    longitude: "-5.88840280",
  },
  {
    id: 2316,
    name: "Causeway Coast and Glens",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CCG",
    type: null,
    latitude: "55.04318300",
    longitude: "-6.67412880",
  },
  {
    id: 2303,
    name: "Central Bedfordshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CBF",
    type: null,
    latitude: "52.00297440",
    longitude: "-0.46513890",
  },
  {
    id: 2509,
    name: "Ceredigion",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CGN",
    type: null,
    latitude: "52.21914290",
    longitude: "-3.93212560",
  },
  {
    id: 2444,
    name: "Cheshire East",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CHE",
    type: null,
    latitude: "53.16104460",
    longitude: "-2.21859320",
  },
  {
    id: 2442,
    name: "Cheshire West and Chester",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CHW",
    type: null,
    latitude: "53.23029740",
    longitude: "-2.71511170",
  },
  {
    id: 2528,
    name: "City and County of Cardiff",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CRF",
    type: null,
    latitude: "51.48158100",
    longitude: "-3.17909000",
  },
  {
    id: 2433,
    name: "City and County of Swansea",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SWA",
    type: null,
    latitude: "51.62144000",
    longitude: "-3.94364600",
  },
  {
    id: 2413,
    name: "City of Bristol",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BST",
    type: null,
    latitude: "41.67352200",
    longitude: "-72.94653750",
  },
  {
    id: 2485,
    name: "City of Derby",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DER",
    type: null,
    latitude: "37.54837550",
    longitude: "-97.24851910",
  },
  {
    id: 2475,
    name: "City of Kingston upon Hull",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "KHL",
    type: null,
    latitude: "53.76762360",
    longitude: "-0.32741980",
  },
  {
    id: 2318,
    name: "City of Leicester",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "LCE",
    type: null,
    latitude: "52.63687780",
    longitude: "-1.13975920",
  },
  {
    id: 2424,
    name: "City of London",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "LND",
    type: null,
    latitude: "51.51234430",
    longitude: "-0.09098520",
  },
  {
    id: 2359,
    name: "City of Nottingham",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NGM",
    type: null,
    latitude: "52.95478320",
    longitude: "-1.15810860",
  },
  {
    id: 2297,
    name: "City of Peterborough",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "PTE",
    type: null,
    latitude: "44.30936360",
    longitude: "-78.32015300",
  },
  {
    id: 2514,
    name: "City of Plymouth",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "PLY",
    type: null,
    latitude: "42.37089410",
    longitude: "-83.46971410",
  },
  {
    id: 2305,
    name: "City of Portsmouth",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "POR",
    type: null,
    latitude: "36.83291500",
    longitude: "-76.29755490",
  },
  {
    id: 2294,
    name: "City of Southampton",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "STH",
    type: null,
    latitude: "50.90970040",
    longitude: "-1.40435090",
  },
  {
    id: 2506,
    name: "City of Stoke-on-Trent",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "STE",
    type: null,
    latitude: "53.00266800",
    longitude: "-2.17940400",
  },
  {
    id: 2372,
    name: "City of Sunderland",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SND",
    type: null,
    latitude: "54.88614890",
    longitude: "-1.47857970",
  },
  {
    id: 2357,
    name: "City of Westminster",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WSM",
    type: null,
    latitude: "39.57659770",
    longitude: "-76.99721260",
  },
  {
    id: 2489,
    name: "City of Wolverhampton",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WLV",
    type: null,
    latitude: "52.58891200",
    longitude: "-2.15646300",
  },
  {
    id: 2426,
    name: "City of York",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "YOR",
    type: null,
    latitude: "53.95996510",
    longitude: "-1.08729790",
  },
  {
    id: 2450,
    name: "Clackmannanshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CLK",
    type: null,
    latitude: "56.10753510",
    longitude: "-3.75294090",
  },
  {
    id: 2461,
    name: "Coleraine Borough Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CLR",
    type: null,
    latitude: "55.14515700",
    longitude: "-6.67598140",
  },
  {
    id: 2352,
    name: "Conwy County Borough",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CWY",
    type: null,
    latitude: "53.29350130",
    longitude: "-3.72651610",
  },
  {
    id: 2445,
    name: "Cookstown District Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CKT",
    type: null,
    latitude: "54.64181580",
    longitude: "-6.74438950",
  },
  {
    id: 2312,
    name: "Cornwall",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CON",
    type: null,
    latitude: "50.26604710",
    longitude: "-5.05271250",
  },
  {
    id: 2406,
    name: "County Durham",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DUR",
    type: null,
    latitude: "54.72940990",
    longitude: "-1.88115980",
  },
  {
    id: 2438,
    name: "Coventry",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "COV",
    type: null,
    latitude: "52.40682200",
    longitude: "-1.51969300",
  },
  {
    id: 2449,
    name: "Craigavon Borough Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CGV",
    type: null,
    latitude: "54.39325920",
    longitude: "-6.45634010",
  },
  {
    id: 2334,
    name: "Cumbria",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CMA",
    type: null,
    latitude: "54.57723230",
    longitude: "-2.79748350",
  },
  {
    id: 2389,
    name: "Darlington",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DAL",
    type: null,
    latitude: "34.29987620",
    longitude: "-79.87617410",
  },
  {
    id: 2497,
    name: "Denbighshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DEN",
    type: null,
    latitude: "53.18422880",
    longitude: "-3.42249850",
  },
  {
    id: 2403,
    name: "Derbyshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DBY",
    type: null,
    latitude: "53.10467820",
    longitude: "-1.56238850",
  },
  {
    id: 2446,
    name: "Derry City and Strabane",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DRS",
    type: null,
    latitude: "55.00474430",
    longitude: "-7.32092220",
  },
  {
    id: 2417,
    name: "Derry City Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DRY",
    type: null,
    latitude: "54.96907780",
    longitude: "-7.19583510",
  },
  {
    id: 2491,
    name: "Devon",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DEV",
    type: null,
    latitude: "50.71555910",
    longitude: "-3.53087500",
  },
  {
    id: 2364,
    name: "Doncaster",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DNC",
    type: null,
    latitude: "53.52282000",
    longitude: "-1.12846200",
  },
  {
    id: 2345,
    name: "Dorset",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DOR",
    type: null,
    latitude: "50.74876350",
    longitude: "-2.34447860",
  },
  {
    id: 2304,
    name: "Down District Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DOW",
    type: null,
    latitude: "54.24342870",
    longitude: "-5.95779590",
  },
  {
    id: 2457,
    name: "Dudley",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DUD",
    type: null,
    latitude: "42.04336610",
    longitude: "-71.92760330",
  },
  {
    id: 2415,
    name: "Dumfries and Galloway",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DGY",
    type: null,
    latitude: "55.07010730",
    longitude: "-3.60525810",
  },
  {
    id: 2511,
    name: "Dundee",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DND",
    type: null,
    latitude: "56.46201800",
    longitude: "-2.97072100",
  },
  {
    id: 2508,
    name: "Dungannon and South Tyrone Borough Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "DGN",
    type: null,
    latitude: "54.50826840",
    longitude: "-6.76658910",
  },
  {
    id: 2374,
    name: "East Ayrshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "EAY",
    type: null,
    latitude: "55.45184960",
    longitude: "-4.26444780",
  },
  {
    id: 2454,
    name: "East Dunbartonshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "EDU",
    type: null,
    latitude: "55.97431620",
    longitude: "-4.20229800",
  },
  {
    id: 2462,
    name: "East Lothian",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ELN",
    type: null,
    latitude: "55.94933830",
    longitude: "-2.77044640",
  },
  {
    id: 2333,
    name: "East Renfrewshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ERW",
    type: null,
    latitude: "55.77047350",
    longitude: "-4.33598210",
  },
  {
    id: 2370,
    name: "East Riding of Yorkshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ERY",
    type: null,
    latitude: "53.84161680",
    longitude: "-0.43441060",
  },
  {
    id: 2414,
    name: "East Sussex",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ESX",
    type: null,
    latitude: "50.90859550",
    longitude: "0.24941660",
  },
  {
    id: 2428,
    name: "Edinburgh",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "EDH",
    type: null,
    latitude: "55.95325200",
    longitude: "-3.18826700",
  },
  {
    id: 2336,
    name: "England",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ENG",
    type: null,
    latitude: "52.35551770",
    longitude: "-1.17431970",
  },
  {
    id: 2410,
    name: "Essex",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ESS",
    type: null,
    latitude: "51.57424470",
    longitude: "0.48567810",
  },
  {
    id: 2344,
    name: "Falkirk",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "FAL",
    type: null,
    latitude: "56.00187750",
    longitude: "-3.78391310",
  },
  {
    id: 2366,
    name: "Fermanagh and Omagh",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "FMO",
    type: null,
    latitude: "54.45135240",
    longitude: "-7.71250180",
  },
  {
    id: 2531,
    name: "Fermanagh District Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "FER",
    type: null,
    latitude: "54.34479780",
    longitude: "-7.63842180",
  },
  {
    id: 2479,
    name: "Fife",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "FIF",
    type: null,
    latitude: "56.20820780",
    longitude: "-3.14951750",
  },
  {
    id: 2437,
    name: "Flintshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "FLN",
    type: null,
    latitude: "53.16686580",
    longitude: "-3.14189080",
  },
  {
    id: 2431,
    name: "Gateshead",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "GAT",
    type: null,
    latitude: "54.95268000",
    longitude: "-1.60341100",
  },
  {
    id: 2404,
    name: "Glasgow",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "GLG",
    type: null,
    latitude: "55.86423700",
    longitude: "-4.25180600",
  },
  {
    id: 2373,
    name: "Gloucestershire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "GLS",
    type: null,
    latitude: "51.86421120",
    longitude: "-2.23803350",
  },
  {
    id: 2379,
    name: "Gwynedd",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "GWN",
    type: null,
    latitude: "52.92772660",
    longitude: "-4.13348360",
  },
  {
    id: 2466,
    name: "Halton",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "HAL",
    type: null,
    latitude: "43.53253720",
    longitude: "-79.87448360",
  },
  {
    id: 2435,
    name: "Hampshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "HAM",
    type: null,
    latitude: "51.05769480",
    longitude: "-1.30806290",
  },
  {
    id: 2309,
    name: "Hartlepool",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "HPL",
    type: null,
    latitude: "54.69174500",
    longitude: "-1.21292600",
  },
  {
    id: 2500,
    name: "Herefordshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "HEF",
    type: null,
    latitude: "52.07651640",
    longitude: "-2.65441820",
  },
  {
    id: 2369,
    name: "Hertfordshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "HRT",
    type: null,
    latitude: "51.80978230",
    longitude: "-0.23767440",
  },
  {
    id: 2383,
    name: "Highland",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "HLD",
    type: null,
    latitude: "36.29675080",
    longitude: "-95.83803660",
  },
  {
    id: 2388,
    name: "Inverclyde",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "IVC",
    type: null,
    latitude: "55.93165690",
    longitude: "-4.68001580",
  },
  {
    id: 2289,
    name: "Isle of Wight",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "IOW",
    type: null,
    latitude: "50.69384790",
    longitude: "-1.30473400",
  },
  {
    id: 2343,
    name: "Isles of Scilly",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "IOS",
    type: null,
    latitude: "49.92772610",
    longitude: "-6.32749660",
  },
  {
    id: 2464,
    name: "Kent",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "KEN",
    type: null,
    latitude: "41.15366740",
    longitude: "-81.35788590",
  },
  {
    id: 2371,
    name: "Kirklees",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "KIR",
    type: null,
    latitude: "53.59334320",
    longitude: "-1.80095090",
  },
  {
    id: 2330,
    name: "Knowsley",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "KWL",
    type: null,
    latitude: "53.45459400",
    longitude: "-2.85290700",
  },
  {
    id: 2495,
    name: "Lancashire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "LAN",
    type: null,
    latitude: "53.76322540",
    longitude: "-2.70440520",
  },
  {
    id: 2515,
    name: "Larne Borough Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "LRN",
    type: null,
    latitude: "54.85780030",
    longitude: "-5.82362240",
  },
  {
    id: 2503,
    name: "Leeds",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "LDS",
    type: null,
    latitude: "53.80075540",
    longitude: "-1.54907740",
  },
  {
    id: 2516,
    name: "Leicestershire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "LEC",
    type: null,
    latitude: "52.77257100",
    longitude: "-1.20521260",
  },
  {
    id: 2382,
    name: "Limavady Borough Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "LMV",
    type: null,
    latitude: "55.05168200",
    longitude: "-6.94919440",
  },
  {
    id: 2355,
    name: "Lincolnshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "LIN",
    type: null,
    latitude: "52.94518890",
    longitude: "-0.16012460",
  },
  {
    id: 2460,
    name: "Lisburn and Castlereagh",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "LBC",
    type: null,
    latitude: "54.49815840",
    longitude: "-6.13067910",
  },
  {
    id: 2494,
    name: "Lisburn City Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "LSB",
    type: null,
    latitude: "54.49815840",
    longitude: "-6.13067910",
  },
  {
    id: 2340,
    name: "Liverpool",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "LIV",
    type: null,
    latitude: "32.65649810",
    longitude: "-115.47632410",
  },
  {
    id: 2356,
    name: "London Borough of Barking and Dagenham",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BDG",
    type: null,
    latitude: "51.55406660",
    longitude: "0.13401700",
  },
  {
    id: 2520,
    name: "London Borough of Barnet",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BNE",
    type: null,
    latitude: "51.60496730",
    longitude: "-0.20762950",
  },
  {
    id: 2307,
    name: "London Borough of Bexley",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BEX",
    type: null,
    latitude: "51.45190210",
    longitude: "0.11717860",
  },
  {
    id: 2291,
    name: "London Borough of Brent",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BEN",
    type: null,
    latitude: "51.56728080",
    longitude: "-0.27105680",
  },
  {
    id: 2490,
    name: "London Borough of Bromley",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "BRY",
    type: null,
    latitude: "51.36797050",
    longitude: "0.07006200",
  },
  {
    id: 2349,
    name: "London Borough of Camden",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CMD",
    type: null,
    latitude: "51.54547360",
    longitude: "-0.16279020",
  },
  {
    id: 2512,
    name: "London Borough of Croydon",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "CRY",
    type: null,
    latitude: "51.38274460",
    longitude: "-0.09851630",
  },
  {
    id: 2532,
    name: "London Borough of Ealing",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "EAL",
    type: null,
    latitude: "51.52503660",
    longitude: "-0.34139650",
  },
  {
    id: 2476,
    name: "London Borough of Enfield",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ENF",
    type: null,
    latitude: "51.66229090",
    longitude: "-0.11806510",
  },
  {
    id: 2411,
    name: "London Borough of Hackney",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "HCK",
    type: null,
    latitude: "51.57344500",
    longitude: "-0.07243760",
  },
  {
    id: 2448,
    name: "London Borough of Hammersmith and Fulham",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "HMF",
    type: null,
    latitude: "51.49901560",
    longitude: "-0.22915000",
  },
  {
    id: 2306,
    name: "London Borough of Haringey",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "HRY",
    type: null,
    latitude: "51.59061130",
    longitude: "-0.11097090",
  },
  {
    id: 2385,
    name: "London Borough of Harrow",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "HRW",
    type: null,
    latitude: "51.58816270",
    longitude: "-0.34228510",
  },
  {
    id: 2347,
    name: "London Borough of Havering",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "HAV",
    type: null,
    latitude: "51.57792400",
    longitude: "0.21208290",
  },
  {
    id: 2376,
    name: "London Borough of Hillingdon",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "HIL",
    type: null,
    latitude: "51.53518320",
    longitude: "-0.44813780",
  },
  {
    id: 2380,
    name: "London Borough of Hounslow",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "HNS",
    type: null,
    latitude: "51.48283580",
    longitude: "-0.38820620",
  },
  {
    id: 2319,
    name: "London Borough of Islington",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ISL",
    type: null,
    latitude: "51.54650630",
    longitude: "-0.10580580",
  },
  {
    id: 2396,
    name: "London Borough of Lambeth",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "LBH",
    type: null,
    latitude: "51.45714770",
    longitude: "-0.12306810",
  },
  {
    id: 2358,
    name: "London Borough of Lewisham",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "LEW",
    type: null,
    latitude: "51.44145790",
    longitude: "-0.01170060",
  },
  {
    id: 2483,
    name: "London Borough of Merton",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "MRT",
    type: null,
    latitude: "51.40977420",
    longitude: "-0.21080840",
  },
  {
    id: 2418,
    name: "London Borough of Newham",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NWM",
    type: null,
    latitude: "51.52551620",
    longitude: "0.03521630",
  },
  {
    id: 2397,
    name: "London Borough of Redbridge",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "RDB",
    type: null,
    latitude: "51.58861210",
    longitude: "0.08239820",
  },
  {
    id: 2501,
    name: "London Borough of Richmond upon Thames",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "RIC",
    type: null,
    latitude: "51.46130540",
    longitude: "-0.30377090",
  },
  {
    id: 2432,
    name: "London Borough of Southwark",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SWK",
    type: null,
    latitude: "51.48805720",
    longitude: "-0.07628380",
  },
  {
    id: 2313,
    name: "London Borough of Sutton",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "STN",
    type: null,
    latitude: "51.35737620",
    longitude: "-0.17527960",
  },
  {
    id: 2390,
    name: "London Borough of Tower Hamlets",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "TWH",
    type: null,
    latitude: "51.52026070",
    longitude: "-0.02933960",
  },
  {
    id: 2326,
    name: "London Borough of Waltham Forest",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WFT",
    type: null,
    latitude: "51.58863830",
    longitude: "-0.01176250",
  },
  {
    id: 2434,
    name: "London Borough of Wandsworth",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WND",
    type: null,
    latitude: "51.45682740",
    longitude: "-0.18966380",
  },
  {
    id: 2322,
    name: "Magherafelt District Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "MFT",
    type: null,
    latitude: "54.75532790",
    longitude: "-6.60774870",
  },
  {
    id: 2398,
    name: "Manchester",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "MAN",
    type: null,
    latitude: "53.48075930",
    longitude: "-2.24263050",
  },
  {
    id: 2381,
    name: "Medway",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "MDW",
    type: null,
    latitude: "42.14176410",
    longitude: "-71.39672560",
  },
  {
    id: 2328,
    name: "Merthyr Tydfil County Borough",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "MTY",
    type: null,
    latitude: "51.74674740",
    longitude: "-3.38132750",
  },
  {
    id: 2320,
    name: "Metropolitan Borough of Wigan",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WGN",
    type: null,
    latitude: "53.51348120",
    longitude: "-2.61069990",
  },
  {
    id: 2429,
    name: "Mid and East Antrim",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "MEA",
    type: null,
    latitude: "54.93993410",
    longitude: "-6.11374230",
  },
  {
    id: 2399,
    name: "Mid Ulster",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "MUL",
    type: null,
    latitude: "54.64113010",
    longitude: "-6.75225490",
  },
  {
    id: 2332,
    name: "Middlesbrough",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "MDB",
    type: null,
    latitude: "54.57422700",
    longitude: "-1.23495600",
  },
  {
    id: 2519,
    name: "Midlothian",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "MLN",
    type: null,
    latitude: "32.47533500",
    longitude: "-97.01031810",
  },
  {
    id: 2416,
    name: "Milton Keynes",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "MIK",
    type: null,
    latitude: "52.08520380",
    longitude: "-0.73331330",
  },
  {
    id: 2402,
    name: "Monmouthshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "MON",
    type: null,
    latitude: "51.81161000",
    longitude: "-2.71634170",
  },
  {
    id: 2360,
    name: "Moray",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "MRY",
    type: null,
    latitude: "57.64984760",
    longitude: "-3.31680390",
  },
  {
    id: 2348,
    name: "Moyle District Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "MYL",
    type: null,
    latitude: "55.20473270",
    longitude: "-6.25317400",
  },
  {
    id: 2351,
    name: "Neath Port Talbot County Borough",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NTL",
    type: null,
    latitude: "51.59785190",
    longitude: "-3.78396680",
  },
  {
    id: 2458,
    name: "Newcastle upon Tyne",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NET",
    type: null,
    latitude: "54.97825200",
    longitude: "-1.61778000",
  },
  {
    id: 2524,
    name: "Newport",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NWP",
    type: null,
    latitude: "37.52782340",
    longitude: "-94.10438760",
  },
  {
    id: 2350,
    name: "Newry and Mourne District Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NYM",
    type: null,
    latitude: "54.17425050",
    longitude: "-6.33919920",
  },
  {
    id: 2534,
    name: "Newry, Mourne and Down",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NMD",
    type: null,
    latitude: "54.24342870",
    longitude: "-5.95779590",
  },
  {
    id: 2317,
    name: "Newtownabbey Borough Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NTA",
    type: null,
    latitude: "54.67924220",
    longitude: "-5.95911020",
  },
  {
    id: 2473,
    name: "Norfolk",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NFK",
    type: null,
    latitude: "36.85076890",
    longitude: "-76.28587260",
  },
  {
    id: 2535,
    name: "North Ayrshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NAY",
    type: null,
    latitude: "55.64167310",
    longitude: "-4.75946000",
  },
  {
    id: 2513,
    name: "North Down Borough Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NDN",
    type: null,
    latitude: "54.65362970",
    longitude: "-5.67249250",
  },
  {
    id: 2384,
    name: "North East Lincolnshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NEL",
    type: null,
    latitude: "53.56682010",
    longitude: "-0.08150660",
  },
  {
    id: 2487,
    name: "North Lanarkshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NLK",
    type: null,
    latitude: "55.86624320",
    longitude: "-3.96131440",
  },
  {
    id: 2453,
    name: "North Lincolnshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NLN",
    type: null,
    latitude: "53.60555920",
    longitude: "-0.55965820",
  },
  {
    id: 2430,
    name: "North Somerset",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NSM",
    type: null,
    latitude: "51.38790280",
    longitude: "-2.77810910",
  },
  {
    id: 2521,
    name: "North Tyneside",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NTY",
    type: null,
    latitude: "55.01823990",
    longitude: "-1.48584360",
  },
  {
    id: 2522,
    name: "North Yorkshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NYK",
    type: null,
    latitude: "53.99150280",
    longitude: "-1.54120150",
  },
  {
    id: 2480,
    name: "Northamptonshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NTH",
    type: null,
    latitude: "52.27299440",
    longitude: "-0.87555150",
  },
  {
    id: 2337,
    name: "Northern Ireland",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NIR",
    type: null,
    latitude: "54.78771490",
    longitude: "-6.49231450",
  },
  {
    id: 2365,
    name: "Northumberland",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NBL",
    type: null,
    latitude: "55.20825420",
    longitude: "-2.07841380",
  },
  {
    id: 2456,
    name: "Nottinghamshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "NTT",
    type: null,
    latitude: "53.10031900",
    longitude: "-0.99363060",
  },
  {
    id: 2477,
    name: "Oldham",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "OLD",
    type: null,
    latitude: "42.20405980",
    longitude: "-71.20481190",
  },
  {
    id: 2314,
    name: "Omagh District Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "OMH",
    type: null,
    latitude: "54.45135240",
    longitude: "-7.71250180",
  },
  {
    id: 2474,
    name: "Orkney Islands",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ORK",
    type: null,
    latitude: "58.98094010",
    longitude: "-2.96052060",
  },
  {
    id: 2353,
    name: "Outer Hebrides",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ELS",
    type: null,
    latitude: "57.75989180",
    longitude: "-7.01940340",
  },
  {
    id: 2321,
    name: "Oxfordshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "OXF",
    type: null,
    latitude: "51.76120560",
    longitude: "-1.24646740",
  },
  {
    id: 2486,
    name: "Pembrokeshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "PEM",
    type: null,
    latitude: "51.67407800",
    longitude: "-4.90887850",
  },
  {
    id: 2325,
    name: "Perth and Kinross",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "PKN",
    type: null,
    latitude: "56.39538170",
    longitude: "-3.42835470",
  },
  {
    id: 2302,
    name: "Poole",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "POL",
    type: null,
    latitude: "50.71505000",
    longitude: "-1.98724800",
  },
  {
    id: 2441,
    name: "Powys",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "POW",
    type: null,
    latitude: "52.64642490",
    longitude: "-3.32609040",
  },
  {
    id: 2455,
    name: "Reading",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "RDG",
    type: null,
    latitude: "36.14866590",
    longitude: "-95.98400120",
  },
  {
    id: 2527,
    name: "Redcar and Cleveland",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "RCC",
    type: null,
    latitude: "54.59713440",
    longitude: "-1.07759970",
  },
  {
    id: 2443,
    name: "Renfrewshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "RFW",
    type: null,
    latitude: "55.84665400",
    longitude: "-4.53312590",
  },
  {
    id: 2301,
    name: "Rhondda Cynon Taf",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "RCT",
    type: null,
    latitude: "51.64902070",
    longitude: "-3.42886920",
  },
  {
    id: 2327,
    name: "Rochdale",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "RCH",
    type: null,
    latitude: "53.60971360",
    longitude: "-2.15610000",
  },
  {
    id: 2308,
    name: "Rotherham",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ROT",
    type: null,
    latitude: "53.43260350",
    longitude: "-1.36350090",
  },
  {
    id: 2492,
    name: "Royal Borough of Greenwich",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "GRE",
    type: null,
    latitude: "51.48346270",
    longitude: "0.05862020",
  },
  {
    id: 2368,
    name: "Royal Borough of Kensington and Chelsea",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "KEC",
    type: null,
    latitude: "51.49908050",
    longitude: "-0.19382530",
  },
  {
    id: 2481,
    name: "Royal Borough of Kingston upon Thames",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "KTT",
    type: null,
    latitude: "51.37811700",
    longitude: "-0.29270900",
  },
  {
    id: 2472,
    name: "Rutland",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "RUT",
    type: null,
    latitude: "43.61062370",
    longitude: "-72.97260650",
  },
  {
    id: 2502,
    name: "Saint Helena",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SH-HL",
    type: null,
    latitude: "-15.96501040",
    longitude: "-5.70892410",
  },
  {
    id: 2493,
    name: "Salford",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SLF",
    type: null,
    latitude: "53.48752350",
    longitude: "-2.29012640",
  },
  {
    id: 2341,
    name: "Sandwell",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SAW",
    type: null,
    latitude: "52.53616740",
    longitude: "-2.01079300",
  },
  {
    id: 2335,
    name: "Scotland",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SCT",
    type: null,
    latitude: "56.49067120",
    longitude: "-4.20264580",
  },
  {
    id: 2346,
    name: "Scottish Borders",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SCB",
    type: null,
    latitude: "55.54856970",
    longitude: "-2.78613880",
  },
  {
    id: 2518,
    name: "Sefton",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SFT",
    type: null,
    latitude: "53.50344490",
    longitude: "-2.97035900",
  },
  {
    id: 2295,
    name: "Sheffield",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SHF",
    type: null,
    latitude: "36.09507430",
    longitude: "-80.27884660",
  },
  {
    id: 2300,
    name: "Shetland Islands",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "ZET",
    type: null,
    latitude: "60.52965070",
    longitude: "-1.26594090",
  },
  {
    id: 2407,
    name: "Shropshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SHR",
    type: null,
    latitude: "52.70636570",
    longitude: "-2.74178490",
  },
  {
    id: 2427,
    name: "Slough",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SLG",
    type: null,
    latitude: "51.51053840",
    longitude: "-0.59504060",
  },
  {
    id: 2469,
    name: "Solihull",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SOL",
    type: null,
    latitude: "52.41181100",
    longitude: "-1.77761000",
  },
  {
    id: 2386,
    name: "Somerset",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SOM",
    type: null,
    latitude: "51.10509700",
    longitude: "-2.92623070",
  },
  {
    id: 2377,
    name: "South Ayrshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SAY",
    type: null,
    latitude: "55.45889880",
    longitude: "-4.62919940",
  },
  {
    id: 2400,
    name: "South Gloucestershire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SGC",
    type: null,
    latitude: "51.52643610",
    longitude: "-2.47284870",
  },
  {
    id: 2362,
    name: "South Lanarkshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SLK",
    type: null,
    latitude: "55.67359090",
    longitude: "-3.78196610",
  },
  {
    id: 2409,
    name: "South Tyneside",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "STY",
    type: null,
    latitude: "54.96366930",
    longitude: "-1.44186340",
  },
  {
    id: 2323,
    name: "Southend-on-Sea",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SOS",
    type: null,
    latitude: "51.54592690",
    longitude: "0.70771230",
  },
  {
    id: 2290,
    name: "St Helens",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SHN",
    type: null,
    latitude: "45.85896100",
    longitude: "-122.82123560",
  },
  {
    id: 2447,
    name: "Staffordshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "STS",
    type: null,
    latitude: "52.87927450",
    longitude: "-2.05718680",
  },
  {
    id: 2488,
    name: "Stirling",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "STG",
    type: null,
    latitude: "56.11652270",
    longitude: "-3.93690290",
  },
  {
    id: 2394,
    name: "Stockport",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SKP",
    type: null,
    latitude: "53.41063160",
    longitude: "-2.15753320",
  },
  {
    id: 2421,
    name: "Stockton-on-Tees",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "STT",
    type: null,
    latitude: "54.57045510",
    longitude: "-1.32898210",
  },
  {
    id: 2393,
    name: "Strabane District Council",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "STB",
    type: null,
    latitude: "54.82738650",
    longitude: "-7.46331030",
  },
  {
    id: 2467,
    name: "Suffolk",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SFK",
    type: null,
    latitude: "52.18724720",
    longitude: "0.97078010",
  },
  {
    id: 2526,
    name: "Surrey",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SRY",
    type: null,
    latitude: "51.31475930",
    longitude: "-0.55995010",
  },
  {
    id: 2422,
    name: "Swindon",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "SWD",
    type: null,
    latitude: "51.55577390",
    longitude: "-1.77971760",
  },
  {
    id: 2367,
    name: "Tameside",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "TAM",
    type: null,
    latitude: "53.48058280",
    longitude: "-2.08098910",
  },
  {
    id: 2310,
    name: "Telford and Wrekin",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "TFW",
    type: null,
    latitude: "52.74099160",
    longitude: "-2.48685860",
  },
  {
    id: 2468,
    name: "Thurrock",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "THR",
    type: null,
    latitude: "51.49345570",
    longitude: "0.35291970",
  },
  {
    id: 2478,
    name: "Torbay",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "TOB",
    type: null,
    latitude: "50.43923290",
    longitude: "-3.53698990",
  },
  {
    id: 2496,
    name: "Torfaen",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "TOF",
    type: null,
    latitude: "51.70022530",
    longitude: "-3.04460150",
  },
  {
    id: 2293,
    name: "Trafford",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "TRF",
    type: null,
    latitude: "40.38562460",
    longitude: "-79.75893470",
  },
  {
    id: 2375,
    name: "United Kingdom",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "UKM",
    type: null,
    latitude: "55.37805100",
    longitude: "-3.43597300",
  },
  {
    id: 2299,
    name: "Vale of Glamorgan",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "VGL",
    type: null,
    latitude: "51.40959580",
    longitude: "-3.48481670",
  },
  {
    id: 2465,
    name: "Wakefield",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WKF",
    type: null,
    latitude: "42.50393950",
    longitude: "-71.07233910",
  },
  {
    id: 2338,
    name: "Wales",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WLS",
    type: null,
    latitude: "52.13066070",
    longitude: "-3.78371170",
  },
  {
    id: 2292,
    name: "Walsall",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WLL",
    type: null,
    latitude: "52.58621400",
    longitude: "-1.98291900",
  },
  {
    id: 2420,
    name: "Warrington",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WRT",
    type: null,
    latitude: "40.24927410",
    longitude: "-75.13406040",
  },
  {
    id: 2505,
    name: "Warwickshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WAR",
    type: null,
    latitude: "52.26713530",
    longitude: "-1.46752160",
  },
  {
    id: 2471,
    name: "West Berkshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WBK",
    type: null,
    latitude: "51.43082550",
    longitude: "-1.14449270",
  },
  {
    id: 2440,
    name: "West Dunbartonshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WDU",
    type: null,
    latitude: "55.94509250",
    longitude: "-4.56462590",
  },
  {
    id: 2354,
    name: "West Lothian",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WLN",
    type: null,
    latitude: "55.90701980",
    longitude: "-3.55171670",
  },
  {
    id: 2296,
    name: "West Sussex",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WSX",
    type: null,
    latitude: "50.92801430",
    longitude: "-0.46170750",
  },
  {
    id: 2391,
    name: "Wiltshire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WIL",
    type: null,
    latitude: "51.34919960",
    longitude: "-1.99271050",
  },
  {
    id: 2482,
    name: "Windsor and Maidenhead",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WNM",
    type: null,
    latitude: "51.47997120",
    longitude: "-0.62425650",
  },
  {
    id: 2408,
    name: "Wirral",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WRL",
    type: null,
    latitude: "53.37271810",
    longitude: "-3.07375400",
  },
  {
    id: 2419,
    name: "Wokingham",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WOK",
    type: null,
    latitude: "51.41045700",
    longitude: "-0.83386100",
  },
  {
    id: 2510,
    name: "Worcestershire",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WOR",
    type: null,
    latitude: "52.25452250",
    longitude: "-2.26683820",
  },
  {
    id: 2436,
    name: "Wrexham County Borough",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    state_code: "WRX",
    type: null,
    latitude: "53.03013780",
    longitude: "-3.02614870",
  },
  {
    id: 1456,
    name: "Alabama",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "AL",
    type: "state",
    latitude: "32.31823140",
    longitude: "-86.90229800",
  },
  {
    id: 1400,
    name: "Alaska",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "AK",
    type: "state",
    latitude: "64.20084130",
    longitude: "-149.49367330",
  },
  {
    id: 1424,
    name: "American Samoa",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "AS",
    type: "outlying area",
    latitude: "-14.27097200",
    longitude: "-170.13221700",
  },
  {
    id: 1434,
    name: "Arizona",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "AZ",
    type: "state",
    latitude: "34.04892810",
    longitude: "-111.09373110",
  },
  {
    id: 1444,
    name: "Arkansas",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "AR",
    type: "state",
    latitude: "35.20105000",
    longitude: "-91.83183340",
  },
  {
    id: 1402,
    name: "Baker Island",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "UM-81",
    type: "islands / groups of islands",
    latitude: "0.19362660",
    longitude: "-176.47690800",
  },
  {
    id: 1416,
    name: "California",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "CA",
    type: "state",
    latitude: "36.77826100",
    longitude: "-119.41793240",
  },
  {
    id: 1450,
    name: "Colorado",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "CO",
    type: "state",
    latitude: "39.55005070",
    longitude: "-105.78206740",
  },
  {
    id: 1435,
    name: "Connecticut",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "CT",
    type: "state",
    latitude: "41.60322070",
    longitude: "-73.08774900",
  },
  {
    id: 1399,
    name: "Delaware",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "DE",
    type: "state",
    latitude: "38.91083250",
    longitude: "-75.52766990",
  },
  {
    id: 1437,
    name: "District of Columbia",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "DC",
    type: "district",
    latitude: "38.90719230",
    longitude: "-77.03687070",
  },
  {
    id: 1436,
    name: "Florida",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "FL",
    type: "state",
    latitude: "27.66482740",
    longitude: "-81.51575350",
  },
  {
    id: 1455,
    name: "Georgia",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "GA",
    type: "state",
    latitude: "32.16562210",
    longitude: "-82.90007510",
  },
  {
    id: 1412,
    name: "Guam",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "GU",
    type: "outlying area",
    latitude: "13.44430400",
    longitude: "144.79373100",
  },
  {
    id: 1411,
    name: "Hawaii",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "HI",
    type: "state",
    latitude: "19.89676620",
    longitude: "-155.58278180",
  },
  {
    id: 1398,
    name: "Howland Island",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "UM-84",
    type: "islands / groups of islands",
    latitude: "0.81132190",
    longitude: "-176.61827360",
  },
  {
    id: 1460,
    name: "Idaho",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "ID",
    type: "state",
    latitude: "44.06820190",
    longitude: "-114.74204080",
  },
  {
    id: 1425,
    name: "Illinois",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "IL",
    type: "state",
    latitude: "40.63312490",
    longitude: "-89.39852830",
  },
  {
    id: 1440,
    name: "Indiana",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "IN",
    type: "state",
    latitude: "40.26719410",
    longitude: "-86.13490190",
  },
  {
    id: 1459,
    name: "Iowa",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "IA",
    type: "state",
    latitude: "41.87800250",
    longitude: "-93.09770200",
  },
  {
    id: 1410,
    name: "Jarvis Island",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "UM-86",
    type: "islands / groups of islands",
    latitude: "-0.37435030",
    longitude: "-159.99672060",
  },
  {
    id: 1428,
    name: "Johnston Atoll",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "UM-67",
    type: "islands / groups of islands",
    latitude: "16.72950350",
    longitude: "-169.53364770",
  },
  {
    id: 1406,
    name: "Kansas",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "KS",
    type: "state",
    latitude: "39.01190200",
    longitude: "-98.48424650",
  },
  {
    id: 1419,
    name: "Kentucky",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "KY",
    type: "state",
    latitude: "37.83933320",
    longitude: "-84.27001790",
  },
  {
    id: 1403,
    name: "Kingman Reef",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "UM-89",
    type: "islands / groups of islands",
    latitude: "6.38333300",
    longitude: "-162.41666700",
  },
  {
    id: 1457,
    name: "Louisiana",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "LA",
    type: "state",
    latitude: "30.98429770",
    longitude: "-91.96233270",
  },
  {
    id: 1453,
    name: "Maine",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "ME",
    type: "state",
    latitude: "45.25378300",
    longitude: "-69.44546890",
  },
  {
    id: 1401,
    name: "Maryland",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "MD",
    type: "state",
    latitude: "39.04575490",
    longitude: "-76.64127120",
  },
  {
    id: 1433,
    name: "Massachusetts",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "MA",
    type: "state",
    latitude: "42.40721070",
    longitude: "-71.38243740",
  },
  {
    id: 1426,
    name: "Michigan",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "MI",
    type: "state",
    latitude: "44.31484430",
    longitude: "-85.60236430",
  },
  {
    id: 1438,
    name: "Midway Atoll",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "UM-71",
    type: "islands / groups of islands",
    latitude: "28.20721680",
    longitude: "-177.37349260",
  },
  {
    id: 1420,
    name: "Minnesota",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "MN",
    type: "state",
    latitude: "46.72955300",
    longitude: "-94.68589980",
  },
  {
    id: 1430,
    name: "Mississippi",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "MS",
    type: "state",
    latitude: "32.35466790",
    longitude: "-89.39852830",
  },
  {
    id: 1451,
    name: "Missouri",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "MO",
    type: "state",
    latitude: "37.96425290",
    longitude: "-91.83183340",
  },
  {
    id: 1446,
    name: "Montana",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "MT",
    type: "state",
    latitude: "46.87968220",
    longitude: "-110.36256580",
  },
  {
    id: 1439,
    name: "Navassa Island",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "UM-76",
    type: "islands / groups of islands",
    latitude: "18.41006890",
    longitude: "-75.01146120",
  },
  {
    id: 1408,
    name: "Nebraska",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "NE",
    type: "state",
    latitude: "41.49253740",
    longitude: "-99.90181310",
  },
  {
    id: 1458,
    name: "Nevada",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "NV",
    type: "state",
    latitude: "38.80260970",
    longitude: "-116.41938900",
  },
  {
    id: 1404,
    name: "New Hampshire",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "NH",
    type: "state",
    latitude: "43.19385160",
    longitude: "-71.57239530",
  },
  {
    id: 1417,
    name: "New Jersey",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "NJ",
    type: "state",
    latitude: "40.05832380",
    longitude: "-74.40566120",
  },
  {
    id: 1423,
    name: "New Mexico",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "NM",
    type: "state",
    latitude: "34.51994020",
    longitude: "-105.87009010",
  },
  {
    id: 1452,
    name: "New York",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "NY",
    type: "state",
    latitude: "40.71277530",
    longitude: "-74.00597280",
  },
  {
    id: 1447,
    name: "North Carolina",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "NC",
    type: "state",
    latitude: "35.75957310",
    longitude: "-79.01929970",
  },
  {
    id: 1418,
    name: "North Dakota",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "ND",
    type: "state",
    latitude: "47.55149260",
    longitude: "-101.00201190",
  },
  {
    id: 1431,
    name: "Northern Mariana Islands",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "MP",
    type: "outlying area",
    latitude: "15.09790000",
    longitude: "145.67390000",
  },
  {
    id: 4851,
    name: "Ohio",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "OH",
    type: "state",
    latitude: "40.41728710",
    longitude: "-82.90712300",
  },
  {
    id: 1421,
    name: "Oklahoma",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "OK",
    type: "state",
    latitude: "35.46756020",
    longitude: "-97.51642760",
  },
  {
    id: 1415,
    name: "Oregon",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "OR",
    type: "state",
    latitude: "43.80413340",
    longitude: "-120.55420120",
  },
  {
    id: 1448,
    name: "Palmyra Atoll",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "UM-95",
    type: "islands / groups of islands",
    latitude: "5.88850260",
    longitude: "-162.07866560",
  },
  {
    id: 1422,
    name: "Pennsylvania",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "PA",
    type: "state",
    latitude: "41.20332160",
    longitude: "-77.19452470",
  },
  {
    id: 1449,
    name: "Puerto Rico",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "PR",
    type: "outlying area",
    latitude: "18.22083300",
    longitude: "-66.59014900",
  },
  {
    id: 1461,
    name: "Rhode Island",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "RI",
    type: "state",
    latitude: "41.58009450",
    longitude: "-71.47742910",
  },
  {
    id: 1443,
    name: "South Carolina",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "SC",
    type: "state",
    latitude: "33.83608100",
    longitude: "-81.16372450",
  },
  {
    id: 1445,
    name: "South Dakota",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "SD",
    type: "state",
    latitude: "43.96951480",
    longitude: "-99.90181310",
  },
  {
    id: 1454,
    name: "Tennessee",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "TN",
    type: "state",
    latitude: "35.51749130",
    longitude: "-86.58044730",
  },
  {
    id: 1407,
    name: "Texas",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "TX",
    type: "state",
    latitude: "31.96859880",
    longitude: "-99.90181310",
  },
  {
    id: 1432,
    name: "United States Minor Outlying Islands",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "UM",
    type: "outlying area",
    latitude: "19.28231920",
    longitude: "166.64704700",
  },
  {
    id: 1413,
    name: "United States Virgin Islands",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "VI",
    type: "outlying area",
    latitude: "18.33576500",
    longitude: "-64.89633500",
  },
  {
    id: 1414,
    name: "Utah",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "UT",
    type: "state",
    latitude: "39.32098010",
    longitude: "-111.09373110",
  },
  {
    id: 1409,
    name: "Vermont",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "VT",
    type: "state",
    latitude: "44.55880280",
    longitude: "-72.57784150",
  },
  {
    id: 1427,
    name: "Virginia",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "VA",
    type: "state",
    latitude: "37.43157340",
    longitude: "-78.65689420",
  },
  {
    id: 1405,
    name: "Wake Island",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "UM-79",
    type: "islands / groups of islands",
    latitude: "19.27961900",
    longitude: "166.64993480",
  },
  {
    id: 1462,
    name: "Washington",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "WA",
    type: "state",
    latitude: "47.75107410",
    longitude: "-120.74013850",
  },
  {
    id: 1429,
    name: "West Virginia",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "WV",
    type: "state",
    latitude: "38.59762620",
    longitude: "-80.45490260",
  },
  {
    id: 1441,
    name: "Wisconsin",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "WI",
    type: "state",
    latitude: "43.78443970",
    longitude: "-88.78786780",
  },
  {
    id: 1442,
    name: "Wyoming",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    state_code: "WY",
    type: "state",
    latitude: "43.07596780",
    longitude: "-107.29028390",
  },
  {
    id: 5212,
    name: "Baker Island",
    country_id: 234,
    country_code: "UM",
    country_name: "United States Minor Outlying Islands",
    state_code: "81",
    type: "island",
    latitude: "0.19362660",
    longitude: "-176.47690800",
  },
  {
    id: 5213,
    name: "Howland Island",
    country_id: 234,
    country_code: "UM",
    country_name: "United States Minor Outlying Islands",
    state_code: "84",
    type: "island",
    latitude: "0.81132190",
    longitude: "-176.61827360",
  },
  {
    id: 5214,
    name: "Jarvis Island",
    country_id: 234,
    country_code: "UM",
    country_name: "United States Minor Outlying Islands",
    state_code: "86",
    type: "island",
    latitude: "-0.37435030",
    longitude: "-159.99672060",
  },
  {
    id: 5215,
    name: "Johnston Atoll",
    country_id: 234,
    country_code: "UM",
    country_name: "United States Minor Outlying Islands",
    state_code: "67",
    type: "island",
    latitude: "16.72950350",
    longitude: "-169.53364770",
  },
  {
    id: 5216,
    name: "Kingman Reef",
    country_id: 234,
    country_code: "UM",
    country_name: "United States Minor Outlying Islands",
    state_code: "89",
    type: "island",
    latitude: "6.38333300",
    longitude: "-162.41666700",
  },
  {
    id: 5217,
    name: "Midway Islands",
    country_id: 234,
    country_code: "UM",
    country_name: "United States Minor Outlying Islands",
    state_code: "71",
    type: "island",
    latitude: "28.20721680",
    longitude: "-177.37349260",
  },
  {
    id: 5218,
    name: "Navassa Island",
    country_id: 234,
    country_code: "UM",
    country_name: "United States Minor Outlying Islands",
    state_code: "76",
    type: "island",
    latitude: "18.41006890",
    longitude: "-75.01146120",
  },
  {
    id: 5219,
    name: "Palmyra Atoll",
    country_id: 234,
    country_code: "UM",
    country_name: "United States Minor Outlying Islands",
    state_code: "95",
    type: "island",
    latitude: "5.88850260",
    longitude: "-162.07866560",
  },
  {
    id: 5220,
    name: "Wake Island",
    country_id: 234,
    country_code: "UM",
    country_name: "United States Minor Outlying Islands",
    state_code: "79",
    type: "island",
    latitude: "19.27961900",
    longitude: "166.64993480",
  },
  {
    id: 3205,
    name: "Artigas Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "AR",
    type: null,
    latitude: "-30.61751120",
    longitude: "-56.95945590",
  },
  {
    id: 3213,
    name: "Canelones Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "CA",
    type: null,
    latitude: "-34.54087170",
    longitude: "-55.93076000",
  },
  {
    id: 3211,
    name: "Cerro Largo Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "CL",
    type: null,
    latitude: "-32.44110320",
    longitude: "-54.35217530",
  },
  {
    id: 3208,
    name: "Colonia Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "CO",
    type: null,
    latitude: "-34.12946780",
    longitude: "-57.66051840",
  },
  {
    id: 3209,
    name: "Durazno Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "DU",
    type: null,
    latitude: "-33.02324540",
    longitude: "-56.02846440",
  },
  {
    id: 3203,
    name: "Flores Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "FS",
    type: null,
    latitude: "-33.57337530",
    longitude: "-56.89450280",
  },
  {
    id: 3217,
    name: "Florida Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "FD",
    type: null,
    latitude: "28.03594950",
    longitude: "-82.45792890",
  },
  {
    id: 3215,
    name: "Lavalleja Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "LA",
    type: null,
    latitude: "-33.92261750",
    longitude: "-54.97657940",
  },
  {
    id: 3206,
    name: "Maldonado Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "MA",
    type: null,
    latitude: "-34.55979320",
    longitude: "-54.86285520",
  },
  {
    id: 3218,
    name: "Montevideo Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "MO",
    type: null,
    latitude: "-34.81815870",
    longitude: "-56.21382560",
  },
  {
    id: 3212,
    name: "Paysandú Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "PA",
    type: null,
    latitude: "-32.06673660",
    longitude: "-57.33647890",
  },
  {
    id: 3210,
    name: "Río Negro Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "RN",
    type: null,
    latitude: "-32.76763560",
    longitude: "-57.42952070",
  },
  {
    id: 3207,
    name: "Rivera Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "RV",
    type: null,
    latitude: "-31.48174210",
    longitude: "-55.24357590",
  },
  {
    id: 3216,
    name: "Rocha Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "RO",
    type: null,
    latitude: "-33.96900810",
    longitude: "-54.02148500",
  },
  {
    id: 3220,
    name: "Salto Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "SA",
    type: null,
    latitude: "-31.38802800",
    longitude: "-57.96124550",
  },
  {
    id: 3204,
    name: "San José Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "SJ",
    type: null,
    latitude: "37.34929680",
    longitude: "-121.90560490",
  },
  {
    id: 3219,
    name: "Soriano Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "SO",
    type: null,
    latitude: "-33.51027920",
    longitude: "-57.74981030",
  },
  {
    id: 3221,
    name: "Tacuarembó Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "TA",
    type: null,
    latitude: "-31.72068370",
    longitude: "-55.98598870",
  },
  {
    id: 3214,
    name: "Treinta y Tres Department",
    country_id: 235,
    country_code: "UY",
    country_name: "Uruguay",
    state_code: "TT",
    type: null,
    latitude: "-33.06850860",
    longitude: "-54.28586270",
  },
  {
    id: 2540,
    name: "Andijan Region",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "AN",
    type: null,
    latitude: "40.76859410",
    longitude: "72.23637900",
  },
  {
    id: 2541,
    name: "Bukhara Region",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "BU",
    type: null,
    latitude: "40.25041620",
    longitude: "63.20321510",
  },
  {
    id: 2538,
    name: "Fergana Region",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "FA",
    type: null,
    latitude: "40.45680810",
    longitude: "71.28742090",
  },
  {
    id: 2545,
    name: "Jizzakh Region",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "JI",
    type: null,
    latitude: "40.47064150",
    longitude: "67.57085360",
  },
  {
    id: 2548,
    name: "Karakalpakstan",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "QR",
    type: null,
    latitude: "43.80413340",
    longitude: "59.44579880",
  },
  {
    id: 2537,
    name: "Namangan Region",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "NG",
    type: null,
    latitude: "41.05100370",
    longitude: "71.09731700",
  },
  {
    id: 2542,
    name: "Navoiy Region",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "NW",
    type: null,
    latitude: "42.69885750",
    longitude: "64.63376850",
  },
  {
    id: 2543,
    name: "Qashqadaryo Region",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "QA",
    type: null,
    latitude: "38.89862310",
    longitude: "66.04635340",
  },
  {
    id: 2544,
    name: "Samarqand Region",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "SA",
    type: null,
    latitude: "39.62701200",
    longitude: "66.97497310",
  },
  {
    id: 2547,
    name: "Sirdaryo Region",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "SI",
    type: null,
    latitude: "40.38638080",
    longitude: "68.71549750",
  },
  {
    id: 2546,
    name: "Surxondaryo Region",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "SU",
    type: null,
    latitude: "37.94090050",
    longitude: "67.57085360",
  },
  {
    id: 2536,
    name: "Tashkent",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "TK",
    type: null,
    latitude: "41.29949580",
    longitude: "69.24007340",
  },
  {
    id: 2549,
    name: "Tashkent Region",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "TO",
    type: null,
    latitude: "41.22132340",
    longitude: "69.85974060",
  },
  {
    id: 2539,
    name: "Xorazm Region",
    country_id: 236,
    country_code: "UZ",
    country_name: "Uzbekistan",
    state_code: "XO",
    type: null,
    latitude: "41.35653360",
    longitude: "60.85666860",
  },
  {
    id: 4775,
    name: "Malampa",
    country_id: 237,
    country_code: "VU",
    country_name: "Vanuatu",
    state_code: "MAP",
    type: null,
    latitude: "-16.40114050",
    longitude: "167.60778650",
  },
  {
    id: 4773,
    name: "Penama",
    country_id: 237,
    country_code: "VU",
    country_name: "Vanuatu",
    state_code: "PAM",
    type: null,
    latitude: "-15.37957580",
    longitude: "167.90531820",
  },
  {
    id: 4776,
    name: "Sanma",
    country_id: 237,
    country_code: "VU",
    country_name: "Vanuatu",
    state_code: "SAM",
    type: null,
    latitude: "-15.48400170",
    longitude: "166.91820970",
  },
  {
    id: 4774,
    name: "Shefa",
    country_id: 237,
    country_code: "VU",
    country_name: "Vanuatu",
    state_code: "SEE",
    type: null,
    latitude: "32.80576500",
    longitude: "35.16997100",
  },
  {
    id: 4777,
    name: "Tafea",
    country_id: 237,
    country_code: "VU",
    country_name: "Vanuatu",
    state_code: "TAE",
    type: null,
    latitude: "-18.72378270",
    longitude: "169.06450560",
  },
  {
    id: 4772,
    name: "Torba",
    country_id: 237,
    country_code: "VU",
    country_name: "Vanuatu",
    state_code: "TOB",
    type: null,
    latitude: "37.07653000",
    longitude: "27.45657300",
  },
  {
    id: 2044,
    name: "Amazonas",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "Z",
    type: "state",
    latitude: "-3.41684270",
    longitude: "-65.85606460",
  },
  {
    id: 2050,
    name: "Anzoátegui",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "B",
    type: "state",
    latitude: "8.59130730",
    longitude: "-63.95861110",
  },
  {
    id: 4856,
    name: "Apure",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "C",
    type: "state",
    latitude: "6.92694830",
    longitude: "-68.52471490",
  },
  {
    id: 2047,
    name: "Aragua",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "D",
    type: "state",
    latitude: "10.06357580",
    longitude: "-67.28478750",
  },
  {
    id: 2049,
    name: "Barinas",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "E",
    type: "state",
    latitude: "8.62314980",
    longitude: "-70.23710450",
  },
  {
    id: 2039,
    name: "Bolívar",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "F",
    type: "state",
    latitude: "37.61448380",
    longitude: "-93.41047490",
  },
  {
    id: 2040,
    name: "Carabobo",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "G",
    type: "state",
    latitude: "10.11764330",
    longitude: "-68.04775090",
  },
  {
    id: 2034,
    name: "Cojedes",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "H",
    type: "state",
    latitude: "9.38166820",
    longitude: "-68.33392750",
  },
  {
    id: 2051,
    name: "Delta Amacuro",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "Y",
    type: "state",
    latitude: "8.84993070",
    longitude: "-61.14031960",
  },
  {
    id: 4855,
    name: "Distrito Capital",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "A",
    type: "capital district",
    latitude: "41.26148460",
    longitude: "-95.93108070",
  },
  {
    id: 2035,
    name: "Falcón",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "I",
    type: "state",
    latitude: "11.18106740",
    longitude: "-69.85974060",
  },
  {
    id: 2046,
    name: "Federal Dependencies of Venezuela",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "W",
    type: "federal dependency",
    latitude: "10.93770530",
    longitude: "-65.35695730",
  },
  {
    id: 2045,
    name: "Guárico",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "J",
    type: "state",
    latitude: "8.74893090",
    longitude: "-66.23671720",
  },
  {
    id: 2055,
    name: "La Guaira",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "X",
    type: "state",
    latitude: "29.30522680",
    longitude: "-94.79138540",
  },
  {
    id: 2038,
    name: "Lara",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "K",
    type: "state",
    latitude: "33.98221650",
    longitude: "-118.13227470",
  },
  {
    id: 2053,
    name: "Mérida",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "L",
    type: "state",
    latitude: "20.96737020",
    longitude: "-89.59258570",
  },
  {
    id: 2037,
    name: "Miranda",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "M",
    type: "state",
    latitude: "42.35193830",
    longitude: "-71.52907660",
  },
  {
    id: 2054,
    name: "Monagas",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "N",
    type: "state",
    latitude: "9.32416520",
    longitude: "-63.01475780",
  },
  {
    id: 2052,
    name: "Nueva Esparta",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "O",
    type: "state",
    latitude: "10.99707230",
    longitude: "-63.91132960",
  },
  {
    id: 2036,
    name: "Portuguesa",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "P",
    type: "state",
    latitude: "9.09439990",
    longitude: "-69.09702300",
  },
  {
    id: 2056,
    name: "Sucre",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "R",
    type: "state",
    latitude: "-19.03534500",
    longitude: "-65.25921280",
  },
  {
    id: 2048,
    name: "Táchira",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "S",
    type: "state",
    latitude: "7.91370010",
    longitude: "-72.14161320",
  },
  {
    id: 2043,
    name: "Trujillo",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "T",
    type: "state",
    latitude: "36.67343430",
    longitude: "-121.62875880",
  },
  {
    id: 2041,
    name: "Yaracuy",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "U",
    type: "state",
    latitude: "10.33938900",
    longitude: "-68.81088490",
  },
  {
    id: 2042,
    name: "Zulia",
    country_id: 239,
    country_code: "VE",
    country_name: "Venezuela",
    state_code: "V",
    type: "state",
    latitude: "10.29102370",
    longitude: "-72.14161320",
  },
  {
    id: 3794,
    name: "An Giang",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "44",
    type: null,
    latitude: "10.52158360",
    longitude: "105.12589550",
  },
  {
    id: 3770,
    name: "Bà Rịa-Vũng Tàu",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "43",
    type: null,
    latitude: "10.54173970",
    longitude: "107.24299760",
  },
  {
    id: 3815,
    name: "Bắc Giang",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "54",
    type: null,
    latitude: "21.28199210",
    longitude: "106.19747690",
  },
  {
    id: 3822,
    name: "Bắc Kạn",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "53",
    type: null,
    latitude: "22.30329230",
    longitude: "105.87600400",
  },
  {
    id: 3804,
    name: "Bạc Liêu",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "55",
    type: null,
    latitude: "9.29400270",
    longitude: "105.72156630",
  },
  {
    id: 3791,
    name: "Bắc Ninh",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "56",
    type: null,
    latitude: "21.12144400",
    longitude: "106.11105010",
  },
  {
    id: 3796,
    name: "Bến Tre",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "50",
    type: null,
    latitude: "10.24335560",
    longitude: "106.37555100",
  },
  {
    id: 3785,
    name: "Bình Dương",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "57",
    type: null,
    latitude: "11.32540240",
    longitude: "106.47701700",
  },
  {
    id: 3830,
    name: "Bình Định",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "31",
    type: null,
    latitude: "14.16653240",
    longitude: "108.90268300",
  },
  {
    id: 3797,
    name: "Bình Phước",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "58",
    type: null,
    latitude: "11.75118940",
    longitude: "106.72346390",
  },
  {
    id: 3787,
    name: "Bình Thuận",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "40",
    type: null,
    latitude: "11.09037030",
    longitude: "108.07207810",
  },
  {
    id: 3778,
    name: "Cà Mau",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "59",
    type: null,
    latitude: "9.15267280",
    longitude: "105.19607950",
  },
  {
    id: 4925,
    name: "Cần Thơ",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "CT",
    type: null,
    latitude: "10.03418510",
    longitude: "105.72255070",
  },
  {
    id: 3782,
    name: "Cao Bằng",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "04",
    type: null,
    latitude: "22.63568900",
    longitude: "106.25221430",
  },
  {
    id: 3806,
    name: "Đà Nẵng",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "DN",
    type: null,
    latitude: "16.05440680",
    longitude: "108.20216670",
  },
  {
    id: 3829,
    name: "Đắk Lắk",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "33",
    type: null,
    latitude: "12.71001160",
    longitude: "108.23775190",
  },
  {
    id: 3823,
    name: "Đắk Nông",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "72",
    type: null,
    latitude: "12.26464760",
    longitude: "107.60980600",
  },
  {
    id: 3773,
    name: "Điện Biên",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "71",
    type: null,
    latitude: "21.80423090",
    longitude: "103.10765250",
  },
  {
    id: 3821,
    name: "Đồng Nai",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "39",
    type: null,
    latitude: "11.06863050",
    longitude: "107.16759760",
  },
  {
    id: 3769,
    name: "Đồng Tháp",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "45",
    type: null,
    latitude: "10.49379890",
    longitude: "105.68817880",
  },
  {
    id: 3813,
    name: "Gia Lai",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "30",
    type: null,
    latitude: "13.80789430",
    longitude: "108.10937500",
  },
  {
    id: 3779,
    name: "Hà Giang",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "03",
    type: null,
    latitude: "22.80255880",
    longitude: "104.97844940",
  },
  {
    id: 3802,
    name: "Hà Nam",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "63",
    type: null,
    latitude: "20.58351960",
    longitude: "105.92299000",
  },
  {
    id: 3810,
    name: "Hà Nội",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "HN",
    type: null,
    latitude: "21.02776440",
    longitude: "105.83415980",
  },
  {
    id: 3816,
    name: "Hà Tĩnh",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "23",
    type: null,
    latitude: "18.35595370",
    longitude: "105.88774940",
  },
  {
    id: 3827,
    name: "Hải Dương",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "61",
    type: null,
    latitude: "20.93734130",
    longitude: "106.31455420",
  },
  {
    id: 3783,
    name: "Hải Phòng",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "HP",
    type: null,
    latitude: "20.84491150",
    longitude: "106.68808410",
  },
  {
    id: 3777,
    name: "Hậu Giang",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "73",
    type: null,
    latitude: "9.75789800",
    longitude: "105.64125270",
  },
  {
    id: 3811,
    name: "Hồ Chí Minh",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "SG",
    type: null,
    latitude: "10.82309890",
    longitude: "106.62966380",
  },
  {
    id: 3799,
    name: "Hòa Bình",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "14",
    type: null,
    latitude: "20.68612650",
    longitude: "105.31311850",
  },
  {
    id: 3768,
    name: "Hưng Yên",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "66",
    type: null,
    latitude: "20.85257110",
    longitude: "106.01699710",
  },
  {
    id: 3793,
    name: "Khánh Hòa",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "34",
    type: null,
    latitude: "12.25850980",
    longitude: "109.05260760",
  },
  {
    id: 3800,
    name: "Kiên Giang",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "47",
    type: null,
    latitude: "9.82495870",
    longitude: "105.12589550",
  },
  {
    id: 3772,
    name: "Kon Tum",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "28",
    type: null,
    latitude: "14.34974030",
    longitude: "108.00046060",
  },
  {
    id: 3825,
    name: "Lai Châu",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "01",
    type: null,
    latitude: "22.38622270",
    longitude: "103.47026310",
  },
  {
    id: 3818,
    name: "Lâm Đồng",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "35",
    type: null,
    latitude: "11.57527910",
    longitude: "108.14286690",
  },
  {
    id: 3792,
    name: "Lạng Sơn",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "09",
    type: null,
    latitude: "21.85370800",
    longitude: "106.76151900",
  },
  {
    id: 3817,
    name: "Lào Cai",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "02",
    type: null,
    latitude: "22.48094310",
    longitude: "103.97549590",
  },
  {
    id: 3808,
    name: "Long An",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "41",
    type: null,
    latitude: "10.56071680",
    longitude: "106.64976230",
  },
  {
    id: 3789,
    name: "Nam Định",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "67",
    type: null,
    latitude: "20.43882250",
    longitude: "106.16210530",
  },
  {
    id: 3780,
    name: "Nghệ An",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "22",
    type: null,
    latitude: "19.23424890",
    longitude: "104.92003650",
  },
  {
    id: 3786,
    name: "Ninh Bình",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "18",
    type: null,
    latitude: "20.25061490",
    longitude: "105.97445360",
  },
  {
    id: 3788,
    name: "Ninh Thuận",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "36",
    type: null,
    latitude: "11.67387670",
    longitude: "108.86295720",
  },
  {
    id: 3801,
    name: "Phú Thọ",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "68",
    type: null,
    latitude: "21.26844300",
    longitude: "105.20455730",
  },
  {
    id: 3824,
    name: "Phú Yên",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "32",
    type: null,
    latitude: "13.08818610",
    longitude: "109.09287640",
  },
  {
    id: 3809,
    name: "Quảng Bình",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "24",
    type: null,
    latitude: "17.61027150",
    longitude: "106.34874740",
  },
  {
    id: 3776,
    name: "Quảng Nam",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "27",
    type: null,
    latitude: "15.53935380",
    longitude: "108.01910200",
  },
  {
    id: 3828,
    name: "Quảng Ngãi",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "29",
    type: null,
    latitude: "15.12138730",
    longitude: "108.80441450",
  },
  {
    id: 3814,
    name: "Quảng Ninh",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "13",
    type: null,
    latitude: "21.00638200",
    longitude: "107.29251440",
  },
  {
    id: 3803,
    name: "Quảng Trị",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "25",
    type: null,
    latitude: "16.74030740",
    longitude: "107.18546790",
  },
  {
    id: 3819,
    name: "Sóc Trăng",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "52",
    type: null,
    latitude: "9.60252100",
    longitude: "105.97390490",
  },
  {
    id: 3812,
    name: "Sơn La",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "05",
    type: null,
    latitude: "21.10222840",
    longitude: "103.72891670",
  },
  {
    id: 3826,
    name: "Tây Ninh",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "37",
    type: null,
    latitude: "11.33515540",
    longitude: "106.10988540",
  },
  {
    id: 3775,
    name: "Thái Bình",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "20",
    type: null,
    latitude: "20.44634710",
    longitude: "106.33658280",
  },
  {
    id: 3807,
    name: "Thái Nguyên",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "69",
    type: null,
    latitude: "21.56715590",
    longitude: "105.82520380",
  },
  {
    id: 3771,
    name: "Thanh Hóa",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "21",
    type: null,
    latitude: "19.80669200",
    longitude: "105.78518160",
  },
  {
    id: 3798,
    name: "Thừa Thiên-Huế",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "26",
    type: null,
    latitude: "16.46739700",
    longitude: "107.59053260",
  },
  {
    id: 3781,
    name: "Tiền Giang",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "46",
    type: null,
    latitude: "10.44933240",
    longitude: "106.34205040",
  },
  {
    id: 3805,
    name: "Trà Vinh",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "51",
    type: null,
    latitude: "9.81274100",
    longitude: "106.29929120",
  },
  {
    id: 3795,
    name: "Tuyên Quang",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "07",
    type: null,
    latitude: "21.77672460",
    longitude: "105.22801960",
  },
  {
    id: 3790,
    name: "Vĩnh Long",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "49",
    type: null,
    latitude: "10.23957400",
    longitude: "105.95719280",
  },
  {
    id: 3774,
    name: "Vĩnh Phúc",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "70",
    type: null,
    latitude: "21.36088050",
    longitude: "105.54743730",
  },
  {
    id: 3784,
    name: "Yên Bái",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    state_code: "06",
    type: null,
    latitude: "21.71676890",
    longitude: "104.89858780",
  },
  {
    id: 5074,
    name: "Saint Croix",
    country_id: 242,
    country_code: "VI",
    country_name: "Virgin Islands (US)",
    state_code: "SC",
    type: "district",
    latitude: "17.72935200",
    longitude: "-64.73437050",
  },
  {
    id: 5073,
    name: "Saint John",
    country_id: 242,
    country_code: "VI",
    country_name: "Virgin Islands (US)",
    state_code: "SJ",
    type: "district",
    latitude: "18.33561690",
    longitude: "-64.80028000",
  },
  {
    id: 5072,
    name: "Saint Thomas",
    country_id: 242,
    country_code: "VI",
    country_name: "Virgin Islands (US)",
    state_code: "ST",
    type: "district",
    latitude: "18.34284590",
    longitude: "-65.07701800",
  },
  {
    id: 1242,
    name: "'Adan",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "AD",
    type: null,
    latitude: "12.82574810",
    longitude: "44.79438040",
  },
  {
    id: 1250,
    name: "'Amran",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "AM",
    type: null,
    latitude: "16.25692140",
    longitude: "43.94367880",
  },
  {
    id: 1237,
    name: "Abyan",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "AB",
    type: null,
    latitude: "13.63434130",
    longitude: "46.05632120",
  },
  {
    id: 1240,
    name: "Al Bayda'",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "BA",
    type: null,
    latitude: "14.35886620",
    longitude: "45.44980650",
  },
  {
    id: 1241,
    name: "Al Hudaydah",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "HU",
    type: null,
    latitude: "15.30530720",
    longitude: "43.01948970",
  },
  {
    id: 1243,
    name: "Al Jawf",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "JA",
    type: null,
    latitude: "16.79018190",
    longitude: "45.29938620",
  },
  {
    id: 1251,
    name: "Al Mahrah",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "MR",
    type: null,
    latitude: "16.52384230",
    longitude: "51.68342750",
  },
  {
    id: 1235,
    name: "Al Mahwit",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "MW",
    type: null,
    latitude: "15.39632290",
    longitude: "43.56069460",
  },
  {
    id: 1232,
    name: "Amanat Al Asimah",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "SA",
    type: null,
    latitude: "15.36944510",
    longitude: "44.19100660",
  },
  {
    id: 1246,
    name: "Dhamar",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "DH",
    type: null,
    latitude: "14.71953440",
    longitude: "44.24790150",
  },
  {
    id: 1238,
    name: "Hadhramaut",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "HD",
    type: null,
    latitude: "16.93041350",
    longitude: "49.36531490",
  },
  {
    id: 1244,
    name: "Hajjah",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "HJ",
    type: null,
    latitude: "16.11806310",
    longitude: "43.32946600",
  },
  {
    id: 1233,
    name: "Ibb",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "IB",
    type: null,
    latitude: "14.14157170",
    longitude: "44.24790150",
  },
  {
    id: 1245,
    name: "Lahij",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "LA",
    type: null,
    latitude: "13.14895880",
    longitude: "44.85054950",
  },
  {
    id: 1234,
    name: "Ma'rib",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "MA",
    type: null,
    latitude: "15.51588800",
    longitude: "45.44980650",
  },
  {
    id: 1248,
    name: "Raymah",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "RA",
    type: null,
    latitude: "14.62776820",
    longitude: "43.71424840",
  },
  {
    id: 1249,
    name: "Saada",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "SD",
    type: null,
    latitude: "16.84765280",
    longitude: "43.94367880",
  },
  {
    id: 1236,
    name: "Sana'a",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "SN",
    type: null,
    latitude: "15.31689130",
    longitude: "44.47480180",
  },
  {
    id: 1247,
    name: "Shabwah",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "SH",
    type: null,
    latitude: "14.75463030",
    longitude: "46.51626200",
  },
  {
    id: 1239,
    name: "Socotra",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "SU",
    type: null,
    latitude: "12.46342050",
    longitude: "53.82373850",
  },
  {
    id: 1231,
    name: "Ta'izz",
    country_id: 245,
    country_code: "YE",
    country_name: "Yemen",
    state_code: "TA",
    type: null,
    latitude: "13.57758860",
    longitude: "44.01779890",
  },
  {
    id: 1986,
    name: "Central Province",
    country_id: 246,
    country_code: "ZM",
    country_name: "Zambia",
    state_code: "02",
    type: null,
    latitude: "7.25649960",
    longitude: "80.72144170",
  },
  {
    id: 1984,
    name: "Copperbelt Province",
    country_id: 246,
    country_code: "ZM",
    country_name: "Zambia",
    state_code: "08",
    type: null,
    latitude: "-13.05700730",
    longitude: "27.54958460",
  },
  {
    id: 1991,
    name: "Eastern Province",
    country_id: 246,
    country_code: "ZM",
    country_name: "Zambia",
    state_code: "03",
    type: null,
    latitude: "23.16696880",
    longitude: "49.36531490",
  },
  {
    id: 1987,
    name: "Luapula Province",
    country_id: 246,
    country_code: "ZM",
    country_name: "Zambia",
    state_code: "04",
    type: null,
    latitude: "-11.56483100",
    longitude: "29.04599270",
  },
  {
    id: 1988,
    name: "Lusaka Province",
    country_id: 246,
    country_code: "ZM",
    country_name: "Zambia",
    state_code: "09",
    type: null,
    latitude: "-15.36571290",
    longitude: "29.23207840",
  },
  {
    id: 1989,
    name: "Muchinga Province",
    country_id: 246,
    country_code: "ZM",
    country_name: "Zambia",
    state_code: "10",
    type: null,
    latitude: "-15.38219300",
    longitude: "28.26158000",
  },
  {
    id: 1982,
    name: "Northern Province",
    country_id: 246,
    country_code: "ZM",
    country_name: "Zambia",
    state_code: "05",
    type: null,
    latitude: "8.88550270",
    longitude: "80.27673270",
  },
  {
    id: 1985,
    name: "Northwestern Province",
    country_id: 246,
    country_code: "ZM",
    country_name: "Zambia",
    state_code: "06",
    type: null,
    latitude: "-13.00502580",
    longitude: "24.90422080",
  },
  {
    id: 1990,
    name: "Southern Province",
    country_id: 246,
    country_code: "ZM",
    country_name: "Zambia",
    state_code: "07",
    type: null,
    latitude: "6.23737500",
    longitude: "80.54384500",
  },
  {
    id: 1983,
    name: "Western Province",
    country_id: 246,
    country_code: "ZM",
    country_name: "Zambia",
    state_code: "01",
    type: null,
    latitude: "6.90160860",
    longitude: "80.00877460",
  },
  {
    id: 1956,
    name: "Bulawayo Province",
    country_id: 247,
    country_code: "ZW",
    country_name: "Zimbabwe",
    state_code: "BU",
    type: null,
    latitude: "-20.14895050",
    longitude: "28.53310380",
  },
  {
    id: 1958,
    name: "Harare Province",
    country_id: 247,
    country_code: "ZW",
    country_name: "Zimbabwe",
    state_code: "HA",
    type: null,
    latitude: "-17.82162880",
    longitude: "31.04922590",
  },
  {
    id: 1959,
    name: "Manicaland",
    country_id: 247,
    country_code: "ZW",
    country_name: "Zimbabwe",
    state_code: "MA",
    type: null,
    latitude: "-18.92163860",
    longitude: "32.17460500",
  },
  {
    id: 1955,
    name: "Mashonaland Central Province",
    country_id: 247,
    country_code: "ZW",
    country_name: "Zimbabwe",
    state_code: "MC",
    type: null,
    latitude: "-16.76442950",
    longitude: "31.07937050",
  },
  {
    id: 1951,
    name: "Mashonaland East Province",
    country_id: 247,
    country_code: "ZW",
    country_name: "Zimbabwe",
    state_code: "ME",
    type: null,
    latitude: "-18.58716420",
    longitude: "31.26263660",
  },
  {
    id: 1953,
    name: "Mashonaland West Province",
    country_id: 247,
    country_code: "ZW",
    country_name: "Zimbabwe",
    state_code: "MW",
    type: null,
    latitude: "-17.48510290",
    longitude: "29.78892480",
  },
  {
    id: 1960,
    name: "Masvingo Province",
    country_id: 247,
    country_code: "ZW",
    country_name: "Zimbabwe",
    state_code: "MV",
    type: null,
    latitude: "-20.62415090",
    longitude: "31.26263660",
  },
  {
    id: 1954,
    name: "Matabeleland North Province",
    country_id: 247,
    country_code: "ZW",
    country_name: "Zimbabwe",
    state_code: "MN",
    type: null,
    latitude: "-18.53315660",
    longitude: "27.54958460",
  },
  {
    id: 1952,
    name: "Matabeleland South Province",
    country_id: 247,
    country_code: "ZW",
    country_name: "Zimbabwe",
    state_code: "MS",
    type: null,
    latitude: "-21.05233700",
    longitude: "29.04599270",
  },
  {
    id: 1957,
    name: "Midlands Province",
    country_id: 247,
    country_code: "ZW",
    country_name: "Zimbabwe",
    state_code: "MI",
    type: null,
    latitude: "-19.05520090",
    longitude: "29.60354950",
  },
];
