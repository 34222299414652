import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import jwt_decode from "jwt-decode";

const Welcome = () => {
  const queryParams = new URLSearchParams(window.location.search);

  // States
  const [session, setSession] = useState();
  const [decodeSession, setDecodeSession] = useState();
  const [sessionToken] = useState(queryParams.get("session_token"));
  const [sessionState] = useState(queryParams.get("state"));

  const redirectToSession = () => {
    window.location.replace(
      decodeSession.continue_uri +
        `?session_token=${session}&state=${sessionState}`
    );
  };

  useState(() => {
    let mySessionToken = jwt_decode(sessionToken);
    mySessionToken["state"] = sessionState;
    setDecodeSession(mySessionToken);
    mySessionToken["tenant"] = process.env.REACT_APP_TENANT;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mySessionToken),
    };

    fetch("https://jwtmiddleware.vercel.app/api", options)
      .then((res) => res.json())
      .then((res) => {
        setSession(res);
      });
  }, []);

  return (
    <Box
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Typography variant="h3" fontFamily="inherit" fontWeight="bold">
          Welcome! Thanks for registering
        </Typography>
        <Typography fontFamily="inherit" variant="h4">
          Press the button below to continue.
        </Typography>
        <Button
          onClick={redirectToSession}
          className="start-meeting-tab only-next-payment"
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default Welcome;
