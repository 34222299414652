import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { isMobile } from "react-device-detect";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import TuneIcon from "@mui/icons-material/Tune";
import PropTypes from "prop-types";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import CircularProgress from "@mui/material/CircularProgress";
import ScheduleIcon from "@mui/icons-material/Schedule";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Confetti from "react-confetti";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Skeleton from "@mui/material/Skeleton";
import "../../App.css";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import Layout from "../global/Layout";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import { useAuth0 } from "@auth0/auth0-react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Carrusel from "../../components/Carrusel";
import "./index.scss";
import { CardMeeting } from "../../components/CardMeeting/CardMeeting";
import { CardStats } from "../../components/CardStats/CardStats";
import { ContentMeetings } from "../../components/ContentMeetings";
import { CardDetailMeeting } from "../../components/CardDetailMeeting";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import deleteMeeting from "../../services/meetings/deleteMeeting";
import { useGlobalState } from "../../hooks/state";
// Utils
const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#784af4",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#412770 !important",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#412770 !important",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#DEDEDE",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#333333",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#412770 !important",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon fontSize="large" style={{ color: "#dec567" }} />,
    2: <DevicesOtherIcon fontSize="large" style={{ color: "#dec567" }} />,
    3: <ReduceCapacityIcon fontSize="large" style={{ color: "#dec567" }} />,
    4: <YoutubeSearchedForIcon fontSize="large" style={{ color: "#dec567" }} />,
    5: <InsertInvitationIcon fontSize="large" style={{ color: "#dec567" }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      style={{ background: "#412770" }}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "MEETING SET UP",
  "CHOOSE PRODUCT VIEW",
  "ADD CO-HOSTS",
  "REVIEW CRITERIA",
  "CREATE INVITE LINK",
];

const basicSteps = ["MEETING SET UP", "REVIEW CRITERIA", "CREATE INVITE LINK"];

Modal.setAppElement("#root");

const Dashboard = () => {
  // auth
  const { user, getAccessTokenSilently, isLoading } = useAuth0();
  const [userStatus] = useGlobalState("userStatus");
  console.log(userStatus);
  const [urlStore, setUrlStore] = useState(null);
  const [topic, setInputTopic] = useState(null);
  const [inputDesc, setInputDesc] = useState("");
  const [inputDate, setInputDate] = useState(new Date());
  const [inputTime, setInputTime] = useState(new Date());
  const [modalStepTwo, setModalStepTwo] = useState(false);
  const [modalMiddleStep, setModalMiddleStep] = useState(false);
  const [modalTeam, setModalTeam] = useState(false);
  const [modalReview, setModalReview] = useState(false);
  const [chooseOption, setChooseOption] = useState(0);
  const [filterProducts, setFilterProducts] = useState(null);
  const [teamSelected, setTeamSelected] = useState([]);
  const [loadingInvitation, setLoadingInvitation] = useState(false);
  const [allRecordings, setAllRecordings] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [idMeeting, setIDMeeting] = useState(null);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setURL] = useState(null);
  const [items, setitems] = useState([]);
  const [productList, setProductList] = useState([]);
  const [showDate, setShowDate] = useState(new Date());
  const [showTime, setShowTime] = useState(new Date());
  const [createdMeeting, setCreateMeeting] = useState(0);
  const [nextMeeting, setNextMeeting] = useState(null);
  const [dateRange, setDateRange] = useState([
    dayjs().startOf("month").format("YYYY-MM-DD"),
    dayjs().endOf("month").format("YYYY-MM-DD"),
  ]);
  const [invite, setInvite] = useState(false);
  const [emailInvitation, setEmailInvitation] = useState("");
  const [nameInvitation, setNameInvitation] = useState("");
  const [referralInvitation, setReferralINvitation] = useState("");
  const [invitationSended, setInvitationSended] = useState(false);
  const [loadingRecordings, setLoadingRecordings] = useState(false);
  const [validateUser, setValidateUser] = useState(true);
  const [loadingMeetingsByUser, setLoadingMeetingsByUser] = useState(false);
  const [previousMeetings, setPreviousMeetings] = useState([]);
  const [loadingPreviousMeetings, setLoadingPreviousMeetings] = useState(false);
  const [allMeetings, setAllMeetings] = useState([]);
  const [checkDeleteModal, setCheckDeleteModal] = useState(false);
  const [meetingIdForDelete, setMeetingIdForDelete] = useState(null);
  const [checkMeetingTopic, setCheckMeetingTopic] = useState("");
  const [loadingDeleteMeeting, setLoadinDeleteMeeting] = useState(false);
  const [successDeleteMeeting, setSuccessDeleteMeeting] = useState(false);

  const confirmDelete = (myMeetingID, myMeetingTopic) => {
    setCheckDeleteModal(!checkDeleteModal);
    setMeetingIdForDelete(myMeetingID);
    setCheckMeetingTopic(myMeetingTopic);
  };

  const facebook = "/assets/images/virtual_skin/fb.svg";
  const telegram = "/assets/images/virtual_skin/tel.svg";
  const msjText = "/assets/images/virtual_skin/msj.svg";
  const tw = "/assets/images/virtual_skin/tw.svg";
  const email = "/assets/images/virtual_skin/mail.svg";

  const team = [
    {
      id: 1,
      name: "Nancy Baker",
      smallThumbnailUrl:
        "https://img.freepik.com/free-photo/portrait-happy-young-woman-looking-camera_23-2147892777.jpg?w=2000",
    },
    {
      id: 2,
      name: "Tom Reeve",
      smallThumbnailUrl:
        "https://media.istockphoto.com/id/1300512215/photo/headshot-portrait-of-smiling-ethnic-businessman-in-office.jpg?b=1&s=170667a&w=0&k=20&c=TXCiY7rYEvIBd6ibj2bE-VbJu0rRGy3MlHwxt2LHt9w=",
    },
    {
      id: 3,
      name: "Gloria McKenny",
      smallThumbnailUrl:
        "https://www.upthereeverywhere.com/hubfs/2020_People/LizR_portrait-square%20cr.jpeg",
    },
  ];

  const [meetings, setMeetings] = useState([]);
  const zoomDomain = "https://meetingssellwithzoom-develop.herokuapp.com";
  const [valueTab, setValueTab] = useState("1");

  const handleMeeting = () => {
    setOpenModal(true);
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChange = (newValue) => {
    setInputDate(newValue);
  };

  const handleChangeTime = (newValue) => {
    setInputTime(newValue);
  };

  const updateProductList = (id) => {
    if (!productList.includes(id)) {
      setProductList((productList) => [...productList, id]);
    } else {
      setProductList([]);
      productList.map((productID) => {
        if (productID != id) {
          setProductList((productList) => [...productList, productID]);
        }
      });
    }
  };

  useEffect(() => {
    const getAllRecordings = async () => {
      setLoadingRecordings(true);
      const start = dayjs()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const end = dayjs()
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");

      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          comp: process.env.REACT_APP_TENANT,
        },
      };
      await fetch(
        `https://meetingssellwithzoom-develop.herokuapp.com/recordingsbyuser?from_=${start}&to=${end}`,
        options
      )
        .then((res) => res.json())
        .then((res) => {
          setAllRecordings(res.meetings);
          setLoadingRecordings(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingRecordings(false);
        });
    };

    const getAllProducts = async () => {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer public_z7gaHztX8yD8gwEg8RH2MEzAT65xpFYC",
        },
      };

      await fetch(`https://app.ecwid.com/api/v3/84085268/products`, options)
        .then((response) => response.json())
        .then((result) => {
          setitems(result?.items);
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    getAllRecordings();
    getAllProducts();
  }, [user?.sub]);

  const sendInvitation = async () => {
    setLoadingInvitation(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        comp: process.env.REACT_APP_TENANT,
      },
      body: JSON.stringify({
        name: nameInvitation,
        email: emailInvitation,
        referarstring: referralInvitation,
      }),
    };

    fetch("https://companduser-develop.herokuapp.com/addteam", options)
      .then((res) => res.json())
      .then((res) => {
        setLoadingInvitation(false);
        setInvitationSended(true);
      })
      .catch((err) => {
        console.log(err);
        setLoadingInvitation(false);
      });
  };

  const updateTeamSelected = (id) => {
    if (!teamSelected.includes(id)) {
      setTeamSelected((teamSelected) => [...teamSelected, id]);
    } else {
      setTeamSelected([]);
      teamSelected.map((productID) => {
        if (productID != id) {
          setTeamSelected((teamSelected) => [...teamSelected, productID]);
        }
      });
    }
  };

  useEffect(() => {
    const dateShow = new Date(inputDate).toString().split(" ").slice(0, 4);
    const concatDate =
      dateShow.slice(0, 3).join(" ") + ", " + dateShow.slice(3, 4).join(" ");
    setShowDate(concatDate);
    const dateStart = new Date(inputDate).toLocaleTimeString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    setShowTime(
      [dateStart.split(":")[0], dateStart.split(":")[1]].join(":") +
        " " +
        dateStart.split(" ")[1] +
        " " +
        Intl.DateTimeFormat().resolvedOptions().timeZone?.split("_").join(" ")
    );
  }, [inputTime, inputDate]);

  useEffect(() => {
    if (!isLoading) {
      setValidateUser(true);
      if (!user.email_verified) {
        window.location.replace("/verify");
      } else if (user["http://locahost:5000/roles"]?.length == 0) {
        window.location.replace("/plan");
      } else {
        setValidateUser(false);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    setLoadingMeetingsByUser(true);
    const loadMeetings = async () => {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          comp: process.env.REACT_APP_TENANT,
        },
      };

      await fetch(
        `https://backzoom-develop.herokuapp.com/meetingDBByUser?date_from=${dateRange[0]}&date_to=${dateRange[1]}&id_user=${user?.sub}&status=created&page_size=30&page_number=1`,
        options
      )
        .then((response) => response.json())
        .then((result) => {
          setMeetings(result?.meetings);
          setNextMeeting(result?.meetings[0]);
          setLoadingMeetingsByUser(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoadingMeetingsByUser(false);
        });
    };

    const loadPreviousMeetings = async () => {
      setLoadingPreviousMeetings(true);
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          comp: process.env.REACT_APP_TENANT,
        },
      };
      await fetch(
        `https://backzoom-develop.herokuapp.com/meetingDBByUser?date_from=${dateRange[0]}&date_to=${dateRange[1]}&id_user=${user?.sub}&status=ended&page_size=30&page_number=1`,
        options
      )
        .then((response) => response.json())
        .then((result) => {
          setPreviousMeetings(result?.meetings);
          setLoadingPreviousMeetings(false);
        })
        .catch((error) => {
          console.log("Error loading previous Meetings", error);
          setLoadingPreviousMeetings(false);
        });
    };

    user?.sub && loadMeetings();
    user?.sub && loadPreviousMeetings();
  }, [createdMeeting]);

  useEffect(() => {
    setNextMeeting(meetings.length != 0 ? meetings[0] : null);
  }, [meetings]);

  const openMeeting = () => {
    let url = document.getElementById("txtLinkMeeting").value;
    window.open(
      `${window.location.origin}/${idMeeting}${
        url ? `?loadIframe=` + url : ""
      }`,
      "_blank"
    );
  };

  const copyInvitation = (id) => {
    navigator.clipboard
      .writeText(
        `${window.location.origin}/${idMeeting}${
          url ? `?loadIframe=` + url : ""
        }`
      )
      .then(() => {
        setCopied(true);
        // console.log("Text copied to clipboard...");
      });
  };

  const handleInvite = () => {
    setInvite(true);
  };

  const generateMeeting = async () => {
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        comp: process.env.REACT_APP_TENANT,
      },
      body: JSON.stringify({
        id_user: user.sub,
        start_time: `${inputDate}T${inputTime}Z`,
        topic: topic,
        description: inputDesc,
        settings: {},
        product_ids: productList.join(","),
        url_store: url,
        cohost: [],
      }),
    };

    await fetch(`${zoomDomain}/meeting`, options)
      .then((response) => response.json())
      .then((result) => {
        console.log("Generate Meeting: ", result);
        setIDMeeting(result.meeting_id);
        setLoading(false);
        setCreateMeeting(createdMeeting + 1);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const openNewTab = (url) => {
    window.open(url, "_blank");
  };

  const filterMeetingsByUser = async () => {
    setLoadingMeetingsByUser(true);
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        comp: process.env.REACT_APP_TENANT,
      },
    };

    fetch(
      `https://backzoom-develop.herokuapp.com/meetingDBByUser?date_from=${
        new Date(dateRange[0]?.$d).toISOString().split("T")[0]
      }&date_to=${
        new Date(dateRange[1]?.$d).toISOString().split("T")[0]
      }&id_user=${user?.sub}&status=created&page_size=30&page_number=1`,
      options
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("Meetings after Filter: ", result);
        setMeetings(result?.meetings);
        setNextMeeting(result?.meetings[0]);
        setLoadingMeetingsByUser(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingMeetingsByUser(false);
      });
  };

  const sendDeleteMeeting = async (myMeetingID) => {
    setLoadinDeleteMeeting(true);
    const myToken = await getAccessTokenSilently();
    const resDelete = await deleteMeeting(
      myMeetingID,
      myToken,
      process.env.REACT_APP_TENANT
    );
    console.log(resDelete);
    setLoadinDeleteMeeting(false);
    setSuccessDeleteMeeting(true);
  };

  return (
    <>
      {validateUser && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          width="100vw"
          flexDirection="column"
        >
          <CircularProgress
            style={{ color: "#00000", width: "70px", height: "70px" }}
          />
          <Typography variant="h6" fontWeight="bold">
            Loading...
          </Typography>
        </Box>
      )}
      {!validateUser && (
        <Layout allMeetings={allMeetings}>
          <Box className="dashboard-container">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="flex-start"
              flexDirection="column"
              gap="15px"
            >
              {/* <DynamicHeader title={`Hi`} subtitle="Welcome to your Admin Panel" /> */}
              <Box width="100%" className="content-slider">
                <Carrusel
                  navButtonsProps={{
                    // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                    style: {
                      backgroundColor: "#red",
                      borderRadius: 0,
                    },
                  }}
                  navButtonsWrapperProps={{
                    // Move the buttons to the bottom. Unsetting top here to override default style.
                    style: {
                      bottom: "0",
                      backgroundColor: "#red",
                      top: "unset",
                    },
                  }}
                ></Carrusel>
                <div className="slider-content-sub">
                  <div>
                    <h4
                      style={{
                        fontWeight: "bold",
                        textAlign: "start",
                        color: "#412770",
                      }}
                    >
                      Hi, {user?.name}
                    </h4>
                  </div>
                  <div className="sub-text-slider">
                    <span
                      style={{
                        fontSize: "13px",
                        textAlign: "left",
                        color: "#8F72AE",
                      }}
                    >
                      Welcome, start selling with Zoom{" "}
                      {!user["http://locahost:5000/roles"]?.includes("BASIC") &&
                        !user["http://locahost:5000/roles"]?.includes(
                          "VENTURE"
                        ) &&
                        "shopping"}{" "}
                      today!
                    </span>
                  </div>
                </div>
              </Box>

              <Box className="tab-meetings-dashboard">
                <TabContext value={valueTab ? valueTab : "1"}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <TabList
                      onChange={handleChangeTab}
                      aria-label="lab API tabs example"
                      sx={{
                        "& button": {
                          borderTopLeftRadius: 2,
                          borderTopRightRadius: 2,
                        },
                        "& button:hover": {
                          backgroundColor: "#dec567",
                          color: "#412770 !important",
                        },
                        "& button:focus": {
                          backgroundColor: "#dec567",
                          color: "#412770 !important",
                        },
                        "& button.Mui-selected": {
                          backgroundColor: "#dec567",
                          color: "#412770 !important",
                        },
                        "& .MuiTabs-flexContaine": {
                          height: "50px",
                        },
                        "& .MuiButtonBase-root": {
                          fontSize: "12px !important",
                          width: "200px",
                          height: "50px",
                        },
                      }}
                    >
                      <Tab
                        label={`Upcoming Meetings`}
                        value="1"
                        style={{
                          width: "200px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#06213b",
                          textAlign: "center",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                          paddingLeft: "40px",
                          paddingRight: "40px",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                        focusVisibleClassName="focus-tab"
                      ></Tab>
                      <Tab
                        label={`Previous Meetings`}
                        value="2"
                        style={{
                          width: "200px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#06213b",
                          textAlign: "center",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                          paddingLeft: "40px",
                          paddingRight: "40px",
                          borderTopLeftRadius: "10px",
                          color: "#412770",
                          borderTopRightRadius: "10px",
                        }}
                        focusVisibleClassName="focus-tab"
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1" className="upcoming-meetings-container">
                    <Box m="0 0 0 0" height="auto">
                      <ContentMeetings
                        onClick={handleMeeting}
                        onInvite={handleInvite}
                        isActive={userStatus}
                      ></ContentMeetings>
                      <Box className="select-dates-filter-container">
                        <Typography
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          variant="h5"
                          fontFamily="inherit"
                          fontWeight="bold"
                          gap="15px"
                          color="#412770"
                        >
                          <TuneIcon fontSize="large" color="#412770" />
                          Upcoming Meetings Filter
                        </Typography>

                        <LocalizationProvider
                          sx={{ width: "50%" }}
                          dateAdapter={AdapterDayjs}
                          localeText={{ start: "From", end: "Until" }}
                        >
                          <DateRangePicker
                            value={dateRange}
                            id="dashboard-meetings-date-filter"
                            onChange={(newDates) => {
                              setDateRange(newDates);
                            }}
                            renderInput={(startProps, endProps) => (
                              <React.Fragment>
                                <TextField
                                  style={{ height: "10px !important" }}
                                  {...startProps}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                              </React.Fragment>
                            )}
                          />
                        </LocalizationProvider>
                        <Button
                          className="invite-meeting-tab"
                          style={{ marginLeft: "5px", height: "30px" }}
                          onClick={filterMeetingsByUser}
                          disabled={userStatus}
                        >
                          Apply
                        </Button>
                      </Box>
                      {!loadingMeetingsByUser &&
                        meetings.length > 0 &&
                        meetings?.map((meet, index) => {
                          const dateStart = new Date(
                            meet?.start_time
                          ).toLocaleTimeString("en-US", {
                            timeZone: meet?.time_zone,
                          });
                          return (
                            <CardDetailMeeting
                              key={index}
                              startDate={new Date(
                                meet?.start_time
                              ).toDateString()}
                              onClick={() =>
                                confirmDelete(meet?.id, meet?.topic)
                              }
                              meetingTopic={meet?.topic}
                              startTime={
                                [
                                  dateStart.split(":")[0],
                                  dateStart.split(":")[1],
                                ].join(":") +
                                " " +
                                dateStart.split(" ")[1] +
                                " " +
                                meet?.time_zone?.split("_").join(" ")
                              }
                              meetingID={
                                meet?.join_url
                                  ?.split("?")[0]
                                  .split("/")
                                  .reverse()[0]
                              }
                            ></CardDetailMeeting>
                          );
                        })}
                      {!loadingMeetingsByUser && meetings.length == 0 && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="30vh"
                        >
                          <Typography variant="h6" fontWeight="bold">
                            You have no upcoming meetings.
                          </Typography>
                        </Box>
                      )}

                      {loadingMeetingsByUser && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="60vh"
                          flexDirection="column"
                        >
                          <CircularProgress
                            style={{
                              color: "#00000",
                              width: "70px",
                              height: "70px",
                            }}
                          />
                          <Typography variant="h6" fontWeight="bold">
                            Loading Upcoming Meetings...
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </TabPanel>
                  <TabPanel value="2" className="upcoming-meetings-container">
                    <Box m="0 0 0 0" height="auto">
                      <Box className="select-dates-filter-container">
                        <Typography
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          variant="h5"
                          fontFamily="inherit"
                          fontWeight="bold"
                          gap="15px"
                        >
                          <TuneIcon fontSize="large" />
                          Previous Meetings Filter
                        </Typography>

                        <LocalizationProvider
                          sx={{ width: "50%" }}
                          dateAdapter={AdapterDayjs}
                          localeText={{ start: "From", end: "Until" }}
                        >
                          <DateRangePicker
                            value={dateRange}
                            id="dashboard-meetings-date-filter"
                            onChange={(newDates) => {
                              setDateRange(newDates);
                            }}
                            renderInput={(startProps, endProps) => (
                              <React.Fragment>
                                <TextField
                                  style={{ height: "10px !important" }}
                                  {...startProps}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                              </React.Fragment>
                            )}
                          />
                        </LocalizationProvider>
                        <Button
                          className="invite-meeting-tab"
                          style={{ marginLeft: "5px", height: "30px" }}
                          onClick={filterMeetingsByUser}
                        >
                          Apply
                        </Button>
                      </Box>
                      {!loadingPreviousMeetings &&
                        previousMeetings?.map((meet, index) => {
                          const dateStart = new Date(
                            meet?.start_time
                          ).toLocaleTimeString("en-US", {
                            timeZone: meet?.time_zone,
                          });
                          return (
                            <CardDetailMeeting
                              key={index}
                              type="previous"
                              onClick={() =>
                                confirmDelete(meet?.id, meet?.topic)
                              }
                              startDate={new Date(
                                meet?.start_time
                              ).toDateString()}
                              meetingTopic={meet?.topic}
                              startTime={
                                [
                                  dateStart.split(":")[0],
                                  dateStart.split(":")[1],
                                ].join(":") +
                                " " +
                                dateStart.split(" ")[1] +
                                " " +
                                meet?.time_zone?.split("_").join(" ")
                              }
                              meetingID={
                                meet?.join_url
                                  ?.split("?")[0]
                                  .split("/")
                                  .reverse()[0]
                              }
                            ></CardDetailMeeting>
                          );
                        })}
                      {loadingPreviousMeetings && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="60vh"
                          flexDirection="column"
                        >
                          <CircularProgress
                            style={{
                              color: "#00000",
                              width: "70px",
                              height: "70px",
                            }}
                          />
                          <Typography variant="h6" fontWeight="bold">
                            Loading Previous Meetings...
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </TabPanel>
                  {/* <TabPanel value="3">
          {" "}
          <h1>Coming Soon</h1>
        </TabPanel> */}
                </TabContext>
              </Box>
            </Box>
            <Box className="right-side-cards">
              <CardMeeting details={nextMeeting} />
              {!user["http://locahost:5000/roles"]?.includes("BASIC") &&
                !user["http://locahost:5000/roles"]?.includes("VENTURE") && (
                  <CardStats
                    title="Total Meeting Sales"
                    totalValue="$632.00"
                    details="VIEW ALL SALES"
                  />
                )}
              {!user["http://locahost:5000/roles"]?.includes("BASIC") &&
                !user["http://locahost:5000/roles"]?.includes("VENTURE") && (
                  <CardStats
                    title="Product Sold"
                    totalValue="123"
                    details="VIEW PRODUCTS"
                  />
                )}
              {(user["http://locahost:5000/roles"]?.includes("BASIC") ||
                user["http://locahost:5000/roles"]?.includes("VENTURE")) && (
                <Box className="recording-stats-container">
                  <Box>
                    <Typography
                      variant="h4"
                      fontWeight="bold"
                      sx={{ padding: "10px" }}
                      color="#412770"
                    >
                      Recent Recordings
                    </Typography>
                    <Typography variant="h6" color="#a991c4">
                      Review all meetings that have been recorded.
                    </Typography>
                  </Box>
                  {loadingRecordings && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100vh"
                      flexDirection="column"
                    >
                      <CircularProgress
                        style={{
                          color: "#412770",
                          width: "70px",
                          height: "70px",
                        }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="#412770"
                      >
                        Loading...
                      </Typography>
                    </Box>
                  )}
                  {allRecordings?.length > 0 && !loadingRecordings && (
                    <Box style={{ width: "100%" }}>
                      {allRecordings?.map((itemMeeting) => {
                        return (
                          <Box className="recordings-items">
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <PlayCircleOutlinedIcon
                                fontSize="large"
                                style={{ color: "#428aca", cursor: "pointer" }}
                                onClick={() =>
                                  window.open(itemMeeting.share_url)
                                }
                              />
                              <Typography
                                className="topic-recording-item"
                                variant="h6"
                                onClick={() =>
                                  window.open(itemMeeting.share_url)
                                }
                              >
                                {itemMeeting?.topic}
                              </Typography>
                            </Box>

                            <Typography variant="h6" fontWeight={600}>
                              {new Date(
                                itemMeeting?.start_time
                              ).toLocaleDateString()}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                  {allRecordings?.length == 0 && (
                    <Box style={{ width: "100%" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="#412770"
                      >
                        No recent recordings found
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Layout>
      )}
      {/*  First Step  */}
      <div className="App">
        <Modal
          isOpen={openModal}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div className="first-step-content">
            <div>
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#412770",
                }}
              >
                MEETING SET UP
              </h3>
            </div>
            <div>
              <Stepper
                alternativeLabel
                activeStep={0}
                connector={<ColorlibConnector />}
              >
                {!user["http://locahost:5000/roles"]?.includes("BASIC") &&
                  !user["http://locahost:5000/roles"]?.includes("VENTURE") &&
                  steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                {(user["http://locahost:5000/roles"]?.includes("BASIC") ||
                  user["http://locahost:5000/roles"]?.includes("VENTURE")) &&
                  basicSteps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
              </Stepper>
            </div>
            <div className="content-form">
              <div className="container-input-create">
                <div style={{}}>
                  <Typography variant="h6" fontWeight="bold" color="#412770">
                    Topic / Title
                  </Typography>
                </div>
                <div className="container-input-create">
                  <input
                    onChange={(e) => setInputTopic(e.target.value)}
                    className="input-credentials"
                    defaultValue={topic ? topic : ""}
                    placeholder="Example: Let's talk about skin care"
                  />
                </div>
                {/* <div>
                  <label>Store URL</label>
                </div>
                <div>
                  <input
                    onChange={(e) => setURL(e.target.value)}
                    className="input-credentials"
                  />
                </div> */}
              </div>
              <div className="container-input-create">
                <div className="container-input-create">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    className="container-input-create"
                  >
                    <Stack spacing={0.5} className="container-input-create">
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="#412770"
                      >
                        Date
                      </Typography>

                      {!isMobile ? (
                        <DesktopDatePicker
                          id="date-select-wk"
                          inputFormat="MM/DD/YYYY"
                          minDate={new Date()}
                          value={inputDate}
                          className="input-credentials container-input-create"
                          onChange={handleChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "tt.mm.jjjj",
                              }}
                            />
                          )}
                        />
                      ) : (
                        <MobileDatePicker
                          inputFormat="MM/DD/YYYY"
                          value={inputDate}
                          className="input-credentials container-input-create"
                          onChange={handleChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="#412770"
                      >
                        Time
                      </Typography>

                      <TimePicker
                        className="input-credentials"
                        value={inputTime}
                        onChange={handleChangeTime}
                        renderInput={(params) => (
                          <TextField {...params} style={{ color: "#8F72AE" }} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="container-input-create">
                <div>
                  <Typography variant="h6" fontWeight="bold" color="#412770">
                    Description
                  </Typography>
                </div>
                <div>
                  <textarea
                    type="text"
                    onChange={(e) => setInputDesc(e.target.value)}
                    className="input-credentials input-description new-text-area"
                    defaultValue={inputDesc ? inputDesc : ""}
                    placeholder="Example: How to improve your night time skin care regime and more!"
                  />
                </div>
              </div>
              <div className="step-btn-content">
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    if (
                      user["http://locahost:5000/roles"]?.includes("BASIC") ||
                      user["http://locahost:5000/roles"]?.includes("VENTURE")
                    ) {
                      setOpenModal(false);
                      setModalReview(true);
                    } else {
                      setOpenModal(false);
                      setModalMiddleStep(true);
                    }
                  }}
                  disabled={!topic}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <button
            className="close-modal"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>
      {/*  Second Step  */}
      <div className="App">
        <Modal
          isOpen={modalMiddleStep}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <div>
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#202020",
                }}
              >
                Choose Product View
              </h3>
            </div>
            <div>
              <Stepper
                alternativeLabel
                activeStep={1}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="content-form">
              <Typography
                variant="h4"
                fontWeight="bold"
                style={{ padding: "10px" }}
              >
                Choose product view
              </Typography>
              <div className="content-choose-cards">
                <div className="choose-card" onClick={() => setChooseOption(1)}>
                  <div className="choose-card-header">
                    <img
                      className="image-header"
                      src="https://images.unsplash.com/photo-1504270997636-07ddfbd48945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80"
                    />
                  </div>
                  <div className="choose-card-body">
                    <h4 className="choose-card-title">URL Store</h4>
                    <p style={{ fontSize: "10px" }}>
                      Selecting URL Store you can view the products of any store
                      using the URL of the store you want to access during the
                      meeting
                    </p>
                  </div>
                  {chooseOption == 1 ? (
                    <CheckCircleIcon
                      sx={{
                        position: "relative",
                        top: "0",
                        left: "94%",
                        fontSize: "large",
                      }}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon
                      sx={{
                        position: "relative",
                        top: "0",
                        left: "94%",
                        fontSize: "large",
                      }}
                    />
                  )}
                </div>
                <div className="choose-card" onClick={() => setChooseOption(2)}>
                  <div className="choose-card-header">
                    <img
                      className="image-header"
                      src="https://images.unsplash.com/photo-1522204523234-8729aa6e3d5f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                    />
                  </div>
                  <div className="choose-card-body">
                    <h4 className="choose-card-title">Products</h4>
                    <p style={{ fontSize: "10px" }}>
                      Select the products that you want people in the meeting to
                      be able to buy.
                    </p>
                  </div>
                  {chooseOption == 2 ? (
                    <CheckCircleIcon
                      sx={{
                        position: "relative",
                        top: "0",
                        left: "94%",
                        fontSize: "large",
                      }}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon
                      sx={{
                        position: "relative",
                        top: "0",
                        left: "94%",
                        fontSize: "large",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="step-btn-content">
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setOpenModal(true);
                    setModalMiddleStep(false);
                  }}
                >
                  Back
                </button>
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setModalStepTwo(true);
                    setModalMiddleStep(false);
                  }}
                  disabled={chooseOption == 0}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <button
            className="close-modal"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>
      {/*  Third Step  */}
      <div className="App">
        <Modal
          isOpen={modalStepTwo}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <div>
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#333333",
                }}
              >
                Search Products
              </h3>
            </div>
            <div>
              <Stepper
                alternativeLabel
                activeStep={1}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="content-form">
              {chooseOption == 1 && (
                <div className="select-products-container">
                  <div>
                    <Typography
                      variant="h4"
                      fontWeight="bold"
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      URL Store
                    </Typography>
                    <input
                      placeholder="Add an URL Store"
                      className="checkout-working-live-email-form-input"
                      onChange={(e) => setUrlStore(e.target.value)}
                    />
                  </div>
                </div>
              )}

              {chooseOption == 2 && (
                <div className="select-products-container">
                  <div>
                    <Typography
                      variant="h5"
                      style={{
                        textAlign: "center",
                        padding: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Choose the products
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <input
                        type="search"
                        className="input-credentials"
                        placeholder="Search Catalog"
                        onChange={(e) => setFilterProducts(e.target.value)}
                      />
                    </div>
                    <div className="content-product-list">
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        {items?.map((item) => {
                          return (
                            <ListItem>
                              <Checkbox
                                key={item.id}
                                onClick={() => updateProductList(item.id)}
                                checked={productList.includes(item.id)}
                              />
                              <div style={{ padding: "5px" }}>
                                <img
                                  src={item.smallThumbnailUrl}
                                  width="60px"
                                  style={{
                                    borderRadius: "3px",
                                    border: "1px solid #DEDEDE",
                                  }}
                                />
                              </div>
                              <ListItemText
                                primary={item.name}
                                secondary={`$${item.price}`}
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </div>
                  </div>
                </div>
              )}

              <div className="step-btn-content">
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setModalMiddleStep(true);
                    setModalStepTwo(false);
                  }}
                >
                  Back
                </button>
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setModalStepTwo(false);
                    setModalTeam(true);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          {/* <button
            className="close-modal"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button> */}
        </Modal>
      </div>
      {/*  Add CO HOSTS  */}
      <div className="App">
        <Modal
          isOpen={modalTeam}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <h3
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#333333",
              }}
            >
              Add Co-Host (Optional)
            </h3>
          </div>
          <div>
            <Stepper
              alternativeLabel
              activeStep={2}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div className="content-form" style={{ width: "100%" }}>
            <div className="select-products-container">
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <input
                    type="search"
                    className="input-credentials"
                    placeholder="Search My Team"
                    onChange={(e) => setFilterProducts(e.target.value)}
                  />
                </div>
                <div className="content-product-list">
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    {team?.map((item) => {
                      return (
                        <ListItem>
                          <Checkbox
                            key={item.id}
                            onClick={() => updateTeamSelected(item.id)}
                            checked={teamSelected.includes(item.id)}
                          />
                          <div style={{ padding: "5px" }}>
                            <img
                              src={item.smallThumbnailUrl}
                              width="60px"
                              style={{
                                borderRadius: "3px",
                                border: "1px solid #DEDEDE",
                              }}
                            />
                          </div>
                          <ListItemText
                            sx={{ fontSize: "12px" }}
                            primary={item.name}
                            secondary={`Team Member`}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </div>
            </div>

            <div className="step-btn-content">
              <button
                className="btn-personalized"
                onClick={(e) => {
                  setModalStepTwo(true);
                  setModalTeam(false);
                }}
              >
                Back
              </button>
              <button
                className="btn-personalized"
                onClick={(e) => {
                  setModalTeam(false);
                  setModalReview(true);
                }}
              >
                Next
              </button>
              <button
                className="btn-personalized-skip"
                onClick={(e) => {
                  setModalTeam(false);
                  setModalReview(true);
                }}
                disabled={chooseOption == 1 && !url}
              >
                Skip
              </button>
            </div>
          </div>

          {/* <button
            className="close-modal"
            onClick={() => {
              setConfirmModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button> */}
        </Modal>
      </div>
      {/*  Review Details  */}
      <div className="App">
        <Modal
          isOpen={modalReview}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <h3
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#412770",
              }}
            >
              REVIEW
            </h3>
          </div>
          <div style={{ width: "100%" }}>
            <Stepper
              alternativeLabel
              style={{ color: "#412770" }}
              activeStep={
                user["http://locahost:5000/roles"]?.includes("BASIC") ||
                user["http://locahost:5000/roles"]?.includes("VENTURE")
                  ? 1
                  : 3
              }
              connector={<ColorlibConnector style={{ color: "#412770" }} />}
            >
              {!user["http://locahost:5000/roles"]?.includes("BASIC") &&
                !user["http://locahost:5000/roles"]?.includes("VENTURE") &&
                steps.map((label) => (
                  <Step key={label} style={{ color: "#412770" }}>
                    <StepLabel
                      StepIconComponent={ColorlibStepIcon}
                      style={{ color: "#412770" }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              {(user["http://locahost:5000/roles"]?.includes("BASIC") ||
                user["http://locahost:5000/roles"]?.includes("VENTURE")) &&
                basicSteps.map((label) => (
                  <Step key={label} style={{ color: "#412770" }}>
                    <StepLabel
                      StepIconComponent={ColorlibStepIcon}
                      style={{ color: "#412770" }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
            </Stepper>
          </div>
          <div
            className="content-form review-meeting-container"
            style={{ width: "80%", borderRadius: "5px" }}
          >
            <Box>
              <img
                src="https://nblpublicfiles.s3.amazonaws.com/Screenshot+2023-05-17+at+11.54.42+AM.png"
                width="200px"
                alt="gologo"
              />
            </Box>
            <Typography
              variant="h3"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                padding: "10px",
                color: "#412770",
              }}
            >
              MEETING INFORMATION
            </Typography>
            <Typography
              variant="h4"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#412770",
              }}
            >
              TOPIC: {topic}
            </Typography>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#412770",
              }}
            >
              DESCRIPTION: {inputDesc ? inputDesc : "No Description"}
            </Typography>
            <div className="select-info-meeting-container">
              <Box className="meeting-info-header">
                <CalendarMonthIcon
                  fontSize="large"
                  style={{ color: "#8f72ae" }}
                />
                <Typography variant="h5" color="#8f72ae">
                  {showDate}
                </Typography>
                <ScheduleIcon fontSize="large" sx={{ color: "#8f72ae" }} />
                <Typography variant="h5" color="#8f72ae">
                  {showTime}
                </Typography>
              </Box>
              {!user["http://locahost:5000/roles"]?.includes("BASIC") &&
                !user["http://locahost:5000/roles"]?.includes("VENTURE") && (
                  <Box className="shopping-info-container">
                    <Box className="meeting-info-check">
                      <Checkbox checked={url} />
                      <Typography variant="p" fontSize="14px">
                        Store URL- Entire Catalog
                      </Typography>
                    </Box>
                    <Box className="meeting-info-check">
                      <Checkbox checked={items.length > 0} />
                      <Typography variant="p" fontSize="14px">
                        Individual Products
                      </Typography>
                    </Box>
                    <Box className="images-info-container">
                      {items?.map((item) => {
                        if (productList?.includes(item?.id))
                          return (
                            <Box>
                              <a title={item.name}>
                                <img
                                  className="image-info-meeting"
                                  src={item.smallThumbnailUrl}
                                  alt={item.name}
                                  width="60px"
                                />
                              </a>
                            </Box>
                          );
                      })}
                    </Box>
                    <Box className="meeting-info-check">
                      <Checkbox checked={teamSelected.length > 0} />
                      <Typography variant="p" fontSize="14px">
                        Co-Hosts: Nancy Baker, Tom Reeve
                      </Typography>
                    </Box>
                  </Box>
                )}
            </div>
          </div>
          <div className="step-btn-content">
            <button
              className="btn-personalized"
              onClick={(e) => {
                if (
                  user["http://locahost:5000/roles"]?.includes("BASIC") ||
                  user["http://locahost:5000/roles"]?.includes("VENTURE")
                ) {
                  setModalReview(false);
                  setOpenModal(true);
                } else {
                  setModalTeam(true);
                  setModalReview(false);
                }
              }}
            >
              Back
            </button>
            <button
              className="btn-personalized"
              onClick={(e) => {
                generateMeeting();
                setModalReview(false);
                setConfirmModal(true);
              }}
            >
              Create Link
            </button>
          </div>

          <button
            className="close-modal"
            onClick={() => {
              setConfirmModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>

      {/*  Generate Meeting  */}
      <div className="App">
        <Modal
          isOpen={confirmModal}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div className="content-final-step-share">
            <div>
              <h3
                style={{
                  fontWeight: "bold",
                  color: "#412770",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                CREATE INVITE LINK
              </h3>
            </div>
            <Stepper
              alternativeLabel
              activeStep={4}
              connector={<ColorlibConnector />}
            >
              {!user["http://locahost:5000/roles"]?.includes("BASIC") &&
                !user["http://locahost:5000/roles"]?.includes("VENTURE") &&
                steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              {(user["http://locahost:5000/roles"]?.includes("BASIC") ||
                user["http://locahost:5000/roles"]?.includes("VENTURE")) &&
                basicSteps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
            </Stepper>
            <div className="content-form">
              <Box className="content-form-header">
                <Typography variant="h3" fontWeight="bold" textAlign="center">
                  {topic}
                </Typography>
                <Typography variant="h5" fontWeight="bold">
                  Your Zoom Shooping meeting has been created!{" "}
                </Typography>
              </Box>
              <Box
                className="share-meeting-link"
                style={{ padding: "20px 0px 20px 0px" }}
              >
                <Box
                  className="share-meeting-link-item"
                  onClick={() =>
                    openNewTab(
                      `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/${idMeeting}`
                    )
                  }
                >
                  <img
                    src={facebook}
                    width="50px"
                    height="50px"
                    style={{ borderRadius: "50%" }}
                  />
                  <Typography
                    fontWeight="bold"
                    variant="h6"
                    textAlign="center"
                    style={{ fontFamily: "inherit" }}
                  >
                    Facebook
                  </Typography>
                </Box>
                <Box
                  className="share-meeting-link-item"
                  onClick={() => {
                    openNewTab(
                      `https://t.me/share/url?url=${window.location.origin}/${idMeeting}`
                    );
                  }}
                >
                  <img
                    src={telegram}
                    width="50px"
                    height="50px"
                    style={{ borderRadius: "50%" }}
                  />
                  <Typography
                    fontWeight="bold"
                    variant="h6"
                    textAlign="center"
                    style={{ fontFamily: "inherit" }}
                  >
                    Telegram
                  </Typography>
                </Box>
                <Box
                  className="share-meeting-link-item"
                  onClick={() =>
                    openNewTab(
                      `sms:?&body=${window.location.origin}/${idMeeting}`
                    )
                  }
                >
                  <img
                    src={msjText}
                    width="50px"
                    height="50px"
                    style={{ borderRadius: "50%" }}
                  />
                  <Typography
                    fontWeight="bold"
                    variant="h6"
                    textAlign="center"
                    style={{ fontFamily: "inherit" }}
                  >
                    Messages
                  </Typography>
                </Box>
                <Box
                  className="share-meeting-link-item"
                  onClick={() =>
                    openNewTab(
                      `https://twitter.com/intent/tweet?url=${window.location.origin}/${idMeeting}`
                    )
                  }
                >
                  <img
                    src={tw}
                    width="50px"
                    height="50px"
                    style={{ borderRadius: "50%" }}
                  />
                  <Typography
                    fontWeight="bold"
                    variant="h6"
                    textAlign="center"
                    style={{ fontFamily: "inherit" }}
                  >
                    Twitter
                  </Typography>
                </Box>
                <Box
                  className="share-meeting-link-item"
                  onClick={() =>
                    openNewTab(
                      `mailto:info@example.com?&subject=&cc=&bcc=&body=${window.location.origin}/${idMeeting}`
                    )
                  }
                >
                  <img
                    src={email}
                    width="50px"
                    height="50px"
                    style={{ borderRadius: "50%" }}
                  />
                  <Typography
                    fontWeight="bold"
                    variant="h6"
                    textAlign="center"
                    style={{ fontFamily: "inherit" }}
                  >
                    Email
                  </Typography>
                </Box>
              </Box>
              <Box className="form-inline">
                <Box className="form-inline-link">
                  {!loading && (
                    <>
                      <input
                        type="text"
                        className="input-credentials share-meeting shared-meeting-input"
                        id="txtLinkMeeting"
                        disabled={true}
                        placeholder={`${window.location.origin}/${idMeeting}${
                          url ? `?loadIframe=` + url : ""
                        }`}
                      />
                      <Button
                        className="start-meeting-tab btn-width-start"
                        onClick={(e) => {
                          copyInvitation();
                        }}
                      >
                        {copied ? "Copied" : "COPY HOST LINK"}
                      </Button>
                    </>
                  )}
                  {loading && (
                    <Skeleton variant="rounded" width="100%" height={30} />
                  )}
                </Box>
              </Box>
              <div className="content-btn-down" style={{ margin: "10px" }}>
                <Button
                  className="btn-width-start-exit"
                  onClick={(e) => {
                    openMeeting();
                  }}
                >
                  SHARE INVITE
                </Button>
                <Button
                  className="btn-width-start-exit"
                  onClick={(e) => {
                    setConfirmModal(false);
                    window.location.reload();
                  }}
                >
                  GO TO DASHBOARD
                </Button>
                <Button
                  className="start-meeting-tab btn-width-start"
                  onClick={(e) => {
                    openMeeting();
                  }}
                >
                  START MEETING NOW
                </Button>
              </div>
            </div>
          </div>

          <button
            className="close-modal"
            onClick={() => {
              setConfirmModal(false);
              window.location.reload();
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>

      {/*  Invite  */}
      <div className="App">
        <Modal
          isOpen={invite}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max-invite",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          {!invitationSended && (
            <div>
              <div>
                <h2
                  style={{
                    fontWeight: "bold",
                    color: "#202020",
                    padding: "30px 30px 15px 30px",
                    textAlign: "center",
                  }}
                >
                  Invite to my Team
                </h2>
              </div>
              <div className="content-form">
                <Box className="content-form-header">
                  <Typography
                    variant="h5"
                    fontWeight="500"
                    textAlign="center"
                    padding="0px 10px 10px 10px"
                  >
                    Here you can invite more people to be part of your team by
                    completing the following fields.
                  </Typography>
                </Box>
                <Box style={{ width: "80%" }}>
                  <Typography variant="h5" fontWeight="bold">
                    Full Name
                  </Typography>
                  <input
                    type="text"
                    className="checkout-working-live-email-form-input"
                    placeholder="Enter Full Name"
                    onChange={(e) => setNameInvitation(e.target.value)}
                  />
                </Box>
                <Box style={{ width: "80%" }}>
                  <Typography variant="h5" fontWeight="bold">
                    Email
                  </Typography>
                  <input
                    type="email"
                    className="checkout-working-live-email-form-input"
                    placeholder="Enter Email"
                    onChange={(e) => setEmailInvitation(e.target.value)}
                  />
                </Box>
                <Box style={{ width: "80%" }}>
                  <Typography variant="h5" fontWeight="bold">
                    Referral Indentifier
                  </Typography>
                  <input
                    type="email"
                    className="checkout-working-live-email-form-input"
                    placeholder="Company Referral Indentifier"
                    onChange={(e) => setReferralINvitation(e.target.value)}
                  />
                </Box>
                <div className="content-btn-down">
                  <Button
                    className="btn-width-start-exit"
                    onClick={(e) => {
                      sendInvitation();
                    }}
                    disabled={
                      !nameInvitation || !emailInvitation || !referralInvitation
                    }
                  >
                    {loadingInvitation ? (
                      <CircularProgress sx={{ color: "#dec567" }} />
                    ) : (
                      "SEND INVITATION"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {invitationSended && (
            <>
              <Confetti numberOfPieces={70} tweenDuration={50}></Confetti>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div>
                  <h2
                    style={{
                      fontWeight: "bold",
                      color: "#202020",
                      padding: "0px 30px 10px 30px",
                      textAlign: "center",
                    }}
                  >
                    Invitation sent! 🎉
                  </h2>
                </div>
                <div className="content-form">
                  <Box className="content-form-header">
                    <Typography
                      variant="h5"
                      fontWeight="500"
                      textAlign="center"
                      padding="0px 20px 20px 20px"
                    >
                      The invitation for <b>{nameInvitation}</b> has been sent.
                      The invitation was sent to the email{" "}
                      <b>{emailInvitation}</b>
                    </Typography>
                  </Box>
                </div>
              </div>
            </>
          )}

          <button
            className="close-modal"
            onClick={() => {
              setInvite(false);
              setEmailInvitation("");
              setNameInvitation("");
              setReferralINvitation("");
              setInvitationSended(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>

      {/*  Confirm Delete  */}
      <div className="App">
        <Modal
          isOpen={checkDeleteModal}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max-delete",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          {!successDeleteMeeting && (
            <div>
              <div>
                <h2
                  style={{
                    fontWeight: "bold",
                    color: "#202020",
                    padding: "30px 30px 15px 30px",
                    textAlign: "center",
                  }}
                >
                  Delete Meeting
                </h2>
              </div>
              <div className="content-form">
                <Box className="content-form-header">
                  <Typography
                    variant="h5"
                    fontWeight="500"
                    textAlign="center"
                    padding="0px 10px 10px 10px"
                  >
                    Are you sure you want to delete meeting{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {meetingIdForDelete}
                    </span>{" "}
                    with topic "
                    <span style={{ fontWeight: "bold" }}>
                      {checkMeetingTopic}
                    </span>
                    "?
                  </Typography>
                </Box>
                <div className="content-btn-down">
                  <Button
                    className="btn-width-start-delete"
                    onClick={(e) => {
                      sendDeleteMeeting(meetingIdForDelete);
                    }}
                  >
                    {loadingDeleteMeeting ? (
                      <CircularProgress sx={{ color: "#FFFFFF" }} />
                    ) : (
                      "DELETE"
                    )}
                  </Button>
                  <Button
                    className="btn-width-start-exit"
                    onClick={(e) => {
                      setCheckDeleteModal(false);
                    }}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            </div>
          )}
          {successDeleteMeeting && (
            <>
              <Confetti numberOfPieces={70} tweenDuration={50}></Confetti>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div>
                  <h2
                    style={{
                      fontWeight: "bold",
                      color: "#202020",
                      padding: "0px 30px 10px 30px",
                      textAlign: "center",
                    }}
                  >
                    Meeting has been deleted! 🎉
                  </h2>
                </div>
                <div className="content-form">
                  <Box className="content-form-header">
                    <Typography
                      variant="h5"
                      fontWeight="500"
                      textAlign="center"
                      padding="0px 20px 20px 20px"
                    >
                      The meeting topic "
                      <span style={{ fontWeight: "bold" }}>
                        {checkMeetingTopic}
                      </span>
                      " with ID{" "}
                      <span style={{ fontWeight: "bold" }}>
                        #{meetingIdForDelete}
                      </span>{" "}
                      has been deleted, which means it won't show up in your
                      past meetings.
                    </Typography>
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        className="btn-width-start-exit"
                        onClick={(e) => {
                          setCreateMeeting(createdMeeting + 1);
                          setCheckDeleteModal(false);
                          setSuccessDeleteMeeting(false);
                        }}
                      >
                        ACCEPT
                      </Button>
                    </Box>
                  </Box>
                </div>
              </div>
            </>
          )}

          <button
            className="close-modal"
            onClick={() => {
              setCheckDeleteModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>
    </>
  );
};

export default withAuthenticationRequired(Dashboard);
