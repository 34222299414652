import React from "react";
import { Box, Button, Typography } from "@mui/material";
import "./index.scss";
import { useAuth0 } from "@auth0/auth0-react";

export const ContentMeetings = (props) => {
  const { user } = useAuth0();
  return (
    <Box className="header-create-meeting">
      <Box className="header-create-meeting-text">
        <Typography variant="h5" fontWeight="bold" color="#412770">
          Create a new Zoom{" "}
          {!user["http://locahost:5000/roles"]?.includes("BASIC") &&
            !user["http://locahost:5000/roles"]?.includes("VENTURE") &&
            "shopping"}{" "}
          meeting
        </Typography>
        <Typography variant="h6" style={{ color: "#8f72ae" }}>
          Set up the new meetings you want to create.
        </Typography>
      </Box>
      <Box>
        <Button
          className="start-meeting-tab"
          onClick={props.onClick}
          disabled={props.isActive}
        >
          Set Up New Meeting
        </Button>
        <Button
          className="invite-meeting-tab"
          onClick={props.onInvite}
          style={{ marginLeft: "5px" }}
          disabled={props.isActive}
        >
          Invite
        </Button>
      </Box>
    </Box>
  );
};
