import React from "react";
import { Button } from "@mui/material";
import { useStopwatch } from "react-timer-hook";

const MyTimer = () => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    reset,
  } = useStopwatch({
    autoStart: true,
  });

  return (
    <div
      style={{
        textAlign: "left",
      }}
    >
      <div style={{ fontSize: "20px" }}>
        <span style={{ fontWeight: "bold" }}>Slide Time: </span>
        <span>
          {hours <= 9 ? 0 : ""}
          {hours}
        </span>
        :
        <span>
          {minutes <= 9 ? 0 : ""}
          {minutes}
        </span>
        :
        <span>
          {seconds <= 9 ? 0 : ""}
          {seconds}
        </span>
        <Button className="btn-zrp-reset" onClick={reset}>
          Click to Reset
        </Button>
      </div>
    </div>
  );
};

export default MyTimer;
