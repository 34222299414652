import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SlowMotionVideoOutlinedIcon from "@mui/icons-material/SlowMotionVideoOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useAuth0 } from "@auth0/auth0-react";
import { isMobile } from "react-device-detect";
import * as React from "react";
import { useGlobalState } from "../../hooks/state";

const Item = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  onClick,
  disabled,
}) => {
  console.log("Disabled State: ", disabled);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => {
        if (title == "Logout") {
          onClick();
        } else {
          setSelected(title);
        }
      }}
      icon={icon}
      className={`${disabled ? "disabled-menu-item" : ""}`}
    >
      <Typography className={`${disabled ? "disabled-menu-item" : ""}`} variant="h5">{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = (props) => {
  const { user, logout } = useAuth0();
  const [userStatus] = useGlobalState("userStatus");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  useEffect(() => {
    if (isMobile) {
      setIsCollapsed(true);
    }
  }, []);

  return (
    <Box
      className={
        isMobile
          ? props.mobileCollapsed
            ? "slider-mobile-version"
            : "slider-mobile-version slider-mobile-version-open"
          : ""
      }
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
          color: "#000000 !important",
        },
        "& .pro-inner-item:hover": {
          color: "#8F72AE !important",
          fontWeight: "bold",
        },
        "& .pro-menu-item.active": {
          color: "#412770 !important",
          fontWeight: "bold",
        },
      }}
    >
      <ProSidebar collapsed={isMobile ? props.mobileCollapsed : isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          {!isMobile && (
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
                color: "#412770",
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <img
                    src="https://nblpublicfiles.s3.amazonaws.com/NBL_logo-flat.png"
                    alt="National Business League"
                    width="80px"
                    className="logo-image-dashboard"
                  />
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon
                      fontSize="large"
                      style={{ color: "#412770" }}
                    />
                  </IconButton>
                </Box>
              )}
            </MenuItem>
          )}
          {isMobile && (
            <MenuItem
              onClick={props.onClick}
              icon={
                props.mobileCollapsed ? (
                  <MenuOutlinedIcon style={{ color: "#412770" }} />
                ) : undefined
              }
              style={{
                margin: "10px 0 20px 0",
                color: "#8F72AE",
              }}
            >
              {!props.mobileCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="0px"
                >
                  <img
                    src="https://nblpublicfiles.s3.amazonaws.com/NBL_logo-flat.png"
                    alt="WorkingLive"
                    width="110px"
                    className="logo-image-dashboard"
                  />
                  <IconButton onClick={props.onClick}>
                    <CloseOutlinedIcon sx={{ fontSize: "30px !important" }} />
                  </IconButton>
                </Box>
              )}
            </MenuItem>
          )}

          {!props.mobileCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`${user?.picture}`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0", color: "#412770" }}
                >
                  {user?.name}
                </Typography>
                {/* <Typography
                  variant="h5"
                  color="#b01d54"
                  mt="10px"
                  fontWeight="bold"
                >
                  International Sales Director
                </Typography> */}
              </Box>
            </Box>
          )}
          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`${user?.picture}`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0", color: "#412770" }}
                >
                  {user?.name}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon fontSize="large" />}
              selected={selected}
              setSelected={setSelected}
              disabled={userStatus}
            />
            <Item
              title="My Profile"
              to="/profile"
              icon={<AccountCircleOutlinedIcon fontSize="large" />}
              selected={selected}
              setSelected={setSelected}
              disabled={userStatus}
            />
            {/* <Item
              title="Payment Methods"
              to="/payment-methods"
              icon={<PaymentOutlinedIcon fontSize="large" />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Zoom Settings"
              to="/settings"
              icon={<SettingsOutlinedIcon fontSize="large" />}
              selected={selected}
              setSelected={setSelected}
              disabled={userStatus}
            />
            <Item
              title="Zoom Recording"
              to="/recordings"
              icon={<SlowMotionVideoOutlinedIcon fontSize="large" />}
              selected={selected}
              setSelected={setSelected}
              disabled={userStatus}
            />
            <Item
              title="Logout"
              onClick={logout}
              icon={<LogoutOutlinedIcon fontSize="large" />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
