import React from "react";
import { Box, Typography } from "@mui/material";

const FourthNote = () => {
  return (
    <Box className="notes-container-zrp">
      <Typography variant="h5" fontWeight="bold" textAlign="left">
        <span className="number-detail-italic">1</span> Are you having fun so
        far?
      </Typography>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          I hope you have been learning some useful tips about the importance of
          caring for your skin.
        </Typography>
      </Box>

      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          Now I’d like to take a moment to show you our most popular sets.
        </Typography>
      </Box>
    </Box>
  );
};

export default FourthNote;
