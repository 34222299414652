import React, { useState, useEffect } from "react";
import { contentNotes, presentation } from "../../data/mockData";
import { Typography, Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { SliderHost } from "../../components/SliderHost";
import "./index.css";
import FirstNote from "../notes/firstNote";
import SecondNotes from "../notes/secondNote";
import ThirdNote from "../notes/thirdNote";
import FourthNote from "../notes/fourthNote";
import FifthNote from "../notes/fifthNote";
import SixthNote from "../notes/sixthNote";
import SeventhNote from "../notes/seventhNote";
import EigthNote from "../notes/eigthNote";

const Host = (props) => {
  const [currentSlide, setCurrentSlide] = useState("");
  const { user } = useAuth0();
  const time = new Date();
  time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
  const [actualImage, setActualImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActualImage(parseInt(localStorage.getItem("actual")));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box className="presentation-container">
      <Box className="presentation-sub-container">
        <SliderHost
          navButtonsProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              backgroundColor: "#red",
              borderRadius: 0,
              top: "unset",
            },
          }}
          navButtonsWrapperProps={{
            // Move the buttons to the bottom. Unsetting top here to override default style.
            style: {
              bottom: "0",
              backgroundColor: "#red",
              top: "unset",
            },
          }}
          sliders={presentation}
          activeControls={true}
        />
        {actualImage == 0 && (
          <Box className="notes-container-zrp">
            <Typography
              variant="h4"
              fontWeight="bold"
              textAlign="left"
              fontStyle="italic"
              color="#e64682"
            >
              The party has started, you can tell the guests the purpose of this
              meeting.
            </Typography>
          </Box>
        )}
        {actualImage == 1 && <FirstNote />}
        {actualImage == 2 && <SecondNotes />}
        {actualImage == 3 && <ThirdNote />}
        {actualImage == 4 && <FourthNote />}
        {actualImage == 5 && <FifthNote />}
        {actualImage == 6 && <SixthNote />}
        {actualImage == 7 && <SeventhNote />}
        {actualImage == 8 && <EigthNote />}
      </Box>
    </Box>
  );
};

export default Host;
