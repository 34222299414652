const deleteMeeting = async (meetingId, token, comp) => {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      comp: comp,
    },
  };

  await fetch(
    `https://meetingssellwithzoom-develop.herokuapp.com/meeting/${meetingId}`,
    options
  )
    .then((res) => {
      if (res.ok) return res.json();
      else throw new Error("Error Deleting Meeting");
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return { error: error };
    });
};

export default deleteMeeting;
