import React from "react";
import { Box, Typography } from "@mui/material";

const SecondNotes = () => {
  return (
    <Box className="notes-container-zrp">
      <Typography variant="h5" fontWeight="bold" textAlign="left">
        <span className="number-detail-italic">1</span> It is great to meet
        everyone, and I am so glad you’ve joined us today! Now I’d like to tell
        you a little about Mary Kay Ash. You can read the dialogue, play the
        dialogue, or play the video
      </Typography>
      <Typography variant="h4" fontWeight="bold">
        Audio Help:
      </Typography>
      <audio
        src="https://marykay.workinglive.us/wp-content/uploads/sites/11/2021/08/pg-3-Mary-Kay-Ash.mp3"
        controls
      />
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">2</span> Mary Kay was a single
          mom struggling to make ends meet in a male-dominated business world
          for more than 25 years. She retired in protest after yet another man
          whom she had trained was promoted above her.
        </Typography>
      </Box>

      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">3</span> In 1963, Mary Kay
          thought about her career frustrations and wished there was a company
          where women could be fully rewarded for their skills and abilities.
          She realized she didn’t have to just sit and wish; she could start
          that kind of company herself. And she knew she wanted that company to
          sell a product that would create confidence and make women feel
          beautiful inside and out.
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">4</span> This Company follows
          the Golden Rule and emphasizes the importance of giving back.
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">5</span> I’m a small business
          owner, and when you buy from me, you’re supporting MY DREAMS and your
          local community.
        </Typography>
      </Box>
    </Box>
  );
};

export default SecondNotes;
