import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import LayoutLite from "../global/LayoutLite";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import { CardLite } from "../../components/CardLite";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { generateMeeting } from "../../services/meetings/meetings";
import { isMobile } from "react-device-detect";
import { useGlobalState } from "../../hooks/state";

const DashboardLite = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [loadingInsta, setLoadingInsta] = useState(false);
  const [successGenerate, setSuccessGenerate] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState(null);

  useEffect(() => {
    console.log("Mobile: ", isMobile);
  }, []);

  const sendInstantRequest = async () => {
    setLoadingInsta(true);
    const token = await getAccessTokenSilently();
    const resGenerate = await generateMeeting(user.given_name, token);
    if (resGenerate.meetingInfo) {
      setMeetingInfo(resGenerate.meetingInfo);
      setSuccessGenerate(true);
      window.open(resGenerate.meetingInfo.join_url, "_blank");
    }
    console.log("Result Generate: ", resGenerate);
    setLoadingInsta(false);
  };

  const supportPage = () => {
    window.open(
      "https://forms.helpdesk.com?licenseID=68633871&contactFormID=a117c040-b3f6-4a13-9aa3-970ca4d34246",
      "_blank"
    );
  };

  const openZoomApp = () => {
    window.open("https://zoom.us/es/signin#/login", "_blank");
  };

  return (
    <>
      <LayoutLite>
        <Box className="dashboard-container-lite">
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
            gap="25px"
          >
            <Box style={{ width: "100%", padding: "10px 0px" }}>
              <Typography
                variant="h3"
                fontWeight="600"
                textAlign="left"
                color="#4B4B4B"
              >
                WELCOME BACK{" "}
                <span
                  style={{ fontWeight: "bold", textTransform: "uppercase" }}
                >
                  {user.name}
                </span>
              </Typography>
            </Box>
            <Box className="container-main-lite">
              {!isMobile && (
                <CardLite
                  icon={<AddCircleOutlineOutlinedIcon fontSize="large" />}
                  image="https://nblpublicfiles.s3.amazonaws.com/Screenshot+2023-05-17+at+11.54.42+AM.png"
                  title="Instant Meeting"
                  detail="Start a meeting with a single click and start today."
                  textButton="Start a Meeting"
                  onClick={sendInstantRequest}
                  isLoading={loadingInsta}
                  status={successGenerate}
                />
              )}
              <CardLite
                icon={<AddCircleOutlineOutlinedIcon fontSize="large" />}
                image="https://nblpublicfiles.s3.amazonaws.com/Screenshot+2023-05-17+at+11.54.42+AM.png"
                title="Go To Zoom"
                detail="Schedule or join a meeting from your zoom account."
                textButton="Connect to Zoom"
                onClick={openZoomApp}
              />
              <CardLite
                icon={<AddCircleOutlineOutlinedIcon fontSize="large" />}
                image="https://nblpublicfiles.s3.amazonaws.com/Screenshot+2023-05-17+at+11.54.42+AM.png"
                title="Contact Support"
                detail="Contact WorkingLive for Zoom Support."
                textButton="Contact"
                onClick={supportPage}
              />
            </Box>
          </Box>
        </Box>
      </LayoutLite>
    </>
  );
};

export default withAuthenticationRequired(DashboardLite);
