import React from "react";
import { useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Button, TextField, MenuItem, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import Header from "../../components/Header";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Switch from "@mui/material/Switch";
import SubTitle from "../../components/Subtitle";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Layout from "../global/Layout";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

function Row(props) {
  const { row } = props;
  const [openDetails, setOpenDetails] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenDetails(!openDetails)}
          >
            {openDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="right">{row.bonusId}</TableCell>
        <TableCell align="right">{row.bonusTitle}</TableCell>
        <TableCell align="right">{row.commissionDate}</TableCell>
        <TableCell align="right">{row.amount}</TableCell>
        <TableCell align="right">{row.date}</TableCell>
        <TableCell align="right">{row.description}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openDetails} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Customer ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell align="right">Released</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => {
                    return (
                      <TableRow key={historyRow.id}>
                        <TableCell component="th" scope="row">
                          {historyRow.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {historyRow.nodeId}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {historyRow.fullName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {historyRow.commissionDate}
                        </TableCell>
                        <TableCell>{historyRow.released}</TableCell>
                        <TableCell align="right">
                          {historyRow.transaction}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    bonusId: PropTypes.string.isRequired,
    bonusTitle: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        nodeId: PropTypes.number.isRequired,
        fullName: PropTypes.string.isRequired,
        commissionDate: PropTypes.string.isRequired,
        released: PropTypes.number.isRequired,
        transaction: PropTypes.string.isRequired,
      })
    ).isRequired,
    due: PropTypes.string.isRequired,
    paynow: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#dec567" : "#dec567",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#9C9C9C" : "#9C9C9C",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const Profile = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user, getAccessTokenSilently } = useAuth0();
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [requirePsw, setRequirePsw] = useState(false);
  const [liveStreaming, setLiveStreaming] = useState(false);
  const [breakoutRoom, setBreakOutRoom] = useState(false);
  const [shareMeeting, setShareMeeting] = useState(false);
  const [waitingRoom, setWaitingRoom] = useState(false);
  const [personalMID, setPersonalMID] = useState(false);

  useEffect(() => {
    setLoadingInfo(true);
    const loadUserSettings = async () => {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          comp: process.env.REACT_APP_TENANT,
        },
      };

      fetch(
        `https://meetingssellwithzoom-develop.herokuapp.com/userssettingszoom`,
        options
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setRequirePsw(
            response?.schedule_meeting
              ?.require_password_for_scheduling_new_meetings
          );
          setLiveStreaming(response?.in_meeting?.allow_live_streaming);
          setBreakOutRoom(response?.in_meeting?.breakout_room);
          setShareMeeting(
            response?.in_meeting?.disable_screen_sharing_for_in_meeting_guests
          );
          setWaitingRoom(response?.in_meeting?.waiting_room);
          setPersonalMID(response?.schedule_meeting?.personal_meeting);
          setLoadingInfo(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoadingInfo(false);
        });
    };
    loadUserSettings();
  }, []);

  return (
    <Layout>
      <Box m="20px">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Header title="Account Settings" />
        </Box>

        {!loadingInfo && (
          <Box>
            <Box className="subtitle-btn-container">
              <Typography variant="h4" textAlign="left" fontWeight="500">
                <span style={{ fontWeight: "bold" }}>{user.given_name}</span>{" "}
                this is your zoom account settings.
              </Typography>
              <Button className="start-meeting-tab">Save changes</Button>
            </Box>
            <Box className="zoom-setting-item">
              <Typography
                variant="h4"
                textAlign="left"
                fontWeight="bold"
                color="#777777"
              >
                Waiting Room:
              </Typography>
              <Box className="zoom-setting-subitem">
                <Typography variant="h6" textAlign="left">
                  This can be used by participants at any time to access your
                  personal virtual room.
                </Typography>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={waitingRoom}
                      onClick={() => setWaitingRoom(!waitingRoom)}
                    />
                  }
                />
              </Box>
            </Box>
            <Box className="zoom-setting-item">
              <Typography
                variant="h4"
                textAlign="left"
                fontWeight="bold"
                color="#777777"
              >
                Require Password for future meetings:
              </Typography>
              <Box className="zoom-setting-subitem">
                <Typography variant="h6" textAlign="left">
                  This can be used by participants at any time to access your
                  personal virtual room.
                </Typography>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={requirePsw}
                      onClick={() => setRequirePsw(!requirePsw)}
                    />
                  }
                />
              </Box>
            </Box>
            <Box className="zoom-setting-item">
              <Typography
                variant="h4"
                textAlign="left"
                fontWeight="bold"
                color="#777777"
              >
                Facebook/Youtube Live Streaming:
              </Typography>
              <Box className="zoom-setting-subitem">
                <Typography variant="h6" textAlign="left">
                  This can be used by participants at any time to access your
                  personal virtual room.
                </Typography>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={liveStreaming}
                      onClick={() => setLiveStreaming(!liveStreaming)}
                    />
                  }
                />
              </Box>
            </Box>
            <Box className="zoom-setting-item">
              <Typography
                variant="h4"
                textAlign="left"
                fontWeight="bold"
                color="#777777"
              >
                Breakout Rooms:
              </Typography>
              <Box className="zoom-setting-subitem">
                <Typography variant="h6" textAlign="left">
                  This can be used by participants at any time to access your
                  personal virtual room.
                </Typography>
                <FormControlLabel
                  control={<IOSSwitch sx={{ m: 1 }} />}
                  checked={breakoutRoom}
                  onClick={() => setBreakOutRoom(!breakoutRoom)}
                />
              </Box>
            </Box>
            <Box className="zoom-setting-item">
              <Typography
                variant="h4"
                textAlign="left"
                fontWeight="bold"
                color="#777777"
              >
                Auto Recording:
              </Typography>
              <Box className="zoom-setting-subitem">
                <Typography variant="h6" textAlign="left">
                  This can be used by participants at any time to access your
                  personal virtual room
                </Typography>
                <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />
              </Box>
            </Box>
            <Box className="zoom-setting-item">
              <Typography
                variant="h4"
                textAlign="left"
                fontWeight="bold"
                color="#777777"
              >
                Audio Transcript:
              </Typography>
              <Box className="zoom-setting-subitem">
                <Typography variant="h6" textAlign="left">
                  This can be used by participants at any time to access your
                  personal virtual room.
                </Typography>
                <FormControlLabel
                  control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                />
              </Box>
            </Box>
            <Box className="zoom-setting-item">
              <Typography
                variant="h4"
                textAlign="left"
                fontWeight="bold"
                color="#777777"
              >
                Screen Share:
              </Typography>
              <Box className="zoom-setting-subitem">
                <Typography variant="h6" textAlign="left">
                  This can be used by participants at any time to access your
                  personal virtual room.
                </Typography>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={shareMeeting}
                      onClick={() => setShareMeeting(!shareMeeting)}
                    />
                  }
                />
              </Box>
            </Box>
            <Box className="zoom-setting-item">
              <Typography
                variant="h4"
                textAlign="left"
                fontWeight="bold"
                color="#777777"
              >
                Use personal meeting id:
              </Typography>
              <Box className="zoom-setting-subitem">
                <Typography variant="h6" textAlign="left">
                  This can be used by participants at any time to access your
                  personal virtual room.
                </Typography>
                <FormControlLabel
                  control={<IOSSwitch sx={{ m: 1 }} />}
                  checked={personalMID}
                  onClick={() => setPersonalMID(!personalMID)}
                />
              </Box>
            </Box>
          </Box>
        )}
        {loadingInfo && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            flexDirection="column"
          >
            <CircularProgress
              style={{ color: "#00000", width: "70px", height: "70px" }}
            />
            <Typography variant="h6" fontWeight="bold">
              Loading...
            </Typography>
          </Box>
        )}
        {/*  <Box sx={{ flexGrow: 1, height: "500px" }}>
    <Grid container spacing={2}>
      <Grid item xs={4} md={4}>
        <div className="phone-number-4 opensans-bold-cloud-16px">
          Bonus of {userInfo.firstName + " " + userInfo.lastName}
        </div>
      </Grid>
      <Grid item xs={12} md={12}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Id</TableCell>
                <TableCell>Bonus Id</TableCell>
                <TableCell align="right">Bonus</TableCell>
                <TableCell align="right">HNT Due</TableCell>
                <TableCell align="right">HNT Pay Now</TableCell>
                <TableCell align="right">Com. Date</TableCell>
                <TableCell align="right">Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bonusesUser.map((row) => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </Box> */}
      </Box>
    </Layout>
  );
};

export default withAuthenticationRequired(Profile);
