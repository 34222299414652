import React from "react";
import { Box, Typography } from "@mui/material";

const EigthNote = () => {
  return (
    <Box className="notes-container-zrp">
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          1. If you’ve ever tried a face mask, you’ll know masking is all the
          rage. So I would love to introduce you to one of my favorite masks.
        </Typography>
        <Typography
          variant="h5"
          fontWeight="bold"
          textAlign="left"
          marginTop="30px"
        >
          It may sound illogical, but getting a little dirty can actually help
          deliver skin that’s deep-down clean. That’s the magic of activated
          charcoal.
        </Typography>
        <Typography
          variant="h5"
          fontWeight="bold"
          textAlign="left"
          marginTop="30px"
        >
          This is no ordinary mask! This triple-action mask deeply cleanses
          skin, immediately reduces shine and features an effective complex of
          botanical extracts to help clear the way to beautiful skin.
        </Typography>
      </Box>
    </Box>
  );
};

export default EigthNote;
