import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography } from "@mui/material";
import Navbar from "../../components/navbar/Navbar";
import PaymentForm from "../../components/PayCreditCard/PaymentForm";
import { useSearchParams } from "react-router-dom";
import InlineCartPageSub from "../../components/CollectJsSection/InlineCartFormSub";
import { Footer } from "../../components/Footer/Footer";
import "./index.scss";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "@mui/material/Button";
import { PlanCard } from "../../components/PlanCard/PlanCard";
import { isMobile } from "react-device-detect";
import { countriesData, statesData } from "../../data/mockData";
import _ from "lodash";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];

const Payments = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  let [searchParams, setSearchParams] = useSearchParams();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [zip, setZip] = useState(null);
  const [username] = useState(user.nickname);
  const [state, setState] = useState(null);
  const [firstStep, setFirstStep] = useState(true);
  const [secondStep, setSecondStep] = useState(false);
  const [lastStep, setlastStep] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [countries, setCountries] = useState();
  const [dataLocation, setDataLocation] = useState();
  const [groupStates, setGroupStates] = useState();
  const [countrySelected, setCountrySelected] = useState();
  const [totalCard, setTotalCard] = useState(0);
  const [planId, setPlanId] = useState(null);
  const [myToken, setMyTOken] = useState();
  const [firstValidate, setFirstValidate] = useState([]);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const nombre = params.get("nombre");

    setTotalCard(
      JSON.parse(window.sessionStorage.getItem("set_up_plan_payment"))?.plan
    );
    setPlanId(
      JSON.parse(window.sessionStorage.getItem("set_up_plan_payment"))?.planId
    );
    setGroupStates(
      _(statesData)
        .groupBy((x) => x.country_id)
        .map((value, key) => ({ idCountry: key, info: value }))
        .value()
    );

    const getMyToken = async () => {
      setMyTOken(await getAccessTokenSilently());
    };

    const getPostMessage = () => {
      window.addEventListener(
        "message",
        function (event) {
          if (event.data.type === "payment") {
            window.location.replace("/verify");
            // se realiza una acción en respuesta al mensaje recibido
          }
        },
        false
      );
    };
    getPostMessage();
    getMyToken();
  }, []);

  useEffect(() => {
    const options = {
      method: "GET",
    };

    fetch(`https://countriesnow.space/api/v0.1/countries`, options)
      .then((res) => res.json())
      .then((res) => {
        setDataLocation(res.data);
      })
      .catch((err) => console.log("New Error: ", err));
  }, []);

  return (
    <Box className="payment-container-page">
      <Navbar></Navbar>
      <Box className="form-content">
        <Box className="form-content-payment-title">
          <Typography variant="h2" fontWeight="bold">
            <span style={{ color: "#B42560" }}>Welcome!</span> Choose your
            favorite plan
          </Typography>
          <Typography variant="h5" fontWeight="500">
            We are pleased to have you here, to continue you must choose one of
            the available plans.
          </Typography>
        </Box>
        <Box
          className="cards-payment-container"
          sx={{
            display: "flex",
            gap: "15px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {/* <PlanCard
            planTitle="WorkingLive Shopping"
            planTotal={5}
            regular={true}
          ></PlanCard> */}
          <PlanCard
            planTitle="The original WorkingLive Webinar"
            planTotal={totalCard}
            max={true}
            selected={true}
          />
          {/* <PlanCard
            planTitle="WorkingLive Shopping Max"
            planTotal={17.99}
            max={true}
            selected={true}
          /> */}
          <Box className="form-content-first-box">
            <Box style={{ borderBottom: "1px solid #000", width: "100%" }}>
              <Typography variant="h3" fontWeight="bold" fontFamily="inherit">
                Payment Form
              </Typography>
              <Typography
                fontFamily="inherit"
                paddingTop="10px"
                paddingBottom="10px"
                variant="h6"
              >
                *The original WorkingLive Webinar plan has been selected, a
                charge of{" "}
                <span style={{ fontWeight: "bold" }}>${totalCard}</span> will be
                made to your payment method{" "}
                <span style={{ fontWeight: "bold" }}>
                  (This subscription is{" "}
                  {planId == "swzpremiummonthly" ? "monthly" : "yearly"})
                </span>
                .
              </Typography>
            </Box>
            {firstStep && (
              <Box className="payment-form-fields class-for-move">
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box style={{ width: "49%" }}>
                    <Typography variant="h5" fontWeight={600}>
                      First Name (Required)
                      <span style={{ color: "#ff0000" }}> *</span>
                    </Typography>
                    <input
                      className="input-credentials"
                      type="text"
                      placeholder="ex: Richard"
                      style={{ fontSize: "16px" }}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      value={firstName}
                    />
                  </Box>
                  <Box style={{ width: "49%" }}>
                    <Typography variant="h5" fontWeight={600}>
                      Last Name (Required)
                      <span style={{ color: "#ff0000" }}> *</span>
                    </Typography>
                    <input
                      className="input-credentials"
                      type="text"
                      placeholder="ex: Venegas"
                      required
                      style={{ fontSize: "16px" }}
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                  </Box>
                </Box>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box style={{ width: "49%" }}>
                    <Typography variant="h5" fontWeight={600}>
                      Phone Number (Required)
                      <span style={{ color: "#ff0000" }}> *</span>
                    </Typography>
                    <input
                      className="input-credentials"
                      type="number"
                      pattern="[0-9]{10}"
                      required
                      placeholder="000 000 0000"
                      style={{ fontSize: "16px" }}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                    />
                  </Box>
                  <Box style={{ width: "49%" }}>
                    <Typography variant="h5" fontWeight={600}>
                      Username
                    </Typography>
                    <input
                      className="input-credentials"
                      type="email"
                      placeholder="ex: myusername"
                      required
                      style={{ fontSize: "16px" }}
                      disabled={true}
                      value={user.nickname}
                    />
                  </Box>
                </Box>
                <Box style={{ width: "100%" }}>
                  <Typography variant="h5" fontWeight={600}>
                    E-mail
                  </Typography>
                  <input
                    className="input-credentials"
                    type="email"
                    disabled={true}
                    placeholder="ex: myname@example.com"
                    style={{ fontSize: "16px" }}
                    value={user?.email}
                  />
                </Box>

                <Box className="next-btn-payment">
                  <Button
                    className="start-meeting-tab only-next-payment"
                    onClick={() => {
                      setFirstStep(false);
                      setSecondStep(true);
                    }}
                    disabled={
                      !firstName || !lastName || !username || !phoneNumber
                    }
                  >
                    Next <NavigateNextIcon />
                  </Button>
                </Box>
              </Box>
            )}

            {secondStep && (
              <Box className="payment-form-fields class-for-move">
                <Box style={{ width: "100%" }}>
                  <Typography variant="h5" fontWeight={600}>
                    Address Line 1<span style={{ color: "#ff0000" }}> *</span>
                  </Typography>
                  <input
                    className="input-credentials"
                    type="email"
                    placeholder="ex: 305 Creek RD"
                    required
                    style={{ fontSize: "16px" }}
                    onChange={(e) => setAddress1(e.target.value)}
                    value={address1}
                  />
                </Box>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box style={{ width: "49%" }}>
                    <Typography variant="h5" fontWeight={600}>
                      Country<span style={{ color: "#ff0000" }}> *</span>
                    </Typography>
                    <select
                      className="input-credentials"
                      name="country"
                      id="country"
                      placeholder="ex: Canada"
                      onChange={(e) => {
                        setCountry(JSON.parse(e.target.value));
                        setCountrySelected(JSON.parse(e.target.value).iso2);
                      }}
                      value={JSON.stringify(country)}
                    >
                      <option value="">--Select a Country--</option>
                      {countriesData?.map((data) => {
                        return (
                          <option value={JSON.stringify(data)}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                  </Box>
                  <Box style={{ width: "49%" }}>
                    <Typography variant="h5" fontWeight={600}>
                      State<span style={{ color: "#ff0000" }}> *</span>
                    </Typography>
                    <select
                      className="input-credentials"
                      name="state"
                      id="state"
                      placeholder="ex: Montreal"
                      onChange={(e) => {
                        setState(e.target.value);
                      }}
                      value={state}
                    >
                      <option value="">--Select a State--</option>
                      {groupStates?.map((data) => {
                        if (parseInt(data?.idCountry) == country?.id) {
                          return data?.info?.map((infoState) => {
                            return (
                              <option value={infoState?.state_code}>
                                {infoState?.name}
                              </option>
                            );
                          });
                        }
                      })}
                    </select>
                    {/* <input
                      className="input-credentials"
                      type="email"
                      placeholder="ex: TN"
                      style={{ fontSize: "16px" }}
                      onChange={(e) => setState(e.target.value)}
                    /> */}
                  </Box>
                </Box>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box style={{ width: "49%" }}>
                    <Typography variant="h5" fontWeight={600}>
                      City<span style={{ color: "#ff0000" }}> *</span>
                    </Typography>
                    <input
                      className="input-credentials"
                      type="text"
                      placeholder="ex: Sevierville"
                      required
                      style={{ fontSize: "16px" }}
                      onChange={(e) => setCity(e.target.value)}
                      value={city}
                    />
                  </Box>
                  <Box style={{ width: "49%" }}>
                    <Typography variant="h5" fontWeight={600}>
                      Zip<span style={{ color: "#ff0000" }}> *</span>
                    </Typography>
                    <input
                      className="input-credentials"
                      type="email"
                      placeholder="ex: 37645"
                      required
                      style={{ fontSize: "16px" }}
                      onChange={(e) => setZip(e.target.value)}
                      value={zip}
                    />
                  </Box>
                </Box>
                <Box className="next-btn-payment-both">
                  <Button
                    className="start-meeting-tab only-next-payment"
                    onClick={() => {
                      setFirstStep(true);
                      setSecondStep(false);
                    }}
                  >
                    <ChevronLeftIcon /> Back
                  </Button>
                  <Button
                    className="start-meeting-tab only-next-payment"
                    onClick={() => {
                      setSecondStep(false);
                      setlastStep(true);
                    }}
                    disabled={!zip || !address1 || !city || !state || !country}
                  >
                    Next <NavigateNextIcon />
                  </Button>
                </Box>
              </Box>
            )}
            {lastStep && (
              <Box className="form-content-second-box class-for-move">
                {/* <InlineCartPageSub
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  phoneNumber={phoneNumber}
                  city={city}
                  address1={address1}
                  zip={zip}
                  username={username}
                  amount={5.99}
                  state={state}
                  session={searchParams.get("session_token")}
                /> */}
                <iframe
                  //src="https://nmifront.vercel.app/?total=12.99&orderId=410144367&storeId=52438034&organization=test2"
                  src={`https://nmifront.vercel.app/?total=${totalCard}&orderId=410144367&storeId=52438034&organization=test2&paymentType=swz&myState=${state}&zip=${zip}&planId=${planId}&city=${city}&firstName=${firstName}&lastName=${lastName}&username=${username}&address1=${address1}&emailAddress=${user.email}&token=${myToken}`}
                  width="100%"
                  height={isMobile ? "450px" : "350px"}
                  style={{ border: "0px" }}
                />
                <Button
                  className="start-meeting-tab only-last-payment"
                  onClick={() => {
                    setSecondStep(true);
                    setlastStep(false);
                  }}
                >
                  <ChevronLeftIcon /> Back
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Footer></Footer>
    </Box>
  );
};

export default withAuthenticationRequired(Payments);
