import React, { useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { QRCode } from "react-qrcode-logo";
import html2canvas from "html2canvas";
import "./index.scss";
import Modal from "react-modal";

Modal.setAppElement("#root");
export const CardDetailMeeting = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);
  const [copiedQR, setCopiedQR] = useState(false);

  const facebook = "/assets/images/virtual_skin/fb.svg";
  const telegram = "/assets/images/virtual_skin/tel.svg";
  const msjText = "/assets/images/virtual_skin/msj.svg";
  const tw = "/assets/images/virtual_skin/tw.svg";
  const email = "/assets/images/virtual_skin/mail.svg";

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const openNewTab = (url) => {
    window.open(url, "_blank");
  };

  const copyToClipboardQR = () => {
    const qr = document.getElementById("codeQr");
    html2canvas(qr).then((canvas) => {
      canvas.toBlob((blob) =>
        navigator.clipboard
          .write([new ClipboardItem({ "image/png": blob })])
          .then(() => {
            setCopiedQR(true);
            console.log("El objeto QR se copió correctamente al portapapeles");
          })
          .catch((error) => {
            console.error(
              "Error al copiar el objeto QR al portapapeles:",
              error
            );
            setCopiedQR(false);
          })
      );
    });
  };

  const copyText = (meeting_id) => {
    navigator.clipboard
      .writeText(window.location.origin + "/" + `${meeting_id}`)
      .then(() => {
        setCopiedLink(true);
      })
      .catch((err) => {
        setCopiedLink(false);
        console.log("Something went wrong", err);
      });
  };

  return (
    <>
      <Box className="header-detail-meeting">
        <Box className="header-detail-meeting-text">
          <Typography variant="h5">{props.startDate}</Typography>
          <Typography variant="h6" style={{ color: "#B3B3B3" }}>
            {props.startTime}
          </Typography>
        </Box>
        <Box className="header-detail-meeting-text">
          <Typography variant="h5">{props.meetingTopic}</Typography>
          <a
            href={`${window.location.origin}/${props.meetingID}`}
            target="_blank"
          >
            <Typography variant="h6" sx={{ color: "#428bca" }}>
              {" "}
              Meeting ID: {props.meetingID}{" "}
              <LaunchIcon style={{ color: "#428bca" }} />
            </Typography>
          </a>
        </Box>
        <Box className="meeting-tab-options">
          <Button className="share-meeting-tab" onClick={handleOpenModal}>
            Share
          </Button>
          <Button className="edit-meeting-tab">Edit</Button>
          <Button className="delete-meeting-tab" onClick={props.onClick}>
            Delete
          </Button>
        </Box>
      </Box>
      {/*  Invite  */}
      <div className="App">
        <Modal
          isOpen={openModal}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-share",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <Box className="share-meeting-link-container">
            <Typography
              variant="h4"
              fontWeight="bold"
              textAlign="left"
              style={{
                fontFamily: "inherit",
                width: "100%",
                paddingLeft: "30px",
              }}
            >
              Easy Share
            </Typography>
            <Box className="share-meeting-input-link">
              <TextField
                id="personalized-outlined-basic"
                label="Invitation Link"
                variant="outlined"
                value={window.location + props.meetingID}
                style={{ width: "70%" }}
              />
              <Button
                className="open-presentation-zrp set-btn-size"
                onClick={() => copyText(props.meetingID)}
              >
                {copiedLink ? "Copied link" : "Copy Invite Link"}
              </Button>
            </Box>
            <Box className="share-meeting-link">
              <Box
                className="share-meeting-link-item"
                onClick={() =>
                  openNewTab(
                    `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/${props.meetingID}`
                  )
                }
              >
                <img
                  src={facebook}
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "50%" }}
                />
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  textAlign="center"
                  style={{ fontFamily: "inherit" }}
                >
                  Facebook
                </Typography>
              </Box>
              <Box
                className="share-meeting-link-item"
                onClick={() => {
                  openNewTab(
                    `https://t.me/share/url?url=${window.location.origin}/${props.meetingID}`
                  );
                }}
              >
                <img
                  src={telegram}
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "50%" }}
                />
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  textAlign="center"
                  style={{ fontFamily: "inherit" }}
                >
                  Telegram
                </Typography>
              </Box>
              <Box
                className="share-meeting-link-item"
                onClick={() =>
                  openNewTab(
                    `sms:?&body=${window.location.origin}/${props.meetingID}`
                  )
                }
              >
                <img
                  src={msjText}
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "50%" }}
                />
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  textAlign="center"
                  style={{ fontFamily: "inherit" }}
                >
                  Messages
                </Typography>
              </Box>
              <Box
                className="share-meeting-link-item"
                onClick={() =>
                  openNewTab(
                    `https://twitter.com/intent/tweet?url=${window.location.origin}/${props.meetingID}`
                  )
                }
              >
                <img
                  src={tw}
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "50%" }}
                />
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  textAlign="center"
                  style={{ fontFamily: "inherit" }}
                >
                  Twitter
                </Typography>
              </Box>
              <Box
                className="share-meeting-link-item"
                onClick={() =>
                  openNewTab(
                    `mailto:info@example.com?&subject=&cc=&bcc=&body=${window.location.origin}/${props.meetingID}`
                  )
                }
              >
                <img
                  src={email}
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "50%" }}
                />
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  textAlign="center"
                  style={{ fontFamily: "inherit" }}
                >
                  Email
                </Typography>
              </Box>
            </Box>
            <Box className="container-choose-copy">
              <div className="firstPart-copy"></div>
              <div style={{ textAlign: "center" }}>
                <span>OR</span>
              </div>
              <div className="firstPart-copy"></div>
            </Box>
            <QRCode
              value={`${window.location.origin}/${props.meetingID}`}
              size={125}
              qrStyle="dots"
              logoPaddingStyle="circle"
              eyeColor="#000000"
              id="codeQr"
            />
            <Button
              className="open-presentation-zrp copy-my-size"
              onClick={() => copyToClipboardQR()}
            >
              {copiedQR ? "QR Copied" : "Copy QR"}
            </Button>
          </Box>
          <button
            className="close-modal"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>
    </>
  );
};
