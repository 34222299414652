import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="nationalbusinessleague.us.auth0.com"
      clientId="P9Qc22aiFk08JN0gX5NpjgfXlgoKwD7b"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: ["https://nationalbusinessleague.us.auth0.com/api/v2/"],
        scope: "openid profile email offline_access",
        connection: "email",
      }}
    >
      <BrowserRouter>
        <App></App>
      </BrowserRouter>
    </Auth0Provider>
    {/*  <BrowserRouter>
      <App></App>
    </BrowserRouter> */}
  </React.StrictMode>
);
