import React from "react";
import { Box, Typography } from "@mui/material";

const SeventhNote = () => {
  return (
    <Box className="notes-container-zrp">
      <Box
        style={{
          marginTop: "15px",
          padding: "8px",
        }}
      >
        <Typography
          variant="h5"
          color="#e64682"
          fontStyle="italic"
          textAlign="left"
          fontWeight="bold"
        >
          Once you’ve ended the skin care party, begin the individual closes by
          phone, text or email as soon as possible.
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">1</span> Did you have a good
          time (today/tonight)?
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">2</span> How does your face
          feel? (Touch your face with the back of your hand.)
        </Typography>
      </Box>

      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">3</span> Do you have any
          questions for me as your personal Mary Kay Independent Beauty
          Consultant?
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">4</span> So tell me, what did
          you like best about the TimeWise Miracle Set 3D®?
        </Typography>
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Typography variant="h5" fontWeight="bold" textAlign="left">
          <span className="number-detail-italic">5</span> Let’s talk about your
          favorite sets. If money were no object, what sets would you buy today?
          (Allow the customer to answer.)
        </Typography>
      </Box>
      <Typography variant="h4" fontWeight="bold" textAlign="center" marginTop="10px">
        Great!
      </Typography>
    </Box>
  );
};

export default SeventhNote;
